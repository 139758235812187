/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClinicalProfileSchema } from '../models/ClinicalProfileSchema';
import type { MonitoringSessionListSchema } from '../models/MonitoringSessionListSchema';
import type { PatientHospitalListResponseSchema } from '../models/PatientHospitalListResponseSchema';
import type { PatientListSchema } from '../models/PatientListSchema';
import type { PatientRaceDictSchema } from '../models/PatientRaceDictSchema';
import type { PatientSchema } from '../models/PatientSchema';
import type { VitalsAlertListSchema } from '../models/VitalsAlertListSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PatientService {
    /**
     * Returns a list of enrolled patients
     * @param id Patient id to search for
     * @param firstName First name to search for
     * @param fullName Full name to search for
     * @param mrn MRN to search for
     * @param fnormrn Full name or MRN to search for
     * @param gender Gender parameter to perform search by
     * @param hospitalId Id of the hospital to search for patients
     * @param marketId Id of the market to search for patients
     * @param status Enrollment status of the patient, default to ENROLLED and DISENROLLED if not provided
     * @param careProviderId Id of the care provider to search for patients
     * @param page Page number
     * @param pageSize Page size
     * @param dobFrom Lowest value to filter by
     * @param dobTo Maximum value to filter by
     * @param vitalsUpdatedSince All vital readings updated from this date
     * @param vitalsNotUpdatedSince All vital readings not updated from this date
     * @param hideTest Hide is_test=True patients
     * @param hasActiveAlert Filter on whether a user has active alerts. If not set, all users are returned. If true, patients will be descending ordered by the max alert priority by default, otherwise by any other provided sort_by values.
     * @param onlyMyPatients Returns patients assigned to the current care provider if true. If false or not set, all patients user can access are returned.
     * @param condition Filters out patients that doesn't have selected condition
     * @param sortBy Field to sort by
     * @param orderBy Type of the ordering
     * @param include Additional data to include on patient
     * @returns PatientListSchema
     * @throws ApiError
     */
    public static getPmsApiV1Patients(
        id?: string,
        firstName?: string,
        fullName?: string,
        mrn?: string,
        fnormrn?: string,
        gender?: 'MALE' | 'FEMALE' | 'OTHER' | 'X',
        hospitalId?: Array<string>,
        marketId?: Array<string>,
        status?: Array<'IDENTIFIED' | 'ELIGIBLE' | 'NOT_ELIGIBLE' | 'PENDING' | 'NEEDS_REVIEW' | 'WAITING_FOR_PROVIDER' | 'READY_TO_ENROLL' | 'APPROVED' | 'ORDER_PENDED' | 'ENROLLED' | 'NOT_ENROLLED' | 'DISENROLLED' | 'PROVIDER_DECLINED' | 'PATIENT_DECLINED'>,
        careProviderId?: string,
        page?: number,
        pageSize?: number,
        dobFrom?: string,
        dobTo?: string,
        vitalsUpdatedSince?: string,
        vitalsNotUpdatedSince?: string,
        hideTest?: boolean,
        hasActiveAlert?: boolean,
        onlyMyPatients?: boolean,
        condition?: string,
        sortBy?: string,
        orderBy: 'asc' | 'desc' = 'asc',
        include?: Array<'ACTIVE_VITALS_ALERTS'>,
    ): CancelablePromise<PatientListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients',
            query: {
                'id': id,
                'first_name': firstName,
                'full_name': fullName,
                'mrn': mrn,
                'fnormrn': fnormrn,
                'gender': gender,
                'hospital_id': hospitalId,
                'market_id': marketId,
                'status': status,
                'care_provider_id': careProviderId,
                'page': page,
                'page_size': pageSize,
                'dob_from': dobFrom,
                'dob_to': dobTo,
                'vitals_updated_since': vitalsUpdatedSince,
                'vitals_not_updated_since': vitalsNotUpdatedSince,
                'hide_test': hideTest,
                'has_active_alert': hasActiveAlert,
                'only_my_patients': onlyMyPatients,
                'condition': condition,
                'sort_by': sortBy,
                'order_by': orderBy,
                'include': include,
            },
        });
    }
    /**
     * Create a new Patient with provided data
     * @param requestBody A JSON object with new Patient data
     * @returns PatientSchema Successfully created patient
     * @throws ApiError
     */
    public static postPmsApiV1Patients(
        requestBody: PatientSchema,
    ): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/patients',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Couldn't process request data`,
            },
        });
    }
    /**
     * Returns a dict of patient races
     * @returns PatientRaceDictSchema
     * @throws ApiError
     */
    public static getPmsApiV1PatientsRaces(): CancelablePromise<PatientRaceDictSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients/races',
        });
    }
    /**
     * Returns a patient
     * <br/>Returns:<br/>    [[PatientSchema]]: Patient List Schema<br/>
     * @param patientId Patient id
     * @returns PatientSchema
     * @throws ApiError
     */
    public static getPmsApiV1Patients1(
        patientId: string,
    ): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients/{patient_id}',
            path: {
                'patient_id': patientId,
            },
        });
    }
    /**
     * Updates specified Patient and returns updated version
     * <br/>Returns:<br/>    [PatientSchema]: Patient Schema<br/>
     * @param patientId Patient id
     * @param requestBody A JSON object containing patient update data
     * @param forceStatus Force patient status to be set to this value, skipping validation (must be admin)
     * @returns PatientSchema
     * @throws ApiError
     */
    public static patchPmsApiV1Patients(
        patientId: string,
        requestBody: PatientSchema,
        forceStatus?: boolean,
    ): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/pms/api/v1/patients/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            query: {
                'force_status': forceStatus,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Resource not found`,
                422: `Couldn't process request data`,
            },
        });
    }
    /**
     * Updates specified Patient and returns updated version
     * <br/>Returns:<br/>    [PatientSchema]: Patient Schema<br/>
     * @param patientId Patient id
     * @param requestBody A JSON object containing patient update data
     * @param forceStatus Force patient status to be set to this value, skipping validation (must be admin)
     * @returns PatientSchema
     * @throws ApiError
     */
    public static putPmsApiV1Patients(
        patientId: string,
        requestBody: PatientSchema,
        forceStatus?: boolean,
    ): CancelablePromise<PatientSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pms/api/v1/patients/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            query: {
                'force_status': forceStatus,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Resource not found`,
                422: `Couldn't process request data`,
            },
        });
    }
    /**
     * Returns a patient clinical profile
     * Returns patient clinical profile
     * @param patientId Patient id
     * @returns ClinicalProfileSchema Successful operation
     * @throws ApiError
     */
    public static getPmsApiV1PatientsClinicalProfile(
        patientId: string,
    ): CancelablePromise<ClinicalProfileSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients/{patient_id}/clinical-profile',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Returns a list Patient Hospitals
     * Returns a list of Hospitals
     * @param patientId ID of the patient to get hospitals
     * @param page Page number
     * @param pageSize Page size
     * @param sortBy Field to sort by
     * @param createdAtFrom Lowest value to filter by
     * @param createdAtTo Highest value to filter by
     * @param updatedAtFrom Lowest value to filter by
     * @param updatedAtTo Highest value to filter by
     * @param orderBy Type of the ordering
     * @param hospitalId ID of the hospital to query for
     * @returns PatientHospitalListResponseSchema successful operation
     * @throws ApiError
     */
    public static getPmsApiV1PatientsHospitals(
        patientId: string,
        page?: number,
        pageSize?: number,
        sortBy?: 'created_at' | 'updated_at',
        createdAtFrom?: string,
        createdAtTo?: string,
        updatedAtFrom?: string,
        updatedAtTo?: string,
        orderBy: 'asc' | 'desc' = 'asc',
        hospitalId?: string,
    ): CancelablePromise<PatientHospitalListResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients/{patient_id}/hospitals',
            path: {
                'patient_id': patientId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
                'created_at_from': createdAtFrom,
                'created_at_to': createdAtTo,
                'updated_at_from': updatedAtFrom,
                'updated_at_to': updatedAtTo,
                'order_by': orderBy,
                'hospital_id': hospitalId,
            },
            errors: {
                400: `Bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * Returns a list of patient's monitoring sessions
     * @param patientId ID of the patient to get monitoring sessions
     * @param id Monitoring session id to search for
     * @param startDatetimeFrom Start date to filter from
     * @param startDatetimeTo Start date to filter until
     * @param endDatetimeFrom End date to filter from
     * @param page Page number
     * @param pageSize Page size
     * @param sortBy Field to sort by
     * @param orderBy Type of the ordering
     * @returns MonitoringSessionListSchema successful operation
     * @throws ApiError
     */
    public static getPmsApiV1PatientsMonitoringSessions(
        patientId: string,
        id?: string,
        startDatetimeFrom?: string,
        startDatetimeTo?: string,
        endDatetimeFrom?: string,
        page?: number,
        pageSize?: number,
        sortBy?: string,
        orderBy: 'asc' | 'desc' = 'asc',
    ): CancelablePromise<MonitoringSessionListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients/{patient_id}/monitoring_sessions',
            path: {
                'patient_id': patientId,
            },
            query: {
                'id': id,
                'start_datetime_from': startDatetimeFrom,
                'start_datetime_to': startDatetimeTo,
                'end_datetime_from': endDatetimeFrom,
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
                'order_by': orderBy,
            },
            errors: {
                400: `Bad request`,
                401: `unauthorized`,
            },
        });
    }
    /**
     * Returns a patient's vitals alerts
     * <br/>Returns:<br/>    [[VitalsAlertListSchema]]: Vitals Alert List Schema<br/>
     * @param patientId Patient id
     * @param dateTo Date to
     * @param dateFrom Date from
     * @param status
     * @param sortBy
     * @param orderBy Type of the ordering
     * @returns VitalsAlertListSchema
     * @throws ApiError
     */
    public static getPmsApiV1PatientsVitalsAlerts(
        patientId: string,
        dateTo: string,
        dateFrom: string,
        status?: any[],
        sortBy?: any[],
        orderBy?: 'asc' | 'desc',
    ): CancelablePromise<VitalsAlertListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/patients/{patient_id}/vitals-alerts',
            path: {
                'patient_id': patientId,
            },
            query: {
                'date_to': dateTo,
                'date_from': dateFrom,
                'status': status,
                'sort_by': sortBy,
                'order_by': orderBy,
            },
        });
    }
}
