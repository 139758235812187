import merge from 'lodash/merge';

import type { Schema } from '../Schema.types';

export function mergeDependencies(schema: Schema): Schema {
  if (typeof schema !== 'object' || !schema) {
    return schema;
  }

  const hasDependentSchemas = Object.keys(schema).includes('dependentSchemas');
  const hasDependentRequired =
    Object.keys(schema).includes('dependentRequired');
  const newSchema = { ...schema } as {
    dependencies?: { [name: string]: Schema };
    dependentSchemas?: { [name: string]: Schema };
    dependentRequired?: { [key: string]: string[] };
  };
  if (hasDependentSchemas || hasDependentRequired) {
    newSchema.dependencies = merge(
      {},
      newSchema.dependencies ?? {},
      newSchema.dependentSchemas ?? {},
      newSchema.dependentRequired ?? {},
    );
    delete newSchema.dependentSchemas;
    delete newSchema.dependentRequired;
  }

  return Object.fromEntries(
    Object.entries(newSchema).map(([key, value]) => [
      key,
      Array.isArray(value)
        ? value.map(mergeDependencies)
        : mergeDependencies(value),
    ]),
  );
}
