import cx from 'classnames';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';

import { TableRow } from '@/deprecated/mui';

import { tableRow } from './styles.css';

type Props = {
  children?: ReactNode;
  className?: string;
  hover?: boolean;
  selected?: boolean;
};

export const Row = forwardRef(
  (
    { children, className, ...props }: Props,
    ref?: ForwardedRef<HTMLTableRowElement> | null,
  ) => (
    <TableRow ref={ref} className={cx(className, tableRow)} {...props}>
      {children}
    </TableRow>
  ),
);
