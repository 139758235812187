export const spacing = {
  "XXS": "4px",
  "XS": "8px",
  "S": "12px",
  "M": "16px",
  "L": "24px",
  "XL": "32px",
  "XXL": "40px",
  "XXXL": "64px",
  "XXXXL": "128px"
} as const;

export const color = {
  "Theme": {
    "Light": {
      "Primary Tint": "#eaf1f9",
      "Primary": "#0029B2",
      "Primary Shade": "#001B8C",
      "Success Tint": "#e7f8ef",
      "Success": "#27a968",
      "Success Shade": "#157d56",
      "Warning Tint": "#fff4c0",
      "Warning": "#f0c323",
      "Warning Shade": "#c69e0f",
      "Danger Tint": "#ffdade",
      "Danger": "#db3345",
      "Danger Shade": "#a91d2d",
      "Info Tint": "#e8f9f8",
      "Info": "#2ba2ab",
      "Info Shade": "#1c819a",
      "Base Font": "#2b3d4e",
      "Base Font Subtle": "#5a6a7b",
      "Base Form Border": "#bec5d0",
      "Base Form Disabled": "#E8EDEF",
      "Base Divider": "#D9DDE4",
      "Base Divider Strong": "#728091",
      "Base Background": "#ffffff",
      "Base Background Alt": "#fafafa",
      "Base Background Striped": "#E8EDEF",
      "Base Overlay Shim": "#00000026",
      "Caution Tint": "#FFCEAA",
      "Caution Shade": "#8B5400",
      "Caution": "#FF974A"
    }
  },
  "Palette": {
    "Primary": {
      "100": "#F0F3Fd",
      "200": "#B4C4F2",
      "300": "#7891E2",
      "400": "#3C5DCD",
      "500": "#0029B2",
      "600": "#0022A0",
      "700": "#001B8C",
      "800": "#001578",
      "900": "#001062"
    },
    "Neutral": {
      "100": "#fafafa",
      "200": "#E8EDEF",
      "300": "#D9DDE4",
      "400": "#bec5d0",
      "500": "#8b97a7",
      "600": "#728091",
      "700": "#5a6a7b",
      "800": "#425365",
      "900": "#2b3d4e",
      "White": "#ffffff",
      "Black": "#000000"
    },
    "Success": {
      "100": "#e7f8ef",
      "200": "#b4e8cb",
      "300": "#83d5a9",
      "400": "#54c088",
      "500": "#27a968",
      "600": "#1d9460",
      "700": "#157d56",
      "800": "#0d654a",
      "900": "#084b3b"
    },
    "Warning": {
      "100": "#fff4c0",
      "200": "#ffea95",
      "300": "#ffdf6c",
      "400": "#f9d246",
      "500": "#f0c323",
      "600": "#dcb118",
      "700": "#c69e0f",
      "800": "#a98a08",
      "900": "#5b4e0b"
    },
    "Danger": {
      "100": "#ffdade",
      "200": "#ffa3ad",
      "300": "#fe7f8d",
      "400": "#f85e6f",
      "500": "#f04053",
      "600": "#db3345",
      "700": "#c42738",
      "800": "#a91d2d",
      "900": "#8c1522"
    },
    "Info": {
      "100": "#e8f9f8",
      "200": "#b5e8e8",
      "300": "#85d4d6",
      "400": "#57bcc2",
      "500": "#2ba2ab",
      "600": "#2392a3",
      "700": "#1c819a",
      "800": "#167090",
      "900": "#116085"
    },
    "Grey Neutral": {
      "100": "#f9f8ff",
      "200": "#dedde4",
      "300": "#c3c3c8",
      "400": "#a8a8ad",
      "500": "#8d8e91",
      "600": "#737376",
      "700": "#58595b",
      "800": "#3d3e3f",
      "900": "#232324"
    },
    "Caution": {
      "100": "#FFCEAA",
      "500": "#FF974A",
      "900": "#8B5400"
    }
  },
  "Brand": {
    "Primary": {
      "Navy": "#062055",
      "Marigold": "#f4c47b",
      "Cream": "#f8ead1",
      "Cream Tint": "#fdf8f0",
      "Cream Dark": "#E8D9C0",
      "Bright Blue": "#0029b2"
    },
    "Secondary": {
      "Blue": "#a2c0e2",
      "Blue Light": "#c9daed",
      "Blue Tint": "#eaf1f9",
      "Green": "#aecaaa",
      "Green Light": "#dae7d8",
      "Green Tint": "#edf3ec",
      "Purple": "#d6c7df",
      "Purple Light": "#e3dae8",
      "Purple Tint": "#efe9f2"
    },
    "Neutral": {
      "100": "#e6e9ee",
      "200": "#cdd2dd",
      "300": "#9ba6bb",
      "400": "#6a7999",
      "500": "#384d77",
      "600": "#19346B"
    }
  }
} as const;

export const boxShadow = {
  "Focus": [
    {
      "color": "#8cb6eabf",
      "type": "boxShadow",
      "x": "0px",
      "y": "0px",
      "blur": "0px",
      "spread": "5px"
    },
    {
      "color": "#ffffff",
      "type": "boxShadow",
      "x": "0px",
      "y": "0px",
      "blur": "0px",
      "spread": "2px"
    }
  ],
  "Elevation": {
    "100": {
      "color": "#2b3d4e29",
      "type": "boxShadow",
      "x": "0px",
      "y": "4px",
      "blur": "10px",
      "spread": "0px"
    },
    "200": {
      "color": "#2b3d4e29",
      "type": "boxShadow",
      "x": "0px",
      "y": "8px",
      "blur": "20px",
      "spread": "0px"
    },
    "300": {
      "color": "#2b3d4e29",
      "type": "boxShadow",
      "x": "0px",
      "y": "12px",
      "blur": "30px",
      "spread": "0px"
    },
    "050": {
      "color": "#2b3d4e14",
      "type": "boxShadow",
      "x": "0px",
      "y": "1px",
      "blur": "4px",
      "spread": "1px"
    }
  }
} as const;

export const fontFamilies = {
  "Display": "Source Serif 4",
  "Sans": "Inter",
  "Mono": "Source Code Pro, Consolas, monaco, monospace"
} as const;

export const lineHeights = {
  "0": "48px",
  "1": "36px",
  "2": "28px",
  "3": "24px",
  "4": "20px",
  "5": "16px",
  "6": "14px"
} as const;

export const fontWeights = {
  "display-semibold": 600,
  "sans-medium": 500,
  "sans-regular": 400,
  "sans-semibold": 600,
  "mono-regular": 400,
  "mono-medium": 500,
  "Display-Regular": "Regular"
} as const;

export const fontSizes = {
  "fontSize": {
    "0": "10px",
    "1": "12px",
    "2": "14px",
    "3": "16px",
    "4": "18px",
    "5": "24px",
    "6": "30px",
    "7": "40px"
  }
} as const;

export const letterSpacing = {
  "0": "0px",
  "1": "0.6px",
  "-1": "-0.6px"
} as const;

export const paragraphSpacing = {
  "0": "16px"
} as const;

export const typography = {
  "Heading": {
    "XL": {
      "fontFamily": "'Source Serif 4'",
      "fontWeight": "Regular",
      "lineHeight": "48px",
      "fontSize": "40px",
      "letterSpacing": "-0.6px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "L": {
      "fontFamily": "Inter",
      "fontWeight": 600,
      "lineHeight": "36px",
      "fontSize": "30px",
      "letterSpacing": "-0.6px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "M": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "28px",
      "fontSize": "24px",
      "letterSpacing": "-0.6px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "S": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "28px",
      "fontSize": "18px",
      "letterSpacing": "-0.6px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "XS": {
      "fontFamily": "Inter",
      "fontWeight": 600,
      "lineHeight": "20px",
      "fontSize": "16px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    }
  },
  "Body": {
    "Lead": {
      "fontFamily": "Inter",
      "fontWeight": 400,
      "lineHeight": "24px",
      "fontSize": "16px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Default": {
      "fontFamily": "Inter",
      "fontWeight": 400,
      "lineHeight": "20px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Strong": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "20px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "DefaultShort": {
      "fontFamily": "Inter",
      "fontWeight": 400,
      "lineHeight": "16px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "StrongShort": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "16px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Small": {
      "fontFamily": "Inter",
      "fontWeight": 400,
      "lineHeight": "16px",
      "fontSize": "12px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Caption": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "14px",
      "fontSize": "10px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "LeadStrong": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "fontSize": "16px",
      "lineHeight": "24px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px"
    }
  },
  "Action": {
    "Default": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "16px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Strong": {
      "fontFamily": "Inter",
      "fontWeight": 600,
      "lineHeight": "16px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Small": {
      "fontFamily": "Inter",
      "fontWeight": 500,
      "lineHeight": "16px",
      "fontSize": "12px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "paragraphIndent": "",
      "textCase": "none",
      "textDecoration": "none"
    }
  },
  "Mono": {
    "Default": {
      "fontFamily": "'Source Code Pro', Consolas, monaco, monospace",
      "fontWeight": 400,
      "lineHeight": "16px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "paragraphIndent": "",
      "textCase": "none",
      "textDecoration": "none"
    },
    "Small": {
      "fontFamily": "'Source Code Pro', Consolas, monaco, monospace",
      "fontWeight": 400,
      "lineHeight": "16px",
      "fontSize": "12px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "paragraphIndent": "",
      "textCase": "none",
      "textDecoration": "none"
    },
    "SmallStrong": {
      "fontFamily": "'Source Code Pro', Consolas, monaco, monospace",
      "fontWeight": 500,
      "lineHeight": "16px",
      "fontSize": "12px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "paragraphIndent": "",
      "textCase": "uppercase",
      "textDecoration": "none"
    },
    "DefaultStrong": {
      "fontFamily": "'Source Code Pro', Consolas, monaco, monospace",
      "fontWeight": 500,
      "lineHeight": "16px",
      "fontSize": "14px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textDecoration": "none",
      "textCase": "none"
    }
  },
  "Serif": {
    "Lead": {
      "fontFamily": "'Source Serif 4'",
      "fontWeight": 400,
      "lineHeight": "24px",
      "fontSize": "16px",
      "letterSpacing": "0px",
      "paragraphSpacing": "16px",
      "textCase": "none",
      "textDecoration": "none"
    }
  }
} as const;

export const textCase = {
  "none": "none",
  "uppercase": "uppercase"
} as const;

export const textDecoration = {
  "none": "none"
} as const;

export const borderRadius = {
  "S": "4px",
  "M": "8px",
  "L": "16px",
  "XL": "9999px"
} as const;