{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}

{{> header val="Product Support" }}
<ul>
  <li>Questions related to Cadence: </li>
  <li>Questions related to devices: </li>
  <li>Patient confirms their contact information, emergency contact is correct: </li>
  <li>Patient device utilization:
    <ul>
      <li>Is the patient taking regular readings with all assigned devices?</li>
      <li>[Goal for vital frequency]</li>
    </ul>
  </li>
</ul>
