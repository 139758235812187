import { useQuery } from 'react-query';

import { grpcQueryFunction } from '@/reactQuery';

import { CallService } from '../../generated/grpcGateway/call.pb';
import type {
  Call,
  CallState,
  ListCallsResponse,
} from '../../generated/grpcGateway/call.pb';
import {
  CALL_QUERY_PARAMS_FILTER_LOOKUP,
  CALL_QUERY_PARAMS_ORDER_BY_LOOKUP,
  buildCallRPCRequest,
} from './call-grpc';

const POLLING_INTERVAL = 10000;

export const CALL_QUERY_KEY_BASE = ['rpm', 'v1', 'call'] as const;

export type CallParams = {
  patientId?: string;
  caregiverId?: string;
  state?: CallState;
  noteInfo?: {
    noteId?: number;
  };
  user?: {
    patientId?: string;
    caregiverId?: string | null;
    noteId?: number;
  };
};

const CALL_QUERY_KEYS = {
  single: (callId: string) => [...CALL_QUERY_KEY_BASE, callId] as const,
  list: (params?: CallParams) =>
    [
      ...CALL_QUERY_KEY_BASE,
      {
        sort_by: ['startTime'],
        order_by: ['desc'],
        ...params,
      },
      'infinite',
    ] as const,
};
export function useGetCall(callId: string) {
  return useQuery(CALL_QUERY_KEYS.single(callId), (ctx) =>
    grpcQueryFunction<Call>(ctx, CallService.GetCall, {
      name: `call/${callId}`,
    }),
  );
}

export function useListCalls(
  params?: CallParams,
  enabled: boolean = true,
  refetch: boolean = true,
) {
  return useQuery(
    CALL_QUERY_KEYS.list(params),
    (ctx) =>
      grpcQueryFunction<ListCallsResponse>(
        ctx,
        CallService.ListCalls,
        buildCallRPCRequest(
          ctx,
          CALL_QUERY_PARAMS_FILTER_LOOKUP,
          CALL_QUERY_PARAMS_ORDER_BY_LOOKUP,
        ),
      ),
    {
      enabled,
      refetchInterval: refetch ? POLLING_INTERVAL : false,
    },
  );
}
