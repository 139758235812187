import type { LogEntry } from '../logger.types';

export abstract class LogEmitter {
  public abstract debug(entry: LogEntry): void;

  public abstract info(entry: LogEntry): void;

  public abstract warn(entry: LogEntry): void;

  public abstract error(entry: LogEntry, error?: Error): void;
}
