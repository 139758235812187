const CHF_PRIMARY_CODES = [
  'I501',
  'I509',
  'I5020',
  'I5021',
  'I5022',
  'I5023',
  'I5031',
  'I5032',
  'I5033',
  'I5040',
  'I5041',
  'I5042',
  'I5082',
  'I5089',
  'I50',
  'I5030',
  'I5043',
  'I5081',
  'I50812',
  'I5083',
  'I5084',
  'I255',
  'I420',
  'I422',
  'I429',
  '1509',
];

const ADDITIONAL_CHF_CODES = [
  'I4891',
  'I251',
  'I472',
  'N189',
  'E875',
  'E876',
  'E871',
  'E870',
  'I959',
];

const HTN_PRIMARY_CODES = ['I10', 'I11', 'I12', 'I13', 'I15', 'I152', 'I16'];
const ADDITIONAL_HTN_CODES: string[] = [];
const ADDITIONAL_T2D_CODES = ['I251', 'E162'];
const T2D_PRIMARY_CODES = [
  'E11',
  'E110',
  'E110',
  'E111',
  'E111',
  'E1110',
  'E1111',
  'E112',
  'E1121',
  'E1122',
  'E1129',
  'E113',
  'E1131',
  'E11311',
  'E11319',
  'E1132',
  'E11321',
  'E113211',
  'E113212',
  'E113213',
  'E113219',
  'E11329',
  'E113291',
  'E113292',
  'E113293',
  'E113299',
  'E1133',
  'E11331',
  'E113311',
  'E113312',
  'E113313',
  'E113319',
  'E11339',
  'E113391',
  'E113392',
  'E113393',
  'E113399',
  'E1134',
  'E11341',
  'E113411',
  'E113412',
  'E113413',
  'E113419',
  'E11349',
  'E113491',
  'E113492',
  'E113493',
  'E113499',
  'E1135',
  'E11351',
  'E113511',
  'E113512',
  'E113513',
  'E113519',
  'E11352',
  'E113521',
  'E113522',
  'E113523',
  'E113529',
  'E11353',
  'E113531',
  'E113532',
  'E113533',
  'E113539',
  'E11354',
  'E113541',
  'E113542',
  'E113543',
  'E113549',
  'E11355',
  'E113551',
  'E113552',
  'E113553',
  'E113559',
  'E11359',
  'E113591',
  'E113592',
  'E113593',
  'E113599',
  'E1136',
  'E1137',
  'E1137X1',
  'E1137X2',
  'E1137X3',
  'E1137X9',
  'E1139',
  'E114',
  'E1140',
  'E1141',
  'E1142',
  'E1143',
  'E1144',
  'E1149',
  'E115',
  'E1151',
  'E1152',
  'E1159',
  'E116',
  'E1161',
  'E11610',
  'E11618',
  'E1162',
  'E11620',
  'E11621',
  'E11622',
  'E11628',
  'E1163',
  'E11630',
  'E11638',
  'E1164',
  'E11641',
  'E11649',
  'E1165',
  'E1169',
  'E118',
  'E119',
];

const ALL_HTN_PRIMARY_CODES = [...HTN_PRIMARY_CODES, ...ADDITIONAL_HTN_CODES];
const ALL_T2D_PRIMARY_CODES = [...T2D_PRIMARY_CODES, ...ADDITIONAL_T2D_CODES];
const ALL_CHF_PRIMARY_CODES = [...CHF_PRIMARY_CODES, ...ADDITIONAL_CHF_CODES];

export const ALL_PRIMARY_CODES = [
  ...ALL_HTN_PRIMARY_CODES,
  ...ALL_T2D_PRIMARY_CODES,
  ...ALL_CHF_PRIMARY_CODES,
];
