import { FormattedMessage } from 'react-intl';

import { vitalType } from '../../vitalEducation/scriptUtils';
import {
  ConditionProgram,
  VitalAverageLevel,
} from '../../vitalEducation/types';
import type {
  ConditionScripts,
  ScriptContext,
  ScriptFn,
  VitalAvgLevelScriptMap,
} from '../../vitalEducation/types';

type AdditionalContext = {
  isProviderManaged: boolean;
};

function getLevelFourProviderParagraph(
  isProviderManaged: boolean,
  conditionProgram: ConditionProgram,
): Maybe<JSX.Element> {
  if (!isProviderManaged) {
    return undefined;
  }

  return (
    <FormattedMessage
      defaultMessage="We will work closely with your provider and share your recent {vital} trends to get you the care you need. If you develop any new symptoms between now and your next visit always remember you can contact Cadence 24/7 or your Provider with any questions or concerns."
      values={{ vital: vitalType(conditionProgram) }}
    />
  );
}

function getHtnScripts({
  isProviderManaged,
}: AdditionalContext): VitalAvgLevelScriptMap {
  return {
    [VitalAverageLevel.One]: ({ vitalAvg, clinicalGoal }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="Your average blood pressure of {vitalAvg} over the last 30 days means that your blood pressure is currently well controlled. Generally, we consider your blood pressure being at goal when it is under {clinicalGoal}. Both numbers count in that determination. You're doing a great job to get your blood pressure under control. Keep up the good work."
        values={{ vitalAvg, clinicalGoal }}
      />
    ),
    [VitalAverageLevel.Two]: ({ vitalAvg, clinicalGoal }: ScriptContext) => [
      <FormattedMessage
        defaultMessage="The goal your provider would like to see is under {clinicalGoal}."
        values={{ vitalAvg, clinicalGoal }}
      />,
      <FormattedMessage
        defaultMessage="As your average blood pressure over the last 30 days is {vitalAvg}, this means that your blood pressure is near goal. Good job! You are definitely on track and doing a great job."
        values={{ vitalAvg }}
      />,
    ],
    [VitalAverageLevel.Three]: ({ vitalAvg, clinicalGoal }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="As your average blood pressure is in the 140 to 160 over 90 to 100 range (both numbers count in that determination), this indicates your blood pressure is not currently at goal. You are not far off, but we do want to get your blood pressure under better control. Our aim is to get your blood pressure down to at least under {clinicalGoal}."
        values={{ vitalAvg, clinicalGoal }}
      />
    ),
    [VitalAverageLevel.Four]: ({ conditionProgram }: ScriptContext) =>
      [
        <FormattedMessage defaultMessage="As your average blood pressure is in the 160 to 180 over 100 to 120 range (both numbers count in that determination), this indicates your blood pressure is significantly out of range. It also indicates that it is highly likely your blood pressure might well benefit from medication adjustments in the coming months." />,
        getLevelFourProviderParagraph(isProviderManaged, conditionProgram),
        <FormattedMessage defaultMessage="Our aim is to get your blood pressure down to at least under 140 over 90 or ideally under 130 over 80, based on your Provider's recommendation." />,
      ].filter((el): el is JSX.Element => !!el),
    [VitalAverageLevel.Five]: ({ vitalAvg }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="This patient's vital average is {vitalAvg} and should be escalated to an NP"
        values={{ vitalAvg }}
      />
    ),
    [VitalAverageLevel.Unknown]: ({
      vitalAvg,
      clinicalGoal,
    }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="Your average blood pressure of {vitalAvg} over the last 30 days means that your blood pressure appears to be running low periodically. Generally, we consider your blood pressure being at goal when it is under {clinicalGoal} but above 90/60. Both numbers count in that determination. We will continue to monitor with you and make adjustments in the future as needed. If you develop any new symptoms, please notify Cadence."
        values={{ vitalAvg, clinicalGoal }}
      />
    ),
  };
}

function getT2dScripts({
  isProviderManaged,
}: AdditionalContext): VitalAvgLevelScriptMap {
  return {
    [VitalAverageLevel.One]: ({ vitalAvg, clinicalGoal }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="Your average blood sugar of {vitalAvg} over the last 30 days means that your blood glucose is currently well controlled. Generally, we consider your blood sugar being at goal when it averages less than {clinicalGoal} over 30 days. You're doing a great job to keeping your blood glucose under control. Keep up the good work."
        values={{ vitalAvg, clinicalGoal }}
      />
    ),
    [VitalAverageLevel.Two]: ({ clinicalGoal }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="Generally, we consider your blood sugar being at goal when it averages less than {clinicalGoal} over 30 days while avoiding hypoglycemia (low blood sugar). As your average blood glucose is within 154-183, you are definitely on track and doing a great job."
        values={{ clinicalGoal }}
      />
    ),
    [VitalAverageLevel.Three]: ({ clinicalGoal }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="As your average blood glucose is in the 184 to 200 range, this indicates your blood glucose is not currently at goal. You are not far off, but we do want to get your blood glucose under better control. Our aim is to get your blood glucose down to at least at average monthly blood sugar less than {clinicalGoal} while avoiding hypoglycemia (low blood sugar)."
        values={{ clinicalGoal }}
      />
    ),
    [VitalAverageLevel.Four]: ({
      clinicalGoal,
      conditionProgram,
    }: ScriptContext) =>
      [
        <FormattedMessage defaultMessage="As your average blood glucose is in the 200-240 range, this indicates your blood sugar is significantly out of range. It also indicates that it is highly likely your blood glucose might well benefit from medication adjustments in the coming months." />,
        getLevelFourProviderParagraph(isProviderManaged, conditionProgram),
        <FormattedMessage
          defaultMessage="Our aim is to get your average blood glucose down to at least under {clinicalGoal} while avoiding hypoglycemia (low blood sugar)."
          values={{ clinicalGoal }}
        />,
      ].filter((el): el is JSX.Element => !!el),
    [VitalAverageLevel.Five]: ({ vitalAvg }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="This patient's vital average is {vitalAvg} and should be escalated to an NP"
        values={{ vitalAvg }}
      />
    ),
    [VitalAverageLevel.Unknown]: ({
      vitalAvg,
      clinicalGoal,
    }: ScriptContext) => (
      <FormattedMessage
        defaultMessage="Your average blood sugar of {vitalAvg} over the last 30 days means that your blood sugar appears to be running low periodically. Generally, we consider your blood sugar being at goal when it averages less than {clinicalGoal} over 30 days but staying above 70. We will continue to monitor with you and make adjustments in the future as needed. If you develop any new symptoms, please notify Cadence."
        values={{ vitalAvg, clinicalGoal }}
      />
    ),
  };
}

export const HTN_AND_T2D_THIRTY_DAY_AVG_SCRIPT: ScriptFn = ({
  clinicalGoal,
  secondaryClinicalGoal,
}: ScriptContext) => (
  <FormattedMessage
    defaultMessage="Generally, our aim is to get your monthly averages down to {clinicalGoal} for blood pressure and < {secondaryClinicalGoal} while avoiding low blood sugar for your blood glucose control."
    values={{ clinicalGoal, secondaryClinicalGoal }}
  />
);

export const getThirtyDayAvgScripts = (
  ctx: AdditionalContext,
): ConditionScripts => ({
  [ConditionProgram.HYPERTENSION]: getHtnScripts(ctx),
  [ConditionProgram.TYPE_2_DIABETES]: getT2dScripts(ctx),
  [ConditionProgram.T2D_AND_HYPERTENSION]: HTN_AND_T2D_THIRTY_DAY_AVG_SCRIPT,
  // All scripting is in DeviceUsage component
  [ConditionProgram.HEART_FAILURE]: () => [],
});
