import type { ToastAria } from '@react-aria/toast';
import cx from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import AlertCircle from '@/shared/assets/svgs/alertCircle.svg?react';
import AlertTriangle from '@/shared/assets/svgs/alertTriangle.svg?react';
import CheckIcon from '@/shared/assets/svgs/check.svg?react';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import InfoCircle from '@/shared/assets/svgs/info-circle.svg?react';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { color } from '@/shared/tempo/theme';

import { container, content, icon, iconContainer } from './Toast.css';
import type { Variant } from './types';

interface Props {
  className?: string;
  variant?: Variant;
  title?: ReactNode;
  children: ReactNode;
  aria?: ToastAria;
}

export const Toast = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      title,
      children,
      variant = 'info',
      aria = {
        toastProps: {},
        closeButtonProps: {},
        descriptionProps: {},
        titleProps: {},
      },
    },
    forwardedRef,
  ) => (
    <div
      {...aria.toastProps}
      ref={forwardedRef}
      className={cx(className, container[variant], flexContainer.row)}
    >
      <div>
        <div className={iconContainer[variant]}>{ICON_MAP[variant]}</div>
      </div>
      <div className={content.container}>
        <div className={content.header}>
          <div className={content.title}>{title || TITLE_MAP[variant]}</div>
          <IconButton
            {...aria.closeButtonProps}
            variant="tertiary"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div {...aria.titleProps} className={content.body}>
          {children}
        </div>
      </div>
    </div>
  ),
);

const TITLE_MAP: Record<Variant, ReactElement> = {
  success: <FormattedMessage defaultMessage="Success" />,
  info: <FormattedMessage defaultMessage="Info" />,
  error: <FormattedMessage defaultMessage="Error" />,
  alert: <FormattedMessage defaultMessage="Alert" />,
};

const ICON_MAP: Record<Variant, ReactElement> = {
  success: <CheckIcon className={icon} fill={color.Palette.Neutral.White} />,
  info: <InfoCircle className={icon} stroke={color.Palette.Neutral.White} />,
  error: <AlertTriangle className={icon} fill={color.Palette.Neutral.White} />,
  alert: (
    <AlertCircle className={icon} stroke={color.Theme.Light['Base Font']} />
  ),
};
