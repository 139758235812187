import { Fragment } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Divider } from '@/shared/common/Divider';
import { Form } from '@/shared/common/Form';
import { Button } from '@/shared/tempo/atom/Button';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import { GoalMeasure } from '../../../Goals/goals.types';
import { EMPTY_GOALS_SECTION, type FormFields } from '../../formConfig';
import {
  addItemButton,
  divider,
  goalSectionContainer,
  sectionTitle,
} from '../carePlanSections.css';
import { ConditionSection } from './ConditionSection';

type Props = {
  form: UseFormReturn<FormFields>;
  manualAutoSave: () => void;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
};

export function GoalsAndInterventions({
  form,
  manualAutoSave,
  createGoalWithMeasure,
}: Props) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'goalsAndInterventions',
  });

  return (
    <Form.Section
      title={
        <div className={goalSectionContainer}>
          {intl.formatMessage({ defaultMessage: 'Goals and Interventions' })}
          <ContextualGoalButton
            onPress={() => createGoalWithMeasure(GoalMeasure.ChronicConditions)}
          />
        </div>
      }
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <ConditionSection
            numOfConditions={fields.length}
            index={index}
            form={form}
            manualAutoSave={manualAutoSave}
            onRemove={() => remove(index)}
            isRemovable={fields.length > 2}
          />
          {index < fields.length - 1 && <Divider className={divider} />}
        </Fragment>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() => append({ ...EMPTY_GOALS_SECTION })}
        >
          <FormattedMessage defaultMessage="Add Condition" />
        </Button>
      </div>
    </Form.Section>
  );
}
