import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { SharedScheduling } from '../../shared/Scheduling';
import { regVisitPath } from '../paths';

export function Scheduling() {
  return (
    <SharedScheduling
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={regVisitPath('/time-tracking', '/index')}
    />
  );
}
