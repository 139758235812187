import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { SharedDeviceUsage } from '../../shared/ProductSupport';
import { regVisitPath } from '../paths';
import { useDeviceUsageOnNext } from './useDeviceUsageOnNext';

export function DeviceUsage() {
  const [onNext, isLoading] = useDeviceUsageOnNext();

  return (
    <SharedDeviceUsage
      isLoading={isLoading}
      sections={REGULAR_VISIT_SECTIONS}
      highUsageOnNext={onNext}
      lowUsagePath={regVisitPath('/product-support', '/low-device-usage')}
    />
  );
}
