import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { AppointmentCancellationType } from './formConfig';

export function getAppointmentCancellationTypeI18n(
  intl: IntlShape,
): Record<AppointmentCancellationType, string> {
  return {
    [AppointmentCancellationType.NoShow]: intl.formatMessage({
      defaultMessage: 'No Show',
    }),
    [AppointmentCancellationType.PatientRequestReschedule]: intl.formatMessage({
      defaultMessage: 'Patient requested reschedule',
    }),
    [AppointmentCancellationType.PatientHospitalized]: intl.formatMessage({
      defaultMessage: 'Patient hospitalized',
    }),
    [AppointmentCancellationType.SchedulingErrorDisenrollment]:
      intl.formatMessage({
        defaultMessage: 'Scheduling error / disenrollment',
      }),
  };
}

export function useAppointmentCancellationTypeI18n() {
  const intl = useIntl();
  return getAppointmentCancellationTypeI18n(intl);
}
