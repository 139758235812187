import isEmpty from 'lodash/isEmpty';

import type { VitalsAlertSchema } from '@/shared/generated/api/pms';
import type {
  ListPatientVitalsResponseVital,
  ListPatientVitalsResponseVitalType,
} from '@/shared/generated/grpcGateway/telemetry.pb';
import type {
  GroupedVitals,
  PatientVitals,
  VitalType,
  VitalsGroupedByDate,
} from '@/shared/types/vitals.types';

function getUnsuppressedReadingsByVitalType(
  relation_tags_unsuppressed: Record<
    string,
    string[] | Record<string, string[]>
  >,
  relations: Record<string, string[]>,
  vitalType: string,
) {
  /* because we didn't backfill/normalize alerts after we added cool down logic for vitals alerts
   * now we have three versions of `relation_tags_unsuppressed`
   * v0Alerts: alerts before any cool down work applied: no `relation_tags_unsuppressed` field
   * v1Alerts: alerts.relation_tags_unsuppressed is: `{<readingId>:[<tagName>...]}`
   * v2Alerts: alerts.relation_tags_unsuppressed is: `{<tagType>:{<readingId>:[ <tagName>...]}}`
   */

  // case: no suppressed alerts (including v0Alerts)
  const patientVitalType = vitalType.toUpperCase();
  if (isEmpty(relation_tags_unsuppressed)) {
    if (!relations[patientVitalType]) {
      return [];
    }
    return relations[patientVitalType];
  }

  // case: v2Alerts
  const isV2Alerts = Object.keys(relation_tags_unsuppressed).some((key) =>
    Object.keys(relations).includes(key),
  );
  if (isV2Alerts) {
    if (!relation_tags_unsuppressed[patientVitalType]) {
      return [];
    }
    return (
      Object.entries(relation_tags_unsuppressed[patientVitalType])
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, tags]) => !isEmpty(tags))
        .map(([reading_id]) => reading_id)
    );
  }

  // case: v1Alerts
  // note v1Alerts is not vitalType based, legacy issue, treating it as unsuppressed
  // for all vitalTypes
  return Object.keys(relation_tags_unsuppressed);
}

export type UnsuppressedReadings = Record<VitalType, Set<string>>;

export function getUnsuppressedReadings(
  patientVitalsAlerts: VitalsAlertSchema[],
) {
  const unsuppressedReadings: UnsuppressedReadings = {
    blood_pressure: new Set<string>(),
    heart_rate: new Set<string>(),
    weight: new Set<string>(),
    blood_glucose: new Set<string>(),
  };
  patientVitalsAlerts.forEach(({ relation_tags_unsuppressed, relations }) => {
    Object.keys(unsuppressedReadings).forEach((vitalType) => {
      const unSuppressedReadingIds = getUnsuppressedReadingsByVitalType(
        relation_tags_unsuppressed || {},
        relations,
        vitalType,
      );
      unSuppressedReadingIds.forEach((id) => {
        unsuppressedReadings[vitalType as VitalType].add(id);
      });
    });
  });

  return unsuppressedReadings;
}

// TODO: delete after telemetry endpoint roll out
export const getVitalsGroupedByDate = (
  patientVitals: PatientVitals,
  notSuppressedReadings: UnsuppressedReadings,
) => {
  const result: VitalsGroupedByDate = {};

  Object.entries(patientVitals).forEach(([vitalType, vitals]) => {
    const patientVitalType = vitalType as VitalType;
    vitals.forEach((vital) => {
      const date = vital.timestamp.substring(0, 10);
      const existingReadingsOnDate = result[date]?.[patientVitalType];
      const updatedVital = {
        ...vital,
        isSuppressed: !notSuppressedReadings[patientVitalType].has(vital.id),
      };
      const updatedReadingsOnDate = existingReadingsOnDate
        ? [...existingReadingsOnDate, updatedVital]
        : [updatedVital];

      result[date] = {
        ...result[date],
        [vitalType]: updatedReadingsOnDate,
      };
    });
  });

  return result;
};

export const getPendedAlertVitals = (
  groupedListVitals: GroupedVitals,
  unsuppressedReadings: UnsuppressedReadings,
): GroupedVitals =>
  Object.entries(groupedListVitals).reduce((acc, [date, vitalsByType]) => {
    acc[date] = Object.entries(vitalsByType).reduce(
      (innerAcc, [type, vitals]) => {
        const vitalType = type.toLowerCase() as VitalType;
        const updatedVitals = vitals.map((vital) => ({
          ...vital,
          isSuppressed: !unsuppressedReadings[vitalType]?.has(vital.name || ''),
        }));

        return {
          ...innerAcc,
          [type]: updatedVitals,
        };
      },
      {} as GroupedVitals[string],
    );

    return {
      ...acc,
      [date]: acc[date],
    };
  }, {} as GroupedVitals);

export const groupVitalsByDateAndType = (
  vitals: ListPatientVitalsResponseVital[],
): GroupedVitals =>
  vitals.reduce((acc, vital) => {
    const type = vital.type as ListPatientVitalsResponseVitalType;
    const timestamp = vital.timestamp as string;
    const date = new Date(timestamp).toISOString().split('T')[0];

    if (!acc[date]) {
      acc[date] = {};
    }

    if (!acc[date][type]) {
      acc[date][type] = [];
    }

    acc[date][type].push(vital);
    return acc;
  }, {} as GroupedVitals);
