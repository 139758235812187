import { type UseFormReturn, useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Button } from '@/shared/tempo/atom/Button';

import { RemovableRow } from '../RemovableRow';
import type { FormFields } from '../formConfig';
import { addItemButton, sectionTitle } from './carePlanSections.css';

type Props = {
  form: UseFormReturn<FormFields>;
  manualAutoSave: () => void;
};

export function MedicationAllergies({ form, manualAutoSave }: Props) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'medicationAllergies',
  });

  return (
    <Form.Section
      title={intl.formatMessage({ defaultMessage: 'Medication Allergies' })}
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <RemovableRow
          key={field.id}
          onChange={() => manualAutoSave()}
          onRemove={() => remove(index)}
          hasError={!!form.formState.errors.medicationAllergies?.[index]}
        >
          <Form.TextField
            required
            size={5}
            label={intl.formatMessage({
              defaultMessage: 'Medication',
            })}
            name={`medicationAllergies.${index}.medication`}
          />
          <Form.TextField
            required
            size={6}
            label={intl.formatMessage({
              defaultMessage: 'Reaction',
            })}
            name={`medicationAllergies.${index}.reaction`}
          />
        </RemovableRow>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() => append({ medication: '', reaction: '' })}
        >
          <FormattedMessage defaultMessage="Add Medication Allergy" />
        </Button>
      </div>
    </Form.Section>
  );
}
