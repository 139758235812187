import { NoteEditorFooter } from '../NoteEditorFooter';
import type { NoteFormValues, SetNoteFormValue } from '../noteFormState';

type Props = {
  noteFormValues: NoteFormValues;
  setNoteFormValue: SetNoteFormValue;
  hasProviderError: boolean;
};

export function EscalationFormFields({
  noteFormValues,
  setNoteFormValue,
  hasProviderError,
}: Props) {
  return (
    <NoteEditorFooter
      actionRequired={noteFormValues.actionRequired}
      urgent={noteFormValues.urgent}
      externalProviderId={noteFormValues.externalProviderId}
      clearNoteExternalProviderId={() =>
        setNoteFormValue('externalProviderId', undefined)
      }
      onActionRequiredChange={(actionRequired) =>
        setNoteFormValue('actionRequired', actionRequired)
      }
      onUrgentChange={(urgent) => setNoteFormValue('urgent', urgent)}
      setNoteExternalProviderId={(externalProviderId) =>
        setNoteFormValue('externalProviderId', externalProviderId)
      }
      hasError={hasProviderError}
    />
  );
}
