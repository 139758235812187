import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';

import { settings } from '@/config';
import { logger } from '@/logger';
import { store } from '@/store/store';

import {
  logout,
  refreshToken,
  refreshTokenFailure,
} from '../../pages/auth/actionCreators';
import { Check, isRefreshTokenNeeded } from './helpers';

function createAxiosInstance(accessToken?: string, contentType?: string) {
  const instance = axios.create({
    baseURL: settings.API_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      ...(contentType && { 'content-type': contentType }),
    },
  });

  // TODO: When we completely switch to cookies,`
  // remove header and set withCredentials to true.
  instance.defaults.withCredentials = false;
  return instance;
}

function createInterceptors(
  instance: AxiosInstance,
  tokens: { access_token: string },
) {
  // Intercepts requests to refresh access token if necessary
  instance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      // If access token has expired, pause execution of request until token
      // has been refreshed
      if (isRefreshTokenNeeded(Check.IMMEDIATELY, tokens.access_token)) {
        await makeRefreshRequest();

        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${
          store.getState().auth.token.access_token
        }`;
        // If access token will expire in 5 minutes, refresh token in background
      } else if (
        isRefreshTokenNeeded(Check.IN_NEXT_5_MIN, tokens.access_token)
      ) {
        makeRefreshRequest();
      }

      return config;
    },
  );

  // Intercepts responses to automatically log out user on 401
  instance.interceptors.response.use(undefined, (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        logger.warn('Request failed with status code 401, logging user out');
        store.dispatch(logout());
      }
    }
    if (error.message === 'Request failed with status code 401') {
      logger.warn('Request failed with status code 401, logging user out');
      store.dispatch(logout());
    }
    return Promise.reject(error);
  });
}

// eslint-disable-next-line import/no-default-export
export default {
  /**
   *  Returns an axios instance for use when making API calls
   */
  get Api() {
    const tokens = store.getState().auth.token;

    const instance = createAxiosInstance(tokens.access_token);

    createInterceptors(instance, tokens);

    return instance;
  },
};

const REFRESH_TRIES = 3;

export async function makeRefreshRequest() {
  const tokens = store.getState().auth.token;
  if (!tokens.refresh_token) {
    // No refresh token in state, so we cannot refresh
    store.dispatch(
      refreshTokenFailure(
        new Error('No refresh token, skipping refresh') as TResponseError,
      ),
    );
    return;
  }

  // Create a new axios instance where interceptors are not applied.
  // Otherwise a refresh request will invoke another refresh request,
  // causing an infinite loop
  const instanceWithoutInterceptors = createAxiosInstance(tokens.access_token);

  for (let i = 1; i <= REFRESH_TRIES; i++) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await instanceWithoutInterceptors.post(
        `${settings.API_URL}/auth/api/v1/refresh-token`,
        { refresh_token: tokens.refresh_token },
        {
          headers: {
            // Remove the Authorization header for refresh token request so Istio
            // doesn't prematurely reject the request.
            Authorization: undefined,
          },
        },
      );

      store.dispatch(refreshToken(response.data));
      return;
    } catch (err) {
      if (i === REFRESH_TRIES) {
        logger.info('Failed to refresh token. No remaining retries.', { err });
        store.dispatch(refreshTokenFailure(err as TResponseError));
        return;
      }

      logger.info('Refresh token failed. Retrying', { err });
    }
  }
}
