{{! This field template is exactly the same amongst all Condition Programs (Except for CHF) as of right now }}
{{! Therefore it is stored in shared to be re-used }}
{{> header val="Action Plan" }}
<p>Review action plan from last visit</p>
<p>- Patient comments on self-assessment of progress:</p>
<p>- Updated Patient self-defined action steps:</p>
<br/>
<p>My Health Goal</p>
<p>- The main thing I want to change about my health is</p>
<p>    <i>[choose existing from prior visit or from options below]</i></p>
<p>  - Become more physically active</p>
<p>  - Take my medications more consistently</p>
<p>  - Lower my weight</p>
<p>  - Improve my food choices</p>
<p>  - Reduce my stress</p>
<p>  - Better understand my disease</p>
<p>  - Get better sleep</p>
<p>  - Quit smoking</p>
<br/>

{{> subheader val="Patient self-defined action steps"}}
<p>Top things I can do to meet this health goal are:</p>
<p>1. Action step:</p>
<p>  a. Timing and Frequency:</p>
<br/>

{{> header val="Assessment and Plan" }}
{{> assessment_and_plan }}
<br/>

{{> subheader val="Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Status of Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Patient Clinical Goal Reached" }}
<p>[Yes or No and explanation]</p>
<p>If not reached, please choose reason</p>
<p>- Provider preferences</p>
<p>- Patient preferences</p>
<p>- Medical reason</p>
<p>- Financial reason</p>
{{> free_text_space }}

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet/Lifestyle Recommendation" }}
{{> free_text_space }}

{{> subheader val="Additional recommendations (labs/imaging/outreach to order physician)" }}
{{> free_text_space }}
