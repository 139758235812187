import cx from 'classnames';

import { Box, Typography } from '@/deprecated/mui';
import { actionStrong } from '@/shared/jsStyle/typography.css';
import { displayVitalTimestamp } from '@/shared/patient/datetimeUtils';
import { color, typography } from '@/shared/tempo/theme';
import type { AllTagType } from '@/shared/types/tagsAndThreshold.types';

import { fullWidthColumn, fullWidthRow } from './AlertDescription.styles';
import type { AlertMessageFormatterProps } from './AlertDescription.types';
import { verifiedTagTitleStyle } from './AlertDescriptionRow.css';
import type { AlertFormatter } from './utils/alertDescriptionFormatters';

export const AlertDescriptionRow = ({
  isTagVerified = false,
  tag,
  readings,
  timestamp,
  formatter,
}: {
  isTagVerified?: boolean;
  tag: AllTagType;
  readings: AlertMessageFormatterProps;
  timestamp: string;
  formatter: AlertFormatter<AllTagType, AlertMessageFormatterProps>;
}) => {
  const tagTitle = formatter.getTitle(tag, timestamp);

  const description = formatter.getDescription(tag, readings);
  return (
    <Box sx={fullWidthColumn}>
      <span
        className={cx(actionStrong, {
          [verifiedTagTitleStyle]: isTagVerified,
        })}
      >
        {tagTitle}
      </span>
      <Box sx={fullWidthRow}>
        <Typography
          style={{
            ...typography.Body.Default,
            color: color.Theme.Light['Base Font Subtle'],
          }}
        >
          <span data-testid="alert-description-test-id">{description}</span>
        </Typography>
      </Box>
      <Box
        sx={[typography.Body.Small, { color: color.Theme.Light['Base Font'] }]}
      >
        {displayVitalTimestamp(timestamp)}
      </Box>
    </Box>
  );
};
