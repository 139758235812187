import cx from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Label } from '@/shared/tempo/atom/Label';
import { TimePeriod, map24HTimeFormatTo12H } from '@/shared/utils/time-helpers';

import { TimeInputs } from './TimeInputs';
import {
  subContentFooter,
  timeSelectionsContainer,
} from './VitalReminders.css';

export type Props = {
  vitalRemindersOn: boolean;
  loading: boolean;
  time: string | null;
  onSave: (hour: string, ampm: TimePeriod) => void;
};

export function VitalRemindersTimeEdit({
  vitalRemindersOn,
  loading,
  time,
  onSave,
}: Props) {
  const intl = useIntl();
  const [vitalHour, setVitalHour] = useState('8');
  const [vitalTimeAmPm, setVitalTimeAmPm] = useState(TimePeriod.AnteMeridiem);

  function isVitalTimeInputsUnChanged() {
    if (time && vitalRemindersOn) {
      const [hour, ampm] = map24HTimeFormatTo12H(time);
      return hour === vitalHour && ampm === vitalTimeAmPm;
    }
    return false;
  }

  useEffect(() => {
    if (time) {
      const [hour, ampm] = map24HTimeFormatTo12H(time);
      setVitalHour(hour);
      setVitalTimeAmPm(ampm);
    }
  }, [time]);

  return (
    <div className={cx(flexContainer.column, gap.XS)}>
      <Label
        label={
          <FormattedMessage defaultMessage="Set a time for SMS reminders" />
        }
      />
      <div>
        <div className={timeSelectionsContainer}>
          <TimeInputs
            disabled={loading}
            initAMPM={vitalTimeAmPm}
            initHour={vitalHour}
            onAMPMChange={(ampm) => setVitalTimeAmPm(ampm)}
            onHourChange={(hour) => setVitalHour(hour)}
          />
          <Button
            id="save-vitals-reminders"
            variant="primary"
            isDisabled={isVitalTimeInputsUnChanged()}
            isProcessing={loading}
            onPress={() => onSave(vitalHour, vitalTimeAmPm)}
          >
            {intl.formatMessage({ defaultMessage: 'Save' })}
          </Button>
        </div>
        <p className={subContentFooter}>
          {intl.formatMessage({
            defaultMessage:
              'A reminder will be sent if no vitals in current or prior day',
          })}
        </p>
      </div>
    </div>
  );
}
