import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export function useJitQueryEnabler() {
  const [enabled, setEnabled] = useState(false);
  const [inViewRef, isInView] = useInView();

  useEffect(() => {
    // we only care about setting this to true - not looking
    // to disable the query when we move out of view
    if (isInView) {
      setEnabled(true);
    }
  }, [isInView]);

  return { queryEnabled: enabled, inViewRef };
}
