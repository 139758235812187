import { type IntlShape } from 'react-intl';

import { type FormConfig } from '@/shared/common/Form';
import { getValidationSchema } from '@/shared/common/Form/form.utils';
import { validators } from '@/shared/common/Form/validations';

import {
  type ClinicalGoalReachedFormFields,
  type ClinicalGoalReachedInputs,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
  GoalUnmetReason,
  HfType,
} from '../../Notes.types';

export function getFormConfig(
  intl: IntlShape,
  isValidationEnabled: boolean,
  isChf: boolean,
  isT2d: boolean,
  isHtn: boolean,
  {
    systolicGoal,
    diastolicGoal,
    a1cGoal,
    bgGoal,
  }: Pick<
    ClinicalGoalReachedInputs,
    'systolicGoal' | 'diastolicGoal' | 'a1cGoal' | 'bgGoal'
  >,
  initialValues: ClinicalGoalReachedFormFields = {},
  isLongErrorMessaging: boolean = false,
) {
  const { required, maxLengthString, enumType, boolean, array } =
    validators(intl);
  const getMessageVariant = (longMessage: string) =>
    isLongErrorMessaging
      ? longMessage
      : intl.formatMessage({ defaultMessage: 'Required' });
  const formConfig: FormConfig = {
    fields: {
      hfType: {
        defaultValue: initialValues.hfType ?? undefined,
        validation: enumType({
          source: HfType,
          pluck: 'values',
          emptyValue: '',
        }).when([], {
          is: () => isChf,
          then: (schema) =>
            required({
              schema,
              errorMessage: getMessageVariant(
                intl.formatMessage({
                  defaultMessage:
                    'Heart failure (reduced or preserved) is required',
                }),
              ),
            }),
        }),
      },
      isBpGoalMet: {
        defaultValue: initialValues.isBpGoalMet ?? undefined,
        validation: boolean().when('hfType', {
          is: (hfType?: HfType) =>
            systolicGoal &&
            diastolicGoal &&
            (hfType === HfType.Hfpef || (isHtn && !isT2d)),
          then: (schema) =>
            required({
              schema,
              errorMessage: getMessageVariant(
                intl.formatMessage({
                  defaultMessage: 'Blood pressure goal is required',
                }),
              ),
            }),
        }),
      },
      bpGoalDetails: {
        defaultValue: initialValues.bpGoalDetails ?? undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
      isBgGoalMet: {
        defaultValue: initialValues.isBgGoalMet ?? undefined,
        validation: boolean().when([], {
          is: () => bgGoal && a1cGoal && isT2d && !isHtn,
          then: (schema) =>
            required({
              schema,
              errorMessage: getMessageVariant(
                intl.formatMessage({
                  defaultMessage: 'Blood glucose goal is required',
                }),
              ),
            }),
        }),
      },
      bgGoalDetails: {
        defaultValue: initialValues.bgGoalDetails ?? undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
      isBgBpGoalMet: {
        defaultValue: initialValues.isBgBpGoalMet ?? undefined,
        validation: boolean().when([], {
          is: () =>
            bgGoal &&
            a1cGoal &&
            systolicGoal &&
            diastolicGoal &&
            isHtn &&
            isT2d,
          then: (schema) =>
            required({
              schema,
              errorMessage: getMessageVariant(
                intl.formatMessage({
                  defaultMessage:
                    'Blood pressure and blood glucose goal is required',
                }),
              ),
            }),
        }),
      },
      bgBpGoalDetails: {
        defaultValue: initialValues.bgBpGoalDetails ?? undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
      isGdmtGoalMet: {
        defaultValue: initialValues.isGdmtGoalMet ?? undefined,
        validation: boolean().when([], {
          is: () => isChf,
          then: (schema) =>
            required({
              schema,
              errorMessage: getMessageVariant(
                intl.formatMessage({ defaultMessage: 'GDMT goal is required' }),
              ),
            }),
        }),
      },
      gdmtGoalDetails: {
        defaultValue: initialValues.gdmtGoalDetails ?? undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
      reasonsGoalUnmet: {
        defaultValue: initialValues.reasonsGoalUnmet ?? undefined,
        validation: array({
          ofType: enumType({ source: GoalUnmetReason, pluck: 'values' }),
          minLength: 1,
          minLengthMessage: getMessageVariant(
            intl.formatMessage({
              defaultMessage: 'Reason is required',
            }),
          ),
        }).when(
          [
            'isBgGoalMet',
            'isGdmtGoalMet',
            'isBpGoalMet',
            'isBgBpGoalMet',
            'hfType',
          ],
          {
            is: (
              isBgGoalMet?: boolean,
              isGdmtGoalMet?: boolean,
              isBpGoalMet?: boolean,
              isBgBpGoalMet?: boolean,
              hfType?: HfType,
            ) =>
              (isChf &&
                (isGdmtGoalMet === false ||
                  (hfType === HfType.Hfpef && isBpGoalMet === false))) ||
              (isHtn && !isT2d && isBpGoalMet === false) ||
              (isT2d && !isHtn && isBgGoalMet === false) ||
              (isHtn && isT2d && isBgBpGoalMet === false),
            then: (schema) =>
              required({
                schema,
                errorMessage: getMessageVariant(
                  intl.formatMessage({
                    defaultMessage: 'Reason is required',
                  }),
                ),
              }),
          },
        ),
      },
      otherReasonDetails: {
        defaultValue: initialValues.otherReasonDetails ?? undefined,
        validation: maxLengthString({ maxLength: 255 }).when(
          ['reasonsGoalUnmet'],
          {
            is: (reasons?: GoalUnmetReason[]) =>
              reasons?.includes(GoalUnmetReason.Other),
            then: (schema) =>
              required({
                schema,
                errorMessage: getMessageVariant(
                  intl.formatMessage({
                    defaultMessage: 'Other reason details are required',
                  }),
                ),
              }),
          },
        ),
      },
    },
  };
  if (!isValidationEnabled) {
    Object.values(formConfig.fields).forEach((field) => {
      // eslint-disable-next-line no-param-reassign
      delete field.validation;
    });
  }
  return formConfig;
}

export function getClinicalGoalReachedFormYupSchema(
  intl: IntlShape,
  inputs: ClinicalGoalReachedInputs,
  encounterTypeInstance?: EncounterModuleInstance<EncounterTypeInputs>,
) {
  const { isChf, isT2d, isHtn, systolicGoal, diastolicGoal, a1cGoal, bgGoal } =
    inputs;
  const formConfig = getFormConfig(
    intl,
    !encounterTypeInstance?.inputs.patient_no_show,
    isChf ?? false,
    isT2d ?? false,
    isHtn ?? false,
    { systolicGoal, diastolicGoal, a1cGoal, bgGoal },
    {},
    true,
  );
  return getValidationSchema(formConfig.fields);
}
