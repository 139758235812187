{{> header val="General Assessment" }}
<br/>

{{> header val="GOALS AND INTERVENTIONS" }}
<br/>
<p><strong>Chronic Condition #1:</strong> </p>
<p><strong>Treatment Goal:</strong> </p>
<p><strong>Self-Management Goals:</strong> </p>
<p><strong>Coordination of Care/Resources:</strong> </p>
<br/>

{{> header val="Psychosocial Assessment/Social Determinants of Need" }}
<p>Domain: </p>
<p>Resource Provided: </p>
<p>Next Steps: </p>
<p>Domains assessed with no current need: </p>
<p>Physical Activity, Transportation, Housing, Interpersonal Safety, Food Insecurity, Utility Help</p>
<br/>

{{> header val="Action Plan" }}
<p>My Health Goal</p>
<p>- The main thing I want to change about my health is</p>
<br/>

<p>Patient self-defined action step:</p>
<br/>

{{> subheader val="Follow Up" }}
<ul>
  <li>Date and time for next follow-up with my chronic care manager: </li>
  <li>Date and time for next appointment with PCP: </li>
  <li>Date and time for next appointment with Specialists (as applicable): </li>
</ul>
<br/>
