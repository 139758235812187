import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { initVisitPath } from '../../initialVisit/paths';
import { CHF_VISIT_SECTIONS } from '../../metadata';
import { LastDiscussed } from '../../shared/LastDiscussed';
import { DailyLivingForm } from '../../shared/generalAssessment';
import type { DailyLivingFormFields } from '../../shared/generalAssessment/formConfig';
import { getDailyLivingFormConfig } from '../../shared/generalAssessment/formConfig';
import { chfVisitPath } from '../paths';

export function DailyLiving() {
  const intl = useIntl();

  const path = chfVisitPath('/general-assessment', '/daily-living');
  const { isLoading: isLoadingPrevDailyLiving, data } =
    useRecentNoteFormData<DailyLivingFormFields>([
      path,
      initVisitPath('/general-assessment', '/daily-living'),
    ]);
  const form = useWizardFormFromConfig<DailyLivingFormFields>(path, {
    ...getDailyLivingFormConfig(intl, data),
    triggerReset: !isLoadingPrevDailyLiving,
  });

  return (
    <Wizard.Step
      sections={CHF_VISIT_SECTIONS}
      form={form}
      isLoading={isLoadingPrevDailyLiving}
      title={intl.formatMessage({
        defaultMessage: 'Activities of Daily Living',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Finally, have you had any significant changes in your functional status, like any new assistance with walking, eating, using the bathroom, or getting dressed?" />
        </Script>
        <LastDiscussed hasData={!!data}>
          <DailyLivingForm
            form={form}
            requiresAssistanceLabel={
              <FormattedMessage defaultMessage="Do you require any assistance with walking, eating, using the bathroom, or getting dressed?" />
            }
          />
        </LastDiscussed>
      </FieldGroup>
    </Wizard.Step>
  );
}
