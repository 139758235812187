import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedScheduling } from '../../shared/Scheduling';
import { cycle17InitVisitPath } from '../paths';

export function Scheduling() {
  return (
    <SharedScheduling
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      nextPath={cycle17InitVisitPath('/time-tracking', '/index')}
    />
  );
}
