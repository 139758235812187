import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { useDeleteCarePlan } from '@/shared/hooks/queries/carePlan.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

type Props = {
  carePlanName: string;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export function DeleteModal({ carePlanName, onClose, onDeleteSuccess }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { mutate: deleteCarePlan } = useDeleteCarePlan({
    onError() {
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to delete care plan' }),
      );
    },
    onSuccess: onDeleteSuccess,
  });

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="Delete this care plan" />}
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="Are you sure you want to delete this care plan? This action cannot be reversed and all data associated with this care plan will be deleted." />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={() => onClose()}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button onPress={() => deleteCarePlan(carePlanName)}>
          <FormattedMessage defaultMessage="Delete" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
