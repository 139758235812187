import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { Modal } from '@/shared/common/Modal';
import { Button } from '@/shared/tempo/atom/Button';
import { Link } from '@/shared/tempo/atom/Link';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  carePlanId: string | undefined;
};

export function WarnAssociatedCarePlanDialog({
  isOpen,
  onClose,
  isLoading,
  carePlanId,
}: Props) {
  const { url } = useRouteMatch();
  return (
    <Modal open={isOpen} onClose={onClose} isLoading={isLoading || !carePlanId}>
      <Modal.Header
        title={
          <FormattedMessage defaultMessage="Encounter is associated with a care plan" />
        }
      />
      <Modal.Body>
        <FormattedMessage
          defaultMessage="This encounter is associated with a care plan. Please delete the <link>associated care plan</link> in order to change the encounter type."
          values={{
            link: (text: string) => (
              <Link.Routed
                onPress={() =>
                  setTimeout(() => {
                    onClose();
                  }, 100)
                }
                to={`${url}/care-plan/${carePlanId}`}
              >
                {text}
              </Link.Routed>
            ),
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
