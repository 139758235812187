import omit from 'lodash/omit';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { usePatientMedicationsContext } from '../PatientMedicationsContext';
import {
  useDenyMedication,
  useReviewMedication,
} from '../patientMedications.queries';
import { isUnreferenced } from '../utils/medChangeUtils';
import { PatientNotesField } from './MedicationForm';
import type { RemoveMedFormFields } from './removeMedicationFormConfig';
import { useRemoveMedicationForm } from './removeMedicationFormConfig';
import {
  formHeading,
  medFormField,
  medFormInput,
  medicationForm,
} from './styles.css';

export function RemoveMedicationForm({
  medChange,
  isDiseaseSpecific,
  medChangeId,
  onClose,
  onSuccess,
}: {
  medChange: MedicationChange;
  isDiseaseSpecific: boolean;
  medChangeId: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { noteId } = usePatientMedicationsContext();
  const form = useRemoveMedicationForm();

  const denyMedication = useDenyMedication(medChangeId, onSuccess);
  const reviewMedication = useReviewMedication(onSuccess);

  function handleSubmit(values: RemoveMedFormFields) {
    const payload = {
      reason: values.reason,
      endDate: values.endDate?.toISOString(),
      noteId: noteId || undefined,
    };

    if (
      medChange.changeType === MedicationChangeChangeType.EMR_CAPTURE ||
      isUnreferenced(medChange.rxnorm)
    ) {
      denyMedication.mutate(payload, {
        onSuccess: onClose,
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to remove medication: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      });
      return;
    }

    reviewMedication.mutate(
      {
        ...omit(medChange, 'rxnorm'),
        ...payload,
        status: MedicationChangeStatus.INACTIVE,
        medicationChangeId:
          noteId === medChange.noteId ? medChangeId : undefined,
        rxnormId: medChange.rxnorm?.id,
      },
      {
        onSuccess: onClose,
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to remove medication: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  // Force the form to be dirty initially to make the form submittable if no fields are filled out.
  // This is needed because none of the fields are required
  useEffect(() => {
    form.setValue('reason', '', { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={formHeading}>
        <FormattedMessage defaultMessage="Mark not active" />
      </div>
      <Form form={form} onSubmit={handleSubmit}>
        {({ canSubmit }) => (
          <div className={medicationForm}>
            <PatientNotesField />
            {isDiseaseSpecific && (
              <Form.DatePicker
                className={medFormField}
                name="endDate"
                label={intl.formatMessage({ defaultMessage: 'End date' })}
                inputClass={medFormInput}
              />
            )}

            <Form.Actions direction="row-reverse">
              <Button
                variant="primary"
                size="small"
                onPress={() => form.handleSubmit(handleSubmit)()}
                isDisabled={!canSubmit}
              >
                <FormattedMessage defaultMessage="Submit" />
              </Button>
              <Button variant="secondary" size="small" onPress={onClose}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
            </Form.Actions>
          </div>
        )}
      </Form>
    </>
  );
}
