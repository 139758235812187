import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { logger } from '@/logger';
import { ErrorScene } from '@/shared/common/ErrorScene';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { Button } from '@/shared/tempo/atom/Button';

import { reportIssueButton } from './RouteNotFound.css';

export function RouteNotFound() {
  const { location } = useHistory();
  useOnMount(() => {
    logger.error(
      `Wizard attempted to navigate to path '${location.pathname}' that does not have a corresponding routed component.`,
    );
  });

  return (
    <ErrorScene>
      {(openReport) => (
        <>
          <FormattedMessage defaultMessage="If the problem persists, report the error below so we can address it. We apologize for the inconvenience." />
          <Button
            variant="primary"
            size="default"
            onPress={openReport}
            className={reportIssueButton}
          >
            <FormattedMessage defaultMessage="Report error" />
          </Button>
        </>
      )}
    </ErrorScene>
  );
}
