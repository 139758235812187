import { format, isValid, parseISO } from 'date-fns';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

export function formatDate(iso_date: string) {
  const parsedDate = parseISO(iso_date);
  if (isValid(parsedDate)) {
    return format(parsedDate, 'MM/dd/yyyy');
  }
  return 'Invalid date';
}

/**
 * Joins a list of FormattedMessages grammatically
 *
 * @param {Array} formattedMessages - List of messages to join
 * @param {string} textToPrepend - Text to prepend to the joined phrase
 * @returns {string} Joined phrase, e.g. "apple and orange" or "apple, orange
 * and banana"
 */
export function joinMessagesGrammatically(
  formattedMessages: JSX.Element[],
  textToPrepend?: JSX.Element,
) {
  if (formattedMessages.length === 0) {
    return null;
  }

  let result: (JSX.Element | string)[] = [
    <FormattedMessage
      defaultMessage="{textToPrepend}{reason}"
      values={{ reason: formattedMessages[0], textToPrepend }}
    />,
  ];

  if (formattedMessages.length === 1) {
    return result;
  }

  if (formattedMessages.length > 2) {
    for (let i = 1; i < formattedMessages.length - 1; i++) {
      result = result.concat(
        <FormattedMessage
          defaultMessage=", {message}"
          values={{ message: formattedMessages[i] }}
        />,
      );
    }
  }

  result = result.concat(
    <FormattedMessage
      defaultMessage=" and {message}"
      values={{ message: formattedMessages[formattedMessages.length - 1] }}
    />,
  );

  return result;
}

export function joinMessages(
  formattedMessages: Nullable<JSX.Element | string>[],
) {
  const filtered = formattedMessages.filter(Boolean) as (
    | JSX.Element
    | string
  )[];

  return filtered.reduce((result: JSX.Element[], message, i) => {
    const key =
      typeof message === 'string'
        ? `message-${i}-${message}`
        : message.props.id;

    if (i === 0) {
      return result.concat(
        <Fragment key={key}>
          <FormattedMessage defaultMessage="{message}" values={{ message }} />
        </Fragment>,
      );
    }

    return result.concat(
      <Fragment key={key}>
        <FormattedMessage defaultMessage=", {message}" values={{ message }} />
      </Fragment>,
    );
  }, []);
}
