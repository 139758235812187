import { FormattedMessage } from 'react-intl';

import { vitalType, vitalTypeCapitalized } from '../vitalEducation/scriptUtils';
import type {
  ScriptContext,
  VitalAvgLevelScriptMap,
} from '../vitalEducation/types';
import { ConditionProgram, VitalAverageLevel } from '../vitalEducation/types';

export const AP_COMPLETION_SCRIPTS: VitalAvgLevelScriptMap = {
  [VitalAverageLevel.One]: ({ conditionProgram }: ScriptContext) => [
    <FormattedMessage
      defaultMessage="And, as a reminder, {vital} control is a lifelong process. Even though it is under great control, keeping it that way is key to a happier and healthier life. So let's continue to touch base on your progress, follow up on your action steps, and continue your journey of great {vital} control."
      values={{ vital: vitalType(conditionProgram) }}
    />,
    <FormattedMessage defaultMessage="Congratulations and let's keep up the amazing work." />,
  ],
  [VitalAverageLevel.Two]: ({ conditionProgram }: ScriptContext) => (
    <FormattedMessage
      defaultMessage="And, as a reminder, {vital} control is a lifelong process. Even though it is under great control, keeping it that way is key to a happier and healthier life. So lets continue to touch base on your progress, follow up on your action steps, and continue your journey of great {vital} control."
      values={{ vital: vitalType(conditionProgram) }}
    />
  ),
  [VitalAverageLevel.Three]: ({ conditionProgram }: ScriptContext) => {
    const phrase =
      conditionProgram === ConditionProgram.TYPE_2_DIABETES ? (
        <FormattedMessage defaultMessage="BG still remains between 184-200" />
      ) : (
        <FormattedMessage defaultMessage="BP still remains between 140 to 160 over 90 to 100" />
      );

    return [
      <FormattedMessage
        defaultMessage="We will continue to work together to track progress and focus on the key “improvement” opportunities noted above (and part of your action goals). If we have tried lifestyles changes and your {phrase}, we may consider referring you to one of our Cadence Nurse Practitioners, who is in close contact with your Provider and can add/adjust medications to help get your {vital} under better control if we need a little more help."
        values={{ phrase, vital: vitalType(conditionProgram) }}
      />,
      <FormattedMessage
        defaultMessage="The good news is that your {vital} is not far away from our target. Let's work together through education, support, monitoring, and lifestyle changes to hit the targets. {vitalCap} control is a lifelong process. Getting your {vital} under better control can be a key part in creating a happier and healthier life."
        values={{
          vitalCap: vitalTypeCapitalized(conditionProgram),
          vital: vitalType(conditionProgram),
        }}
      />,
      <FormattedMessage
        defaultMessage="So let's continue to touch base on your progress, follow up on your action steps, and continue your journey toward reaching {vital} control."
        values={{ vital: vitalType(conditionProgram) }}
      />,
    ];
  },
  [VitalAverageLevel.Four]: ({ conditionProgram }: ScriptContext) => [
    <FormattedMessage defaultMessage='We will continue to work together to track progress and focus on the key "improvement" opportunities noted above.' />,
    <FormattedMessage
      defaultMessage="{vitalCapitalized} control is a lifelong process. Getting your {vital} under better control can be a key part in creating a happier and healthier life.  So let's continue to touch base on your progress, follow up on your action steps, and continue your journey toward reaching {vital} control."
      values={{
        vital: vitalType(conditionProgram),
        vitalCapitalized: vitalTypeCapitalized(conditionProgram),
      }}
    />,
  ],
  [VitalAverageLevel.Five]: ({ vitalAvg }: ScriptContext) => (
    <FormattedMessage
      defaultMessage="This patient's vital average is {vitalAvg} and should be escalated to an NP"
      values={{ vitalAvg }}
    />
  ),
  [VitalAverageLevel.Unknown]: ({ conditionProgram }: ScriptContext) => (
    <FormattedMessage
      defaultMessage="And, as a reminder, {vital} control is a lifelong process. Let's continue to touch base on your progress, follow up on your action steps, and continue your journey toward reaching {vital} control."
      values={{ vital: vitalType(conditionProgram) }}
    />
  ),
};
