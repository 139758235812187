import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { CarePlanDetail } from './CarePlanDetail';
import { CarePlanForm } from './CarePlanForm';
import { CarePlanRedirect } from './CarePlanRedirect';

export function CarePlanTab() {
  const { path } = useRouteMatch<{ patientId: string }>();

  return (
    <Switch>
      <Route path={`${path}/draft`} component={CarePlanForm} />
      <Route path={`${path}/:carePlanId`} component={CarePlanDetail} />
      <Route path={path} exact component={CarePlanRedirect} />
      <Redirect from="*" to={path} />
    </Switch>
  );
}
