import { isValid } from 'date-fns';

import { DatePicker } from '@/shared/common/DatePicker';

import { calendarSelect } from './styles.css';
import { useDateValidators } from './useDateValidators';

type Props = {
  value: Date;
  onChange: (date: Date) => void;
};

export function CalendarSelect({ value, onChange }: Props) {
  const { today, isValidDateSelection } = useDateValidators();
  return (
    <DatePicker
      classes={{ input: calendarSelect.input }}
      onChange={(date) => {
        if (
          date instanceof Date &&
          date &&
          isValid(date) &&
          isValidDateSelection(date)
        ) {
          onChange(date);
        }
      }}
      minDate={today}
      value={value}
    />
  );
}
