import type { FieldProps, WidgetProps } from '@rjsf/core';
import type { EditorState } from 'draft-js';
import { convertToRaw } from 'draft-js';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';

import { EMPTY_RTF_BODY } from '@/pages/patients/patientDetails/ui/Notes/utils/rtfBodyUtil';
import { EditorRTF } from '@/shared/common/EditorRTF';
import { convertToEditorState } from '@/shared/common/EditorRTF/utils';
import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';
import { hasValidationErrorFormContext } from '../validationErrorUtils';
import { rtfEditorWidget } from './RichTextEditorWidget.css';

export function RichTextEditorWidget(options: WidgetProps | FieldProps) {
  const { formContext } = options;
  return <RichTextEditorWidgetInner {...options} key={formContext?.key} />;
}

function RichTextEditorWidgetInner(options: WidgetProps | FieldProps) {
  const {
    onChange: rjsfOnChange,
    value: initialValue,
    formContext,
    label,
    id,
    formData,
  } = options;
  const fieldValue = initialValue || formData;
  const initialRtfBodyValue: TNoteBodyRTF = isEmpty(fieldValue)
    ? EMPTY_RTF_BODY
    : fieldValue;

  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  // The initialRtfBodyValue is used only at first render, at which point
  // we manage the state internally. A re-mount of the component must take place
  // if we want to reset to a new initialRtfBodyValue
  const [editorState, setEditorState] = useState(
    convertToEditorState(initialRtfBodyValue),
  );
  const hasError = hasValidationErrorFormContext(formContext, label, id);

  const debouncedOnChange = useMemo(
    () =>
      debounce((newEditorState) => {
        // If empty string then pass undefined so rjsf treats it as no value.
        // It's required for proper validation of required texts fields.
        if (isEditorStateEmpty(newEditorState)) {
          onChange(undefined);
        } else {
          const newValue = convertToRaw(newEditorState.getCurrentContent());
          onChange(newValue);
        }
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <EditorRTF
      className={rtfEditorWidget}
      editorState={editorState}
      onEditorChange={(newEditorState) => {
        setEditorState(newEditorState);
        debouncedOnChange(newEditorState);
      }}
      fullWidth
      hasError={hasError}
    />
  );
}

function isEditorStateEmpty(editorState: EditorState) {
  return !editorState.getCurrentContent().hasText();
}
