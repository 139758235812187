import type { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';

import type { FormFields } from '../formConfig';
import { getPreventativeCareFieldNames } from '../formConfig';
import { preventativeCareGap, sectionTitle } from './carePlanSections.css';
import { PREVENTATIVE_CARE_OPTIONS } from './preventativeCareOptions';

type Props = {
  form: UseFormReturn<FormFields>;
};

export function PreventativeCareGaps({ form }: Props) {
  const intl = useIntl();
  const values = form.watch();
  const fields = PREVENTATIVE_CARE_OPTIONS.map((option) => ({
    ...option,
    ...getPreventativeCareFieldNames(option.name),
  })).filter(
    ({ checked, notApplicable }) =>
      values[checked] === false && !values[notApplicable],
  );

  // TODO: empty state?
  if (!fields.length) {
    return null;
  }

  return (
    <Form.Section
      title={intl.formatMessage({ defaultMessage: 'Preventative Care Gaps' })}
      classes={{ title: sectionTitle }}
    >
      {fields.map(({ nextSteps, label }) => (
        <Form.TextField
          className={preventativeCareGap.field}
          inputClass={preventativeCareGap.input}
          size={11}
          key={`${label}-${nextSteps}`}
          name={nextSteps}
          label={label}
        />
      ))}
    </Form.Section>
  );
}
