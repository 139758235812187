import { format, isValid, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';

import CloseCircleIcon from '@/shared/assets/svgs/closeCircle.svg?react';
import InboundCallIcon from '@/shared/assets/svgs/inbound-call.svg?react';
import OutboundCallIcon from '@/shared/assets/svgs/outbound-call.svg?react';
import { Tag } from '@/shared/common/Tag';
import type { Conference } from '@/shared/generated/grpcGateway/call.pb';
import { CallDirection } from '@/shared/generated/grpcGateway/call.pb';
import { useRemoveConferenceNote } from '@/shared/hooks/queries/conference.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import {
  callTrackingDetails,
  callTrackingMainDetails,
  callTrackingMainRow,
  callTrackingMins,
  removeCallIcon,
} from './CallTracking.css';

type Props = {
  conference: Conference;
  noteId: number;
  onRemoveNoteAssoc: () => void;
};

export function CallTrackingRow({
  conference,
  noteId,
  onRemoveNoteAssoc,
}: Props) {
  const { currentUserId } = useCurrentUser();
  const intl = useIntl();
  const { toaster } = useToaster();

  const currentProviderLeg = [
    conference.mainCareProviderLeg,
    ...(conference.transferLegs || []),
  ].find((leg) => leg?.careProvider?.id === currentUserId);

  const duration = currentProviderLeg
    ? currentProviderLeg.calculatedCallDuration
    : 0;

  const conferenceSid = conference.name ?? '';
  const callTime = parseISO(conference.patientStartTime ?? '');

  const removeConferenceNote = useRemoveConferenceNote(
    noteId,
    conferenceSid,
    onRemoveNoteAssoc,
  );

  function handleUndo() {
    removeConferenceNote.mutate(undefined, {
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: 'Failed to remove {conferenceSid}: {message}',
            },
            { conferenceSid, message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }

  return (
    <div className={callTrackingMainRow}>
      <IconButton variant="tertiary" size="small" onPress={handleUndo}>
        <CloseCircleIcon className={removeCallIcon} />
      </IconButton>
      <div className={callTrackingDetails}>
        <div className={callTrackingMainDetails}>
          {conference.direction === CallDirection.INBOUND ? (
            <InboundCallIcon />
          ) : (
            <OutboundCallIcon />
          )}
          {isValid(callTime) && format(callTime, "MM/dd/yyyy 'at' h:mm a")}
        </div>
        <Tag>{conference.state}</Tag>
      </div>
      <div className={callTrackingMins}>{formatRowDuration(duration || 0)}</div>
    </div>
  );
}

function formatRowDuration(seconds: number) {
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);

  return `${minutes} mins`;
}
