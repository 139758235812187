import { useRef } from 'react';
import type { AriaPopoverProps } from 'react-aria';
import { Overlay, usePopover } from 'react-aria';
import { FormattedMessage, useIntl } from 'react-intl';
import type { OverlayTriggerState } from 'react-stately';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import CloseIcon from '@/shared/common/Modal/close.svg?react';
import { useCreateTask } from '@/shared/hooks/queries/tasks.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { color } from '@/shared/tempo/theme';

import { TaskForm } from '../TaskModal/TaskForm';
import {
  type FormFields,
  formDataToTaskPayload,
  getFormConfig,
} from '../TaskModal/form.config';
import { footer, header, overlay } from './CreateTaskOverlay.css';

type Props = {
  patientId: string;
  state: OverlayTriggerState;
} & Omit<
  AriaPopoverProps,
  'popoverRef' | 'placement' | 'isKeyboardDismissDisabled' | 'isNonModal'
>;

export function CreateTaskOverlay({ patientId, state, ...props }: Props) {
  const ref = useRef(null);
  const { popoverProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
      placement: 'bottom end',
      isKeyboardDismissDisabled: true,
      isNonModal: true,
      // sidebar width (66px) + spacing.S (12px). can't use tokens because
      // react-aria requires a number. this ensures we don't render any of
      // the overlay under the sidebar.
      containerPadding: 78,
    },
    state,
  );
  const intl = useIntl();
  const { toaster } = useToaster();
  const form = useFormFromConfig<FormFields>(getFormConfig(intl));
  const { mutate: createTask, isLoading: isSaving } = useCreateTask({
    onSuccess: () => {
      toaster.success(intl.formatMessage({ defaultMessage: 'Task created' }));
      state.close();
    },
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to create task' }),
      ),
  });
  const selectedTaskType = form.watch('taskTypeId');
  const onSubmit = (data: FormFields) => {
    const payload = formDataToTaskPayload(data, patientId);
    createTask(payload);
  };

  return (
    <Overlay>
      <div
        {...popoverProps}
        ref={ref}
        className={overlay}
        // react-aria puts a huge z-index on this popover, which causes
        // child elements to render underneath. unsetting the z-index seems
        // to fix this.
        style={{ ...popoverProps.style, zIndex: 'initial' }}
      >
        <div className={header}>
          <FormattedMessage defaultMessage="Task Details" />
          <IconButton size="small" variant="tertiary" onPress={state.close}>
            <CloseIcon fill={color.Theme.Light['Base Font']} />
          </IconButton>
        </div>
        <TaskForm form={form} onSubmit={onSubmit} />
        {selectedTaskType && (
          <div className={footer}>
            <Button variant="secondary" onPress={state.close}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              variant="primary"
              type="submit"
              isProcessing={isSaving}
              isDisabled={!form.canSubmit}
              onPress={() => onSubmit(form.getValues())}
            >
              <FormattedMessage defaultMessage="Create Task" />
            </Button>
          </div>
        )}
      </div>
    </Overlay>
  );
}
