import { format } from 'date-fns';
import * as yup from 'yup';

import { validator } from './factory';

type DateValidatorParams = Maybe<{
  maxDate?: Date;
  minDate?: Date;
  maxDateErrorMessage?: string;
  minDateErrorMessage?: string;
  invalidDateErrorMessage?: string;
}>;

export const dateValidator = validator((intl, params: DateValidatorParams) => {
  const errorMessage =
    params?.invalidDateErrorMessage ??
    intl.formatMessage({
      defaultMessage: 'Must be a valid date',
    });

  let schema = yup.date().typeError(errorMessage);

  if (params?.maxDate) {
    const message =
      params.maxDateErrorMessage ||
      intl.formatMessage(
        { defaultMessage: 'Must be before {maxDate}' },
        { maxDate: format(params.maxDate, 'MM/dd/yyyy') },
      );
    schema = schema.max(params.maxDate, message);
  }

  if (params?.minDate) {
    const message =
      params.minDateErrorMessage ||
      intl.formatMessage(
        { defaultMessage: 'Must be after {minDate}' },
        { minDate: format(params.minDate, 'MM/dd/yyyy') },
      );
    schema = schema.min(params.minDate, message);
  }

  return schema.nullable();
});
