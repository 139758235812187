import cx from 'classnames';
import { useRef } from 'react';
import { FocusRing, useOption } from 'react-aria';
import type { ListState, Node, SelectionMode } from 'react-stately';

import { Checkbox } from '@/shared/tempo/atom/Checkbox';
import {
  option,
  optionFocusOutline,
} from '@/shared/tempo/shared/styles/listStyles.css';
import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { MultiSelect } from '../../molecule/MultiSelect';

type Props<T> = {
  item: Node<T>;
  state: ListState<T>;
  className?: string;
  selectionMode?: SelectionMode;
};

export function Option<T>({ item, state, className, selectionMode }: Props<T>) {
  const ref = useRef(null);
  const {
    optionProps,
    isFocused: isHovered,
    isSelected,
    isDisabled,
  } = useOption({ key: item.key }, state, ref);
  let displayAsSelected = isSelected;

  // special case for when using a multiselect and the user has selected the "all" option:
  // we only want to highlight the "all" option because it's pretty overhwhelming to see
  // a ton of highlighted items one after another. note that we still want to check the
  // checkbox in this scenario, so we're only looking at this for rendering the li's class
  if (
    state.selectionManager.selectionMode !== 'single' &&
    state.selectionManager.isSelectAll &&
    state.collection.getItem(MultiSelect.SELECT_ALL_KEY) &&
    item.key !== MultiSelect.SELECT_ALL_KEY
  ) {
    displayAsSelected = false;
  }

  return (
    <FocusRing focusRingClass={cx(focusRingCss.keyboard, optionFocusOutline)}>
      <li
        {...optionProps}
        ref={ref}
        className={cx(className, {
          [option.default]: !displayAsSelected && !isHovered,
          [option.selected]: displayAsSelected,
          [option.hover]: isHovered,
          [option.disabled]: isDisabled,
        })}
      >
        {selectionMode === 'multiple' && (
          <Checkbox
            aria-label={String(item.key)}
            isFocusDisabled
            isSelected={isSelected}
            isDisabled={isDisabled}
          />
        )}
        <span aria-disabled={isDisabled}>{item.rendered}</span>
      </li>
    </FocusRing>
  );
}
