/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatientNotificationAuditSchema } from '../models/PatientNotificationAuditSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationAuditService {
    /**
     * Get notification audit history for a patient
     * @param patientId Patient ID to get audit history for
     * @param notificationType Notification type to filter by
     * @param notificationStatus Notification status to filter by
     * @param page Page number
     * @param pageSize Page size
     * @param sortBy Field to sort by
     * @param orderBy Type of the ordering
     * @returns PatientNotificationAuditSchema Successful operation
     * @throws ApiError
     */
    public static getNotificationApiV1NotificationAudit(
        patientId: string,
        notificationType?: 'SMS' | 'EMAIL',
        notificationStatus?: string,
        page?: number,
        pageSize?: number,
        sortBy?: 'timestamp' | 'notification_type' | 'notification_status',
        orderBy: 'asc' | 'desc' = 'asc',
    ): CancelablePromise<PatientNotificationAuditSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notification/api/v1/notification-audit/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            query: {
                'notification_type': notificationType,
                'notification_status': notificationStatus,
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
                'order_by': orderBy,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }
}
