import { useEffect } from 'react';
import { useIntl } from 'react-intl';

type DocumentTitleParams = {
  title?: string;
};

export function useDocumentTitle({ title = 'Cadence' }: DocumentTitleParams) {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = 'Cadence';
    };
  });
}

export function usePatientTitle(first?: string, last?: string) {
  const intl = useIntl();
  if (!first || !last) {
    return undefined;
  }

  return intl.formatMessage(
    { defaultMessage: 'Patient: {firstName} {lastInitial}.' },
    { firstName: first, lastInitial: last[0].toLocaleUpperCase() },
  );
}
