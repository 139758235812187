import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import type { EmergencyVisitsFormFields } from '../../shared/emergencyVisits';
import {
  SharedEmergencyVisits,
  getEmergencyVisitsFormConfig,
} from '../../shared/emergencyVisits';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';
import { cycle17InitVisitPath } from '../paths';

export function EmergencyVisits() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<EmergencyVisitsFormFields>(
    cycle17InitVisitPath('/emergency-visits', '/index'),
    getEmergencyVisitsFormConfig(intl),
  );

  return (
    <SharedEmergencyVisits
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
    />
  );
}
