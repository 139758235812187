import { Badge } from '@/shared/tempo/atom/Badge';
import { Tag } from '@/shared/tempo/atom/Tag';

import { badge as badgeCss, label } from './ProgramTag.css';

type Props = {
  program: string;
  badges: string[];
  className?: string;
};

export function ProgramTag({ program, badges, className }: Props) {
  return (
    <Tag className={className}>
      <span className={label}>{program}</span>
      {badges.map((badge) => (
        <Badge key={badge} className={badgeCss}>
          {badge}
        </Badge>
      ))}
    </Tag>
  );
}
