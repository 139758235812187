{{! this template is used in all regularCnVisit templates }}
{{> header val="General Assessment" }}
<br/>

{{> subheader val="Social History" }}
<p>Tobacco/Alcohol/Drugs- any changes since last visit? Review past history and ask patients if any changes?</p>
{{> free_text_space }}

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>1. Review past discussion and highlight any recent patient diet goals?</p>
<p>2. Do they have any new dietary goals?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>1. Review past discussion and highlight any recent patient exercise goals?</p>
<p>2. Do they have any new exercise goals?</p>
{{> free_text_space }}

{{> subheader val="Activities of Daily Living" }}
<p>Any significant changes in functional status (need new assistance with walking, eating, toileting, or dressing?)</p>
{{> free_text_space }}

{{> header val="Patient Education" }}
<p>Today, the following modules were reviewed with the patient:</p>
<p>[insert module name]</p>
{{> free_text_space }}

{{> header val="Action Plan" }}
{{> regular_visit_action_plan }}
<br/>

{{> header val="Assessment and Plan" }}
{{> assessment_and_plan }}
<br/>

{{> subheader val="Symptoms Assessment" }}
{{> free_text_space }}

{{> subheader val="Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Status of Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Patient Clinical Goal Reached" }}
<p>[Yes or No and explanation]</p>
<p>If not reached, please choose reason</p>
<p>- Provider preferences</p>
<p>- Patient preferences</p>
<p>- Medical reason</p>
<p>- Financial reason</p>
{{> free_text_space }}

{{> subheader val="Patient Education" }}

{{! Hypertension-specific List }}
<p>Recommend during next Cadence visit patient reviews the following education modules:</p>
<p>- Basic Hypertension Education</p>
<p>- Dietary Basics</p>
<p>- Low Sodium Diet</p>
<p>- High Fiber Diet</p>
<p>- Starting Exercise</p>
<p>- Quitting Smoke</p>
<p>- When is Alcohol a Problem?</p>
<p>- Mindfulness</p>
<br/>
{{! Hypertension-specific List }}

{{> subheader val="Additional recommendations for patient care" }}
{{> free_text_space }}
