import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';
import Session from '@/shared/utils/session';

import {
  FETCH_NPI_BY_ID,
  FETCH_NPI_BY_ID_FAILURE,
  npiFetched,
} from '../actionCreators';

const fetchNpiByIdEpic = (action$: any) =>
  action$.pipe(
    ofType(FETCH_NPI_BY_ID),
    switchMap((action: any) =>
      from(Session.Api.get(`/pms/api/v1/npis/${action.id}`)).pipe(
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      ),
    ),
    map((response: AxiosResponse) => npiFetched(response.data)),
    catchError((error, caught) =>
      merge(
        of({
          type: FETCH_NPI_BY_ID_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default fetchNpiByIdEpic;
