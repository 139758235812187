import type { ReactNode } from 'react';

import { footer, titleClass } from './FloatingFooter.css';

type Props = {
  title?: string;
  children: ReactNode;
};

export function FloatingFooter({ children, title }: Props) {
  return (
    <footer className={footer}>
      <div className={titleClass}>{title}</div>
      {children}
    </footer>
  );
}
