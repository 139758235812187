import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Modal } from '@/shared/common/Modal';

import { Breadcrumbs } from './Breadcrumbs';
import { useCategories } from './useCategories';

export function Header() {
  const intl = useIntl();
  const categories = useCategories();
  const location = useLocation();
  let activeCategory = categories.find(({ to }) => location.pathname === to)
    ?.label;

  // special case since we build the nav from `useCategories` and don't want this page in the nav list
  if (!activeCategory && location.pathname.includes('report')) {
    activeCategory = intl.formatMessage({ defaultMessage: 'Report issue' });
  }

  return <Modal.Header title={<Breadcrumbs activePage={activeCategory} />} />;
}
