import { parseISO } from 'date-fns';
import { type IntlShape } from 'react-intl';

export function getDeviceAssignedDate(
  createdAt: string | undefined,
  timezone: string | undefined,
  intl: IntlShape,
) {
  // The creation date here is the assignment date,
  // not the device creation date.
  let assignedDate = intl.formatMessage({ defaultMessage: 'N/A' });
  if (createdAt) {
    const parsedCreatedAt = parseISO(createdAt);
    const assignDate = intl.formatDate(parsedCreatedAt, {
      timeZone: timezone,
    });
    const assignTime = intl.formatTime(parsedCreatedAt, {
      timeZone: timezone,
      timeZoneName: 'shortGeneric',
    });

    assignedDate = `${assignDate} ${assignTime}`;
  }
  return assignedDate;
}
