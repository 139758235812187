import { Editor } from 'draft-js';

import { RTF_STYLE_MAP } from '@/shared/common/EditorRTF/constants';
import { convertToEditorState } from '@/shared/common/EditorRTF/utils';
import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { modulePreviewSection } from './NotePreview.css';

type Props = {
  rtfBody: Maybe<TNoteBodyRTF>;
};

export const RtfBodyPreview = ({ rtfBody }: Props) => {
  const hasNonEmptyRtfBody = rtfBody?.blocks?.some(
    (block) => block.text.length,
  );
  return hasNonEmptyRtfBody ? (
    <div className={modulePreviewSection}>
      <Editor
        customStyleMap={RTF_STYLE_MAP}
        editorState={convertToEditorState(
          rtfBody as NonNullable<typeof rtfBody>,
        )}
        onChange={
          () => {} /* noop because draftjs requires this prop even though the editor is read-only */
        }
        readOnly
      />
    </div>
  ) : null;
};
