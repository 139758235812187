import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  patientDetailsError,
  patientDetailsFetched,
} from '@/pages/patients/patientDetails/actionCreators';
import type { PatientDetails } from '@/shared/types/patient.types';
import Session from '@/shared/utils/session';

/**
 * CAUTION!
 * You might find this hook weird since it storing
 * patientDetails both locally and in redux
 * The ultimate goal is to remove the redux slice, but apparently
 * both editPatient and ClinicalProfile related components need the data
 * from redux so doing both things here.
 * Will remove this redundancy once we are using react-query
 *  */

/** @deprecated */
export const useFetchPatientDetails = (
  patientId: string,
  patientDetailsExists: boolean = false,
) => {
  const dispatch = useDispatch();

  const [patientDetails, setPatientDetails] = useState<PatientDetails>(
    {} as PatientDetails,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (!patientDetailsExists) {
      setIsLoading(true);
      Session.Api.get(`/pms/api/v1/patients/${patientId}`)
        .then((response) => {
          const { data } = response;
          setPatientDetails(data);
          dispatch(patientDetailsFetched(data));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          dispatch(patientDetailsError(err));
          setError(err);
        });
    }
  }, [dispatch, patientDetailsExists, patientId]);

  return { isLoading, error, patientDetails, setPatientDetails };
};
