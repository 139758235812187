import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { arrayValidator } from './array';
import { dateValidator } from './date';
import { emailValidator } from './emails';
import { enumValidator } from './enum';
import { imageDimensions, requiredFile } from './file';
import { maxLengthString, required } from './general';
import { jsonSchemaValidator } from './jsonSchemaValidator';
import { numberValidator } from './number';
import {
  phoneNumberValidator,
  phoneNumberWithoutMaskValidator,
  phoneTypeValidator,
} from './phones';

// Exports map of validators which all get access to `intl` via currying
export function validators(intl: IntlShape) {
  return {
    date: dateValidator(intl),
    email: emailValidator(intl),
    maxLengthString: maxLengthString(intl),
    phoneNumber: phoneNumberValidator(intl),
    phoneNumberWithoutMask: phoneNumberWithoutMaskValidator(intl),
    phoneType: phoneTypeValidator(intl),
    number: numberValidator(intl),
    array: arrayValidator(intl),
    enumType: enumValidator(intl),
    required: required(intl),
    boolean: () => yup.boolean().nullable(),
    jsonSchema: jsonSchemaValidator(intl),
    requiredFile: requiredFile(intl),
    imageDimensions: imageDimensions(intl),
  };
}

export function anyFieldIsPopulated(...fieldValues: string[]) {
  return fieldValues.some((value) => !!value);
}
