import type { ReactNode } from 'react';

import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import { arrow, tooltip } from './MenuTooltip.css';

type Props = {
  children: ReactNode;
  label: string | ReactNode;
  blockOpen?: boolean;
};

export function MenuTooltip({ blockOpen, children, label }: Props) {
  if (blockOpen) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      placement="right"
      content={label}
      className={tooltip}
      arrowClassName={arrow}
    >
      {children}
    </Tooltip>
  );
}
