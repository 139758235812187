/* eslint-disable react-refresh/only-export-components */
import { format, formatISO, parse, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import range from 'lodash/range';

export const TWELVE_HOURS_RANGE = range(1, 13);

export enum TimePeriod {
  AnteMeridiem = 'AM',
  PostMeridiem = 'PM',
}

export const CADENCE_TIMEZONES = [
  {
    timezone: 'America/New_York',
    offset: 18000,
    display: 'GMT-05:00',
    name: 'Eastern Time, US & Canada',
  },
  {
    timezone: 'America/Chicago',
    offset: 21600,
    display: 'GMT-06:00',
    name: 'Central Time, US & Canada',
  },
  {
    timezone: 'America/Dawson_Creek',
    offset: 25200,
    display: 'GMT-07:00',
    name: 'Arizona Time',
  },
  {
    timezone: 'America/Denver',
    offset: 25200,
    display: 'GMT-07:00',
    name: 'Mountain Time, US & Canada',
  },
  {
    timezone: 'America/Los_Angeles',
    offset: 28800,
    display: 'GMT-08:00',
    name: 'Pacific Time, US & Canada',
  },
  {
    timezone: 'America/Anchorage',
    offset: 32400,
    display: 'GMT-09:00',
    name: 'Alaska Time',
  },
  {
    timezone: 'Etc/GMT+10',
    offset: 36000,
    display: 'GMT-10:00',
    name: 'Hawaii Time',
  },
  {
    timezone: 'Etc/UTC',
    offset: 0,
    display: 'GMT-00:00',
    name: 'UTC (placeholder, please choose correct time zone)',
  },
] as const;

export function timezoneToFriendlyName(timezone: string) {
  const timezoneData = CADENCE_TIMEZONES.find((tz) => tz.timezone === timezone);

  if (!timezoneData) {
    return timezone;
  }

  return timezoneData.name;
}

const TIMESTAMP = 'HH:mm:ss';
const HOUR_AMPM = 'h a';

export const map24HTimeFormatTo12H = (time: string) => {
  const [hour, ampm] = format(
    parse(time, TIMESTAMP, new Date()),
    HOUR_AMPM,
  ).split(' ');

  return [hour, ampm] as [string, TimePeriod];
};

export const map12HTimeFormatTo24H = (
  hour: string | number,
  ampm: TimePeriod,
) => format(parse(`${hour} ${ampm}`, HOUR_AMPM, new Date()), TIMESTAMP);

export function formatISOIgnoringTimezone(date: Date) {
  return formatISO(new Date(format(date, 'yyyy-MM-dd')));
}

export function formatDate(
  dateString: string,
  parseFormat = 'MM-yyyy',
  resultFormat = 'MMMM yyyy',
) {
  const date = parse(dateString, parseFormat, new Date());
  return format(date, resultFormat);
}

export function formatISOUriDate(date: Date) {
  return encodeURI(formatISO(date, { representation: 'date' }));
}

/**
 * @returns JS Date with date and time from `isoDateTime` param with timezone
 * from the browser (e.g. visible that way when stringified).
 * This means that the produced timestamp in the returned date is affected by
 * the browser timezone. JS Dates don't contain timezones but date operations
 * are influenced by browser timezone, e.g. when parsing a date the resulting
 * JS Date (it's timestamp) is influenced by the browser timezone.
 */
export function parseISOInBrowserTz(isoDateTime: string) {
  return parseISO(isoDateTime);
}

/**
 * @returns An ISO date string produced by using timestamp from `date` param
 * and browser timezone.
 * Examples:
 * 2020-01-02T00:00:00+01:00 results in 2020-01-02
 * 2020-01-02T23:00:00-01:00 results in 2020-01-02
 */
export function formatISODateInBrowserTz(date: Date) {
  // formatISO with representation: 'date' returns ISO date in browser timezone
  return formatISO(date, { representation: 'date' });
}

/**
 * @returns An ISO date string produced by using timestamp from `date` param
 * and UTC timezone (ignores browser timezone when producing the date string
 * in contrary to formatISO(..., { representation: 'date' }) where browser
 * timezone influences the resulting date).
 * Examples:
 * 2020-01-02T00:00:00+01:00 which is 2020-01-01T23:00:00 in UTC results in 2020-01-01
 * 2020-01-02T23:00:00-01:00 which is 2020-01-03T00:00:00 in UTC results in 2020-01-03
 */
export function formatISODateInUTC(date: Date) {
  return formatISO(utcToZonedTime(date, 'UTC'), {
    representation: 'date',
  });
}

/**
 * @param isoDate ISO date string without time
 * @returns JS Date with time set to zeros in UTC. Examples:
 * - With UTC+1 browser timezone for 2020-01-02 returns 2020-01-02T01:00:00+01:00
 * - With UTC-1 browser timezone for 2020-01-02 returns 2020-01-01T23:00:00-01:00
 * JS Dates don't contain timezones but just timestamps - the returned date
 * has timestamp for a beginning of the day in UTC. The examples are just to
 * demonstrate how these may be treated later in date related calculations when
 * browser timezone is used.
 */
export function parseISODateInUTC(isoDate: string) {
  // Date constructor with date string treats the string as it's in UTC
  return new Date(isoDate);
}

export function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZone;
}
