import { Component } from 'react';

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField as MuiTextField,
  withStyles,
} from '@/deprecated/mui';
import HidePasswordIcon from '@/shared/assets/icons/HidePasswordIcon';
import ShowPasswordIcon from '@/shared/assets/icons/ShowPasswordIcon';

import type {
  TPasswordTextField,
  TPasswordTextFieldState,
} from './TPasswordTextField';

const styles = () => ({
  root: {
    border: `1px solid #EAEAEA`,
    borderRadius: '8px',
  },
  label: {
    fontWeight: 800,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    color: 'black',
  },
  input: {
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '5px',
    minHeight: '32px',
    color: 'black',
    fontFamily: 'Inter',
  },
});

class PasswordTextField extends Component<
  TPasswordTextField,
  TPasswordTextFieldState
> {
  constructor(props: TPasswordTextField) {
    super(props);

    this.state = {
      isPassword: true,
    };

    this.changeType = this.changeType.bind(this);
  }

  changeType() {
    this.setState((prevState) => ({
      isPassword: !prevState.isPassword,
    }));
  }

  render() {
    const { isPassword } = this.state;
    const {
      input,
      name,
      meta: { touched, error },
      value,
      label,
      classes,
      onChange,
      required,
      customStyle,
      fullWidth,
      ...custom
    } = this.props;

    return (
      <FormControl fullWidth={fullWidth}>
        <MuiTextField
          id={name}
          name={name}
          label={label}
          value={value}
          InputProps={{
            classes: { root: classes.root, input: classes.input },
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="start">
                <button
                  style={{
                    border: 0,
                    outline: 'none',
                    background: 'transparent',
                    marginTop: '9px',
                    cursor: 'pointer',
                  }}
                  type="button"
                  onClick={this.changeType}
                >
                  {isPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                </button>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          onChange={onChange}
          type={isPassword ? 'password' : 'text'}
          required={required}
          style={customStyle}
          error={error && touched}
          variant="standard"
          {...input}
          {...custom}
        />
        {touched && error && (
          <FormHelperText error>
            <span style={customStyle}>{error.message}</span>
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

// eslint-disable-next-line import/no-default-export, react-refresh/only-export-components
export default withStyles(styles)(PasswordTextField);
