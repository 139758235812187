import cx from 'classnames';
import { useEffect } from 'react';
import { usePress } from 'react-aria';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useOverlayTriggerState } from 'react-stately';

import TwilioFlexIcon from '@/shared/assets/svgs/twilio-flex.svg?react';

import { positionedNotificationBadge } from '../../../components/TaskHubTray/TaskHub.css';
import { MenuItem } from '../../../deprecated/mui';
import {
  ACTIVE_TASK_STATUSES,
  AWAITING_INBOUND_TASK_STATUSES,
  TaskDirection,
} from '../../hooks/useTwilioFlexMessagesListener';
import { NotificationBadge } from '../../tempo/atom/NotificationBadge';
import { MenuTooltip } from '../Sidebar/MenuTooltip';
import {
  activeMenuItem,
  menuItem,
  sidebarIconContainer,
  sidebarIconNoFill,
} from '../Sidebar/Sidebar.css';
import { activeTask } from './TwilioFlex.css';
import { useTwilioFlexContext } from './TwilioFlexContext';
import { TwilioFlexModal } from './TwilioFlexModal';

type Props = {
  onOpenChange: (isOpen: boolean) => void;
};

export function TwilioFlex({ onOpenChange }: Props) {
  const { isFlexOpen, tasks } = useTwilioFlexContext();
  const state = useOverlayTriggerState({ onOpenChange });
  const label = <FormattedMessage defaultMessage="Conversations" />;
  const location = useLocation();

  const hasActiveTask = Array.from(tasks.values()).some((task) =>
    ACTIVE_TASK_STATUSES.includes(task.status),
  );
  const hasAwaitingInboundTask = Array.from(tasks.values()).some(
    (task) =>
      AWAITING_INBOUND_TASK_STATUSES.includes(task.status) &&
      task.data.direction === TaskDirection.INBOUND,
  );

  // we have to use react-aria's `usePress` instead of a standard `onClick` handler
  // because the `useOverlayTrigger` hook will close the overlay when the user clicks,
  // but then our button also triggers and reopens the overlay. `usePress` is aware
  // of what react-aria is doing behind the scenes and avoids this behavior.
  //
  // we could also accomplish this with a react-aria button (`useButton`) because that
  // makes use of `usePress` behind the scenes, but i wanted to maintain consistent
  // styling with the rest of the menu items.
  const { pressProps } = usePress({
    onPress() {
      state.toggle();
    },
  });

  useEffect(() => {
    state.close();
    // we don't want to run this when (if?) `state` changes, we
    // only want to close the modal when the user navigates away
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isFlexOpen) {
      state.open();
    } else {
      state.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlexOpen]);

  return (
    <>
      <MenuTooltip label={label}>
        <div
          className={cx({
            [activeMenuItem]: state.isOpen,
            [activeTask]: hasActiveTask,
          })}
        >
          <MenuItem {...pressProps} className={menuItem}>
            <div className={sidebarIconContainer}>
              <TwilioFlexIcon
                className={cx(sidebarIconNoFill, {
                  [activeTask]: hasActiveTask,
                })}
              />
              {hasAwaitingInboundTask && (
                <NotificationBadge
                  count={1}
                  className={positionedNotificationBadge}
                />
              )}
            </div>
          </MenuItem>
        </div>
      </MenuTooltip>
      <TwilioFlexModal state={state} />
    </>
  );
}
