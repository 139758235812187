import {
  type SectionPath,
  type StepPath,
  getFullStepPath,
} from '@/shared/common/Wizard/path.utils';

import { REGULAR_VISIT_SECTIONS } from '../metadata';

export function regVisitPath<
  P extends SectionPath<typeof REGULAR_VISIT_SECTIONS>,
>(basePath: P, stepPath: StepPath<typeof REGULAR_VISIT_SECTIONS, P>): string {
  return getFullStepPath(REGULAR_VISIT_SECTIONS, basePath, stepPath);
}
