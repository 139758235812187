import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import blueLogo from '@/shared/assets/blueLogo.png';
import password from '@/shared/assets/password.png';
import { Button } from '@/shared/tempo/atom/Button';

import '../ResetPasswordPage/ResetPasswordPage.scss';
import { bigButton } from '../shared/auth.css';

class ResetPasswordPrompt extends Component {
  render() {
    return (
      <>
        <div className="reset-password-success-logo">
          <img src={blueLogo} alt="blueLogo" />
          <div className="reset-password-success-page-container">
            <div className="reset-password-success-page-image">
              <img src={password} alt="password" />
            </div>
            <div className="reset-password-title">
              <FormattedMessage defaultMessage="Your password was successfully updated!" />
              <div className="clear" />
              <div className="reset-password-form-title-content">
                <FormattedMessage defaultMessage="Use your new password to log in to your account." />
              </div>
              <div className="reset-password-sections">
                <Link to="/login">
                  <Button
                    id="go-to-login"
                    variant="primary"
                    className={bigButton}
                  >
                    <FormattedMessage defaultMessage="Log in" />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResetPasswordPrompt;
