import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import type { ExpandState } from '@/shared/common/TextArea';
import { TextArea } from '@/shared/common/TextArea';

type CommentInputProps = {
  value: string;
  canSubmit?: boolean;
  disabled?: boolean;
  onChange: (body: string) => void;
  onSubmit: (body: string) => void;
  expand?: ExpandState;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
};

export const MAX_COMMENT_LENGTH = 400;
export const COMMENT_TEXTAREA_HEIGHT = 104;

export function CommentInput({
  onSubmit,
  expand,
  value,
  canSubmit = true,
  disabled,
  onFocus,
  onBlur,
  onChange,
  placeholder,
}: CommentInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  const canPost =
    Boolean(value) &&
    value.length <= MAX_COMMENT_LENGTH &&
    !disabled &&
    canSubmit;

  useHotkeys(
    'meta+enter',
    () => {
      onSubmit(value);
      ref.current?.blur();
    },
    [onSubmit, value, canPost, ref],
    {
      enabled: canSubmit && canPost,
      enableOnFormTags: true,
      enableOnContentEditable: true,
      preventDefault: true,
    },
  );

  return (
    <TextArea
      inputRef={ref}
      expand={expand}
      disabled={disabled}
      expandHeight={COMMENT_TEXTAREA_HEIGHT}
      maxLength={MAX_COMMENT_LENGTH}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
}
