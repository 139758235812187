import { type UseFormReturn, useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import PlusIcon from '@/shared/assets/svgs/plus.svg?react';
import { Form } from '@/shared/common/Form';
import type { BaseFieldProps } from '@/shared/common/Form/fields/BaseField';
import { display } from '@/shared/jsStyle/utils.css';
import { Button } from '@/shared/tempo/atom/Button';

import { RemovableRow } from '../CarePlanForm/RemovableRow';
import { addItemButton, container } from './GoalsTasksField.css';
import type { FormFields } from './formConfig';

type Props = {
  form: UseFormReturn<FormFields>;
};

export function GoalsTasksField({ form }: Props) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'goalTasks',
  });

  return (
    <div className={container}>
      {fields.map((field, index) => (
        <>
          <RemovableRow
            key={field.id}
            onRemove={() => remove(index)}
            hasError={!!form.formState.errors.goalTasks?.[index]}
          >
            <Form.TextField
              required
              size={10.9 as BaseFieldProps['size']}
              placeholder={intl.formatMessage({
                defaultMessage: 'Enter task action...',
              })}
              name={`goalTasks.${index}.description`}
            />
          </RemovableRow>
          {/* Make these hidden fields so we can preserve the form data even though it isn't editable */}
          <Form.TextField
            name={`goalTasks.${index}.name`}
            className={display.none}
          />
          <Form.TextField
            name={`goalTasks.${index}.completed`}
            className={display.none}
          />
        </>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="tertiary"
          size="small"
          onPress={() => append({ completed: false, description: '' })}
        >
          <Button.Icon>
            <PlusIcon />
          </Button.Icon>
          <FormattedMessage defaultMessage="Add Task" />
        </Button>
      </div>
    </div>
  );
}
