import { FormattedMessage, useIntl } from 'react-intl';

import { useDeviceProviderTypeI18nMap } from '@/pages/adminPanel/patient-profile/tabs/DevicesTab/useDeviceProviderTypeI18nMap';
import { useDeviceTypeI18nMap } from '@/pages/adminPanel/patient-profile/tabs/DevicesTab/useDeviceTypeI18nMap';
import AlertCircleIcon from '@/shared/assets/svgs/alertCircle.svg?react';
import AlertTriangleIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import CheckIcon from '@/shared/assets/svgs/check-circle.svg?react';
import QuestionIcon from '@/shared/assets/svgs/question-circle.svg?react';
import {
  type DeviceWithHealth,
  useDevicesWithHealth,
} from '@/shared/common/devices/hooks/useDevicesWithHealth';
import type { PatientDeviceHealthDeviceDeviceHealth } from '@/shared/generated/grpcGateway/telemetry.pb';
import type { TagVariant } from '@/shared/tempo/atom/Tag';
import { Tag } from '@/shared/tempo/atom/Tag';

import { SubHeader } from '../SubHeader';
import {
  container,
  deviceRow,
  statusIcon,
  statusMessage,
  tag,
} from './DeviceStatuses.css';

type DeviceStatus = 'GREEN' | 'YELLOW' | 'RED';

type SupportedTagVariant = Extract<
  TagVariant,
  'success' | 'warning' | 'danger' | 'default'
>;

function statusToTagVariant(
  status?: PatientDeviceHealthDeviceDeviceHealth,
): SupportedTagVariant {
  return ({
    GREEN: 'success',
    YELLOW: 'warning',
    RED: 'danger',
  }[status as DeviceStatus] || 'default') as SupportedTagVariant;
}

type Props = {
  patientId: string;
};

export function DeviceStatuses({ patientId }: Props) {
  const { devices, isLoading } = useDevicesWithHealth(patientId);

  if (isLoading || !devices?.length) {
    return null;
  }

  return (
    <div className={container}>
      <SubHeader>
        <FormattedMessage defaultMessage="Patient device status" />
      </SubHeader>
      {devices?.map((device) => (
        <DeviceStatusRow device={device} key={device.name} />
      ))}
    </div>
  );
}

function DeviceStatusRow({ device }: { device: DeviceWithHealth }) {
  const intl = useIntl();
  const deviceTypei18n = useDeviceTypeI18nMap();
  const providerI18n = useDeviceProviderTypeI18nMap();
  const variant = statusToTagVariant(device.status);
  const betterSignal = intl.formatMessage({
    defaultMessage:
      'Please ask the patient to try taking their vitals from an area with better reception.',
  });

  return (
    <div className={deviceRow[variant]}>
      <Tag variant={variant} className={tag}>
        <StatusIcon variant={variant} /> {providerI18n[device.provider]}{' '}
        {deviceTypei18n[device.device_type]}
      </Tag>
      <div className={statusMessage}>
        {device.status === 'GREEN' && (
          <FormattedMessage defaultMessage="No issues detected." />
        )}
        {device.status === 'YELLOW' && (
          <span>
            <FormattedMessage defaultMessage="Potential signal issues detected. " />
            {betterSignal}
          </span>
        )}
        {device.status === 'RED' && (
          <span>
            <FormattedMessage defaultMessage="Signal issues detected. " />
            {betterSignal}
          </span>
        )}
        {!device.status && (
          <span>
            <FormattedMessage defaultMessage="We haven't received vitals for this device in the past 30 days. The device may be new, unused, faulty, or experiencing severe connection issues." />
          </span>
        )}
      </div>
    </div>
  );
}

function StatusIcon({ variant }: { variant: SupportedTagVariant }) {
  const IconCmp = {
    success: CheckIcon,
    warning: AlertCircleIcon,
    danger: AlertTriangleIcon,
    default: QuestionIcon,
  }[variant];

  return <IconCmp className={statusIcon[variant]} />;
}
