import { FormattedMessage } from 'react-intl';

import {
  Script,
  YesNoRadioGroup,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import {
  isHtn,
  isT2d,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/condition.utils';
import { ConditionProgram } from '@/shared/types/condition.types';

type Props = {
  program: ConditionProgram;
};

export function DiseaseSpecificSymptoms({ program }: Props) {
  return (
    <>
      {isT2d(program) && (
        <YesNoRadioGroup
          name="confusion"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              <FormattedMessage defaultMessage="Patient is exhibiting confusion &/or having difficulty communicating?" />
            </Script>
          }
        />
      )}

      {isHtn(program) && (
        <>
          <YesNoRadioGroup
            name="newWeakness"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Are you having any new weakness in one area of your body, like an arm or a leg?" />
              </Script>
            }
          />
          <YesNoRadioGroup
            name="fainting"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Have you had any episodes of fainting or passing out?" />
              </Script>
            }
          />
          <YesNoRadioGroup
            name="slurredSpeech"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Are you having any issues with slurred speech?" />
              </Script>
            }
          />
        </>
      )}

      {program === ConditionProgram.CHF && (
        <>
          <YesNoRadioGroup
            name="chestPain"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Are you having any chest pain?" />
              </Script>
            }
          />
          <YesNoRadioGroup
            name="dizziness"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="Are you having any issues with feeling dizzy?" />
              </Script>
            }
          />
        </>
      )}
    </>
  );
}
