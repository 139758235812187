import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';

import { type Goal, GoalMeasure, GoalStatus } from './goals.types';

export type FormFields = Goal;

export type EditMode = 'all' | 'core';

export function getFormConfig(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
  mode: EditMode = 'all',
): FormConfig {
  const { required, enumType } = validators(intl);
  return {
    fields: {
      ...(mode === 'all' && {
        measure: {
          defaultValue: fieldValues.measure,
          validation: required(
            enumType({ source: GoalMeasure, pluck: 'values' }),
          ),
        },
        title: {
          defaultValue: fieldValues.title,
          validation: required(yup.string()),
        },
        description: {
          defaultValue: fieldValues.description,
          validation: yup.string(),
        },
      }),
      status: {
        defaultValue: fieldValues.status,
        validation: required(enumType({ source: GoalStatus, pluck: 'values' })),
      },
      goalTasks: {
        defaultValue: fieldValues.goalTasks || [],
        validation: yup.array().of(
          yup.object().shape({
            name: yup.string().nullable(),
            details: yup.string(),
            completed: yup.bool(),
          }),
        ),
      },
    },
  };
}
