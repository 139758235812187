import { CHF_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/chfVisit';
import type { IntroFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/IntroPatientAttendance';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { cnIntro } from '../../shared/cnIntro';

export function chfVisitIntro(data: SectionStepsState) {
  const introData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/intro',
    '/patient-attendance',
  )<IntroFormFields>();

  return cnIntro({ introData });
}
