import type { ReactNode } from 'react';

import { timeTrackingContainer } from './TasksContainer.css';

type Props = {
  children: ReactNode;
};

export function TasksContainer({ children }: Props) {
  return <div className={timeTrackingContainer}>{children}</div>;
}
