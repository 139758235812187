import type { RpcInterceptor, RpcOptions } from '@protobuf-ts/runtime-rpc';
import {
  RpcOutputStreamController,
  ServerStreamingCall,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc';

import { logger } from '@/logger';
import { Check, isRefreshTokenNeeded } from '@/shared/utils/helpers';
import { makeRefreshRequest } from '@/shared/utils/session';
import { store } from '@/store';

/**
 * Adds the Authorization header to requests
 */
export function authInterceptor(): RpcInterceptor {
  function injectAuthIntoOptions(opts: RpcOptions) {
    const tokens = store.getState().auth.token;
    return {
      ...opts,
      meta: {
        ...opts.meta,
        Authorization: `Bearer ${tokens.access_token}`,
      },
    };
  }

  return {
    // Inspired by: https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733025632
    interceptUnary: (next, method, input, options = {}) => {
      const optionsWithAuth = injectAuthIntoOptions(options);
      const callPromise = new Promise<UnaryCall>((resolve) => {
        const tokens = store.getState().auth.token;
        if (isRefreshTokenNeeded(Check.IMMEDIATELY, tokens.access_token)) {
          makeRefreshRequest().then(() => {
            optionsWithAuth.meta.Authorization = `Bearer ${
              store.getState().auth.token.access_token
            }`;
            resolve(next(method, input, optionsWithAuth));
          });
          // If access token will expire in 5 minutes, refresh token in background
        } else if (
          isRefreshTokenNeeded(Check.IN_NEXT_5_MIN, tokens.access_token)
        ) {
          makeRefreshRequest();
          resolve(next(method, input, optionsWithAuth));
        } else {
          resolve(next(method, input, optionsWithAuth));
        }
      });

      return new UnaryCall(
        method,
        optionsWithAuth.meta,
        input,
        callPromise.then((c) => c.headers),
        callPromise.then((c) => c.response),
        callPromise.then((c) => c.status),
        callPromise.then((c) => c.trailers),
      );
    },
    // Inspired by: https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733118832
    interceptServerStreaming: (next, method, input, options = {}) => {
      const optionsWithAuth = injectAuthIntoOptions(options);

      // We have to return an output stream instance before we get one
      // from next(), so we create our own and delegate data below
      const outputStream = new RpcOutputStreamController();

      function resolveStreamCall(
        oStream: RpcOutputStreamController<object>,
        opts: RpcOptions,
        resolve: (
          value:
            | [ServerStreamingCall<object, object>]
            | PromiseLike<[ServerStreamingCall<object, object>]>,
        ) => void,
      ) {
        // Start the call
        const call = next(method, input, opts);
        // Delegate from the original output stream to the one controlled by us
        call.responses.onNext((message, error, done) => {
          if (message) oStream.notifyMessage(message);
          if (error) oStream.notifyError(error);
          if (done) oStream.notifyComplete();
        });
        // Deliberately returning a tuple. the call is awaitable - the
        // promise chain would wait until the call is finished.
        resolve([call]);
      }

      const callPromise = new Promise<[ServerStreamingCall]>((resolve) => {
        const tokens = store.getState().auth.token;
        if (isRefreshTokenNeeded(Check.IMMEDIATELY, tokens.access_token)) {
          logger.debug(
            `Refreshing access token for streaming call '${method.name}'`,
          );
          makeRefreshRequest().then(() => {
            optionsWithAuth.meta.Authorization = `Bearer ${
              store.getState().auth.token.access_token
            }`;
            logger.debug(
              `Successfully refreshed access token for streaming call '${method.name}'`,
            );
            resolveStreamCall(outputStream, optionsWithAuth, resolve);
          });
        } else {
          resolveStreamCall(outputStream, optionsWithAuth, resolve);
        }
      });

      return new ServerStreamingCall(
        method,
        optionsWithAuth.meta,
        input,
        callPromise.then((c) => c[0].headers),
        outputStream,
        callPromise.then((c) => c[0].status),
        callPromise.then((c) => c[0].trailers),
      );
    },
  };
}
