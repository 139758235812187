import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Snackbar } from '@/shared/common/Snackbar';

import { SectionHeader } from '../SectionHeader';
import { PatientGoalsEdit } from './PatientGoalsEdit';
import { PatientGoalsView } from './PatientGoalsView';
import {
  useBulkUpdateGoals,
  useFetchPatientGoals,
  useToggleGoal,
} from './patientGoals.hooks';

type PatientGoalsProps = {
  patientId: string;
};

export function PatientGoals({ patientId }: PatientGoalsProps) {
  const {
    isLoading,
    isError,
    data: goals,
    error,
  } = useFetchPatientGoals(patientId);

  const [isEditing, setIsEditing] = useState(false);
  const onCheckToggled = useToggleGoal(patientId);
  const onGoalsListUpdated = useBulkUpdateGoals(patientId, () =>
    setIsEditing(false),
  );

  return (
    <>
      {isError && <Snackbar message={error} variant="error" />}
      {onGoalsListUpdated.isError && (
        <Snackbar message={onGoalsListUpdated.error} variant="error" />
      )}
      {onCheckToggled.isError && (
        <Snackbar message={onCheckToggled.error} variant="error" />
      )}
      <SectionHeader
        onEditClicked={!isEditing ? () => setIsEditing(true) : undefined}
      >
        <FormattedMessage defaultMessage="Patient Goals" />
      </SectionHeader>
      <LoadingPlaceholder isLoading={isLoading}>
        {isEditing ? (
          <PatientGoalsEdit
            goals={goals}
            isSaving={onGoalsListUpdated.isLoading}
            onSave={onGoalsListUpdated.mutate}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <PatientGoalsView
            goals={goals}
            onCheckToggled={onCheckToggled.mutate}
            onCreate={() => setIsEditing(true)}
          />
        )}
      </LoadingPlaceholder>
    </>
  );
}
