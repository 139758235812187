import { type AxiosError } from 'axios';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import CheckIcon from '@/shared/assets/svgs/check.svg?react';
import ChevronIcon from '@/shared/assets/svgs/chevron.svg?react';
import DownloadIcon from '@/shared/assets/svgs/download.svg?react';
import PencilIcon from '@/shared/assets/svgs/pencil.svg?react';
import TrashIcon from '@/shared/assets/svgs/trash.svg?react';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import {
  type CCMCarePlan,
  State,
} from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import {
  useCarePlan,
  useCarePlanNoteInfo,
  useDownloadCarePlanPdf,
  useListCarePlans,
} from '@/shared/hooks/queries/carePlan.queries';
import { Menu } from '@/shared/tempo/@labs/molecule/Menu';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { color } from '@/shared/tempo/theme';
import { NoteStatus } from '@/shared/types/note.types';
import { grpcNameToId, parseGrpcDate } from '@/shared/utils/grpc';
import { parentPath } from '@/shared/utils/route-helpers';

import { CarePlanAlertBanner } from '../CarePlanAlertBanner';
import { useValidateCarePlan } from '../CarePlanForm/useValidateCarePlan';
import { detail } from '../CarePlanTab.css';
import { GoalsGrid } from '../Goals/GoalsGrid';
import { GoalModalType } from '../Goals/goalModal.types';
import {
  CarePlanDetailModals,
  type ModalState,
  ModalType,
} from './CarePlanDetailModals';
import { CarePlanPreview } from './CarePlanPreview';
import { StatusTag } from './StatusTag';

export function CarePlanDetail() {
  const history = useHistory();
  const [modalState, setModalState] = useState<Nullable<ModalState>>(null);
  const { url, params } = useRouteMatch<{
    carePlanId: string;
    patientId: string;
  }>();
  const { mutate: downloadCarePlanPdf, isLoading: isDownloadingPdf } =
    useDownloadCarePlanPdf();
  // the `enabled` setting here is a bit of a hack to prevent getting stuck trying to load the care plan after deleting it.
  // the delete invalidates the base query key, so react-query tries to refetch it a few times before giving up and following
  // through on the rest of the post-delete process.
  const { data: carePlan, isFetching } = useCarePlan(params.carePlanId, {
    enabled: modalState?.type !== ModalType.Delete,
    keepPreviousData: true,
    onError(err) {
      if ((err as AxiosError)?.response?.status === 404) {
        history.replace(parentPath(url));
      }
    },
  });
  const { data: allCarePlans, isFetching: isFetchingAllCarePlans } =
    useListCarePlans({
      filter: `patientId="${params.patientId}"`,
      orderBy: 'createTime desc',
    });
  const { isValid: isValidCarePlan } = useValidateCarePlan(
    !isFetching ? carePlan : null,
  );
  const {
    data: noteInfo,
    isLoading: isLoadingNoteInfo,
    isFetching: isFetchingNoteInfo,
  } = useCarePlanNoteInfo(params.carePlanId);
  const associatedNoteIsPublished =
    noteInfo?.noteStatus === NoteStatus.Published;

  if (isFetching || isFetchingAllCarePlans || !carePlan) {
    return <LoadingPlaceholder isLoading />;
  }

  const createdOn = parseISO(carePlan.createTime || '');
  const isDraft = carePlan.state === State.DRAFT;
  const hasMultiplePlans = (allCarePlans?.ccmCarePlans?.length ?? 0) > 1;
  const isUnassignedApproval = carePlan.state === State.UNASSIGNED_APPROVAL;
  const isNoClinicalDocFound = carePlan.state === State.CLINICALDOC_NOT_FOUND;

  return (
    <>
      <div className={detail.container}>
        <GoalsGrid
          patientId={params.patientId}
          onEditGoal={(goal) =>
            setModalState({ type: GoalModalType.EditGoal, goal })
          }
          onCreateGoal={() => setModalState({ type: GoalModalType.CreateGoal })}
        />
        <div>
          <div className={detail.headerContainer}>
            <Menu.Trigger
              isDisabled={!hasMultiplePlans}
              menu={
                <Menu
                  items={allCarePlans?.ccmCarePlans ?? []}
                  onAction={(name) =>
                    history.push(
                      `${parentPath(url)}/${grpcNameToId(name as string)}`,
                    )
                  }
                  selectedKeys={[carePlan.name as string]}
                >
                  {(item: CCMCarePlan) => (
                    <Menu.Item key={item.name}>
                      <CarePlanTitle
                        isDraft={item.state === State.DRAFT}
                        createdOn={parseISO(item.createTime || '')}
                        approvedOn={item.approvalDate}
                      />
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button
                className={detail.planTitle}
                variant="tertiary"
                isDisabled={!hasMultiplePlans}
              >
                <StatusTag carePlan={carePlan} />
                <CarePlanTitle
                  isDraft={isDraft}
                  createdOn={createdOn}
                  approvedOn={carePlan.approvalDate}
                />
                {hasMultiplePlans && (
                  <Button.Icon>
                    <ChevronIcon />
                  </Button.Icon>
                )}
              </Button>
            </Menu.Trigger>
            <div className={detail.buttonsContainer}>
              {associatedNoteIsPublished && (
                <IconButton
                  size="small"
                  variant="secondary"
                  isProcessing={isDownloadingPdf}
                  isDisabled={!isValidCarePlan}
                  onPress={() => downloadCarePlanPdf(carePlan.name || '')}
                >
                  <DownloadIcon />
                </IconButton>
              )}
              {isDraft && (
                <IconButton
                  size="small"
                  variant="secondary"
                  onPress={() => history.push(`${parentPath(url)}/draft`)}
                >
                  <PencilIcon />
                </IconButton>
              )}
              {(isDraft || isNoClinicalDocFound || isUnassignedApproval) &&
                associatedNoteIsPublished && (
                  <Button
                    size="small"
                    isDisabled={!isValidCarePlan}
                    onPress={() => setModalState({ type: ModalType.Approve })}
                  >
                    <Button.Icon>
                      <CheckIcon />
                    </Button.Icon>
                    <FormattedMessage defaultMessage="Approve" />
                  </Button>
                )}
            </div>
          </div>
          {isUnassignedApproval && (
            <CarePlanAlertBanner
              title={
                <FormattedMessage defaultMessage="This Care Plan was not approved by its assigned provider. Please reopen Care Plan for review in patient's chart." />
              }
            />
          )}
          {isNoClinicalDocFound && (
            <CarePlanAlertBanner
              title={
                <FormattedMessage defaultMessage="This Care Plan is not a clinical document type and cannot be automatically approved. Please verify that the Care Plan is in the patient's chart." />
              }
            />
          )}
          {!isValidCarePlan && (
            <CarePlanAlertBanner
              title={
                <FormattedMessage defaultMessage="This Care Plan is not complete. Please edit the care plan and fill out the missing required fields." />
              }
            />
          )}
          <CarePlanPreview
            patientId={params.patientId}
            carePlan={carePlan}
            allCarePlans={allCarePlans?.ccmCarePlans || []}
          />
          {isDraft && !associatedNoteIsPublished && (
            <Button
              variant="tertiary"
              className={detail.deleteButton}
              isDisabled={isLoadingNoteInfo || isFetchingNoteInfo}
              onPress={() => setModalState({ type: ModalType.Delete })}
            >
              <Button.Icon>
                <TrashIcon fill={color.Theme.Light.Danger} />
              </Button.Icon>
              <FormattedMessage defaultMessage="Delete" />
            </Button>
          )}
        </div>
      </div>
      <CarePlanDetailModals
        carePlan={carePlan}
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  );
}

function CarePlanTitle({
  isDraft,
  createdOn,
  approvedOn,
}: {
  isDraft: boolean;
  createdOn: Date;
  approvedOn?: GoogleDate;
}) {
  const date = !isDraft && approvedOn ? parseGrpcDate(approvedOn) : createdOn;

  return (
    <FormattedMessage
      defaultMessage="{isDraft, select, true {[draft] } other {}}{createdOn}"
      values={{
        isDraft,
        createdOn: format(date, 'MMM dd, yyyy'),
      }}
    />
  );
}
