import reduce from 'lodash/reduce';
import type { IntlShape } from 'react-intl';

import type {
  SecondarySymptomsFormFields,
  Symptom,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/SecondarySymptoms';
import {
  SYMPTOMS_BY_PROGRAM,
  getSymptomI18nMapping,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/SecondarySymptoms';
import type { SymptomsFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/Symptoms';
import { conditionsToProgram } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/hooks';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { isHtn, isT2d } from '../../../shared/condition.utils';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';
import { sectionHasData } from '../util';

type Params = {
  intl: IntlShape;
  rpmConditions: RpmCondition[];
  symptomsData: Maybe<SymptomsFormFields>;
  secondarySymptomsData: Maybe<SecondarySymptomsFormFields>;
};

export function cnSymptoms({
  intl,
  rpmConditions,
  symptomsData,
  secondarySymptomsData,
}: Params) {
  const md = new MarkdownBuilder();

  if (!sectionHasData(symptomsData)) {
    return '';
  }

  const program = conditionsToProgram(rpmConditions);

  md.h3('Symptoms');
  if (symptomsData?.newConcerns === 'false') {
    md.uli(
      'A full disease-specific review of symptoms was completed and was negative.',
    );
  } else if (symptomsData?.newConcerns === 'true') {
    md.field('Patient-reported', symptomsData?.patientReportedSymptoms);

    if (isT2d(program)) {
      md.field(
        'Patient is exhibiting confusion &/or having difficulty communicating?',
        symptomsData?.confusion,
      );
    }

    if (isHtn(program)) {
      md.field(
        'New weakness in one area of your body, like an arm or a leg?',
        symptomsData?.newWeakness,
      );
      md.field(
        'Any episodes of fainting or passing out?',
        symptomsData?.fainting,
      );
      md.field('Any issues with slurred speech?', symptomsData?.slurredSpeech);
    }

    if (program === ConditionProgram.CHF) {
      md.field('Are you having any chest pain?', symptomsData?.chestPain);
      md.field(
        'Are you having any issues with feeling dizzy?',
        symptomsData?.dizziness,
      );
    }
  }

  if (symptomsData?.clinicalEscalationConfirmed === 'true') {
    md.field(
      'Clinical escalation was confirmed',
      symptomsData.escalationDetails || 'No escalation details',
    );
  }

  const symptomI18nMap = getSymptomI18nMapping(intl, program);
  const experiencingSymptoms = hasSecondarySymptoms(
    secondarySymptomsData,
    program,
  );

  if (experiencingSymptoms === false) {
    const programSymptoms = program ? SYMPTOMS_BY_PROGRAM[program] : [];
    md.newline();
    md.p(
      `Patient has not experienced any ${programSymptoms
        .map((s) => symptomI18nMap[s].toLocaleLowerCase())
        .join(', ')}`,
    );
  } else if (experiencingSymptoms === true) {
    const secondarySymptoms = getOrganizedSecondarySymptoms(
      secondarySymptomsData,
    );

    Object.entries(secondarySymptoms).forEach(([symptom, options]) => {
      const formattedSymptom = symptomI18nMap[symptom as Symptom] || symptom;
      const descriptor = options.newSymptom ? 'new symptom' : 'chronic';
      const consistency = options.newSeverity
        ? "isn't consistent"
        : 'is consistent';

      if (options.newSymptom) {
        md.field(formattedSymptom, descriptor);
      } else {
        md.field(
          formattedSymptom,
          `${descriptor}, ${consistency} with past episodes`,
        );
      }
    });
  }

  return md.toString();
}

function getOrganizedSecondarySymptoms(
  secondarySymptoms: Maybe<SecondarySymptomsFormFields>,
) {
  if (!secondarySymptoms) {
    return {};
  }

  return reduce(
    secondarySymptoms,
    (result, value, key) => {
      if (key.includes('-') && value !== undefined) {
        const [symptom, property] = key.split('-');
        const newRes: Record<string, Record<string, boolean>> = {
          ...result,
          [symptom]: result[symptom] || {},
        };
        newRes[symptom][property] = value === 'true';
        return newRes;
      }
      return result;
    },
    {} as Record<string, Record<string, boolean>>,
  );
}

/**
 * Returns:
 * true = has secondary symptoms
 * false = does not have secondary symptoms
 * null = not enough data specified to be able to determine
 */
function hasSecondarySymptoms(
  secondarySymptoms: Maybe<SecondarySymptomsFormFields>,
  program: Nullable<ConditionProgram>,
): Nullable<boolean> {
  if (!secondarySymptoms) {
    return null;
  }

  if (program !== ConditionProgram.CHF) {
    if (secondarySymptoms.newSecondarySymptoms === 'true') {
      return true;
    }
    if (secondarySymptoms.newSecondarySymptoms === 'false') {
      return false;
    }
    return null;
  }

  // CHF doesn't capture the newSecondarySymptoms field, so we need to
  // inspect the individual symptoms to determine if any are enabled.
  return Object.keys(secondarySymptoms).some(
    (key) =>
      key.includes('-checked') &&
      secondarySymptoms[key as keyof SecondarySymptomsFormFields] === true,
  );
}
