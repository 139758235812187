import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { resultTable } from './results.css';

export function ResultTable({
  children,
  className,
}: HTMLAttributes<HTMLTableElement>) {
  return <table className={cx(resultTable, className)}>{children}</table>;
}
