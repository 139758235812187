import { FormattedMessage } from 'react-intl';

import { Script } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';

export function ActionPlanIntroScript() {
  return (
    <>
      <Script>
        <FormattedMessage defaultMessage="At Cadence, we have created education modules to help empower you to continue your successful journey in reaching your goals and taking charge of your health. We'll work together to make sure you have the tools to accomplish your goals." />
      </Script>
      <Script>
        <FormattedMessage defaultMessage="Now we're going to focus on creating your action plan. Think of this plan as a simple roadmap for how you can achieve your health goals." />
      </Script>
      <Script>
        <FormattedMessage defaultMessage="The first step in your action plan is to choose a health goal that's important to you and then determine what steps you are able to take in order to achieve that health goal." />
      </Script>
    </>
  );
}
