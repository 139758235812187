import cx from 'classnames';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import { TWELVE_HOURS_RANGE, TimePeriod } from '@/shared/utils/time-helpers';

import {
  hidden,
  indentedCheckboxes,
  noPadding,
} from '../../shared/sections.css';
import {
  engagementLegend,
  vitalsReceiptsSettings,
} from './CommunicationPreferences.css';
import {
  fieldset,
  leftSelect,
  legend,
  preferencesContainer,
  rightSelect,
  selectWrapper,
  span,
} from './vitalsReminders.css';

type Props = {
  collapsible?: boolean;
  defaultOpen?: boolean;
};

export function CommunicationPreferences({
  collapsible = false,
  defaultOpen = false,
}: Props) {
  const intl = useIntl();
  const form = useFormContext();
  const { smsAppointmentsReminders, engagementStreaks, engagementBingo } =
    useFlags();

  // Need to watch these values or there is a race condition where react hook form
  // does not have access to the latest value via formValues.
  const vitalsConfirmation = useWatch({ name: 'vitals_confirmation' });
  const weeklySummary = useWatch({ name: 'weekly_summary' });
  const smsConsent = useWatch({ name: 'sms_consent' });
  const emailConsent = useWatch({ name: 'email_consent' });
  const vitalsReminder = useWatch({ name: 'vitals_reminder' });

  const SectionCmp = collapsible
    ? Form.CollapsibleCardSection
    : Form.CardSection;

  return (
    <SectionCmp
      defaultOpen={defaultOpen}
      title={intl.formatMessage({
        defaultMessage: 'Communication Preferences',
      })}
    >
      <div className={preferencesContainer}>
        <Form.Toggle
          name="sms_consent"
          label={<FormattedMessage defaultMessage="SMS Opt-in" />}
          onChange={(checked) => {
            form.setValue('sms_consent', checked);
          }}
        />
        <div
          className={cx(indentedCheckboxes, {
            [hidden]: !smsConsent,
          })}
        >
          <Form.Checkbox
            name="vitals_confirmation"
            className={noPadding}
            label={<FormattedMessage defaultMessage="Vitals Receipts" />}
            onChange={(checked) => {
              if (!checked) {
                form.setValue('vitals_value', false);
                form.setValue('streaks', false);
                form.setValue('bingo', false);
              }
            }}
            useGrid={false}
          />
          <div
            className={cx({
              [vitalsReceiptsSettings]: engagementStreaks || engagementBingo,
              [hidden]: !vitalsConfirmation,
            })}
          >
            <Form.Checkbox
              name="vitals_value"
              className={noPadding}
              label={
                <FormattedMessage defaultMessage="Include Vitals Values" />
              }
              useGrid={false}
            />
            <Form.Checkbox
              name="streaks"
              className={cx(noPadding, {
                [hidden]: !engagementStreaks,
              })}
              label={<FormattedMessage defaultMessage="Streaks" />}
              useGrid={false}
            />
            <Form.Checkbox
              name="bingo"
              className={cx(noPadding, {
                [hidden]: !engagementBingo,
              })}
              label={<FormattedMessage defaultMessage="Bingo" />}
              useGrid={false}
            />
            {engagementStreaks && engagementBingo && (
              <div className={engagementLegend}>
                <FormattedMessage defaultMessage="If Streaks and Bingo are selected, Bingo will take priority" />
              </div>
            )}
          </div>
          {smsAppointmentsReminders && (
            <Form.Checkbox
              name="appointments_reminder"
              className={noPadding}
              label={
                <FormattedMessage defaultMessage="Appointment Reminders" />
              }
              onChange={(checked) => {
                if (checked) {
                  form.setValue('appointments_reminder', checked);
                }
              }}
              useGrid={false}
            />
          )}
          <Form.Checkbox
            name="vitals_reminder"
            className={noPadding}
            label={<FormattedMessage defaultMessage="Vitals Reminders" />}
            onChange={(checked) => {
              if (checked) {
                form.setValue('vitals_reminder', checked);
              }
            }}
            useGrid={false}
          />
          <fieldset
            className={cx(fieldset, {
              [hidden]: !vitalsReminder,
            })}
          >
            <legend className={legend}>
              <FormattedMessage defaultMessage="Set a time for SMS reminders" />
            </legend>
            <div className={selectWrapper}>
              <Form.DeprecatedSelect
                name="vitals_reminder_time"
                className={leftSelect}
                label=""
              >
                {TWELVE_HOURS_RANGE.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Form.DeprecatedSelect>
              <Form.DeprecatedSelect
                name="vitals_reminder_time_am_pm"
                className={rightSelect}
                label=""
              >
                <option value={TimePeriod.AnteMeridiem}>
                  {intl.formatMessage({ defaultMessage: 'AM' })}
                </option>
                <option value={TimePeriod.PostMeridiem}>
                  {intl.formatMessage({ defaultMessage: 'PM' })}
                </option>
              </Form.DeprecatedSelect>
            </div>
            <span className={span}>
              <FormattedMessage defaultMessage="A reminder will be sent if no vitals in current or prior day" />
            </span>
          </fieldset>
        </div>
        <Form.Toggle
          name="email_consent"
          label={<FormattedMessage defaultMessage="Email Opt-in" />}
          onChange={(checked) => {
            form.setValue('email_consent', checked);
          }}
        />
        <div
          className={cx(indentedCheckboxes, {
            [hidden]: !emailConsent,
          })}
        >
          <Form.Checkbox
            name="weekly_summary"
            className={noPadding}
            label={<FormattedMessage defaultMessage="Weekly Vital summary" />}
            persistedHelp={
              weeklySummary ? (
                <FormattedMessage defaultMessage="Sent weekly on Mondays at 9am" />
              ) : undefined
            }
            useGrid={false}
          />
        </div>
      </div>
    </SectionCmp>
  );
}
