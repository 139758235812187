import { decode } from 'js-base64';
import queryString from 'query-string';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { compose } from '@/deprecated/recompose';
import GrayCheckIcon from '@/shared/assets/icons/GrayCheckIcon';
import {
  lowercaseCharacter,
  numberCharacter,
  passwordMinLength,
  required,
  specialCharacter,
  uppercaseCharacter,
} from '@/shared/common/FormValidation';
import PasswordTextField from '@/shared/common/PasswordTextField';
import { withToaster } from '@/shared/common/withToaster';
import { Button } from '@/shared/tempo/atom/Button';
import { getErrorMsg } from '@/shared/utils/helpers';
import type { RootState } from '@/store/redux.types';

import { confirmUser } from '../../actionCreators';
import { AuthPageWrapper } from '../shared';
import { bigButton } from '../shared/auth.css';
import type { TUserConfirmationPage } from './TUserConfirmationPage';
import './UserConfirmationPage.scss';

export class UserConfirmationPageComponent extends Component<TUserConfirmationPage> {
  constructor(props: TUserConfirmationPage) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps: TUserConfirmationPage) {
    const {
      isAuthenticated,
      history,
      location,
      userConfirmationError,
      toaster,
    } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      history.replace(from.pathname);
    }

    if (
      userConfirmationError !== prevProps.userConfirmationError &&
      userConfirmationError !== null
    ) {
      toaster?.error(getErrorMsg(userConfirmationError));
    }
  }

  submit(values: any) {
    const { dispatchConfirmUser, location } = this.props;
    const queryParameters: any = queryString.parse(location.search);

    const data = queryString.parse(decode(queryParameters.token));
    const { username, code } = data;

    dispatchConfirmUser(username, code, values.password);
  }

  render() {
    const { pristine, submitting, handleSubmit } = this.props;

    return (
      <AuthPageWrapper>
        <div className="user-confirmation-title">
          <FormattedMessage defaultMessage="Sign up" />
          <div className="user-confirmation-form-title-content">
            <FormattedMessage defaultMessage="Please, create your password." />
          </div>
        </div>
        <form id="user-confirmation--form" onSubmit={handleSubmit(this.submit)}>
          <div>
            <Field
              name="password"
              component={PasswordTextField}
              placeholder="Create your password"
              customStyle={{
                width: '400px',
              }}
              validate={[
                required,
                passwordMinLength,
                lowercaseCharacter,
                uppercaseCharacter,
                numberCharacter,
                specialCharacter,
              ]}
              fullWidth
            />
          </div>
          <div className="user-confirmation-sections user-confirmation-password-hint">
            <GrayCheckIcon />
            <span className="user-confirmation-password-hint-text">
              <FormattedMessage defaultMessage="At least 8 characters" />
            </span>
          </div>
          <div className="user-confirmation-password-hint">
            <GrayCheckIcon />
            <span className="user-confirmation-password-hint-text">
              <FormattedMessage defaultMessage="1 number" />
            </span>
          </div>
          <div className="user-confirmation-password-hint">
            <GrayCheckIcon />
            <span className="user-confirmation-password-hint-text">
              <FormattedMessage defaultMessage="1 special character" />
            </span>
          </div>
          <div className="user-confirmation-password-hint">
            <GrayCheckIcon />
            <span className="user-confirmation-password-hint-text">
              <FormattedMessage defaultMessage="1 Lowercase letter" />
            </span>
          </div>
          <div className="user-confirmation-password-hint">
            <GrayCheckIcon />
            <span className="user-confirmation-password-hint-text">
              <FormattedMessage defaultMessage="1 Uppercase letter" />
            </span>
          </div>
          <div className="user-confirmation-sections">
            <Button
              id="user-confirmation--form-submit"
              variant="primary"
              type="submit"
              isDisabled={pristine || submitting}
              className={bigButton}
            >
              <FormattedMessage defaultMessage="Sign up" />
            </Button>
          </div>
          <div className="user-confirmation-sections user-confirmation-footer-text">
            <FormattedMessage defaultMessage="Already have an account?" />
            &nbsp;
            <Link className="user-confirmation-form-link" to="/login">
              <FormattedMessage defaultMessage="Log in" />
            </Link>
          </div>
        </form>
      </AuthPageWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userConfirmationError: state.auth.error,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchConfirmUser: (
    email: string,
    previousPassword: string,
    proposedPassword: string,
  ) => {
    dispatch(confirmUser(email, previousPassword, proposedPassword));
  },
});

const enhance = compose(
  withToaster,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'user-confirmation--form',
    enableReinitialize: true,
  }),
);

export const UserConfirmationPage = enhance(UserConfirmationPageComponent);
