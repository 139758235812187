import { container, goal as goalStyle } from './GoalCard.css';
import { GoalStatusTag } from './GoalStatusTag';
import type { Goal, GoalMeasure, GoalStatus } from './goals.types';
import { useGoalGoalMeasureI18n } from './i18nMapping';

type Props = {
  goal: Goal;
  onEdit: () => void;
};

export function GoalCard({ goal, onEdit }: Props) {
  const goalMeasureI18n = useGoalGoalMeasureI18n();
  return (
    <div
      className={container}
      role="button"
      tabIndex={0}
      onClick={onEdit}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onEdit();
        }
      }}
    >
      <div className={goalStyle.header}>
        <GoalStatusTag status={goal.status as GoalStatus} />
        <div className={goalStyle.measure}>
          {goalMeasureI18n[goal.measure as GoalMeasure]}
        </div>
      </div>
      <div className={goalStyle.title}>{goal.title}</div>
    </div>
  );
}
