import {
  EncounterModuleId,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { draftJsFieldTemplate } from '../../../../template.utils';
import assessmentBody from './assessment_body.hbs';
import notesBody from './notes_body.hbs';

const SHARED_MODULES = {
  [EncounterModuleId.PatientNotes]: {
    notes_body: draftJsFieldTemplate(notesBody),
  },
  [EncounterModuleId.GeneralAssessmentAndPlan]: {
    assessment_body: draftJsFieldTemplate(assessmentBody),
  },
};

export const regularCcmVisit = {
  [TypeOfEncounter.CCM_VISIT]: {
    [ConditionProgram.Hypertension]: { ...SHARED_MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...SHARED_MODULES },
    [ConditionProgram.T2DAndHTN]: { ...SHARED_MODULES },
    [ConditionProgram.CHF]: { ...SHARED_MODULES },
    [ConditionProgram.CCM]: { ...SHARED_MODULES },
  },
};
