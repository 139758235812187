/**
 * Recompose is no longer a supported/maintained package:
 * https://github.com/acdlite/recompose#a-note-from-the-author-acdlite-oct-25-2018
 * We have removed all usages of recompose except for the function `compose`.
 * Here we declared it directly so we can remove our dependency on the npm package.
 */
/** @deprecated */
export function compose(...funcs: Function[]) {
  return funcs.reduce(
    (a, b) =>
      (...args: unknown[]) =>
        a(b(...args)),
    (arg: unknown) => arg,
  );
}
