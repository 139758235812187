import type { ReactNode } from 'react';
import ReactDOMServer from 'react-dom/server';
import { IntlProvider } from 'react-intl';
import TurndownService from 'turndown';

const turndownService = new TurndownService().addRule('div', {
  filter: 'div',
  replacement: (content) => `\n${content}\n`,
});

export function getMarkdownFromNode(node: ReactNode) {
  const previewHtml = ReactDOMServer.renderToString(
    <IntlProvider locale="en">{node}</IntlProvider>,
  );
  return turndownService.turndown(previewHtml);
}

const medsTurndownService = new TurndownService()
  .addRule('div', {
    filter: 'div',
    replacement: (content) => `\n${content}\n`,
  })
  .addRule('strikethrough', {
    filter: ['del', 's'],
    replacement(content) {
      // TODO: test that this shows as strikethrough in EMR
      return `~~${content}~~`;
    },
  })
  .addRule('del-delimeter', {
    // Need to have a separate rule for this because only the last rule that matches a given HTML element will be applied
    filter(node) {
      return node.nodeName === 'DEL' && Boolean(node.previousSibling);
    },
    replacement: (content) => ` ~~${content}~~`,
  })
  .addRule('span-delimeter', {
    filter(node) {
      return node.nodeName === 'SPAN' && Boolean(node.previousSibling);
    },
    replacement: (content) => ` ${content}`,
  });

export function getMedicationsMarkdownFromNode(node: ReactNode) {
  const previewHtml = ReactDOMServer.renderToString(
    <IntlProvider locale="en">{node}</IntlProvider>,
  );
  return medsTurndownService.turndown(previewHtml);
}
