import { color } from '@/shared/tempo/theme';

export type Theme = (typeof THEMES)[number];

export const THEMES = [
  {
    background: color.Brand.Secondary.Purple,
    text: color.Brand.Primary.Navy,
  },
  {
    background: color.Brand.Secondary.Green,
    text: color.Brand.Primary.Navy,
  },
  {
    background: color.Brand.Secondary.Blue,
    text: color.Brand.Primary.Navy,
  },
  {
    background: color.Brand.Primary.Marigold,
    text: color.Brand.Primary.Navy,
  },
  {
    background: color.Brand.Primary.Cream,
    text: color.Brand.Primary.Navy,
  },
] as const;
