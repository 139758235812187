import { FormattedMessage } from 'react-intl';

import { logger } from '@/logger';
import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import {
  hasHtnWithNoBpVitals,
  hasT2dWithScaleOnly,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/condition.utils';
import { Wizard } from '@/shared/common/Wizard';
import type { PatientContextVitalsVitalSigns } from '@/shared/generated/grpcGateway/telemetry.pb';
import { usePatientDetails } from '@/shared/hooks/queries';
import {
  MedManagementDelegationUiMapping,
  useMedManagementDelegation,
} from '@/shared/hooks/queries/med-management.queries';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';
import { useVitalsEngagement } from '@/shared/hooks/useVitalsEngagement';
import { Tag } from '@/shared/tempo/atom/Tag';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import type { Device } from '@/shared/types/device.types';
import { DeviceTypes } from '@/shared/types/device.types';

import { useCNFormContext } from '../../../CNFormContext';
import { VitalsList, isHighDeviceUsage } from '../ProductSupport';
import { useAdditionalScriptContext } from '../vitalEducation/useAdditionalScriptContext';
import { useVitalBasedScript } from '../vitalEducation/useVitalBasedScript';
import { ScriptWrapper } from './scripts/ScriptWrapper';
import { getThirtyDayAvgScripts } from './scripts/thirtyDayAvgScripts';
import { deviceUsageContainer, vitalsList } from './styles.css';

export function SharedVitalProgressIntro() {
  const { patientId } = useCNFormContext();
  const { data: patient } = usePatientDetails(patientId, false);
  const { medDelegation, isLoading: isLoadingMedDelegation } =
    useMedManagementDelegation(patient);
  const [thirtyDayAvgScript, isLoadingScript] = useVitalBasedScript(
    getThirtyDayAvgScripts({
      isProviderManaged:
        medDelegation ===
        MedManagementDelegationUiMapping.OptOutProviderManaged,
    }),
  );

  const { data: patientVitalsContext, isLoading: isLoadingVitalsContext } =
    usePatientVitalsContext(patientId);
  const contextVitals = patientVitalsContext?.contextVitals;
  const {
    conditions,
    devices,
    isLoading: isLoadingAddContext,
  } = useAdditionalScriptContext();

  const hasT2d = conditions.includes(Condition.TypeTwoDiabetes);
  const hasChf = conditions.includes(Condition.CHF);
  const hasGlucometer = devices?.some(
    (device) => device.device_type === DeviceTypes.BLOOD_GLUCOSE_METER,
  );
  const noThirtyDayAvgScript = hasChf || (hasT2d && !hasGlucometer);

  return (
    <Wizard.Step
      sections={[]}
      isLoading={
        isLoadingScript ||
        isLoadingVitalsContext ||
        isLoadingAddContext ||
        isLoadingMedDelegation
      }
    >
      <div className={deviceUsageContainer}>
        <DeviceUsage
          vitalsContext={contextVitals}
          devices={devices}
          conditions={conditions}
        />
      </div>
      {!noThirtyDayAvgScript && (
        <ScriptWrapper>{thirtyDayAvgScript}</ScriptWrapper>
      )}
    </Wizard.Step>
  );
}

type DeviceUsageProps = {
  vitalsContext: PatientContextVitalsVitalSigns | undefined;
  devices: Device[] | undefined;
  conditions: RpmCondition[];
};

function DeviceUsage(props: DeviceUsageProps) {
  const { patientId } = useCNFormContext();

  const { data: vitalsData } = useVitalsEngagement(patientId);
  const isHighUsage = isHighDeviceUsage(vitalsData);

  if (isHighUsage) {
    return (
      <>
        <div>
          <Tag variant="success">
            <FormattedMessage defaultMessage="High device usage" />
          </Tag>
        </div>

        <HighDeviceUsage {...props} />
      </>
    );
  }

  return (
    <>
      <div>
        <Tag variant="danger">
          <FormattedMessage defaultMessage="Low device usage" />
        </Tag>
      </div>

      <LowDeviceUsage {...props} />
    </>
  );
}

export function HighDeviceUsage(props: DeviceUsageProps) {
  const { conditions, devices, vitalsContext } = props;

  if (hasHtnWithNoBpVitals(conditions, vitalsContext)) {
    return (
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="You've done a great job taking your weight often. However, it looks like you haven't taken your blood pressure in the past 30 days. Your averages over the last 30 days are:" />
          <VitalsList className={vitalsList} {...props} />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="As a reminder, taking your blood pressure daily helps us have a clear picture of your blood pressure control." />
        </Script>
      </FieldGroup>
    );
  }

  if (hasT2dWithScaleOnly(conditions, devices)) {
    return (
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Since you've done an excellent job taking your vitals often, we're able to keep an eye on your weight and how you are managing it. Your averages over the last 30 days are:" />
          <VitalsList className={vitalsList} {...props} />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="You're doing a great job stepping on the scale often. Keep up the good work." />
        </Script>
      </FieldGroup>
    );
  }
  const hasChf = conditions.includes(Condition.CHF);

  return (
    <FieldGroup>
      <Script>
        <FormattedMessage
          defaultMessage="Since you've done an excellent job taking your vitals often, we're able to have a clear picture of your {controlPhrase}. Your averages over the last 30 days are:"
          values={{ controlPhrase: getControlPhrase(conditions, devices) }}
        />
        <VitalsList className={vitalsList} {...props} />
      </Script>
      {hasChf && (
        <ReminderMessage
          isHighUsage
          conditions={conditions}
          devices={devices}
        />
      )}
    </FieldGroup>
  );
}

export function LowDeviceUsage(props: DeviceUsageProps) {
  const { conditions, devices, vitalsContext } = props;

  return (
    <FieldGroup>
      <Script>
        {hasVitals(vitalsContext, devices) ? (
          <>
            <FormattedMessage defaultMessage="I noticed you haven't taken readings very often over the past 30 days. From the vitals you did take, your averages over the last 30 days are:" />
            <VitalsList className={vitalsList} {...props} />
          </>
        ) : (
          <FormattedMessage defaultMessage="It looks like you haven't taken any vitals in the past 30 days." />
        )}
      </Script>
      <ReminderMessage
        isHighUsage={false}
        conditions={conditions}
        devices={devices}
      />
    </FieldGroup>
  );
}

function hasVitals(
  vitalsContext: PatientContextVitalsVitalSigns | undefined,
  devices: Device[] | undefined,
) {
  if (!devices) {
    return false;
  }

  return devices?.some((device) => {
    switch (device.device_type) {
      case DeviceTypes.BLOOD_GLUCOSE_METER:
        return !!vitalsContext?.bloodGlucose?.avg30d;
      case DeviceTypes.WEIGHT_SCALE:
        return !!vitalsContext?.weight?.avg30d;
      case DeviceTypes.BLOOD_PRESSURE_METER:
        return !!vitalsContext?.bloodPressure?.systolic?.avg30d;
      default:
        return false;
    }
  });
}

function ReminderMessage({
  conditions,
  isHighUsage,
  devices,
}: {
  conditions: RpmCondition[];
  devices: Device[] | undefined;
  isHighUsage: boolean;
}) {
  if (hasT2dWithScaleOnly(conditions, devices)) {
    return (
      <Script>
        <FormattedMessage defaultMessage="As part of the management of your blood sugar, weight management is important to keeping you on track. Stepping on the scale every day allows us to keep an eye on your weight and how that corresponds to your diet and activity level." />
      </Script>
    );
  }
  const hasChf = conditions.includes(Condition.CHF);
  const highUsageMessage = isHighUsage ? (
    <FormattedMessage defaultMessage=" You're doing a great job taking your vitals often. Keep up the good work." />
  ) : null;

  if (hasChf) {
    return (
      <>
        <Script>
          <FormattedMessage defaultMessage="Heart Failure symptoms are generally related to fluid build up or reduced blood flow to parts of the body. For example, sudden weight changes or rapid heart rate can be signs of worsening heart failure." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="Taking your vitals daily helps us have a clear picture of your blood pressure and weight as it relates to your heart condition control." />
          {highUsageMessage}
        </Script>
      </>
    );
  }

  return (
    <Script>
      <FormattedMessage
        defaultMessage="As a reminder, taking your vitals daily helps us have a clear picture of your {controlPhrase}."
        values={{ controlPhrase: getControlPhrase(conditions, devices) }}
      />
      {highUsageMessage}
    </Script>
  );
}

function getControlPhrase(
  conditions: RpmCondition[],
  devices: Device[] | undefined,
) {
  const hasChf = conditions.includes(Condition.CHF);
  const hasHtn = conditions.includes(Condition.Hypertension);
  const hasT2d = conditions.includes(Condition.TypeTwoDiabetes);
  if (hasChf) {
    return (
      <FormattedMessage defaultMessage="blood pressure and weight as it relates to your heart condition control" />
    );
  }
  if (hasHtn && hasT2d) {
    return (
      <FormattedMessage defaultMessage="blood sugar and blood pressure control" />
    );
  }
  if (hasHtn) {
    return <FormattedMessage defaultMessage="blood pressure control" />;
  }
  if (hasT2d) {
    const hasGlucometer = devices?.some(
      (device) => device.device_type === DeviceTypes.BLOOD_GLUCOSE_METER,
    );
    return hasGlucometer ? (
      <FormattedMessage defaultMessage="blood sugar control" />
    ) : (
      <FormattedMessage defaultMessage="diabetes management" />
    );
  }

  logger.error(`Unexpected conditions ${conditions.join(', ')}`);
  return null;
}
