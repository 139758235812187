import { Toast as ToastBase } from './Toast';
import { ToastProvider } from './ToastProvider';
import { ToasterContextConsumer } from './ToasterContext';

export const Toast = Object.assign(ToastBase, {
  Provider: ToastProvider,
  Consumer: ToasterContextConsumer,
});

export { useToaster } from './ToasterContext';
