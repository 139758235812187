import cx from 'classnames';
import type { ReactNode } from 'react';

import { breadcrumbText } from './Active.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export function Active({ children, className }: Props) {
  return <span className={cx(breadcrumbText, className)}>{children}</span>;
}
