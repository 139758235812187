/* eslint-disable react/destructuring-assignment */
const TableIcon = (props: { active: boolean }) => {
  const strokeColor = props.active ? '#BDBDBD' : '#2458C5';
  const fillColor = props.active ? 'none' : '#2458C5';

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.05" width="32" height="32" rx="8" fill={fillColor} />
      <path
        d="M23 7H9C7.89543 7 7 7.89543 7 9V23C7 24.1046 7.89543 25 9 25H23C24.1046 25 25 24.1046 25 23V9C25 7.89543 24.1046 7 23 7Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 13H25" stroke={strokeColor} strokeWidth="1.5" />
      <path d="M7 19H25" stroke={strokeColor} strokeWidth="1.5" />
      <path d="M16 7L16 25" stroke={strokeColor} strokeWidth="1.5" />
    </svg>
  );
};

export default TableIcon;
