import type { AriaToastProps } from '@react-aria/toast';
import { useToast } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { useRef } from 'react';

import { Toast } from './Toast';
import type { ToastContents } from './types';

interface Props extends AriaToastProps<ToastContents> {
  className?: string;
  state: ToastState<ToastContents>;
}

export function StatefulToast({ state, className, ...props }: Props) {
  const ref = useRef(null);
  const aria = useToast(props, state, ref);

  const { variant, content, title } = props.toast.content;

  return (
    <Toast variant={variant} aria={aria} title={title} ref={ref}>
      {content}
    </Toast>
  );
}
