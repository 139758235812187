import { useFlags } from '@/shared/hooks';
import { EHR } from '@/shared/types/ehr.types';

export function useShouldShowRpaButton(
  ehr: Maybe<EHR>,
  healthSystemId: Maybe<string>,
) {
  const { enableRpaChromeExtension, rpaAllowedHealthSystems } = useFlags();

  return (
    ehr === EHR.Athena &&
    rpaAllowedHealthSystems.includes(healthSystemId || '') &&
    enableRpaChromeExtension
  );
}
