import type { Patient } from './patient.types';
import type { BloodGlucoseReadingQualifier } from './vitals.types';

export enum ReadingDataType {
  BloodPressure = 'BLOOD_PRESSURE',
  BloodGlucose = 'BLOOD_GLUCOSE',
  HeartRate = 'HEART_RATE',
  Weight = 'WEIGHT',
}

export enum BloodPressureValueType {
  Diastolic = 'diastolic',
  Systolic = 'systolic',
}

export type GenericPatientSummaryReading = NonNullable<
  Unpacked<Patient['patient_summary']>
>;

interface PreviousReading {
  reading_id: string;
  reading_timezone: string;
  reading_utc_timestamp: string;
  reading_timestamp: string;
}

interface BloodPressureLastReading {
  value: string;
  diastolic: number;
  systolic: number;
}
type BloodPressurePreviousReading = PreviousReading & BloodPressureLastReading;

interface BloodGlucoseLastReading {
  value: string;
  glucose_level: number;
  reading_qualifier: BloodGlucoseReadingQualifier;
}
type BloodGlucosePreviousReading = PreviousReading & BloodGlucoseLastReading;

interface WeightLastReading {
  value: string;
  weight: number;
}
type WeightPreviousReading = PreviousReading & WeightLastReading;

interface HeartRateLastReading {
  value: string;
  pulse: number;
  regular: boolean;
}
type HeartRatePreviousReading = PreviousReading & HeartRateLastReading;

export interface PatientSummaryBloodPressureReading
  extends GenericPatientSummaryReading {
  previous_readings: Nullable<BloodPressurePreviousReading[]>;
  data_type: ReadingDataType.BloodPressure;
  last_reading: BloodPressureLastReading;
}

export interface PatientSummaryBloodGlucoseReading
  extends GenericPatientSummaryReading {
  previous_readings: Nullable<BloodGlucosePreviousReading[]>;
  data_type: ReadingDataType.BloodGlucose;
  last_reading: BloodGlucoseLastReading;
}
export interface PatientSummaryWeightReading
  extends GenericPatientSummaryReading {
  previous_readings: Nullable<WeightPreviousReading[]>;
  data_type: ReadingDataType.Weight;
  last_reading: WeightLastReading;
}

export interface PatientSummaryHeartRateReading
  extends GenericPatientSummaryReading {
  previous_readings: Nullable<HeartRatePreviousReading[]>;
  data_type: ReadingDataType.HeartRate;
  last_reading: HeartRateLastReading;
}

export type PatientSummaryReading =
  | PatientSummaryBloodPressureReading
  | PatientSummaryBloodGlucoseReading
  | PatientSummaryWeightReading
  | PatientSummaryHeartRateReading;

export type PatientSummary = PatientSummaryReading[];
