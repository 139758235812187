import type { WidgetProps } from '@rjsf/core';
import { format, getYear, isValid } from 'date-fns';

import { DatePicker } from '@/shared/common/DatePicker';

import { INVALID_DATE } from '../SchemaDrivenForm';
import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';
import { hasValidationErrorFormContext } from '../validationErrorUtils';

export function DateWidget({
  onChange: rjsfOnChange,
  value,
  formContext,
  label,
  id,
}: WidgetProps) {
  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  const hasError = hasValidationErrorFormContext(formContext, label, id);

  // Add 'T12:00' to value because DatePicker uses Date(dateString) constructor
  // internally, and date-only strings (e.g. "1970-01-01") are treated as
  // UTC which will thereby cause the DatePicker to incorrectly show the prior
  // date for users in western timezones, whereas date-time strings (e.g.
  // "1970-01-01T12:00") are treated as local. See
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#parameters
  const datePickerValue = value ? `${value}T12:00` : null;

  return (
    <DatePicker
      inputFormat="MM/dd/yyyy"
      value={datePickerValue}
      isError={hasError}
      onChange={(newValue: unknown) => {
        if (!newValue) {
          onChange(undefined);
          return;
        }

        const sanitizedValue =
          newValue instanceof Date &&
          isValid(newValue) &&
          getYear(newValue) >= 1000
            ? newValue
            : null;
        if (sanitizedValue) {
          onChange(format(sanitizedValue, 'yyyy-MM-dd'));
        } else {
          onChange(INVALID_DATE);
        }
      }}
    />
  );
}
