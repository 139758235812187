import { useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { MedicationForm } from '../forms/MedicationForm';
import type { MedPayload } from '../forms/editMedicationFormConfig';
import { RxNormRestriction } from '../forms/types';
import { useReviewMedication } from '../patientMedications.queries';
import { modalBodyContainer } from '../styles.css';

export function AddMedicationModal({
  onClose,
  existingReferencedMedIds,
}: {
  onClose: () => void;
  existingReferencedMedIds: Maybe<string>[];
}) {
  const intl = useIntl();
  const { toaster } = useToaster();

  const reviewMedMutation = useReviewMedication();

  function handleSubmitForm(payload: MedPayload) {
    reviewMedMutation.mutate(payload, {
      onSuccess: () => {
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Successfully added medication',
          }),
        );
        onClose();
      },
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: `Failed to add medication: {message}`,
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({ defaultMessage: 'Add missing medication' })}
      />
      <Modal.Body className={modalBodyContainer}>
        <MedicationForm
          onSubmit={handleSubmitForm}
          onClose={onClose}
          isProcessing={reviewMedMutation.isLoading}
          rxNormRestriction={RxNormRestriction.NON_EXISTING_MEDS_FREE_TEXT}
          existingReferencedMedIds={existingReferencedMedIds}
        />
      </Modal.Body>
    </Modal>
  );
}
