import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import { chf } from '../../1.0/initialNpVisit/chf';
import { htn } from './htn';
import { t2d } from './t2d';
import { t2dAndHtn } from './t2dAndHtn';

export const initialNpVisit = {
  [TypeOfEncounter.INITIAL_NP_VISIT]: {
    ...htn,
    ...t2d,
    ...t2dAndHtn,
    ...chf,
  },
};
