import type { IntlShape } from 'react-intl';

import { CHF_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/chfVisit';
import type { EndCallFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EndCall/formConfig';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { cnEarlyEndCall } from '../../shared/cnEarlyEndCall';

export function chfVisitEarlyEndCall(data: SectionStepsState, intl: IntlShape) {
  const endCallData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/end-call',
    '/index',
  )<EndCallFormFields>();

  return cnEarlyEndCall({
    intl,
    endCallData,
    sectionData: data,
    sections: CHF_VISIT_SECTIONS,
  });
}
