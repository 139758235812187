import cx from 'classnames';
import type { DraftEditorCommand } from 'draft-js';
import { Editor, EditorState, RichUtils } from 'draft-js';
import * as Immutable from 'immutable';
import type { FocusEventHandler } from 'react';
import { Component } from 'react';
import type { WrappedFieldProps } from 'redux-form';

import { FormControl, FormHelperText } from '@/deprecated/mui';
import BoldIcon from '@/shared/assets/icons/BoldIcon';
import BulletPointListIcon from '@/shared/assets/icons/BulletPointListIcon';
import HighlightColorIcon from '@/shared/assets/icons/HighlightColorIcon';
import ItalicIcon from '@/shared/assets/icons/ItalicIcon';
import LinkIcon from '@/shared/assets/icons/LinkIcon';
import NumberListIcon from '@/shared/assets/icons/NumberListIcon';
import StrikethroughIcon from '@/shared/assets/icons/StrikethroughIcon';
import UnderlineIcon from '@/shared/assets/icons/UnderlineIcon';
import { display } from '@/shared/jsStyle/utils.css';

import { editorContainer, styleControlsContainer } from './EditorRTF.css';
import './EditorRTF.scss';
import { onAddLink } from './RtfLink/utils';
import { RTF_STYLE_MAP } from './constants';
import { CustomBlockType, RtfEditorStyles } from './types';

type EditorRTFProps = {
  editorState: EditorState;
  onEditorChange: (newEditorState: EditorState) => void;
  fullWidth: boolean;
  hasError?: boolean;
  onBlur?: FocusEventHandler;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
} & Partial<WrappedFieldProps>;

export class EditorRTF extends Component<EditorRTFProps> {
  constructor(props: EditorRTFProps) {
    super(props);

    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.toggleBoldStyle = this.toggleBoldStyle.bind(this);
    this.toggleItalicStyle = this.toggleItalicStyle.bind(this);
    this.toggleUnderlineStyle = this.toggleUnderlineStyle.bind(this);
    this.toggleLineThroughStyle = this.toggleLineThroughStyle.bind(this);
    this.toggleHighlightColor = this.toggleHighlightColor.bind(this);
    this.toggleBulletPoints = this.toggleBulletPoints.bind(this);
    this.toggleNumberListPoints = this.toggleNumberListPoints.bind(this);
  }

  componentDidUpdate(prevProps: EditorRTFProps) {
    const { editorState, onEditorChange } = this.props;
    const { editorState: prevEditorState } = prevProps;

    if (editorState !== prevEditorState) {
      const currentBlockType = RichUtils.getCurrentBlockType(editorState);
      const startKey = editorState.getSelection().getStartKey();
      const selectedBlock = editorState
        .getCurrentContent()
        .getBlockForKey(startKey);

      // Set the initial styling state of an empty 'ClearFormat' block type to empty styles
      if (
        currentBlockType === CustomBlockType.ClearFormat &&
        selectedBlock.getText() === '' &&
        startKey !== prevEditorState.getSelection().getStartKey()
      ) {
        onEditorChange(
          EditorState.setInlineStyleOverride(
            editorState,
            Immutable.OrderedSet<string>(),
          ),
        );
      }
    }
  }

  handleKeyCommand(command: DraftEditorCommand) {
    const { editorState, onEditorChange } = this.props;
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (newEditorState) {
      onEditorChange(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  }

  toggleBoldStyle() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(
      RichUtils.toggleInlineStyle(editorState, RtfEditorStyles.BOLD),
    );
  }

  toggleItalicStyle() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(
      RichUtils.toggleInlineStyle(editorState, RtfEditorStyles.ITALIC),
    );
  }

  toggleUnderlineStyle() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(
      RichUtils.toggleInlineStyle(editorState, RtfEditorStyles.UNDERLINE),
    );
  }

  toggleLineThroughStyle() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(
      RichUtils.toggleInlineStyle(editorState, RtfEditorStyles.STRIKETHROUGH),
    );
  }

  toggleHighlightColor() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(
      RichUtils.toggleInlineStyle(editorState, RtfEditorStyles.HIGHLIGHT),
    );
  }

  toggleBulletPoints() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(
      RichUtils.toggleBlockType(editorState, 'unordered-list-item'),
    );
  }

  toggleNumberListPoints() {
    const { onEditorChange, editorState } = this.props;
    onEditorChange(RichUtils.toggleBlockType(editorState, 'ordered-list-item'));
  }

  render() {
    const {
      onBlur,
      onEditorChange,
      editorState,
      meta,
      fullWidth,
      hasError,
      className,
      placeholder,
      isDisabled,
    } = this.props;
    const touched = meta?.touched;
    const error = meta?.error;
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);

    return (
      <FormControl fullWidth={fullWidth}>
        <div
          className={cx(
            'editor-container',
            { 'error-style': hasError, [editorContainer.disabled]: isDisabled },
            className,
          )}
        >
          <Editor
            onBlur={onBlur}
            customStyleMap={RTF_STYLE_MAP}
            editorState={editorState}
            onChange={onEditorChange}
            handleKeyCommand={this.handleKeyCommand}
            placeholder={placeholder}
            spellCheck
            readOnly={isDisabled}
          />
        </div>
        <div
          className={cx(styleControlsContainer, { [display.none]: isDisabled })}
        >
          <div>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active': currentInlineStyle.has(
                  RtfEditorStyles.BOLD,
                ),
              })}
              onClick={this.toggleBoldStyle}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <BoldIcon />
            </button>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active': currentInlineStyle.has(
                  RtfEditorStyles.ITALIC,
                ),
              })}
              onClick={this.toggleItalicStyle}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <ItalicIcon />
            </button>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active': currentInlineStyle.has(
                  RtfEditorStyles.UNDERLINE,
                ),
              })}
              onClick={this.toggleUnderlineStyle}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <UnderlineIcon />
            </button>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active': currentInlineStyle.has(
                  RtfEditorStyles.STRIKETHROUGH,
                ),
              })}
              onClick={this.toggleLineThroughStyle}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <StrikethroughIcon />
            </button>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active': currentInlineStyle.has(
                  RtfEditorStyles.HIGHLIGHT,
                ),
              })}
              onClick={this.toggleHighlightColor}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <HighlightColorIcon />
            </button>
          </div>
          <span className="editor-styles-divider">|</span>
          <div>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active':
                  currentBlockType === 'unordered-list-item',
              })}
              onClick={this.toggleBulletPoints}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <BulletPointListIcon />
            </button>
            <button
              type="button"
              className={cx('editor-styles-button', {
                'editor-styles-button--active':
                  currentBlockType === 'ordered-list-item',
              })}
              onClick={this.toggleNumberListPoints}
              onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
            >
              <NumberListIcon />
            </button>
          </div>
          <span className="editor-styles-divider">|</span>
          <button
            type="button"
            className="editor-styles-button"
            onClick={() => onAddLink(editorState, onEditorChange)}
            onMouseDown={(e) => e.preventDefault()} // workaround for editor losing focus and not applying style.
          >
            <LinkIcon />
          </button>
        </div>
        {touched && error && (
          <FormHelperText error>{error.message}</FormHelperText>
        )}
      </FormControl>
    );
  }
}
