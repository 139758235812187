import { CHF_VISIT_SECTIONS } from '../../metadata';
import { SharedChartPrep } from '../../shared/ChartPrep';
import { chfVisitPath } from '../paths';

export function ChartPrep() {
  return (
    <SharedChartPrep
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/intro', '/patient-attendance')}
    />
  );
}
