import type { TemplateDelegate } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/types';
import type { WizardFormData } from '@/shared/common/Wizard/state';

import { patientSummaryPartial, vitalsListPartial } from '../files/partials';
import type { CnTemplateContext } from '../types';
import { engine } from './engine';

type TemplateCtx = WizardFormData & CnTemplateContext;

export function markdownFieldTemplate(
  template: TemplateSpecification,
): TemplateDelegate<string, TemplateCtx> {
  return (context: TemplateCtx) =>
    postprocessMarkdown(engine.template(template)(context));
}

function postprocessMarkdown(markdown: string) {
  // In Athena, if there are multiple newlines separating bullet lists,
  // then it will break formatting, need to join them back together
  return markdown.replace(/(\n\s*[-*]\s+)+/g, '\n- ');
}

type PartialsDeclareSpec = Record<
  string,
  TemplateDelegate<string, WizardFormData>
>;

export const TEMPLATE_PARTIALS: PartialsDeclareSpec = {
  patient_summary: markdownFieldTemplate(patientSummaryPartial),
  vitals_list: markdownFieldTemplate(vitalsListPartial),
};
