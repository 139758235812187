/* eslint-disable react-refresh/only-export-components */
import orderBy from 'lodash/orderBy';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import ArrowUpRight from '@/shared/assets/svgs/arrow-up-right.svg?react';
import { UserAvatar } from '@/shared/common/UserAvatar';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import type { CareProvider } from '@/shared/tasking/types';

import { useFormatProviderNameOrMyself } from './useFormatProviderNameOrMyself';

export const ChooseUser = 'choose_user' as const;
export const CurrentUser = 'current_user' as const;
type ProviderId = string;
type TMenuItem = {
  key: ProviderId | typeof ChooseUser | typeof CurrentUser;
  icon: ReactNode;
  label: ReactNode;
};

export function useMenuItems(participants: CareProvider[]): TMenuItem[] {
  const intl = useIntl();
  const { currentUser, currentUserId } = useCurrentUser();
  const formatProviderNameOrMyself = useFormatProviderNameOrMyself();

  return [
    // All participants except myself
    ...orderBy(
      participants
        .filter(({ uid }) => uid && uid !== currentUserId)
        .map((p) => ({
          key: p.uid,
          icon: (
            <UserAvatar
              size="small"
              firstName={p.givenName}
              lastName={p.familyName}
            />
          ),
          label: formatProviderNameOrMyself(p, currentUserId),
        })),
      (p) => p.label,
      'asc',
    ),
    // Current User
    {
      key: CurrentUser,
      icon: (
        <UserAvatar
          size="small"
          firstName={currentUser.given_name}
          lastName={currentUser.family_name}
        />
      ),
      label: intl.formatMessage({ defaultMessage: 'Myself' }),
    },
    // Choose Person Action
    {
      key: ChooseUser,
      // Use current color to get the color coming from the menu's listStyles
      icon: <ArrowUpRight fill="currentColor" />,
      label: intl.formatMessage({ defaultMessage: 'Choose another user' }),
    },
  ];
}
