import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  type ChangeEncounterTypeState,
  ConfirmEncounterTypeChangeDialog,
} from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/TypeOfEncounterForm';
import { TEMPLATE_HELPERS } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/engine';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { useFlags } from '@/shared/hooks';
import {
  usePatientCareProviders,
  usePatientDetails,
} from '@/shared/hooks/queries';
import { usePatientContext } from '@/shared/hooks/queries/patientContext.queries';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';
import { displayHomeState } from '@/shared/patient/patientInfoUtils';
import { Select } from '@/shared/tempo/@labs/molecule/Select';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';
import { ProviderType } from '@/shared/types/provider.types';

import { useCNFormContext } from '../CNWizardForm/CNFormContext';
import type { CNVisitType } from '../CNWizardForm/types';
import { formatProviderForScript } from '../shared/script.utils';
import {
  cadenceClinicians,
  cnVisitSelect,
  container,
  patientDetails,
} from './CNNotesHeading.css';

type Props = {
  isChf: boolean;
  cnVisitType: CNVisitType;
  onVisitTypeChange: (visit: CNVisitType) => void;
};

export function CNNotesHeading({
  isChf,
  onVisitTypeChange,
  cnVisitType,
}: Props) {
  const intl = useIntl();
  const [changeEncounterTypeState, setChangeEncounterTypeState] =
    useState<ChangeEncounterTypeState>({ modalOpen: false });
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { data: patient, isLoading: isLoadingDetails } = usePatientDetails(
    patientId,
    false,
  );
  const { data: cadenceCareProviders, isLoading: isLoadingCareProviders } =
    usePatientCareProviders(patientId, {
      providerType: ProviderType.Cadence,
    });

  const careProviderNames = cadenceCareProviders?.care_providers.map((cp) =>
    formatProviderForScript(cp.first_name, cp.last_name, cp.role),
  );

  const isLoadingPatientSummary =
    isLoadingDetails || isLoadingContext || !patient || !context;

  const rpmPrograms = getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );

  const formatGender = () => {
    if (patient?.gender === 'OTHER') {
      return intl.formatMessage({
        defaultMessage: '(Gender specified as "Other")',
      });
    }
    if (patient?.gender === 'X') {
      return intl.formatMessage({
        defaultMessage: '(Gender specified as "X")',
      });
    }

    return TEMPLATE_HELPERS.fmtGender(patient?.gender);
  };

  const visitTypes = useVisitTypes(isChf);

  return (
    <>
      <ConfirmEncounterTypeChangeDialog
        state={changeEncounterTypeState}
        onClose={() => setChangeEncounterTypeState({ modalOpen: false })}
      />
      <div className={container}>
        <Select
          className={cnVisitSelect}
          selectedKey={cnVisitType}
          isDisabled={changeEncounterTypeState.modalOpen}
          onSelectionChange={(key) => {
            setChangeEncounterTypeState({
              type: 'cn',
              modalOpen: true,
              onConfirm: () => {
                onVisitTypeChange(`${key}` as CNVisitType);
                setChangeEncounterTypeState({ modalOpen: false });
              },
            });
          }}
          items={visitTypes}
          aria-label={intl.formatMessage({ defaultMessage: 'Appointments' })}
        >
          {(item) => <Select.Option key={item.key}>{item.label}</Select.Option>}
        </Select>
        <div className={patientDetails}>
          {isLoadingPatientSummary ? (
            <Skeleton variant="text" />
          ) : (
            <FormattedMessage
              defaultMessage="{patientName} {isAnAgeYearOld} {gender} enrolled in Cadence RPM at {clinicName} for {conditions} on {rpmConsentDate}. The supervising provider is {primaryProviderName}. {patientFirstName}'s home state is {homeState}."
              values={{
                patientName: `${patient.first_name} ${patient.last_name}`,
                isAnAgeYearOld: TEMPLATE_HELPERS.isAnAgeYearOld(patient.dob),
                gender: formatGender(),
                clinicName: context.clinicName,
                conditions: TEMPLATE_HELPERS.fmtRpmConditions(rpmPrograms),
                rpmConsentDate: TEMPLATE_HELPERS.date(
                  context.rpmConsentDateTime,
                  patient.timezone,
                ),
                primaryProviderName: formatProviderForScript(
                  context.primaryPhysicianGivenName,
                  context.primaryPhysicianFamilyName,
                  context.primaryPhysicianRole,
                ),
                patientFirstName: patient.first_name,
                homeState: displayHomeState(patient),
              }}
            />
          )}
        </div>
        <div className={cadenceClinicians}>
          {isLoadingCareProviders ? (
            <Skeleton variant="text" width="40%" />
          ) : (
            <FormattedMessage
              defaultMessage="Cadence Clinicians: {careProviderNames}"
              values={{
                careProviderNames:
                  careProviderNames?.join(', ') ||
                  intl.formatMessage({ defaultMessage: 'N/A' }),
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

function useVisitTypes(isChf: boolean) {
  const intl = useIntl();
  const { cycle17InitialCnVisit, removeManualTimeTracking, asyncTitrations } =
    useFlags();
  let result;

  if (isChf) {
    result = [
      {
        key: cycle17InitialCnVisit
          ? TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT
          : TypeOfEncounter.INITIAL_CN_CHF_VISIT,
        label: intl.formatMessage({
          defaultMessage: 'Initial CN CHF Visit',
        }),
      },
      {
        key: TypeOfEncounter.CN_CHF_VISIT,
        label: intl.formatMessage({
          defaultMessage: 'CN CHF Visit',
        }),
      },
    ];
  } else {
    result = [
      {
        key: cycle17InitialCnVisit
          ? TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT
          : TypeOfEncounter.INITIAL_CN_VISIT,
        label: intl.formatMessage({
          defaultMessage: 'Initial CN Visit',
        }),
      },
      {
        key: TypeOfEncounter.CN_VISIT,
        label: intl.formatMessage({
          defaultMessage: 'Regular CN Visit',
        }),
      },
    ];
  }

  if (removeManualTimeTracking) {
    result.push({
      key: TypeOfEncounter.PATIENT_FOLLOW_UP,
      label: intl.formatMessage({
        defaultMessage: 'Patient follow-up',
      }),
    });
  }

  if (asyncTitrations) {
    result.push({
      key: TypeOfEncounter.CN_TITRATION_OUTREACH,
      label: intl.formatMessage({ defaultMessage: 'Titration outreach' }),
    });
  }

  return result;
}
