import type { Goal } from './goals.types';

export enum GoalModalType {
  CreateGoal = 'create_goal',
  EditGoal = 'edit_goal',
}

export type GoalModalState =
  | { type: GoalModalType.CreateGoal; defaults?: Partial<Goal> }
  | { type: GoalModalType.EditGoal; goal: Goal };
