import cx from 'classnames';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { MinutesPicker } from '@/shared/common/MinutesPicker';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { fullWidth } from '@/shared/jsStyle/utils.css';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import type { NoteFieldValidationResult } from '../../validation';
import { TimeTrackerLabel } from '../TimeTrackerLabel';
import { ValidationMessage } from '../ValidationMessage';
import {
  container,
  headerDescription,
  headerTextContainer,
  headerTitle,
  iconContainer,
  minutesPickerContainer,
  tooltip as tooltipClass,
  validationError,
} from './TimeTrackerMinutesPicker.css';

type Props = {
  icon: ReactNode;
  description?: ReactNode;
  label: ReactNode;
  onChange: (value?: number) => void;
  value?: number;
  tooltip?: string;
  isDisabled?: boolean;
  fieldValidationResult?: NoteFieldValidationResult;
};

export function TimeTrackerMinutesPicker({
  onChange,
  value,
  icon,
  label,
  tooltip,
  isDisabled,
  description,
  fieldValidationResult,
}: Props) {
  const messageOverride = useValidationMessageOverride(fieldValidationResult);
  return (
    <div className={cx(flexContainer.row, fullWidth)}>
      <div className={iconContainer}>{icon}</div>
      <div className={container}>
        <div className={headerTextContainer}>
          <TimeTrackerLabel
            message={label}
            className={headerTitle}
            error={Boolean(fieldValidationResult)}
          />
          <div className={headerDescription}>{description}</div>
          <div>
            <ValidationMessage
              validationMessage={fieldValidationResult?.message}
              messageOverride={messageOverride}
              className={validationError}
            />
          </div>
        </div>
        <div className={minutesPickerContainer}>
          <Tooltip content={tooltip} placement="left" className={tooltipClass}>
            <MinutesPicker
              min={0}
              value={value}
              onChange={onChange}
              isDisabled={isDisabled}
              error={Boolean(fieldValidationResult)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

function useValidationMessageOverride(
  fieldValidationResult?: NoteFieldValidationResult,
) {
  switch (fieldValidationResult?.type) {
    case 'required':
      return <FormattedMessage defaultMessage="Required" />;
    case 'max':
      return <FormattedMessage defaultMessage="Must be 1-120 mins" />;
    default:
      return null;
  }
}
