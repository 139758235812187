import { parseISO } from 'date-fns';
import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import {
  anyFieldIsPopulated,
  validators,
} from '@/shared/common/Form/validations';
import {
  CYCLICAL_DEPENDENCIES as ADDRESS_CYCLICAL_DEPENDENCIES,
  addressValidation,
} from '@/shared/common/FormSections/MailingAddress';
import type { AddressValues } from '@/shared/common/FormSections/MailingAddress/formUtils';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';
import { Language } from '@/shared/types/language.types';
import type { NPI } from '@/shared/types/npi.types';
import type { Patient } from '@/shared/types/patient.types';
import {
  Accommodation,
  EnrollmentType,
  Gender,
  PatientStatus,
  Race,
  Relationship,
  Residence,
} from '@/shared/types/patient.types';
import type { MedManagementDelegation } from '@/shared/types/shared.types';
import { PhoneType } from '@/shared/types/shared.types';
import type { TimePeriod } from '@/shared/utils/time-helpers';
import { map24HTimeFormatTo12H } from '@/shared/utils/time-helpers';

import { getProgramConsentAndInfoFields } from '../ConsentTab/consentTabFormConfig';
import {
  EMERGENCY_CONTACT_CYCLICAL_DEPENDENCIES,
  getEmergencyContactDependentFields,
} from './emergencyContactUtils';
import {
  isAccommodationsOtherRequired,
  isContactInformationRequired,
  isResidenceOtherRequired,
} from './formUtils';

const DEFAULT_VITAL_REMINDER_TIME = '08:00:00';

export function getPatientFormConfig(
  intl: IntlShape,
  flags: FeatureFlagSet,
  maxDate: Date,
  isUpdate: boolean,
  patient?: Patient,
  npi?: NPI,
) {
  const dateMsg = intl.formatMessage({
    defaultMessage: 'Must be today or a valid date in the past',
  });

  return {
    fields: {
      ...getCreateFields(intl, maxDate, dateMsg, patient),
      ...(isUpdate &&
        getUpdateFields(intl, flags, maxDate, dateMsg, patient, npi)),
    },
    cyclicalDependencies: [
      ...ADDRESS_CYCLICAL_DEPENDENCIES,
      ...EMERGENCY_CONTACT_CYCLICAL_DEPENDENCIES,
    ],
  };
}

function getCreateFields(
  intl: IntlShape,
  maxDate: Date,
  dateMsg: string,
  patient?: Patient,
) {
  const preferredPhoneMsg = intl.formatMessage({
    defaultMessage: 'Preferred phone is required',
  });
  const mobile = patient?.contacts?.find(
    ({ emergency, contact }) =>
      !emergency && contact?.phone_type === PhoneType.Cellular,
  )?.contact;
  const home = patient?.contacts?.find(
    ({ emergency, contact }) =>
      !emergency && contact?.phone_type === PhoneType.Landline,
  )?.contact;
  const preferredPhoneType = patient?.has_caregiver
    ? PreferCaregiverContact
    : patient?.contacts?.find(({ primary }) => primary)?.contact?.phone_type;
  const { date, email, required, phoneNumber, phoneType, maxLengthString } =
    validators(intl);

  const isAnyContactRequired = isContactInformationRequired(patient);

  return {
    first_name: {
      defaultValue: patient?.first_name || '',
      validation: required(maxLengthString({ maxLength: 255 })),
    },
    last_name: {
      defaultValue: patient?.last_name || '',
      validation: required(maxLengthString({ maxLength: 255 })),
    },
    dob: {
      defaultValue: patient?.dob ? parseISO(patient.dob) : null,
      validation: required(date({ maxDate, maxDateErrorMessage: dateMsg })),
    },
    mobile_number: {
      defaultValue: mobile?.phone_number || '',
      validation: isAnyContactRequired
        ? phoneNumber().when('preferred_phone', {
            is: (preferred_phone: PhoneType | typeof PreferCaregiverContact) =>
              preferred_phone !== PreferCaregiverContact,
            then: (schema) => schema.required(preferredPhoneMsg),
          })
        : phoneNumber().when('preferred_phone', {
            is: PhoneType.Cellular,
            then: (schema) => schema.required(preferredPhoneMsg),
          }),
    },
    has_caregiver: { defaultValue: Boolean(patient?.has_caregiver) },
    phone_number: {
      defaultValue: home?.phone_number || '',
      validation: phoneNumber().when('preferred_phone', {
        is: PhoneType.Landline,
        then: (schema) => schema.required(preferredPhoneMsg),
      }),
    },
    preferred_phone: {
      defaultValue: preferredPhoneType || '',
      validation: isAnyContactRequired
        ? // only allows types specified in phoneType's oneOf
          required(
            phoneType().concat(yup.string().oneOf([PreferCaregiverContact])),
          )
        : yup.string(),
    },
    email: {
      defaultValue: patient?.email || '',
      validation: email(),
    },
    is_test: {
      defaultValue: !!patient?.is_test || false,
      validation: yup.boolean(),
    },
  };
}

export function getCommunicationPreferencesFields(
  patient: Patient | undefined,
) {
  const notificationSettings = patient?.notification_settings;
  const [reminderHour, reminderAmPm] = map24HTimeFormatTo12H(
    patient?.vitals_reminders_time_local || DEFAULT_VITAL_REMINDER_TIME,
  );
  return {
    sms_consent: {
      defaultValue: notificationSettings?.sms_consent ?? true,
      validation: yup.boolean(),
    },
    vitals_confirmation: {
      defaultValue: notificationSettings?.vitals_confirmation ?? true,
      validation: yup.boolean(),
    },
    vitals_value: {
      defaultValue: notificationSettings?.vitals_value ?? true,
      validation: yup.boolean(),
    },
    streaks: {
      defaultValue: notificationSettings?.streaks ?? true,
      validation: yup.boolean(),
    },
    bingo: {
      defaultValue: notificationSettings?.bingo ?? false,
      validation: yup.boolean(),
    },
    weekly_summary: {
      defaultValue: notificationSettings?.weekly_summary ?? true,
      validation: yup.boolean(),
    },
    vitals_reminder: {
      defaultValue: notificationSettings?.vitals_reminder ?? true,
      validation: yup.boolean(),
    },
    appointments_reminder: {
      defaultValue: notificationSettings?.appointments_reminder ?? true,
      validation: yup.boolean(),
    },
    vitals_reminder_time: { defaultValue: reminderHour },
    vitals_reminder_time_am_pm: { defaultValue: reminderAmPm },
    email_consent: {
      defaultValue: notificationSettings?.email_consent ?? true,
      validation: yup.boolean(),
    },
  };
}

function getUpdateFields(
  intl: IntlShape,
  flags: FeatureFlagSet,
  maxDate: Date,
  dateMsg: string,
  patient?: Patient,
  npi?: NPI,
) {
  const emergencyContact = patient?.contacts?.find(
    ({ emergency }) => emergency,
  );
  const address = patient?.addresses?.find(({ primary }) => primary)?.address;

  const { required, phoneNumber, phoneType, maxLengthString, enumType } =
    validators(intl);

  const validateAddress = (field: keyof AddressValues) => {
    const addressFieldSchema = addressValidation(intl)(field);
    return addressFieldSchema;
  };

  function conditionallyRequireEmergencyContact(
    fieldName: string,
    field: yup.AnySchema,
    schemaWhenRequired?: yup.AnySchema,
  ) {
    if (flags.newEnrollmentUiUpdates && isEmergencyContactOptional(patient)) {
      // Require other fields if one of them is filled out
      return field.when(getEmergencyContactDependentFields(fieldName), {
        is: anyFieldIsPopulated,
        then: (schema) =>
          schemaWhenRequired ? required(schemaWhenRequired) : required(schema),
      });
    }
    return field.when('emergency_contact_disabled', {
      is: false,
      then: (schema) =>
        schemaWhenRequired ? required(schemaWhenRequired) : required(schema),
    });
  }

  return {
    gender: {
      defaultValue: patient?.gender || '',
      validation: required(yup.string()).oneOf(Object.values(Gender)),
    },
    race: {
      defaultValue: patient?.race || '',
      validation: enumType({
        source: Race,
        pluck: 'keys',
        emptyValue: '',
      }),
    },
    timezone: {
      defaultValue: patient?.timezone || '',
      validation: required(yup.string()),
    },
    primary_language: {
      defaultValue: patient?.primary_language,
      validation: yup.string().oneOf(Object.values(Language)),
    },
    accommodations: {
      defaultValue: patient?.accommodations || [],
      validation: yup
        .array()
        .of(enumType({ source: Accommodation, pluck: 'keys' }))
        .ensure(),
    },
    accommodations_other: {
      defaultValue: patient?.accommodations_other || '',
      validation: maxLengthString({ maxLength: 255 }).when('accommodations', {
        is: isAccommodationsOtherRequired,
        then: (schema) => required(schema),
      }),
    },
    residence: {
      defaultValue: patient?.residence || '',
      validation: enumType({
        source: Residence,
        pluck: 'keys',
        emptyValue: '',
      }),
    },
    residence_other: {
      defaultValue: patient?.residence_other || '',
      validation: maxLengthString({ maxLength: 255 }).when('residence', {
        is: isResidenceOtherRequired,
        then: (schema) => required(schema),
      }),
    },
    street_address: {
      defaultValue: address?.street_address || '',
      validation: validateAddress('street_address'),
    },
    premise: {
      defaultValue: address?.premise || '',
      validation: yup.string(),
    },
    postal_code: {
      defaultValue: address?.postal_code || '',
      validation: validateAddress('postal_code'),
    },
    locality: {
      defaultValue: address?.locality || '',
      validation: validateAddress('locality'),
    },
    region: {
      defaultValue: address?.region || '',
      validation: validateAddress('region'),
    },
    // Program Consent and Info fields
    ...(!flags.newEnrollmentUiUpdates &&
      getProgramConsentAndInfoFields(intl, maxDate, patient, npi)),
    // Communication preferences fields
    ...getCommunicationPreferencesFields(patient),
    emergency_contact_disabled: {
      defaultValue: patient?.emergency_contact_disabled ?? false,
    },
    contact_full_name: {
      defaultValue: emergencyContact?.contact?.name || '',
      validation: conditionallyRequireEmergencyContact(
        'contact_full_name',
        yup.string(),
      ),
    },
    emergency_phone: {
      defaultValue: emergencyContact?.contact?.phone_number || '',
      validation: conditionallyRequireEmergencyContact(
        'emergency_phone',
        phoneNumber(),
      ),
    },
    emergency_phone_type: {
      defaultValue: emergencyContact?.contact?.phone_type || '',
      validation: conditionallyRequireEmergencyContact(
        'emergency_phone_type',
        phoneType({ allowEmpty: true }),
        phoneType(),
      ),
    },
    relationship: {
      defaultValue: emergencyContact?.relationship || '',
      validation: conditionallyRequireEmergencyContact(
        'relationship',
        yup.string(),
        enumType({ source: Relationship, pluck: 'keys' }),
      ),
    },
  };
}

export function isEmergencyContactOptional(patient?: Patient) {
  return (
    patient?.status === PatientStatus.ReadyToEnroll &&
    patient?.enrollment_type === EnrollmentType.VIRTUAL
  );
}

export const PreferCaregiverContact = 'PREFER_CAREGIVER_CONTACT' as const;
export type PatientPreferedContact = PhoneType | typeof PreferCaregiverContact;

export type CommunicationPreferencesFormFields = {
  sms_consent: boolean;
  email_consent: boolean;
  notification_consent: boolean;
  vitals_confirmation: boolean;
  vitals_value: boolean;
  streaks: boolean;
  bingo: boolean;
  weekly_summary: boolean;
  vitals_reminder: boolean;
  appointments_reminder: boolean;
  vitals_reminder_time: string;
  vitals_reminder_time_am_pm: TimePeriod;
};

export type CreatePatientFormFields = {
  first_name: string;
  last_name: string;
  dob: Date;
  mobile_number: string;
  phone_number: string;
  preferred_phone: PatientPreferedContact | ''; // Empty string is the default value
  has_caregiver: boolean;
  email: string;
  is_test: boolean;
};

export type EditPatientFormFields = CreatePatientFormFields & {
  gender: Gender;
  race: keyof typeof Race;
  timezone: string;
  accommodations: (keyof typeof Accommodation)[];
  accommodations_other: string;
  residence: keyof typeof Residence;
  residence_other: string;
  street_address: string;
  premise: string;
  postal_code: string;
  locality: string;
  region: string;
  rpm_consent_date?: Date;
  ccm_consent_date?: Date;
  npi: NPI;
  med_management_delegation: MedManagementDelegation;
  emergency_contact_disabled: boolean;
  contact_full_name?: string;
  emergency_phone?: string;
  emergency_phone_type?: PhoneType;
  relationship?: keyof typeof Relationship;
  primary_language?: keyof typeof Language;
} & CommunicationPreferencesFormFields;

export type EditPatientFormFieldsWithEmergencyContact =
  EditPatientFormFields & {
    contact_full_name: string;
    emergency_phone: string;
    emergency_phone_type: PhoneType;
    relationship: keyof typeof Relationship;
  };
