import type { BaseCellProps } from './BaseCell';
import { BaseCell } from './BaseCell';

type Props = {
  children: Maybe<number>;
} & BaseCellProps;

export function NumberCell({ children, className, ...baseProps }: Props) {
  const num =
    children === null || children === undefined ? null : children.toString();
  return <BaseCell {...baseProps}>{num}</BaseCell>;
}
