import { addDays, isAfter, isEqual, startOfDay, subDays } from 'date-fns';
import { useCallback, useMemo } from 'react';

export function useDateValidators() {
  const today = useMemo(() => startOfDay(new Date()), []);

  const isValidDateSelection = useCallback(
    (date: Date) => isAfter(date, today) || isEqual(date, today),
    [today],
  );
  const canSubDay = useCallback(
    (date: Date) => isValidDateSelection(subDays(date, 1)),
    [isValidDateSelection],
  );
  const canAddDay = useCallback(
    (date: Date) => isValidDateSelection(addDays(date, 1)),
    [isValidDateSelection],
  );
  return { today, isValidDateSelection, canSubDay, canAddDay };
}
