import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const BulletPointListIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2438 8H10.7562C10.3388 8 10 7.664 10 7.25C10 6.836 10.3388 6.5 10.7562 6.5L19.2438 6.5C19.6612 6.5 20 6.836 20 7.25C20 7.664 19.6612 8 19.2438 8Z"
        fill="#3B3A45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2438 13H10.7562C10.3388 13 10 12.664 10 12.25C10 11.836 10.3388 11.5 10.7562 11.5L19.2438 11.5C19.6612 11.5 20 11.836 20 12.25C20 12.664 19.6612 13 19.2438 13Z"
        fill="#3B3A45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2438 18H10.7562C10.3388 18 10 17.664 10 17.25C10 16.836 10.3388 16.5 10.7562 16.5L19.2438 16.5C19.6612 16.5 20 16.836 20 17.25C20 17.664 19.6612 18 19.2438 18Z"
        fill="#3B3A45"
      />
      <circle cx="5.75" cy="7.25" r="1.25" fill="#3B3A45" />
      <circle cx="5.75" cy="12.25" r="1.25" fill="#3B3A45" />
      <circle cx="5.75" cy="17.25" r="1.25" fill="#3B3A45" />
    </svg>
  </SvgIcon>
);

export default BulletPointListIcon;
