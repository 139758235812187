import { Radio } from '@/shared/tempo/atom/Radio';

import { Actions } from './Actions';
import { CardSection, CollapsibleCardSection } from './CardSection';
import { Container } from './Container';
import { FloatingFooter } from './FloatingFooter';
import { FormContainer } from './FormContainer';
import { GridItem } from './GridItem';
import { Row } from './Row';
import { Section } from './Section';
import { RadioGroup as DeprecatedRadioGroup } from './fields/@deprecated/RadioGroup';
import { Select as DeprecatedSelect } from './fields/@deprecated/Select';
import { Autocomplete } from './fields/Autocomplete';
import { BaseField as Field } from './fields/BaseField';
import { Checkbox } from './fields/Checkbox';
import { CheckboxGroup } from './fields/CheckboxGroup';
import { DatePicker } from './fields/DatePicker';
import { File } from './fields/File';
import { MultiSelect } from './fields/MultiSelect';
import { PhoneNumber, UnmaskedPhoneNumber } from './fields/PhoneNumber';
import { RadioGroup } from './fields/RadioGroup';
import { Search } from './fields/Search';
import { Select } from './fields/Select';
import { TextArea } from './fields/TextArea';
import { TextField } from './fields/TextField';
import { TimePicker } from './fields/TimePicker';
import { Toggle } from './fields/Toggle';
import { TypeaheadSelect } from './fields/TypeaheadSelect';

// Export types
export type { FormConfig } from './form.types';

// Export Form with subcomponents (i.e. Form.Actions, Form.Row, etc...)

export const Fields = {
  Autocomplete,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  DeprecatedRadioGroup,
  DeprecatedSelect,
  Field,
  File,
  MultiSelect,
  PhoneNumber,
  UnmaskedPhoneNumber,
  Radio,
  RadioGroup,
  Search,
  Select,
  TextField,
  TextArea,
  TimePicker,
  Toggle,
  TypeaheadSelect,
};

export const Form = Object.assign(FormContainer, {
  Actions,
  CardSection,
  CollapsibleCardSection,
  Container,
  FloatingFooter,
  GridItem,
  Row,
  Section,
  ...Fields,
});
