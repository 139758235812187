import { useIntl } from 'react-intl';

import { Table } from '@/shared/common/Table';
import type { Note } from '@/shared/types/note.types';
import {
  type NoteSymptom,
  useNoteSymptomI18nMap,
} from '@/shared/utils/note-symptom-data';

import { NoteDataRow } from './NoteDataRow';
import { table } from './PatientActivity.css';

type Props = {
  notesWithSymptoms: { note: Note; symptoms: NoteSymptom[] }[];
};

export function NoteDataTable({ notesWithSymptoms }: Props) {
  const intl = useIntl();
  const symptomI18n = useNoteSymptomI18nMap();

  return (
    <Table>
      <Table.Header
        columns={[
          {
            title: intl.formatMessage({ defaultMessage: 'Recent Symptoms' }),
            className: table.headerCell,
          },
        ]}
      />
      <Table.Body>
        {notesWithSymptoms.map(({ note, symptoms }) => (
          <NoteDataRow key={note.id} note={note}>
            {symptoms
              .map((symptom) => symptomI18n[symptom])
              .sort()
              .join('; ')}
          </NoteDataRow>
        ))}
      </Table.Body>
    </Table>
  );
}
