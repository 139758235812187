import { SharedFeedback } from '../../shared/ProductSupport/SharedFeedback';
import { chfVisitPath } from '../paths';

export function Feedback() {
  return (
    <SharedFeedback
      currentPath={chfVisitPath('/product-support', '/feedback')}
    />
  );
}
