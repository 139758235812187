import type { NpiState } from './npi.types';

const initialState: NpiState = {
  status: {
    isProcessing: true,
    isSuccess: false,
  },
  data: [],
  selectedNpi: {},
  metadata: {
    page: 1,
    pages: 1,
    per_page: 25,
    total: 0,
  },
  isSubmitting: false,
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  error: null,
};

export default initialState;
