import type { QueryClient, UseQueryOptions } from 'react-query';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { queriesAreLoading } from '@/reactQuery';
import type {
  CommunicationType,
  EntryType,
  MonitoringSession,
} from '@/shared/types/monitoringSession.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type {
  CompositeSortDirection,
  CompositeSortKey,
} from '@/shared/types/sorting.types';
import Session from '@/shared/utils/session';

import { useQueryNoRefetch } from '../useQueryNoRefetch';

const getPatientMonitoringSessionQueryKeyBase = (patientId: string) => [
  'pms',
  'api',
  'v1',
  'patients',
  patientId,
  'monitoring_sessions',
];

const MONITORING_SESSION_QUERY_KEY_BASE = [
  'pms',
  'api',
  'v1',
  'monitoring_sessions',
];

export async function invalidatePatientMonitoringSessionQueries(
  patientId: string,
  queryClient: QueryClient,
) {
  return queryClient.invalidateQueries(
    getPatientMonitoringSessionQueryKeyBase(patientId),
  );
}

export type MonitoringSessionsParams = {
  sort_by?: CompositeSortKey<MonitoringSessionsSortField>;
  order_by?: CompositeSortDirection;
};

export type MonitoringSessionsSortField =
  | 'id'
  | 'created_at'
  | 'updated_at'
  | 'start_datetime'
  | 'live_communication'
  | 'communication_type'
  | 'type'
  // Care provider first/last name
  | 'first_name'
  | 'last_name';

export const MONITORING_SESSIONS_QUERY_KEYS = {
  infinite: (patientId: string, params: MonitoringSessionsParams = {}) =>
    [
      ...getPatientMonitoringSessionQueryKeyBase(patientId),
      params,
      'infinite',
    ] as const,
  detail: (sessionId: string) =>
    [...MONITORING_SESSION_QUERY_KEY_BASE, sessionId] as const,
};

export function useMonitoringSessionsInfiniteQuery(
  patientId: string,
  params?: MonitoringSessionsParams,
) {
  return useInfiniteQuery<PaginatedData<MonitoringSession>>(
    MONITORING_SESSIONS_QUERY_KEYS.infinite(patientId, params),
  );
}

export function useMonitoringSessionDetailsNoRefetch(
  sessionId: string,
  params?: UseQueryOptions<MonitoringSession>,
) {
  return useQueryNoRefetch<MonitoringSession>(
    MONITORING_SESSIONS_QUERY_KEYS.detail(sessionId),
    params,
  );
}

export function useDeleteMonitoringSession(patientId: string) {
  const client = useQueryClient();
  return useMutation(
    (sessionId: string) =>
      Session.Api.delete<MonitoringSession>(
        `/pms/api/v1/monitoring_sessions/${sessionId}`,
      ),
    {
      onSuccess: async () => {
        await client.invalidateQueries(
          getPatientMonitoringSessionQueryKeyBase(patientId),
        );
      },
    },
  );
}

export type MonitoringSessionPostPayload = {
  session: {
    patient_id: string;
    start_datetime: string;
    end_datetime: string;
    timezone: string;
    communication_type: CommunicationType;
    type?: EntryType;
  };
  note: MonitoringSessionNotePostPayload['data'];
};

type Callbacks = {
  onSuccess?: () => void;
  onError?: () => void;
};

export function usePostMonitoringSession(
  patientId: string,
  params?: { session?: Callbacks; note?: Callbacks },
) {
  const client = useQueryClient();
  const createSessionNote = usePostMonitoringSessionNote(patientId);
  const createSession = useMutation(
    (payload: MonitoringSessionPostPayload) =>
      Session.Api.post<MonitoringSession>(
        '/pms/api/v1/monitoring_sessions',
        payload.session,
      ),
    {
      onSuccess: async ({ data }, { note }) => {
        await invalidatePatientMonitoringSessionQueries(patientId, client);
        params?.session?.onSuccess?.();
        createSessionNote.mutate(
          {
            sessionId: data.id,
            data: note,
          },
          params?.note,
        );
      },
      onError: () => params?.session?.onError?.(),
    },
  );

  return {
    ...createSession,
    isLoading: queriesAreLoading([createSession, createSessionNote]),
  };
}

export type MonitoringSessionNotePostPayload = {
  sessionId: string;
  data: {
    body: string;
    title: string;
  };
};

export function usePostMonitoringSessionNote(patientId: string) {
  return useMutation((payload: MonitoringSessionNotePostPayload) =>
    Session.Api.post(
      `/pms/api/v1/notes/monitoring_sessions/${payload.sessionId}/patient/${patientId}`,
      payload.data,
    ),
  );
}

export type MonitoringSessionNotePatchPayload = {
  sessionId: string;
  data: {
    body: string;
    title: string;
  };
};

export function usePatchMonitoringSessionNote(patientId: string) {
  return useMutation((payload: MonitoringSessionNotePostPayload) =>
    Session.Api.patch(
      `/pms/api/v1/notes/monitoring_sessions/${payload.sessionId}/patient/${patientId}`,
      payload.data,
    ),
  );
}

export type MonitoringSessionPutPayload = {
  id: string;
  session: {
    patient_id: string;
    start_datetime: string;
    end_datetime: string;
    timezone: string;
    communication_type: CommunicationType;
    type?: EntryType;
  };
  note: MonitoringSessionNotePostPayload['data'];
};

export function usePutMonitoringSession(
  patientId: string,
  params?: { session?: Callbacks; note?: Callbacks },
) {
  const client = useQueryClient();
  const updateSessionNote = usePatchMonitoringSessionNote(patientId);
  const updateSession = useMutation(
    (payload: MonitoringSessionPutPayload) =>
      Session.Api.put<MonitoringSession>(
        `/pms/api/v1/monitoring_sessions/${payload.id}`,
        payload.session,
      ),
    {
      onSuccess: async ({ data }, { note }) => {
        await client.invalidateQueries(
          getPatientMonitoringSessionQueryKeyBase(patientId),
        );
        params?.session?.onSuccess?.();
        updateSessionNote.mutate(
          {
            sessionId: data.id,
            data: note,
          },
          params?.note,
        );
      },
      onError: () => params?.session?.onError?.(),
    },
  );

  return {
    ...updateSession,
    isLoading: queriesAreLoading([updateSession, updateSessionNote]),
  };
}
