import { SharedVitalProgressIntro } from '../shared/VitalProgress/VitalProgressIntro';
import { SharedVitalRecommendation } from '../shared/VitalProgress/VitalRecommendation';
import {
  EducationModulesReview,
  NoPrevGoal,
  PrevGoalDiscuss,
  PrevGoalProgress,
  ReviewActionSteps,
  SetActionSteps,
  SetGoal,
} from './actionPlan';
import { ChartPrep } from './chartPrep';
import { EndCall } from './endCall';
import { DailyLiving, Diet, Exercise, TobaccoUse } from './generalAssessment';
import { IntroConfirmData, IntroPatientAttendance } from './introduction';
import { MedReview } from './medReview';
import { LowDeviceUsage } from './productSupport';
import { DeviceUsage } from './productSupport/DeviceUsage';
import { Feedback } from './productSupport/Feedback';
import { Intro } from './productSupport/Intro';
import { Scheduling } from './scheduling';
import { Symptoms } from './symptoms';
import { SecondarySymptoms } from './symptoms/SecondarySymptoms';
import { TimeTracking } from './timeTracking';

export const REGULAR_VISIT_ROUTES = {
  '/chart-prep/index': ChartPrep,
  '/intro/patient-attendance': IntroPatientAttendance,
  '/intro/patient-identification': IntroConfirmData,
  '/symptoms/index': Symptoms,
  '/symptoms/secondary': SecondarySymptoms,
  '/product-support/feedback': Feedback,
  '/product-support/intro': Intro,
  '/product-support/device-usage': DeviceUsage,
  '/product-support/low-device-usage': LowDeviceUsage,
  '/med-review/index': MedReview,
  '/general-assessment/tobacco-use': TobaccoUse,
  '/general-assessment/diet': Diet,
  '/general-assessment/exercise': Exercise,
  '/general-assessment/daily-living': DailyLiving,
  '/vital-progress/intro': SharedVitalProgressIntro,
  '/vital-progress/recommendation': SharedVitalRecommendation,
  '/action-plan/prev-goal-progress': PrevGoalProgress,
  '/action-plan/prev-goal-progress/discuss': PrevGoalDiscuss,
  '/action-plan/no-prev-goal': NoPrevGoal,
  '/action-plan/set-goal': SetGoal,
  '/action-plan/education-modules-review': EducationModulesReview,
  '/action-plan/set-action-steps': SetActionSteps,
  '/action-plan/review-action-steps': ReviewActionSteps,
  '/scheduling/index': Scheduling,
  '/time-tracking/index': TimeTracking,
  '/end-call/index': EndCall,
};
