import cx from 'classnames';
import type { ReactNode } from 'react';

import { TableHead, TableRow } from '@/deprecated/mui';
import type {
  CompositeSortDirection,
  CompositeSortKey,
} from '@/shared/types/sorting.types';

import type { ColumnType } from './HeaderCell/HeaderCell';
import { HeaderCell } from './HeaderCell/HeaderCell';
import { areSortKeysEqual } from './sort/sort.utils';
import type { SortState } from './sort/useTableSort';

export type HeaderColumn<K> = {
  title: string;
  type?: ColumnType;
  customHeader?: ReactNode;
  className?: string;
  sortKey?: CompositeSortKey<K>;
  hidden?: boolean;
  colSpan?: number;
};

type BaseProps<K> = {
  sortValue?: SortState<K>;
  onSort?: (
    sortKey: CompositeSortKey<K>,
    sortDir?: CompositeSortDirection,
  ) => void;
  headerCellClassName?: string;
};

type Props<K> = {
  columns: HeaderColumn<K>[];
  className?: string;
} & BaseProps<K>;

function HeaderContainer<K = string>({
  column: { title, customHeader, sortKey, className: cellClass, type, colSpan },
  headerCellClassName,
  sortValue,
  onSort,
}: BaseProps<K> & { column: HeaderColumn<K> }) {
  return (
    <>
      {customHeader ? (
        <HeaderCell
          colSpan={colSpan}
          className={cx(cellClass, headerCellClassName)}
          type={type}
        >
          {customHeader}
        </HeaderCell>
      ) : (
        <HeaderCell
          colSpan={colSpan}
          className={cx(cellClass, headerCellClassName)}
          type={type}
          sortDir={
            areSortKeysEqual(sortKey, sortValue?.sortKey)
              ? sortValue?.sortDir
              : undefined
          }
          onSort={sortKey ? (newDir) => onSort?.(sortKey, newDir) : null}
        >
          {title}
        </HeaderCell>
      )}
    </>
  );
}

export function Header<K = string>({ columns, className, ...rest }: Props<K>) {
  return (
    <TableHead className={cx(className)}>
      <TableRow>
        {columns.map((column) => (
          <HeaderContainer
            key={`${column.title}-${column.sortKey}`}
            column={column}
            {...rest}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}
