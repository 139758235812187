import type { ListState, Node } from 'react-stately';

// we can't use listState.collection.size to get the size because it
// (annoyingly) includes unselectable items like sections. instead, we
// get to fiddle around with iterators.
export function getSelectableItems<T>(state: ListState<T>): Node<T>[] {
  return [...state.collection.getKeys()]
    .map((key) => state.collection.getItem(key))
    .filter((item): item is Node<T> => item?.type === 'item');
}
