import type { ReactNode } from 'react';

import { confirmation as confirmationStyles } from './PublishConfirmationDialog.css';

export function ListContainer({
  children,
  showAsList,
}: {
  children: ReactNode;
  showAsList: boolean;
}) {
  <ul className={confirmationStyles.list}>{children}</ul>;
  if (showAsList) {
    return <ul className={confirmationStyles.list}>{children}</ul>;
  }
  return <>{children}</>;
}

export function ListItemContainer({
  showAsList,
  children,
}: {
  showAsList: boolean;
  children: ReactNode;
}) {
  if (showAsList) {
    return <li>{children}</li>;
  }
  return <>{children}</>;
}
