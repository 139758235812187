export function getLoginUrl(environment?: string) {
  // When running locally, logging in through /login/password enables user
  // to remain on localhost instead of redirecting to the CLIENT_DOMAIN_NAME
  // set by the heimdall service
  if (environment === 'development') {
    return '/login/password';
  }

  return '/login';
}
