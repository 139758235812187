import { useIntl } from 'react-intl';

import { Select } from '@/shared/tempo/@labs/molecule/Select';

import type { TeamTabAssignee } from '../TaskFilterContext';
import { useTaskFilterContext } from '../TaskFilterContext';
import { filterPopover, selectButton, subtleChevron } from './TaskFilters.css';

export function AssigneeFilter() {
  const intl = useIntl();
  const { teamTabAssignee, onTeamTabAssigneeChange } = useTaskFilterContext();

  return (
    <Select
      variant="subtle"
      placeholder={intl.formatMessage({
        defaultMessage: 'Assignee',
      })}
      aria-label={intl.formatMessage({
        defaultMessage: 'Assignee',
      })}
      selectedKey={teamTabAssignee}
      className={selectButton}
      classes={{ popover: filterPopover, chevron: subtleChevron }}
      popover={{ placement: 'bottom end', width: 'static' }}
      onSelectionChange={(key) =>
        onTeamTabAssigneeChange(key as TeamTabAssignee)
      }
      items={[
        {
          displayName: intl.formatMessage({
            defaultMessage: 'Unassigned',
          }),
          value: 'unassigned',
        },
        {
          displayName: intl.formatMessage({
            defaultMessage: 'Assigned',
          }),
          value: 'assigned',
        },
      ]}
    >
      {({ displayName, value }) => (
        <Select.Option key={value}>{displayName}</Select.Option>
      )}
    </Select>
  );
}
