import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const BoldIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 22 22">
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18H11.9679C15.3442 18 17 16.4829 17 14.458C17 12.4902 15.4162 11.335 13.8469 11.2651V11.1382C15.2866 10.8398 16.4241 9.95117 16.4241 8.37061C16.4241 6.43457 14.8403 5 11.644 5H6V18ZM8.67081 16.0322V12.2236H11.6152C13.2637 12.2236 14.286 13.1123 14.286 14.2739C14.286 15.3086 13.4797 16.0322 11.5432 16.0322H8.67081ZM8.67081 10.5288V6.94238H11.3704C12.9398 6.94238 13.7533 7.67236 13.7533 8.67529C13.7533 9.81787 12.7022 10.5288 11.3128 10.5288H8.67081Z"
        fill="#3B3A45"
      />
    </svg>
  </SvgIcon>
);

export default BoldIcon;
