import cx from 'classnames';
import type { ReactNode } from 'react';

import ArrowUpRight from '@/shared/assets/svgs/arrow-up-right.svg?react';

import { arrowUpRight, link, linkContents } from './OpenInNewTabLink.css';

export function OpenInNewTabLink({
  text,
  href,
  showArrow = true,
  className,
}: {
  text: string | ReactNode;
  href: string;
  showArrow?: boolean;
  className?: Record<string, string>;
}) {
  return (
    <a
      className={cx(link, className?.link)}
      target="_blank"
      rel="noreferrer"
      href={href}
    >
      <div className={cx(linkContents, className?.content)}>
        {text}
        {showArrow && <ArrowUpRight className={arrowUpRight} />}
      </div>
    </a>
  );
}
