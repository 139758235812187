import { useEffect, useMemo, useRef } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { TableHeaderProps } from '@/shared/common/@deprecated/Table';
import {
  DataTableRows,
  Table,
  TableBody,
  TableHeader,
} from '@/shared/common/@deprecated/Table';
import { useSidePanelCtx } from '@/shared/common/Page';
import type { Patient } from '@/shared/types/patient.types';

import { PatientTableRow } from './PatientsTableRow';

type PatientsTableProps = {
  hasActiveAlert?: boolean;
  patients: Patient[];
  onClickPatient: (patient: Patient) => void;
  onPageRequested: () => unknown;
  isFetching: boolean;
  hasNextPage: boolean;
};

function getColumnHeaders(intl: IntlShape): TableHeaderProps['columnHeaders'] {
  return [
    {
      message: intl.formatMessage({ defaultMessage: 'Status' }),
      style: {
        width: '72px',
      },
    },
    {
      message: intl.formatMessage({ defaultMessage: 'Patient' }),
    },
    {
      message: intl.formatMessage({ defaultMessage: 'Last Update' }),
    },
    {
      message: intl.formatMessage({
        defaultMessage: 'BP',
        description: 'Abbreviation for blood pressure',
      }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({
        defaultMessage: 'HR',
        description: 'Abbreviation for heart rate',
      }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({ defaultMessage: 'Weight' }),
      style: { textAlign: 'center' },
    },
    {
      message: intl.formatMessage({
        defaultMessage: 'BG',
        description: 'Abbreviation for blood glucose',
      }),
      style: { textAlign: 'center' },
    },
  ];
}

export const PatientsTable = ({
  hasActiveAlert = false,
  hasNextPage,
  isFetching,
  onClickPatient,
  onPageRequested,
  patients,
}: PatientsTableProps) => {
  const intl = useIntl();
  const columnHeaders = useMemo(() => getColumnHeaders(intl), [intl]);
  const { handlers } = useSidePanelCtx();
  const { addWhitelistElement } = handlers;
  const tableBodyNode = useRef<HTMLTableSectionElement>(null);
  useEffect(() => {
    addWhitelistElement(tableBodyNode);
  }, [addWhitelistElement]);

  return (
    <Table sx={{ borderSpacing: '0 8px', borderCollapse: 'separate' }}>
      <TableHeader columnHeaders={columnHeaders} />
      <TableBody ref={tableBodyNode}>
        <DataTableRows
          onPageRequested={onPageRequested}
          isFetching={isFetching ?? false}
          hasNextPage={hasNextPage ?? false}
          columns={columnHeaders.length}
        >
          {patients.map((patient) => (
            <PatientTableRow
              key={patient.id}
              patient={patient}
              hasActiveAlert={hasActiveAlert}
              onClick={() => onClickPatient(patient)}
            />
          ))}
        </DataTableRows>
      </TableBody>
    </Table>
  );
};
