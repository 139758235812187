import cx from 'classnames';
import type { ReactNode } from 'react';

import { Stack } from '@/deprecated/mui';

import type { BaseCellProps } from './BaseCell';
import { BaseCell } from './BaseCell';
import { snugFit } from './cells.css';

type Props = {
  children: ReactNode;
} & BaseCellProps;

export function ActionsCell({ children, className, ...baseProps }: Props) {
  return (
    <BaseCell {...baseProps} className={cx(className, snugFit)} align="center">
      <Stack alignContent="space-between" spacing="14px" direction="row">
        {children}
      </Stack>
    </BaseCell>
  );
}
