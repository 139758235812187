import cx from 'classnames';

import {
  BaseField,
  type BaseFieldProps,
} from '@/shared/common/Form/fields/BaseField';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { fullWidth } from '@/shared/jsStyle/utils.css';
import { Button } from '@/shared/tempo/atom/Button';

import {
  container,
  option,
  options,
  scale as scaleClass,
} from './RatingScaleField.css';

type Props = {
  scale?: {
    max: string;
    min: string;
  };
  max?: number;
  classes?: {
    container?: string;
    options?: string;
  };
} & BaseFieldProps;

export function RatingScaleField({
  max = 10,
  scale,
  classes,
  ...baseProps
}: Props) {
  return (
    <BaseField {...baseProps}>
      {({ controller: { field } }) => (
        <div className={cx(fullWidth, flexContainer.center)}>
          <div className={cx(container, classes?.container)}>
            <div className={cx(options, classes?.options)}>
              {Array(max)
                .fill(0)
                .map((_, idx) => (
                  <Button
                    key={idx}
                    size="small"
                    variant="secondary"
                    isDisabled={field.value === valueFromIdx(idx)}
                    onPress={() => field.onChange(valueFromIdx(idx))}
                    className={cx(option.default, {
                      [option.selected]: field.value === valueFromIdx(idx),
                    })}
                  >
                    {valueFromIdx(idx)}
                  </Button>
                ))}
            </div>
            {scale && (
              <div className={scaleClass.container}>
                <div className={scaleClass.label}>
                  {scale?.min && `← ${scale.min}`}
                </div>
                <div className={scaleClass.label}>
                  {scale?.max && `${scale.max} →`}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </BaseField>
  );
}

function valueFromIdx(idx: number) {
  return idx + 1;
}
