import { FormattedMessage } from 'react-intl';

/** @deprecated */
export const required = (value?: string | number, fieldName?: string) =>
  value || typeof value === 'number'
    ? undefined
    : {
        message:
          typeof fieldName === 'string' ? (
            <FormattedMessage
              defaultMessage="{fieldName} is required"
              values={{ fieldName }}
            />
          ) : (
            <FormattedMessage defaultMessage="Required" />
          ),
      };

/** @deprecated */
export const number = (value: string) =>
  value && Number.isNaN(Number(value))
    ? {
        message: <FormattedMessage defaultMessage="Must be a number" />,
      }
    : undefined;

/** @deprecated */
export const emailValidation = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? {
        message: <FormattedMessage defaultMessage="Invalid email address" />,
      }
    : undefined;

/** @deprecated */
export const passwordMinLength = (value: string) =>
  value && value.length < 8
    ? {
        message: (
          <FormattedMessage defaultMessage="Password must be atleast 8 characters or more" />
        ),
      }
    : undefined;

/** @deprecated */
export const lowercaseCharacter = (value: string) =>
  value && /[a-z]+/.test(value)
    ? undefined
    : {
        message: (
          <FormattedMessage defaultMessage="Password must contain a lowercase character" />
        ),
      };

/** @deprecated */
export const uppercaseCharacter = (value: string) =>
  value && /[A-Z]+/.test(value)
    ? undefined
    : {
        message: (
          <FormattedMessage defaultMessage="Password must contain a uppercase character" />
        ),
      };

/** @deprecated */
export const numberCharacter = (value: string) =>
  value && /[0-9]+/.test(value)
    ? undefined
    : {
        message: (
          <FormattedMessage defaultMessage="Password must contain a number" />
        ),
      };

/** @deprecated */
export const specialCharacter = (value: string) =>
  value && /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/.test(value)
    ? undefined
    : {
        message: (
          <FormattedMessage defaultMessage="Password must contain a special character" />
        ),
      };

/** @deprecated */
export const npiLength = (value: string) =>
  value && value.length !== 10
    ? {
        message: (
          <FormattedMessage defaultMessage="NPI must be 10 characters" />
        ),
      }
    : undefined;
