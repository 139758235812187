export const FETCH_NPI = 'FETCH_NPI';
export const FETCH_NPI_SUCCESS = 'FETCH_NPI_SUCCESS';
export const FETCH_NPI_FAILURE = 'FETCH_NPI_FAILURE';
export const FETCH_NPI_BY_ID = 'FETCH_NPI_BY_ID';
export const FETCH_NPI_BY_ID_SUCCESS = 'FETCH_NPI_BY_ID_SUCCESS';
export const FETCH_NPI_BY_ID_FAILURE = 'FETCH_NPI_BY_ID_FAILURE';
export const CREATE_NPI = 'CREATE_NPI';
export const CREATE_NPI_SUCCESS = 'CREATE_NPI_SUCCESS';
export const CREATE_NPI_FAILURE = 'CREATE_NPI_FAILURE';
export const UPDATE_NPI = 'UPDATE_NPI';
export const UPDATE_NPI_SUCCESS = 'UPDATE_NPI_SUCCESS';
export const UPDATE_NPI_FAILURE = 'UPDATE_NPI_FAILURE';
export const DELETE_NPI = 'DELETE_NPI';
export const DELETE_NPI_SUCCESS = 'DELETE_NPI_SUCCESS';
export const DELETE_NPI_FAILURE = 'DELETE_NPI_FAILURE';

export const fetchNpiList = (
  page: number,
  pageSize: number,
  searchTerm?: string,
): TSimpleListActionCreator => ({
  page,
  pageSize,
  searchTerm,
  type: FETCH_NPI,
});

export const npiListFetched = (payload: TNpiListPayload): TNpiListResponse => ({
  payload,
  type: FETCH_NPI_SUCCESS,
});

export const npiListError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: FETCH_NPI_FAILURE,
});

export const fetchNpi = (id: number): TActionCreatorById => ({
  id,
  type: FETCH_NPI_BY_ID,
});

export const npiFetched = (
  payload: TPatientsListPayload,
): TPatientsListResponse => ({
  payload,
  type: FETCH_NPI_BY_ID_SUCCESS,
});

export const npiError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: FETCH_NPI_BY_ID_FAILURE,
});

export const createNpi = (npi: string): TActionCreatorMultipleParam => ({
  npi,
  type: CREATE_NPI,
});

export const npiCreated = (
  payload: TNpiCreatedUpdatedPayload,
): TNpiCreatedUpdatedResponse => ({
  payload,
  type: CREATE_NPI_SUCCESS,
});

export const createNpiError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: CREATE_NPI_FAILURE,
});

export const updateNpi = (
  npiId: number,
  npi: string,
): TActionCreatorMultipleParam => ({
  npiId,
  npi,
  type: UPDATE_NPI,
});

export const npiUpdated = (
  payload: TNpiCreatedUpdatedPayload,
): TNpiCreatedUpdatedResponse => ({
  payload,
  type: UPDATE_NPI_SUCCESS,
});

export const updateNpiError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: UPDATE_NPI_FAILURE,
});

export const deleteNpi = (npiId: number): TActionCreatorMultipleParam => ({
  npiId,
  type: DELETE_NPI,
});

export const npiDeleted = (
  payload: TNPIDeletedPayload,
): TNPIDeletedResponse => ({
  payload,
  type: DELETE_NPI_SUCCESS,
});

export const deleteNpiError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: DELETE_NPI_FAILURE,
});
