import { FormattedMessage } from 'react-intl';

import { Script } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { ConditionProgram } from '@/shared/types/condition.types';
import type { DeviceType } from '@/shared/types/device.types';
import { type Device, DeviceTypes } from '@/shared/types/device.types';

import { VitalAverageLevel } from '../../shared/vitalEducation/types';

type Props = {
  level?: VitalAverageLevel;
  program?: Nullable<ConditionProgram>;
  devices?: Device[];
  goal?: string;
  isActivated?: boolean;
};

export function ConditionalVitalContent({
  level,
  program,
  devices,
  goal,
  isActivated,
}: Props) {
  if (program === ConditionProgram.CHF) {
    return <ChfContent isActivated={isActivated} />;
  }

  if (program === ConditionProgram.Hypertension) {
    return <HtnContent level={level} goal={goal} />;
  }

  if (program === ConditionProgram.TypeTwoDiabetes) {
    return <T2dContent devices={devices} goal={goal} />;
  }

  return null;
}

function ChfContent({ isActivated }: { isActivated?: boolean }) {
  return (
    <>
      <Script>
        {!isActivated ? (
          <FormattedMessage defaultMessage="Even though we haven't received any of your readings yet, once you do begin taking your readings, we will be reviewing them closely for any significant changes in your blood pressure or weight. If there are any changes noted, we will call you to check on you." />
        ) : (
          <FormattedMessage defaultMessage="It looks like we've received a few readings from you. Thank for you these! We will be watching these closely for any significant changes. If there are any changes noted, we will call you to check on you." />
        )}
      </Script>
      <Script>
        <FormattedMessage defaultMessage="Heart Failure symptoms are generally related to fluid build up or reduced blood flow to parts of the body. For example, sudden weight changes or rapid heart rate can be signs of worsening heart failure." />
      </Script>
    </>
  );
}

function HtnContent({
  level,
  goal,
}: {
  level?: VitalAverageLevel;
  goal?: string;
}) {
  const isHypotensive = level === VitalAverageLevel.Unknown;
  const isOverGoal =
    !level ||
    (!isHypotensive &&
      level !== VitalAverageLevel.One &&
      level !== VitalAverageLevel.Two);

  if (isHypotensive) {
    return (
      <Script>
        <FormattedMessage
          defaultMessage="The blood pressures that you have taken so far appear to be running low periodically. Generally, we consider your blood pressure being at goal when it is under {goal} but above 90/60. Both numbers count in that determination. We will continue to monitor with you and make adjustments in the future as needed. If you develop any new symptoms, please notify Cadence."
          values={{ goal }}
        />
      </Script>
    );
  }

  if (isOverGoal) {
    return (
      <Script>
        <FormattedMessage
          defaultMessage="The blood pressures that you have taken so far have been above our goal. We would like to continue checking additional readings before making any changes. Our aim is to get your blood pressure down to at least under {goal}. As I mentioned previously, if your blood pressure readings are significantly elevated, we will check on you prior to our next appointment."
          values={{ goal }}
        />
      </Script>
    );
  }

  return (
    <Script>
      <FormattedMessage
        defaultMessage="The blood pressures that you have taken so far have been at or very near our goal. Generally, we consider your blood pressure being at goal when it is under {goal}. Both numbers count in that determination. So far, you're doing great with your blood pressures. Keep up the good work!"
        values={{ goal }}
      />
    </Script>
  );
}

function T2dContent({ devices, goal }: { devices?: Device[]; goal?: string }) {
  const hasDevice = (type: DeviceType) =>
    devices?.find(({ device_type }) => device_type === type);

  if (hasDevice(DeviceTypes.BLOOD_GLUCOSE_METER)) {
    return (
      <Script>
        <FormattedMessage
          defaultMessage="The blood sugars that you have taken so far have been received. Generally, we consider your blood sugar being at goal when it averages less than {goal} over 30 days but staying above 70. We will continue to have you check your sugars every day to help us gauge how well your sugars are controlled. If we note any significant changes in your sugar, we will call to check on you."
          values={{ goal }}
        />
      </Script>
    );
  }

  if (hasDevice(DeviceTypes.WEIGHT_SCALE)) {
    return (
      <Script>
        <FormattedMessage defaultMessage="We have received your weights that you have taken so far. As part of the management of your blood sugar, weight management is important to keeping you on track. Stepping on the scale every day allows us to keep an eye on your weight and how that corresponds to your diet and activity level." />
      </Script>
    );
  }

  return null;
}
