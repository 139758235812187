import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useFetchAllPages, useFlatPages } from '@/reactQuery/query.utils';
import { TimeTracker } from '@/shared/common/TimeTracker';
import { Tooltip } from '@/shared/common/Tooltip';
import { useFlags } from '@/shared/hooks';
import {
  useMonitoringSessionsInfiniteQuery,
  usePatientHospitals,
} from '@/shared/hooks/queries';
import type { RouteParam } from '@/shared/types/route.types';

import { getCumulativeTime } from './cumulativeTime.utils';

export const PatientCumulativeTimeTracker = ({
  className,
}: {
  className?: string;
}) => {
  const { showCumulativeTimeTracker } = useFlags();
  const { patientId }: RouteParam = useParams();
  const { data: hospitals, isLoading: isLoadingHospital } = usePatientHospitals(
    { patientId, page: 1, pageSize: 1 },
  );
  const sessionsInfiniteQuery = useFetchAllPages(
    useMonitoringSessionsInfiniteQuery(patientId, {
      sort_by: ['start_datetime'],
      order_by: ['desc'],
    }),
  );
  const timeEntries = useFlatPages(sessionsInfiniteQuery);

  if (sessionsInfiniteQuery.isLoading || isLoadingHospital) return null;

  const hospitalTimezone = hospitals?.hospitals?.[0]?.time_zone ?? 'UTC';

  const cumulativeTime = getCumulativeTime(timeEntries, hospitalTimezone);

  return (
    <>
      {showCumulativeTimeTracker && (
        <Tooltip
          tooltipMessage={
            <FormattedMessage defaultMessage="Billable time this calendar month" />
          }
          tooltipPlacement="bottom"
        >
          <TimeTracker className={className}>
            <FormattedMessage
              defaultMessage="{cumulativeTime} {cumulativeTime, plural, =1 {min} other {mins}}"
              values={{ cumulativeTime }}
            />
          </TimeTracker>
        </Tooltip>
      )}
    </>
  );
};
