import { compareAsc, parseISO } from 'date-fns';

import type { FieldsMap } from '@/shared/common/Form/form.types';

import type { Goal } from './patientGoals.types';

export const MAX_GOALS = 3;
export const NEW_GOAL_NAME_PREFIX = 'new-goal';

export function sortGoals(goals: Goal[]) {
  return goals.sort((a, b) =>
    compareAsc(parseISO(a.created_at), parseISO(b.created_at)),
  );
}

export function getFormName(goal: Goal) {
  return `goal-${goal.id}`;
}

export function getGoalIdFromFormName(name: string) {
  if (name.startsWith(NEW_GOAL_NAME_PREFIX)) {
    return undefined;
  }

  return parseInt(name.replace('goal-', ''), 10);
}

export function getFormConfig(goals: Goal[]) {
  /* eslint-disable no-param-reassign */
  const fields = goals.reduce<FieldsMap>((accum, goal) => {
    accum[getFormName(goal)] = {
      defaultValue: goal.text,
    };

    return accum;
  }, {});
  /* eslint-enable no-param-reassign */

  for (let i = goals.length; i < MAX_GOALS; i++) {
    fields[`${NEW_GOAL_NAME_PREFIX}-${i}`] = {
      defaultValue: '',
    };
  }

  return { fields };
}
