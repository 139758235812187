import type { BoxProps } from '@/deprecated/mui';
import { Box, IconButton, styled } from '@/deprecated/mui';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import { color } from '@/shared/tempo/theme';

type TagProps = {
  backgroundColor?: string;
  borderColor?: string;
};

type TagCloseProps = {
  onClose: () => void;
};

function Span({ children, ...props }: BoxProps) {
  return (
    <Box {...props} component="span">
      {children}
    </Box>
  );
}

export const Tag = Object.assign(
  styled(Span)<TagProps>((props: TagProps) => ({
    backgroundColor:
      props.backgroundColor ?? color.Theme.Light['Base Background Alt'],
    color: color.Theme.Light['Base Font'],
    border: `1px solid ${
      props.borderColor ?? color.Theme.Light['Base Form Disabled']
    }`,
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '14px',
    lineHeight: '1.43',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  })),
  {
    Close: (props: TagCloseProps) => {
      const { onClose } = props;
      return (
        <IconButton
          sx={{ padding: '0 1px 0 11px' }}
          onMouseDown={onClose}
          size="small"
        >
          <CloseIcon width="0.75em" />
        </IconButton>
      );
    },
  },
);
