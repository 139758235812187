import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import last from 'lodash/last';
import { FormattedMessage, useIntl } from 'react-intl';

import { EditableMessage } from '@/pages/patients/patientDetails/ui/shared/EditableMessage';
import { useCommunicationTypeMap } from '@/pages/patients/patientDetails/ui/shared/i18n/useCommunicationTypeMap';
import { useFormatDistanceMinutes } from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import { Modal } from '@/shared/common/Modal';
import {
  useMonitoringSessionDetailsNoRefetch,
  useMonitoringSessionNotesNoRefetch,
} from '@/shared/hooks/queries';
import { Button } from '@/shared/tempo/atom/Button';

import { useEntryTypeMap } from '../../trackingI18n';
import { contentContainer, subHeading } from '../styles.css';
import { EntryRow } from './EntryRow.tsx';

const EMPTY_PLACEHOLDER = '---';

export type Props = {
  entryId: string;
  onClose: () => void;
};

export function ViewEntryModal({ entryId, onClose }: Props) {
  const intl = useIntl();
  const { data: timeEntry, isFetching: isFetchingTimeEntry } =
    useMonitoringSessionDetailsNoRefetch(entryId || '', {
      enabled: Boolean(entryId),
    });
  const { data: notes, isFetching: isFetchingNotes } =
    useMonitoringSessionNotesNoRefetch(entryId || '', {
      enabled: Boolean(entryId),
    });
  const timeEntryNote = last(notes?.data);

  const formatDistanceMinutes = useFormatDistanceMinutes();
  const minutes = timeEntry
    ? formatDistanceMinutes(
        parseISO(timeEntry.start_datetime),
        parseISO(timeEntry.end_datetime),
      )
    : EMPTY_PLACEHOLDER;
  const entryTypeMap = useEntryTypeMap();
  const commTypeMap = useCommunicationTypeMap();

  return (
    <Modal
      open
      onClose={onClose}
      size="large"
      isLoading={isFetchingNotes || isFetchingTimeEntry}
    >
      <Modal.Header
        title={intl.formatMessage({ defaultMessage: 'Logged time record' })}
      />
      <Modal.Body>
        <div className={subHeading}>
          <EditableMessage />
        </div>
        <div className={contentContainer}>
          <EntryRow
            title={intl.formatMessage({
              defaultMessage: 'Total time spent',
            })}
            value={minutes}
          />
          <EntryRow
            title={intl.formatMessage({
              defaultMessage: 'Type',
            })}
            value={timeEntry && entryTypeMap[timeEntry.type]}
          />
          <EntryRow
            title={intl.formatMessage({
              defaultMessage: 'Notes',
            })}
            value={timeEntryNote?.body}
          />
          <EntryRow
            title={intl.formatMessage({
              defaultMessage: 'Date',
            })}
            value={
              timeEntry &&
              formatInTimeZone(
                timeEntry.start_datetime,
                timeEntry.timezone,
                'MM/dd/yyyy',
              )
            }
          />
          <EntryRow
            title={intl.formatMessage({
              defaultMessage: 'Start time',
            })}
            value={
              timeEntry &&
              formatInTimeZone(
                timeEntry.start_datetime,
                timeEntry.timezone,
                'hh:mm aa zzz',
              )
            }
          />
          <EntryRow
            title={intl.formatMessage({
              defaultMessage: 'Communication type',
            })}
            value={timeEntry && commTypeMap[timeEntry.communication_type]}
          />
          <EntryRow
            lastRow
            title={intl.formatMessage({
              defaultMessage: 'Added by',
            })}
            value={timeEntry?.care_provider_name}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id="close" variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
