import type { BaseCellProps } from './BaseCell';
import { BaseCell } from './BaseCell';

type Props = {
  children: Maybe<string>;
} & BaseCellProps;

export function TextCell({ children, ...baseProps }: Props) {
  return <BaseCell {...baseProps}>{children}</BaseCell>;
}
