import cx from 'classnames';

import type { SectionProps } from '../Section';
import { Section } from '../Section';
import { cardWrapper } from './cardSection.css';

export function CardSection({ children, classes, ...rest }: SectionProps) {
  return (
    <Section
      classes={{ container: cx(cardWrapper, classes?.container) }}
      {...rest}
    >
      {children}
    </Section>
  );
}
