import { useIntl } from 'react-intl';

import type { NotificationAudit } from '@/shared/hooks/queries';

export const UNKNOWN_COMM_STATUS = 'UNKNOWN' as const;

export function useCommunicationStatusi18nMap(): Record<
  | OmitUndefined<NotificationAudit['notification_status']>
  | typeof UNKNOWN_COMM_STATUS,
  string
> {
  const intl = useIntl();
  return {
    ACCEPTED: intl.formatMessage({ defaultMessage: 'Accepted' }),
    SCHEDULED: intl.formatMessage({ defaultMessage: 'Scheduled' }),
    QUEUED: intl.formatMessage({ defaultMessage: 'Queued' }),
    SENDING: intl.formatMessage({ defaultMessage: 'Sending' }),
    SENT: intl.formatMessage({ defaultMessage: 'Sent' }),
    FAILED: intl.formatMessage({ defaultMessage: 'Failed' }),
    UNDELIVERED: intl.formatMessage({ defaultMessage: 'Undelivered' }),
    RECEIVING: intl.formatMessage({ defaultMessage: 'Receiving' }),
    RECEIVED: intl.formatMessage({ defaultMessage: 'Received' }),
    READ: intl.formatMessage({ defaultMessage: 'Read' }),
    PROCESSED: intl.formatMessage({ defaultMessage: 'Processed' }),
    DROPPED: intl.formatMessage({ defaultMessage: 'Dropped' }),
    DEFERRED: intl.formatMessage({ defaultMessage: 'Deferred' }),
    BOUNCE: intl.formatMessage({ defaultMessage: 'Bounced' }),
    OPEN: intl.formatMessage({ defaultMessage: 'Opened' }),
    CLICK: intl.formatMessage({ defaultMessage: 'Clicked' }),
    SPAM_REPORT: intl.formatMessage({ defaultMessage: 'Reported Spam' }),
    UNSUBSCRIBE: intl.formatMessage({ defaultMessage: 'Unsubscribed' }),
    GROUP_UNSUBSCRIBE: intl.formatMessage({
      defaultMessage: 'Group Unsubscribed',
    }),
    GROUP_RESUBSCRIBE: intl.formatMessage({
      defaultMessage: 'Group Resubscribed',
    }),
    DELIVERED: intl.formatMessage({ defaultMessage: 'Delivered' }),
    INTERNAL_QUEUED: intl.formatMessage({ defaultMessage: 'Internal queued' }),
    [UNKNOWN_COMM_STATUS]: intl.formatMessage({ defaultMessage: 'Unknown' }),
  };
}
