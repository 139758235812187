export const LogLevels = {
  Debug: 'debug',
  Info: 'info',
  Warn: 'warn',
  Error: 'error',
} as const;

export type LogLevelsType = (typeof LogLevels)[keyof typeof LogLevels];

export type LogLevelMap = Record<LogLevelsType, number>;

export type LogMetadata = Record<string, unknown>;

export type LogEntry = {
  level: LogLevelsType;
  message: string;
  metadata: LogMetadata;
};
