import cx from 'classnames';
import type { ReactElement } from 'react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { ANIMATION_DURATION_MS, transition } from './ToastTransition.css';

type Props = {
  children: ReactElement;
};

export function ToastTransition({ children, ...rest }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Transition
      appear
      mountOnEnter
      unmountOnExit
      nodeRef={ref}
      timeout={ANIMATION_DURATION_MS}
      {...rest}
    >
      {(state) => (
        <div
          ref={ref}
          className={cx(transition.base, transition.states[state])}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
