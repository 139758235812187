import type { Placement } from '@floating-ui/react';
import {
  FloatingArrow,
  arrow,
  autoUpdate,
  computePosition,
  flip,
  hide,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { AriaTooltipProps } from 'react-aria';
import { mergeProps, useTooltip } from 'react-aria';
import type { TooltipTriggerState } from 'react-stately';

import { arrow as arrowClass, size as sizeClass, tooltip } from './styles.css';

export type TooltipPlacement = Placement;

export type PassthroughProps = {
  size?: keyof typeof sizeClass;
  isDisabled?: boolean;
  arrowClassName?: string;
  placement?: TooltipPlacement;
};

type Props = {
  className?: string;
  children: ReactNode;
  state: TooltipTriggerState;
  anchor: Element;
} & PassthroughProps &
  Omit<AriaTooltipProps, 'placement'>;

const ARROW_HEIGHT = 7;
const ARROW_WIDTH = 14;
const GAP = 4;

export function BaseTooltip({
  className,
  arrowClassName,
  isDisabled,
  state,
  children,
  anchor,
  size = 'default',
  placement = 'bottom',
  ...props
}: Props) {
  // Make sure the anchor ref is stored in state, not in a ref.
  // This makes them reactive to changes, and avoids reading refs in render (forbidden by React).
  const [tooltipEl, setTooltipEl] = useState<Nullable<HTMLElement>>(null);
  const { tooltipProps } = useTooltip(props, state);

  const arrowRef = useRef<SVGSVGElement>(null);
  const { floatingStyles, context } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    elements: { reference: anchor, floating: tooltipEl as HTMLElement },
    middleware: [
      flip(),
      shift(),
      offset(ARROW_HEIGHT + GAP),
      arrow({ element: arrowRef }),
      hide(),
    ],
  });

  // Hide tooltip when it is no longer visible
  if (anchor && tooltipEl) {
    computePosition(anchor, tooltipEl as HTMLElement, {
      middleware: [hide()].filter(Boolean),
    }).then(({ middlewareData }) => {
      const referenceHidden = middlewareData.hide?.referenceHidden;
      Object.assign(tooltipEl?.style || {}, {
        visibility: referenceHidden ? 'hidden' : 'visible',
      });
    });
  }

  if (isDisabled || !children) {
    return null;
  }

  return (
    <span
      {...mergeProps(props, tooltipProps)}
      ref={setTooltipEl}
      style={floatingStyles}
      className={cx(
        sizeClass[size],
        {
          [tooltip.visible]: state.isOpen,
          [tooltip.hidden]: !state.isOpen,
        },
        className,
      )}
    >
      {children}
      <FloatingArrow
        tipRadius={2}
        ref={arrowRef}
        context={context}
        width={ARROW_WIDTH}
        height={ARROW_HEIGHT}
        className={cx(arrowClass, arrowClassName)}
      />
    </span>
  );
}
