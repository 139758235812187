import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import type { SectionMeta } from '../path.utils';
import {
  activeStepIndicator,
  container,
  scrollContainer,
} from './ProgressBar.css';
import { ProgressStep } from './ProgressStep';
import { useSectionProgressMap } from './useSectionProgressMap';

type Props = {
  sections: Readonly<SectionMeta[]>;
};

export function ProgressBar({ sections }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { pathname: fullStepPath } = useLocation();
  const sectionCompletionStatuses = useSectionProgressMap(sections);
  const [activeStepStyle, setActiveStepStyle] = useState({
    width: 0,
    transform: 'translateX(0)',
  });

  const activeStep = ref.current?.querySelector<HTMLDivElement>(
    `#${stepId(fullStepPath)}`,
  );

  // casting is fine here - no failures occur if anchor is null
  useResizeObserver(activeStep as HTMLDivElement, () => {
    if (activeStep) {
      setActiveStepStyle({
        width: activeStep?.offsetWidth ?? 0,
        transform: `translateX(${activeStep?.offsetLeft ?? 0}px`,
      });
    }
  });

  useLayoutEffect(() => {
    setActiveStepStyle({
      width: activeStep?.offsetWidth ?? 0,
      transform: `translateX(${activeStep?.offsetLeft ?? 0}px`,
    });

    // Scroll the active step into view
    activeStep?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'end',
    });
  }, [activeStep, fullStepPath]);

  return (
    <div className={container} ref={ref}>
      <span className={scrollContainer}>
        <span className={activeStepIndicator} style={{ ...activeStepStyle }} />
        {sections
          .filter((s) => !s.excludeFromProgress)
          .map((section) => (
            <ProgressStep
              id={stepId(section.basePath)}
              key={section.basePath}
              active={fullStepPath.startsWith(section.basePath)}
              complete={sectionCompletionStatuses[section.basePath]}
            >
              {section.title}
            </ProgressStep>
          ))}
      </span>
    </div>
  );
}

function stepId(fullStepPath: string) {
  // Using index 1 to ignore leading slash.
  return fullStepPath.split('/')[1];
}
