import * as React from 'react';

export function useMenu() {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const openMenu = (event: { currentTarget: Element }) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchorEl(null);
  };
  return { isMenuOpen, openMenu, closeMenu, menuAnchorEl };
}
