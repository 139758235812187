import { parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { validators } from '@/shared/common/Form/validations';
import type {
  MedicationChange,
  ReferenceMedicationMedicationClass,
} from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeFrequency,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';

import { useReferenceMedications } from '../referenceMedications.queries';
import { isChfGdmt, isHtnMed } from './medClassUtil';
import type { RxNormOptionType } from './types';

export type MedFormFields = {
  rxnorm?: RxNormOptionType;
  rxnormId?: string;
  medicationName?: string;
  medicationChange?: string;
  doseQuantities?: string[];
  frequencies?: MedicationChangeFrequency[];
  startDate?: Date;
  endDate?: Date;
  status?: MedicationChangeStatus;
  reason?: string;
  maxToleratedDoseAchieved?: string;
};

export type MedPayload = {
  rxnormId?: string;
  doseQuantities?: number[];
  frequencies?: MedicationChangeFrequency[];
  startDate?: string;
  endDate?: string;
  status?: MedicationChangeStatus;
  reason?: string;
  noteId?: number;
  medicationChangeId?: string;
  maxToleratedDoseAchieved?: boolean;
  asyncTitration?: {
    isAsyncTitration: boolean;
    asyncTitrationId: string;
    rxcui: string;
  };
};

export function serializeMedFormFields(
  values: MedFormFields,
  noteId: Maybe<number>,
) {
  return {
    doseQuantities: values.doseQuantities?.map((q) => Number(q)),
    frequencies: values.frequencies,
    startDate: values.startDate?.toISOString(),
    endDate: values.endDate?.toISOString(),
    status: values.status,
    noteId: noteId || undefined,
    reason: values.reason,
    maxToleratedDoseAchieved: Boolean(values.maxToleratedDoseAchieved),
    ...serializeRxNormFields(values),
  };
}

function serializeRxNormFields(values: MedFormFields) {
  if (values.rxnormId && values.rxnormId !== '0') {
    return { rxnormId: values.rxnormId };
  }

  const rxnormIdFromAutocomplete = values.rxnorm?.norm?.id;
  if (rxnormIdFromAutocomplete && rxnormIdFromAutocomplete !== '0') {
    return { rxnormId: rxnormIdFromAutocomplete };
  }

  const medNameFromInput = values.rxnorm?.inputValue;
  if (medNameFromInput) {
    return { medicationName: medNameFromInput };
  }

  return undefined;
}

export type MedFormInitValues = MedicationChange & {
  isAsyncTitration?: boolean;
  asyncTitrationId?: string;
};

export function useMedicationForm(
  isTitration: boolean,
  showRxNormSelect: boolean,
  initMedClasses?: ReferenceMedicationMedicationClass[],
  initValues?: MedFormInitValues,
  hasChf?: boolean,
  hasHtn?: boolean,
) {
  const { data, isFetching } = useReferenceMedications();

  const intl = useIntl();
  const { required, enumType, date, array, maxLengthString } = validators(intl);

  return useFormFromConfig<MedFormFields>({
    triggerReset: !isFetching,
    fields: {
      ...(showRxNormSelect
        ? {
            rxnormId: {
              defaultValue: initValues?.rxnorm?.id,
              validation: required(
                yup.string().test({
                  name: 'required_rxnormid',
                  message: intl.formatMessage({ defaultMessage: 'Required' }),
                  // Need to validate this in addition to the required validator because rxnormId is an int64 value, which is translated to a string
                  test: async (value) => value !== '0',
                }),
              ),
            },
          }
        : {
            rxnorm: {
              defaultValue:
                initValues?.rxnorm?.id !== '0' &&
                initValues?.rxnorm?.id !== undefined
                  ? {
                      norm: initValues?.rxnorm,
                      medClasses: initMedClasses,
                    }
                  : { inputValue: initValues?.medicationName },

              validation: required(
                yup
                  .object({
                    norm: yup.object(),
                    inputValue: yup.string(),
                  })
                  .nullable()
                  .test({
                    name: 'required_rxnorm',
                    message: intl.formatMessage({ defaultMessage: 'Required' }),
                    test: async (value) =>
                      Boolean(
                        (value?.norm?.id && value?.norm?.id !== '0') ||
                          value?.inputValue,
                      ),
                  }),
              ),
            },
          }),
      doseQuantities: {
        defaultValue: initValues?.doseQuantities,
        validation: required(
          array({
            ofType: required(
              // Use mixed schema initially because we need to transform empty string to null first for required validation
              yup
                .mixed()
                // Transform to null to required validation is applied
                .transform((v) => (v === '' ? null : v))
                .nullable()
                .test(
                  'is-number',
                  intl.formatMessage({ defaultMessage: 'Invalid number' }),
                  (value) =>
                    value === null || (!Number.isNaN(value) && value >= 0),
                ),
            ),
            minLength: 1,
          }),
        ),
      },
      frequencies: {
        defaultValue: initValues?.frequencies,
        validation: required(
          array({
            ofType: required(
              enumType({
                source: MedicationChangeFrequency,
                pluck: 'values',
              }),
            ),
            minLength: 1,
          }),
        ),
      },
      ...(!isTitration && {
        status: {
          defaultValue:
            initValues?.status === MedicationChangeStatus.ACTIVE
              ? initValues?.status
              : undefined,
          validation: required(
            enumType({
              source: MedicationChangeStatus,
              pluck: 'values',
            }),
          ),
        },
      }),
      ...(isTitration && {
        startDate: {
          defaultValue: new Date(),
          validation: required(date()),
        },
      }),
      endDate: {
        defaultValue: initValues?.endDate ? parseISO(initValues.endDate) : null,
        validation: isTitration
          ? date()
          : date().when('status', {
              is: MedicationChangeStatus.INACTIVE,
              then: required(date()),
            }),
      },
      ...(isTitration &&
        (hasChf || hasHtn) && {
          maxToleratedDoseAchieved: {
            validation: yup.boolean().when(['rxnormId', 'rxnorm'], {
              is: (rxnormId: string, rxnorm: RxNormOptionType) => {
                const id = rxnorm?.norm?.id || rxnormId;
                return (
                  (hasChf && isChfGdmt(data?.referenceMedications, id)) ||
                  (hasHtn && isHtnMed(data?.referenceMedications, id))
                );
              },
              then: required(yup.boolean()),
            }),
          },
        }),
      reason: {
        defaultValue: initValues?.reason || '',
        validation: maxLengthString({ maxLength: 255 }),
      },
    },
  });
}
