import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import type { DeviceUsageGoalsFormFields } from '../../shared/LowDeviceUsage';
import {
  SharedLowDeviceUsage,
  getDeviceUsageGoalsFormConfig,
} from '../../shared/LowDeviceUsage';
import { initVisitPath } from '../paths';
import { useDeviceUsageNextPath } from './useDeviceUsageNextPath';

export function LowDeviceUsage() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<DeviceUsageGoalsFormFields>(
    initVisitPath('/product-support', '/low-device-usage'),
    getDeviceUsageGoalsFormConfig(intl),
  );
  const history = useHistory();

  const nextPath = useDeviceUsageNextPath();

  return (
    <SharedLowDeviceUsage
      form={form}
      sections={INIT_VISIT_SECTIONS}
      onNext={() => history.push(nextPath)}
    />
  );
}
