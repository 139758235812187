import type { History } from 'history';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';

import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { getDefaults } from '@/shared/common/Form/form.utils';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { usePrevious } from '@/shared/hooks';

import type { SectionMeta } from '../path.utils';
import type { StepCompletion, StepFormValues } from '../state';
import { useWizardStateContext } from '../state';
import { BackButton } from './BackButton';
import {
  body,
  container,
  footer,
  header,
  subtitle as subtitleStyle,
  title as titleStyle,
} from './Step.css';
import { SubmitButton } from './SubmitButton';

type HistoryWithIndex = History & { index: number };

export type SubmitButtonOverrideProps<TFieldValues extends FieldValues> = {
  submitWithTiming: (
    isComplete: boolean,
    overrideTimerRunning?: boolean,
  ) => TFieldValues | StepFormValues;
} & DiscriminatedSubmitButtonFormProps<TFieldValues>;

export type SubmitBtnRenderFn<TFieldValues extends FieldValues> = (
  params: SubmitButtonOverrideProps<TFieldValues>,
) => ReactNode;

export type BackButtonOverrideProps = {
  onBack: () => void;
};

export type BackBtnRenderFn = (params: BackButtonOverrideProps) => ReactNode;

export type DiscriminatedFormProps<TFieldValues extends FieldValues> =
  | {
      form: ConfiguredForm<TFieldValues>;
      onNext?: SubmitHandler<TFieldValues>;
    }
  | {
      form?: null;
      onNext?: () => void;
    };

export type DiscriminatedSubmitButtonFormProps<
  TFieldValues extends FieldValues,
> =
  | {
      form: ConfiguredForm<TFieldValues>;
      onNext: SubmitHandler<TFieldValues>;
    }
  | {
      form?: null;
      onNext: () => void;
    };

type Props<
  TFieldValues extends FieldValues,
  TSections extends Readonly<SectionMeta[]>,
> = {
  title?: string;
  subtitle?: string;
  sections: TSections;
  children: ReactNode;
  isLoading?: boolean;
  backButton?: BackBtnRenderFn | false;
  submitButton?: SubmitBtnRenderFn<TFieldValues> | false;
} & DiscriminatedFormProps<TFieldValues>;

export function Step<
  TFieldValues extends FieldValues,
  TSections extends Readonly<SectionMeta[]>,
>({
  title,
  subtitle,
  sections,
  children,
  backButton,
  submitButton,
  isLoading,
  ...formProps
}: Props<TFieldValues, TSections>) {
  const { form } = formProps;
  const history = useHistory();
  const { pathname: fullStepPath } = useLocation();
  const { submitSectionStep, timerRunning } = useWizardStateContext();
  const wasTimerRunning = usePrevious(timerRunning);
  const values = form?.watch() || null;
  const startTime = useRef(Date.now());
  const formConfigFields = form?.config?.fields;
  const submitWithTiming = useCallback(
    (isComplete: StepCompletion, overrideTimerRunning: boolean = false) => {
      const now = Date.now();
      const defaultValues = getDefaults(formConfigFields || {}, {
        includeUndefinedVals: true,
      });
      const formValues = values && { ...defaultValues, ...values };

      submitSectionStep(fullStepPath, {
        values: formValues,
        isComplete,
        elapsed:
          timerRunning || overrideTimerRunning ? now - startTime.current : 0,
      });

      startTime.current = now;

      return formValues;
    },
    [formConfigFields, fullStepPath, submitSectionStep, timerRunning, values],
  );

  useEffect(() => {
    if (wasTimerRunning === undefined) {
      // first render, bail out because nothing has changed
      return;
    }

    if (wasTimerRunning && !timerRunning) {
      // timer was running, but now it's not. submit whatever we've captured
      // between the last submit and now
      submitWithTiming('preserve', true);
    }

    if (!wasTimerRunning && timerRunning) {
      // timer was not running, but now it is. reset the start time
      startTime.current = Date.now();
    }
  }, [timerRunning, wasTimerRunning, startTime, submitWithTiming]);

  useInterval(
    () => submitWithTiming('preserve'),
    // 10 second interval here is arbitrary. we're only displaying minutes
    // so we don't need to update the timer super frequently.
    // Note: Since we are submitting, this will trigger an autosave
    timerRunning ? 10 * 1000 : null,
  );

  return (
    <div className={container}>
      <div className={body}>
        {(title || subtitle) && (
          <div className={header}>
            <h2 className={titleStyle}>{title}</h2>
            <div className={subtitleStyle}>{subtitle}</div>
          </div>
        )}
        <Wrapper {...formProps} onBlur={() => submitWithTiming('preserve')}>
          <LoadingPlaceholder keepMounted isLoading={!!isLoading}>
            {children}
          </LoadingPlaceholder>
        </Wrapper>
      </div>
      <div className={footer}>
        <div>
          {(history as HistoryWithIndex).index !== 0 && (
            <BackButton
              backButton={backButton}
              submitWithTiming={() =>
                submitWithTiming(form ? form.formState.isValid : 'preserve')
              }
            />
          )}
        </div>
        <div>
          <SubmitButton
            {...formProps}
            isDisabled={isLoading}
            submitButton={submitButton}
            submitWithTiming={submitWithTiming}
          />
        </div>
      </div>
    </div>
  );
}

type WrapperProps<TFieldValues extends FieldValues> = {
  children: ReactNode;
  onBlur?: () => void;
} & DiscriminatedFormProps<TFieldValues>;

function Wrapper<TFieldValues extends FieldValues>({
  form,
  children,
  onBlur,
}: WrapperProps<TFieldValues>) {
  if (form) {
    return (
      <Form form={form} onBlur={onBlur}>
        {children}
      </Form>
    );
  }

  return <>{children}</>;
}
