import { CYCLE_17_INIT_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/cycle17InitialVisit';
import type { FormFields as IntroAdditionalNotesFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/cycle17InitialVisit/introduction';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { MarkdownBuilder } from '../../../engine/MarkdownBuilder';
import { sectionHasData } from '../../util';

export function initialVisitAdditionalNotes(data: SectionStepsState) {
  const introData = getWizardStepValues(
    data,
    CYCLE_17_INIT_VISIT_SECTIONS,
    '/intro',
    '/questions',
  )<IntroAdditionalNotesFormFields>();

  if (!sectionHasData(introData) || !introData?.additionalNotes) {
    return '';
  }

  const md = new MarkdownBuilder();

  md.p(introData?.additionalNotes);

  return md.toString();
}
