import { camelizeKeys } from 'humps';
import { useEffect, useState } from 'react';

import type {
  Device as ApiDevice,
  DevicesResponse,
} from '@/shared/types/device.types';
import Session from '@/shared/utils/session';

export type Device = SnakeToCamelCaseNested<ApiDevice>;

export function useAssignedDevices(
  patientId: string,
): [boolean, unknown, Device[]] {
  const [devices, setDevices] = useState<Device[]>([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    Session.Api.get<DevicesResponse>(
      `/pms/api/v1/device_assignments/${patientId}`,
    )
      .then((response) => {
        const camelized = camelizeKeys(
          response.data.data,
        ) as unknown as Device[];

        setDevices(camelized);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, [patientId]);

  return [isLoading, error, devices];
}
