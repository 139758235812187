import { FormattedMessage } from 'react-intl';

import { AcceptButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/AcceptButton';
import { RejectButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/RejectButton';

import { patientDecision } from '../SuggestedTitration.css';
import type { SuggestedTitrationMode } from '../types';

type Props = {
  mode: SuggestedTitrationMode;
  isDisabled?: boolean;
  onReject: () => void;
  onAccept: () => void;
};

export function InitiallyReviewedActions({
  mode,
  isDisabled = false,
  onReject,
  onAccept,
}: Props) {
  return (
    <div className={patientDecision.container}>
      <div className={patientDecision.header}>
        <FormattedMessage defaultMessage="Patient decision" />
      </div>
      <div className={patientDecision.description}>
        <FormattedMessage defaultMessage="Ask the patient if they accept the pending titration." />
      </div>
      <div className={patientDecision.actionsContainer}>
        <RejectButton onReject={onReject} mode={mode} isDisabled={isDisabled}>
          <FormattedMessage defaultMessage="Reject" />
        </RejectButton>
        <AcceptButton onAccept={onAccept} mode={mode} isDisabled={isDisabled}>
          <FormattedMessage defaultMessage="Accept" />
        </AcceptButton>
      </div>
    </div>
  );
}
