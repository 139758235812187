import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import trim from 'lodash/trim';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';

import { STALE_TIME, useFlatPages } from '@/reactQuery';
import { useMarketsInfinite } from '@/shared/hooks/queries/market.queries';
import { flexGrow } from '@/shared/jsStyle/flex.css';
import { MultiSelect } from '@/shared/tempo/@labs/molecule/MultiSelect';
import { grpcNameToId } from '@/shared/utils/grpc';

import { useFiltersQuery } from '../../../hooks/useFiltersQuery.hook';
import { ALL_MARKETS_KEY } from './constants';

export function MarketFilterDropdown() {
  const intl = useIntl();
  const { marketFilter, setMarketFilter } = useFiltersQuery();

  const marketsInfiniteQuery = useMarketsInfinite(
    { orderBy: 'healthSystemName asc' },
    { staleTime: STALE_TIME.TEN_MINUTES },
  );

  const [lastRowRef, lastRowInView] = useInView();
  const { isFetching, hasNextPage, fetchNextPage } = marketsInfiniteQuery;
  const hasMoreToLoad = hasNextPage ?? false;

  // Fetch when user has scrolled to the bottom (and there are more items to fetch)
  useEffect(() => {
    if (lastRowInView && hasMoreToLoad && !isFetching) {
      fetchNextPage();
    }
  }, [isFetching, lastRowInView, hasMoreToLoad, fetchNextPage]);

  const marketsData = useFlatPages(marketsInfiniteQuery);
  const marketsGroupedByHealthSystem = useMemo(
    () =>
      map(
        groupBy(marketsData, 'healthSystemName'),
        (markets, healthSystemName) => ({
          name: healthSystemName,
          children: sortBy(markets, 'displayName'),
        }),
      ),
    [marketsData],
  );

  const lastSection =
    marketsGroupedByHealthSystem?.[marketsGroupedByHealthSystem.length - 1];
  const lastMarket = lastSection?.children[lastSection.children.length - 1];

  const hasSelectedMarkets =
    !!marketFilter.length && !isEqual(marketFilter, [ALL_MARKETS_KEY]);

  return (
    <MultiSelect
      className={flexGrow[1]}
      selectedKeys={marketFilter}
      ignoreKeys={[ALL_MARKETS_KEY]}
      placeholder={intl.formatMessage({ defaultMessage: 'All markets' })}
      disabledKeys={
        marketFilter.includes(ALL_MARKETS_KEY) ? [ALL_MARKETS_KEY] : []
      }
      isLoading={hasSelectedMarkets && marketsInfiniteQuery.isLoading}
      onSelectionChange={(key) => {
        const selected = key instanceof Set ? Array.from(key) : [key];
        const isSelectingAllMarkets =
          !marketFilter.includes(ALL_MARKETS_KEY) &&
          selected.includes(ALL_MARKETS_KEY);

        if (selected.length === 0 || isSelectingAllMarkets) {
          // If no selection, default to All Markets
          // Selecting all markets should de-select all other options
          setMarketFilter([ALL_MARKETS_KEY]);
        } else {
          // Deselect "All markets" on other selections
          setMarketFilter(
            selected.filter((k) => k !== ALL_MARKETS_KEY) as string[],
          );
        }
      }}
      aria-label={intl.formatMessage({ defaultMessage: 'Markets filter' })}
      items={[
        {
          name: null, // All Markets is not under a section
          children: [
            {
              name: ALL_MARKETS_KEY,
              displayName: intl.formatMessage({
                defaultMessage: 'All markets',
              }),
            },
          ],
        },
        ...marketsGroupedByHealthSystem,
      ]}
    >
      {(section) => (
        <MultiSelect.Section
          key={section.name}
          items={section.children}
          title={section.name}
        >
          {(market) => (
            <MultiSelect.Option key={grpcNameToId(market.name || '')}>
              <span
                {...(market.name === lastMarket?.name && { ref: lastRowRef })}
              >
                {trim(market.displayName) || '-'}
              </span>
            </MultiSelect.Option>
          )}
        </MultiSelect.Section>
      )}
    </MultiSelect>
  );
}
