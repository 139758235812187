import cx from 'classnames';
import type { ReactElement } from 'react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';

import { useTaskCardContext } from '../taskCardContext';
import { ANIMATION_DURATION_MS, transition } from './CardHoverAction.css';

type Props = {
  children:
    | ReactElement
    | (({ isDisabled }: { isDisabled: boolean }) => ReactElement);
  className?: string;
};

export function CardHoverAction({ children, className }: Props) {
  const { isHovering, isEditingComment } = useTaskCardContext();
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Transition
      in={isHovering}
      appear
      nodeRef={ref}
      timeout={ANIMATION_DURATION_MS}
    >
      {(state) => (
        <div
          ref={ref}
          aria-disabled={isEditingComment}
          className={cx(transition.base, transition.states[state], className)}
        >
          {typeof children === 'function'
            ? children({ isDisabled: isEditingComment })
            : children}
        </div>
      )}
    </Transition>
  );
}
