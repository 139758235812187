import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

export function isOfType<T>(
  // Typeguard from any -> T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  varToBeChecked: any,
  propertyToCheckFor: keyof T,
): varToBeChecked is T {
  return (varToBeChecked as T)[propertyToCheckFor] !== undefined;
}

/**
 * It returns an array with item replaced if found by predicate
 * or with item added at the end of the items array.
 * @param items - array to upsert item into
 * @param item - item to upsert
 * @param predicate - predicate to find an item to replace
 */
export function upsertItem<T>(
  items: T[],
  item: T,
  predicate: (item: T) => boolean,
) {
  const existingItemIndex = items.findIndex(predicate);
  return existingItemIndex >= 0
    ? [
        ...items.slice(0, existingItemIndex),
        item,
        ...items.slice(existingItemIndex + 1),
      ]
    : [...items, item];
}

/**
 * It returns an array with item removed if found by predicate
 * @param items - array to remove item from
 * @param predicate - predicate to find item to remove
 */
export function removeItem<T>(items: T[], predicate: (item: T) => boolean) {
  const existingItemIndex = items.findIndex(predicate);
  return existingItemIndex >= 0
    ? [
        ...items.slice(0, existingItemIndex),
        ...items.slice(existingItemIndex + 1),
      ]
    : [...items];
}

/**
 * Returns new object from filtered object entries.
 * It's recursive. The filter is applied on an entry before going deeper.
 */
export function filterObjectDeep(
  objectToFilter: Record<string, unknown>,
  entryPredicate: (key: string, value: unknown) => boolean,
): Record<string, unknown> {
  const filtered = Object.fromEntries(
    Object.entries(objectToFilter).filter(([key, value]) =>
      entryPredicate(key, value),
    ),
  );
  return mapValues(filtered, (value) =>
    isPlainObject(value)
      ? filterObjectDeep(value as Record<string, unknown>, entryPredicate)
      : value,
  );
}
