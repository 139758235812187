import {
  endOfDay,
  formatISO,
  isEqual,
  startOfDay,
  startOfToday,
} from 'date-fns';
import { useRef, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { PublishConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import { timeTrackingFormValsToEntry } from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import type { TimeTrackingFormFields } from '@/pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm';
import type { SubmitButtonOverrideProps } from '@/shared/common/Wizard/Step';
import {
  useGetWizardStepValues,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useCanPublishToEhr } from '@/shared/hooks/useCanPublishToEhr';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { appointmentKeys } from '../../PatientScheduling/appointments.queries';
import { useCreateCnNote } from '../shared/query.hooks';
import { useCNFormContext } from './CNFormContext';
import { CN_SECTIONS_MAP } from './sections/metadata';

export function PublishButton<TFieldValues extends FieldValues>({
  submitWithTiming,
  ...formProps
}: SubmitButtonOverrideProps<TFieldValues>) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { currentUserId: providerId } = useCurrentUser();
  const [isPublishing, setIsPublishing] = useState(false);
  const {
    patientId,
    clearEditor,
    cnVisitType,
    noteId,
    zendeskTicketId,
    appointmentId,
    noShowAppointmentId,
  } = useCNFormContext();
  const { data: patient } = usePatientDetails(patientId, false);
  const canPublishToEhr = useCanPublishToEhr(patientId);
  const { wizardFormData, setTimerRunning, timerRunning } =
    useWizardStateContext();
  const initialTimerRunning = useRef(timerRunning);
  const {
    mutate: createCnNote,
    isLoading: isCreating,
    loadingContext,
  } = useCreateCnNote(patientId, noteId, cnVisitType);
  const { toaster } = useToaster();
  const { onNext, form } = formProps;
  const timeEntryVals = useGetWizardStepValues(
    CN_SECTIONS_MAP[cnVisitType],
    '/time-tracking',
    '/index',
  )<TimeTrackingFormFields>();

  const startPublish = () => {
    initialTimerRunning.current = timerRunning;
    setTimerRunning(false);
    setIsPublishing(true);
  };

  const timeEntry = timeEntryVals
    ? timeTrackingFormValsToEntry(timeEntryVals)
    : undefined;
  const totalTimeEntryDuration =
    (timeEntry?.duration || 0) + (timeEntry?.non_interactive_duration || 0);

  return (
    <>
      <Button
        size="small"
        onPress={startPublish}
        isProcessing={isCreating}
        isDisabled={(!!form && !form.canSubmit) || loadingContext}
      >
        <FormattedMessage defaultMessage="Publish" />
      </Button>
      <PublishConfirmationDialog
        patientId={patientId}
        ehr={patient?.ehr_information?.ehr}
        timeEntry={timeEntry || {}}
        shouldEmrSync={canPublishToEhr}
        isOpen={isPublishing}
        publishConfirmations={{
          isAppointmentAssociationNeeded:
            !appointmentId && !noShowAppointmentId,
          isNonTodayStartDate: Boolean(
            timeEntry?.start_date &&
              !isEqual(startOfDay(timeEntry.start_date), startOfToday()),
          ),
          isZeroDuration: totalTimeEntryDuration === 0,
        }}
        onConfirm={async (apptId) => {
          submitWithTiming(true);
          setIsPublishing(false);

          createCnNote(
            {
              apptId: apptId ?? appointmentId,
              noShowApptId: noShowAppointmentId,
              zendeskTicketId,
              shouldEmrSync: canPublishToEhr,
              ...wizardFormData,
            },
            {
              onSuccess() {
                clearEditor();
                toaster.success(
                  intl.formatMessage({
                    defaultMessage: 'Successfully published note',
                  }),
                );
                if (!form) {
                  onNext();
                } else {
                  onNext(form.getValues());
                }
              },
              onError() {
                toaster.error(
                  intl.formatMessage({
                    defaultMessage: 'Failed to publish note',
                  }),
                );
              },
            },
          );
          await queryClient.invalidateQueries(
            appointmentKeys.list({
              apptStartTimeFrom: formatISO(startOfDay(new Date())),
              apptStartTimeTo: formatISO(endOfDay(new Date())),
              careProviderId: providerId,
            }),
          );
          await queryClient.invalidateQueries(
            appointmentKeys.nextScheduled(patientId),
          );
        }}
        onCancel={() => {
          setIsPublishing(false);
          setTimerRunning(initialTimerRunning.current);
        }}
      />
    </>
  );
}
