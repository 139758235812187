<p>{{givenName}} {{familyName}} confirmed receipt of RPM device(s) and successful instruction and education on such device(s). Consent verbally obtained for Cadence to discuss health and treatment with the following: [first and last name, relationship to patient], [first and last name, relationship to patient], with no objection noted. Cadence contacts have been saved in the assigned mobile phone and vitals readings were successfully received. Initial intake phone call scheduled.</p>

<br/>
<p><strong>OR</strong></p>
<br/>

<p>[First and last name], [relationship to patient], confirmed patient’s receipt of RPM device(s) and successful instruction and education on such device(s). Consent verbally obtained for Cadence to discuss health and treatment with the following: [first and last name, relationship to patient], [first and last name, relationship to patient], with no objection noted. Cadence contacts have been saved in the assigned mobile phone and vitals readings were successfully received. Initial intake phone call scheduled.</p>

<br/>

<i>Any patient lifestyle information that would be helpful for the initial visit</i>
{{> free_text_space }}

<p>Vitals taken on day of activation or time of activation:</p>
<p>BP <i>[]</i></p>
<p>HR <i>[]</i></p>
<p>Weight <i>[]</i> lbs</p>
<p>Blood glucose <i>[]</i></p>
<p><i>Escalation not required / Escalation required &amp; note on action taken</i>.</p>
