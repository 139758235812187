import type { QueryParams } from '@/reactQuery';

export enum OpType {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  GREATER_THAN_OR_EQUAL = '>=',
  LESS_THAN_OR_EQUAL = '<=',
  ILIKE = 'ILIKE',
  CONTAINS = ':',
}

type ValueMapper = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
) => Array<string> | Array<number> | string | number;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NativeFilterGetter = (value: any) => string;

type FilterDeclaration = {
  renames?: string[];
  op?: OpType;
  valueMapper?: ValueMapper;
  nativeFilterGetter?: NativeFilterGetter;
};

export type QueryParamsFilterLookup = {
  [key: string]: FilterDeclaration;
};

export class GrpcFilterBuilder {
  filter: string = '';

  public fromQueryParams(qp: QueryParams, qpLookup: QueryParamsFilterLookup) {
    Object.keys(qp).forEach((key) => {
      const filterDeclaration = qpLookup[key];

      if (filterDeclaration) {
        if (filterDeclaration.nativeFilterGetter) {
          this.applyNativeFilter(filterDeclaration.nativeFilterGetter, qp[key]);
          return;
        }
        this.maybeAddAND();
        const mappedValue = GrpcFilterBuilder.applyValueMapping(
          filterDeclaration?.valueMapper,
          qp[key],
        );
        this.handleRenames(filterDeclaration, mappedValue);
      }
    });
    return this;
  }

  private maybeAddAND() {
    if (!(this.isFilterEmpty() || this.doesFilterEndWithAND())) {
      this.and();
    }
  }

  private applyNativeFilter(
    native: NativeFilterGetter,
    value: QueryParams[string],
  ) {
    const nativeFilter = native(value);
    if (nativeFilter) {
      this.maybeAddAND();
      this.filter += nativeFilter;
    }
  }

  private static applyValueMapping(
    mapper: ValueMapper | undefined,
    value: QueryParams[string],
  ) {
    return mapper ? mapper(value) : value;
  }

  private handleRenames(
    filterDeclaration: FilterDeclaration,
    value: QueryParams[string],
  ) {
    const { renames, op } = filterDeclaration;

    this.openParen();
    if (renames?.length === 1) {
      this.handleSingleRename(renames[0], op, value);
    } else {
      this.handleMultipleRenames(renames, op, value);
    }
    this.closeParen();
  }

  private handleSingleRename(
    rename: string,
    op: OpType | undefined,
    val: QueryParams[string],
  ) {
    if (typeof val === 'string' || (Array.isArray(val) && val.length === 1)) {
      this.where(rename, op, Array.isArray(val) ? val[0] : val);
    }

    if (Array.isArray(val) && val.length > 1) {
      this.where(rename, op, val[0]);
      val.slice(1).forEach((v) => {
        this.orWhere(rename, op, v);
      });
    }
  }

  private handleMultipleRenames(
    renames: FilterDeclaration['renames'],
    op: OpType | undefined,
    val: QueryParams[string],
  ) {
    renames?.forEach((name: string, index: number) => {
      if (index === 0) {
        this.where(name, op, Array.isArray(val) ? val[0] : val);
      } else {
        this.orWhere(name, op, Array.isArray(val) ? val[0] : val);
      }
    });
  }

  private isFilterEmpty() {
    return this.filter === '';
  }

  private doesFilterEndWithAND() {
    return this.filter.endsWith('AND ');
  }

  private openParen() {
    this.filter += '(';
  }

  private closeParen() {
    this.filter += ')';
  }

  private and() {
    this.filter += ' AND ';
  }

  private where(
    column: string,
    op: OpType | undefined,
    val: string | number | boolean,
  ) {
    // If operation OpType is undefined or when value is undefined, we assume that the column is a boolean column,
    // and we don't want to add the operation or the value to the filter
    // e.g. "WHERE isProspective"
    if (op === undefined || val === undefined) {
      this.filter += column;
      return;
    }

    // Otherwise, we add the operation and the value to the filter as usual
    this.filter += `${column} ${op} ${
      typeof val === 'string' ? `"${val}"` : val
    }`;
  }

  private orWhere(
    column: string,
    op: OpType | undefined,
    val: string | number | boolean,
  ) {
    // If operation OpType is undefined or when value is undefined, we assume that the column is a boolean column,
    // and we don't want to add the operation or the value to the filter
    // e.g. "WHERE isProspective"
    if (op === undefined || val === undefined) {
      this.filter += column;
      return;
    }

    // Otherwise, we add the operation and the value to the filter as usual
    this.filter += ` OR ${column} ${op} ${
      typeof val === 'string' ? `"${val}"` : val
    }`;
  }
}
