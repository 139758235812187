import { FormattedMessage } from 'react-intl';

import CheckIcon from '@/shared/assets/svgs/check.svg?react';

import { BaseIndicator } from './BaseIndicator';

export function ResolvedIndicator() {
  return (
    <BaseIndicator
      variant="success"
      tooltip={<FormattedMessage defaultMessage="Resolved" />}
    >
      <CheckIcon />
    </BaseIndicator>
  );
}
