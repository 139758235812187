import { type ReactNode, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { TimeTrackedTaskType } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { useTimeTrackingEditableDateRange } from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import CallIcon from '@/shared/assets/svgs/calling.svg?react';
import FileIcon from '@/shared/assets/svgs/file.svg?react';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { BaseField } from '@/shared/common/Form/fields/BaseField';
import { useFlags } from '@/shared/hooks';
import { useOnMount } from '@/shared/hooks/useOnMount';
import type { MonitoringSession } from '@/shared/types/monitoringSession.types';
import { type EntryType } from '@/shared/types/monitoringSession.types';

import { CallTracking } from '../CallTracking/CallTracking';
import { TimeTrackerMinutesField } from './fields/TimeTrackerMinutesField';
import { TimeTrackerTasksField } from './fields/TimeTrackerTasksField';
import { TimeTrackerTimeField } from './fields/TimeTrackerTimeField';
import type { TimeTrackingFormFields } from './formConfig';
import {
  Divider,
  TASK_OPTIONS,
  TasksContainer,
  TotalTimeDisplay,
} from './shared';
import { taskDescriptionInput, timePickerField } from './styles.css';

type EntryAction =
  | { action: 'create'; entryType: EntryType }
  | { action: 'update'; timeEntry: MonitoringSession };

type Props = {
  patientNoShow?: boolean;
  patientId?: string;
  noteId?: number;
  form?: ConfiguredForm<TimeTrackingFormFields>;
} & EntryAction;

export function ManualTimeTrackingForm({
  form,
  patientNoShow,
  patientId,
  noteId,
  ...entryProps
}: Props) {
  const intl = useIntl();
  const { addCallsToEncounter } = useFlags();
  const hasMounted = useRef(false);
  useOnMount(() => {
    hasMounted.current = true;
  });
  const isCreating = entryProps.action === 'create';

  const { minDate: minTimeTrackingDate, maxDate: maxTimeTrackingDate } =
    useTimeTrackingEditableDateRange(
      !isCreating ? entryProps.timeEntry : undefined,
    );

  const contextForm = useFormContext<TimeTrackingFormFields>();
  const retrievedForm = form || contextForm;
  const [
    interactiveDuration = 0,
    nonInteractiveDuration = 0,
    tasksAccomplished = [],
  ] = retrievedForm.watch([
    'interactive_duration',
    'non_interactive_duration',
    'tasks_accomplished',
  ]);

  useEffect(() => {
    // Can't have interactive duration if patient no show, so reset the value to zero
    if (patientNoShow) {
      retrievedForm.setValue('interactive_duration', 0);
    }
    // form is an unstable ref, so excluding it here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactiveDuration, patientNoShow]);

  // On changing non_interactive_duration time to 0, revalidate tasks_accomplished to hide validation message
  useEffect(() => {
    if (nonInteractiveDuration === 0) {
      retrievedForm.trigger('tasks_accomplished');
    }
    // Don't trigger on changes of: form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonInteractiveDuration]);

  const totalTimeInMinutes = interactiveDuration + nonInteractiveDuration;

  return (
    <FormWrapper form={form}>
      <TimeTrackerMinutesField
        size={12}
        name="interactive_duration"
        min={0}
        onChange={() =>
          hasMounted.current && retrievedForm?.trigger('totalTimeDisplay')
        }
        isDisabled={patientNoShow}
        tooltip={
          patientNoShow
            ? intl.formatMessage({
                defaultMessage:
                  'Interactive time cannot be entered for a patient no-show ',
              })
            : undefined
        }
        icon={<CallIcon width={16} height={16} />}
        label={<FormattedMessage defaultMessage="Interactive time" />}
        description={
          <FormattedMessage defaultMessage="Live phone conversation" />
        }
      />
      {addCallsToEncounter && noteId && patientId && (
        <CallTracking noteId={noteId} patientId={patientId} />
      )}
      <Divider />
      <TimeTrackerMinutesField
        size={12}
        min={0}
        onChange={() =>
          hasMounted.current && retrievedForm?.trigger('totalTimeDisplay')
        }
        name="non_interactive_duration"
        icon={<FileIcon width={16} height={16} />}
        label={<FormattedMessage defaultMessage="Non-interactive time" />}
        description={
          <FormattedMessage defaultMessage="Chart prep, documentation and other tasks" />
        }
      />
      <TasksContainer>
        <TimeTrackerTasksField
          required={!!nonInteractiveDuration}
          size={12}
          name="tasks_accomplished"
          label={
            <FormattedMessage defaultMessage="Select tasks you accomplished" />
          }
          options={TASK_OPTIONS}
        />
        {tasksAccomplished.includes(TimeTrackedTaskType.Other) && (
          <Form.TextField
            size={12}
            inputClass={taskDescriptionInput}
            name="other_task_description"
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter the task description',
            })}
            label={<FormattedMessage defaultMessage="Other task description" />}
          />
        )}
      </TasksContainer>
      <Form.Row>
        <BaseField name="totalTimeDisplay" size={12}>
          {({ renderError, controller: { fieldState } }) => (
            <TotalTimeDisplay
              minutes={totalTimeInMinutes}
              error={fieldState.error ? renderError() : null}
            />
          )}
        </BaseField>
      </Form.Row>
      <Divider />
      <Form.Row>
        <Form.DatePicker
          size={4}
          minDate={minTimeTrackingDate}
          maxDate={maxTimeTrackingDate}
          name="start_date"
          label={<FormattedMessage defaultMessage="Date of activity" />}
        />
        <TimeTrackerTimeField
          size={6}
          className={timePickerField}
          name="start_time"
          label={<FormattedMessage defaultMessage="Time of activity" />}
        />
      </Form.Row>
    </FormWrapper>
  );
}

type WrapperProps = {
  children: ReactNode;
  form?: ConfiguredForm<TimeTrackingFormFields>;
};
function FormWrapper({ children, form }: WrapperProps) {
  // If form is provided, wrap the form contents in a Form, else
  // assume the parent provides a wrapping Form
  if (form) {
    return <Form form={form}>{children}</Form>;
  }
  return <>{children}</>;
}
