import { convertFromHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import {
  CustomBlockType,
  CustomRtfEditorStyles,
} from '@/shared/common/EditorRTF/types';
import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { convertRtfBodyToRawDraftContentState } from './rtfBodyUtil';

export function convertRawDraftjsToHtml(rtfBody: TNoteBodyRTF) {
  return (
    draftToHtml(convertRtfBodyToRawDraftContentState(rtfBody))
      // Remove unnecessary new lines that are added by library
      // https://github.com/jpuri/draftjs-to-html/issues/89
      .replace(/\n+/g, '')
      // Translate blocks into hard breaks instead of paragraphs, because in the old editor they were treated as such
      // https://github.com/jpuri/draftjs-to-html/issues/80
      .replace(/<\/p><p>/g, '<br>')
      .replace(/<p>|<\/p>/g, '')
  );
}

export function convertHtmlToDraftJsEditorState(html: string) {
  // Using draft-convert's convertFromHTML instead of draft-js' due to
  // https://github.com/facebook/draft-js/issues/231
  const contentState = convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (node.classList?.contains('template-header')) {
        return currentStyle.add(CustomRtfEditorStyles.TEMPLATE_HEADER);
      }
      return currentStyle;
    },
    htmlToEntity(nodeName, node, createEntity) {
      if (nodeName === 'a') {
        return createEntity('LINK', 'MUTABLE', { url: node.href });
      }
      return undefined;
    },
    htmlToBlock(nodeName, node) {
      if (node.getAttribute('data-clearformat')) {
        return {
          type: CustomBlockType.ClearFormat,
          data: {},
        };
      }
      return undefined;
    },
  })(html);
  return EditorState.createWithContent(contentState);
}
