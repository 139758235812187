import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { validators } from '@/shared/common/Form/validations';
import { Modal } from '@/shared/common/Modal';
import { usePrevious } from '@/shared/hooks';
import {
  GRPC_RESOURCE_NAME,
  useUpdateTask,
} from '@/shared/hooks/queries/tasks.queries';
import { AssignmentAutocomplete } from '@/shared/tasking/AssignmentAutocomplete';
import type { ConvertedProvider, Task } from '@/shared/tasking/types';
import { TaskPriority } from '@/shared/tasking/types';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type { Provider } from '@/shared/types/provider.types';

import { toPartialProvider, urgencyToPriority } from '../../utils';

type Props = {
  open: boolean;
  task: Task;
  onClose: () => void;
};

type FormFields = {
  assignee: Provider | ConvertedProvider;
  is_urgent: boolean;
};

function useFormConfig(intl: IntlShape, task: Task): FormConfig {
  const { required } = validators(intl);
  const prevTask = usePrevious(task);
  return {
    fields: {
      assignee: {
        defaultValue: task.assignee
          ? toPartialProvider(task.assignee)
          : undefined,
        validation: required(yup.mixed()),
      },
      is_urgent: {
        defaultValue: task.priority === TaskPriority.Urgent,
        validation: yup.boolean(),
      },
    },
    triggerReset: prevTask?.updateTime !== task.updateTime,
  };
}

export function EditTaskModal({ open, task, onClose }: Props) {
  const intl = useIntl();
  const config = useFormConfig(intl, task);
  const form = useFormFromConfig<FormFields>(config);
  const { toaster } = useToaster();

  const { isLoading: isSaving, mutate: updateTask } = useUpdateTask(task, {
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to update task' }),
      ),
    onSuccess: () => {
      toaster.success(intl.formatMessage({ defaultMessage: 'Task updated' }));
      onClose();
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      // stops clicks from propagating to the underlying page and
      // opening random windows from clicking on tasks
      onClick={(e) => e.stopPropagation()}
    >
      <Form
        form={form}
        onSubmit={({ assignee, is_urgent }) => {
          updateTask({
            task: {
              priority: urgencyToPriority(is_urgent),
              assignee: {
                name: GRPC_RESOURCE_NAME.careProvider(assignee.id),
              },
            },
          });
        }}
      >
        {({ canSubmit }) => (
          <>
            <Modal.Header
              title={intl.formatMessage({ defaultMessage: 'Edit Task' })}
            />
            <Modal.Body>
              <Form.Row>
                <AssignmentAutocomplete
                  name="assignee"
                  initialValue={
                    task.assignee?.uid
                      ? toPartialProvider(task.assignee)
                      : undefined
                  }
                  view={{ type: 'participants', task }}
                />
              </Form.Row>
              <Form.Row>
                <Form.Toggle
                  name="is_urgent"
                  label={<FormattedMessage defaultMessage="Urgent task" />}
                />
              </Form.Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onPress={onClose}>
                <FormattedMessage defaultMessage="Cancel" />
              </Button>
              <Button
                variant="primary"
                type="submit"
                isProcessing={isSaving}
                isDisabled={!canSubmit}
              >
                <FormattedMessage defaultMessage="Update Task" />
              </Button>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
}
