import type { IntlShape } from 'react-intl';

import { INIT_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type { SecondarySymptomsFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/SecondarySymptoms';
import type { SymptomsFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/Symptoms';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';

import { cnSymptoms } from '../../shared/cnSymptoms';

export function initialVisitSymptoms(
  data: SectionStepsState,
  rpmConditions: RpmCondition[],
  intl: IntlShape,
) {
  const symptomsData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/symptoms',
    '/index',
  )<SymptomsFormFields>();
  const secondarySymptomsData = getWizardStepValues(
    data,
    INIT_VISIT_SECTIONS,
    '/symptoms',
    '/secondary',
  )<SecondarySymptomsFormFields>();

  return cnSymptoms({
    intl,
    rpmConditions,
    symptomsData,
    secondarySymptomsData,
  });
}
