import type { RefObject } from 'react';
import { useCallback, useState } from 'react';

import type {
  SidePanelState,
  SidePanelStateAndHandlers,
} from './sidePanel.types';
import { SidePanelType } from './sidePanel.types';

interface Params {
  initialIsVisible?: boolean;
}

export const useSidePanelState = ({
  initialIsVisible = false,
}: Params): SidePanelStateAndHandlers => {
  const [state, setState] = useState<SidePanelState>({
    type: SidePanelType.Normal,
    isFullscreen: false,
    isVisible: initialIsVisible,
    isExpanded: false,
    whitelistElements: [],
  });

  const { whitelistElements } = state;

  const updateState = useCallback(
    (newState: Partial<SidePanelState>) =>
      setState((prevState) => ({ ...prevState, ...newState })),
    [setState],
  );

  const addWhitelistElement = useCallback(
    (ref: RefObject<HTMLDivElement>) => {
      whitelistElements.push(ref);
      updateState({ whitelistElements });
    },
    [updateState, whitelistElements],
  );

  const setIsExpanded = useCallback(
    (isExpanded) => updateState({ isExpanded }),
    [updateState],
  );

  const setIsFullscreen = useCallback(
    (isFullscreen) => updateState({ isFullscreen }),
    [updateState],
  );

  const setIsVisible = useCallback(
    (isVisible) => updateState({ isVisible, isFullscreen: false }),
    [updateState],
  );

  const setType = useCallback((type) => updateState({ type }), [updateState]);

  return {
    handlers: {
      setType,
      setIsFullscreen,
      setIsVisible,
      setIsExpanded,
      addWhitelistElement,
    },
    state,
  };
};
