import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { SharedScheduling } from '../../shared/Scheduling';
import { titrationOutreachPath } from '../paths';

export function Scheduling() {
  return (
    <SharedScheduling
      sections={TITRATION_OUTREACH_SECTIONS}
      nextPath={titrationOutreachPath('/time-tracking', '/index')}
    />
  );
}
