import { useIntl } from 'react-intl';

import { DEFAULT_ORDER_BY } from '@/shared/hooks/queries/tasks.queries';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { useTaskFilterContext } from '../TaskFilterContext';
import { filterPopover, selectButton, subtleChevron } from './TaskFilters.css';

export function OrderBy() {
  const intl = useIntl();
  const { orderBy, onOrderByChange } = useTaskFilterContext();

  return (
    <Select
      variant="subtle"
      placeholder={intl.formatMessage({ defaultMessage: 'Sort by' })}
      aria-label={intl.formatMessage({ defaultMessage: 'Sort by' })}
      selectedKey={orderBy}
      className={selectButton}
      classes={{ popover: filterPopover, chevron: subtleChevron }}
      popover={{ placement: 'bottom end', width: 'static' }}
      onSelectionChange={(key) => onOrderByChange(key as string)}
      items={[
        {
          displayName: intl.formatMessage({
            defaultMessage: 'Urgency',
          }),
          value: DEFAULT_ORDER_BY,
        },
        {
          displayName: intl.formatMessage({
            defaultMessage: 'Most recent',
          }),
          value: 'updateTime desc',
        },
        {
          displayName: intl.formatMessage({
            defaultMessage: 'Least recent',
          }),
          value: 'updateTime asc',
        },
      ]}
    >
      {({ displayName, value }) => (
        <Select.Option key={value}>{displayName}</Select.Option>
      )}
    </Select>
  );
}
