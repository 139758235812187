import { decamelizeKeys } from 'humps';
import { useEffect } from 'react';
import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useNoteEditorContext } from '@/pages/patients/patientDetails/ui/Notes/NoteEditorContext';
import { EditableNoteType } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import type { PatientNoteParams } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import {
  patientNotesQueryKey,
  timeElapsedPayloadParam,
  zendeskPayloadParam,
} from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import type { QueryCallbacks } from '@/reactQuery/types';
import { PatientNotesService } from '@/shared/generated/api/pms';
import { useFlags } from '@/shared/hooks/useFlags';
import type { TimerState } from '@/shared/notes/Timer';
import type { NoteResponse, RequiredNoteType } from '@/shared/types/note.types';
import Session from '@/shared/utils/session';

import { invalidateEncounterSuggestionsQuery } from './encounter.queries';

export const AUTOSAVED_NOTES_QUERY_KEY_BASE = [
  'pms',
  'api',
  'v1',
  'notes',
  'patient',
];

export const autosavedNotesQueryKey = {
  patient: (patientId: string) =>
    [...AUTOSAVED_NOTES_QUERY_KEY_BASE, patientId, 'autosaved'] as const,
  delete: (patientId: string) =>
    [
      ...AUTOSAVED_NOTES_QUERY_KEY_BASE,
      patientId,
      'autosaved',
      'delete',
    ] as const,
};

export function usePatientAutosavedNote(
  patientId: string,
  params?: UseQueryOptions<NoteResponse>,
) {
  return useQuery<NoteResponse>(autosavedNotesQueryKey.patient(patientId), {
    ...params,
    refetchOnWindowFocus: false,
  });
}

export function useUpsertPatientAutosavedNote(
  patientId: string,
  timer: TimerState,
  callbacks: Partial<QueryCallbacks<RequiredNoteType>>,
) {
  const { appointmentReminders } = useFlags();
  const client = useQueryClient();
  const queryKeyBase = autosavedNotesQueryKey.patient(patientId);
  const draftQueryKey = patientNotesQueryKey.draft(patientId);
  const { editingNote } = useNoteEditorContext();

  const { isEditorOpen } = useNoteEditorContext();

  useEffect(() => {
    client.invalidateQueries(queryKeyBase);
    client.invalidateQueries(draftQueryKey);
    return () => {
      // Invalidate query on unmount
      client.invalidateQueries(queryKeyBase);
      client.invalidateQueries(draftQueryKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditorOpen]);

  return useMutation(
    (payload: PatientNoteParams) =>
      Session.Api.put<RequiredNoteType>(
        `/pms/api/v1/notes/patient/${patientId}/autosaved`,
        {
          ...decamelizeKeys(payload),
          // Don't decamelize keys in rtfBody object because the api is expecting
          // them to be in camel case
          rtf_body: payload.rtfBody,
          encounter_instances: payload.encounterModuleInstances,
          ui_state:
            editingNote?.type === EditableNoteType.Alert
              ? {
                  vitals_alert_id: editingNote.alert.id,
                }
              : null,
          ...zendeskPayloadParam(payload.zendeskTicket),
          end_encounter_type: payload.endEncounter?.endType,
          end_encounter_reason: payload.endEncounter?.endReason,
          end_encounter_note: payload.endEncounter?.endNote,
          ...timeElapsedPayloadParam(timer.timeElapsed, appointmentReminders),
        },
      ),
    {
      onSuccess: (data) => {
        invalidateEncounterSuggestionsQuery(patientId, client);
        callbacks.onSuccess?.(data.data);
      },
      onError: (err) => {
        callbacks.onError?.(err);
        // TODO: Surface error to user
        // console.error('Error creating autosaved note', error.message)
      },
    },
  );
}

export function useDeleteAutosavedNoteByPatientId(
  patientId: string,
  successCallback: () => void = () => {},
  errorCallback: (error: unknown) => void = () => {},
) {
  const client = useQueryClient();
  const queryKeyBase = autosavedNotesQueryKey.patient(patientId);
  return useMutation(
    autosavedNotesQueryKey.delete(patientId),
    (payload?: { new_note_id: number }) =>
      PatientNotesService.deletePmsApiV1NotesPatientAutosaved(
        patientId,
        payload,
      ),
    {
      onSuccess: async () => {
        invalidateEncounterSuggestionsQuery(patientId, client);
        await client.invalidateQueries(queryKeyBase);
        successCallback();
      },
      onError: (error) => {
        errorCallback(error);
      },
    },
  );
}
