import { FormattedMessage } from 'react-intl';

import { TimePicker } from '@/shared/common/TimePicker';

import type { NoteFieldValidationResult } from '../../validation';
import { TimeTrackerLabel } from '../TimeTrackerLabel';
import { ValidationMessage } from '../ValidationMessage';
import { validationError } from './StartOfEncounter.css';

export function StartOfEncounter({
  onChange,
  value,
  validationResult,
}: {
  onChange: (value: string) => void;
  value?: string;
  validationResult?: NoteFieldValidationResult;
}) {
  const messageOverride = useValidationMessageOverride(validationResult);
  return (
    <div>
      <TimeTrackerLabel
        message={<FormattedMessage defaultMessage="Start of encounter" />}
        error={Boolean(validationResult)}
      />
      <TimePicker
        error={Boolean(validationResult)}
        value={value ?? ''}
        onChange={onChange}
      />
      <ValidationMessage
        validationMessage={validationResult?.message}
        messageOverride={messageOverride}
        className={validationError}
      />
    </div>
  );
}

function useValidationMessageOverride(
  validationResult?: NoteFieldValidationResult,
) {
  switch (validationResult?.type) {
    case 'required':
      return <FormattedMessage defaultMessage="Must be a valid time" />;
    default:
      return null;
  }
}
