import cx from 'classnames';
import type { ReactNode } from 'react';

import { scriptText } from './Script.css';

type Props = {
  className?: string;
  children: ReactNode;
};

export function Script({ className, children }: Props) {
  return <span className={cx(scriptText, className)}>{children}</span>;
}
