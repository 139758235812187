import difference from 'lodash/difference';
import flatten from 'lodash/flatten';
import type { IntlShape } from 'react-intl';

import type {
  EducationModuleSection,
  EducationModulesReviewFormFields,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EducationModulesReview';
import { EDUCATION_MODULES } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EducationModulesReview';
import type {
  SetActionStepsFields,
  TActionStep,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/SetActionSteps';
import {
  type PatientGoalFormFields,
  getPatientGoalI18n,
  hasNewPatientGoal,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/setPatientGoal';
import { getMarkdownFromNode } from '@/pages/patients/patientDetails/ui/Notes/NotePreview/getMarkdownFromNode';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import type { GoalDiscussFormFields } from '../../../CNWizardForm/sections/regularVisit/actionPlan/PrevGoalDiscuss/formConfig';
import type { GoalProgressFormFields } from '../../../CNWizardForm/sections/regularVisit/actionPlan/PrevGoalProgress/formConfig';
import type { CNVisitType } from '../../../CNWizardForm/types';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';
import { sectionHasData } from '../util';

type Params = {
  intl: IntlShape;
  visitType: CNVisitType;
  patientGoalData: Maybe<PatientGoalFormFields>;
  educationModuleData: Maybe<EducationModulesReviewFormFields>;
  actionStepsData: Maybe<SetActionStepsFields>;
  // Only for regular visits
  goalProgressData: Maybe<GoalProgressFormFields>;
  goalDiscussionData: Maybe<GoalDiscussFormFields>;
};

export function cnActionPlan({
  intl,
  visitType,
  patientGoalData,
  educationModuleData,
  actionStepsData,
  goalProgressData,
  goalDiscussionData,
}: Params) {
  const md = new MarkdownBuilder();

  const patientGoalI18n = getPatientGoalI18n(intl);

  if (sectionHasData(patientGoalData)) {
    // Add Action Plan if patient goal has data
    md.h3('Action Plan');

    // HACK: Whenever we have two headers directly nested (Regardless of size or amount of newlines)
    // the EMR Generated PDF will squash them. Using bold text instead of h4 to get around this
    md.newline();
    md.concat('**Patient Health Goal:**');
    md.newline();

    if (visitType === TypeOfEncounter.CN_VISIT) {
      // Regular visit
      const hasNewGoal = hasNewPatientGoal(patientGoalData);
      const progressRating =
        goalProgressData?.goalProgressRating !== undefined
          ? `(Progress: ${goalProgressData?.goalProgressRating}/5)`
          : '';

      if (hasNewGoal) {
        md.field(
          'Current goal',
          patientGoalData?.previousPatientGoal
            ? `${
                patientGoalI18n[patientGoalData.previousPatientGoal]
              } ${progressRating}`
            : undefined,
        );
        md.field(
          'New goal',
          patientGoalData?.patientGoal
            ? patientGoalI18n[patientGoalData.patientGoal]
            : undefined,
        );
        if (patientGoalData?.newGoalReason) {
          md.field('Reason for new goal', patientGoalData.newGoalReason);
        }
      } else {
        md.field(
          'Patient goal',
          patientGoalData?.patientGoal
            ? `${
                patientGoalI18n[patientGoalData.patientGoal]
              } ${progressRating}`
            : undefined,
        );
      }

      if (goalDiscussionData?.actionPlanNotes) {
        md.newline();
        md.p(goalDiscussionData.actionPlanNotes);
      }
    } else if (visitType === TypeOfEncounter.INITIAL_CN_VISIT) {
      md.field(
        'Patient goal',
        patientGoalData?.patientGoal
          ? patientGoalI18n[patientGoalData.patientGoal]
          : undefined,
      );
    }
  }

  if (sectionHasData(educationModuleData)) {
    const prevReviewed = educationModuleData?.previousReviewedModules || [];
    const allReviewed = educationModuleData?.reviewedModules || [];
    const reviewedInThisEncounter = difference(allReviewed, prevReviewed);
    if (reviewedInThisEncounter.length > 0) {
      md.h4('Patient Education:');
      md.p('Today, the following module(s) were reviewed with the patient:');
      reviewedInThisEncounter.forEach((moduleSection) =>
        md.uli(getEducationModuleTitle(moduleSection)),
      );
    }
  }

  if (sectionHasData(actionStepsData)) {
    md.h4('Patient Self-defined Action Steps:');
    md.p('Top things I can do to meet this health goal are:');
    formatActionSteps(actionStepsData?.actionSteps || []).forEach(
      (stepSentence, idx) => {
        md.oli(stepSentence, idx);
      },
    );
  }

  return md.toString();
}

function formatActionSteps(steps: TActionStep[]) {
  return steps.map(({ step, frequency, dependencies }) =>
    [step, frequency, dependencies ? `if ${dependencies}` : null]
      .filter(Boolean)
      .join(', '),
  );
}

function getEducationModuleTitle(moduleSection: EducationModuleSection) {
  const moduleList = flatten(Object.values(EDUCATION_MODULES));
  const matchedSection = moduleList.find(
    ({ section }) => section === moduleSection,
  );
  return getMarkdownFromNode(matchedSection?.title) || 'N/A';
}
