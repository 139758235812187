type ConjunctionType = 'conjunction' | 'disjunction' | 'unit';

type ConjunctionWord<Conjunction extends ConjunctionType> =
  Conjunction extends 'conjunction'
    ? 'and'
    : Conjunction extends 'disjunction'
      ? 'or'
      : never;

type Join<
  Items extends readonly string[],
  Conjunction extends ConjunctionType,
> = Items extends []
  ? ''
  : Items extends [infer First extends string]
    ? First
    : Items extends [infer First extends string, infer Second extends string]
      ? Conjunction extends 'unit'
        ? `${First}, ${Second}`
        : `${First} ${ConjunctionWord<Conjunction>} ${Second}`
      : Items extends [
            infer First extends string,
            ...infer Rest extends string[],
          ]
        ? `${First}, ${Join<Rest, Conjunction>}`
        : string;

export function conjugateList<
  Items extends string[],
  Conjunction extends ConjunctionType = 'conjunction',
>(
  list: Items,
  type: Conjunction = 'conjunction' as Conjunction,
): Join<Items, Conjunction> {
  const formatter = new Intl.ListFormat('en', {
    style: 'long',
    type,
  });
  return formatter.format(list) as Join<Items, Conjunction>;
}
