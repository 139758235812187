import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { Button } from '@/shared/tempo/atom/Button';

import { SmartEncountersNoteLink } from '../../SmartEncountersNoteLink';

export type Props = {
  onClose: () => void;
  onManualConfirm: () => void;
};

export function ConfirmationModal({ onClose, onManualConfirm }: Props) {
  const intl = useIntl();
  return (
    <Modal open size="large" onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage:
            'Please confirm this note is not associated with an encounter',
        })}
      />
      <Modal.Body>
        <FormattedMessage
          defaultMessage="This time tracking method is only for non-encounter actions. If your logging is for a follow-up, alert resolution, or visit, please use the {encountersLink} to log time instead."
          values={{
            encountersLink: <SmartEncountersNoteLink onClick={onClose} />,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button id="close" variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
        <Button
          id="add-manual-time"
          type="submit"
          variant="primary"
          onPress={onManualConfirm}
        >
          <FormattedMessage defaultMessage="Add manual time" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
