import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Script } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Wizard } from '@/shared/common/Wizard';
import { usePatientContext, usePatientDetails } from '@/shared/hooks/queries';
import { useAssignedDevices } from '@/shared/hooks/queries/devices.queries';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';

import { useCNFormContext } from '../../../CNFormContext';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { VitalsList } from '../../shared/ProductSupport';
import { useConditionStrings } from '../../shared/ProductSupport/useConditionStrings';
import { regVisitPath } from '../paths';

export function Intro() {
  const intl = useIntl();
  const history = useHistory();
  const { patientId } = useCNFormContext();
  const { isLoading: isLoadingPatientContext } = usePatientContext(patientId);
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const { data: devices, isLoading: isLoadingDevices } = useAssignedDevices(
    patientId,
    {},
  );

  const { data: patientVitalsContext, isLoading: isLoadingVitalsContext } =
    usePatientVitalsContext(patientId);
  const contextVitals = patientVitalsContext?.contextVitals;

  const conditions = getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );
  const { condition, vitals } = useConditionStrings(conditions);

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Product Support' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() =>
        history.push(regVisitPath('/product-support', '/device-usage'))
      }
    >
      <LoadingPlaceholder
        isLoading={
          isLoadingPatientContext ||
          isLoadingPatient ||
          isLoadingVitalsContext ||
          isLoadingDevices
        }
      >
        <Script>
          <FormattedMessage
            defaultMessage="Now let's review your latest readings for your {condition}. Over the past 30 days, your {vitals} averages have been:"
            values={{ condition, vitals }}
          />
          <VitalsList
            conditions={conditions}
            devices={devices?.data}
            vitalsContext={contextVitals}
          />
          <FormattedMessage defaultMessage="Our goal is to get you to your best health and keep you there through working on lifestyle changes and if need be, medication changes." />
        </Script>
      </LoadingPlaceholder>
    </Wizard.Step>
  );
}
