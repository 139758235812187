import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedMedReview } from '../../shared/MedReview';
import type { MedReviewFormFields } from '../../shared/MedReview/formConfig';
import { getMedReviewFormConfig } from '../../shared/MedReview/formConfig';
import { initVisitPath } from '../paths';

export function MedReview() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<MedReviewFormFields>(
    initVisitPath('/med-review', '/index'),
    getMedReviewFormConfig(intl),
  );

  return (
    <SharedMedReview
      form={form}
      sections={INIT_VISIT_SECTIONS}
      nextPath={initVisitPath('/general-assessment', '/tobacco-use')}
    />
  );
}
