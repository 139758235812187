const NOTE_QUERY_KEY_BASE = ['pms', 'api', 'v1', 'notes'] as const;

export const cnNotePatientKeyBase = (patientId: string) =>
  [...NOTE_QUERY_KEY_BASE, patientId] as const;

export const cnNoteKeys = {
  autosaved: (patientId: string) =>
    [...cnNotePatientKeyBase(patientId), 'autosaved'] as const,
  publishedNotesInfinite: (
    patientId: string,
    params: Record<string, unknown> = {},
  ) => [...cnNotePatientKeyBase(patientId), params, 'infinite'] as const,
};
