import type { ReactElement } from 'react';

export type SectionMeta = {
  basePath: string;
  title: ReactElement;
  excludeFromProgress?: boolean;
  steps: Readonly<StepMeta[]>;
};

type StepMeta = {
  path: string;
};

type Section<S extends Readonly<SectionMeta[]>> = S[number];
export type SectionPath<
  S extends Readonly<SectionMeta[]> = Readonly<SectionMeta[]>,
> = S[number]['basePath'];
type StepPathMap<S extends Readonly<SectionMeta[]>> = {
  [K in SectionPath<S>]: Extract<
    Section<S>,
    { basePath: Readonly<K> }
  >['steps'][number]['path'];
};
export type StepPath<
  S extends Readonly<SectionMeta[]>,
  P extends SectionPath<S>,
> = StepPathMap<S>[P];

export function getFullStepPath<
  S extends Readonly<SectionMeta[]>,
  P extends SectionPath<S>,
>(sections: S, basePath: P, stepPath: StepPath<S, P>): string {
  const section = sections.find((s) => s.basePath === basePath);

  if (section) {
    const matchingStep = section.steps.find((step) => step.path === stepPath);
    if (matchingStep) {
      return `${section.basePath}${matchingStep.path}`;
    }
  }
  return '';
}
