type Minutes = number;
type Ms = number;

export function getTimerUrgency(
  timeElapsed: Ms,
  appointmentDuration: Maybe<Minutes>,
): 'none' | 'moderate' | 'severe' {
  if (!appointmentDuration) {
    return 'none';
  }

  const elapsedMinutes = timeElapsed / 60000;
  if (appointmentDuration - elapsedMinutes <= 2) {
    return 'severe';
  }
  if (appointmentDuration - elapsedMinutes <= 5) {
    return 'moderate';
  }
  return 'none';
}
