import useResizeObserver from '@react-hook/resize-observer';
import { useRef } from 'react';

import type { PopperProps } from '@/deprecated/mui';
import { Popper } from '@/deprecated/mui';

function useAnchorWidth(anchorEl: PopperProps['anchorEl']) {
  const popperRef = useRef<HTMLDivElement>(null);
  const anchor = typeof anchorEl === 'function' ? anchorEl() : anchorEl;
  const getWidth = () => `${anchor?.getBoundingClientRect().width}px`;

  // casting is fine here - no failures occur if anchor is null
  useResizeObserver(anchor as HTMLElement, () => {
    if (popperRef.current) {
      popperRef.current.style.width = getWidth();
    }
  });

  return { popperRef, getWidth };
}

// used to expand the autocomplete search results out to the right of the input
// has to use inline styles to override width.
export function WidePopper(props: PopperProps) {
  return (
    <Popper {...props} placement="bottom-start" style={{ width: '450px' }} />
  );
}

type ResponsivePopperProps = PopperProps & {
  isWithinModal?: boolean;
  minWidth?: string | number;
};

export function ResponsivePopper({
  isWithinModal,
  minWidth,
  ...props
}: ResponsivePopperProps) {
  const { anchorEl } = props;
  const { popperRef, getWidth } = useAnchorWidth(anchorEl);

  return (
    <Popper
      {...props}
      ref={popperRef}
      placement="bottom-start"
      style={{
        width: getWidth(),
        // Render popper below the left sidebar unless it's within a modal
        ...(!isWithinModal && { zIndex: 1 }),
        ...(minWidth && { minWidth }),
      }}
    />
  );
}

export function ModalPopper(props: PopperProps) {
  return <ResponsivePopper {...props} isWithinModal />;
}

export function ExtraWidePopper(props: PopperProps) {
  return <ResponsivePopper {...props} minWidth="75%" />;
}
