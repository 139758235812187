{{#if flags.autopopulateEncounterMedications}}
  {{#if ehrMedications}}
    {{> free_text_space }}
    {{#each ehrMedications}}
      <ul>
        <li>{{medName}} {{unstructuredsig}}</li>
      </ul>
    {{/each}}
  {{else}}
    <i>[name, dose, frequency for all meds]</i>
    <br/>
  {{/if}}
{{/if}}
