import { useCaregivers, usePatientDetails } from '@/shared/hooks/queries';
import type {
  CaregiverContact,
  StandaloneCaregiver,
} from '@/shared/types/caregiver.types';
import type { Patient } from '@/shared/types/patient.types';
import type { PhoneType } from '@/shared/types/shared.types';

export type ContactLike = {
  caregiverId?: string;
  primary?: boolean;
  contact?: {
    phone_type?: 'LANDLINE' | 'CELLULAR' | PhoneType;
    phone_number?: string;
    phoneNumber?: string;
  };
};

export function isCaregiverContacts(
  contacts: ContactLike[],
): contacts is CaregiverContact[] {
  return isCaregiverContact(contacts[0]);
}

export function isCaregiverContact(
  contact: ContactLike,
): contact is CaregiverContact {
  return 'caregiverId' in contact;
}

export function getPrimaryPhoneContact(contacts: ContactLike[] = []) {
  if (!contacts.length) {
    return null;
  }
  if (isCaregiverContacts(contacts)) {
    // Caregiver contacts
    return contacts?.find((contact) => contact.isPreferred) || null;
  }
  // Patient contacts
  return contacts?.find((contact) => contact.primary) || null;
}

export function getPreferredPhoneContact(
  patient: Patient,
  caregivers: StandaloneCaregiver[],
) {
  const patientPrimaryPhoneContact = getPrimaryPhoneContact(patient.contacts);
  const primaryCaregiver = getPrimaryCaregiver(caregivers);
  const primaryCaregiverPreferredPhoneContact = getPrimaryPhoneContact(
    primaryCaregiver?.contacts,
  );

  // The has_caregiver field means we want to prefer the caregiver contact info
  if (patient.has_caregiver) {
    return primaryCaregiverPreferredPhoneContact;
  }
  // Otherwise find and return the primary patient contact,
  return patientPrimaryPhoneContact;
}

export function getPrimaryCaregiver(caregivers: StandaloneCaregiver[]) {
  return caregivers.find((caregiver) => caregiver.isPrimary)?.caregiver;
}

export function usePrimaryPhoneContact(patientId: string) {
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const { data: caregivers, isLoading: isLoadingCaregivers } = useCaregivers(
    patientId,
    { enabled: Boolean(patientId) },
  );

  if (!patient || !caregivers || isLoadingPatient || isLoadingCaregivers) {
    return null;
  }

  return getPreferredPhoneContact(patient, caregivers || []);
}

export function extractPhoneNumberFromContact(contact: Maybe<ContactLike>) {
  if (!contact) {
    return {};
  }
  if (isCaregiverContact(contact)) {
    return {
      phone: contact.contact?.phoneNumber || null,
      type: contact.contact?.phoneType,
    };
  }
  return {
    phone: contact.contact?.phone_number || null,
    type: contact.contact?.phone_type || null,
  };
}

export function extractPhoneTypeFromContact(contact: Maybe<ContactLike>) {
  if (!contact) {
    return null;
  }
  if (isCaregiverContact(contact)) {
    return contact.contact?.phoneNumber || null;
  }
  return contact.contact?.phone_number || null;
}
