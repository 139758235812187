import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { SHARED_PROGRAM_MODULES as SHARED_PROGRAM_MODULES_DOT_ZERO } from '../../3.0.0/regularNpVisit';
import { generalAssessmentAndPlanModule as generalAssessmentAndPlanModuleDotOne } from './__shared__/assessmentAndPlanModule';
import { chf as chfDotOne } from './chf';

const SHARED_PROGRAM_MODULES = {
  ...SHARED_PROGRAM_MODULES_DOT_ZERO,
  ...generalAssessmentAndPlanModuleDotOne,
};

export const regularNpVisit = {
  [TypeOfEncounter.NP_VISIT]: {
    ...chfDotOne,
    [ConditionProgram.Hypertension]: { ...SHARED_PROGRAM_MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...SHARED_PROGRAM_MODULES },
    [ConditionProgram.T2DAndHTN]: { ...SHARED_PROGRAM_MODULES },
  },
};
