import { FormattedMessage } from 'react-intl';

import { TimeTrackedTaskType } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

export const TASK_OPTIONS = [
  {
    label: <FormattedMessage defaultMessage="Chart prep / documentation" />,
    value: TimeTrackedTaskType.ChartPrepOrDocumentation,
  },
  {
    label: <FormattedMessage defaultMessage="Lab / med order" />,
    value: TimeTrackedTaskType.LabOrMedOrder,
  },
  {
    label: (
      <FormattedMessage defaultMessage="Internal / provider communication" />
    ),
    value: TimeTrackedTaskType.InternalOrProviderCommunication,
  },
  {
    label: <FormattedMessage defaultMessage="Patient voicemail" />,
    value: TimeTrackedTaskType.PatientVoicemail,
  },
  {
    label: <FormattedMessage defaultMessage="Patient SMS" />,
    value: TimeTrackedTaskType.PatientSMS,
  },
  {
    label: <FormattedMessage defaultMessage="Other" />,
    value: TimeTrackedTaskType.Other,
  },
];
