import type { MenuTriggerState } from '@react-stately/menu';
import type { TreeState } from '@react-stately/tree';
import type { FocusStrategy } from '@react-types/shared';
import type { HTMLAttributes, MutableRefObject } from 'react';
import { createContext, useContext } from 'react';

interface MenuContextValue extends HTMLAttributes<HTMLElement> {
  onClose?: () => void;
  closeOnSelect?: boolean;
  shouldFocusWrap?: boolean;
  autoFocus?: boolean | FocusStrategy;
  ref?: MutableRefObject<HTMLUListElement>;
  state?: MenuTriggerState;
}

export const MenuContext = createContext<MenuContextValue>({});

export function useMenuContext(): MenuContextValue {
  return useContext(MenuContext);
}

interface MenuDialogContextValue {
  isUnavailable?: boolean;
  triggerRef?: MutableRefObject<HTMLLIElement>;
}

export const MenuDialogContext = createContext<
  MenuDialogContextValue | undefined
>(undefined);

export function useMenuDialogContext(): MenuDialogContextValue | undefined {
  return useContext(MenuDialogContext);
}

interface MenuStateContextValue<T> {
  state?: TreeState<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MenuStateContext = createContext<MenuStateContextValue<any>>({});

export function useMenuStateContext<T>(): MenuStateContextValue<T> {
  return useContext(MenuStateContext);
}
