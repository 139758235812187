import concat from 'lodash/concat';
import times from 'lodash/times';
import type { IntlShape } from 'react-intl';
import * as yup from 'yup';
import type { TestContext } from 'yup';
import type { InternalOptions } from 'yup/lib/types';

import type { FormConfig } from '@/shared/common/Form';
import type { FieldsMap } from '@/shared/common/Form/form.types';
import { validators } from '@/shared/common/Form/validations';
import type {
  CCMCarePlan,
  MedicationAllergy,
  Specialist,
  SurgicalHistory,
} from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { CCMPrognosis } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import {
  type CcmCondition,
  Condition,
} from '@/shared/types/clinicalprofile.types';

import { CCM_CONDITION_RESOURCES } from './conditions';
import { PREVENTATIVE_CARE_OPTIONS } from './sections/preventativeCareOptions';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './sections/psychoSocialAssessmentOptions';

export type FormFields = {
  tobaccoUse?: string;
  fastFood?: string;
  snacks?: string;
  desserts?: string;
  regularSoda?: string;
  sweetTea?: string;
  waterIntake?: string;
  saltIntake?: string;
  exercise?: string;
  ccmAppointment?: string;
  providerAppointment?: string;
  medsNotReviewed?: boolean;
  medicationAllergies?: MedicationAllergy[];
  surgicalHistories?: SurgicalHistory[];
  specialists?: Specialist[];
  emergencyVisits?: EmergencyVisit[];
  goalsAndInterventions?: GoalsAndInterventions[];
} & PreventativeCareFields &
  AssessmentFields;

type EmergencyVisit = {
  hospital?: string;
  // Must use empty string for empty date or picker will default to today
  date?: Date | '';
  relatedDiagnosis?: string;
};

type GoalsAndInterventions = {
  condition?: CcmCondition;
  prognosis?: CCMPrognosis;
  treatmentGoal?: string;
  healthGoal?: string;
  actionSteps?: string;
  confidence?: number;
  coordination?: string;
};

export const EMPTY_GOALS_SECTION: GoalsAndInterventions = {
  condition: undefined,
  prognosis: undefined,
  treatmentGoal: '',
  healthGoal: '',
  actionSteps: '',
  confidence: undefined,
  coordination: '',
};

export const EMPTY_ALLERGIES_SECTION: MedicationAllergy = {
  medication: '',
  reaction: '',
};

export const EMPTY_EMERGENCY_VISIT_SECTION: EmergencyVisit = {
  date: '',
  hospital: '',
  relatedDiagnosis: '',
};

export const EMPTY_PAST_SURGICAL_HISTORY_SECTION: SurgicalHistory = {
  details: '',
  diagnosis: '',
};

export const EMPTY_SPECIALISTS_SECTION: Specialist = {
  name: '',
  specialty: '',
  lastVisit: '',
  nextVisit: '',
};

type ExtractNames<T extends readonly { name: string }[]> = T[number]['name'];

export type PreventativeCareName = ExtractNames<
  typeof PREVENTATIVE_CARE_OPTIONS
>;
export type PreventativeCareFields = {
  [key in `preventativeCare-${PreventativeCareName}-checked`]?: boolean;
} & {
  [key in `preventativeCare-${PreventativeCareName}-date`]?: Date | null;
} & {
  [key in `preventativeCare-${PreventativeCareName}-nextSteps`]?: string | null;
} & {
  [key in `preventativeCare-${PreventativeCareName}-notApplicable`]?: boolean;
};

export type AssessmentName = ExtractNames<
  typeof PSYCHOSOCIAL_ASSESSMENT_OPTIONS
>;
export type AssessmentFields = {
  [key in `assessment-${AssessmentName}-checked`]?: boolean;
} & {
  [key in `assessment-${AssessmentName}-description`]?: string;
};

export function getFormConfig(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
  ccmConditions: Nullable<CcmCondition[]>,
): FormConfig {
  const { required, date, number, enumType } = validators(intl);

  return {
    fields: {
      tobaccoUse: {
        defaultValue: fieldValues.tobaccoUse ?? '',
        validation: required(yup.string()),
      },
      fastFood: {
        defaultValue: fieldValues.fastFood ?? '',
        validation: required(yup.string()),
      },
      snacks: {
        defaultValue: fieldValues.snacks ?? '',
        validation: required(yup.string()),
      },
      desserts: {
        defaultValue: fieldValues.desserts ?? '',
        validation: required(yup.string()),
      },
      regularSoda: {
        defaultValue: fieldValues.regularSoda ?? '',
        validation: required(yup.string()),
      },
      sweetTea: {
        defaultValue: fieldValues.sweetTea ?? '',
        validation: required(yup.string()),
      },
      waterIntake: {
        defaultValue: fieldValues.waterIntake ?? '',
        validation: required(yup.string()),
      },
      saltIntake: {
        defaultValue: fieldValues.saltIntake ?? '',
        validation: required(yup.string()),
      },
      exercise: {
        defaultValue: fieldValues.exercise ?? '',
        validation: required(yup.string()),
      },
      ccmAppointment: {
        defaultValue: fieldValues.ccmAppointment ?? '',
        validation: required(yup.string()),
      },
      providerAppointment: {
        defaultValue: fieldValues.providerAppointment ?? '',
        validation: required(yup.string()),
      },
      surgicalHistories: {
        defaultValue: fieldValues.surgicalHistories ?? [
          { ...EMPTY_PAST_SURGICAL_HISTORY_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            details: yup.string(),
            diagnosis: yup.string(),
          }),
        ),
      },
      specialists: {
        defaultValue: fieldValues.specialists ?? [
          { ...EMPTY_SPECIALISTS_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            name: yup.string(),
            specialty: yup.string(),
            lastVisit: yup.string(),
            nextVisit: yup.string(),
          }),
        ),
      },
      medsNotReviewed: {
        defaultValue: fieldValues.medsNotReviewed ?? false,
        validation: yup.boolean(),
      },
      medicationAllergies: {
        defaultValue: fieldValues.medicationAllergies ?? [
          { ...EMPTY_ALLERGIES_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            medication: required(yup.string()),
            reaction: required(yup.string()),
          }),
        ),
      },
      emergencyVisits: {
        defaultValue: fieldValues.emergencyVisits ?? [
          { ...EMPTY_EMERGENCY_VISIT_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            hospital: required(yup.string()),
            date: required(date()),
            relatedDiagnosis: required(yup.string()),
          }),
        ),
      },
      goalsAndInterventions: {
        defaultValue: fieldValues.goalsAndInterventions?.length
          ? fieldValues.goalsAndInterventions
          : getDefaultGoalsAndInterventions(ccmConditions),
        validation: yup
          .array()
          .of(
            yup.object().shape({
              condition: required(
                enumType({ source: Condition, pluck: 'values' }),
              ).test(
                'unique-condition',
                intl.formatMessage({
                  defaultMessage: 'Condition must be unique',
                }),
                (value: string | undefined, context: TestContext) => {
                  // https://github.com/jquense/yup/issues/398#issuecomment-916693907
                  const options = context.options as InternalOptions;
                  const { from: parents } = options;
                  const allConditions = (
                    parents?.[1].value as CCMCarePlan['carePlan']
                  )?.goalsAndInterventions
                    ?.map((item) => item.condition)
                    .filter(Boolean);

                  const conditionOccurrences =
                    allConditions?.filter((cond) => value && cond === value)
                      .length || 0;

                  return conditionOccurrences <= 1;
                },
              ),
              prognosis: required(
                enumType({ source: CCMPrognosis, pluck: 'values' }),
              ),
              treatmentGoal: required(yup.string()),
              healthGoal: required(yup.string()),
              actionSteps: required(yup.string()),
              confidence: number({ min: 1, max: 10 }).nullable(),
              coordination: required(yup.string()),
            }),
          )
          .min(
            2,
            intl.formatMessage({
              defaultMessage: 'At least two conditions are required',
            }),
          ),
      },
      ...getPreventativeCareFields(intl, fieldValues),
      ...getAssessmentFields(intl, fieldValues),
    },
  };
}

export function getPreventativeCareFieldNames(baseName: PreventativeCareName) {
  const baseFieldName = `preventativeCare-${baseName}`;

  return {
    checked: `${baseFieldName}-checked` as keyof PreventativeCareFields,
    date: `${baseFieldName}-date` as keyof PreventativeCareFields,
    nextSteps: `${baseFieldName}-nextSteps` as keyof PreventativeCareFields,
    notApplicable:
      `${baseFieldName}-notApplicable` as keyof PreventativeCareFields,
  };
}

function getPreventativeCareFields(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
): FieldsMap {
  const { required } = validators(intl);

  return PREVENTATIVE_CARE_OPTIONS.reduce((fields, { name }) => {
    const {
      checked: checkedFieldName,
      date: dateFieldName,
      nextSteps: nextStepsFieldName,
      notApplicable: notApplicableFieldName,
    } = getPreventativeCareFieldNames(name);

    return {
      ...fields,
      [checkedFieldName]: {
        defaultValue: fieldValues[checkedFieldName] ?? false,
      },
      [dateFieldName]: {
        defaultValue: fieldValues[dateFieldName] ?? '',
        validation: yup.string().when(checkedFieldName, {
          is: true,
          then: (schema) => required(schema),
        }),
      },
      [nextStepsFieldName]: {
        defaultValue: fieldValues[nextStepsFieldName] ?? '',
      },
      [notApplicableFieldName]: {
        defaultValue: fieldValues[notApplicableFieldName] ?? false,
      },
    };
  }, {});
}

export function getAssessmentFieldNames(baseName: AssessmentName) {
  const baseFieldName = `assessment-${baseName}`;

  return {
    checked: `${baseFieldName}-checked` as keyof AssessmentFields,
    description: `${baseFieldName}-description` as keyof AssessmentFields,
  };
}

function getAssessmentFields(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
): FieldsMap {
  const { required } = validators(intl);

  return PSYCHOSOCIAL_ASSESSMENT_OPTIONS.reduce((fields, { name }) => {
    const { checked: checkedFieldName, description: descriptionFieldName } =
      getAssessmentFieldNames(name);

    return {
      ...fields,
      [checkedFieldName]: {
        defaultValue: fieldValues[checkedFieldName] ?? false,
      },
      [descriptionFieldName]: {
        defaultValue: fieldValues[descriptionFieldName] ?? '',
        validation: yup.string().when(checkedFieldName, {
          is: true,
          then: (schema) => required(schema),
        }),
      },
    };
  }, {});
}

export function getDefaultGoalsAndInterventions(
  ccmConditions: Nullable<CcmCondition[]>,
): GoalsAndInterventions[] {
  const goalsFromConditions =
    ccmConditions?.map((condition) => ({
      ...EMPTY_GOALS_SECTION,
      condition,
      treatmentGoal: condition ? CCM_CONDITION_RESOURCES[condition].goal : '',
    })) || [];

  return concat(
    goalsFromConditions,
    times(
      Math.max(0, 2 - (ccmConditions?.length || 0)),
      () => EMPTY_GOALS_SECTION,
    ),
  );
}
