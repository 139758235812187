import cx from 'classnames';
import type { ReactNode } from 'react';

import { Table } from '@/shared/common/Table';

import { readingCell, table } from './PatientActivity.css';

type Props = {
  reading: ReactNode;
  goal?: ReactNode;
  atGoal?: boolean;
};

export function VitalCell({ reading, goal, atGoal }: Props) {
  return (
    <Table.NodeCell align="right" className={table.cell}>
      <p
        className={cx(readingCell.text, {
          [readingCell.overGoal]: atGoal === false,
          [readingCell.atGoal]: atGoal === true,
        })}
      >
        {reading}
      </p>
      {goal && <p className={cx(readingCell.text, readingCell.goal)}>{goal}</p>}
    </Table.NodeCell>
  );
}
