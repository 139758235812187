import type { AriaToastRegionProps } from '@react-aria/toast';
import { useToastRegion } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';

import { StatefulToast } from './StatefulToast';
import { region } from './ToastRegion.css';
import { ToastTransition } from './ToastTransition';
import type { ToastContents } from './types';

interface Props extends AriaToastRegionProps {
  state: ToastState<ToastContents>;
}

export function ToastRegion({ state, ...props }: Props) {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div className={region} {...regionProps} ref={ref}>
      <TransitionGroup component={null}>
        {state.visibleToasts.map((toast) => (
          <ToastTransition key={toast.key}>
            {/* StatefulToast also needs key passed in */}
            <StatefulToast key={toast.key} state={state} toast={toast} />
          </ToastTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}
