import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PatientSearchTypeahead } from '@/shared/common/@deprecated/PatientSearch';
import { useSidePanelCtx } from '@/shared/common/Page';

import { useFiltersQuery } from './hooks/useFiltersQuery.hook';

type Props = {
  onBlur?: () => void;
  onClose: () => void;
  autoFocus?: boolean;
};

export function PatientListSearchBar({ onBlur, onClose, autoFocus }: Props) {
  const history = useHistory();

  const { searchFilter, setSearchFilter } = useFiltersQuery();
  const { state: sidePanelState, handlers: sidePanel } = useSidePanelCtx();

  const handleSelectOption = (id: string) => {
    history.push(`/patients/${id}`);
  };

  const handleSearch = (label: string) => {
    if (sidePanelState.isVisible) {
      sidePanel.setIsVisible(false);
    }

    setSearchFilter(label);
  };

  // Getting default search value only on mount, to not change it later
  const searchBarDefaultValue = useMemo(
    () => searchFilter || undefined,
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <PatientSearchTypeahead
      initialValue={searchBarDefaultValue}
      autoFocus={autoFocus}
      onBlur={onBlur}
      onClose={onClose}
      onSearch={(term) => {
        if (term) {
          handleSearch(term);
        }
      }}
      onSelect={handleSelectOption}
    />
  );
}
