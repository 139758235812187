export enum PatientGoal {
  BecomePhysicallyActive = 'become_more_physically_active',
  TakeMedicationsConsistently = 'take_medications_more_consistently',
  LowerMyWeight = 'lower_my_weight',
  ImproveMyFoodChoices = 'improve_my_food_choices',
  ReduceMyStress = 'reduce_my_stress',
  BetterUnderstandMyDisease = 'better_understand_my_disease',
  GetBetterSleep = 'get_better_sleep',
  QuitSmoking = 'quit_smoking',
}
