/* eslint-disable */
// @generated by protobuf-ts 2.9.1 with parameter long_type_string,add_pb_suffix,eslint_disable
// @generated from protobuf file "go/pms/pkg/patient/pms.proto" (package "go.pms.pkg.patient", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PatientService } from "./pms_pb";
import type { PatientChangeNotificationResponse } from "./pms_pb";
import type { PatientChangeNotificationsRequest } from "./pms_pb";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";

import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service go.pms.pkg.patient.PatientService
 */
export interface IPatientServiceClient {
    /**
     * @generated from protobuf rpc: PatientChangeNotifications(go.pms.pkg.patient.PatientChangeNotificationsRequest) returns (stream go.pms.pkg.patient.PatientChangeNotificationResponse);
     */
    patientChangeNotifications(input: PatientChangeNotificationsRequest, options?: RpcOptions): ServerStreamingCall<PatientChangeNotificationsRequest, PatientChangeNotificationResponse>;
}
/**
 * @generated from protobuf service go.pms.pkg.patient.PatientService
 */
export class PatientServiceClient implements IPatientServiceClient, ServiceInfo {
    typeName = PatientService.typeName;
    methods = PatientService.methods;
    options = PatientService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: PatientChangeNotifications(go.pms.pkg.patient.PatientChangeNotificationsRequest) returns (stream go.pms.pkg.patient.PatientChangeNotificationResponse);
     */
    patientChangeNotifications(input: PatientChangeNotificationsRequest, options?: RpcOptions): ServerStreamingCall<PatientChangeNotificationsRequest, PatientChangeNotificationResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<PatientChangeNotificationsRequest, PatientChangeNotificationResponse>("serverStreaming", this._transport, method, opt, input);
    }
}
