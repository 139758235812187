import type { ReactElement, ReactText } from 'react';
import { FormattedMessage } from 'react-intl';

import { YesNoRadioGroup } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';

import type { DailyLivingFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<DailyLivingFormFields>;
  requiresAssistanceLabel: ReactElement | ReactText;
  classes?: {
    requiresAssistanceLabel?: string;
  };
};

export function DailyLivingForm({
  classes,
  form,
  requiresAssistanceLabel,
}: Props) {
  const requiresAssistance = form.watch('requiresAssistance');

  return (
    <>
      <YesNoRadioGroup
        name="requiresAssistance"
        classes={{ label: classes?.requiresAssistanceLabel }}
        label={requiresAssistanceLabel}
      />
      {requiresAssistance === 'true' && (
        <Form.TextArea
          size={12}
          rows={3}
          name="requiresAssistanceDescription"
          label={
            <FormattedMessage defaultMessage="What do they need help with?" />
          }
          required
        />
      )}
    </>
  );
}
