import cx from 'classnames';
import type { ReactNode } from 'react';

import CheckIcon from '@/shared/assets/svgs/smallCheck.svg?react';
import { Button } from '@/shared/tempo/atom/Button';

import { SuggestedTitrationMode } from '../types';
import { acceptButton, acceptIconSizeFix } from './actions.css';

type Props = {
  mode: SuggestedTitrationMode;
  isDisabled?: boolean;
  onAccept: () => void;
  children: ReactNode;
};

export function AcceptButton({
  mode,
  isDisabled = false,
  onAccept,
  children,
}: Props) {
  return (
    <Button
      variant="secondary"
      className={
        mode === SuggestedTitrationMode.Accepted
          ? acceptButton.button.selected
          : acceptButton.button.default
      }
      onPress={onAccept}
      isDisabled={isDisabled}
    >
      <Button.Icon className={acceptButton.iconWrapper}>
        <CheckIcon className={cx(acceptButton.icon, acceptIconSizeFix)} />
      </Button.Icon>
      {children}
    </Button>
  );
}
