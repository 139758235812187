import { useIntl } from 'react-intl';

import type { SortOption } from './types';

export function useAllPatientsSortOptions(): SortOption[] {
  const intl = useIntl();
  return [
    {
      key: 'mostRecent',
      label: intl.formatMessage({ defaultMessage: 'Most recent' }),
      orderBy: 'desc',
      sortBy: 'reading_timestamp',
    },
    {
      key: 'leastRecent',
      label: intl.formatMessage({ defaultMessage: 'Least recent' }),
      orderBy: 'asc',
      sortBy: 'reading_timestamp',
    },
    {
      key: 'firstNameAsc',
      label: intl.formatMessage({
        defaultMessage: 'First name A-Z',
      }),
      orderBy: 'asc',
      sortBy: 'first_name',
    },
    {
      key: 'firstNameDesc',
      label: intl.formatMessage({
        defaultMessage: 'First name Z-A',
      }),
      orderBy: 'desc',
      sortBy: 'first_name',
    },
    {
      key: 'lastNameAsc',
      label: intl.formatMessage({
        defaultMessage: 'Last name A-Z',
      }),
      orderBy: 'asc',
      sortBy: 'last_name',
    },
    {
      key: 'lastNameDesc',
      label: intl.formatMessage({
        defaultMessage: 'Last name Z-A',
      }),
      orderBy: 'desc',
      sortBy: 'last_name',
    },
    {
      key: 'bpAsc',
      label: intl.formatMessage({
        defaultMessage: 'Lowest blood pressure',
      }),
      orderBy: 'asc',
      sortBy: 'BLOOD_PRESSURE',
    },
    {
      key: 'bpDesc',
      label: intl.formatMessage({
        defaultMessage: 'Highest blood pressure',
      }),
      orderBy: 'desc',
      sortBy: 'BLOOD_PRESSURE',
    },
    {
      key: 'hrAsc',
      label: intl.formatMessage({
        defaultMessage: 'Lowest heart rate',
      }),
      orderBy: 'asc',
      sortBy: 'HEART_RATE',
    },
    {
      key: 'hrDesc',
      label: intl.formatMessage({
        defaultMessage: 'Highest heart rate',
      }),
      orderBy: 'desc',
      sortBy: 'HEART_RATE',
    },
    {
      key: 'weightAsc',
      label: intl.formatMessage({
        defaultMessage: 'Lowest weight',
      }),
      orderBy: 'asc',
      sortBy: 'WEIGHT',
    },
    {
      key: 'weightDesc',
      label: intl.formatMessage({
        defaultMessage: 'Highest weight',
      }),
      orderBy: 'desc',
      sortBy: 'WEIGHT',
    },
    {
      key: 'bgAsc',
      label: intl.formatMessage({
        defaultMessage: 'Lowest blood glucose',
      }),
      orderBy: 'asc',
      sortBy: 'BLOOD_GLUCOSE',
    },
    {
      key: 'bgDesc',
      label: intl.formatMessage({
        defaultMessage: 'Highest blood glucose',
      }),
      orderBy: 'desc',
      sortBy: 'BLOOD_GLUCOSE',
    },
  ];
}

export function usePriorityPatientsSortOptions(): SortOption[] {
  const intl = useIntl();
  return [
    {
      key: 'highestPriority',
      label: intl.formatMessage({
        defaultMessage: 'Highest priority',
      }),
      orderBy: undefined,
      sortBy: undefined,
    },
    {
      key: 'mostRecent',
      label: intl.formatMessage({ defaultMessage: 'Most recent' }),
      orderBy: 'desc',
      sortBy: 'most_recent_active_alert_created_at',
    },
    {
      key: 'leastRecent',
      label: intl.formatMessage({ defaultMessage: 'Least recent' }),
      orderBy: 'asc',
      sortBy: 'oldest_active_alert_created_at',
    },
  ];
}
