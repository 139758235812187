import STATES from '@/shared/assets/states.json';
import type { PatientDetails as PbPatientDetails } from '@/shared/generated/grpcGateway/pms.pb';
import type { Patient } from '@/shared/types/patient.types';
import type { StateInfo } from '@/shared/types/states';
import { CADENCE_TIMEZONES } from '@/shared/utils/time-helpers';

export function getPatientStateInfo(patient: Patient): Maybe<StateInfo> {
  const patientState = patient.addresses?.find(({ primary }) => primary)
    ?.address?.region;
  return STATES.find((s) => s.abbreviation === patientState);
}

export function getPbPatientDetailsStateInfo(
  patientDetails: PbPatientDetails,
): Maybe<StateInfo> {
  const patientState = patientDetails.addresses?.find(({ primary }) => primary)
    ?.region;
  return STATES.find((s) => s.abbreviation === patientState);
}

export function getPatientTimezoneInfo(patient: Patient) {
  return CADENCE_TIMEZONES.find(
    ({ timezone }) => timezone === patient?.timezone,
  );
}
