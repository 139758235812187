import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { NoteRow } from '@/pages/patients/patientDetails/ui/Notes/NoteRow';
import { Modal } from '@/shared/common/Modal';
import { Button } from '@/shared/tempo/atom/Button';
import type { Note } from '@/shared/types/note.types';

type Props = {
  note: Note;
  onClose: () => void;
  title?: ReactNode;
};

const noop = () => {};

export function RecentNoteModal({ note, onClose, title }: Props) {
  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={title ?? <FormattedMessage defaultMessage="Last NP Note" />}
      />
      <Modal.Body>
        <NoteRow
          note={note}
          enableRepublish={false}
          allowTruncate={false}
          onRepublishToEhrClick={noop}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
