export enum CareProviderFilter {
  AllPatients = 'all',
  MyPatients = 'me',
}

export enum LocationFilter {
  AllLocations = 'all',
  default = AllLocations,
}

export enum ConditionFilter {
  AllConditions = 'all',
  CHF = 'CHF',
  Hypertension = 'Hypertension',
  T2D = 'Type 2 Diabetes',
  default = AllConditions,
}
