import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Provider } from './NotesLoadingStateContext';
import type { LoadingId, LoadingState } from './types';

export function NotesLoadingStateContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [loadingStates, setLoadingStates] = useState<LoadingState[]>([]);
  const isLoading = loadingStates.some((ls) => ls.isLoading);

  /**
   * Subscribes to a loading state, adding it to the loadingStates,
   * and removing it on unmount.
   */
  function useWatchNoteLoadingState(state: LoadingState) {
    useEffect(() => {
      updateLoadingState(state);
      // Remove loading state on watching component unmount
      return () => {
        const clonedStates = cloneDeep(loadingStates);
        remove(clonedStates, (ls) => ls.id === state.id);
        setLoadingStates(clonedStates);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.id, state.isLoading]);
  }

  function updateLoadingState(state: LoadingState) {
    const clonedStates = cloneDeep(loadingStates);
    const { id } = state;
    const loadingStateIdx = loadingStates.findIndex((ls) => ls.id === id);

    if (loadingStateIdx === -1) {
      clonedStates.push(state);
    } else {
      clonedStates[loadingStateIdx] = state;
    }

    setLoadingStates(clonedStates);
  }

  function getLoadingState(id: LoadingId) {
    return loadingStates.find((ls) => ls.id === id);
  }

  return (
    <Provider
      value={{
        isLoading,
        getLoadingState,
        updateLoadingState,
        useWatchNoteLoadingState,
      }}
    >
      {children}
    </Provider>
  );
}
