import type { ReactNode } from 'react';
import { StrictMode, useEffect } from 'react';
import type { Store } from 'redux';

import { Providers } from './Providers';
import { instrumentApp } from './instrumentApp';

type Props = {
  children: ReactNode;
  reduxStore?: Store;
};

export function AppShell({ children, reduxStore }: Props) {
  useEffect(() => {
    instrumentApp();
  }, []);

  return (
    <StrictMode>
      <Providers reduxStore={reduxStore}>{children}</Providers>
    </StrictMode>
  );
}
