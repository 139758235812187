import type { Key } from 'react';
import { type DOMAttributes, useRef } from 'react';
import { useTab } from 'react-aria';
import type { Node, TabListState } from 'react-stately';

import { tab } from './Tabs.css';

type Props<T> = {
  item: Node<T>;
  state: TabListState<T>;
  onClick?: (key: Key) => void;
};

export function Tab<T extends object>({ item, state, onClick }: Props<T>) {
  const ref = useRef(null);
  const { key, rendered } = item;
  const { tabProps: ariaTabProps } = useTab({ key }, state, ref);
  const tabProps: DOMAttributes<HTMLDivElement> = {
    ...ariaTabProps,
    onClick: (e) => {
      if (onClick) {
        onClick(key);
      } else {
        ariaTabProps.onClick?.(e);
      }
    },
  };

  return (
    <div
      {...tabProps}
      ref={ref}
      className={state.selectedKey === key ? tab.active : tab.default}
    >
      {rendered}
    </div>
  );
}
