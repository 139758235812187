import type { ReactElement } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { CircularProgress } from '@/deprecated/mui';
import type { RootState } from '@/store/redux.types';

import { raiseError, requestLoginSSO } from '../../actionCreators';
import { landingPageSpinner } from './OAuthLandingPage.css';
import type { TOAuthLandingPage } from './TOAuthLandingPage';

const errorDescription =
  'error_description=Already+found+an+entry+for+username';

class OAuthLandingPage extends Component<TOAuthLandingPage> {
  componentDidMount(): void {
    const { dispatchRequestLoginSSO, dispatchRaiseError, history, location } =
      this.props;
    const queryString = window.location.search;
    const { from } = location.state || {
      from: { pathname: '/login' },
    };

    if (queryString.includes(errorDescription)) {
      dispatchRaiseError();
      history.replace(from.pathname);
    } else dispatchRequestLoginSSO(queryString);
  }

  componentDidUpdate(): void {
    const { isAuthenticated, nextUrl, history } = this.props;

    const nextUrlPath = nextUrl ? new URL(nextUrl).pathname : '/';

    if (isAuthenticated) {
      history.replace(nextUrlPath);
    }
  }

  render(): ReactElement {
    return (
      <div className={landingPageSpinner}>
        <CircularProgress />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  nextUrl: state.auth.token?.redirect_url,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchRequestLoginSSO: (queryString: string) => {
    dispatch(requestLoginSSO(queryString));
  },
  dispatchRaiseError: () => {
    dispatch(raiseError());
  },
});

// eslint-disable-next-line react-refresh/only-export-components, import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(OAuthLandingPage);
