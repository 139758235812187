import classnames from 'classnames/bind';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { InfoRow } from '@/pages/patients/patientDetails/ui/tabs/PatientInfo/InfoRow';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Snackbar } from '@/shared/common/Snackbar';
import {
  getFriendlyDeviceProviders,
  getFriendlyDeviceTypes,
} from '@/shared/common/devices/intl';
import { getDeviceAssignedDate } from '@/shared/common/devices/utils';

import { EmptySection } from '../EmptySection';
import { SectionHeader } from '../SectionHeader';
import { getAssociationsLink } from '../adminLinks';
import styles from './assignedDevices.module.scss';
import type { Device } from './useAssignedDevices';
import { useAssignedDevices } from './useAssignedDevices';

const cx = classnames.bind(styles);

type DeviceAssignmentRowProps = {
  device: Device;
  timezone: string;
};

function DeviceRow({ device, timezone }: DeviceAssignmentRowProps) {
  const intl = useIntl();
  const friendlyDeviceType = useMemo(
    () => getFriendlyDeviceTypes(intl),
    [intl],
  );
  const friendlyDeviceProvider = useMemo(
    () => getFriendlyDeviceProviders(intl),
    [intl],
  );

  // The creation date here is the assignment date,
  // not the device creation date.
  const assignedDate = getDeviceAssignedDate(device.createdAt, timezone, intl);

  return (
    <>
      <InfoRow
        left={friendlyDeviceType[device.deviceType]}
        right={friendlyDeviceProvider[device.provider]}
        rightSubdued
      />
      <InfoRow
        left={intl.formatMessage({
          defaultMessage: 'Assigned Date',
          description: 'The date the device was assigned to the patient',
        })}
        leftSubdued
        right={assignedDate}
      />
      <InfoRow
        left={intl.formatMessage({
          defaultMessage: 'IMEI',
          description:
            'Abbreviation for International Mobile Equipment Identity',
        })}
        leftSubdued
        right={device.imei}
      />
      <InfoRow
        left={intl.formatMessage({
          defaultMessage: 'SN',
          description: 'Abbreviation for serial number',
        })}
        leftSubdued
        right={device.serialNumber}
      />
    </>
  );
}

type AssignedDevicesProps = {
  patientId: string;
  timezone: string;
};

export function AssignedDevices({ patientId, timezone }: AssignedDevicesProps) {
  const intl = useIntl();
  const [isLoading, error, devices] = useAssignedDevices(patientId);
  const associationsLink = getAssociationsLink(patientId, 'devices');
  const hasDevices = devices?.length;

  return (
    <>
      {error && <Snackbar message={error} variant="error" />}
      <SectionHeader editLink={hasDevices ? associationsLink : undefined}>
        <FormattedMessage defaultMessage="Assigned Devices" />
      </SectionHeader>
      <LoadingPlaceholder isLoading={isLoading}>
        {hasDevices ? (
          devices.map((device) => (
            <div key={`device-${device.id}`} className={cx('device-row')}>
              <DeviceRow device={device} timezone={timezone} />
            </div>
          ))
        ) : (
          <EmptySection
            message={intl.formatMessage({
              defaultMessage: 'No assigned devices',
            })}
            action={intl.formatMessage({
              defaultMessage: 'Add device',
            })}
            href={associationsLink}
          />
        )}
      </LoadingPlaceholder>
    </>
  );
}
