import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PencilIcon from '@/shared/assets/svgs/pencil.svg?react';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import {
  useDeleteCcmGoal,
  useSaveCcmGoal,
} from '@/shared/hooks/queries/ccmGoals.queries';
import { flexContainer, flexJustify } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { display, removePadding } from '@/shared/jsStyle/utils.css';
import { Button } from '@/shared/tempo/atom/Button';
import { ButtonIcon } from '@/shared/tempo/atom/Button/ButtonIcon';
import { Checkbox } from '@/shared/tempo/atom/Checkbox';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { GoalForm } from '../GoalForm';
import { GoalStatusTag } from '../GoalStatusTag';
import { StatusSelect } from '../StatusSelect';
import {
  marshalFormDataToGoal,
  serializeGoalToFormData,
} from '../dataTransformations';
import { type EditMode, type FormFields, getFormConfig } from '../formConfig';
import type { Goal, GoalMeasure, GoalStatus } from '../goals.types';
import { useGoalGoalMeasureI18n } from '../i18nMapping';
import {
  deleteButton,
  description,
  detailsContainer,
  divider,
  editButton,
  measure,
  taskField,
  title,
} from './EditGoalModal.css';
import { GoalTimestampsInfo } from './GoalTimestampsInfo';

type Props = {
  goal: Goal;
  onClose: () => void;
};

export function EditGoalModal({ goal, onClose }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const [mode, setMode] = useState<EditMode>('core');
  const coreForm = useFormFromConfig<FormFields>(
    getFormConfig(intl, goal, 'core'),
  );
  const fullForm = useFormFromConfig<FormFields>({
    ...getFormConfig(
      intl,
      serializeGoalToFormData(coreForm.watch(), goal),
      'all',
    ),
    triggerReset: mode === 'all',
  });
  const form = mode === 'all' ? fullForm : coreForm;

  const { mutate: deleteGoal, isLoading: isDeletingGoal } = useDeleteCcmGoal();
  const { mutate: saveGoal, isLoading: isSavingGoal } = useSaveCcmGoal();
  const goalMeasureI18n = useGoalGoalMeasureI18n();

  return (
    <Modal open onClose={onClose}>
      <Modal.Header title={<FormattedMessage defaultMessage="Update goal" />} />
      <Modal.Body>
        {mode === 'all' ? (
          <GoalForm form={form} />
        ) : (
          <div className={cx(flexContainer.column, gap.M)}>
            <div className={detailsContainer}>
              <div className={measure}>
                {goalMeasureI18n[goal.measure as GoalMeasure]}
              </div>
              <GoalStatusTag status={goal.status as GoalStatus} />
            </div>
            <div>
              <div className={title}>{goal.title}</div>
              <div className={description}>{goal.description}</div>
            </div>
            <Form form={form}>
              <div className={cx(flexContainer.row, flexJustify.spaceBetween)}>
                <StatusSelect size={6} className={removePadding} />
                <GoalTimestampsInfo goal={goal} />
              </div>
              {!!goal.goalTasks?.length && (
                <>
                  <div className={divider} />
                  {goal.goalTasks?.map((t, idx) => (
                    <>
                      <Form.Field
                        size={12}
                        key={`${t.description}-${idx}`}
                        name={`goalTasks.${idx}.completed`}
                        className={taskField}
                      >
                        {({ controller: { field } }) => (
                          <Checkbox
                            onChange={(isSelected) =>
                              field.onChange(isSelected)
                            }
                            isSelected={field.value}
                          >
                            {t.description}
                          </Checkbox>
                        )}
                      </Form.Field>
                      {/* Make these hidden fields so we can preserve the form data even though it isn't editable */}
                      <Form.TextField
                        name={`goalTasks.${idx}.name`}
                        className={display.none}
                      />
                      <Form.TextField
                        name={`goalTasks.${idx}.description`}
                        className={display.none}
                      />
                    </>
                  ))}
                </>
              )}
            </Form>
          </div>
        )}
        {mode === 'core' && (
          <Button
            variant="tertiary"
            onPress={() => setMode('all')}
            className={editButton}
          >
            <ButtonIcon>
              <PencilIcon />
            </ButtonIcon>
            <FormattedMessage defaultMessage="Edit Goal" />
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer
        tertiary={
          <Button
            variant="tertiary"
            className={deleteButton}
            isProcessing={isDeletingGoal}
            onPress={() => {
              deleteGoal(goal.name as string, {
                onSuccess() {
                  toaster.success(
                    intl.formatMessage({
                      defaultMessage: 'Goal deleted successfully',
                    }),
                  );
                  onClose();
                },
                onError() {
                  toaster.success(
                    intl.formatMessage({
                      defaultMessage: 'Failed to delete goal',
                    }),
                  );
                },
              });
            }}
          >
            <FormattedMessage defaultMessage="Delete Goal" />
          </Button>
        }
      >
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          isProcessing={isSavingGoal}
          isDisabled={!form.canSubmit}
          onPress={() => {
            saveGoal(marshalFormDataToGoal(form.getValues(), goal), {
              onSuccess() {
                toaster.success(
                  intl.formatMessage({
                    defaultMessage: 'Goal saved successfully',
                  }),
                );
                onClose();
              },
              onError() {
                toaster.error(
                  intl.formatMessage({
                    defaultMessage: 'Failed to save goal',
                  }),
                );
              },
            });
          }}
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
