const ONE_MINUTE_IN_MILLISECONDS = 1000 * 60;

const TIME_SETTINGS = {
  ONE_MINUTE: ONE_MINUTE_IN_MILLISECONDS,
  FIVE_MINUTES: ONE_MINUTE_IN_MILLISECONDS * 5,
  TEN_MINUTES: ONE_MINUTE_IN_MILLISECONDS * 10,
};

export const STALE_TIME = { INSTANT: 0, ...TIME_SETTINGS } as const;
export const CACHE_TIME = { NONE: 0, ...TIME_SETTINGS } as const;
