import Session from '@/shared/utils/session';

import type { Goal } from './patientGoals.types';
import { sortGoals } from './patientGoals.utils';

export function bulkUpdateGoals(
  patientId: string,
  goals: Partial<Goal>[],
): Promise<Goal[]> {
  return Session.Api.put(`/pms/api/v1/patients/${patientId}/goals-collection`, {
    goals,
  }).then((response) => sortGoals(response.data.goals));
}

export function updateGoal(
  patientId: string,
  id: number,
  isCompleted: boolean,
  text: string,
): Promise<Goal> {
  return Session.Api.put(`/pms/api/v1/patients/${patientId}/goals/${id}`, {
    is_completed: isCompleted,
    text,
  }).then((response) => response.data);
}
