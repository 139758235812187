import type { RefObject } from 'react';

export type SidePanelContext = {
  handlers: {
    addWhitelistElement: (node: React.RefObject<HTMLDivElement>) => void;
  } & SidePanelStateHandlers;
  state: SidePanelState;
};

export type SidePanelState = {
  type: SidePanelType;
  isVisible: boolean;
  isFullscreen: boolean;
  isExpanded: boolean;
  whitelistElements: RefObject<HTMLDivElement>[];
};

type SidePanelStateHandlers = {
  setType: (val: SidePanelType) => void;
  setIsFullscreen: (val: boolean) => void;
  setIsVisible: (val: boolean) => void;
  setIsExpanded: (val: boolean) => void;
  addWhitelistElement: (ref: RefObject<HTMLDivElement>) => void;
};

export type SidePanelStateAndHandlers = {
  handlers: SidePanelStateHandlers;
  state: SidePanelState;
};

export enum SidePanelType {
  Normal = 'normal',
  Dockable = 'dockable',
}

export type SidePanelVariant =
  | { type: SidePanelType.Normal }
  | {
      type: SidePanelType.Dockable;
      dockTabTitle: string;
    };
