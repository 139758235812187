import { CHF_VISIT_SECTIONS } from '../../metadata';
import { SharedScheduling } from '../../shared/Scheduling';
import { chfVisitPath } from '../paths';

export function Scheduling() {
  return (
    <SharedScheduling
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/time-tracking', '/index')}
    />
  );
}
