import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { chf as chfOneDotOh } from '../../../1.0/initialNpVisit/chf';
import { generalAssessmentAndPlanModule } from './__shared__/assessmentAndPlanModule';
import { patientNotesModule } from './__shared__/patientNotesModule';

export const chf = chfOneDotOh;

export const SHARED_PROGRAM_MODULES = {
  ...patientNotesModule,
  ...generalAssessmentAndPlanModule,
};

export const initialNpVisit = {
  [TypeOfEncounter.INITIAL_NP_VISIT]: {
    ...chfOneDotOh,
    [ConditionProgram.Hypertension]: { ...SHARED_PROGRAM_MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...SHARED_PROGRAM_MODULES },
    [ConditionProgram.T2DAndHTN]: { ...SHARED_PROGRAM_MODULES },
  },
};
