import type { FieldValues } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptText } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { verificationsList } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { Button } from '@/shared/tempo/atom/Button';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';

type Props = {
  sections: CNSectionMap;
  nextPath: string;
};

export function SharedChartPrep({ sections, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { patientId, noteId } = useCNFormContext();

  return (
    <Wizard.Step
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Chart Prep' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        // Replace history entry so that we can't navigate back to chart prep step
        history.replace(nextPath);
      }}
      submitButton={({ submitWithTiming, form, onNext }) => (
        <Button
          size="small"
          type="submit"
          onPress={() => {
            const storeValues = submitWithTiming(true);
            if (form) {
              onNext(storeValues as FieldValues);
            } else {
              onNext();
            }
          }}
          isDisabled={!!form && !form.formState.isValid}
        >
          <FormattedMessage defaultMessage="Complete Chart Prep" />
        </Button>
      )}
    >
      <FieldGroup>
        <Script className={scriptText}>
          <FormattedMessage defaultMessage="Prepare for this patient's visit by:" />
        </Script>
        <ol className={verificationsList}>
          <li>
            <FormattedMessage defaultMessage="Structuring the disease-specific medications" />
          </li>
          <li>
            <FormattedMessage defaultMessage="If this is a new patient, review their devices in the patient info tab and verify that they are transmitting vitals." />
          </li>
        </ol>
        <PatientMedicationsList
          showRequiredActions={false}
          noteId={noteId}
          patientId={patientId}
          medPermissions={MedPermissions.Review}
          hideOtherMeds
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
