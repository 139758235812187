import type { RefObject } from 'react';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import type { ListState, MenuTriggerState, SelectState } from 'react-stately';

import { Skeleton } from '@/shared/tempo/atom/Skeleton';
import { TagGroup } from '@/shared/tempo/atom/TagGroup';

import { hiddenCount } from './SelectionTagView.css';
import { SELECT_ALL_KEY } from './constants';
import { useHideOverflowTags } from './useHideOverflowTags';

type SelectedItemsProps<T> = {
  ariaLabel?: string;
  isLoading?: boolean;
  disabledKeys?: Iterable<React.Key> | undefined;
  selectButtonRef: RefObject<HTMLButtonElement>;
  state: MenuTriggerState & SelectState<T> & ListState<T>;
};

export function SelectionTagView<T>({
  ariaLabel,
  state,
  isLoading,
  disabledKeys,
  selectButtonRef,
}: SelectedItemsProps<T>) {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  // Hide the tags that overflow outside the TagGroup container
  const { hiddenTagCount } = useHideOverflowTags(ref, selectButtonRef);

  return (
    <>
      <TagGroup
        ref={ref}
        aria-label={
          ariaLabel
            ? intl.formatMessage(
                {
                  defaultMessage: 'Selected values for {ariaLabel}',
                },
                { ariaLabel },
              )
            : undefined
        }
        onRemove={(keys) => {
          const newSelection = new Set([
            ...state.selectionManager.selectedKeys,
          ]);
          [...keys].forEach((key) => newSelection.delete(key));
          state.selectionManager.setSelectedKeys(newSelection);
        }}
      >
        {[...state.selectionManager.selectedKeys]
          .filter((key) => key !== SELECT_ALL_KEY)
          .map((key) => {
            const text = state.collection.getItem(key)?.rendered;
            return (
              <TagGroup.Tag
                key={key}
                // Need to spread custom props into TagGroup.Tag which is really just a
                // react-stately Item. Items support custom props, but the types
                // for static (vs. dynamic collections) aren't robust enough
                {...{
                  isDisabled:
                    [...(disabledKeys || [])].includes(key) || isLoading,
                }}
              >
                {isLoading ? <Skeleton variant="text" width={80} /> : text}
              </TagGroup.Tag>
            );
          })}
      </TagGroup>
      {hiddenTagCount > 0 && (
        <span className={hiddenCount}>+{hiddenTagCount}</span>
      )}
    </>
  );
}
