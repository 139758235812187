import type { ReactNode } from 'react';

import SmallXIcon from '@/shared/assets/svgs/smallX.svg?react';
import { Button } from '@/shared/tempo/atom/Button';

import { SuggestedTitrationMode } from '../types';
import { cancelButton } from './actions.css';

type Props = {
  isDisabled?: boolean;
  onReject: () => void;
  mode: SuggestedTitrationMode;
  children: ReactNode;
};

export function RejectButton({
  mode,
  isDisabled = false,
  onReject,
  children,
}: Props) {
  return (
    <Button
      variant="secondary"
      className={
        mode === SuggestedTitrationMode.NotTitrating
          ? cancelButton.button.selected
          : cancelButton.button.default
      }
      onPress={onReject}
      isDisabled={isDisabled}
    >
      <Button.Icon className={cancelButton.iconWrapper}>
        <SmallXIcon className={cancelButton.icon} />
      </Button.Icon>
      {children}
    </Button>
  );
}
