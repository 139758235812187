import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { UseInfiniteQueryResult } from 'react-query';

import { Checkbox } from '@/deprecated/mui';
import type { SelectChangeEvent } from '@/shared/common/Select';
import { Option, Select } from '@/shared/common/Select';
import { usePrevious } from '@/shared/hooks';
import { typography } from '@/shared/tempo/theme';

import {
  checkbox,
  checkboxOption,
  controlContainer,
  enrollmentLabelContainer,
  itemSelect,
  patientListLabelContainer,
} from './MultiselectDropdown.css';
import { SelectedItems } from './SelectedItems';
import { ALL_VALUE, getFilteredItemValues } from './getFilteredItemValues';

type Props = {
  className?: string;
  controlled?: boolean;
  filter: string[];
  setFilter: (filter: string[]) => void;
  query?: UseInfiniteQueryResult; // only not passed down for Conditions
  items: Array<{ value: string; label: string }>;
  allLabel: ReactNode;
  dropdownLabel?: ReactNode;
  maxLabelLength?: number;
  isEnrollment?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
  onClose?: (event: React.SyntheticEvent<Element, Event>) => void;
};

export function MultiselectDropdown({
  className,
  filter,
  setFilter,
  query,
  items,
  allLabel,
  dropdownLabel,
  maxLabelLength,
  isEnrollment,
  isDisabled,
  hasError,
  controlled = false,
  ...rest
}: Props) {
  const [filteredItemValues, setFilteredItemValues] = useState<string[]>(
    filter || [],
  );

  // When controlled, live update the filter value
  const prevFilter = usePrevious(filter);
  useEffect(() => {
    if (controlled && !isEqual(prevFilter, filter)) {
      setFilteredItemValues(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, controlled]);

  useEffect(() => {
    setFilter(filteredItemValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredItemValues]);

  const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const values = typeof value === 'string' ? value.split(',') : value;
    setFilteredItemValues(getFilteredItemValues(values));
  };

  const handleDeleteItem = (valueToDelete: string) => {
    setFilteredItemValues(
      filteredItemValues.filter((itemValue) => itemValue !== valueToDelete),
    );
  };

  // Handles style difference between PT & PET
  const styleTypography = isEnrollment
    ? typography.Body.Lead
    : typography.Body.Default;
  const styleLabel = isEnrollment
    ? enrollmentLabelContainer
    : patientListLabelContainer;

  return (
    <div className={controlContainer}>
      {dropdownLabel && <div className={styleLabel}>{dropdownLabel}</div>}
      <Select
        className={className}
        isDisabled={isDisabled}
        customStyle={styleTypography}
        classes={{
          select: cx({
            [itemSelect]:
              !query?.isLoading &&
              filteredItemValues.length > 0 &&
              filteredItemValues[0] !== ALL_VALUE,
          }),
        }}
        fullWidth
        hasError={hasError}
        onChange={handleSelectionChange}
        value={filteredItemValues.length > 0 ? filteredItemValues : [ALL_VALUE]}
        multiple
        renderValue={(selected) => {
          if (selected[0] === ALL_VALUE) {
            return allLabel;
          }
          if (query?.isLoading) {
            return <FormattedMessage defaultMessage="Loading..." />;
          }

          return (
            <SelectedItems
              isDisabled={isDisabled}
              items={items}
              selected={selected}
              onDelete={handleDeleteItem}
              maxLabelLength={maxLabelLength}
            />
          );
        }}
        {...rest}
      >
        {[
          <Option key="all" value={ALL_VALUE}>
            <Checkbox
              checked={
                filteredItemValues.length === 0 ||
                filteredItemValues.includes(ALL_VALUE)
              }
              classes={{ root: checkbox }}
            />
            {allLabel}
          </Option>,
          ...items.map(({ value, label }) => (
            <Option
              key={value}
              value={value}
              classes={{ root: checkboxOption }}
            >
              <Checkbox
                checked={filteredItemValues.includes(value)}
                classes={{ root: checkbox }}
              />
              {label}
            </Option>
          )),
        ]}
      </Select>
    </div>
  );
}
