import { addHours, differenceInHours, isValid, parseISO } from 'date-fns';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { logger } from '@/logger';
import {
  LobLetterLetterStatus as LetterStatus,
  type LobLetter,
} from '@/shared/generated/grpcGateway/patient_outreach.pb';
import type { TagVariant } from '@/shared/tempo/atom/Tag';

export function letterInFailedStatus(letter: Maybe<LobLetter>) {
  return letter?.letterStatus === LetterStatus.FAILED_TO_CREATE;
}

export function letterDelivered(
  statusMeta: Nullable<StatusMetadata>,
): statusMeta is 'delivered' | 'presumed_delivered' {
  return statusMeta === 'delivered' || statusMeta === 'presumed_delivered';
}

export function letterInProgress(
  statusMeta: Nullable<StatusMetadata>,
): statusMeta is Exclude<StatusMetadata, 'delivered' | 'presumed_delivered'> {
  return !!statusMeta && !letterDelivered(statusMeta);
}

export function getLetterDeliveredDate(letter: Maybe<LobLetter>) {
  const letterStatusMeta = getLetterStatusMetadata(letter);
  if (letter && letterDelivered(letterStatusMeta)) {
    const deliveredAt = letter.updateTime
      ? parseISO(letter.updateTime)
      : undefined;
    if (letterStatusMeta === 'presumed_delivered' && deliveredAt) {
      // We presume delivered after 48 hours of being in the PROCESSED_FOR_DELIVERY status
      // so add 48 hours to the delivery date√
      return isValid(deliveredAt) ? addHours(deliveredAt, 48) : undefined;
    }
    return isValid(deliveredAt) ? deliveredAt : undefined;
  }
  return undefined;
}

type StatusMetadata =
  | 'delivered'
  | 'presumed_delivered'
  | {
      label: ReactNode;
      variant: TagVariant;
      step: Nullable<IntRange<0, 3>>;
    };

export function getLetterStatusMetadata(
  letter: Maybe<LobLetter>,
): Nullable<StatusMetadata> {
  if (!letter?.letterStatus) {
    return null;
  }

  const lastUpdated = letter.updateTime ? parseISO(letter.updateTime) : null;

  switch (letter.letterStatus) {
    case LetterStatus.CREATED:
      return {
        variant: 'success',
        step: 0,
        label: <FormattedMessage defaultMessage="Created" />,
      };
    case LetterStatus.MAILED:
    case LetterStatus.IN_TRANSIT:
    case LetterStatus.IN_LOCAL_AREA:
      return {
        variant: 'success',
        step: 1,
        label: <FormattedMessage defaultMessage="At USPS" />,
      };
    case LetterStatus.PROCESSED_FOR_DELIVERY:
      if (
        lastUpdated &&
        isValid(lastUpdated) &&
        differenceInHours(lastUpdated, new Date()) >= 48
      ) {
        logger.debug(
          'Care plan is in PROCESSED_FOR_DELIVERY for over 48 hours and is presumed to have been delivered.',
        );
        return 'presumed_delivered';
      }

      return {
        variant: 'success',
        step: 2,
        label: <FormattedMessage defaultMessage="Out for Delivery" />,
      };
    case LetterStatus.DELIVERED:
      return 'delivered';
    case LetterStatus.RE_ROUTED:
      return {
        variant: 'warning',
        step: null,
        label: <FormattedMessage defaultMessage="Re-Routed" />,
      };
    case LetterStatus.RETURNED_TO_SENDER:
      return {
        variant: 'danger',
        step: null,
        label: <FormattedMessage defaultMessage="Returned to sender" />,
      };
    case LetterStatus.INTERNATIONAL_EXIT:
      return {
        variant: 'danger',
        step: null,
        label: <FormattedMessage defaultMessage="International exit" />,
      };
    default:
      logger.error(
        `Care plan letter in unexpected state: '${letter.letterStatus}'`,
      );
      return null;
  }
}
