import pick from 'lodash/pick';
import { type ReactNode, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import AlertIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import TrendDownIcon from '@/shared/assets/svgs/trend-down.svg?react';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { usePatientVitalAvgAndGoals } from '@/shared/hooks/queries/vitalsContext.queries';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';
import { Link } from '@/shared/tempo/atom/Link';
import {
  Condition,
  type RpmCondition,
} from '@/shared/types/clinicalprofile.types';

import {
  type ClinicalGoalReachedFormFields,
  type ClinicalGoalReachedInputs,
  EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
  GoalUnmetReason,
  HfType,
} from '../../Notes.types';
import { useGoalUnmetReasonsI18n } from '../../i18n';
import {
  alertTriangleIcon,
  goalDetailsInput,
  goalDetailsStyle,
  goalInfo,
  header,
  line,
  noGoalsSet,
  noGoalsSetText,
  roundedMultiselect,
  trendDownIcon,
  vitalDataMissing,
  vitalGoalText,
  vitalValueGoalMet,
  vitalValueGoalUnmet,
  vitalValueHeader,
  vitalsContainer,
} from './ClinicalGoalReachedForm.css';
import { getFormConfig } from './clinicalGoalReachedFormConfig';

type Props = {
  initialValues?: ClinicalGoalReachedInputs;
  onChange: (inputs: EncounterModuleInstance) => void;
  shouldShowValidation: boolean;
  conditions: RpmCondition[];
  encounterTypeInstance: EncounterModuleInstance<EncounterTypeInputs>;
};

export function ClinicalGoalReachedForm(props: Props) {
  const { isLoading: isVitalValuesAndGoalsLoading, vitalValuesAndGoals } =
    useVitalAvgAndGoals();
  if (isVitalValuesAndGoalsLoading || !vitalValuesAndGoals) {
    return null;
  }
  return (
    <ClinicalGoalReached {...props} vitalValuesAndGoals={vitalValuesAndGoals} />
  );
}

function ClinicalGoalReached({
  initialValues,
  onChange,
  shouldShowValidation,
  conditions,
  encounterTypeInstance,
  vitalValuesAndGoals,
}: Props & {
  vitalValuesAndGoals: Exclude<
    ReturnType<typeof useVitalAvgAndGoals>['vitalValuesAndGoals'],
    null
  >;
}) {
  const intl = useIntl();
  const isChf = conditions.includes(Condition.CHF);
  const isHtn = conditions.includes(Condition.Hypertension);
  const isT2d = conditions.includes(Condition.TypeTwoDiabetes);
  const isNoShow = encounterTypeInstance.inputs.patient_no_show;

  const goalValues = pick(
    vitalValuesAndGoals,
    'systolicGoal',
    'diastolicGoal',
    'a1cGoal',
    'bgGoal',
  );
  const formConfig = getFormConfig(
    intl,
    shouldShowValidation,
    isChf,
    isT2d,
    isHtn,
    goalValues,
    initialValues,
  );
  const form = useFormFromConfig<ClinicalGoalReachedFormFields>({
    ...formConfig,
    triggerReset: isNoShow,
  });
  const {
    hfType,
    isBpGoalMet,
    isBgGoalMet,
    isGdmtGoalMet,
    isBgBpGoalMet,
    bpGoalDetails,
    bgGoalDetails,
    bgBpGoalDetails,
    gdmtGoalDetails,
    reasonsGoalUnmet,
    otherReasonDetails,
  } = form.watch();

  useEffect(
    () => {
      // Trigger whole form validation when validation gets enabled and when new inputs are added
      if (shouldShowValidation) {
        form.trigger();
      }

      // Using setTimeout to avoid race condition causing
      // form validation to not work for new notes and untouched form fields
      setTimeout(() =>
        onChange({
          encounter_module_id: EncounterModuleId.ClinicalGoalReached,
          inputs: isNoShow
            ? {}
            : ({
                hfType,
                isBpGoalMet,
                bpGoalDetails,
                isBgGoalMet,
                bgGoalDetails,
                isGdmtGoalMet,
                gdmtGoalDetails,
                reasonsGoalUnmet,
                otherReasonDetails,
                isBgBpGoalMet,
                bgBpGoalDetails,
                ...vitalValuesAndGoals,
                ...(isChf ? { isChf } : {}),
                ...(isT2d ? { isT2d } : {}),
                ...(isHtn ? { isHtn } : {}),
              } satisfies ClinicalGoalReachedInputs),
        }),
      );
    },
    // Run only on form fields, isNoShow or shouldShowValidation change (e.g. not on onChange change)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hfType,
      isBpGoalMet,
      bpGoalDetails,
      isBgGoalMet,
      bgGoalDetails,
      isGdmtGoalMet,
      gdmtGoalDetails,
      reasonsGoalUnmet,
      otherReasonDetails,
      isBgBpGoalMet,
      bgBpGoalDetails,
      shouldShowValidation,
      isNoShow,
    ],
  );

  if (isNoShow) {
    return null;
  }

  if (!conditions.length) {
    return (
      <div>
        <FormattedMessage defaultMessage="The patient has no conditions set for which vital goals can be displayed." />
      </div>
    );
  }

  return (
    <>
      <div className={header}>
        <FormattedMessage defaultMessage="Clinical Goals" />
      </div>
      <Form form={form}>
        {isChf ? (
          <>
            <HfTypeRadios />
            {hfType === HfType.Hfpef ? (
              <Goal
                isGoalMetFieldName="isBpGoalMet"
                isGoalMetFieldValue={isBpGoalMet}
                goalDetailsFieldName="bpGoalDetails"
                description={intl.formatMessage({
                  defaultMessage:
                    'Goal 1 of 2: Is the patient at blood pressure goal?',
                })}
                areGoalsDefined={Boolean(
                  vitalValuesAndGoals.systolicGoal &&
                    vitalValuesAndGoals.diastolicGoal,
                )}
                vitalSummary={<BpSummary />}
              />
            ) : null}
            {hfType ? (
              <Goal
                isGoalMetFieldName="isGdmtGoalMet"
                goalDetailsFieldName="gdmtGoalDetails"
                isGoalMetFieldValue={isGdmtGoalMet}
                description={
                  hfType === HfType.Hfpef
                    ? intl.formatMessage({
                        defaultMessage:
                          'Goal 2 of 2: Has the patient reached max tolerated GDMT?',
                      })
                    : intl.formatMessage({
                        defaultMessage:
                          'Goal 1 of 1: Has the patient reached max tolerated GDMT?',
                      })
                }
              />
            ) : null}
            {((hfType === HfType.Hfpef && isBpGoalMet === false) ||
              isGdmtGoalMet === false) && <GoalUnmetReasons />}
          </>
        ) : null}
        {isHtn && !isT2d ? (
          <>
            <Goal
              isGoalMetFieldName="isBpGoalMet"
              goalDetailsFieldName="bpGoalDetails"
              isGoalMetFieldValue={isBpGoalMet}
              description={intl.formatMessage({
                defaultMessage: 'Goal 1 of 1: Is the patient at goal for HTN?',
              })}
              areGoalsDefined={Boolean(
                vitalValuesAndGoals.systolicGoal &&
                  vitalValuesAndGoals.diastolicGoal,
              )}
              vitalSummary={<BpSummary />}
            />
            {isBpGoalMet === false && <GoalUnmetReasons />}
          </>
        ) : null}
        {isT2d && !isHtn ? (
          <>
            <Goal
              isGoalMetFieldName="isBgGoalMet"
              goalDetailsFieldName="bgGoalDetails"
              isGoalMetFieldValue={isBgGoalMet}
              description={intl.formatMessage({
                defaultMessage: 'Goal 1 of 1: Is the patient at goal for T2D?',
              })}
              areGoalsDefined={Boolean(
                vitalValuesAndGoals.bgGoal && vitalValuesAndGoals.a1cGoal,
              )}
              vitalSummary={<BgA1cSummary />}
            />
            {isBgGoalMet === false && <GoalUnmetReasons />}
          </>
        ) : null}
        {isT2d && isHtn && (
          <>
            <Goal
              isGoalMetFieldName="isBgBpGoalMet"
              goalDetailsFieldName="bgBpGoalDetails"
              isGoalMetFieldValue={isBgBpGoalMet}
              description={intl.formatMessage({
                defaultMessage:
                  'Goal 1 of 1: Is the patient at goal for T2D+HTN?',
              })}
              areGoalsDefined={Boolean(
                vitalValuesAndGoals.bgGoal &&
                  vitalValuesAndGoals.a1cGoal &&
                  vitalValuesAndGoals.systolicGoal &&
                  vitalValuesAndGoals.diastolicGoal,
              )}
              vitalSummary={
                <>
                  <BpSummary />
                  <BgA1cSummary />
                </>
              }
            />
            {isBgBpGoalMet === false && <GoalUnmetReasons />}
          </>
        )}
      </Form>
    </>
  );
}

function HfTypeRadios() {
  return (
    <Form.RadioGroup
      size={12}
      name="hfType"
      label={
        <FormattedMessage defaultMessage="Does the patient have HFrEF (reduced) or HFpEF (preserved) *" />
      }
      orientation="horizontal"
    >
      <Form.Radio
        value={HfType.Hfref}
        classes={{ label: tiledRadioLabel.horizontal }}
      >
        <FormattedMessage defaultMessage="HFrEF (reduced)" />
      </Form.Radio>
      <Form.Radio
        value={HfType.Hfpef}
        classes={{ label: tiledRadioLabel.horizontal }}
      >
        <FormattedMessage defaultMessage="HFpEF (preserved)" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}

function Goal({
  isGoalMetFieldName,
  isGoalMetFieldValue,
  goalDetailsFieldName,
  description,
  vitalSummary,
  areGoalsDefined = true,
}: {
  isGoalMetFieldName: string;
  goalDetailsFieldName: string;
  isGoalMetFieldValue?: boolean;
  description: string;
  vitalSummary?: ReactNode;
  areGoalsDefined?: boolean;
}) {
  const intl = useIntl();

  const { healthSystemId } = useVitalAvgAndGoals();
  if (!healthSystemId) {
    return null;
  }
  if (!areGoalsDefined) {
    return (
      <div className={goalInfo}>
        <NoGoalsSetMessage healthSystemId={healthSystemId} />
      </div>
    );
  }

  const goalDetails =
    isGoalMetFieldValue !== undefined ? (
      <div className={goalDetailsStyle}>
        <Form.TextArea
          classes={{ input: goalDetailsInput }}
          maxLength={200}
          size={12}
          name={goalDetailsFieldName}
          label={<FormattedMessage defaultMessage="Goal details (optional)" />}
          placeholder={intl.formatMessage({
            defaultMessage: 'Enter optional details',
          })}
        />
      </div>
    ) : null;

  return (
    <div>
      <Form.RadioGroup
        size={12}
        name={isGoalMetFieldName}
        label={`${description} *`}
        orientation="horizontal"
        parseValue={(value: string) => ensureBoolean(value)}
        serializeValue={(value: unknown) => String(value)}
      >
        <Form.Radio
          value="true"
          classes={{ label: tiledRadioLabel.horizontal }}
        >
          <FormattedMessage defaultMessage="Yes" />
        </Form.Radio>
        <Form.Radio
          value="false"
          classes={{ label: tiledRadioLabel.horizontal }}
        >
          <FormattedMessage defaultMessage="No" />
        </Form.Radio>
      </Form.RadioGroup>
      {(vitalSummary || goalDetails) && (
        <div className={goalInfo}>
          {vitalSummary}
          {goalDetails && vitalSummary && <hr className={line} />}
          {goalDetails}
        </div>
      )}
    </div>
  );
}

function GoalUnmetReasons() {
  const goalUnmetReasonsI18n = useGoalUnmetReasonsI18n();
  const isOtherReason = Boolean(
    useFormContext().watch('reasonsGoalUnmet')?.includes(GoalUnmetReason.Other),
  );
  return (
    <>
      <Form.MultiSelect
        classes={{ root: roundedMultiselect }}
        size={12}
        items={Object.entries(goalUnmetReasonsI18n).map(([value, label]) => ({
          value,
          label,
        }))}
        name="reasonsGoalUnmet"
        label={
          <FormattedMessage defaultMessage="Reason why patient is not at clinical goal *" />
        }
        allOptionsLabel={
          <FormattedMessage defaultMessage="Select all that apply" />
        }
      />
      {isOtherReason && (
        <Form.TextArea
          size={12}
          name="otherReasonDetails"
          label={<FormattedMessage defaultMessage="Other reason details *" />}
        />
      )}
    </>
  );
}

function InsufficientDataMessage() {
  return (
    <FormattedMessage defaultMessage="Insufficient data to provide a 30-day average" />
  );
}

function BgA1cSummary() {
  const { isLoading, vitalValuesAndGoals } = useVitalAvgAndGoals();
  if (isLoading || !vitalValuesAndGoals) {
    return null;
  }

  const { bgGoal, a1cGoal, bgAvg30d, a1c } = vitalValuesAndGoals;
  const areGoalsSet = bgGoal && a1cGoal;
  const isDataMissing = !areGoalsSet || (!bgAvg30d && !a1c);
  const isGoalMet =
    !isDataMissing &&
    (!bgAvg30d || bgAvg30d < bgGoal) &&
    (!a1c || a1c < a1cGoal);

  let vitalValueTextCls = vitalDataMissing;
  if (!isDataMissing) {
    vitalValueTextCls = isGoalMet ? vitalValueGoalMet : vitalValueGoalUnmet;
  }

  return (
    <div>
      <div className={vitalsContainer}>
        <TrendDownIcon className={trendDownIcon} />
        <div>
          <div>
            <span className={vitalValueHeader}>
              <FormattedMessage defaultMessage="ACTUAL: " />
            </span>
            <span className={vitalValueTextCls}>
              {bgAvg30d || a1c ? (
                <FormattedMessage
                  defaultMessage="{bgAvg30d}mg/dl blood glucose; {a1c}% A1c (30-day avg)"
                  values={{
                    bgAvg30d: bgAvg30d || 'N/A ',
                    a1c: a1c || 'N/A ',
                  }}
                />
              ) : (
                <InsufficientDataMessage />
              )}
            </span>
          </div>
          <div className={vitalGoalText}>
            <FormattedMessage
              defaultMessage="GOAL: <{bgGoal}mg/dl blood glucose; or <{a1cGoal}% A1c (30-day avg)"
              values={{
                bgGoal: bgGoal || 'N/A ',
                a1cGoal: a1cGoal || 'N/A ',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function BpSummary() {
  const { isLoading, vitalValuesAndGoals } = useVitalAvgAndGoals();
  if (isLoading || !vitalValuesAndGoals) {
    return null;
  }

  const { systolicGoal, diastolicGoal, systolicAvg30d, diastolicAvg30d } =
    vitalValuesAndGoals;
  const areGoalsSet = systolicGoal && diastolicGoal;
  const isDataMissing = !systolicAvg30d || !diastolicAvg30d || !areGoalsSet;
  const isGoalMet =
    !isDataMissing &&
    systolicAvg30d < systolicGoal &&
    diastolicAvg30d < diastolicGoal;

  let vitalValueTextCls = vitalDataMissing;
  if (!isDataMissing) {
    vitalValueTextCls = isGoalMet ? vitalValueGoalMet : vitalValueGoalUnmet;
  }

  return (
    <div>
      <div className={vitalsContainer}>
        <TrendDownIcon className={trendDownIcon} />
        <div>
          <div>
            <span className={vitalValueHeader}>
              <FormattedMessage defaultMessage="ACTUAL: " />
            </span>
            <span className={vitalValueTextCls}>
              {systolicAvg30d && diastolicAvg30d ? (
                <FormattedMessage
                  defaultMessage="{systolicAvg30d}/{diastolicAvg30d} blood pressure (30-day avg)"
                  values={{ systolicAvg30d, diastolicAvg30d }}
                />
              ) : (
                <InsufficientDataMessage />
              )}
            </span>
          </div>
          <div className={vitalGoalText}>
            <FormattedMessage
              defaultMessage="GOAL: {systolicGoal}/{diastolicGoal} blood pressure (30-day avg)"
              values={{
                systolicGoal: systolicGoal || 'N/A ',
                diastolicGoal: diastolicGoal || ' N/A',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function NoGoalsSetMessage({ healthSystemId }: { healthSystemId: string }) {
  const intl = useIntl();
  return (
    <div className={noGoalsSet}>
      <AlertIcon className={alertTriangleIcon} />
      <div className={noGoalsSetText}>
        <FormattedMessage defaultMessage="Clinical goals have not been set for this health system. " />
        <Link.Routed
          to={`/admin/health-system/${healthSystemId}/provider-settings`}
        >
          {intl.formatMessage({ defaultMessage: 'Configure here' })}
        </Link.Routed>
      </div>
    </div>
  );
}

function useVitalAvgAndGoals() {
  const { patientId } = useParams<{ patientId: string }>();
  return usePatientVitalAvgAndGoals(patientId);
}

function ensureBoolean(boolString?: string | boolean) {
  if (boolString === 'true') {
    return true;
  }
  if (boolString === 'false') {
    return false;
  }
  return undefined;
}
