import type { Placement } from '@react-types/overlays';
import type { ReactElement } from 'react';
import { cloneElement, useRef } from 'react';
import type { AriaMenuProps } from 'react-aria';
import { useMenuTrigger } from 'react-aria';
import type { MenuTriggerProps } from 'react-stately';
import { useMenuTriggerState } from 'react-stately';

import { Popover } from '../Popover';

type RenderTrigger = (props: { isOpen: boolean }) => ReactElement;

interface Props<T>
  extends Omit<AriaMenuProps<T>, 'children'>,
    MenuTriggerProps {
  placement?: Placement;
  closeOnSelect?: boolean;
  children: ReactElement | RenderTrigger;
  menu: ReactElement;
  isDisabled?: boolean;
}

export function MenuTrigger<T extends object>({
  menu,
  children,
  placement = 'bottom start',
  isDisabled,
  ...props
}: Props<T>) {
  const state = useMenuTriggerState(props);
  const { isOpen } = state;

  const triggerRef = useRef(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<T>(
    { isDisabled },
    state,
    triggerRef,
  );

  return (
    <>
      {cloneElement(
        typeof children === 'function' ? children({ isOpen }) : children,
        {
          ref: triggerRef,
          ...menuTriggerProps,
          isMenuOpen: isOpen,
        },
      )}
      {isOpen && (
        <Popover
          state={state}
          triggerRef={triggerRef}
          placement={placement}
          offset={8}
        >
          {cloneElement(menu, { ...props, ...menuProps })}
        </Popover>
      )}
    </>
  );
}
