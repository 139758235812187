import {
  CONFIRM_USER,
  CONFIRM_USER_FAILURE,
  CONFIRM_USER_SUCCESS,
  FORCE_CHANGE_PASSWORD,
  FORCE_CHANGE_PASSWORD_FAILURE,
  FORCE_CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  LOGIN_REQUEST_SSO,
  LOGIN_REQUEST_SSO_FAILURE,
  LOGIN_REQUEST_SSO_SUCCESS,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  RAISE_ERROR,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  SIGN_UP_REQUEST_SSO,
  SIGN_UP_REQUEST_SSO_FAILURE,
  SIGN_UP_REQUEST_SSO_SUCCESS,
  UPDATE_EMAIL,
} from '../actionCreators';
import initialState from './initialState';

function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        email: action.email,
        isAuthenticated: false,
        token: {},
        error: null,
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: { ...action.payload },
        error: null,
      };
    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: action.payload,
      };
    case LOGIN_REQUEST_SSO:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: null,
      };
    case LOGIN_REQUEST_SSO_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: { ...action.payload },
        error: null,
      };
    case LOGIN_REQUEST_SSO_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: {
          ...state.token,
          id_token: action.payload.id_token,
          access_token: action.payload.access_token,
        },
        error: null,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        isEmailSent: false,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isEmailSent: true,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isEmailSent: false,
        error: action.payload,
      };
    case FORCE_CHANGE_PASSWORD:
      return {
        ...state,
        isNewPasswordCreated: false,
        error: null,
      };
    case FORCE_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isNewPasswordCreated: true,
        error: null,
      };
    case FORCE_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isNewPasswordCreated: false,
        error: action.payload,
      };
    case CONFIRM_USER:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: null,
      };
    case CONFIRM_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: { ...action.payload },
        error: null,
      };
    case CONFIRM_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: action.payload,
      };
    case SIGN_UP_REQUEST_SSO:
      return {
        ...state,
        isAuthenticated: false,
        email: action.email,
        token: {},
        error: null,
      };
    case SIGN_UP_REQUEST_SSO_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        data: { ...action.payload },
        headers: { ...action.headers },
        error: null,
      };
    case SIGN_UP_REQUEST_SSO_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        token: {},
        error: action.payload,
      };
    case UPDATE_EMAIL: {
      return {
        ...state,
        email: action.email,
      };
    }
    case RAISE_ERROR: {
      return {
        ...state,
        error: { message: 'Successfully setup SSO authentication.' },
      };
    }
    default:
      return state;
  }
}

export default authReducer;
