import Handlebars from 'handlebars';

// Reuse helpers from non-cn experience
import { TEMPLATE_HELPERS } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/engine';

import { CHF_VISIT_HELPERS } from '../files/chfVisit/helpers';
import { INIT_CN_VISIT_HELPERS } from '../files/initialCnVisit/helpers';
import { patientFollowUpNotes } from '../files/patientFollowUp/helpers';
import { REGULAR_CN_VISIT_HELPERS } from '../files/regularCnVisit/helpers';
import { TEMPLATE_PARTIALS } from './partials';

// Exporting an isolated (non-global) pre-configured instance of handlebars
export const engine = (() => {
  const instance = Handlebars.create();
  instance.registerHelper({
    ...TEMPLATE_HELPERS,
    ...INIT_CN_VISIT_HELPERS,
    ...REGULAR_CN_VISIT_HELPERS,
    ...CHF_VISIT_HELPERS,
    patientFollowUpNotes,
  });
  instance.registerPartial(TEMPLATE_PARTIALS);
  return instance;
})();
