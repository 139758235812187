import classnames from 'classnames/bind';
import type { FocusEventHandler, FormEvent, ReactNode } from 'react';
import { useEffect } from 'react';
import type {
  FieldValues,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';

import { logger } from '@/logger';
import { hidden } from '@/shared/jsStyle/utils.css';

import { displayContents } from './styles.css';
import styles from './styles.module.scss';

const cx = classnames.bind(styles);

type RenderProps = {
  canSubmit: boolean;
};
interface FormProps<TFieldValues extends FieldValues = FieldValues> {
  children: ReactNode | ((props: RenderProps) => React.ReactNode);
  onSubmit?: SubmitHandler<TFieldValues>;
  onBlur?: FocusEventHandler<HTMLFormElement>;
  form: UseFormReturn<TFieldValues>;
  className?: string;
}

export function Form<TFieldValues extends FieldValues = FieldValues>(
  props: FormProps<TFieldValues>,
) {
  const { children, onSubmit, form, className, ...rest } = props;
  const { handleSubmit, formState } = form;

  /*
  important: formState is wrapped with Proxy to improve render performance
  and skip extra logic if specific state is not subscribed, so make sure
  you deconstruct or read it before render in order to enable the subscription.
  https://react-hook-form.com/api/useformstate/

  do not refactor to `canSubmit = formState.isDirty && formState.isValid`
  or it will cause `canSubmit` to return incorrectly in some cases
  */
  const { isDirty, isValid } = formState;
  const canSubmit = isDirty && isValid;
  // now we return you to your regularly scheduled programming

  const noop = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const isNestedForm = Boolean(useFormContext());
  useEffect(() => {
    if (isNestedForm) {
      logger.warn(
        'Nested form was detected. Nesting forms is not supported and can have undesirable behavior',
      );
    }
  }, [isNestedForm]);

  return (
    <FormProvider {...form}>
      <form
        className={cx(['form-container', className, displayContents])}
        onSubmit={canSubmit && onSubmit ? handleSubmit(onSubmit) : noop}
        {...rest}
      >
        {typeof children === 'function' ? children({ canSubmit }) : children}
        {/* Hack to prevent page-refreshing (Implicit submission) when the form */}
        {/* is both nested and has only one field */}
        {/* https://github.com/orgs/react-hook-form/discussions/6745#discussioncomment-1444947 */}
        {isNestedForm && <input className={hidden} />}
      </form>
    </FormProvider>
  );
}
