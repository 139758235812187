import { useRef } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';
import { useOverlayTriggerState } from 'react-stately';

import CreateTaskIcon from '@/shared/assets/svgs/arrowUpRightCircled.svg?react';
import { MODAL_OPEN_ATTR } from '@/shared/common/Modal';
import { circular } from '@/shared/jsStyle/utils.css';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import {
  HotkeyTooltip,
  SpecialChar,
} from '@/shared/tempo/molecule/HotkeyTooltip';

import { CreateTaskOverlay } from './CreateTaskOverlay';

type Props = {
  patientId: string;
};

export function CreateTaskOverlayTrigger({ patientId }: Props) {
  const intl = useIntl();
  const ref = useRef(null);
  const state = useOverlayTriggerState({});
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    ref,
  );

  useHotkeys(['meta+.', 'meta+shift+.'], () => state.open(), {
    enabled: () => !document.querySelector(`[${MODAL_OPEN_ATTR}="true"]`),
  });

  return (
    <>
      <HotkeyTooltip
        title={intl.formatMessage({ defaultMessage: 'Create task' })}
        hotkey={[SpecialChar.Meta, '>']}
        classes={{ triggerContainer: circular }}
        disabled={state.isOpen}
      >
        <IconButton
          size="small"
          variant="secondary"
          {...triggerProps}
          ref={ref}
        >
          <CreateTaskIcon />
        </IconButton>
      </HotkeyTooltip>
      {state.isOpen && (
        <CreateTaskOverlay
          {...overlayProps}
          state={state}
          triggerRef={ref}
          patientId={patientId}
        />
      )}
    </>
  );
}
