import { useCallback, useRef, useState } from 'react';

import '../Notes.scss';

export function useTruncateNoteBody(allowTruncate: boolean) {
  const [isTruncated, setIsTruncated] = useState(false);
  const noteBodyRef = useRef<HTMLDivElement | null>(null);
  const canTruncate = useRef<boolean>(false);
  const setNoteBodyRef = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    noteBodyRef.current = node;
    const fullHeight = noteBodyRef.current.clientHeight / 20;

    if (fullHeight > 8) {
      canTruncate.current = true;
      setIsTruncated(true);
    }
  }, []);

  if (!allowTruncate) {
    return {
      isTruncated: false,
      setIsTruncated: () => {},
      setNoteBodyRef: () => {},
      noteBodyRef: null,
      isTruncatable: false,
    };
  }

  return {
    isTruncated,
    setIsTruncated,
    setNoteBodyRef,
    noteBodyRef,
    isTruncatable: canTruncate.current,
  };
}
