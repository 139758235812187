import { FormattedMessage } from 'react-intl';

import { AcceptButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/AcceptButton';
import { RejectButton } from '@/components/AsyncTitration/SuggestedTitration/Actions/RejectButton';
import PencilIcon from '@/shared/assets/svgs/pencil.svg?react';
import { Button } from '@/shared/tempo/atom/Button';

import type { SuggestedTitrationMode } from '../types';
import { buttonContainer, editButton } from './actions.css';

type Props = {
  mode: SuggestedTitrationMode;
  isDisabled?: boolean;
  onReject: () => void;
  onEdit: () => void;
  onAccept: () => void;
};

export function NewActions({
  mode,
  isDisabled = false,
  onReject,
  onEdit,
  onAccept,
}: Props) {
  return (
    <div className={buttonContainer}>
      <RejectButton onReject={onReject} mode={mode} isDisabled={isDisabled}>
        <FormattedMessage defaultMessage="Do not titrate" />
      </RejectButton>
      <Button
        variant="secondary"
        className={editButton.button.default}
        onPress={onEdit}
        isDisabled={isDisabled}
      >
        <Button.Icon>
          <PencilIcon />
        </Button.Icon>
        <FormattedMessage defaultMessage="Edit" />
      </Button>
      <AcceptButton onAccept={onAccept} mode={mode} isDisabled={isDisabled}>
        <FormattedMessage defaultMessage="Accept" />
      </AcceptButton>
    </div>
  );
}
