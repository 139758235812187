import {
  homeActionArea,
  leftActionArea,
  rightActionArea,
} from './HomePage.css';
import { HomeScheduleArea } from './HomeScheduleArea';
import { HomeTaskArea } from './HomeTaskArea';

export function HomeActionArea() {
  return (
    <div className={homeActionArea}>
      <div className={leftActionArea}>
        <HomeTaskArea />
      </div>
      <div className={rightActionArea}>
        <HomeScheduleArea />
      </div>
    </div>
  );
}
