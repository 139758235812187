import type {
  ArrayFieldTemplateProps,
  FieldTemplateProps,
  ObjectFieldTemplateProps,
  UiSchema,
} from '@rjsf/core';
import Form from '@rjsf/core';
import cx from 'classnames';
import type { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import type { ReactNode } from 'react';
import { Fragment, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { FormLabel } from '@/deprecated/mui';
import { logger } from '@/logger';
import { ErrorMessage } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/ErrorMessage';
import {
  type Schema,
  validateSchemaDrivenFormData,
} from '@/shared/common/@deprecated/SchemaDrivenForm';
import { Divider } from '@/shared/common/Divider';
import { inlineFlex } from '@/shared/jsStyle/flex.css';

import {
  divider,
  enumLabelWrapper,
  errorStyle,
  formContainer,
  formField,
  formLabel,
  formLabelWrapper,
  inlineFormField,
  sectionLabel,
} from './SchemaDrivenForm.css';
import { DescriptionField } from './fields/DescriptionField';
import { useFormData } from './formData/useFormData.hook';
import { transformSchemaForRjsf } from './schemaTransform/transformSchemaForRjsf';
import { getUiLabel } from './uiLabelUtils';
import { hasValidationErrorFormContext } from './validationErrorUtils';
import {
  CheckboxWidget,
  DateWidget,
  RadioWidget,
  RichTextEditorWidget,
  TextWidget,
  ToggleWidget,
} from './widgets';

export const INVALID_DATE = 'INVALID_DATE';

export type DataObject = {
  [key: string]: DataValue;
};

export type DataValue = boolean | DataObject | string | number | undefined;

export type Props<T> = {
  uiSchema?: UiSchema;
  schema: Schema;
  shouldChange?: (values: T, prevVals: T | undefined) => boolean;
  onChange: (values: T) => void;
  shouldRenderTitle?: boolean;
  shouldLiveValidate?: boolean;
  initialFormData?: T;
  shouldShowValidation: boolean;
};

/** @deprecated Use our `Form` components instead */
export function SchemaDrivenForm<T extends DataObject>({
  schema,
  uiSchema = {},
  onChange,
  shouldRenderTitle = false,
  shouldLiveValidate = false,
  initialFormData,
  shouldChange = () => true,
  shouldShowValidation,
}: Props<T>) {
  const idPrefix = useIdPrefix();
  const { formData, onFormChange } = useFormData(
    initialFormData,
    onChange,
    schema,
  );
  const rjsfFormSchema = useRjsfSchema(schema, shouldRenderTitle, formData);
  const errors = validateSchemaDrivenFormData(formData as DataObject, schema);

  return (
    <div className={formContainer}>
      <Form
        liveValidate={shouldLiveValidate}
        uiSchema={uiSchema}
        schema={
          // Casting is needed because we type the schema using the jsonschema
          // library which we use for its Validator, but rjsf has its own schema
          //  type that has a more specific type for the "type" keyword
          rjsfFormSchema as JSONSchema7
        }
        onChange={(change: { formData: T }) => {
          if (shouldChange(change.formData, formData)) {
            onFormChange(change.formData);
          }
        }}
        formContext={shouldShowValidation ? errors : null}
        formData={formData}
        omitExtraData
        liveOmit
        onError={(error) => logger.error(error)}
        showErrorList={false}
        idPrefix={idPrefix}
        FieldTemplate={CustomFieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ArrayFieldTemplate={ArrayFieldTemplate}
        fields={{
          DescriptionField,
        }}
        // List of widgets that can be overridden:
        // https://react-jsonschema-form.readthedocs.io/en/latest/advanced-customization/custom-widgets-fields/#customizing-the-default-fields-and-widgets
        // For an inspiration how to write widgets:
        // https://github.com/rjsf-team/react-jsonschema-form/tree/master/packages/material-ui/src
        // https://github.com/rjsf-team/react-jsonschema-form/tree/master/packages/core/src/components/widgets
        widgets={{
          CheckboxWidget,
          TextWidget,
          // If SelectWidget is needed, implement a component version of
          // https://github.com/cadencerpm/falcon/pull/518/files#diff-4333118fd9c9533da9b3e3fdb37a64a6ca2ba91785a1ebd7cacab9da412f0bceR138
          // (function version does not preserve state, which may be required for third-party
          // components to work properly)
          //
          // SelectWidget,
          DateWidget,
          RadioWidget,
          toggle: ToggleWidget,
          richText: RichTextEditorWidget,
        }}
      >
        {/* Passing in an empty fragment hides the submit button */}
        <></>
      </Form>
    </div>
  );
}

// App wide counter used to make sure each SchemaDrivenForm renders
// react-json-schema-form Form and its elements with unique ids
const counterRef = { current: 0 };

function useIdPrefix() {
  return useMemo(() => {
    counterRef.current += 1;
    return `react-json-schema-form-${counterRef.current}`;
  }, []);
}

function useRjsfSchema<T extends DataObject>(
  schema: Schema,
  shouldRenderTitle: boolean,
  formData: T | undefined,
) {
  let rjsfFormSchema = schema;
  if (!shouldRenderTitle) {
    rjsfFormSchema = {
      ...schema,
      title: '',
    };
  }
  return useMemo(
    () => transformSchemaForRjsf(rjsfFormSchema, formData ?? {}),
    [rjsfFormSchema, formData],
  );
}

function CustomFieldTemplate({
  id,
  classNames,
  label: rjsfLabel,
  help,
  required,
  description,
  errors,
  children,
  hidden,
  schema: { type, enum: enumType, title },
  uiSchema: {
    'ui:label': uiLabelOption,
    'ui:options': uiOptions,
    'ui:portalRef': portalRef,
    'ui:divider': isUiDivider,
  },
  formContext,
}: FieldTemplateProps) {
  if (hidden) {
    return children;
  }
  const hasError = hasValidationErrorFormContext(formContext, rjsfLabel, id);

  const fieldType =
    (type as JSONSchema7TypeName) ?? (enumType && 'enum') ?? 'unknown';

  const uiLabel = getUiLabel(uiLabelOption, title);

  const label = getLabel(
    fieldType,
    id,
    uiLabel,
    rjsfLabel,
    required,
    uiOptions,
    hasError,
  );

  const hasInlineStyle = uiOptions?.inline;
  const result = (
    <>
      <div
        className={cx(
          {
            [inlineFlex]: hasInlineStyle,
            [inlineFormField]:
              fieldType !== 'boolean' &&
              fieldType !== 'object' &&
              hasInlineStyle,
            [formField]:
              fieldType !== 'boolean' &&
              (!hasInlineStyle || fieldType !== 'object'),
          },
          classNames,
        )}
      >
        {/* Wrapping with div so inlined fields don't have inlined labels */}
        <div>
          {fieldType !== 'boolean' && label}
          {description}
          {children}
          {errors}
          {help}
          <ErrorMessage hasError={hasError} />
        </div>
      </div>
      {isUiDivider && <Divider className={divider} />}
    </>
  );
  return portalRef?.current
    ? ReactDOM.createPortal(result, portalRef.current)
    : result;
}

// eslint-disable-next-line react-refresh/only-export-components
export function getLabel(
  fieldType: JSONSchema7TypeName | 'enum' | 'unknown',
  id: string,
  uiLabel: ReactNode,
  label: string,
  required: boolean,
  uiOptions?: Record<string, unknown>,
  hasError?: boolean,
) {
  if (uiOptions?.label === false) {
    return null;
  }
  const hasSectionLabel = fieldType === 'object' || fieldType === 'enum';
  const labelElem = (
    <FormLabel
      className={cx({
        [sectionLabel]: hasSectionLabel,
        [formLabel]: !hasSectionLabel,
        [errorStyle]: hasError,
      })}
      htmlFor={id}
    >
      {uiLabel || label}
      {required ? ' *' : null}
    </FormLabel>
  );
  switch (fieldType) {
    case 'boolean':
      return labelElem;
    default:
      return (
        <div
          className={cx({
            [enumLabelWrapper]: fieldType === 'enum',
            [formLabelWrapper]: !hasSectionLabel,
            [errorStyle]: hasError,
          })}
        >
          {labelElem}
        </div>
      );
  }
}

function ObjectFieldTemplate({
  properties,
  uiSchema: { 'ui:childOptions': uiChildOptions },
}: ObjectFieldTemplateProps) {
  function doesPropertyHaveFalseValue(name: string) {
    return properties.find(
      (property) =>
        property.name === name && property.content.props.formData === false,
    );
  }
  return (
    <div>
      {properties.map((element) => {
        const dependentOn = element.content.props.uiSchema?.['ui:dependentOn'];
        if (dependentOn && doesPropertyHaveFalseValue(dependentOn)) {
          return null;
        }

        if (uiChildOptions?.inline) {
          return (
            <div key={element.name} className={cx(inlineFlex, inlineFormField)}>
              {element.content}
            </div>
          );
        }

        return <Fragment key={element.name}>{element.content}</Fragment>;
      })}
    </div>
  );
}

function ArrayFieldTemplate({
  items,
  canAdd,
  onAddClick,
}: ArrayFieldTemplateProps) {
  return (
    <div>
      <div>
        {items.map((element) => (
          <Fragment key={element.key}>{element.children}</Fragment>
        ))}
        {canAdd && (
          <button type="button" onClick={onAddClick}>
            <FormattedMessage defaultMessage="Add item" />
          </button>
        )}
      </div>
    </div>
  );
}
