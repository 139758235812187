import classnames from 'classnames/bind';
import { compareAsc, parseISO } from 'date-fns';
import { camelizeKeys } from 'humps';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Checkbox, FormControlLabel } from '@/deprecated/mui';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Snackbar } from '@/shared/common/Snackbar';
import { usePatchPatient } from '@/shared/hooks/queries';
import type { PatientDetails } from '@/shared/types/patient.types';
import Session from '@/shared/utils/session';

import { SectionHeader } from '../SectionHeader';
import { MedicalInfoDialog } from './MedicalInfoDialog';
import { MedicalInfoRow } from './MedicalInfoRow';
import styles from './medicalInfo.module.scss';
import type {
  MedicalInfo as MedInfo,
  MedicalInfoResponse,
} from './medicalInfo.types';

const cx = classnames.bind(styles);

type MedicalInfoProps = {
  patient: PatientDetails;
  updatePatient: (patient: PatientDetails) => void;
};

export function MedicalInfo({ patient, updatePatient }: MedicalInfoProps) {
  const intl = useIntl();
  const [info, setInfo] = useState<MedInfo[]>([]);
  const [hasOxygen, setHasOxygen] = useState(patient.has_oxygen_at_home);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const patchPatient = usePatchPatient(patient.id);
  useEffect(() => {
    setIsLoading(true);

    Session.Api.get<MedicalInfoResponse>(
      `/pms/api/v1/patients/${patient.id}/medical_info`,
    )
      .then((response) => {
        const sorted = response.data.data.sort((a, b) =>
          compareAsc(parseISO(b.created_at), parseISO(a.created_at)),
        );
        const camelized = camelizeKeys(sorted) as unknown as MedInfo[];

        setInfo(camelized);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, [patient.id]);

  const onMedicalInfoCreated = useCallback(
    (text: string) => {
      setIsSaving(true);
      setError(undefined);

      Session.Api.post(`/pms/api/v1/patients/${patient.id}/medical_info`, {
        text,
      })
        .then((response) => {
          const camelized = camelizeKeys(response.data) as unknown as MedInfo;

          setInfo([camelized, ...info]);
          setIsSaving(false);
          setIsEditing(false);
        })
        .catch((err) => {
          setError(err);
          setIsSaving(false);
        });
    },
    [patient, info],
  );

  const onOxygenToggled = () => {
    // optimistic update
    setHasOxygen(!hasOxygen);

    patchPatient.mutate(
      { has_oxygen_at_home: !hasOxygen },
      {
        onSuccess: async ({ data }) => {
          updatePatient(data);
        },
        onError: () => {
          // roll back the optimistic update
          setHasOxygen(hasOxygen);
        },
      },
    );
  };

  return (
    <>
      {isEditing && (
        <MedicalInfoDialog
          isSaving={isSaving}
          onClose={() => setIsEditing(false)}
          onSave={onMedicalInfoCreated}
        />
      )}
      {error && <Snackbar message={error} variant="error" />}
      <SectionHeader onEditClicked={() => setIsEditing(!isEditing)}>
        <FormattedMessage defaultMessage="Medical Info" />
      </SectionHeader>
      <LoadingPlaceholder isLoading={isLoading}>
        <FormControlLabel
          className={cx('checkbox')}
          label={intl.formatMessage({
            defaultMessage: 'Has oxygen in home',
          })}
          disabled={patchPatient.isLoading}
          control={<Checkbox checked={hasOxygen} onChange={onOxygenToggled} />}
        />
        <ul className={cx('medical-info-list')}>
          {info.map(({ id, careProvider, createdAt, text }) => (
            <MedicalInfoRow
              key={`medical-info-${id}`}
              author={`${careProvider.firstName} ${careProvider.lastName}`}
              createdAt={createdAt}
              note={text}
            />
          ))}
        </ul>
      </LoadingPlaceholder>
    </>
  );
}
