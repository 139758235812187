import type { IntlShape } from 'react-intl';

import { makeI18nHook } from '@/shared/hooks';
import { EntryType } from '@/shared/types/monitoringSession.types';

export const useEntryTypeMap = makeI18nHook(getEntryTypeMap);

type EntryTypeMap = {
  [key in EntryType]: string;
};

function getEntryTypeMap(intl: IntlShape): EntryTypeMap {
  return {
    [EntryType.Encounter]: intl.formatMessage({
      defaultMessage: 'Encounter',
    }),
    [EntryType.Manual]: intl.formatMessage({
      defaultMessage: 'Manual',
    }),
  };
}
