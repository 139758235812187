import { useObjectRef } from '@react-aria/utils';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { AriaTagGroupProps } from 'react-aria';
import { useTagGroup } from 'react-aria';
import { useListState } from 'react-stately';

import { GroupTag } from './GroupTag';
import { tagList } from './TagGroup.css';

type Props<T> = AriaTagGroupProps<T>;

function TagGroup<T extends object>(
  props: Props<T>,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  const ref = useObjectRef(forwardedRef);
  const state = useListState(props);
  const { gridProps } = useTagGroup(props, state, ref);

  return (
    <div {...gridProps} ref={ref} className={tagList}>
      {[...state.collection].map((item) => (
        <GroupTag key={item.key} state={state} item={item} {...item.props} />
      ))}
    </div>
  );
}

const ForwardedTagGroup = forwardRef(TagGroup);
export { ForwardedTagGroup as TagGroup };
