import _zip from 'lodash/zip';

import type { QueryParams } from '@/reactQuery';

type OrderByDeclaration = {
  rename?: string;
  default?: boolean;
};

export type QueryParamsOrderByLookup = {
  [key: string]: OrderByDeclaration;
};

export class GrpcOrderbyBuilder {
  orderBy: string = '';

  public fromQueryParams(
    qp: QueryParams,
    orderByLookup: QueryParamsOrderByLookup,
  ) {
    const { sort_by: sortBy, order_by: orderBy } = qp as {
      sort_by: string[];
      order_by: string[];
    };

    if (sortBy?.length === 0 || !sortBy) {
      const defaultOrderBy = Object.entries(orderByLookup).find(
        ([, { default: isDefault }]) => isDefault,
      );
      if (!defaultOrderBy) {
        throw new Error('Missing default orderby');
      }
      this.orderBy = `${defaultOrderBy[1].rename ?? defaultOrderBy[0]} asc`;
      return this;
    }

    const sortByOrderByPairs = _zip(sortBy, orderBy);

    sortByOrderByPairs.forEach(([sort, order]) => {
      if (sort) {
        if (Object.keys(orderByLookup).includes(sort)) {
          this.orderBy += `${orderByLookup[sort].rename ?? sortBy} ${order}, `;
        }
      }
    });

    this.orderBy = this.orderBy.trimEnd().slice(0, -1);

    return this;
  }
}
