/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError } from 'axios';
import { formatISO, getUnixTime, parseISO } from 'date-fns';
import type { JwtPayload } from 'jwt-decode';
import jwt_decode from 'jwt-decode';

import type { EjectionFraction } from '@/shared/types/clinicalprofile.types';
import type { Pascals } from '@/shared/types/units';

import { convertToMmHg } from './unit-helpers';

export enum Check {
  IMMEDIATELY,
  IN_NEXT_5_MIN,
}

const FIVE_MIN_IN_SEC = 5 * 60;

export const isRefreshTokenNeeded = (
  immediately: Check,
  accessToken?: string,
) => {
  if (!accessToken) {
    return true;
  }

  const now = getUnixTime(new Date());
  const decoded = jwt_decode<JwtPayload>(accessToken);
  const { exp = 0 } = decoded;

  return Boolean(
    now >= exp - (immediately === Check.IMMEDIATELY ? 0 : FIVE_MIN_IN_SEC),
  );
};

const isEmailConflict = (description?: string) =>
  !!description?.includes('email') &&
  !description.includes('primary_contact_email');

const getShortErrorMsg = (description?: string) => {
  if (isEmailConflict(description)) {
    return 'A user with the same email already exists';
  }
  return description;
};

export const getErrorMsg = (error: { message: string } | any): string => {
  if (error?.message === 'Network Error') {
    return error?.message;
  }
  if (error?.name === 'ApiError') {
    return error?.body?.description || '';
  }
  return (
    getShortErrorMsg(
      error.response?.data?.description || error.response?.data?.message,
    ) || ''
  );
};

export function getGrpcErrorMessage(error: unknown): string | undefined {
  return (error as AxiosError<{ message?: string }>)?.response?.data?.message;
}

export function paginateList<T>(list: T[], page: number, perPage: number) {
  return list.slice((page - 1) * perPage, page * perPage);
}

export const displayEjectionFraction = (
  ejectionFraction: Maybe<EjectionFraction>,
  fallback: string = '-',
) => {
  const ef = [ejectionFraction?.lower, ejectionFraction?.upper]
    .filter((v) => v || v === 0)
    .join('-');

  return ef ? `${ef}%` : fallback;
};

export const displayA1c = (
  a1c?: Nullable<number>,
  suffix: string = '%',
  fallback: string = '-',
) => {
  if (typeof a1c !== 'number') return fallback;

  return a1c.toFixed(1).replace(/\.0/, '') + suffix;
};

export function displayBloodPressure(
  systolic: Maybe<Pascals>,
  diastolic: Maybe<Pascals>,
  placeholder = '-',
) {
  if (!systolic || !diastolic) {
    return placeholder;
  }
  return `${convertToMmHg(systolic)}/${convertToMmHg(diastolic)}`;
}

export function displayBmi(bmi: Maybe<number>, placeholder = '-') {
  return String(bmi ?? placeholder);
}

export const getFormattedDate = (date?: Nullable<string | Date>) => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? parseISO(date) : date;

  return formatISO(dateObj, { representation: 'date' });
};
