{{!--
  This field template is shared for HTN, T2D, and HTN+T2D conditions
--}}
{{> header val="General Assessment" }}
<br/>

{{> subheader val="Social History" }}
<p>Tobacco/Alcohol/Drugs- any changes since last visit? Review past history and ask patients if any changes?</p>
{{> free_text_space }}

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>1. Review past discussion and highlight any recent patient diet goals?</p>
<p>2. Do they have any new dietary goals?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>1. Review past discussion and highlight any recent patient exercise goals?</p>
<p>2. Do they have any new exercise goals?</p>
{{> free_text_space }}

{{> subheader val="Activities of Daily Living" }}
<p>Any significant changes in functional status (need new assistance with walking, eating, toileting, or dressing?)</p>
{{> free_text_space }}

{{> header val="Action Plan" }}
{{> regular_visit_action_plan }}
<br/>
