import cx from 'classnames';

import type { InputBaseComponentProps } from '@/deprecated/mui';

import { baseField, input } from './Input.css';

export function addInputWrapperClassname(
  isDisabled?: boolean,
  isReadOnly?: boolean,
  isError?: boolean,
  className?: string,
) {
  return cx(
    {
      [baseField.disabled]: isDisabled,
      [baseField.readOnly]: isReadOnly,
      [baseField.default]: !isDisabled && !isReadOnly,
      [baseField.error]: isError,
    },
    className,
  );
}

export function addInputClassname(props?: InputBaseComponentProps) {
  return {
    ...props,
    className: cx(input, props?.className),
  };
}
