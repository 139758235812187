import { format, parseISO } from 'date-fns';
import flatMap from 'lodash/flatMap';
import orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import type { CCMCarePlan } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import type { LobLetter } from '@/shared/generated/grpcGateway/patient_outreach.pb';
import { Button } from '@/shared/tempo/atom/Button';

import { letterSendRow, letterSendsContainer } from './MailHistoryModal.css';
import { useFormatDateInUserTz } from './useFormatDateInUserTz';

type Props = {
  allCarePlans: CCMCarePlan[];
  onClose: () => void;
};

export function MailHistoryModal({ allCarePlans, onClose }: Props) {
  const letterSendRows = flatMap(
    allCarePlans?.map((cp) => {
      const letterSends = orderBy(
        cp.sends || [],
        'sendTime',
        'desc',
      ) as LobLetter[];

      return letterSends.map((letter) => (
        <LetterSendRow
          key={`${cp.name}-${letter.name}`}
          carePlan={cp}
          letter={letter}
        />
      ));
    }),
  );

  return (
    <Modal open onClose={onClose} isLoading={false}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="Care plan mail history" />}
      />
      <Modal.Body>
        <div className={letterSendsContainer}>{letterSendRows}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={() => onClose()}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function LetterSendRow({
  carePlan,
  letter,
}: {
  carePlan: CCMCarePlan;
  letter: LobLetter;
}) {
  const formatDateInUserTz = useFormatDateInUserTz();
  const cpCreatedOnDate = parseISO(carePlan.createTime || '');
  const sentAt = parseISO(letter.sendTime || '');

  return (
    <div className={letterSendRow}>
      <FormattedMessage
        defaultMessage="{cpDate} Care Plan: Sent {sentAt}"
        values={{
          cpDate: format(cpCreatedOnDate, 'MMM dd, yyyy'),
          sentAt: formatDateInUserTz(sentAt, "MM/dd/yyyy 'at' h:mm aa"),
        }}
      />
    </div>
  );
}
