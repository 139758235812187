import { FormattedMessage, useIntl } from 'react-intl';

import AlertIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import CheckCircleIcon from '@/shared/assets/svgs/check-circle.svg?react';
import CloseIcon from '@/shared/assets/svgs/closeNoFill.svg?react';
import PencilIcon from '@/shared/assets/svgs/edit.svg?react';
import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import { MedicationChangeStatus } from '@/shared/generated/grpcGateway/medication.pb';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import { usePatientMedicationsContext } from '../PatientMedicationsContext';
import { MedPermissions } from '../PatientMedicationsList/types';
import { useVerifyMedication } from '../patientMedications.queries';
import { isStructured, isTitrationChange } from '../utils/medChangeUtils';
import { HistoryIconButton } from './HistoryIconButton';
import { NPActions } from './NPActions';
import { UndoActions } from './UndoAction';
import {
  actionIcon,
  alertIcon,
  alertIconContainer,
  medActionsContainer,
  removeIcon,
} from './styles.css';

export function MedicationActions({
  isDiseaseSpecific,
  medChangeId,
  medChange,
  medPermissions,
  showInactiveInEhrWarning,
  showUnstructuredWarning,
  onReview,
  onRemove,
  onClose,
  onShowHistory,
  onManageRx,
  onSuccess,
  onVerifyUnstructured,
}: {
  isDiseaseSpecific?: boolean;
  medPermissions?: MedPermissions;
  medChangeId: string;
  medChange: MedicationChange;
  status?: MedicationChangeStatus;
  showInactiveInEhrWarning: boolean;
  showUnstructuredWarning?: boolean;
  onReview: () => void;
  onVerifyUnstructured: () => void;
  onRemove: () => void;
  onClose: () => void;
  onShowHistory?: () => void;
  onManageRx: () => void;
  onSuccess: () => void;
}) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { noteId: currNoteId } = usePatientMedicationsContext();

  const { updatedDate, status, changeType } = medChange;

  const verifyMedication = useVerifyMedication(medChangeId, onSuccess);
  function handleVerify() {
    verifyMedication.mutate(undefined, {
      onSuccess: () => {
        if (isStructured(medChange) || !isDiseaseSpecific) {
          onClose();
        } else {
          onVerifyUnstructured();
        }
      },
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: `Failed to verify medication: {message}`,
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }

  const showActions = medPermissions !== MedPermissions.View;
  const isCurrentNoteTitration =
    isTitrationChange(changeType) && medChange.noteId === currNoteId;

  return (
    <div className={medActionsContainer}>
      {showActions && (
        <>
          {showInactiveInEhrWarning && (
            <Tooltip
              content={
                <FormattedMessage defaultMessage="Med is not active in EMR" />
              }
            >
              <div className={alertIconContainer}>
                <AlertIcon className={alertIcon} />
              </div>
            </Tooltip>
          )}
          {showUnstructuredWarning && (
            <Tooltip
              content={
                <FormattedMessage defaultMessage="Disease-specific medications need to be filled out in med corrections" />
              }
            >
              <div className={alertIconContainer}>
                <AlertIcon className={alertIcon} />
              </div>
            </Tooltip>
          )}
          <UndoActions
            medChangeId={medChangeId}
            medChange={medChange}
            onSuccess={onSuccess}
          />
          {status === MedicationChangeStatus.NEEDS_REVIEW && (
            <>
              <IconButton.Tooltip
                content={intl.formatMessage({ defaultMessage: 'Mark active' })}
              >
                <IconButton
                  variant="secondary"
                  size="small"
                  onPress={handleVerify}
                  isProcessing={verifyMedication.isLoading}
                >
                  <CheckCircleIcon className={actionIcon} />
                </IconButton>
              </IconButton.Tooltip>
              <RemoveButton onRemove={onRemove} />
              <IconButton.Tooltip
                content={intl.formatMessage({
                  defaultMessage: 'Med correction',
                })}
              >
                <IconButton variant="secondary" size="small" onPress={onReview}>
                  <PencilIcon />
                </IconButton>
              </IconButton.Tooltip>
            </>
          )}
          {status === MedicationChangeStatus.ACTIVE &&
            !isCurrentNoteTitration && (
              <>
                {medPermissions === MedPermissions.Review && (
                  <>
                    <RemoveButton onRemove={onRemove} />
                    <IconButton.Tooltip
                      content={intl.formatMessage({
                        defaultMessage: 'Med correction',
                      })}
                    >
                      <IconButton
                        variant="secondary"
                        size="small"
                        onPress={onReview}
                      >
                        <PencilIcon />
                      </IconButton>
                    </IconButton.Tooltip>
                  </>
                )}

                {medPermissions === MedPermissions.Titrate && (
                  <NPActions
                    allowTitration={Boolean(
                      !showUnstructuredWarning && isDiseaseSpecific,
                    )}
                    onManageRx={onManageRx}
                    onReview={onReview}
                    onRemove={onRemove}
                  />
                )}
              </>
            )}
        </>
      )}
      {onShowHistory && (
        <HistoryIconButton {...{ onShowHistory, updatedDate }} />
      )}
    </div>
  );
}

function RemoveButton({ onRemove }: { onRemove: () => void }) {
  const intl = useIntl();
  return (
    <IconButton.Tooltip
      content={intl.formatMessage({ defaultMessage: 'Mark not active' })}
    >
      <IconButton variant="secondary" size="small" onPress={onRemove}>
        <CloseIcon className={removeIcon} />
      </IconButton>
    </IconButton.Tooltip>
  );
}
