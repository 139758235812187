import type { IntlShape } from 'react-intl';

import { makeI18nHook } from '@/shared/hooks';
import { CommunicationType } from '@/shared/types/monitoringSession.types';

export const useCommunicationTypeMap = makeI18nHook(getCommunicationTypeMap);

type CommunicationTypeMap = {
  [key in CommunicationType]: string;
};

function getCommunicationTypeMap(intl: IntlShape): CommunicationTypeMap {
  return {
    [CommunicationType.PhoneCall]: intl.formatMessage({
      defaultMessage: 'Phone',
    }),
    [CommunicationType.Sms]: intl.formatMessage({
      defaultMessage: 'SMS',
    }),
    [CommunicationType.PhoneAndSms]: intl.formatMessage({
      defaultMessage: 'Phone + SMS',
    }),
    [CommunicationType.NoCommunication]: intl.formatMessage({
      defaultMessage: 'None',
    }),
  };
}
