import { Avatar as AvatarBase } from './Avatar';
import { Label } from './Label';
import { Portrait } from './Portrait';
import { Stack } from './Stack';

export const Avatar = Object.assign(AvatarBase, {
  Label,
  Portrait,
  Stack,
});
