export enum CustomBlockType {
  ClearFormat = 'clearformat',
}

export enum CustomRtfEditorStyles {
  TEMPLATE_HEADER = 'TEMPLATE_HEADER',
}

export enum RtfEditorStyles {
  HIGHLIGHT = 'HIGHLIGHT',
  STRIKETHROUGH = 'STRIKETHROUGH',
  UNDERLINE = 'UNDERLINE',
  ITALIC = 'ITALIC',
  BOLD = 'BOLD',
}
