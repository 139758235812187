import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';
import { ConditionProgram } from '@/shared/types/condition.types';

import { useCNFormContext } from '../../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../../metadata';
import type { SecondarySymptomsFormFields } from '../../../shared/SecondarySymptoms';
import {
  SharedSecondarySymptoms,
  getSecondarySymptomsFormConfig,
} from '../../../shared/SecondarySymptoms';
import { cycle17InitVisitPath } from '../../paths';

export function SecondarySymptoms() {
  const intl = useIntl();
  const { program } = useCNFormContext();
  const form = useWizardFormFromConfig<SecondarySymptomsFormFields>(
    cycle17InitVisitPath('/symptoms', '/secondary'),
    getSecondarySymptomsFormConfig(intl, program),
  );

  return (
    <SharedSecondarySymptoms
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      nextPath={
        program === ConditionProgram.CHF
          ? cycle17InitVisitPath('/emergency-visits', '/index')
          : cycle17InitVisitPath('/profile-completion', '/index')
      }
    />
  );
}
