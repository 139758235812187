import { createCtx } from '@/shared/hooks/createCtx';

import type { Toaster } from './types';

type ToasterContext = {
  toaster: Toaster;
};

export const [useToaster, ToastContextProvider, ToasterContextConsumer] =
  createCtx<ToasterContext>('ToasterContext');
