import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../../metadata';
import type { SetActionStepsFields } from '../../../shared/SetActionSteps';
import {
  SharedSetActionSteps,
  getSetActionStepsFormConfig,
} from '../../../shared/SetActionSteps';
import { initVisitPath } from '../../paths';
import { usePatientGoalText } from '../usePatientGoal';

export function SetActionSteps() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<SetActionStepsFields>(
    initVisitPath('/action-plan', '/set-action-steps'),
    getSetActionStepsFormConfig(intl),
  );
  const patientGoalText = usePatientGoalText();

  return (
    <SharedSetActionSteps
      form={form}
      sections={INIT_VISIT_SECTIONS}
      nextPath={initVisitPath('/action-plan', '/review-action-steps')}
      patientGoalText={patientGoalText}
    />
  );
}
