import { Item, Section } from 'react-stately';

import { ControlledListBox } from './ControlledListBox';
import { ListBox as BaseListBox } from './ListBox';

export const ListBox = Object.assign(BaseListBox, {
  Controlled: ControlledListBox,
  Option: Item,
  Section,
});
