import cx from 'classnames';

import { notificationBadge, wideBadge } from './NotificationBadge.css';

export type NotificationBadgeProps = {
  count: number | string;
  variant?: 'default' | 'muted' | 'warn';
  className?: string;
};

export function NotificationBadge({
  count,
  className,
  variant = 'default',
}: NotificationBadgeProps) {
  return (
    <span
      className={cx(
        notificationBadge[variant],
        {
          [wideBadge]: count.toString().length > 2,
        },
        className,
      )}
    >
      {count}
    </span>
  );
}
