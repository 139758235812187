import { useIntl } from 'react-intl';

import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import {
  useGetWizardStepValues,
  useWizardFormFromConfig,
} from '@/shared/common/Wizard/state';

import { initVisitPath } from '../../initialVisit/paths';
import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import type { EducationModulesReviewFormFields } from '../../shared/EducationModulesReview';
import {
  SharedEducationModulesReview,
  getEducationModulesReviewFormConfig,
} from '../../shared/EducationModulesReview';
import type { PatientGoalFormFields } from '../../shared/setPatientGoal';
import { titrationOutreachPath } from '../paths';

export function EducationModulesReview() {
  const intl = useIntl();

  const path = titrationOutreachPath(
    '/action-plan',
    '/education-modules-review',
  );
  const { isLoading, data } =
    useRecentNoteFormData<EducationModulesReviewFormFields>([
      path,
      initVisitPath('/action-plan', '/education-modules-review'),
    ]);
  const form = useWizardFormFromConfig<EducationModulesReviewFormFields>(path, {
    ...getEducationModulesReviewFormConfig(intl, data),
    triggerReset: !isLoading,
  });

  const patientGoal = useGetWizardStepValues(
    TITRATION_OUTREACH_SECTIONS,
    '/action-plan',
    '/set-goal',
  )<PatientGoalFormFields>()?.patientGoal;

  return (
    <SharedEducationModulesReview
      form={form}
      isLoading={isLoading}
      sections={TITRATION_OUTREACH_SECTIONS}
      nextPath={titrationOutreachPath('/action-plan', '/set-action-steps')}
      patientGoal={patientGoal}
    />
  );
}
