import { SharedFeedback } from '../../shared/ProductSupport/SharedFeedback';
import { regVisitPath } from '../paths';

export function Feedback() {
  return (
    <SharedFeedback
      currentPath={regVisitPath('/product-support', '/feedback')}
      nextPath={regVisitPath('/vital-progress', '/intro')}
    />
  );
}
