import useResizeObserver from '@react-hook/resize-observer';
import type { RefObject } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import type { SelectState } from 'react-stately';

import type { PopoverWidth } from './types';

type Config = {
  width?: PopoverWidth;
};

export function useSyncSelectPopoverWidth<T>(
  state: SelectState<T>,
  selectButtonRef: RefObject<HTMLButtonElement>,
  popoverRef: RefObject<HTMLDivElement>,
  config: Config = {},
) {
  const resizePopover = useCallback(() => {
    if (!state.isOpen) {
      return;
    }

    if (!selectButtonRef.current || !popoverRef.current) {
      return;
    }

    const selectBtnWidth = `${selectButtonRef.current.offsetWidth}px`;

    /* eslint-disable no-param-reassign */
    if (config.width === 'min-select') {
      popoverRef.current.style.minWidth = selectBtnWidth;
    } else if (config.width !== 'static') {
      popoverRef.current.style.width = selectBtnWidth;
    }
    /* eslint-enable no-param-reassign */
  }, [config.width, popoverRef, selectButtonRef, state.isOpen]);

  // Re-size popover when the select is resized
  useResizeObserver(selectButtonRef, resizePopover);
  // Re-size when the popover is opened
  useLayoutEffect(resizePopover, [resizePopover]);
}
