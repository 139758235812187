import type { Placement } from '@react-types/overlays';
import type { ReactElement } from 'react';
import { cloneElement, useRef } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';
import type { OverlayTriggerProps } from 'react-stately';

import { Popover } from './Popover';
import { hiddenPopover } from './Popover.css';

type RenderTrigger = (props: { isOpen: boolean }) => ReactElement;

type Props = {
  placement?: Placement;
  children: ReactElement | RenderTrigger;
  content: ReactElement;
  keepMounted?: boolean;
} & Omit<OverlayTriggerProps, 'children'>;

export function PopoverTrigger({
  content,
  children,
  keepMounted,
  ...props
}: Props) {
  const triggerRef = useRef(null);
  const hasOpened = useRef(false);
  const state = useOverlayTriggerState(props);
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef,
  );
  const { isOpen } = state;
  const hiddenWhenClosed = !isOpen ? hiddenPopover : undefined;

  if (isOpen && !hasOpened.current) {
    hasOpened.current = true;
  }

  // with keepMounted, we don't want to pre-emptively render the popover, but
  // we do want to keep it mounted once it's been rendered at least once.
  const shouldRenderPopover = isOpen || (hasOpened.current && keepMounted);

  return (
    <>
      {cloneElement(
        typeof children === 'function' ? children({ isOpen }) : children,
        {
          ref: triggerRef,
          ...triggerProps,
          isOpen,
        },
      )}
      {shouldRenderPopover && (
        <Popover
          {...props}
          triggerRef={triggerRef}
          state={state}
          className={hiddenWhenClosed}
          classes={{
            underlay: hiddenWhenClosed,
          }}
        >
          {cloneElement(content, overlayProps)}
        </Popover>
      )}
    </>
  );
}
