import first from 'lodash/first';
import { useCallback, useState } from 'react';

import type {
  CompositeSortDirection,
  CompositeSortKey,
  SortDirection,
} from '@/shared/types/sorting.types';
import { Sort } from '@/shared/types/sorting.types';

import { getCompositeSortDir } from './sort.utils';

const SORT_CONTROL: SortDirection[] = [
  Sort.Ascending,
  Sort.Descending,
  Sort.None,
];

export type SortState<K> = {
  sortKey?: CompositeSortKey<K>;
  sortDir?: CompositeSortDirection;
};

export function useTableSort<K>(initSort: SortState<K> = {}) {
  const [sortState, setSortState] = useState(initSort);
  const handleSort = useCallback(
    (sortKey: CompositeSortKey<K>, sortDir?: CompositeSortDirection) => {
      setSortState({ sortKey, sortDir: getNewSortDir(sortKey, sortDir) });
    },
    [],
  );

  return { sortState, handleSort };
}

function getNewSortDir<K>(
  sortKey: CompositeSortKey<K>,
  currSortDir?: CompositeSortDirection,
) {
  const currDir = first(currSortDir);
  // Find next sort control in sequence
  const currSortControlIdx = SORT_CONTROL.findIndex((dir) => dir === currDir);
  const newSortDir =
    SORT_CONTROL[(currSortControlIdx + 1) % SORT_CONTROL.length];

  return getCompositeSortDir(sortKey, newSortDir);
}
