import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { lastStructuredChange } from '@/pages/patients/PatientMedications/utils/medChangeUtils';

type HasAssociatedTitrationUpdates = {
  isLoading: boolean;
  hasAssociatedTitrationUpdates: boolean | undefined;
};

export function useNoteHasAssociatedTitrationUpdates(
  noteId?: number,
  patientId?: string,
): HasAssociatedTitrationUpdates {
  const { data, isLoading } = usePatientMedications(patientId ?? '', {
    enabled: Boolean(patientId && noteId),
  });

  if (!noteId) {
    return { isLoading: false, hasAssociatedTitrationUpdates: false };
  }

  const hasAssociatedTitrationUpdates = data?.referencedMedications?.some(
    (med) => {
      const titration = lastStructuredChange(med)?.asyncTitration;

      return [
        titration?.initialReviewNoteId,
        titration?.consentRequestNoteId,
      ].includes(noteId);
    },
  );

  return { isLoading, hasAssociatedTitrationUpdates };
}
