import { combineEpics } from 'redux-observable';

import fetchPatientContactsEpic from './fetchPatientContactsEpic';
import labsEpic from './labsEpic';
import patientDetailsEpic from './patientDetailsEpic';

export const patientDetailsEpics = combineEpics(
  patientDetailsEpic,
  labsEpic,
  fetchPatientContactsEpic,
);
