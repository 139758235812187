import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { useDeleteTask } from '@/shared/hooks/queries/tasks.queries';
import type { Task } from '@/shared/tasking/types';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

type Props = {
  open: boolean;
  task: Task;
  onClose: () => void;
};

export function DismissTaskModal({ open, task, onClose }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();

  const { isLoading: isSaving, mutate: deleteTask } = useDeleteTask(task, {
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to dismiss task' }),
      ),
    onSuccess: () => {
      toaster.success(intl.formatMessage({ defaultMessage: 'Task dismissed' }));
      onClose();
    },
  });
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Are you sure you want to dismiss this task?',
        })}
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="This task will be removed from the assignee's Task Hub." />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          type="submit"
          isProcessing={isSaving}
          onPress={() => deleteTask()}
        >
          <FormattedMessage defaultMessage="Dismiss Task" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
