{{#if deviceTypes}}
  {{#ifContains deviceTypes "BLOOD_PRESSURE_METER" }}
    {{#with bloodPressure}}
- **BP** | _30-Day Avg:_ {{bp systolic.avg30d diastolic.avg30d}} | _7-Day Avg:_ {{bp systolic.avg7d diastolic.avg7d}} | _30-Day Min:_ {{bp systolic.min30d diastolic.min30d}} | _30-Day Max:_ {{bp systolic.max30d diastolic.max30d}}
    {{/with}}
    {{#with heartRate}}
- **HR** | _30-Day Avg:_ {{hr avg30d}} | _7-Day Avg:_ {{hr avg7d}} | _30-Day Min:_ {{hr min30d}} | _30-Day Max:_ {{hr max30d}}
    {{/with}}
  {{/ifContains}}

  {{#ifContains deviceTypes "WEIGHT_SCALE" }}
    {{#with weight}}
- **Weight** | _30-Day Avg:_ {{weight avg30d}} | _7-Day Avg:_ {{weight avg7d}} | _30-Day Min:_ {{weight min30d}} | _30-Day Max:_ {{weight max30d}}
    {{/with}}
  {{/ifContains}}

  {{#ifContains deviceTypes "BLOOD_GLUCOSE_METER" }}
    {{#with bloodGlucose}}
- **Blood Glucose** | _30-Day Avg:_ {{bg avg30d}} | _7-Day Avg:_ {{bg avg7d}} | _30-Day Min:_ {{bg min30d}} | _30-Day Max:_ {{bg max30d}}
    {{/with}}
  {{/ifContains}}
{{else}}
No assigned devices found
{{/if}}
