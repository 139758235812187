import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { generalAssessmentAndPlanModule } from './__shared__/assessmentAndPlanModule';
import { patientNotesModule } from './__shared__/patientNotesModule';
import { chf } from './chf';

export const SHARED_PROGRAM_MODULES = {
  ...patientNotesModule,
  ...generalAssessmentAndPlanModule,
};

export const regularNpVisit = {
  [TypeOfEncounter.NP_VISIT]: {
    ...chf,
    [ConditionProgram.Hypertension]: { ...SHARED_PROGRAM_MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...SHARED_PROGRAM_MODULES },
    [ConditionProgram.T2DAndHTN]: { ...SHARED_PROGRAM_MODULES },
  },
};
