import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { compose } from '@/deprecated/recompose';
import BackButtonIcon from '@/shared/assets/icons/BackButtonIcon';
import TextField from '@/shared/common/@deprecated/TextField';
import { emailValidation, required } from '@/shared/common/FormValidation';
import PasswordTextField from '@/shared/common/PasswordTextField';
import { withToaster } from '@/shared/common/withToaster';
import { Button } from '@/shared/tempo/atom/Button';
import { getErrorMsg } from '@/shared/utils/helpers';
import { persistor } from '@/store';
import type { RootState } from '@/store/redux.types';

import { requestLogin } from '../../actionCreators';
import { AuthPageWrapper } from '../shared';
import {
  clear,
  loginFormLink,
  loginFormTitleContent,
  loginPageSections,
  loginTitle,
} from '../shared/LoginPage.css';
import { bigButton } from '../shared/auth.css';
import { backLinkContainer } from './LoginPasswordPage.css';
import type { TLoginPasswordPage } from './TLoginPasswordPage';

export class LoginPasswordPageComponent extends Component<TLoginPasswordPage> {
  constructor(props: TLoginPasswordPage) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(): void {
    const { isAuthenticated, history } = this.props;

    if (isAuthenticated) {
      history.replace('/');
    }
  }

  componentDidUpdate(prevProps: TLoginPasswordPage) {
    const { isAuthenticated, history, location, loginError, toaster } =
      this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      // sometimes we redirect before flushing and it causes us to
      // lose the state. this ensures we flush to local storage before
      // sending the user onward
      persistor.flush().then(() => {
        history.replace(from.pathname);
      });
    }

    if (loginError !== prevProps.loginError && loginError !== null) {
      toaster?.error(getErrorMsg(loginError));
    }
  }

  submit(values: { email: string; password: string }) {
    const { dispatchRequestLogin } = this.props;

    dispatchRequestLogin(values.email, values.password);
  }

  render() {
    const { pristine, submitting, handleSubmit } = this.props;

    return (
      <AuthPageWrapper>
        <div className={backLinkContainer}>
          <Link className={loginFormLink} to="/login">
            <BackButtonIcon />
          </Link>
        </div>
        <div className={loginTitle}>
          <FormattedMessage defaultMessage="Sign in" />
          <div className={clear} />
          <div className={loginFormTitleContent}>
            <FormattedMessage defaultMessage="Enter your account information." />
          </div>
        </div>
        <form
          data-testid="login-password--form"
          onSubmit={handleSubmit(this.submit)}
        >
          <div>
            <Field
              name="email"
              component={TextField}
              placeholder="Company email"
              type="email"
              validate={[required, emailValidation]}
              fullWidth
            />
          </div>
          <div className={loginPageSections}>
            <Field
              name="password"
              component={PasswordTextField}
              placeholder="Your password"
              validate={[required]}
              fullWidth
            />
          </div>
          <div className={loginPageSections}>
            <Button
              id="login-password--form-submit"
              variant="primary"
              type="submit"
              isDisabled={pristine || submitting}
              className={bigButton}
            >
              <FormattedMessage defaultMessage="Log in" />
            </Button>
          </div>
          <div className={loginPageSections}>
            <Link className={loginFormLink} to="/forgot-password">
              <FormattedMessage defaultMessage="Forgot password?" />
            </Link>
          </div>
        </form>
      </AuthPageWrapper>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    email: state.auth.email || '',
  },
  isAuthenticated: state.auth.isAuthenticated,
  loginError: state.auth.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchRequestLogin: (email: string, password: string) => {
    dispatch(requestLogin(email, password));
  },
});

const enhance = compose(
  withToaster,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'login-password--form',
    enableReinitialize: true,
  }),
);

export const LoginPasswordPage = enhance(LoginPasswordPageComponent);
