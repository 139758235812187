import parseISO from 'date-fns/parseISO';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatProviderFullName } from '@/pages/adminPanel/patient-profile/tabs/UtilityTab/ExternalDetail/utils';
import {
  getCodesFromRPMOrder,
  sortOrders,
} from '@/pages/adminPanel/patient-profile/tabs/UtilityTab/RpmOrderDetail/utils';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Table } from '@/shared/common/Table';
import type { CareProvider } from '@/shared/generated/grpcGateway/cadence/models/models.pb';
import {
  CareProviderRole,
  CareProviderStatus,
} from '@/shared/generated/grpcGateway/cadence/models/models.pb';
import type { RPMOrder } from '@/shared/generated/grpcGateway/pms.pb';
import {
  PatientExternalProviderType,
  RPMOrderOrderStatus,
} from '@/shared/generated/grpcGateway/pms.pb';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useOrderStatusI18n } from '@/shared/i18n';
import { useCareProviderRoleI18nMapping } from '@/shared/provider';
import { useCareProviderStatusI18nMapping } from '@/shared/provider/useProviderStatusI18nMapping';
import { arrayNotEmpty } from '@/shared/utils/array';

import { SubHeader } from '../../SubHeader';
import { referringProviderText } from './Orders.css';

type Props = {
  patientId: string;
};

export function OrdersReceivedOrders({ patientId }: Props) {
  const { isSuccess: loadedPatient, data: patientDetails } = usePatientDetails(
    patientId,
    true,
  );

  const referringProvider = patientDetails?.externalCareProviders?.find(
    ({ careProviderType }) =>
      careProviderType === PatientExternalProviderType.REFERRING,
  );

  return (
    <LoadingPlaceholder isLoading={!loadedPatient}>
      {loadedPatient && arrayNotEmpty(patientDetails?.rpmOrders) ? (
        <RpmOrderDetails
          rpmOrders={patientDetails.rpmOrders}
          referringProvider={referringProvider?.careProvider}
        />
      ) : (
        <FormattedMessage defaultMessage="No RPM orders found for patient." />
      )}
    </LoadingPlaceholder>
  );
}

type RpmOrderDetailProps = {
  rpmOrders: RPMOrder[];
  referringProvider: Maybe<CareProvider>;
};

export function RpmOrderDetails({
  rpmOrders,
  referringProvider,
}: RpmOrderDetailProps) {
  return (
    <>
      <SubHeader>
        <FormattedMessage defaultMessage="Received Orders" />
      </SubHeader>
      <div className={referringProviderText}>
        <FormattedMessage defaultMessage="Referring Provider: " />
        <span>{formatProviderFullName(referringProvider, 'N/A')}</span>
      </div>
      <RPMOrderTable rpmOrders={rpmOrders} />
    </>
  );
}

function getColumnHeaders(intl: IntlShape) {
  return [
    { title: intl.formatMessage({ defaultMessage: 'Status' }) },
    { title: intl.formatMessage({ defaultMessage: 'Applied' }) },
    { title: intl.formatMessage({ defaultMessage: 'Time Received' }) },
    { title: intl.formatMessage({ defaultMessage: 'ICD-10 Codes' }) },
    { title: intl.formatMessage({ defaultMessage: 'Care Provider' }) },
  ];
}

type RpmOrderTableProps = {
  rpmOrders: RPMOrder[];
};

function RPMOrderTable({ rpmOrders }: RpmOrderTableProps) {
  const intl = useIntl();
  const columnHeaders = getColumnHeaders(intl);
  const colSpan = columnHeaders.length;
  const sortedOrders = sortOrders(rpmOrders);
  return (
    <Table.Container>
      <Table>
        <Table.Header columns={columnHeaders} />
        <Table.Body>
          {sortedOrders.length > 0 ? (
            sortedOrders.map((order) => (
              <RpmOrderTableRow key={order.id} order={order} />
            ))
          ) : (
            <Table.Row>
              <Table.NodeCell colSpan={colSpan}>
                <FormattedMessage defaultMessage="No RPM Orders found for patient" />
              </Table.NodeCell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Table.Container>
  );
}

type RpmOrderRowProps = {
  order: RPMOrder;
};

function RpmOrderTableRow({ order }: RpmOrderRowProps) {
  const intl = useIntl();
  const careProviderStatusMap = useCareProviderStatusI18nMapping();
  const careProviderRoleMap = useCareProviderRoleI18nMapping();
  const orderStatusMap = useOrderStatusI18n();
  return (
    <Table.Row>
      <Table.TextCell>
        {
          orderStatusMap[
            order.status || RPMOrderOrderStatus.ORDER_STATUS_UNSPECIFIED
          ]
        }
      </Table.TextCell>
      <Table.TextCell>
        {order.applied
          ? intl.formatMessage({ defaultMessage: 'Yes' })
          : intl.formatMessage({ defaultMessage: 'No' })}
      </Table.TextCell>
      <Table.TextCell>
        {parseISO(order.ehrCreatedAt || '').toLocaleString()}
      </Table.TextCell>
      <Table.TextCell>{getCodesFromRPMOrder(order)}</Table.TextCell>
      <Table.NodeCell>
        <div>{formatProviderFullName(order.careProvider, 'N/A')}</div>
        {
          careProviderRoleMap[
            order.careProvider?.role || CareProviderRole.ROLE_UNSPECIFIED
          ]
        }
        <div>
          {
            careProviderStatusMap[
              order.careProvider?.status ||
                CareProviderStatus.STATUS_UNSPECIFIED
            ]
          }
        </div>
      </Table.NodeCell>
    </Table.Row>
  );
}
