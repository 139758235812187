import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import { FormattedMessage, useIntl } from 'react-intl';

import { BillableTimeGuidanceLink } from '@/pages/patients/patientDetails/ui/shared';
import { EditableMessage } from '@/pages/patients/patientDetails/ui/shared/EditableMessage';
import { getIsActiveEditableTimeTrackingEntry } from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import {
  useMonitoringSessionDetailsNoRefetch,
  useMonitoringSessionNotesNoRefetch,
} from '@/shared/hooks/queries';
import { EntryType } from '@/shared/types/monitoringSession.types';

import type { TimeTrackingFormFields } from '../../ManualTimeTrackingForm';
import {
  ManualTimeTrackingForm,
  useTimeTrackingFormConfig,
} from '../../ManualTimeTrackingForm';
import { OldTimeEntryEditWarning } from '../../ManualTimeTrackingForm/shared/OldTimeEntryEditWarning';
import { SmartEncountersNoteLink } from '../../SmartEncountersNoteLink';
import { contentContainer, subHeading } from '../styles.css';
import { ActionsFooter } from './ActionsFooter';
import { guidanceLink } from './MutateEntryModal.css';

export type Props = {
  entryId?: string;
  onClose: () => void;
  onDelete?: () => void;
};

export function CreateEntryModal({ onClose, onDelete, entryId }: Props) {
  const intl = useIntl();
  const { data: timeEntry, isFetching: isFetchingTimeEntry } =
    useMonitoringSessionDetailsNoRefetch(entryId || '', {
      enabled: Boolean(entryId),
    });
  const { data: notes, isFetching: isFetchingNotes } =
    useMonitoringSessionNotesNoRefetch(entryId || '', {
      enabled: Boolean(entryId),
    });
  const timeEntryNote = first(sortBy(notes?.data, 'created_at'));

  const config = useTimeTrackingFormConfig(
    timeEntry
      ? { action: 'update', timeEntry, note: timeEntryNote }
      : { action: 'create', timeEntry: null, entryType: EntryType.Manual },
  );
  const form = useFormFromConfig<TimeTrackingFormFields>({
    ...config,
    triggerReset: !isFetchingTimeEntry && !isFetchingNotes,
  });
  const isUpdate = Boolean(entryId);
  const isLateEdit =
    isUpdate && timeEntry && !getIsActiveEditableTimeTrackingEntry(timeEntry);

  return (
    <Modal
      open
      size="large"
      onClose={onClose}
      isLoading={isFetchingNotes || isFetchingTimeEntry}
    >
      <Modal.Header
        title={
          isUpdate
            ? intl.formatMessage({ defaultMessage: 'Edit Time Record' })
            : intl.formatMessage({ defaultMessage: 'Record Time' })
        }
      />
      <Modal.Body>
        <div className={subHeading}>
          {isUpdate ? (
            <EditableMessage />
          ) : (
            <FormattedMessage
              defaultMessage="Add time for activity not appropriate for an Encounter Note. For encounters such as follow-ups, alert resolutions, and visits, please use the {encountersLink} to log time."
              values={{
                encountersLink: <SmartEncountersNoteLink onClick={onClose} />,
              }}
            />
          )}
        </div>
        <div className={guidanceLink}>
          <BillableTimeGuidanceLink />
        </div>
        <div className={contentContainer}>
          {timeEntry &&
            timeEntryNote &&
            typeof timeEntry.interactive_duration !== 'number' && (
              <OldTimeEntryEditWarning
                timeEntry={timeEntry}
                timeEntryNote={timeEntryNote}
              />
            )}
          <ManualTimeTrackingForm
            form={form}
            {...(timeEntry
              ? { action: 'update', timeEntry }
              : { action: 'create', entryType: EntryType.Manual })}
          />
        </div>
      </Modal.Body>
      <ActionsFooter
        form={form}
        entryId={entryId}
        isLateEdit={isLateEdit}
        onClose={onClose}
        onDelete={onDelete}
      />
    </Modal>
  );
}
