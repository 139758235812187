import type { LogEntry } from '../logger.types';
import type { LogEmitter } from './LogEmitter';

export class ConsoleEmitter implements LogEmitter {
  private readonly emitter: Console = console;

  debug(entry: LogEntry): void {
    this.emitter.debug(format(entry), entry.metadata);
  }

  info(entry: LogEntry): void {
    this.emitter.info(format(entry), entry.metadata);
  }

  warn(entry: LogEntry): void {
    this.emitter.warn(format(entry), entry.metadata);
  }

  error(entry: LogEntry, error?: Error): void {
    if (error) {
      this.emitter.error(format(entry), entry.metadata, '\n\n', error);
    } else {
      this.emitter.error(format(entry), entry.metadata);
    }
  }
}

function format(entry: LogEntry) {
  return `[${entry.level.toUpperCase()}]: ${entry.message}`;
}
