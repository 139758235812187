import cx from 'classnames';
import type { ReactNode } from 'react';
import { Children, cloneElement, forwardRef, isValidElement } from 'react';

import { buttonIcon } from './ButtonIcon.css';

type Props = {
  className?: string;
  children: ReactNode;
};

const DEFAULT_DIMENSIONS = {
  height: '18px',
  width: '18px',
};

export const ButtonIcon = forwardRef<HTMLSpanElement, Props>(
  ({ className, children }, ref) => {
    // Renders the svg with a default fill of inherit
    const svgWithDefaultFill = Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          fill: 'inherit',
          ...DEFAULT_DIMENSIONS,
          ...child.props,
        });
      }
      return child;
    });

    return (
      <span ref={ref} className={cx(className, buttonIcon)}>
        {svgWithDefaultFill}
      </span>
    );
  },
);
