export enum Mode {
  Create,
  Update,
}

export enum TabLink {
  Profile = '/profile',
  Clinic = '/hospitals',
  Providers = '/providers',
  Caregivers = '/caregivers',
  Conditions = '/conditions',
  Consent = '/consent',
  Devices = '/devices',
  Utility = '/utility',
}

export function getOrdersPath(patientId: string) {
  return getTabPath(patientId, TabLink.Devices);
}

export function getConditionsPath(patientId: string) {
  return getTabPath(patientId, TabLink.Conditions);
}

export function getTabPath(patientId: string, tabLink: TabLink) {
  return `/admin/patient/${patientId}${tabLink}`;
}
