import { SharedVitalProgressIntro } from '../shared/VitalProgress/VitalProgressIntro';
import { SharedVitalRecommendation } from '../shared/VitalProgress/VitalRecommendation';
import { EducationModulesReview } from './actionPlan';
import { ChartPrep } from './chartPrep';
import { EmergencyVisits } from './emergencyVisits';
import { EndCall } from './endCall';
import { DailyLiving, Diet, Exercise, TobaccoUse } from './generalAssessment';
import { IntroConfirmData, IntroPatientAttendance } from './introduction';
import { MedReview } from './medReview';
import { LowDeviceUsage } from './productSupport';
import { DeviceUsage } from './productSupport/DeviceUsage';
import { Feedback } from './productSupport/Feedback';
import { Intro } from './productSupport/Intro';
import { Scheduling } from './scheduling';
import { Symptoms } from './symptoms';
import { SecondarySymptoms } from './symptoms/SecondarySymptoms';
import { TimeTracking } from './timeTracking';

export const CHF_VISIT_ROUTES = {
  '/chart-prep/index': ChartPrep,
  '/intro/patient-attendance': IntroPatientAttendance,
  '/intro/patient-identification': IntroConfirmData,
  '/symptoms/index': Symptoms,
  '/symptoms/secondary': SecondarySymptoms,
  '/emergency-visits/index': EmergencyVisits,
  '/product-support/feedback': Feedback,
  '/product-support/intro': Intro,
  '/product-support/device-usage': DeviceUsage,
  '/product-support/low-device-usage': LowDeviceUsage,
  '/vital-progress/intro': SharedVitalProgressIntro,
  '/vital-progress/recommendation': SharedVitalRecommendation,
  '/med-review/index': MedReview,
  '/general-assessment/tobacco-use': TobaccoUse,
  '/general-assessment/diet': Diet,
  '/general-assessment/exercise': Exercise,
  '/general-assessment/daily-living': DailyLiving,
  '/action-plan/education-modules-review': EducationModulesReview,
  '/scheduling/index': Scheduling,
  '/time-tracking/index': TimeTracking,
  '/end-call/index': EndCall,
};
