import * as yup from 'yup';

import { validator } from './factory';

export type RequiredParams = {
  schema: yup.AnySchema;
  errorMessage?: string;
  when?: () => boolean;
};
/**
 * Validator for marking yup schemas as required
 */
export const required = validator(
  (intl, schemaOrParams: yup.AnySchema | RequiredParams) => {
    const schema =
      'schema' in schemaOrParams ? schemaOrParams.schema : schemaOrParams;
    const errorMessage =
      'errorMessage' in schemaOrParams
        ? schemaOrParams.errorMessage
        : intl.formatMessage({ defaultMessage: 'Required' });

    const shouldRequireFn =
      'schema' in schemaOrParams && schemaOrParams.when
        ? schemaOrParams.when
        : // Require by default
          () => true;

    if (shouldRequireFn()) {
      return schema.required(errorMessage);
    }
    return schema;
  },
);

type MaxLengthParams = { maxLength: number; errorMessage?: string };
/**
 * Validator for ensuring a string does not exceed provided length
 * @param: maxLength, the maximum length for the string
 */
export const maxLengthString = validator((intl, params: MaxLengthParams) =>
  yup.string().test(
    'len',
    params.errorMessage ??
      intl.formatMessage(
        {
          defaultMessage:
            'May not exceed {maxLength} {maxLength, plural, one {character} other {characters}}',
        },
        {
          maxLength: params.maxLength,
        },
      ),
    (value) => (value?.length || 0) <= params.maxLength,
  ),
);
