import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { logger } from '@/logger';
import type { QueryOptions } from '@/reactQuery/types';
import type {
  DenyMedicationRequest,
  NewMedicationChangeRequest,
  PatientMedications,
  ReviewMedicationRequest,
  StopMedicationRequest,
  StructureMedicationRequest,
} from '@/shared/generated/grpcGateway/medication.pb';
import { MedicationService } from '@/shared/generated/grpcGateway/medication.pb';
import { GRPC_PATIENT_QUERY_KEY_BASE } from '@/shared/hooks/queries';
import type { RouteParam } from '@/shared/types/route.types';
import { idToGrpcName } from '@/shared/utils/grpc';

import { usePatientMedicationsContext } from './PatientMedicationsContext';

export const medicationsKeys = {
  patientMedications: (patientId: string) =>
    [...GRPC_PATIENT_QUERY_KEY_BASE, patientId, 'medications'] as const,
};

export function usePatientMedications(
  patientId: string,
  config: QueryOptions<PatientMedications> = {},
) {
  return useQuery(
    [
      ...medicationsKeys.patientMedications(patientId),
      config.enabled === false ? 'disabled' : '',
    ],
    () =>
      MedicationService.GetPatientMedications({
        name: idToGrpcName('patients', patientId, 'medications'),
      }),
    {
      ...config,
    },
  );
}

type StructureMedicationPayload = Omit<
  StructureMedicationRequest,
  'medicationChange'
>;

export function useStructureMedication(
  medChangeId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    (payload: StructureMedicationPayload) =>
      MedicationService.StructureMedication({
        medicationChange: idToGrpcName('medication', medChangeId),
        ...payload,
      }),
    {
      onSuccess: () => {
        onSuccess();

        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useReviewMedication(onSuccess?: () => void) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();
  const { patientId: patientIdFromRoute }: RouteParam = useParams();

  return useMutation(
    (payload: ReviewMedicationRequest) => {
      // For preventing https://linear.app/cadencerpm/issue/CARE-432/medication-changes-for-two-patients-combined until we figure out root cause
      if (patientIdFromRoute && patientId !== patientIdFromRoute) {
        logger.error(
          `Med change patient id ${patientId} does not match route patient id ${patientIdFromRoute}`,
        );
        throw Error('Med change patient id does not match route patient id');
      }
      return MedicationService.ReviewMedication({
        ...payload,
        patientId,
      });
    },
    {
      onSuccess: () => {
        onSuccess?.();

        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useDenyMedication(medChangeId: string, onSuccess: () => void) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    (payload: DenyMedicationRequest) =>
      MedicationService.DenyMedication({
        medicationChange: idToGrpcName('medication', medChangeId),
        ...payload,
      }),
    {
      onSuccess: () => {
        onSuccess();
        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useVerifyMedication(
  medChangeId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    () =>
      MedicationService.AcceptMedication({
        medicationChange: idToGrpcName('medication', medChangeId),
      }),
    {
      onSuccess: () => {
        onSuccess();

        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useUndoMarkInactive(
  medChangeId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    () =>
      MedicationService.UndoMedicationChange({
        medicationChange: idToGrpcName('medication', medChangeId),
      }),
    {
      onSuccess: () => {
        onSuccess();
        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useTitrateMedication(onSuccess: () => void) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    (payload: NewMedicationChangeRequest) =>
      MedicationService.TitrateMedication({
        ...payload,
        patientId,
      }),
    {
      onSuccess: () => {
        onSuccess();
        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useStartMedication() {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    (payload: NewMedicationChangeRequest) =>
      MedicationService.StartMedication({
        ...payload,
        patientId,
      }),
    {
      onSuccess: () => {
        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useStopMedication(onSuccess: () => void) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    (payload: StopMedicationRequest) =>
      MedicationService.StopMedication({
        ...payload,
        patientId,
      }),
    {
      onSuccess: () => {
        onSuccess();
        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}

export function useDeleteMedicationChange(
  medChangeId: string,
  onSuccess: () => void,
) {
  const queryClient = useQueryClient();
  const { patientId } = usePatientMedicationsContext();

  return useMutation(
    () =>
      MedicationService.DeleteMedicationChange({
        medicationChange: idToGrpcName('medication', medChangeId),
      }),
    {
      onSuccess: () => {
        onSuccess();
        if (patientId) {
          queryClient.invalidateQueries(
            medicationsKeys.patientMedications(patientId),
          );
        }
      },
    },
  );
}
