import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef, useRef } from 'react';
import type { AriaPopoverProps } from 'react-aria';
import { Overlay, usePopover } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import { popover, underlay } from './Popover.css';

type Props = {
  className?: string;
  classes?: {
    underlay?: string;
  };
  children: ReactNode;
  state: OverlayTriggerState;
} & Omit<AriaPopoverProps, 'popoverRef'>;

export const Popover = forwardRef<HTMLDivElement, Props>(
  ({ className, classes, children, state, ...props }, forwardedRef) => {
    const defaultRef = useRef(null);
    const ref = useObjectRef(forwardedRef);
    const popoverRef = ref || defaultRef;
    const { popoverProps, underlayProps } = usePopover(
      { ...props, popoverRef },
      state,
    );

    // React-aria's Overlay will be portaled outside of MUI's Dialog.
    // Since MUI Dialog has disableEnforceFocus=false, it will block focus of
    // any element outside itself. So here we ensure that if a modal is rendered, we
    // portal inside it
    // TODO: We should be able to remove this once we've replaced MUI Dialogs with react-aria/tempo
    const muiModalContainer =
      document.querySelector(`.MuiDialog-container`) || undefined;

    return (
      <Overlay portalContainer={muiModalContainer}>
        <div {...underlayProps} className={cx(underlay, classes?.underlay)} />
        <div
          {...popoverProps}
          ref={popoverRef}
          className={cx(popover, className)}
        >
          {children}
        </div>
      </Overlay>
    );
  },
);
