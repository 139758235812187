import * as yup from 'yup';

import { validator } from './factory';

/**
 * Validator for ensuring value is a valid email address.
 */
export const emailValidator = validator((intl) => {
  const errorMessage = intl.formatMessage({
    defaultMessage: 'Must be a valid email',
  });

  return yup.string().email(errorMessage);
});
