import invert from 'lodash/invert';

import type { CarePlan } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { CCMCondition as GrpcCondition } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { dateToGrpcDate, parseGrpcDate } from '@/shared/utils/grpc';

import type { FormFields } from './formConfig';
import {
  getAssessmentFieldNames,
  getDefaultGoalsAndInterventions,
  getPreventativeCareFieldNames,
} from './formConfig';
import { PREVENTATIVE_CARE_OPTIONS } from './sections/preventativeCareOptions';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './sections/psychoSocialAssessmentOptions';

export function marshalFormDataToCarePlan(data: FormFields): CarePlan {
  return {
    diet: {
      fastFood: data.fastFood,
      snacks: data.snacks,
      desserts: data.desserts,
      regularSoda: data.regularSoda,
      sweetTea: data.sweetTea,
      waterIntake: data.waterIntake,
      saltIntake: data.saltIntake,
    },
    exercise: data.exercise,
    socialHistory: {
      tobaccoUse: data.tobaccoUse,
    },
    emergencyVisits: data.emergencyVisits?.map(({ date, ...rest }) => ({
      ...rest,
      ...(date && {
        date: dateToGrpcDate(date),
      }),
    })),
    preventativeCare: PREVENTATIVE_CARE_OPTIONS.map(({ name }) => {
      const { checked, date, nextSteps, notApplicable } =
        getPreventativeCareFieldNames(name);

      return {
        name,
        enabled: !!data[checked],
        notApplicable: !!data[notApplicable],
        date: data[date] as string,
        nextSteps: data[nextSteps] as string,
      };
    }),
    surgicalHistories: data.surgicalHistories,
    specialists: data.specialists,
    medsNotReviewed: data.medsNotReviewed,
    medicationAllergies: data.medicationAllergies,
    goalsAndInterventions: data.goalsAndInterventions
      ?.map(({ condition, ...rest }) => ({
        ...rest,
        condition:
          FALCON_CONDITION_TO_GRPC_CONDITION[condition as CcmCondition] ||
          GrpcCondition.CCM_CONDITION_UNSPECIFIED,
      }))
      .filter(
        ({ condition }) =>
          condition !== GrpcCondition.CCM_CONDITION_UNSPECIFIED,
      ),
    psychosocialAssessments: PSYCHOSOCIAL_ASSESSMENT_OPTIONS.map(({ name }) => {
      const { checked, description } = getAssessmentFieldNames(name);

      return {
        name,
        enabled: !!data[checked],
        description: data[description] as string,
      };
    }),
    followUp: {
      ccmAppointment: data.ccmAppointment,
      providerAppointment: data.providerAppointment,
    },
  };
}

export function marshalCarePlanToFormData(data: CarePlan): FormFields {
  return {
    fastFood: data.diet?.fastFood || '',
    snacks: data.diet?.snacks || '',
    desserts: data.diet?.desserts || '',
    regularSoda: data.diet?.regularSoda || '',
    sweetTea: data.diet?.sweetTea || '',
    waterIntake: data.diet?.waterIntake || '',
    saltIntake: data.diet?.saltIntake || '',
    exercise: data.exercise || '',
    tobaccoUse: data.socialHistory?.tobaccoUse || '',
    emergencyVisits: data.emergencyVisits?.map(({ date, ...rest }) => ({
      ...rest,
      date: date ? parseGrpcDate(date) : '',
    })),
    surgicalHistories: data.surgicalHistories,
    specialists: data.specialists,
    medsNotReviewed: data.medsNotReviewed ?? false,
    medicationAllergies: data.medicationAllergies,
    goalsAndInterventions:
      data.goalsAndInterventions?.map(({ condition, ...rest }) => ({
        ...rest,
        condition:
          GRPC_CONDITION_TO_FALCON_CONDITION[condition as GrpcCondition] ||
          Condition.Unspecified,
      })) ?? getDefaultGoalsAndInterventions(null),
    ...PREVENTATIVE_CARE_OPTIONS.reduce((acc, { name }) => {
      const { checked, date, nextSteps, notApplicable } =
        getPreventativeCareFieldNames(name);
      const preventativeCare = data.preventativeCare?.find(
        (pc) => pc.name === name,
      );

      return {
        ...acc,
        [checked]: preventativeCare?.enabled || false,
        [notApplicable]: preventativeCare?.notApplicable || false,
        [nextSteps]: preventativeCare?.nextSteps || '',
        [date]: preventativeCare?.date || '',
      };
    }, {}),
    ...PSYCHOSOCIAL_ASSESSMENT_OPTIONS.reduce((acc, { name }) => {
      const { checked, description } = getAssessmentFieldNames(name);
      const psychosocialAssessment = data.psychosocialAssessments?.find(
        (pa) => pa.name === name,
      );

      return {
        ...acc,
        [checked]: psychosocialAssessment?.enabled || false,
        [description]: psychosocialAssessment?.description || '',
      };
    }, {}),
    ccmAppointment: data.followUp?.ccmAppointment || '',
    providerAppointment: data.followUp?.providerAppointment || '',
  };
}

export const FALCON_CONDITION_TO_GRPC_CONDITION: Record<
  CcmCondition,
  GrpcCondition
> = {
  [Condition.CHF]: GrpcCondition.CHF,
  [Condition.Hypertension]: GrpcCondition.HYPERTENSION,
  [Condition.COPD]: GrpcCondition.COPD,
  [Condition.TypeTwoDiabetes]: GrpcCondition.TYPE2_DIABETES,
  [Condition.Asthma]: GrpcCondition.ASTHMA,
  [Condition.AfibAndFlutter]: GrpcCondition.AFIB_AND_FLUTTER,
  [Condition.ChronicKidneyDisease]: GrpcCondition.CHRONIC_KIDNEY_DISEASE,
  [Condition.Hyperlipidemia]: GrpcCondition.HYPERLIPIDEMIA,
  [Condition.Hypothyroidism]: GrpcCondition.HYPOTHYROIDISM,
  [Condition.IschemicHeartDisease]: GrpcCondition.ISCHEMIC_HEART_DISEASE,
  [Condition.MorbidObesity]: GrpcCondition.MORBID_OBESITY,
  [Condition.ObstructiveSleepApnea]: GrpcCondition.OBSTRUCTIVE_SLEEP_APNEA,
  [Condition.Osteoarthritis]: GrpcCondition.OSTEOARTHRITIS,
  [Condition.PeripheralArteryDisease]: GrpcCondition.PERIPHERAL_ARTERY_DISEASE,
  [Condition.Dementia]: GrpcCondition.DEMENTIA,
};

export const GRPC_CONDITION_TO_FALCON_CONDITION = invert(
  FALCON_CONDITION_TO_GRPC_CONDITION,
) as Record<GrpcCondition, CcmCondition>;
