import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const NumberListIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2438 8.30005H10.7562C10.3388 8.30005 10 7.96405 10 7.55005C10 7.13605 10.3388 6.80005 10.7562 6.80005L19.2438 6.80005C19.6612 6.80005 20 7.13605 20 7.55005C20 7.96405 19.6612 8.30005 19.2438 8.30005Z"
        fill="#3B3A45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2438 13.3H10.7562C10.3388 13.3 10 12.964 10 12.55C10 12.136 10.3388 11.8 10.7562 11.8L19.2438 11.8C19.6612 11.8 20 12.136 20 12.55C20 12.964 19.6612 13.3 19.2438 13.3Z"
        fill="#3B3A45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2438 18.3H10.7562C10.3388 18.3 10 17.964 10 17.55C10 17.136 10.3388 16.8 10.7562 16.8L19.2438 16.8C19.6612 16.8 20 17.136 20 17.55C20 17.964 19.6612 18.3 19.2438 18.3Z"
        fill="#3B3A45"
      />
      <path
        d="M5.94957 5.36364H5.13459L4.23793 5.92116V6.68111L5.05114 6.18395H5.07244V9H5.94957V5.36364Z"
        fill="#3B3A45"
      />
      <path
        d="M4.26811 14H6.95987V13.2915H5.48615V13.2685L5.92116 12.8672C6.70952 12.18 6.91548 11.8303 6.91548 11.413C6.91548 10.7578 6.38104 10.3139 5.5554 10.3139C4.75107 10.3139 4.21307 10.7773 4.21484 11.5178H5.04759C5.04759 11.1893 5.25 10.9993 5.55185 10.9993C5.84837 10.9993 6.06321 11.1822 6.06321 11.4822C6.06321 11.7539 5.89276 11.9386 5.59446 12.2067L4.26811 13.3679V14Z"
        fill="#3B3A45"
      />
      <path
        d="M5.65128 19.0497C6.50355 19.0497 7.10902 18.5987 7.10724 17.9666C7.10902 17.5192 6.81605 17.1996 6.25497 17.1339V17.1055C6.67578 17.0451 6.97408 16.7628 6.9723 16.3544C6.97408 15.7614 6.43786 15.3139 5.65838 15.3139C4.87358 15.3139 4.30185 15.7685 4.29474 16.4148H5.13459C5.14169 16.1662 5.36541 15.9993 5.65838 15.9993C5.93714 15.9993 6.12713 16.168 6.12536 16.4112C6.12713 16.6634 5.90341 16.8374 5.58026 16.8374H5.22692V17.4624H5.58026C5.9478 17.4624 6.1875 17.6435 6.18395 17.8991C6.1875 18.1566 5.96733 18.3359 5.65483 18.3359C5.34411 18.3359 5.11506 18.1708 5.10618 17.9311H4.22195C4.22905 18.5881 4.82031 19.0497 5.65128 19.0497Z"
        fill="#3B3A45"
      />
    </svg>
  </SvgIcon>
);

export default NumberListIcon;
