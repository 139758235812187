import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

import { greetingTitle } from './HomePage.css';

export function Greeting() {
  const intl = useIntl();
  const { currentUser } = useCurrentUser();

  return (
    <div className={greetingTitle}>
      {intl.formatMessage(
        { defaultMessage: 'Good {timeOfDay}, {firstName}' },
        { timeOfDay: getTimeOfDay(intl), firstName: currentUser.given_name },
      )}
    </div>
  );
}

function getTimeOfDay(intl: IntlShape): string {
  const now = new Date();
  const hours = now.getHours();

  if (hours >= 6 && hours < 12) {
    return intl.formatMessage({ defaultMessage: 'morning' });
  }
  if (hours >= 12 && hours < 18) {
    return intl.formatMessage({ defaultMessage: 'afternoon' });
  }
  return intl.formatMessage({ defaultMessage: 'evening' });
}
