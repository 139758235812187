import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import type { IntroFormFields } from '../../shared/IntroPatientAttendance';
import {
  SharedIntroPatientAttendance,
  getIntroductionFormConfig,
} from '../../shared/IntroPatientAttendance';
import { cycle17InitVisitPath } from '../paths';

export function IntroPatientAttendance() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<IntroFormFields>(
    cycle17InitVisitPath('/intro', '/patient-attendance'),
    getIntroductionFormConfig(intl),
  );

  return (
    <SharedIntroPatientAttendance
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      nextPath={cycle17InitVisitPath('/intro', '/contact-info')}
      timeTrackingPath={cycle17InitVisitPath('/time-tracking', '/index')}
    />
  );
}
