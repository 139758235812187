import type {
  QueryParamsFilterLookup,
  QueryParamsOrderByLookup,
} from '../patients-grpc';
import { OpType } from '../patients-grpc';

export const APPOINTMENT_QUERY_PARAMS_ORDER_BY_LOOKUP: QueryParamsOrderByLookup =
  {
    startTime: {
      rename: 'startTime',
      default: true,
    },
    type: {},
  };

export const APPOINTMENT_QUERY_PARAMS_FILTER_LOOKUP: QueryParamsFilterLookup = {
  careProviderId: {
    renames: ['careProviderId'],
    op: OpType.EQUAL,
  },
  patientId: {
    renames: ['patientId'],
    op: OpType.EQUAL,
  },
  apptStartTimeFrom: {
    renames: ['startTime'],
    op: OpType.GREATER_THAN_OR_EQUAL,
  },
  apptStartTimeTo: {
    renames: ['startTime'],
    op: OpType.LESS_THAN_OR_EQUAL,
  },
  excludedApptState: {
    renames: ['state'],
    op: OpType.NOT_EQUAL,
  },
  user: {
    nativeFilterGetter: ({ careProviderId }) => {
      if (careProviderId) {
        return `careProviderId = "${careProviderId}"`;
      }
      return '';
    },
  },
};
