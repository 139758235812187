import { CareModelVersion } from '@/shared/types/featureFlags.types';

import { disenrollment } from '../__shared__/disenrollment';
import { initialCnVisit } from '../__shared__/initialCnVisit';
import { patientEnrollment } from '../__shared__/patientEnrollment';
import { regularCnVisit } from '../__shared__/regularCnVisit';
import { virtualEnrollment } from '../__shared__/virtualEnrollment';
import { welcomeCall } from '../__shared__/welcomeCall';
import { initialNpVisit } from './initialNpVisit';
import { initialRnVisit } from './initialRnVisit';
import { regularNpVisit } from './regularNpVisit';
import { regularRnVisit } from './regularRnVisit';

export const twoDotOhTemplates = {
  [CareModelVersion.V2]: {
    ...initialNpVisit,
    ...regularNpVisit,
    ...initialRnVisit,
    ...regularRnVisit,
    ...initialCnVisit,
    ...regularCnVisit,
    ...patientEnrollment,
    ...virtualEnrollment,
    ...welcomeCall,
    ...disenrollment,
  },
};
