import { FormattedMessage } from 'react-intl';

import { Button } from '@/shared/tempo/atom/Button';

import { republishButton } from './NoteRepublishButton.css';

export function NoteRepublishButton({
  onRepublishToEhrClick,
}: {
  onRepublishToEhrClick: () => void;
}) {
  return (
    <Button
      id="note-republish-confirmation-open"
      variant="primary"
      size="small"
      onPress={onRepublishToEhrClick}
      className={republishButton}
    >
      <FormattedMessage defaultMessage="Republish" />
    </Button>
  );
}
