import merge from 'lodash/merge';

import type { LogEmitter } from './emitters/LogEmitter';
import type {
  LogEntry,
  LogLevelMap,
  LogLevelsType,
  LogMetadata,
} from './logger.types';
import { LogLevels } from './logger.types';

const UNREACHABLE_LOG_LEVEL = Number.POSITIVE_INFINITY;

export class Logger {
  private minLogLevel: number;

  private metadata: LogMetadata | (() => LogMetadata) = {};

  private lazyMetadata: LogMetadata;

  private emitters: LogEmitter[];

  private readonly levels: LogLevelMap = {
    [LogLevels.Debug]: 1,
    [LogLevels.Info]: 2,
    [LogLevels.Warn]: 3,
    [LogLevels.Error]: 4,
  };

  constructor(
    minLogLevel: LogLevelsType,
    emitters: LogEmitter[],
    meta: LogMetadata | (() => LogMetadata) = {},
  ) {
    this.minLogLevel = this.levelToInt(minLogLevel);
    this.emitters = emitters;
    this.metadata = meta;
    this.lazyMetadata = {};
  }

  public debug(message: string, msgMeta?: LogMetadata): void {
    this.emit(LogLevels.Debug, message, msgMeta);
  }

  public info(message: string, msgMeta?: LogMetadata): void {
    this.emit(LogLevels.Info, message, msgMeta);
  }

  public warn(message: string, msgMeta?: LogMetadata): void {
    this.emit(LogLevels.Warn, message, msgMeta);
  }

  public error(message: string, error?: Error, msgMeta?: LogMetadata): void {
    this.emit(LogLevels.Error, message, msgMeta, error);
  }

  public registerMetadata(additionalMeta: Record<string, unknown>) {
    this.lazyMetadata = merge({}, this.lazyMetadata, additionalMeta);
  }

  /* Private Methods */

  private getMetadata() {
    if (typeof this.metadata === 'function') {
      // Function metadata takes precedence over lazy metadata
      return merge({}, this.lazyMetadata, this.metadata());
    }

    // Lazy-registered data takes precedence over static metadata
    return merge({}, this.metadata, this.lazyMetadata);
  }

  private levelToInt(minLogLevel: LogLevelsType): number {
    return this.levels[minLogLevel] || UNREACHABLE_LOG_LEVEL;
  }

  private emit(
    logLevel: LogLevelsType,
    message: string,
    msgMeta?: LogMetadata,
    error?: Error,
  ): void {
    const level = this.levelToInt(logLevel);
    if (level === null || level < this.minLogLevel) return;

    const logEntry: LogEntry = {
      level: logLevel,
      message,
      metadata: {
        ...(msgMeta && { __meta__: msgMeta }),
        ...this.getMetadata(),
        timestamp: new Date().toISOString(),
      },
    };

    // Invoke emitters with log entry
    this.emitters.forEach((emitter) => {
      if (logLevel === LogLevels.Error) {
        emitter.error(logEntry, error);
      } else {
        emitter[logLevel]?.(logEntry);
      }
    });
  }
}
