import type {
  QueryParamsFilterLookup,
  QueryParamsOrderByLookup,
} from '../patients-grpc';
import { OpType } from '../patients-grpc';

export const EPL_QUERY_PARAMS_ORDER_BY_LOOKUP: QueryParamsOrderByLookup = {
  delivery_intended_at: {
    rename: 'deliveryIntendedTime',
    default: true,
  },
  identified_provider_last_name: {
    rename: 'listProviderFamilyName',
  },
  identified_provider_first_name: {
    rename: 'listProviderGivenName',
  },
  author_first_name: {
    rename: 'authorGivenName',
  },
  author_last_name: {
    rename: 'authorFamilyName',
  },
  patient_count: {
    rename: 'size',
  },
  status: {
    rename: 'state',
  },
  delivered_at: {
    rename: 'deliveredTime',
  },
  type: {},
};

export const EPL_QUERY_PARAMS_FILTER_LOOKUP: QueryParamsFilterLookup = {
  delivery_intended_at_start_date: {
    renames: ['deliveryIntendedTime'],
    op: OpType.GREATER_THAN_OR_EQUAL,
  },
  delivery_intended_at_end_date: {
    renames: ['deliveryIntendedTime'],
    op: OpType.LESS_THAN_OR_EQUAL,
  },
  identified_provider_id: {
    renames: ['listProviderId'],
    op: OpType.EQUAL,
  },
  hospital_id: {
    renames: ['hospitalsIds'],
    op: OpType.CONTAINS,
  },
  type: {
    renames: ['type'],
    op: OpType.EQUAL,
  },
  status: {
    renames: ['state'],
    op: OpType.EQUAL,
  },
};
