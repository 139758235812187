import type { FC } from 'react';

import blueCadenceLogo from '@/shared/assets/blueLogoNoPadding.png';
import teamLoginImage from '@/shared/assets/teamLogin.png';
import { useWindowSize } from '@/shared/hooks';

import {
  authPage,
  authPageLeftColumn,
  authPageLeftColumnText,
  authPageMainContentContainer,
  authPageSidebar,
  teamLogo,
} from './AuthPageWrapper.css';

export const AuthPageWrapper: FC = ({ children }) => {
  const size: { width?: number; height?: number } = useWindowSize();

  return (
    <div className={authPage}>
      <div className={authPageSidebar}>
        <div className={authPageLeftColumn}>
          <img src={blueCadenceLogo} alt="cadenceLogoLeftColumn" width={150} />
          <div className={authPageLeftColumnText}>
            The leading remote{' '}
            <strong>monitoring and virtual care platform.</strong>
          </div>
        </div>
        <img
          className={teamLogo}
          src={teamLoginImage}
          alt="teamLogoLeftColumn"
          width={(size?.width || 0) / 3}
        />
      </div>
      <div className={authPageMainContentContainer}>{children}</div>
    </div>
  );
};
