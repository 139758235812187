import { useIntl } from 'react-intl';

import { ConfirmationDialog } from './ConfirmationDialog';

export function ReplaceDraftNoteConfirmationDialog({
  saveNewDraftNote,
  isNewDraftNoteOpen,
  closeNewDraftNoteDialog,
}: {
  isNewDraftNoteOpen: boolean;
  closeNewDraftNoteDialog: () => void;
  saveNewDraftNote: () => void;
}) {
  const intl = useIntl();

  const createNewDraftNote = () => {
    saveNewDraftNote();
    closeNewDraftNoteDialog();
  };

  return (
    <ConfirmationDialog
      isOpen={isNewDraftNoteOpen}
      onCancel={closeNewDraftNoteDialog}
      onConfirm={createNewDraftNote}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Replace draft',
      })}
      dialogTitle={intl.formatMessage({
        defaultMessage: 'Replace current draft',
      })}
      dialogDescription={intl.formatMessage({
        defaultMessage: 'Are you sure you want to replace your current draft?',
      })}
    />
  );
}
