import type { Provider } from '@/shared/types/provider.types';

export function formatProviderFullName(provider: Maybe<Provider>) {
  return provider ? `${provider.last_name} ${provider.first_name}` : null;
}

export function getProviderLabel(provider: Provider) {
  return [`${provider.first_name} ${provider.last_name}`, provider.role]
    .filter(Boolean)
    .join(', ');
}
