import type { DOMAttributes } from '@react-types/shared';
import { type ReactNode, useEffect } from 'react';
import { usePress } from 'react-aria';
import { useLocation } from 'react-router-dom';
import {
  type OverlayTriggerState,
  useOverlayTriggerState,
} from 'react-stately';

import type { Props as TrayOverlayProps } from './TrayOverlay';
import { TrayOverlay } from './TrayOverlay';

type RenderTrigger = (props: {
  pressProps: DOMAttributes;
  state: OverlayTriggerState;
}) => ReactNode;

type RenderContent = (props: { state: OverlayTriggerState }) => ReactNode;

type Props = {
  children?: ReactNode | RenderTrigger;
  content: ReactNode | RenderContent;
  onOpenChange: (isOpen: boolean) => void;
  isOpen?: boolean;
} & Pick<TrayOverlayProps, 'position'>;

export function SidebarTray({
  children,
  content,
  onOpenChange,
  position,
  isOpen,
}: Props) {
  const state = useOverlayTriggerState({ isOpen, onOpenChange });

  // we have to use react-aria's `usePress` instead of a standard `onClick` handler
  // because the `useOverlayTrigger` hook will close the overlay when the user clicks,
  // but then our button also triggers and reopens the overlay. `usePress` is aware
  // of what react-aria is doing behind the scenes and avoids this behavior.
  const { pressProps } = usePress({
    onPress() {
      state.toggle();
    },
  });

  const location = useLocation();

  useEffect(() => {
    state.close();
    // we don't want to run this when (if?) `state` changes, we
    // only want to close the overlay when the user navigates away
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {typeof children === 'function'
        ? children({ pressProps, state })
        : children}
      {state.isOpen && (
        <TrayOverlay state={state} position={position}>
          {typeof content === 'function' ? content({ state }) : content}
        </TrayOverlay>
      )}
    </>
  );
}
