import type { PatientDetailState } from './patientDetail.type';

const initialState: PatientDetailState = {
  status: {
    isProcessing: true,
    isSuccess: false,
  },
  error: null,
};

export default initialState;
