import type { ReactNode } from 'react';

import { fullHeightContainer } from './EmptyState.css';

interface Props {
  image: ReactNode;
  children?: ReactNode;
}

export function SimpleEmptyState({ image, children }: Props) {
  return (
    <div className={fullHeightContainer}>
      {image}
      {children}
    </div>
  );
}
