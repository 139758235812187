import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { scriptText } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { Form } from '@/shared/common/Form';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';
import { display } from '@/shared/jsStyle/utils.css';

import { useShowVitalsEducation } from '../../../sectionUtils';
import { PatientGoal } from '../PatientGoal';
import { scriptList } from '../ReviewActionSteps/SharedReviewActionSteps.css';
import { usePatientGoalI18n } from './patientGoalI18n';

type Props = {
  hasPreviousGoal?: boolean;
};

export function PatientGoalRadioSection({ hasPreviousGoal }: Props) {
  const intl = useIntl();
  const patientGoalI18n = usePatientGoalI18n();
  return (
    <FieldGroup>
      <GoalCreationMessaging hasPreviousGoal={hasPreviousGoal} />
      <Form.RadioGroup
        size={12}
        name="patientGoal"
        classes={{ label: display.none }}
        label=""
        aria-label={intl.formatMessage({ defaultMessage: 'Patient Goal' })}
        orientation="vertical"
      >
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.BecomePhysicallyActive}
        >
          {patientGoalI18n[PatientGoal.BecomePhysicallyActive]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.TakeMedicationsConsistently}
        >
          {patientGoalI18n[PatientGoal.TakeMedicationsConsistently]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.LowerMyWeight}
        >
          {patientGoalI18n[PatientGoal.LowerMyWeight]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.ImproveMyFoodChoices}
        >
          {patientGoalI18n[PatientGoal.ImproveMyFoodChoices]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.ReduceMyStress}
        >
          {patientGoalI18n[PatientGoal.ReduceMyStress]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.BetterUnderstandMyDisease}
        >
          {patientGoalI18n[PatientGoal.BetterUnderstandMyDisease]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.GetBetterSleep}
        >
          {patientGoalI18n[PatientGoal.GetBetterSleep]}
        </Form.Radio>
        <Form.Radio
          classes={{ label: tiledRadioLabel.vertical }}
          value={PatientGoal.QuitSmoking}
        >
          {patientGoalI18n[PatientGoal.QuitSmoking]}
        </Form.Radio>
      </Form.RadioGroup>
    </FieldGroup>
  );
}

function GoalCreationMessaging({
  hasPreviousGoal,
}: {
  hasPreviousGoal?: boolean;
}) {
  const showVitalsEducation = useShowVitalsEducation();

  if (showVitalsEducation) {
    return (
      <>
        {!hasPreviousGoal ? (
          <>
            <Script>
              <FormattedMessage defaultMessage="Let's discuss what items we could work on together. What about your lifestyle do you think could be better?" />
            </Script>
            <Script>
              <FormattedMessage defaultMessage='Give examples: "For example, I know that I could get better sleep or exercise more."' />
            </Script>
          </>
        ) : (
          <Script>
            <FormattedMessage defaultMessage="For today's appointment, you can choose to keep your same overall goal or we can adjust. Do you want to continue with the same goal or do you have something else you want to work on?" />
          </Script>
        )}
        <InlineMessage>
          <FormattedMessage defaultMessage="Based on patient response, choose coordinating Cadence goal and/or continue to ask for additional information to get to the goal choice." />
        </InlineMessage>
      </>
    );
  }

  return (
    <>
      {!hasPreviousGoal ? (
        <Script>
          <FormattedMessage defaultMessage="Do you have any health goals that you want to work on until our next visit?" />
        </Script>
      ) : (
        <Script>
          <FormattedMessage defaultMessage="For today's appointment, you can choose to keep your same overall goal or we can adjust. Do you want to continue with the same goal or do you have something else you want to work on?" />
        </Script>
      )}
      <InlineMessage>
        <FormattedMessage defaultMessage="Follow the patient's lead here, a little silence is okay. If they provide their own goal, align it to the list of Cadence provided goals. If they don't, use a prompt by telling a patient story (example below). If there are still no goals that come to mind for the patient, then read the full list of approved goals." />
      </InlineMessage>
      <Script>
        <FormattedMessage defaultMessage="Example Prompts" />
      </Script>
      <ul className={scriptList}>
        <li className={scriptText}>
          <FormattedMessage defaultMessage="We talked about [x] earlier in the call and [x] is a common goal for people on the Cadence program. Does that seem interesting to you?" />
        </li>
        <li className={scriptText}>
          <FormattedMessage defaultMessage="For example, I recently spoke with a patient who initially set a goal of cutting soda out of their diet. She accomplished that and now she's working on cooking at home for at least one meal a day." />
        </li>
      </ul>
    </>
  );
}
