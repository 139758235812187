import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';
import Session from '@/shared/utils/session';

import {
  FETCH_PATIENT_LABS,
  FETCH_PATIENT_LABS_FAILURE,
  patientLabsFetched,
} from '../actionCreators';

const labsEpic = (action$: any) =>
  action$.pipe(
    ofType(FETCH_PATIENT_LABS),
    switchMap((action: any) => {
      const qs = new URLSearchParams({
        sort_by: 'timestamp',
        order_by: 'desc',
        patient_id: action.id,
        ...(action.cmp && { cmp: `${action.cmp}` }),
        ...(action.referenceLabId && {
          reference_lab_id: `${action.referenceLabId}`,
        }),
      });

      return from(Session.Api.get(`/pms/api/v1/labs?${qs.toString()}`)).pipe(
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      );
    }),
    map((response: AxiosResponse<TLabsPayload>) =>
      patientLabsFetched(response.data),
    ),
    catchError((error, caught) =>
      merge(
        of({
          type: FETCH_PATIENT_LABS_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default labsEpic;
