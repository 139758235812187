import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';

import { Box } from '@/deprecated/mui';
import FileIcon from '@/shared/assets/svgs/file.svg?react';
import { SimpleEmptyState as EmptyState } from '@/shared/common/EmptyState';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useListCalls } from '@/shared/hooks/queries/call.queries';
import { flexSection } from '@/shared/jsStyle';
import type { Note, NoteType } from '@/shared/types/note.types';
import { NoteSyncStatus } from '@/shared/types/note.types';

import { processCallData } from '../tabs/Messages/utils';
import { EmptyNote } from './EmptyNote';
import { NoteRepublishConfirmationDialog } from './NoteRepublishConfirmationDialog';
import { NoteRow } from './NoteRow/NoteRow';
import { NotesFilterValue } from './Notes.types';
import { NotesFilterDropdown } from './NotesFilterDropdown';
import { emptyResultsIconContainer, emptyResultsTitle } from './NotesList.css';
import { usePatientNotes } from './note.queries';
import { sortNoteLabelsAlphabetically } from './notesRequest.utils';

type Props = {
  noteTypes?: NoteType[];
  patientId: string;
  targetNoteId?: Nullable<string>;
} & DiscriminatedReadonly;

type DiscriminatedReadonly =
  // When not read-only, new note handler must be passed
  | { readOnly: true; onClickNewNote?: () => void }
  | { readOnly: false; onClickNewNote: () => void };

export function NotesList({
  readOnly,
  noteTypes,
  onClickNewNote,
  patientId,
  targetNoteId,
}: Props) {
  const { addCallsToEncounter, allowResyncInTriageNotes } = useFlags();
  const [isNoteRepublishOpen, setNoteRepublishOpen] = useState(false);
  const [noteRepublishId, setNoteRepublishId] = useState('');
  const [selectedFilter, setSelectedFilter] = useState(
    NotesFilterValue.AllNotes,
  );

  const {
    infiniteQuery,
    items: notes,
    hasMoreToFetch,
  } = usePatientNotes(patientId, { noteTypes, notesFilter: selectedFilter });
  const { data: patient } = usePatientDetails(patientId, false);
  const { data: callsData } = useListCalls(
    {
      user: {
        patientId: patient?.id,
      },
    },
    addCallsToEncounter,
    false,
  );
  const uniqueCalls = processCallData(callsData?.calls || []);

  const ehr = patient?.ehr_information?.ehr;

  // Fetch when user has scrolled to the bottom (and there are more items to fetch)
  const [lastRowRef, lastRowInView] = useInView();
  useEffect(() => {
    if (lastRowInView && hasMoreToFetch) {
      infiniteQuery.fetchNextPage();
    }
  }, [lastRowInView, hasMoreToFetch, infiniteQuery]);

  const emrSyncFailed = (note: Note) =>
    note.emr_sync_status === NoteSyncStatus.Failed;
  const inTriageAndCanResync = (note: Note) =>
    (note.emr_sync_status === NoteSyncStatus.InTriage ||
      note.emr_sync_status === NoteSyncStatus.Waiting) &&
    allowResyncInTriageNotes;

  const onRepublishToEhrClick = (noteId: string) => () => {
    setNoteRepublishId(noteId);
    setNoteRepublishOpen(true);
  };

  const noteRefs = useRef<Record<string, HTMLDivElement>>({});

  const targetNoteRef = noteRefs.current[targetNoteId || ''];

  useEffect(() => {
    if (targetNoteRef) {
      targetNoteRef.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [targetNoteRef]);

  return (
    <Box
      sx={{
        ...flexSection('column', 'center', 'center'),
        width: '100%',
        ...(selectedFilter && notes.length === 0 && { height: '100%' }),
      }}
    >
      <LoadingPlaceholder isLoading={infiniteQuery.isLoading}>
        <NotesFilterDropdown
          onSelect={setSelectedFilter}
          value={selectedFilter}
        />
        {notes.length === 0 ? (
          <EmptyResults
            onClickNewNote={() => onClickNewNote?.()}
            selectedFilter={selectedFilter}
          />
        ) : (
          sortNoteLabelsAlphabetically(notes).map((note, i) => (
            <NoteRow
              key={note.id}
              note={note}
              ref={(el: Nullable<HTMLDivElement>) => {
                if (el !== null) {
                  noteRefs.current[note.id.toString()] = el;
                }
              }}
              calls={
                addCallsToEncounter
                  ? uniqueCalls.filter(
                      (call) => call.noteId && call.noteId === note.id,
                    )
                  : []
              }
              enableRepublish={
                !readOnly && (emrSyncFailed(note) || inTriageAndCanResync(note))
              }
              onRepublishToEhrClick={onRepublishToEhrClick(note.id.toString())}
              {...(i === notes.length - 1 && {
                ref: lastRowRef,
              })}
            />
          ))
        )}
      </LoadingPlaceholder>
      <NoteRepublishConfirmationDialog
        closeNoteRepublishDialog={() => setNoteRepublishOpen(false)}
        ehr={ehr}
        isNoteRepublishOpen={isNoteRepublishOpen}
        noteId={noteRepublishId}
        patientId={patientId}
      />
    </Box>
  );
}

function EmptyResults({
  selectedFilter,
  onClickNewNote,
}: {
  selectedFilter: NotesFilterValue;
  onClickNewNote: () => void;
}) {
  const intl = useIntl();

  if (selectedFilter === NotesFilterValue.AllNotes) {
    return <EmptyNote onCreateNote={() => onClickNewNote?.()} />;
  }

  return (
    <EmptyState
      image={
        <div className={emptyResultsIconContainer}>
          <FileIcon />
        </div>
      }
    >
      <div className={emptyResultsTitle}>
        {intl.formatMessage({
          defaultMessage: 'There are no results for this filter selection',
        })}
      </div>
    </EmptyState>
  );
}
