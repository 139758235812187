import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatTimestamp } from '../utils';
import { timestamp } from './Timestamp.css';

type Props = {
  ts: string;
  numMessages: number;
  className?: string;
};

export function TimestampWithContext({ ts, numMessages, className }: Props) {
  const intl = useIntl();
  return (
    <div className={cx(timestamp, className)}>
      {numMessages > 0 && (
        <FormattedMessage
          defaultMessage="{numMessages} {numMessages, plural, =1 {comment} other {comments}} &middot; "
          values={{ numMessages }}
        />
      )}
      {formatTimestamp(intl, ts)}
    </div>
  );
}
