import { useHistory } from 'react-router-dom';

import {
  useShouldMoveUpActionPlan,
  useShowVitalsEducation,
} from '../../../sectionUtils';
import { useGoToRegActionPlan } from '../../shared/useGoToActionPlan';
import { regVisitPath } from '../paths';

export function useDeviceUsageOnNext(): [() => void, boolean] {
  const history = useHistory();
  const [goToActionPlan, isLoadingNav] = useGoToRegActionPlan();

  const shouldMoveUpActionPlan = useShouldMoveUpActionPlan();
  const showVitalsEducation = useShowVitalsEducation();

  if (!shouldMoveUpActionPlan) {
    return [() => history.push(regVisitPath('/med-review', '/index')), false];
  }

  if (showVitalsEducation) {
    return [
      () => history.push(regVisitPath('/vital-progress', '/intro')),
      false,
    ];
  }

  return [goToActionPlan, isLoadingNav];
}
