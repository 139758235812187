import type { ReactElement, ReactNode } from 'react';
import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';

import type { PopoverOrigin } from '@/deprecated/mui';
import { Box, Popover as MuiPopover } from '@/deprecated/mui';
import { flexSection } from '@/shared/jsStyle';

import type { Trigger } from './types';
import { TriggerBy } from './types';

const DEFAULT_BOX_STYLES = {
  ...flexSection('column', 'flex-start', 'stretch'),
  width: 420,
  padding: '16px',
};

const DEFAULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center',
} as PopoverOrigin;

const DEFAULT_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'center',
} as PopoverOrigin;

interface Props {
  target: ReactElement;
  children: ReactNode;
  trigger: Trigger;
  boxStyles?: object;
  popoverStyles?: object;
}

export const Popover = ({
  target,
  children,
  trigger,
  boxStyles,
  popoverStyles,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const targetRef = useRef(null);
  const [openedByTrigger, setOpenedByTrigger] = useState(false);

  useEffect(() => {
    setAnchorEl(targetRef.current);
  }, []);

  const isPopoverOpen = useCallback(() => {
    switch (trigger.type) {
      case TriggerBy.HOVER:
      case TriggerBy.CLICK:
        return openedByTrigger;
      case TriggerBy.MANUAL:
        return trigger.open;
      default:
        return openedByTrigger;
    }
  }, [openedByTrigger, trigger]);

  const handleOnClosePopover = useCallback(() => {
    if (trigger.type === TriggerBy.MANUAL) trigger.onClose();
    if (trigger.type === TriggerBy.CLICK) setOpenedByTrigger(false);
  }, [trigger]);

  const getTargetPropsByTriggerType = useCallback(() => {
    switch (trigger.type) {
      case TriggerBy.HOVER:
        return {
          onMouseOver: () => setOpenedByTrigger(true),
          onMouseOut: () => setOpenedByTrigger(false),
        };
      case TriggerBy.CLICK:
        return {
          onClick: () => setOpenedByTrigger(!openedByTrigger),
        };
      case TriggerBy.MANUAL:
        return target.props;
      default:
        return target.props;
    }
  }, [trigger, target, openedByTrigger]);

  return (
    <>
      {cloneElement(target, {
        ref: targetRef,
        ...getTargetPropsByTriggerType(),
      })}
      <MuiPopover
        open={isPopoverOpen()}
        onClose={handleOnClosePopover}
        anchorEl={anchorEl}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
        sx={{
          pointerEvents: trigger.type === TriggerBy.HOVER ? 'none' : 'auto',
          '.MuiPaper-root': {
            borderRadius: 0,
            pointerEvents: 'auto',
            ...popoverStyles,
          },
        }}
      >
        <Box
          sx={{
            ...DEFAULT_BOX_STYLES,
            ...boxStyles,
          }}
        >
          {children}
        </Box>
      </MuiPopover>
    </>
  );
};
