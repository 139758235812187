import type {
  SmartTemplateMap,
  TemplateContext,
  TemplateDelegate,
} from './types';

export function injectContext(
  val: SmartTemplateMap,
  context?: Record<string, unknown>,
): SmartTemplateMap<string>;

export function injectContext(
  val: unknown,
  context: TemplateContext = {},
): unknown {
  if (typeof val === 'function') {
    // Leaf node is handlebars TemplateDelegate,
    // so invoke the function with the provided context
    return (val as TemplateDelegate)(context);
  }
  if (val && typeof val === 'object') {
    const keys = Object.keys(val);
    return keys.reduce(
      (acc, k) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const objVal = (val as Record<string, any>)[k];
        return {
          ...acc,
          [k]: injectContext(objVal, context),
        };
      },
      {} as Record<string, unknown>,
    );
  }
  return val;
}
