{{! this template is used in both initialCnVisit/t2d and initialCnVisit/t2dAndHtn }}
{{> header val="General Assessment" }}
<br/>

{{> subheader val="Social History" }}
<p>1. History (past or current) of Tobacco use?</p>
<p>  - Current:</p>
<p>  - Past:</p>
<p>  - Any e-cigarettes or chewing tobacco?</p>
<p>2. Do you drink alcohol?</p>
<p>  - If Yes, beer, wine, and or liquor?</p>
<p>  - # of servings per week</p>
<p>3. Do you use marijuana or recreational drugs?</p>
{{> free_text_space }}

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>1. How many times a week do you eat fast food meals or snacks/desserts/or other sweets?</p>
<p>2. Do you drink regular sodas or glasses of sweet tea?</p>
<p>  a. If yes, # of servings per day?</p>
<p>3. How much water do you drink per day?</p>
<p>4. Do you add salt to meals regularly?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>Do you exercise regularly? What type? How long and how often?</p>
{{> free_text_space }}

{{> subheader val="Activities of Daily Living" }}
<p>Do you require any assistance with walking, eating, toileting, or dressing?</p>
{{> free_text_space }}

{{> header val="Assessment and Plan" }}
{{> assessment_and_plan }}
<br/>

{{> subheader val="Symptoms Assessment" }}
{{> free_text_space }}

{{> subheader val="Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Status of Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Patient Clinical Goal Reached" }}
<p>[Yes or No and explanation]</p>
<p>If not reached, please choose reason</p>
<p>- Provider preferences</p>
<p>- Patient preferences</p>
<p>- Medical reason</p>
<p>- Financial reason</p>
{{> free_text_space }}

{{> subheader val="Patient Education" }}

{{! T2D / T2D+HTN-specific List }}
<p>Recommend during next Cadence visit patient reviews the following education modules:</p>
<p>- General Introduction to Diabetes: Type 2</p>
<p>- Diabetes and Exercise</p>
<p>- Diabetes and Travel</p>
<p>- Managing Type 2 Diabetes Through Diet</p>
<p>- Avoiding High-Low Blood Glucose Levels</p>
<br/>
{{! T2D / T2D+HTN-specific List }}

{{> subheader val="Additional recommendations for patient care" }}
{{> free_text_space }}
