import { FormattedMessage, useIntl } from 'react-intl';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import { useCreateTask } from '@/shared/hooks/queries/tasks.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { TaskForm } from './TaskForm';
import {
  type FormFields,
  formDataToTaskPayload,
  getFormConfig,
} from './form.config';

type Props = {
  open: boolean;
  patientId: string;
  onClose: () => void;
};

export function TaskModal({ open, onClose, patientId }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const form = useFormFromConfig<FormFields>(getFormConfig(intl));
  const { mutate: createTask, isLoading: isSaving } = useCreateTask({
    onSuccess: () => {
      toaster.success(intl.formatMessage({ defaultMessage: 'Task created' }));
      onClose();
    },
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to create task' }),
      ),
  });
  const onSubmit = (data: FormFields) => {
    const payload = formDataToTaskPayload(data, patientId);
    createTask(payload);
  };

  return (
    <Modal open={open} onClose={onClose} disabled={isSaving}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Task Details',
        })}
      />
      <Modal.Body>
        <TaskForm form={form} onSubmit={onSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          type="submit"
          isProcessing={isSaving}
          isDisabled={!form.canSubmit}
          onPress={() => onSubmit(form.getValues())}
        >
          <FormattedMessage defaultMessage="Create Task" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
