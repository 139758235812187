import type { PatientSchema } from '@/shared/generated/api/pms';
import type { EHR as GrpcEHR } from '@/shared/generated/grpcGateway/cadence/models/models.pb';
import type {
  Condition,
  ConditionConfidence,
  ProgramProgramStatus,
  ProgramProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';

import type { ActiveVitalsAlert } from './alert.types';
import type {
  ChfProfile,
  HtnProfile,
  RpmCondition,
  T2dProfile,
} from './clinicalprofile.types';
import type { EHR } from './ehr.types';
import type { EplItem } from './epl.types';
import type { PatientSummary } from './patientSummary.types';
import type { Provider } from './provider.types';
import type { Address, Contact, MedManagementDelegation } from './shared.types';
import type { Pascals } from './units';

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Other = 'OTHER',
  X = 'X',
}

// keys of this enum correspond to the values on the backend.
export enum Race {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'American Indian / Alaska Native',
  ASIAN_INDIAN = 'Asian Indian',
  BLACK_OR_AFRICAN_AMERICAN = 'Black / African American',
  HISPANIC_OR_LATINO = 'Hispanic / Latino',
  NATIVE_HAWAIIAN = 'Native Hawaiian',
  OTHER = 'Other',
  WHITE = 'White',
}

// keys of this enum correspond to the values on the backend.
export enum Relationship {
  ASSISTED_LIVING = 'Assisted Living',
  AUNT = 'Aunt',
  BROTHER = 'Brother',
  BROTHER_IN_LAW = 'Brother In Law',
  COUSIN = 'Cousin',
  DAUGHTER = 'Daughter',
  DAUGHTER_IN_LAW = 'Daughter In Law',
  FATHER = 'Father',
  FATHER_IN_LAW = 'Father In Law',
  FORMER_SPOUSE = 'Former Spouse',
  FRIEND = 'Friend',
  GRANDDAUGHTER = 'Granddaughter',
  GRANDFATHER = 'Grandfather',
  GRANDMOTHER = 'Grandmother',
  GRANDSON = 'Grandson',
  GREAT_GRANDPARENT = 'Great Grandparent',
  LEGAL_GUARDIAN = 'Legal Guardian',
  LEGALLY_SEPARATED_SPOUSE = 'Legally Separated Spouse',
  MENTAL_HEALTH_CONTACT = 'Mental Health Contact',
  MOTHER = 'Mother',
  MOTHER_IN_LAW = 'Mother In Law',
  NEPHEW = 'Nephew',
  NIECE = 'Niece',
  PARTNER = 'Partner',
  SISTER = 'Sister',
  SISTER_IN_LAW = 'Sister In Law',
  SON = 'Son',
  SON_IN_LAW = 'Son In Law',
  SPOUSE = 'Spouse',
  STEPBROTHER = 'Stepbrother',
  STEPDAUGHTER = 'Stepdaughter',
  STEPFATHER = 'Stepfather',
  STEPMOTHER = 'Stepmother',
  STEPSISTER = 'Stepsister',
  STEPSON = 'Stepson',
  UNCLE = 'Uncle',
  OTHER = 'Other',
}

export type RelationshipKey = keyof typeof Relationship;

// keys of this enum correspond to the values on the backend.
export enum Accommodation {
  HARD_OF_HEARING = 'Hard of hearing',
  LANGUAGE_BARRIER = 'Language barrier',
  SIGHT_ISSUES = 'Sight issues',
  OTHER = 'Other',
}

export type AccommodationKey = keyof typeof Accommodation;

// keys of this enum correspond to the values on the backend.
export enum Residence {
  LIVE_ALONE = 'Live alone',
  LIVE_WITH_SPOUSE = 'Live with spouse/caregiver',
  LIVE_WITH_FAMILY = 'Live with family member',
  ASSISTED_LIVING = 'Assisted living',
  OTHER = 'Other',
}

export type ResidenceKey = keyof typeof Residence;

export enum PatientStatus {
  Disenrolled = 'DISENROLLED',
  Identified = 'IDENTIFIED',
  Eligible = 'ELIGIBLE',
  NotEligible = 'NOT_ELIGIBLE',
  Enrolled = 'ENROLLED',
  ProviderDeclined = 'PROVIDER_DECLINED',
  PatientDeclined = 'PATIENT_DECLINED',
  NeedsReview = 'NEEDS_REVIEW',
  WaitingForProvider = 'WAITING_FOR_PROVIDER',
  ReadyToEnroll = 'READY_TO_ENROLL',
  Approved = 'APPROVED',
  OrderPended = 'ORDER_PENDED',
  Duplicate = 'DUPLICATE',
  Deleted = 'DELETED',
}

export const abStatuses = [
  PatientStatus.Eligible,
  PatientStatus.WaitingForProvider,
  PatientStatus.ReadyToEnroll,
  PatientStatus.PatientDeclined,
  PatientStatus.NeedsReview,
];
export const eplStatuses = [
  PatientStatus.Eligible,
  PatientStatus.WaitingForProvider,
];

export enum IdType {
  ClinicBased = 'CLINIC_BASED',
  NonClinicBased = 'NON_CLINIC_BASED',
}

export enum StatusChangeReason {
  Insurance = 'insurance',
  Clinical = 'clinical',
  ProviderDeclined = 'provider declined',
  PatientDeclined = 'patient declined',
  FinancialConcerns = 'financial concerns',
  EnrolledInAlternateProgram = 'enrolled in alternate program',
  Deceased = 'deceased',
  DoctorRecommendedDisenrollment = 'doctor recommended disenrollment (and reason)',
  LackOfConnectivity = 'lack of connectivity',
  LackOfParticipation = 'lack of participation (and reason)',
  LeavingThePractice = 'leaving the practice',
  MovedToHospicePalliativeCare = 'moved to hospice/palliative care',
  PrivacyConcerns = 'privacy concerns',
  UnableToSafelyUseEquipment = 'unable to safely use equipment',
  AtDischarge = 'at discharge',
  InClinic = 'in clinic',
  Hypertension = 'hypertension',
  CongestiveHeartFailure = 'congestive heart failure',
  Type2Diabetes = 'type 2 diabetes',
  Type2DiabetesWithCongestiveHeartFailure = 'type 2 diabetes, congestive heart failure',
  Type2DiabetesWithHypertension = 'type 2 diabetes, hypertension',
  ClinicNoShow = 'clinic no-show',
  CadenceAppointmentNoShow = 'cadence appointment no-show',
  NotInterested = 'not interested',
  NoCellPhone = 'no cell phone',
  FinancialReasons = 'financial reasons',
  Other = 'other',
  AcuteVisit = 'acute visit',
  UnhappyWithDevices = 'unhappy with devices',
  PatientIsStable = 'patient is stable',
  NoMentionOfCadence = 'no mention of cadence',
  Rescheduled = 'rescheduled',
  PatientStillConsidering = 'patient still considering',
  ReEnrolled = 're-enrolled',
  CausedStress = 'program caused stress on the patient',
  NabOrdersCandidate = 'nab orders candidate',
  NabOrdered = 'nab ordered',
  Virtual = 'virtual',
  PendingOrderFailed = 'pending order failed',
  AppointmentBasedOrder = 'appointment based order',
  AbandonedEnrollment = 'abandoned enrollment',
  EngagementFatigue = 'engagement fatigue',
  PreferSelfManage = 'prefer self-manage',
  PreferPhysicianManage = 'prefer physician manage',
  PatientIsASnowbird = 'patient is a snowbird',
  TooBusy = 'too busy',
  NoLongerAPatientOfTheProvider = 'no longer a patient of the provider',
  Inactive = 'inactive',
  ClinicalExclusion = 'clinical exclusion',
  ConcernsAboutDeviceAccuracy = 'concerns about device accuracy',
  PrefersUsingPersonalDevices = 'prefers using personal devices',
  DoesntWantToHaveAppointments = 'doesnt want to have appointments',
  DoesntWantToReceiveAlertCalls = 'doesnt want to receive alert calls',
  DoesntWantToTakeVitals = 'doesnt want to take vitals',
  ReceivedAnUnexpectedBill = 'received an unexpected bill',
  BelievesHealthGoalMet = 'believes health goals met',
  HasCGM = 'has cgm',
  LivesInFacilityThatManagesTheirMedication = 'lives in facility that manages their medication',
  PrimaryResidenceOutsideCadenceFootprint = 'primary residence outside cadence footprint',
  ProviderLeftPractice = 'provider left practice',
  ProviderRequestsPatientGraduate = 'provider requests patient graduate',
  ProviderToldPatientProgramNotNeeded = 'provider told patient program not needed',
}

export type StatusChangeReasonUppercase =
  (typeof STATUS_CHANGE_REASON_UPPERCASE)[keyof typeof STATUS_CHANGE_REASON_UPPERCASE];

export const STATUS_CHANGE_REASON_UPPERCASE: Record<
  StatusChangeReason,
  string
> = {
  [StatusChangeReason.Insurance]: 'INSURANCE',
  [StatusChangeReason.Clinical]: 'CLINICAL',
  [StatusChangeReason.ProviderDeclined]: 'PROVIDER_DECLINED',
  [StatusChangeReason.PatientDeclined]: 'PATIENT_DECLINED',
  [StatusChangeReason.FinancialConcerns]: 'FINANCIAL_CONCERNS',
  [StatusChangeReason.EnrolledInAlternateProgram]:
    'ENROLLED_IN_ALTERNATE_PROGRAM',
  [StatusChangeReason.Deceased]: 'DECEASED',
  [StatusChangeReason.DoctorRecommendedDisenrollment]:
    'DOCTOR_RECOMMENDED_DISENROLLMENT',
  [StatusChangeReason.LackOfConnectivity]: 'LACK_OF_CONNECTIVITY',
  [StatusChangeReason.LackOfParticipation]: 'LACK_OF_PARTICIPATION',
  [StatusChangeReason.LeavingThePractice]: 'LEAVING_THE_PRACTICE',
  [StatusChangeReason.MovedToHospicePalliativeCare]:
    'MOVED_TO_HOSPICE_PALLIATIVE_CARE',
  [StatusChangeReason.PrivacyConcerns]: 'PRIVACY_CONCERNS',
  [StatusChangeReason.UnableToSafelyUseEquipment]:
    'UNABLE_TO_SAFELY_USE_EQUIPMENT',
  [StatusChangeReason.AtDischarge]: 'AT_DISCHARGE',
  [StatusChangeReason.InClinic]: 'IN_CLINIC',
  [StatusChangeReason.ReEnrolled]: 'RE_ENROLLED',
  [StatusChangeReason.Hypertension]: 'HYPERTENSION',
  [StatusChangeReason.CongestiveHeartFailure]: 'CONGESTIVE_HEART_FAILURE',
  [StatusChangeReason.Type2Diabetes]: 'TYPE_2_DIABETES',
  [StatusChangeReason.Type2DiabetesWithHypertension]:
    'TYPE_2_DIABETES_WITH_HYPERTENSION',
  [StatusChangeReason.Type2DiabetesWithCongestiveHeartFailure]:
    'TYPE_2_DIABETES_WITH_CONGESTIVE_HEART_FAILURE',
  [StatusChangeReason.ClinicNoShow]: 'CLINIC_NO_SHOW',
  [StatusChangeReason.CadenceAppointmentNoShow]: 'CADENCE_APPOINTMENT_NO_SHOW',
  [StatusChangeReason.NotInterested]: 'NOT_INTERESTED',
  [StatusChangeReason.NoCellPhone]: 'NO_CELL_PHONE',
  [StatusChangeReason.FinancialReasons]: 'FINANCIAL_REASONS',
  [StatusChangeReason.Other]: 'OTHER',
  [StatusChangeReason.AcuteVisit]: 'ACUTE_VISIT',
  [StatusChangeReason.UnhappyWithDevices]: 'UNHAPPY_WITH_DEVICES',
  [StatusChangeReason.PatientIsStable]: 'PATIENT_IS_STABLE',
  [StatusChangeReason.NoMentionOfCadence]: 'NO_MENTION_OF_CADENCE',
  [StatusChangeReason.Rescheduled]: 'RESCHEDULED',
  [StatusChangeReason.PatientStillConsidering]: 'PATIENT_STILL_CONSIDERING',
  [StatusChangeReason.CausedStress]: 'CAUSED_STRESS',
  [StatusChangeReason.NabOrdersCandidate]: 'NAB_ORDERS_CANDIDATE',
  [StatusChangeReason.NabOrdered]: 'NAB_ORDERED',
  [StatusChangeReason.Virtual]: 'VIRTUAL',
  [StatusChangeReason.PendingOrderFailed]: 'PENDING_ORDER_FAILED',
  [StatusChangeReason.AppointmentBasedOrder]: 'APPOINTMENT_BASED_ORDER',
  [StatusChangeReason.AbandonedEnrollment]: 'ABANDONED_ENROLLMENT',
  [StatusChangeReason.EngagementFatigue]: 'ENGAGEMENT_FATIGUE',
  [StatusChangeReason.PreferSelfManage]: 'PREFER_SELF_MANAGE',
  [StatusChangeReason.PreferPhysicianManage]: 'PREFER_PHYSICIAN_MANAGE',
  [StatusChangeReason.PatientIsASnowbird]: 'PATIENT_IS_A_SNOWBIRD',
  [StatusChangeReason.TooBusy]: 'TOO_BUSY',
  [StatusChangeReason.NoLongerAPatientOfTheProvider]:
    'NO_LONGER_A_PATIENT_OF_THE_PROVIDER',
  [StatusChangeReason.Inactive]: 'INACTIVE',
  [StatusChangeReason.ClinicalExclusion]: 'CLINICAL_EXCLUSION',
  [StatusChangeReason.ConcernsAboutDeviceAccuracy]:
    'CONCERNS_ABOUT_DEVICE_ACCURACY',
  [StatusChangeReason.PrefersUsingPersonalDevices]:
    'PREFERS_USING_PERSONAL_DEVICES',
  [StatusChangeReason.DoesntWantToHaveAppointments]:
    'DOESNT_WANT_TO_HAVE_APPOINTMENTS',
  [StatusChangeReason.DoesntWantToReceiveAlertCalls]:
    'DOESNT_WANT_TO_RECEIVE_ALERT_CALLS',
  [StatusChangeReason.DoesntWantToTakeVitals]: 'DOESNT_WANT_TO_TAKE_VITALS',
  [StatusChangeReason.ReceivedAnUnexpectedBill]: 'RECEIVED_AN_UNEXPECTED_BILL',
  [StatusChangeReason.BelievesHealthGoalMet]: 'BELIEVES_HEALTH_GOALS_MET',
  [StatusChangeReason.HasCGM]: 'HAS_CGM',
  [StatusChangeReason.LivesInFacilityThatManagesTheirMedication]:
    'LIVES_IN_FACILITY_THAT_MANAGES_THEIR_MEDICATION',
  [StatusChangeReason.PrimaryResidenceOutsideCadenceFootprint]:
    'PRIMARY_RESIDENCE_OUTSIDE_CADENCE_FOOTPRINT',
  [StatusChangeReason.ProviderLeftPractice]: 'PROVIDER_LEFT_PRACTICE',
  [StatusChangeReason.ProviderRequestsPatientGraduate]:
    'PROVIDER_REQUESTS_PATIENT_GRADUATE',
  [StatusChangeReason.ProviderToldPatientProgramNotNeeded]:
    'PROVIDER_TOLD_PATIENT_PROGRAM_NOT_NEEDED',
};

export interface PatientFormContact {
  is_preferred: boolean;
  contact: Contact;
}

export interface PatientContact {
  primary: boolean;
  emergency: boolean;
  contact_id?: number;
  patient_id?: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  contact: Contact;
  relationship?: string;
}

interface PatientAddress {
  primary: boolean;
  address: Address;
  address_id?: number;
  patient_id?: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
}

interface Disenrollment {
  id?: number;
  is_disenrolled?: boolean;
  reason?: string;
  reason_description?: string;
  created_at?: string;
  updated_at?: string;
  created_by?: string;
  updated_by?: string;
  disenrollment_date?: string;
}

export interface PatientReminderNoteData {
  text: string;
  author?: string;
  updated_at?: string;
  created_at?: string;
}

export type PatientProfile = {
  conditions: RpmCondition[];
  chf_profile?: ChfProfile;
  t2d_profile?: T2dProfile;
  htn_profile?: HtnProfile;
};

// the types generated from swagger are generally accurate but do not
// play nicely with our enums and a few other hand-coded types. this is
// a hacky way to slowly start migrating to PatientSchema without having
// to go scorched earth on the codebase
type ProblematicPatientSchemaFields =
  | 'active_vitals_alerts'
  | 'diagnosis_codes'
  | 'ehr_information'
  | 'med_management_delegation'
  | 'patient_profile'
  | 'status'
  | 'status_notes'
  | 'programs';

export type Patient = Omit<PatientSchema, ProblematicPatientSchemaFields> & {
  id: NonNullable<PatientSchema['id']>;
  active_vitals_alerts: ActiveVitalsAlert[];
  diagnosis_codes: DiagnosisCodes;
  ehr_information: Nullable<EhrInformation>;
  med_management_delegation: MedManagementDelegation;
  patient_profile: ProspectivePatientProfile;
  status: PatientStatus;
  status_notes: StatusNote[];
  programs: Program[];
};

/**
 * @deprecated - this is not an accurate representation of the API response
 */
interface DeprecatedPatient {
  first_name: string;
  last_name: string;
  email: string | null;
  dob: string;
  npi_id: number;
  gender: string;
  race: string | null;
  rpm_consent_date: string | null;
  ccm_consent_date: string | null;
  timezone: string;
  accommodations: string[] | [] | null;
  accommodations_other: string | null;
  residence: string | null;
  residence_other: string | null;
  is_test: boolean;
  patient_profile: PatientProfile | null;
  contacts: PatientContact[];
  addresses: PatientAddress[];
  id: string;
  created_at: string;
  updated_at: string;
  is_disenrolled: boolean;
  disenrollments: Disenrollment[];
  mrns: string[];
  patient_summary: PatientSummary;
  zendesk_id: Nullable<string>;
  reading_timestamp: string | null;
  reminder_note: Nullable<PatientReminderNoteData>;
  has_oxygen_at_home: boolean;
  med_management_delegation: MedManagementDelegation;
  vitals_reminders_time_local: string | null;
  vitals_reminders_time?: Nullable<string[]>;
  feature_flags: PatientFeatureFlags;
  primary_language?: string;
  diagnosis_codes?: DiagnosisCodes;
  enrollment_type?: string;
}

/**
 * @deprecated - use Patient
 */
export type PartialPatientType = Partial<DeprecatedPatient>;

/**
 * @deprecated - use Patient
 */
type RequiredPatientType = Omit<
  DeprecatedPatient,
  | 'id'
  | 'email'
  | 'patient_profile'
  | 'ehr_data_updated_at'
  | 'race'
  | 'is_test'
  | 'created_at'
  | 'updated_at'
  | 'contacts'
  | 'addresses'
  | 'accommodations'
  | 'accommodations_other'
  | 'residence'
  | 'residence_other'
  | 'is_disenrolled'
  | 'disenrollments'
  | 'reading_timestamp'
  | 'mrns'
  | 'patient_summary'
  | 'zendesk_id'
  | 'active_vitals_alerts'
  | 'reminder_note'
  | 'has_oxygen_at_home'
  | 'feature_flags'
>;

// not currently making use of any patient level flags, but please add here if needed.
export interface PatientFeatureFlags {}

/**
 * @deprecated - use Patient
 */
export type PatientWithOptionalFields = PartialPatientType &
  RequiredPatientType & { status?: PatientStatus };

export type NotificationSettingsSchema = {
  appointments_reminder?: boolean;
  email_consent?: boolean;
  notification_consent?: boolean;
  sms_consent?: boolean;
  vitals_confirmation?: boolean;
  vitals_reminder?: boolean;
  vitals_reminder_snoozed_until?: string | null;
  vitals_value?: boolean;
  weekly_summary?: boolean;
};

/**
 * @deprecated - use Patient
 */
export interface PatientDetails extends PatientWithOptionalFields {
  id: string;
  ehr_data_updated_at?: string | null;
  original_patient_profile?: PatientProfile | null;
  patient_summary: PatientSummary;
  active_vitals_alerts: ActiveVitalsAlert[];
  reminder_note: Nullable<PatientReminderNoteData>;
  has_oxygen_at_home: boolean;
  feature_flags: PatientFeatureFlags;
  ehr_information?: EhrInformation;
  has_caregiver: boolean;
  readonly notification_settings: NotificationSettingsSchema;
  readonly emergency_contact_disabled: boolean;
  status_notes: StatusNote[];
  programs: Program[];
  primary_insurance_name?: string | null;
  secondary_insurance_name?: string | null;
}

export type ProspectiveICD10Code = {
  category: string;
  code: string;
  code_description: string;
  etiology: Nullable<string>;
};

export type ProspectivePatientProfile = PatientProfile & {
  chf_profile: ChfProfile;
  t2d_profile: T2dProfile;
  htn_profile: HtnProfile;
};

export type ProspectivePatientClinicalProfile = ProspectivePatientProfile & {
  // Required for PUT as a byproduct of sharing the same endpoint with non-prospective patients
  icd_10: [];
};

/**
 * @deprecated - use Patient
 */
export type ProspectivePatient = Pick<
  DeprecatedPatient,
  | 'first_name'
  | 'last_name'
  | 'dob'
  | 'id'
  | 'mrns'
  | 'npi_id'
  | 'updated_at'
  | 'timezone'
> & {
  status: PatientStatus;
  identification_type: Nullable<IdType>;
  scheduling_hospital_name: string;
  upcoming_appointment_date: string;
  last_clinic_appt_provider_name: string;
  last_clinic_appt_date: string;
  scheduling_care_provider_name: string;
  primary_insurance_name: string;
  secondary_insurance_name: string;
  npi: Nullable<{ care_provider: Nullable<Provider> }>;
  medication_name_list: string;
  usual_provider_name: Nullable<string>;
  usual_provider_npi: Nullable<string>;
  identified_provider_npi: Nullable<{ care_provider: Nullable<Provider> }>;
  selected_care_provider_confidence: Nullable<{
    score: Nullable<IdentifiedProviderConfidenceScore>;
    identified_provider_match: Nullable<boolean>;
    scheduling_provider_match: Nullable<boolean>;
    seen_provider_match: Nullable<boolean>;
  }>;
  bmi: Nullable<number>;
  visited_emergency_department: Nullable<boolean>;
  last_blood_pressure_average: Nullable<{
    systolic: Pascals;
    diastolic: Pascals;
  }>;
  last_blood_pressure_max: Nullable<{
    systolic: Pascals;
    diastolic: Pascals;
  }>;
  last_blood_pressure: Nullable<{
    systolic: Pascals;
    diastolic: Pascals;
  }>;
  last_ehr_sync: string;
  diagnosis_codes?: DiagnosisCodes;
  is_prepped: boolean;
  rpm_ordered: boolean;
  status_notes: StatusNote[];
  last_clinic_appt_department_name: Nullable<string>;
  duration: Nullable<number>;
  patient_profile?: Nullable<ProspectivePatientProfile>;
  care_providers: { care_provider: Provider }[];
  zendesk_id: Nullable<string>;
  ehr_information?: EhrInformation;
  epls_pend_statuses: Nullable<PendStatus[]>;
  program: Nullable<Program>;
};

type PendStatus = {
  epl_id: Nullable<string>;
  order_id: Nullable<number>;
  error_description: Nullable<string>;
};

export enum IdentifiedProviderConfidenceScore {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export function isEplProspectivePatient(
  patient: ProspectivePatient | EplProspectivePatient,
): patient is EplProspectivePatient {
  // This is the only key that guarantees we are dealing with an EplProspectivePatient
  return 'nab_lists' in patient;
}

export type EplProspectivePatient = ProspectivePatient & {
  nab_lists: EplItem[];
  latest_nab_list_generated_at?: Nullable<string>;
  has_potential_duplicate_patients?: boolean;
  gender: string;
  mrn: string;
  ehr: GrpcEHR;
  hospital: string;
  hospitalId: string;
};

export type DiagnosisCodes = {
  code_names?: ICD10CodeMap;
};

export type ICD10CodeMap = Record<string, ProspectiveICD10Code | undefined>;

export type StatusNote = {
  id: number;
  reason_description?: string;
  reasons: StatusChangeReason[];
  status_change_date?: string;
  status_from: PatientStatus;
  status_to: PatientStatus;
  updated_by: Nullable<string>;
  created_at: string;
  rpm_order: Nullable<RpmOrder>;
};

export type ProgramCondition = {
  category: string;
  etiology: Nullable<string>;
  description: string;
  condition_type: Condition;
  id: number;
  version_hash: Nullable<string>;
  condition_confidence: Nullable<ConditionConfidence>;
};

export type ProgramConditionHistory = {
  category: string;
  etiology: Nullable<string>;
  description: string;
  condition_type: Condition;
  id: number;
  version_hash: Nullable<string>;
  condition_confidence: Nullable<ConditionConfidence>;
  operation: string;
  operation_time: Date;
};

export type Program = {
  conditions: ProgramCondition[];
  condition_history?: ProgramConditionHistory[];
  id: number;
  program_status: ProgramProgramStatus;
  program_type: ProgramProgramType;
  version_hash: Nullable<string>;
};

export type EhrInformation = {
  ehr: EHR;
  hospital_id: string;
  hospital_name: string;
  mrn: string;
  epic_fhir_id?: string;
  cerner_fhir_id?: string;
  health_system_id?: number;
};

export type DetailedCode = {
  code: string;
  description: string;
  type: string;
};

export type RpmOrder = {
  care_provider?: {
    first_name: string;
    last_name: string;
    role?: 'MD' | 'DO' | 'NP' | 'RN' | 'MA' | 'PA' | 'OTHER' | null;
  };
  ignorable_error_list: Array<{
    error: string;
    order_conditions: RpmCondition[];
    patient_conditions: RpmCondition[];
  }>;
  errors?: Array<unknown>;
  id: number;
  ordered_time: string;
  ehr_created_at: string;
  provider_first_name: string;
  provider_last_name: string;
  applied: boolean;
  icd10_codes: Nullable<{
    codes: string[];
    detailed_codes?: DetailedCode[];
  }>;
  note?: string;
  status?: 'APPROVED' | 'PENDED' | 'DENIED' | 'DELETED';
};

export type PatientAppointment = {
  appointment_datetime?: string;
  care_provider?: {
    first_name: string;
    last_name: string;
  };
  hospital?: {
    name?: string;
  };
  notes?: string[];
};

export enum EnrollmentType {
  VIRTUAL = 'VIRTUAL',
  CLINIC = 'CLINIC',
}

// needed for converter to evaluate type
export enum AcuityTierThreshold {
  Tier1 = 'TIER_1',
  Tier2 = 'TIER_2',
  Tier3 = 'TIER_3',
  AllPatients = 'ALL_PATIENTS',
}
