import first from 'lodash/first';
import { useMemo } from 'react';

import type { FileValue } from '@/shared/common/Form/fields/File';

export type FileWithBase64 = FileValue & { base64?: string };

export function isFileWithBase64(value: unknown): value is FileWithBase64 {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.hasOwn(value, 'base64')
  );
}

export async function fileToBase64(file?: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('no file provided'));
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      // need to remove the data uri stuff from the beginning of the string
      const result = reader.result?.toString().split(',').pop() as string;
      resolve(result);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
}

export function getImageDimensions(
  src: string,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };

    img.onerror = () => {
      reject(new Error('image failed to load'));
    };

    img.src = src;
  });
}

export function getImageSrc(value?: FileWithBase64) {
  const selectedFile = first(value?.files);

  if (selectedFile) {
    return URL.createObjectURL(selectedFile);
  }

  if (value?.base64) {
    return `data:image/png;base64,${value.base64}`;
  }

  return null;
}

export function useImageSrc(value?: FileWithBase64) {
  return useMemo(() => getImageSrc(value), [value]);
}
