import classnames from 'classnames';
import { format, isEqual, isToday, isYesterday, parseISO } from 'date-fns';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@/deprecated/mui';
import { CNNoteBodyPreview } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNNoteBodyPreview';
import { isCnNote } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/note.utils';
import AlertCircleIcon from '@/shared/assets/svgs/alertCircle.svg?react';
import HospitalIcon from '@/shared/assets/svgs/hospital.svg?react';
import ProtocolsIcon from '@/shared/assets/svgs/protocols.svg?react';
import { Tag } from '@/shared/common/Tag';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import type { Note } from '@/shared/types/note.types';
import { NoteSyncStatus } from '@/shared/types/note.types';

import { AudioPlayer } from '../../tabs/Messages/ConversationExchange';
import type { Conference } from '../../tabs/Messages/Messages';
import { NoteBodyPreview } from '../NotePreview';
import { NoteRepublishButton } from '../NoteRepublishButton';
import '../Notes.scss';
import { useTruncateNoteBody } from '../utils/useTruncateNoteBody';
import { TruncateButton } from './TruncateButton';
import {
  emrIcon,
  noteContainer,
  noteFooter,
  noteHeader,
  noteHeaderTopRow,
  noteTitle,
  statusBox,
  statusIcon,
  statusRow,
} from './noteRow.css';

function FormattedDate({ date }: { date: Date }) {
  if (isToday(date)) {
    return <FormattedMessage defaultMessage="TODAY" />;
  }
  if (isYesterday(date)) {
    return <FormattedMessage defaultMessage="YESTERDAY" />;
  }
  return <>{format(date, 'MM/dd/yyyy')}</>;
}

function NoteDate({
  createdAt,
  updatedAt,
}: {
  createdAt: string;
  updatedAt: string;
}) {
  if (!createdAt) {
    return <span className="notes-content-info">-</span>;
  }
  // Add 'Z' to end of timestamp string because time is stored without timezone in backend, but date library needs to know time is in UTC
  const createdDate = parseISO(`${createdAt}Z`);
  const updatedDate = parseISO(`${updatedAt}Z`);
  const formatString = 'MM/dd/yyyy hh:mm:ss a';

  if (isEqual(createdDate, updatedDate)) {
    return (
      <Tooltip title={format(createdDate, formatString)} placement="bottom">
        <span>
          <FormattedDate date={createdDate} />
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={format(updatedDate, formatString)} placement="bottom">
      <span>
        <FormattedMessage defaultMessage="EDITED:" />
        &nbsp;
        <FormattedDate date={updatedDate} />, &nbsp;
        <FormattedMessage defaultMessage="CREATED:" />
        &nbsp;
        <FormattedDate date={createdDate} />
      </span>
    </Tooltip>
  );
}

function SyncState({ syncStatus }: { syncStatus?: NoteSyncStatus }) {
  switch (syncStatus) {
    case NoteSyncStatus.Failed:
      return (
        <div className={statusBox.failed}>
          <HospitalIcon className={emrIcon.failed} />
          <FormattedMessage defaultMessage="Failed EMR sync" />
        </div>
      );
    case NoteSyncStatus.Skipped:
      return (
        <div className={statusBox.skipped}>
          <HospitalIcon className={emrIcon.skipped} />
          <FormattedMessage defaultMessage="Skipped EMR sync" />
        </div>
      );
    case NoteSyncStatus.Complete:
      return (
        <div className={statusBox.synced}>
          <HospitalIcon className={emrIcon.synced} />
          <FormattedMessage defaultMessage="Synced to EMR" />
        </div>
      );
    case NoteSyncStatus.Pending:
    case NoteSyncStatus.Syncing:
    case NoteSyncStatus.Waiting:
      return (
        <div className={statusBox.syncing}>
          <HospitalIcon className={emrIcon.syncing} />
          <FormattedMessage defaultMessage="Pending EMR sync" />
        </div>
      );
    case NoteSyncStatus.InTriage:
      return (
        <div className={statusBox.triage}>
          <HospitalIcon className={emrIcon.triage} />
          <FormattedMessage defaultMessage="Failed EMR sync, in triage" />
        </div>
      );
    default:
      return null;
  }
}

type NoteRowProps = {
  note: Note;
  enableRepublish?: boolean;
  onRepublishToEhrClick: () => void;
  innerRef?: ForwardedRef<unknown>;
  allowTruncate?: boolean;
  calls?: Conference[];
};

function Row({
  innerRef,
  note,
  onRepublishToEhrClick,
  enableRepublish = false,
  allowTruncate = true,
  calls = [],
}: NoteRowProps) {
  const { setNoteBodyRef, isTruncated, isTruncatable, setIsTruncated } =
    useTruncateNoteBody(allowTruncate);

  return (
    <div
      className={noteContainer}
      ref={innerRef as ForwardedRef<HTMLDivElement>}
    >
      <div className={noteHeader}>
        <div>
          <NoteDate createdAt={note.created_at} updatedAt={note.updated_at} />
        </div>
        <div className={noteHeaderTopRow}>
          <div className={noteTitle}>
            <span>{note.title || '-'}</span>
          </div>
          <div className={flexContainer.center}>
            {enableRepublish && (
              <NoteRepublishButton
                onRepublishToEhrClick={onRepublishToEhrClick}
              />
            )}
          </div>
        </div>
        <div>
          <div>{note.author}</div>
          <div className={statusRow}>
            {note.urgent && (
              <div className={statusBox.default}>
                <AlertCircleIcon className={statusIcon} stroke="red" />
                <FormattedMessage defaultMessage="Urgent" />
              </div>
            )}
            {note.action_required && (
              <div className={statusBox.default}>
                <ProtocolsIcon className={statusIcon} />
                <FormattedMessage defaultMessage="Action required" />
              </div>
            )}
            {note.should_emr_sync && (
              <SyncState syncStatus={note.emr_sync_status} />
            )}
          </div>
        </div>
      </div>
      <div
        ref={setNoteBodyRef}
        className={classnames('notes-content-body', {
          truncated: isTruncated,
        })}
      >
        {isCnNote(note) ? (
          <CNNoteBodyPreview body={note.body} />
        ) : (
          <NoteBodyPreview
            hasInvalidMarkdown={hasInvalidMarkdown(note.created_at)}
            body={note.body}
            rtfBody={note.rtf_body}
            bodyHtml={note.body_html}
            encounterModuleInstances={note.encounter_instances}
          />
        )}
      </div>
      <div className="truncate-padding">
        {isTruncatable ? (
          <TruncateButton
            onTruncateClick={() => setIsTruncated(!isTruncated)}
            isTruncated={isTruncated}
          />
        ) : null}
      </div>
      <div>
        {calls
          .filter((call) => Boolean(call.recordingSid))
          .map((call) => (
            <AudioPlayer
              key={call.recordingSid || ''}
              recordingSid={call.recordingSid || ''}
            />
          ))}
      </div>
      <div className={noteFooter}>
        {note.labels?.map((label) => (
          <Tag key={label.id} backgroundColor={label.rgb_hex}>
            {label.name}
          </Tag>
        ))}
      </div>
    </div>
  );
}

function hasInvalidMarkdown(createdAt: string) {
  const createdDate = parseISO(`${createdAt}Z`);
  // Last fix for breaking markdown was https://github.com/cadencerpm/falcon/pull/3815
  return createdDate < new Date('2023-12-06T17:00:00-08:00');
}

export const NoteRow = forwardRef((props: NoteRowProps, ref) => (
  <Row innerRef={ref} {...props} />
));
