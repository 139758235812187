import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { settings } from '@/config';
import { compose } from '@/deprecated/recompose';
import TextField from '@/shared/common/@deprecated/TextField';
import { emailValidation, required } from '@/shared/common/FormValidation';
import { withToaster } from '@/shared/common/withToaster';
import { Button } from '@/shared/tempo/atom/Button';
import { getErrorMsg } from '@/shared/utils/helpers';
import { persistor } from '@/store';
import type { RootState } from '@/store/redux.types';

import { updateEmail } from '../../actionCreators';
import { AuthPageWrapper } from '../shared';
import {
  clear,
  loginFormTitleContent,
  loginPageSections,
  loginTitle,
} from '../shared/LoginPage.css';
import { bigButton } from '../shared/auth.css';
import type { TLoginPage } from './TLoginPage';

export class LoginPageComponent extends Component<TLoginPage> {
  componentDidMount() {
    const { isAuthenticated, history, loginError } = this.props;

    const errorMessage: string = 'Successfully setup SSO authentication.';

    if (loginError?.message === errorMessage) {
      window.onload = () => document.forms[0].submit();
    }

    if (isAuthenticated) {
      history.replace('/');
    }
  }

  componentDidUpdate(prevProps: TLoginPage) {
    const {
      isAuthenticated,
      email,
      dispatchUpdateEmail,
      history,
      location,
      loginError,
      toaster,
    } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      // sometimes we redirect before flushing and it causes us to
      // lose the state. this ensures we flush to local storage before
      // sending the user onward
      persistor.flush().then(() => {
        history.replace(from.pathname);
      });
    }

    if (email && email !== prevProps.email) {
      dispatchUpdateEmail(email);
    }

    if (loginError !== prevProps.loginError && loginError !== null) {
      toaster?.error(getErrorMsg(loginError));
    }
  }

  render() {
    const { invalid, submitting, location } = this.props;
    const { from } = location.state || { from: { pathname: '/' } };

    const nextParams = new URLSearchParams({
      next: from.pathname,
    });

    return (
      <AuthPageWrapper>
        <div className={loginTitle}>
          <FormattedMessage defaultMessage="Log in" />
          <div className={clear} />
          <div className={loginFormTitleContent}>
            <FormattedMessage defaultMessage="Enter your account information." />
          </div>
        </div>
        <form
          id="login--form"
          method="POST"
          action={`${settings.API_URL}/auth/api/v1/login/identifier?${nextParams}`}
        >
          <div>
            <Field
              name="email"
              component={TextField}
              placeholder="Company email"
              type="email"
              validate={[required, emailValidation]}
              fullWidth
            />
          </div>
          <div className={loginPageSections}>
            <Button
              id="login--form-submit"
              variant="primary"
              type="submit"
              isDisabled={invalid || submitting}
              className={bigButton}
            >
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          </div>
        </form>
      </AuthPageWrapper>
    );
  }
}

const selector = formValueSelector('login--form');

const mapStateToProps = (state: RootState) => ({
  initialValues: {
    email: state.auth.email || '',
  },
  isAuthenticated: state.auth.isAuthenticated,
  email: selector(state, 'email'),
  loginError: state.auth.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchUpdateEmail: (email: string) => {
    dispatch(updateEmail(email));
  },
});

const enhance = compose(
  withToaster,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'login--form',
    enableReinitialize: true,
  }),
);

export const LoginPage = enhance(LoginPageComponent);
