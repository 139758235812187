import classnames from 'classnames/bind';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './sectionHeader.module.scss';

const cx = classnames.bind(styles);

type SectionHeaderProps = {
  children: ReactNode;
  editLink?: string;
  onEditClicked?: () => void;
};

export function SectionHeader({
  children,
  editLink,
  onEditClicked,
}: SectionHeaderProps) {
  return (
    <div className={cx('section-header')}>
      <h2>{children}</h2>
      {(editLink || onEditClicked) && (
        <a
          href={editLink}
          onClick={onEditClicked}
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage defaultMessage="Edit" />
        </a>
      )}
    </div>
  );
}
