import cx from 'classnames';
import type { ReactNode } from 'react';

import { section } from './CardSection.css';

type Props = {
  children: ReactNode;
  className?: string;
  unstyled?: boolean;
};

export function CardSection({ children, className, unstyled }: Props) {
  return (
    <div
      className={cx(
        { [section.default]: !unstyled, [section.unstyled]: unstyled },
        className,
      )}
    >
      {children}
    </div>
  );
}
