{{> header val="General Assessment" }}
<br/>

{{> subheader val="Social History" }}
<p>1. History (past or current) of Tobacco use?</p>
<p>  - Current:</p>
<p>  - Past:</p>
<p>  - Any e-cigarettes or chewing tobacco?</p>
<p>2. Do you drink alcohol?</p>
<p>  - If Yes, beer, wine, and or liquor?</p>
<p>  - # of servings per week</p>
<p>3. Do you use marijuana or recreational drugs?</p>
{{> free_text_space }}

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>1. How many times a week do you eat fast food meals or snacks/desserts/or other sweets?</p>
<p>2. Do you drink regular sodas or glasses of sweet tea?</p>
<p>3. How much water do you drink per day?</p>
<p>4. Do you add salt to meals regularly?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>Do you exercise regularly? What type? How long and how often?</p>
{{> free_text_space }}

{{> subheader val="Activities of Daily Living" }}
<p>Do you require any assistance with walking, eating, toileting, or dressing?</p>
{{> free_text_space }}

{{> header val="Assessment and Plan" }}
{{> assessment_and_plan }}
<br/>

{{> subheader val="Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Status of Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Patient Clinical Goal Reached" }}
<p>[Yes or No and explanation]</p>
<p>If not reached, please choose reason</p>
<p>- Provider preferences</p>
<p>- Patient preferences</p>
<p>- Medical reason</p>
<p>- Financial reason</p>
{{> free_text_space }}

{{> subheader val="Diet/Lifestyle Recommendation" }}
{{> free_text_space }}

{{> subheader val="Additional recommendations (labs/imaging/outreach to order physician)" }}
{{> free_text_space }}
