import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';

import { logger } from '@/logger';
import type { EncounterModuleId } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ProgramProgramType as ProgramType } from '@/shared/generated/grpcGateway/pms.pb';
import { useFlags } from '@/shared/hooks';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { ConditionProgram } from '@/shared/types/condition.types';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';

import { injectContext } from '../context.utils';
import { getSmartTemplatesMap } from '../templates';
import type { TemplateContext } from '../types';

export function useGetFieldTemplateMap() {
  const flags = useFlags();

  return useCallback(
    (
      encounterType: TypeOfEncounter | undefined,
      moduleId: EncounterModuleId,
      context: TemplateContext,
    ) => {
      if (!encounterType) {
        return {};
      }

      const contextualTemplateMap = getTemplateMap(context, flags);
      const program = conditionsToProgram(context.rpmConditions);
      const hasCcmProgramType = context.programTypes?.includes(ProgramType.CCM);

      // Disenrolled patients have a null program, but we still
      // want to be able to render the disenrollment template.
      if (!program && encounterType === TypeOfEncounter.DISENROLLMENT) {
        return get(
          contextualTemplateMap,
          [flags.careModelVersion, encounterType, 'default', moduleId],
          {},
        ) as Record<string, unknown>;
      }

      // Favor template-mapping using RPM program type, then by CCM
      const rpmConditionFieldTemplateMap = get(
        contextualTemplateMap,
        [flags.careModelVersion, encounterType, program || '', moduleId],
        {},
      ) as Record<string, unknown>;

      if (!isEmpty(rpmConditionFieldTemplateMap)) {
        return rpmConditionFieldTemplateMap;
      }

      if (hasCcmProgramType) {
        return get(
          contextualTemplateMap,
          [
            flags.careModelVersion,
            encounterType,
            ConditionProgram.CCM,
            moduleId,
          ],
          {},
        ) as Record<string, unknown>;
      }
      return {};
    },
    [flags],
  );
}

/**
 * Returns the full smart template map
 * injected with context data fetched from the API.
 */
function getTemplateMap(context: TemplateContext, flags: FeatureFlagSet) {
  return injectContext(getSmartTemplatesMap(flags), context);
}

export function conditionsToProgram(
  conditions: RpmCondition[] = [],
): Nullable<ConditionProgram> {
  if (conditions.length === 0) {
    return null;
  }

  if (
    rpmConditionsEqual(conditions, [
      Condition.Hypertension,
      Condition.TypeTwoDiabetes,
    ])
  ) {
    return ConditionProgram.T2DAndHTN;
  }
  if (rpmConditionsEqual(conditions, [Condition.CHF])) {
    return ConditionProgram.CHF;
  }
  if (rpmConditionsEqual(conditions, [Condition.COPD])) {
    return ConditionProgram.COPD;
  }
  if (rpmConditionsEqual(conditions, [Condition.Hypertension])) {
    return ConditionProgram.Hypertension;
  }
  if (rpmConditionsEqual(conditions, [Condition.TypeTwoDiabetes])) {
    return ConditionProgram.TypeTwoDiabetes;
  }

  logger.warn(
    `Could not map conditions: [${conditions.join(
      ',',
    )}] to a condition program.`,
  );
  return null;
}

function rpmConditionsEqual(argA: RpmCondition[], argB: RpmCondition[]) {
  return isEqual(argA.sort(), argB.sort());
}
