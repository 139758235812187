import isObject from 'lodash/isObject';

import { logger } from '@/logger';
import type { AllTagType } from '@/shared/types/tagsAndThreshold.types';

import type {
  AlertMessageFormatterProps,
  ReadingValues,
} from '../../AlertDescription.types';

export abstract class AlertFormatter<
  T extends AllTagType,
  V extends AlertMessageFormatterProps,
> {
  protected abstract map: Map<T, string>;

  protected abstract unitConverter: (value: number) => number;

  protected abstract unit: string;

  public getTagTitlesLookup(): Map<T, string> {
    return this.map;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getTitle(tag: T, timestamp: string): string | undefined {
    return this.map.get(tag);
  }

  public abstract getDescription(tag: T, values: V): string;

  protected convertReadings(formatterProps: V) {
    return {
      ...formatterProps,
      values: this.convertReadingValues(formatterProps.values),
      unit: this.unit,
    };
  }

  /**
   * Recursively applies the unitConverter to all values in the reading values
   * object
   */
  private convertReadingValues<U extends ReadingValues>(values: U): U {
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        let newValue;
        if (isObject(value)) {
          newValue = this.convertReadingValues(value);
        } else {
          if (typeof value !== 'number') {
            logger.error(`Unexpected type found for reading value ${value}`);
          }
          newValue = this.unitConverter(Number(value));
        }

        return [key, newValue];
      }),
      // Typecasting because TypeScript is unable to reason that the return value will be the same type
    ) as U;
  }
}
