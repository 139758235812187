import { FormattedMessage } from 'react-intl';

import emptyNotes from '@/shared/assets/emptyNotes.png';
import { Button } from '@/shared/tempo/atom/Button';

type Props = {
  onCreateNote: () => void;
};

export function EmptyNote({ onCreateNote }: Props) {
  return (
    <>
      <div className="notes-image-container">
        <img alt="emptyNotes" src={emptyNotes} />
      </div>
      <div className="notes-empty-state-text notes-empty-state-title">
        <FormattedMessage defaultMessage="Notes about the patient" />
      </div>
      <div className="notes-empty-state-text notes-empty-state-description">
        <FormattedMessage defaultMessage="Document the patient's condition over time and communicate their clinical care." />
      </div>
      <div className="notes-empty-state-button">
        <Button id="create-new-note" variant="primary" onPress={onCreateNote}>
          <FormattedMessage defaultMessage="Create first note" />
        </Button>
      </div>
    </>
  );
}
