import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from '@/shared/common/Form/validations';

import { ReasonForVisit, TypeOfVisit } from './types';

export type EmergencyVisitsFormFields = {
  newVisits: 'true' | 'false';
  typeOfVisit?: TypeOfVisit[];
  startDate?: string;
  endDate?: string;
  location?: string;
  reasonForVisit?: ReasonForVisit[];
  additionalInformation?: string;
};

export function getEmergencyVisitsFormConfig(intl: IntlShape) {
  const { required, enumType, date } = validators(intl);
  const selectOne = intl.formatMessage({
    defaultMessage: 'Select at least one option',
  });

  const requiredWhenNewVisits = (schema: yup.ArraySchema<yup.AnySchema>) =>
    schema.when('newVisits', {
      is: 'true',
      then: (s) => s.min(1, selectOne),
    });

  return {
    fields: {
      newVisits: {
        defaultValue: undefined,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      typeOfVisit: {
        defaultValue: [],
        validation: requiredWhenNewVisits(
          yup.array().of(enumType({ source: TypeOfVisit, pluck: 'values' })),
        ),
      },
      startDate: {
        defaultValue: null,
        validation: date().when('newVisits', {
          is: 'true',
          then: required,
        }),
      },
      endDate: {
        defaultValue: null,
        validation: date(),
      },
      location: {
        defaultValue: undefined,
        validation: yup.string(),
      },
      reasonForVisit: {
        defaultValue: [],
        validation: requiredWhenNewVisits(
          yup.array().of(enumType({ source: ReasonForVisit, pluck: 'values' })),
        ),
      },
      additionalInformation: {
        defaultValue: undefined,
        validation: yup.string(),
      },
    },
  };
}
