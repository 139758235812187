import { usePatientDetails } from '@/shared/hooks/queries';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';
import { Condition } from '@/shared/types/clinicalprofile.types';

export function usePatientConditions(patientId: string) {
  const { data: patient, isLoading } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );

  if (isLoading) {
    return { isLoading };
  }

  const conditions = getRpmConditionsFromProgramAndStatus(
    patient?.programs,
    patient?.status,
  );

  return {
    isLoading,
    hasHtn: conditions.includes(Condition.Hypertension),
    hasT2d: conditions.includes(Condition.TypeTwoDiabetes),
    hasChf: conditions.includes(Condition.CHF),
  };
}
