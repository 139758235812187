import { FormattedMessage } from 'react-intl';

import { scriptText } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { TEMPLATE_HELPERS } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/engine';
import type { PatientContextVitalsVitalSigns } from '@/shared/generated/grpcGateway/telemetry.pb';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { type Device, DeviceTypes } from '@/shared/types/device.types';

type Props = {
  conditions: RpmCondition[];
  devices?: Device[];
  vitalsContext?: PatientContextVitalsVitalSigns;
  className?: string;
};

export function VitalsList({
  conditions,
  devices,
  vitalsContext,
  className,
}: Props) {
  const hasHtn = conditions.includes(Condition.Hypertension);
  const hasT2d = conditions.includes(Condition.TypeTwoDiabetes);
  const hasChf = conditions.includes(Condition.CHF);

  return (
    <ul className={className}>
      {(hasHtn || hasChf) && (
        <li className={scriptText}>
          <FormattedMessage
            defaultMessage="Blood Pressure: {bp}"
            values={{
              bp: TEMPLATE_HELPERS.bp(
                vitalsContext?.bloodPressure?.systolic?.avg30d,
                vitalsContext?.bloodPressure?.diastolic?.avg30d,
              ),
            }}
          />
        </li>
      )}
      {hasT2d &&
        devices?.some(
          (device) => device.device_type === DeviceTypes.BLOOD_GLUCOSE_METER,
        ) && (
          <li className={scriptText}>
            <FormattedMessage
              defaultMessage="Blood Glucose: {bg}"
              values={{
                bg: TEMPLATE_HELPERS.bg(vitalsContext?.bloodGlucose?.avg30d),
              }}
            />
          </li>
        )}
      {(hasT2d || hasChf || hasHtn) &&
        devices?.some(
          (device) => device.device_type === DeviceTypes.WEIGHT_SCALE,
        ) && (
          <li className={scriptText}>
            <FormattedMessage
              defaultMessage="Weight: {weight}"
              values={{
                weight: TEMPLATE_HELPERS.weight(vitalsContext?.weight?.avg30d),
              }}
            />
          </li>
        )}
    </ul>
  );
}
