import { useRadioGroupState } from 'react-stately';
import type { RadioGroupState } from 'react-stately';

import type { Props as SegmentedButtonGroupProps } from './SegmentedButtonGroup';

export type SegmentedButtonGroupState = Expand<RadioGroupState>;

export function useSegmentedButtonGroupState(
  props: SegmentedButtonGroupProps,
): SegmentedButtonGroupState {
  return useRadioGroupState(props) as SegmentedButtonGroupState;
}
