import CircleIcon from '@/shared/assets/svgs/circle.svg?react';
import { Tag, type TagVariant } from '@/shared/tempo/atom/Tag';

import { statusTag, statusTagDot } from './GoalStatusTag.css';
import { GoalStatus } from './goals.types';
import { useGoalStatusI18n } from './i18nMapping';

type Props = { status: GoalStatus };

export function GoalStatusTag({ status }: Props) {
  const goalStatusI18n = useGoalStatusI18n();
  const tagVariant = getTagVariant(status);
  return (
    <Tag className={statusTag} variant={tagVariant}>
      <CircleIcon width={8} height={8} className={statusTagDot[status]} />
      {goalStatusI18n[status]}
    </Tag>
  );
}

function getTagVariant(status: GoalStatus): TagVariant {
  switch (status) {
    case GoalStatus.OPEN:
      return 'danger';
    case GoalStatus.ABANDONED:
      return 'default';
    case GoalStatus.COMPLETE:
      return 'success';
    case GoalStatus.IN_PROGRESS:
      return 'warning';
    default:
      return 'unstyled';
  }
}
