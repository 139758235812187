import { format as formatDate, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import type { BaseCellProps } from './BaseCell';
import { BaseCell } from './BaseCell';

type Props = {
  children: Maybe<number | Date>;
  format?: string;
  timezone?: string;
} & BaseCellProps;

const DEFAULT_FORMAT = 'yyyy-MM-dd';

export function DateCell({
  children,
  timezone,
  format = DEFAULT_FORMAT,
  ...baseProps
}: Props) {
  return (
    <BaseCell {...baseProps}>
      {children && isValid(children)
        ? formatHelper(children, format, timezone)
        : null}
    </BaseCell>
  );
}

function formatHelper(
  date: number | Date,
  formatStr: string,
  timezone?: string,
) {
  if (timezone) {
    return formatInTimeZone(date, timezone, formatStr);
  }
  return formatDate(date, formatStr);
}
