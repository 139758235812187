import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { Checkbox } from '@/deprecated/mui';

import type { BaseCellProps } from '../BaseCell';
import { BaseCell } from '../BaseCell';
import { checkbox } from './CheckboxCell.css';

export type Props = {
  checked: boolean;
  disabled?: boolean;
  onChange: (val: boolean) => void;
} & Omit<BaseCellProps, 'children'>;

export const CheckboxCell = forwardRef(
  (
    { checked, onChange, disabled = false, ...baseProps }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <BaseCell {...baseProps} align="center">
      {/* span is necessary here to detach tooltip active/disable state from child checkbox
          spreading baseProps here due to MUI requirements
      */}
      <span ref={ref} {...baseProps}>
        <Checkbox
          className={checkbox}
          checked={checked}
          disabled={disabled}
          onChange={(event) => onChange(event.target.checked)}
          // need to apply the class to the base input as well because
          // MUI creates a hidden (opacity: 0) checkbox element to
          // power the checkbox UI. this native checkbox has a z-index
          // of 1, causing it to capture clicks when it shouldn't be
          // able to (e.g. hidden behind a table heading)
          inputProps={{ className: checkbox }}
        />
      </span>
    </BaseCell>
  ),
);
