import type { HelperDeclareSpec } from 'handlebars';
import type { IntlShape } from 'react-intl';

import type { SectionStepsState } from '@/shared/common/Wizard/state';
import type { PatientMedications } from '@/shared/generated/grpcGateway/medication.pb';
import type { VitalsEngagement } from '@/shared/hooks/useVitalsEngagement';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';

import { isRegularVisitPatientNoShow } from './isRegularVisitPatientNoShow';
import { regularVisitActionPlan } from './regularVisitActionPlan';
import { regularVisitEarlyEndCall } from './regularVisitEarlyEndCall';
import { regularVisitGeneralAssessment } from './regularVisitGeneralAssessment';
import { regularVisitIntro } from './regularVisitIntro';
import { regularVisitMedicationReview } from './regularVisitMedicationReview';
import { regularVisitPatientNoShow } from './regularVisitPatientNoShow';
import { regularVisitProductSupport } from './regularVisitProductSupport';
import { regularVisitSymptoms } from './regularVisitSymptoms';

interface TemplateHelpers extends HelperDeclareSpec {
  regularVisitProductSupport: (
    data: SectionStepsState,
    vitalsEngagement: VitalsEngagement,
  ) => string;
  isRegularVisitPatientNoShow: (data: SectionStepsState) => boolean;
  regularVisitPatientNoShow: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  regularVisitMedicationReview: (
    data: SectionStepsState,
    noteId: number,
    meds: PatientMedications,
    flags: FeatureFlagSet,
  ) => string;
  regularVisitGeneralAssessment: (data: SectionStepsState) => string;
  regularVisitEarlyEndCall: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  regularVisitSymptoms: (
    data: SectionStepsState,
    rpmConditions: RpmCondition[],
    intl: IntlShape,
  ) => string;
  regularVisitActionPlan: (data: SectionStepsState, intl: IntlShape) => string;
  regularVisitIntro: (data: SectionStepsState) => string;
}

/**
 * These helpers can be used from within handlebars templates to
 * format/transform data
 */
export const REGULAR_CN_VISIT_HELPERS: TemplateHelpers = {
  regularVisitProductSupport,
  isRegularVisitPatientNoShow,
  regularVisitPatientNoShow,
  regularVisitGeneralAssessment,
  regularVisitMedicationReview,
  regularVisitEarlyEndCall,
  regularVisitSymptoms,
  regularVisitActionPlan,
  regularVisitIntro,
};
