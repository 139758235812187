{{!--
  This field template is shared between both NP Initial and NP Regular Visit
  types for HTN, T2D, and HTN+T2D conditions
--}}
{{> header val="General Assessment" }}
<br/>

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>1. Review past discussion and highlight any recent patient diet goals?</p>
<p>2. Do they have any new dietary goals?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>1. Review past discussion and highlight any recent patient exercise goals?</p>
<p>2. Do they have any new exercise goals?</p>
{{> free_text_space }}

{{> header val="Assessment and Plan" }}
{{> assessment_and_plan }}
<br/>

{{> subheader val="Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Status of Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Patient Clinical Goal Reached" }}
<p>[Yes or No and explanation]</p>
<p>If not reached, please choose reason</p>
<p>- Provider preferences</p>
<p>- Patient preferences</p>
<p>- Medical reason</p>
<p>- Financial reason</p>
{{> free_text_space }}

{{> subheader val="Diet/Lifestyle Recommendation" }}
{{> free_text_space }}

{{> subheader val="Additional recommendations (labs/imaging/outreach to order physician)" }}
{{> free_text_space }}
