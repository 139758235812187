import cx from 'classnames';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  StopIconFill as ErrorIcon,
  GreenCheckMark as SuccessIcon,
  ArrowsRotate as UndoIcon,
} from '@/shared/assets/svgs';
import { Button } from '@/shared/tempo/atom/Button';

import { undo } from './actions.css';

type Props = {
  variant: 'accepted' | 'rejected';
  readOnly: boolean;
  isProcessing: boolean;
  onUndo: () => void;
  acceptedHeader?: ReactNode;
  acceptedBody?: ReactNode;
  rejectedMessage?: ReactNode;
};

export function UndoAction({
  variant,
  isProcessing,
  readOnly,
  onUndo,
  acceptedHeader,
  acceptedBody,
  rejectedMessage,
}: Props) {
  return (
    <div
      className={cx(undo.container[variant], {
        [undo.container.singleLine]: !acceptedHeader,
        [undo.container.multiLine]: !!acceptedHeader,
      })}
    >
      <div className={undo.content[variant]}>
        {variant === 'accepted' ? <SuccessIcon /> : <ErrorIcon />}
        <Message
          header={variant === 'accepted' ? acceptedHeader : undefined}
          body={variant === 'accepted' ? acceptedBody : rejectedMessage}
        />
      </div>
      {!readOnly && (
        <Button
          variant="tertiary"
          onPress={onUndo}
          className={undo.undoButton}
          isProcessing={isProcessing}
        >
          <Button.Icon>
            <UndoIcon className={undo.undoIcon} />
          </Button.Icon>
          <FormattedMessage defaultMessage="Undo" />
        </Button>
      )}
    </div>
  );
}

type MessageProps = {
  header?: ReactNode;
  body?: ReactNode;
};

function Message({ header, body }: MessageProps) {
  if (!header) {
    return <>{body}</>;
  }

  return (
    <div>
      <p className={undo.acceptedHeader}>{header}</p>
      <p className={undo.acceptedBody}>{body}</p>
    </div>
  );
}
