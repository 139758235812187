import * as yup from 'yup';

import { validator } from './factory';

type NumberValidatorParams = {
  min?: number;
  max?: number;
  integer?: boolean;
  errorMessage?: string;
};

export const numberValidator = validator(
  (intl, params: NumberValidatorParams) => {
    const errorMessage =
      params.errorMessage ??
      intl.formatMessage({
        defaultMessage: 'Must be a valid number',
      });

    let schema = yup.number().typeError(errorMessage);

    if (params.min !== undefined) {
      const message =
        params.errorMessage ??
        intl.formatMessage(
          { defaultMessage: 'Must be at least {min}' },
          { min: params.min },
        );
      schema = schema.min(params.min, message);
    }

    if (params.max !== undefined) {
      const message =
        params.errorMessage ??
        intl.formatMessage(
          { defaultMessage: 'Must be no more than {max}' },
          { max: params.max },
        );
      schema = schema.max(params.max, message);
    }

    if (params.integer) {
      const message =
        params.errorMessage ??
        intl.formatMessage({
          defaultMessage: 'Must be an integer',
        });
      schema = schema.integer(message);
    }

    return schema;
  },
);
