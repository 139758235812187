import { FormattedMessage } from 'react-intl';
import type { UseInfiniteQueryResult } from 'react-query';

import { TabKey } from '@/components/TaskHubTray/TabKey';
import { useFlatPages } from '@/reactQuery';
import type { PaginatedTasks } from '@/shared/hooks/queries/tasks.queries';

import { isUrgent } from '../TaskCard/utils';
import { TaskState } from '../types';
import { taskCount } from './TaskCountHeader.css';

type Props = {
  query: UseInfiniteQueryResult<PaginatedTasks>;
  tabKey?: TabKey;
};

export function TaskCountHeader({ query, tabKey }: Props) {
  const openUrgentTasksLength = useFlatPages(query).filter(
    (t) => isUrgent(t) && t.state === TaskState.OPENED,
  ).length;

  if (tabKey === TabKey.Resolved) {
    return (
      <div className={taskCount.container}>
        <span className={taskCount.label.resolved}>
          <FormattedMessage
            defaultMessage="{count} resolved"
            values={{ count: query.data?.pages[0]?.totalSize }}
          />
        </span>
      </div>
    );
  }

  if (openUrgentTasksLength) {
    return (
      <div className={taskCount.container}>
        <span className={taskCount.label.urgent}>
          <FormattedMessage
            defaultMessage="{count} urgent"
            values={{ count: openUrgentTasksLength }}
          />
        </span>
      </div>
    );
  }

  return null;
}
