import type { ReactNode } from 'react';
import {
  QueryClientProvider as ClientProvider,
  useIsFetching,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { LinearProgress } from '@/deprecated/mui';

import { queryClient } from './client';

type Props = {
  children: ReactNode;
};

export function QueryClientProvider({ children }: Props) {
  return (
    <ClientProvider client={queryClient}>
      <FetchingProgressBar />
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
        position="bottom-right"
        toggleButtonProps={{ style: { opacity: '0.5' } }}
      />
    </ClientProvider>
  );
}

function FetchingProgressBar() {
  const isFetching = useIsFetching();
  return (
    <>
      {Boolean(isFetching) && (
        <LinearProgress
          sx={{
            position: 'absolute',
            width: '100%',
            zIndex: '4',
          }}
        />
      )}
    </>
  );
}
