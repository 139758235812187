import type { QueryKey, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { QueryCallbacks } from '@/reactQuery/types';
import { CCMGoalService } from '@/shared/generated/grpcGateway/ccm_goal.pb';
import type {
  CCMGoal,
  CreateCCMGoalRequest,
  ListCCMGoalsRequest,
  ListCCMGoalsResponse,
} from '@/shared/generated/grpcGateway/ccm_goal.pb';
import { idToGrpcName } from '@/shared/utils/grpc';

export const CCM_GOAL_QUERY_KEY_BASE = ['rpm', 'v1', 'ccmGoal'] as const;

export const ccmGoalsKeys = {
  list: (params?: ListCCMGoalsRequest) =>
    [...CCM_GOAL_QUERY_KEY_BASE, params] as const,
  detail: (id: string) => [...CCM_GOAL_QUERY_KEY_BASE, id] as const,
};

export function useListCcmGoals(
  params: ListCCMGoalsRequest,
  config?: UseQueryOptions<ListCCMGoalsResponse>,
) {
  return useQuery<ListCCMGoalsResponse>(
    ccmGoalsKeys.list(params),
    () => CCMGoalService.ListCCMGoals(params),
    config,
  );
}

export function useCreateCcmGoal(
  patientId: string,
  callbacks?: QueryCallbacks<CCMGoal>,
) {
  const client = useQueryClient();

  return useMutation(
    (params: { goal: CCMGoal; carePlanId?: string }) => {
      const ccmGoal: CreateCCMGoalRequest['ccmGoal'] = {
        ...params.goal,
        patientId,
        ...(params.carePlanId && { ccmCarePlanId: params.carePlanId }),
      };
      return CCMGoalService.CreateCCMGoal({
        ccm_goal: ccmGoal,
      } as CreateCCMGoalRequest);
    },
    {
      onSuccess: async (data) => {
        await client.invalidateQueries(CCM_GOAL_QUERY_KEY_BASE);
        callbacks?.onSuccess?.(data);
      },
      onError: callbacks?.onError,
    },
  );
}

export function useCcmGoal(id: string, options: UseQueryOptions<CCMGoal> = {}) {
  return useQuery(
    ccmGoalsKeys.detail(id) as QueryKey,
    () =>
      CCMGoalService.GetCCMGoal({
        name: idToGrpcName('ccmGoal', id),
      }),
    options,
  );
}

export function useDeleteCcmGoal(callbacks?: QueryCallbacks<void>) {
  const client = useQueryClient();

  return useMutation((name: string) => CCMGoalService.DeleteCCMGoal({ name }), {
    onSuccess: async () => {
      callbacks?.onSuccess?.();
      await client.invalidateQueries(CCM_GOAL_QUERY_KEY_BASE);
    },
    onError: callbacks?.onError,
  });
}

export function useSaveCcmGoal(callbacks?: QueryCallbacks<CCMGoal>) {
  const client = useQueryClient();
  async function defaultSuccessCallback(data: CCMGoal) {
    await client.invalidateQueries(CCM_GOAL_QUERY_KEY_BASE);
    callbacks?.onSuccess?.(data);
  }
  const mutationResult = useMutation(
    (ccmGoal: CCMGoal) => CCMGoalService.SaveCCMGoal({ ccmGoal }),
    {
      onSuccess: defaultSuccessCallback,
      onError: callbacks?.onError,
    },
  );

  return { ...mutationResult, defaultSuccessCallback };
}
