import type {
  ListPatientVitalsResponseThreshold,
  ListPatientVitalsResponseThresholds,
  ListPatientVitalsResponseVital,
} from '@/shared/generated/grpcGateway/telemetry.pb';
import { ListPatientVitalsResponseVitalType } from '@/shared/generated/grpcGateway/telemetry.pb';
import type {
  BloodGlucoseTags,
  BloodPressureTags,
  HeartRateTags,
  WeightTags,
} from '@/shared/types/tagsAndThreshold.types';
import type {
  BloodGlucoseReadingQualifier,
  PatientVitals,
} from '@/shared/types/vitals.types';

export function transformListVitalsToPatientVitals(
  listVitals: ListPatientVitalsResponseVital[],
): PatientVitals {
  const patientVitals: PatientVitals = {
    blood_pressure: [],
    heart_rate: [],
    weight: [],
    blood_glucose: [],
  };

  listVitals.forEach((vital) => {
    const id = vital.name || 'unknown';
    const timestamp = vital.timestamp?.slice(0, -1) || ''; // Remove 'Z' from the end
    const timezone = vital.timeZone || '';
    const tags = transformTags(vital.tags || {});
    const isRegular = vital.regular || true; // Double check: should we be defaulting to true?
    const isSuppressed = false;
    const baseVital = { id, timestamp, timezone, isSuppressed };

    switch (vital.type) {
      case ListPatientVitalsResponseVitalType.BLOOD_GLUCOSE:
        if (vital.value !== undefined) {
          patientVitals.blood_glucose.push({
            ...baseVital,
            glucose_level: vital.value,
            reading_qualifier:
              vital.readingQualifier as BloodGlucoseReadingQualifier,
            tags: tags as BloodGlucoseTags,
          });
        }
        break;

      case ListPatientVitalsResponseVitalType.BLOOD_PRESSURE:
        if (vital.value !== undefined && vital.secondaryValue !== undefined) {
          patientVitals.blood_pressure.push({
            ...baseVital,
            systolic: vital.value,
            diastolic: vital.secondaryValue,
            tags: tags as BloodPressureTags,
          });
        }
        break;

      case ListPatientVitalsResponseVitalType.HEART_RATE:
        if (vital.value !== undefined) {
          patientVitals.heart_rate.push({
            ...baseVital,
            pulse: vital.value,
            regular: isRegular,
            tags: tags as HeartRateTags,
          });
        }
        break;

      case ListPatientVitalsResponseVitalType.WEIGHT:
        if (vital.value !== undefined) {
          patientVitals.weight.push({
            ...baseVital,
            weight: vital.value,
            tags: tags as WeightTags,
          });
        }
        break;

      default:
        break;
    }
  });

  return patientVitals;
}

/*
    Ideally we should persist snake casing config using Bazel, but the ehr embedded app relies
    on camel case formatting for vital thresholds. To avoid an even larger refactor,
    we're transforming the data object here.
  */
function toSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function transformTags(tags: {
  [key: string]: ListPatientVitalsResponseThresholds;
}) {
  const transformedTags: Record<
    string,
    Record<string, ListPatientVitalsResponseThreshold>
  > = {};
  Object.keys(tags).forEach((key) => {
    const thresholds = tags[key];
    const transformedThresholds: Record<
      string,
      ListPatientVitalsResponseThreshold
    > = {};
    Object.entries(thresholds).forEach(([thresholdKey, thresholdValue]) => {
      const snakeCaseKey = toSnakeCase(thresholdKey);
      transformedThresholds[snakeCaseKey] = thresholdValue;
    });

    transformedTags[key] = transformedThresholds;
  });

  return transformedTags;
}
