import { useIntl } from 'react-intl';

import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';

export function CloseConfirmationDialog({
  closeAddNote,
  closeConfirmationDialog,
  isCloseConfirmationOpen,
}: {
  isCloseConfirmationOpen: boolean;
  closeAddNote: () => void;
  closeConfirmationDialog: () => void;
}) {
  const intl = useIntl();

  const closeAddNoteForm = () => {
    closeAddNote();
    closeConfirmationDialog();
  };

  return (
    <ConfirmationDialog
      isOpen={isCloseConfirmationOpen}
      onCancel={closeConfirmationDialog}
      onConfirm={closeAddNoteForm}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Discard changes',
      })}
      dialogTitle={intl.formatMessage({
        defaultMessage: 'Exit without saving',
      })}
      dialogDescription={intl.formatMessage({
        defaultMessage: 'Are you sure you want to exit without saving?',
      })}
    />
  );
}
