import { useIntl } from 'react-intl';

import type { NotificationAudit } from '@/shared/hooks/queries';

export const UNKNOWN_COMM_TYPE = 'UNKNOWN' as const;

export function useCommunicationTypei18nMap(): Record<
  OmitUndefined<
    NotificationAudit['notification_type'] | typeof UNKNOWN_COMM_TYPE
  >,
  string
> {
  const intl = useIntl();
  return {
    EMAIL: intl.formatMessage({ defaultMessage: 'Email' }),
    SMS: intl.formatMessage({ defaultMessage: 'SMS' }),
    DIRECT_MAIL: intl.formatMessage({ defaultMessage: 'Direct Mail' }),
    UNKNOWN: intl.formatMessage({ defaultMessage: 'N/A' }),
  };
}
