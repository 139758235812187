/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufEmpty from "./google/protobuf/empty.pb"
import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as fm from "./fetch.pb"

export enum GoalStatus {
  GOAL_STATUS_UNSPECIFIED = "GOAL_STATUS_UNSPECIFIED",
  OPEN = "OPEN",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ABANDONED = "ABANDONED",
}

export type SaveCCMGoalRequest = {
  ccmGoal?: CCMGoal
}

export type ListCCMGoalsRequest = {
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListCCMGoalsResponse = {
  ccmGoals?: CCMGoal[]
  nextPageToken?: string
  totalSize?: number
}

export type GetCCMGoalRequest = {
  name?: string
}

export type CreateCCMGoalRequest = {
  ccmGoal?: CCMGoal
}

export type DeleteCCMGoalRequest = {
  name?: string
}

export type CCMGoal = {
  name?: string
  patientId?: string
  ccmCarePlanId?: string
  measure?: string
  title?: string
  description?: string
  status?: GoalStatus
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
  goalTasks?: CCMGoalTask[]
}

export type CCMGoalTask = {
  name?: string
  description?: string
  completed?: boolean
}

export class CCMGoalService {
  static GetCCMGoal(req: GetCCMGoalRequest, initReq?: fm.InitReq): Promise<CCMGoal> {
    return fm.fetchReq<GetCCMGoalRequest, CCMGoal>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static CreateCCMGoal(req: CreateCCMGoalRequest, initReq?: fm.InitReq): Promise<CCMGoal> {
    return fm.fetchReq<CreateCCMGoalRequest, CCMGoal>(`/rpm/v1/ccmGoal`, {...initReq, method: "POST", body: JSON.stringify(req["ccm_goal"], fm.replacer)})
  }
  static ListCCMGoals(req: ListCCMGoalsRequest, initReq?: fm.InitReq): Promise<ListCCMGoalsResponse> {
    return fm.fetchReq<ListCCMGoalsRequest, ListCCMGoalsResponse>(`/rpm/v1/ccmGoal?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static DeleteCCMGoal(req: DeleteCCMGoalRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteCCMGoalRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static SaveCCMGoal(req: SaveCCMGoalRequest, initReq?: fm.InitReq): Promise<CCMGoal> {
    return fm.fetchReq<SaveCCMGoalRequest, CCMGoal>(`/rpm/v1/ccmGoal:save`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}