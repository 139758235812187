import { useIntl } from 'react-intl';

import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { initVisitPath } from '../../initialVisit/paths';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import type { SetActionStepsFields } from '../../shared/SetActionSteps';
import {
  SharedSetActionSteps,
  getSetActionStepsFormConfig,
} from '../../shared/SetActionSteps';
import { hasNewPatientGoal } from '../../shared/setPatientGoal';
import { regVisitPath } from '../paths';
import { usePatientGoalData, usePatientGoalText } from './usePatientGoal';

export function SetActionSteps() {
  const intl = useIntl();

  const patientGoalData = usePatientGoalData();
  const hasNewGoal = hasNewPatientGoal(patientGoalData);

  const path = regVisitPath('/action-plan', '/set-action-steps');
  const { isLoading, data } = useRecentNoteFormData<SetActionStepsFields>([
    path,
    initVisitPath('/action-plan', '/set-action-steps'),
  ]);
  const form = useWizardFormFromConfig<SetActionStepsFields>(path, {
    ...getSetActionStepsFormConfig(intl, hasNewGoal ? undefined : data),
    triggerReset: !isLoading,
  });

  const patientGoalText = usePatientGoalText();

  return (
    <SharedSetActionSteps
      form={form}
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={regVisitPath('/action-plan', '/review-action-steps')}
      patientGoalText={patientGoalText}
    />
  );
}
