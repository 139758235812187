import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTabPanel } from 'react-aria';
import type { Node, TabListState } from 'react-stately';

import { tabPanel } from './Tabs.css';

type Props<T> = {
  item: Node<T>;
  state: TabListState<T>;
};

export function TabPanel<T extends { keepMounted?: boolean }>({
  item,
  state,
  ...props
}: Props<T>) {
  const ref = useRef(null);
  const [visited, setVisited] = useState(false);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  const isSelected = state.selectedItem?.key === item.key;
  useEffect(() => {
    if (isSelected) {
      setVisited(true);
    }
  }, [isSelected]);

  if (isSelected || (visited && item.props.keepMounted)) {
    return (
      <div
        {...tabPanelProps}
        ref={ref}
        className={cx(
          tabPanel[isSelected ? 'visible' : 'hidden'],
          item.props.className,
        )}
      >
        {item.props.children}
      </div>
    );
  }
  return null;
}
