import { PhoneType } from '@/shared/types/shared.types';

// TODO i18n
export function getPhoneType(type: PhoneType) {
  switch (type) {
    case PhoneType.Landline:
      return 'Home';
    case PhoneType.Cellular:
      return 'Mobile';
    default:
      return type;
  }
}

// modified from https://stackoverflow.com/a/8358141
export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumber;
}

export function maskPhoneNumber(phoneNumber: string) {
  if (phoneNumber.trim().startsWith('+1')) {
    return phoneNumber;
  }
  return `+1${phoneNumber.trim()}`;
}
