import isObject from 'lodash/isObject';

import type { Schema } from '../Schema.types';
import type { DataObject, DataValue } from '../SchemaDrivenForm';
import { mapSchema } from './mapSchema';
import { mergeSchemas } from './mergeSchemas';

/**
 * Replaces the "if"/"then"/"else" part of the schema with either the "then" or
 * "else" subschema
 *
 * @param schema - Original schema
 * @param values - Values to evaluate the predicate on
 * @returns new schema
 */
export function mergeConditionalStatements<T extends DataObject>(
  schema: Schema,
  values: T,
) {
  return mapSchema(schema, mergeConditionalsMapperFn, values);
}

function mergeConditionalsMapperFn(schema: Schema, values?: DataValue) {
  let isPredicateTrue = true;
  const properties = schema.if?.properties;
  const notProperties = schema.if?.not?.properties;

  if (!properties && !notProperties) {
    return schema;
  }

  if (properties) {
    isPredicateTrue = isPredicateTrue && getPredicateValue(properties, values);
  }

  if (notProperties) {
    isPredicateTrue =
      isPredicateTrue && !getPredicateValue(notProperties, values);
  }

  const {
    if: ifPredicate,
    else: elseSubschema,
    then: thenSubschema,
    ...schemaWithoutConditionalStatement
  } = schema;

  return mergeSchemas(
    schemaWithoutConditionalStatement,
    (isPredicateTrue ? thenSubschema : elseSubschema) ?? {},
  );
}

function getPredicateValue(
  properties: { [name: string]: Schema },
  values?: DataValue,
) {
  return Object.entries(properties).every(([propName, predicate]) => {
    const value = typeof values === 'object' ? values[propName] : undefined;
    return assertPredicate(value, predicate);
  });
}

function assertPredicate(value: unknown, predicate: Schema): boolean {
  if (predicate.not) {
    if (predicate.not.const !== undefined) {
      return value !== predicate.not.const;
    }
    if (Array.isArray(predicate.not.enum)) {
      return !predicate.not.enum.includes(value);
    }
  }
  if (predicate.const !== undefined) {
    return value === predicate.const;
  }
  if (Array.isArray(predicate.enum)) {
    return predicate.enum.includes(value);
  }
  if (predicate.properties) {
    if (!isObject(value)) {
      return false;
    }
    return Object.entries(value).every(
      ([key, propertyPredicate]) =>
        !predicate.properties?.[key] ||
        assertPredicate(propertyPredicate, predicate.properties[key]),
    );
  }

  throw new Error('Invalid json schema if predicate');
}
