import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { generalAssessmentAndPlanModule } from './__shared__/assessmentAndPlanModule';
import { patientNotesModule } from './__shared__/patientNotesModule';

const MODULES = {
  ...patientNotesModule,
  ...generalAssessmentAndPlanModule,
};

export const regularCnVisit = {
  [TypeOfEncounter.CN_VISIT]: {
    [ConditionProgram.Hypertension]: { ...MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...MODULES },
    [ConditionProgram.T2DAndHTN]: { ...MODULES },
  },
};
