import { FormattedMessage } from 'react-intl';

import { useSidePanelCtx } from '@/shared/common/Page';

import { useNoteEditorContext } from '../../../Notes/NoteEditorContext';
import { useEnqueueOpenEditorError } from '../../../Notes/utils/useEnqueueOpenEditorError';
import { link } from './styles.css';

export function SmartEncountersNoteLink({ onClick }: { onClick?: () => void }) {
  const { handlers } = useSidePanelCtx();
  const { isEditorOpen, openEditor } = useNoteEditorContext();
  const enqueueOpenEditorError = useEnqueueOpenEditorError();

  function openNewEditor() {
    if (isEditorOpen) {
      enqueueOpenEditorError();
      return;
    }
    openEditor();
  }

  function handleLink() {
    handlers.setIsVisible(true);
    openNewEditor();
    onClick?.();
  }

  return (
    <span
      className={link}
      tabIndex={0}
      role="button"
      onClick={handleLink}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleLink();
        }
      }}
    >
      <FormattedMessage defaultMessage="Smart Encounter Note" />
    </span>
  );
}
