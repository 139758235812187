import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { PatientChangeNotificationsRequest } from '@/shared/generated/grpcWeb/pms_pb';
import { PatientServiceClient } from '@/shared/generated/grpcWeb/pms_pb.client';
import { ClientFactory, retry } from '@/shared/grpcWeb';

import { patientKeys } from './queries';

export function useListenToPatientChangeNotifications(patientId?: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!patientId) {
      return () => {};
    }

    const psClient = ClientFactory(PatientServiceClient);
    const abortController = new AbortController();

    retry(
      () => {
        const req = PatientChangeNotificationsRequest.create({ patientId });
        const serverStreamingCall = psClient.patientChangeNotifications(req, {
          abort: abortController.signal,
        });

        serverStreamingCall.responses.onMessage(() => {
          // Invalidate old patientDetails query. Currently we are only getting change notifications for the activated_at field, which is on the old endpoint, so we will need to also invalidate the new version if we listen to any fields that are on the new patientDetails endpoint
          queryClient.invalidateQueries(patientKeys.detail(patientId));
        });

        return serverStreamingCall;
      },
      { exponential: true },
    );

    return () => abortController.abort();
  }, [queryClient, patientId]);
}
