import { FormattedMessage } from 'react-intl';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { usePatientDetails } from '@/shared/hooks/queries';
import { arrayNotEmpty } from '@/shared/utils/array';

import { SubHeader } from '../../SubHeader';
import { OrdersProgramDetails } from './OrdersProgramDetails';

type Props = {
  patientId: string;
};

export function OrdersProgramsConditions({ patientId }: Props) {
  const { isSuccess: loadedPatient, data: patientDetails } = usePatientDetails(
    patientId,
    true,
  );

  return (
    <LoadingPlaceholder isLoading={!loadedPatient}>
      {loadedPatient && (
        <div>
          <SubHeader>
            <FormattedMessage defaultMessage="Patient Programs & Conditions" />
          </SubHeader>
          {arrayNotEmpty(patientDetails?.programs) ? (
            <OrdersProgramDetails programs={patientDetails.programs} />
          ) : (
            <FormattedMessage defaultMessage="No program information found for patient." />
          )}
        </div>
      )}
    </LoadingPlaceholder>
  );
}
