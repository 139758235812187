import { useIntl } from 'react-intl';

import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';

type CareGuideInfo = {
  link: string;
  goal: string;
};

export const CCM_CONDITION_RESOURCES: Record<CcmCondition, CareGuideInfo> = {
  [Condition.Asthma]: {
    link: 'https://www.notion.so/cadencesolutions/Asthma-Education-Guide-ebb7f5fcadd044099d62d7cea69010d5?pvs=25',
    goal: 'Achieving good control of asthma-related symptoms and minimizing future risk (asthma exacerbations, suboptimal lung function, adverse effects of medication)',
  },
  [Condition.AfibAndFlutter]: {
    link: 'https://www.notion.so/cadencesolutions/Atrial-Fibrillation-and-Flutter-Education-Guides-5090d62bd2054bfdaf40db1fe1ef06ee?pvs=25',
    goal: 'Slow the ventricular rate in patients with atrial fibrillation (AF) to treat symptoms, stabilize hemodynamics symptoms, and/or to avoid tachycardia-mediated cardiomyopathy',
  },
  [Condition.ChronicKidneyDisease]: {
    link: 'https://www.notion.so/cadencesolutions/Chronic-Kidney-Disease-Education-Guides-NOT-ESRD-Dialysis-624d84e1217f4f888bbe2d849eae10d3?pvs=25',
    goal: 'Protect against both progressive kidney function loss and cardiovascular disease',
  },
  [Condition.COPD]: {
    link: 'https://www.notion.so/cadencesolutions/Chronic-Obstructive-Pulmonary-Disease-Education-Guide-a40a421c076d4f6480cf10a90656edaa?pvs=25',
    goal: 'Relieving symptoms; preventing and treating disease progression, complications, and exacerbations; improving exercise tolerance, daily activity, and health status; and reducing premature mortality',
  },
  [Condition.Dementia]: {
    link: 'https://www.notion.so/cadencesolutions/Dementia-Education-Guide-06012ccd138e479d8984b7e57c00c835?pvs=25',
    // TODO: Add a goal here once Dementia is a Cadence Supported CCM Condition
    goal: '',
  },
  [Condition.TypeTwoDiabetes]: {
    link: 'https://www.notion.so/cadencesolutions/Diabetes-Education-Guide-1bb07b0ee8174ecfa424c8eebde6d7c5?pvs=25',
    goal: 'A1C < 7% and fasting glucose 80-125 mg/dL without hypoglycemia',
  },
  [Condition.CHF]: {
    link: 'https://www.notion.so/cadencesolutions/Heart-Failure-Education-Guide-9a4f67a171e64197b479f9ab04f9e1bd?pvs=25',
    goal: 'Reduce symptoms, improve health-related quality of life and functional status, and decrease the rate of hospitalization, and to reduce mortality',
  },
  [Condition.Hyperlipidemia]: {
    link: 'https://www.notion.so/cadencesolutions/Hyperlipidemia-High-Cholesterol-Education-Guide-b7c5e7dddd2b4af68a32cb2d7c91d39c?pvs=25',
    goal: 'Lower cholesterol with a combination of lifestyle changes and, if determined by provider, medication',
  },
  [Condition.Hypertension]: {
    link: 'https://www.notion.so/cadencesolutions/Hypertension-Education-Guide-17e3f8b9f3534375937effb34454c205?pvs=25',
    goal: 'Goal BP < 130/80',
  },
  [Condition.Hypothyroidism]: {
    link: 'https://www.notion.so/cadencesolutions/Hypothyroidism-Education-Guide-ad924b5810ed4d6a904cadd650fda720?pvs=25',
    goal: 'Amelioration of symptoms, normalization of TSH secretion. Generally target TSH within the normal reference range (approximately 0.5 to 5.0 mU/L)',
  },
  [Condition.IschemicHeartDisease]: {
    link: 'https://www.notion.so/cadencesolutions/Ischemic-Heart-Disease-Education-Guide-0c00df05615c4928804851caa4838846?pvs=25',
    goal: 'Relieve symptoms, and prevent future cardiac events such as acute coronary syndromes, revascularization, decline in health/functional status or death',
  },
  [Condition.MorbidObesity]: {
    link: 'https://www.notion.so/cadencesolutions/Morbid-Obesity-Education-Guide-787c576fd7e4464b8d204fb78a3578a0?pvs=25',
    goal: 'Prevent, treat, or reverse the complications of obesity and improve quality of life',
  },
  [Condition.ObstructiveSleepApnea]: {
    link: 'https://www.notion.so/cadencesolutions/Obstructive-Sleep-Apnea-db422639b19245db8a827c8536bf9faf?pvs=25',
    goal: 'Improve/resolve signs and symptoms of OSA, improve sleep quality',
  },
  [Condition.Osteoarthritis]: {
    link: 'https://www.notion.so/cadencesolutions/Osteoarthritis-c4630b0654a642378f8a14d76d224724?pvs=25',
    goal: 'Minimize pain, optimize function, and beneficially modify the process of joint damage',
  },
  [Condition.PeripheralArteryDisease]: {
    link: 'https://www.notion.so/cadencesolutions/Peripheral-Arterial-Disease-141b6dddc80847cfae3bdbb648b9376a?pvs=25',
    goal: 'Improving symptoms, lowering the risk of future cardiovascular events and limb complications, and to potentially limit the progression of atherosclerosis',
  },
};

export function useCcmConditionI18n(): Record<CcmCondition, string> {
  const intl = useIntl();
  return getCcmConditionI18n(intl);
}
