{{!-- For RPM only --}}
{{#ifContains programTypes "RPM"}}
  {{#ifNotContains programTypes "CCM"}} {{!-- Ensure it's only RPM --}}
    <p>Patient of [provider] disenrolled in the Cadence Remote Patient Monitoring program on [date]. Cadence processed the disenrollment, and this note serves as notification that vitals readings taken on Cadence-issued devices will no longer be monitored by the Cadence Team.</p>
  {{/ifNotContains}}
{{/ifContains}}

{{!-- For CCM only --}}
{{#ifContains programTypes "CCM"}}
  {{#ifNotContains programTypes "RPM"}} {{!-- Ensure it's only CCM --}}
    <p>Patient of [provider] disenrolled in the Cadence Chronic Care Management program on [date]. Cadence has processed the disenrollment.</p>
  {{/ifNotContains}}
{{/ifContains}}

{{!-- For RPM+CCM --}}
{{#ifContains programTypes "RPM"}}
  {{#ifContains programTypes "CCM"}}
    <p>Patient of [provider] disenrolled in the Cadence Remote Patient Monitoring and Chronic Care Management program on [date]. Cadence processed the disenrollments, and this note serves as notification that vitals readings taken on Cadence-issued devices from the Cadence Remote Patient Monitoring Program will no longer be monitored by the Cadence Team.</p>
  {{/ifContains}}
{{/ifContains}}

<p>Please reach out to the Cadence Team with any questions or concerns. Thank you.</p>
