import type { UIEventHandler } from 'react';
import { useEffect, useRef } from 'react';

import { NoteEditorLayout } from './noteEditor.types';

export function useSyncScrollPosition(layout: NoteEditorLayout) {
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);
  const panelRef = useRef<Nullable<HTMLDivElement>>(null);
  const scrollPosition = useRef(0);

  useEffect(() => {
    const element =
      layout === NoteEditorLayout.TwoColumn
        ? panelRef.current
        : containerRef.current;

    // in reality this should always exist, but need to check for it to make TS happy
    if (element) {
      element.scrollTop = scrollPosition.current;
    }
  }, [layout]);

  const onScroll: UIEventHandler<HTMLDivElement> = (event) => {
    // when in a two column layout, we don't care about the container's scrollTop (it shouldn't scroll)
    // similarly, when in a one column layout, we don't care about the panel's scrollTop for the same
    // reason. these two clauses are here to discard events that we don't care about.
    if (
      event.currentTarget === containerRef.current &&
      layout === NoteEditorLayout.TwoColumn
    ) {
      return;
    }

    if (
      event.currentTarget === panelRef.current &&
      layout === NoteEditorLayout.OneColumn
    ) {
      return;
    }

    scrollPosition.current = event.currentTarget.scrollTop;
  };

  return {
    onScroll,
    containerRef,
    panelRef,
  };
}
