import { FormattedMessage } from 'react-intl';

export const MED_INFO_FIELDS = {
  med_titration: <FormattedMessage defaultMessage="Medication Plan" />,
  med_name: <FormattedMessage defaultMessage="Medication name" />,
  titration_date: {
    start_date: <FormattedMessage defaultMessage="Start date" />,
    default: <FormattedMessage defaultMessage="Date" />,
  },
  titration_end_date: <FormattedMessage defaultMessage="End date" />,
  titration_reason: <FormattedMessage defaultMessage="Reason for change" />,
  previous_dose: <FormattedMessage defaultMessage="Previous dosage" />,
  med_dose: {
    new_dose: <FormattedMessage defaultMessage="New dosage" />,
    default: <FormattedMessage defaultMessage="Dosage" />,
  },
  med_frequency: <FormattedMessage defaultMessage="Frequency" />,
  is_target_dose: <FormattedMessage defaultMessage="Target dose achieved?" />,
  is_max_tolerated_dose: (
    <FormattedMessage defaultMessage="Maximum tolerated dose achieved?" />
  ),
  reason_not_on_target_dose: (
    <FormattedMessage defaultMessage="If no, provide a reason" />
  ),
  reason_not_on_max_tolerated_dose: (
    <FormattedMessage defaultMessage="If no, provide a reason" />
  ),
};

export const MEDICATIONS_VALIDATION_PROPERTY_NAMES = {
  ...MED_INFO_FIELDS,
  med_info: <FormattedMessage defaultMessage="Medication Plan" />,
  reason_not_on_target_dose: (
    <FormattedMessage defaultMessage="Reason not on target dose" />
  ),
  reason_not_on_max_tolerated_dose: (
    <FormattedMessage defaultMessage="Reason not on maximum tolerated dose" />
  ),
  is_target_dose: <FormattedMessage defaultMessage="Target dose achieved" />,
  is_max_tolerated_dose: (
    <FormattedMessage defaultMessage="Maximum tolerated dose achieved" />
  ),
};

export const DOSE_REASONS = {
  medical: <FormattedMessage defaultMessage="Medical reason" />,
  health_team: <FormattedMessage defaultMessage="Health team reason" />,
  system_based: <FormattedMessage defaultMessage="System based reason" />,
  patient_centered: (
    <FormattedMessage defaultMessage="Patient centered reason" />
  ),
};

export const YES_NO_OPTIONS = {
  yes: <FormattedMessage defaultMessage="Yes" />,
  no: <FormattedMessage defaultMessage="No" />,
};

export const MED_TITRATION_OPTIONS = {
  'start medication': <FormattedMessage defaultMessage="Start medication" />,
  'continue medication': (
    <FormattedMessage defaultMessage="Continue medication" />
  ),
  'stop medication': <FormattedMessage defaultMessage="Stop medication" />,
  'up-titrate': <FormattedMessage defaultMessage="Up titrate medication" />,
  'down-titrate': <FormattedMessage defaultMessage="Down titrate medication" />,
};

export const MED_TITRATION_OPTION_PREVIEWS = {
  'start medication': <FormattedMessage defaultMessage="Start" />,
  'continue medication': <FormattedMessage defaultMessage="Continue" />,
  'stop medication': <FormattedMessage defaultMessage="Stop" />,
  'up-titrate': <FormattedMessage defaultMessage="Up titrate" />,
  'down-titrate': <FormattedMessage defaultMessage="Down titrate" />,
};

export const MEDICATION_CLASSES = {
  acei_arb_arni: (
    <FormattedMessage defaultMessage="Renin angiotensin aldosterone system inhibitors (i.e. ACEi, ARB, ARNI)" />
  ),
  mra: (
    <FormattedMessage defaultMessage="Mineralocorticoid receptor antagonist" />
  ),
  sglt2i: (
    <FormattedMessage defaultMessage="Sodium-glucose co-transporter 2 inhibitor" />
  ),
  beta_blocker: <FormattedMessage defaultMessage="Beta blocker" />,
  loop_diuretic: <FormattedMessage defaultMessage="Loop diuretic" />,
  thiazide_diuretic: <FormattedMessage defaultMessage="Thiazide diuretic" />,
  acei: <FormattedMessage defaultMessage="ACEi" />,
  arb: <FormattedMessage defaultMessage="ARB" />,
  dihypdropyridine: (
    <FormattedMessage defaultMessage="Calcium channel blocker (Dihydropyridine)" />
  ),
  nondihydropyridine: (
    <FormattedMessage defaultMessage="Calcium channel blocker (Nondihydropyridine)" />
  ),
  aldosterone_antagonist: (
    <FormattedMessage defaultMessage="Aldosterone antagonist" />
  ),
  renin_inhibitor: <FormattedMessage defaultMessage="Renin inhibitor" />,
  alpha_1_blocker: <FormattedMessage defaultMessage="Alpha-1 blocker" />,
  central_alpha_2_agonist: (
    <FormattedMessage defaultMessage="Central alpha-2 agonist" />
  ),
  vasodilator: <FormattedMessage defaultMessage="Vasodilator" />,
  ivabradine: <FormattedMessage defaultMessage="Ivabradine" />,
  biguanide: <FormattedMessage defaultMessage="Biguanide" />,
  glp1_receptor_agonist: (
    <FormattedMessage defaultMessage="Glucagon-like peptide-1 receptor agonist" />
  ),
  dpp4_inhibitor: (
    <FormattedMessage defaultMessage="Dipeptidyl peptidase IV inhibitor" />
  ),
  thiazolidinedione: <FormattedMessage defaultMessage="Thiazolidinedione" />,
  sulfonylurea: <FormattedMessage defaultMessage="Sulfonylurea" />,
  basal_insulin: <FormattedMessage defaultMessage="Basal insulin" />,
  prandial_insulin: <FormattedMessage defaultMessage="Prandial insulin" />,
  u_500_insulin: <FormattedMessage defaultMessage="U-500 regular insulin" />,
};
