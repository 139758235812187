{{#if flags.autopopulateEncounterLabs}}
  {{#if latestLabs}}
    {{> free_text_space }}
    {{#each latestLabs}}
      <strong>{{date labDate timezone}} {{groupName}}</strong>
      <ul>
      {{#each values}}
        <li><i>{{analyte}}:</i> {{#ifTruthy value}}{{value}} {{unit}}{{else}}---{{/ifTruthy}}</li>
      {{/each}}
      </ul>
    {{/each}}
  {{/if}}
{{/if}}
