import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ConfiguredTaskFilterContext } from '@/components/TaskHubTray/TaskFilterContext';
import { TaskHubBody } from '@/components/TaskHubTray/TaskHubBody';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { SidebarTray } from '@/shared/common/SidebarTray';
import { useCareProvider } from '@/shared/hooks/queries';
import { useTask } from '@/shared/hooks/queries/tasks.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { TaskDetail } from '@/shared/tasking/TaskDetail';
import { ParentView } from '@/shared/tasking/TaskList';
import { TaskListContextProvider } from '@/shared/tasking/TaskList/taskListContext';
import { grpcNameToId } from '@/shared/utils/grpc';

import { areaTitle, homeTaskArea } from './HomePage.css';

export function HomeTaskArea() {
  const [activeTaskId, setActiveTaskId] = useState<string | undefined>();
  const { data: activeTask } = useTask(activeTaskId ?? '', {
    enabled: !!activeTaskId,
  });
  const { currentUserId } = useCurrentUser();
  const { data: loggedInProvider } = useCareProvider(currentUserId);

  if (!loggedInProvider) {
    return <LoadingPlaceholder isLoading />;
  }

  return (
    <div className={homeTaskArea}>
      <div className={areaTitle}>
        <FormattedMessage defaultMessage="Tasks" />
      </div>
      <ConfiguredTaskFilterContext>
        <TaskHubBody
          parentView={ParentView.HomePage}
          activeTaskId={activeTaskId}
          onOpenTaskDetail={(task) => setActiveTaskId(grpcNameToId(task.name))}
        />
      </ConfiguredTaskFilterContext>
      <SidebarTray
        isOpen={!!activeTask}
        position="right"
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setActiveTaskId(undefined);
          }
        }}
        content={({ state }) => (
          <>
            <SidebarTray.Header onClose={state.close}>
              <FormattedMessage defaultMessage="Task Detail" />
            </SidebarTray.Header>
            {activeTask && (
              <TaskListContextProvider
                value={{
                  loggedInProvider,
                  parentView: ParentView.HomePage,
                  taskHubOverlayState: null,
                }}
              >
                <TaskDetail task={activeTask} />
              </TaskListContextProvider>
            )}
          </>
        )}
      />
    </div>
  );
}
