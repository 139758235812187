import type { ReactNode } from 'react';

import { Stack } from '@/deprecated/mui';

interface ActionsProps {
  children: ReactNode;
  className?: string;
  direction?: 'row' | 'row-reverse';
}

export function Actions({
  children,
  direction = 'row',
  ...rest
}: ActionsProps) {
  return (
    <Stack spacing={2} direction={direction} {...rest}>
      {children}
    </Stack>
  );
}
