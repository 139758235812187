import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';

type Diff = Record<string, unknown>;

/**
 * Gets a deep object diff
 */
export function getObjectDiff(
  outerObj1: Maybe<Record<string, unknown>>,
  outerObj2: Maybe<Record<string, unknown>>,
): Diff {
  function getDiff(
    innerObj1: Maybe<Record<string, unknown>>,
    innerObj2: Maybe<Record<string, unknown>>,
  ): Diff {
    if (innerObj1 === null && innerObj2 === null) return {};
    if (innerObj1 === undefined && innerObj2 === undefined) return {};
    if (!innerObj1 || !innerObj2) return { old: innerObj1, new: innerObj2 };

    return reduce(
      innerObj1,
      (acc: Diff, value: unknown, key: string) => {
        if (!isEqual(value, innerObj2[key])) {
          if (isObject(value) && isObject(innerObj2[key])) {
            const nestedDiff = getDiff(
              value as Maybe<Record<string, unknown>>,
              innerObj2[key] as Maybe<Record<string, unknown>>,
            );
            if (!isEmpty(nestedDiff)) {
              acc[key] = nestedDiff;
            }
          } else {
            acc[key] = { old: value, new: innerObj2[key] };
          }
        }
        return acc;
      },
      {} as Diff,
    );
  }

  return getDiff(outerObj1, outerObj2);
}
