import * as yup from 'yup';

import { validator } from './factory';

type ArrayValidatorParams = {
  minLength?: number;
  minLengthMessage?: string;
  maxLength?: number;
  maxLengthMessage?: string;
  ofType: yup.AnySchema;
};

export const arrayValidator = validator(
  (intl, params: ArrayValidatorParams) => {
    const errorMessage = intl.formatMessage({
      defaultMessage: 'Must be a valid array',
    });

    let schema = yup.array().of(params.ofType).typeError(errorMessage);

    if (params.minLength) {
      const message =
        params.minLengthMessage ??
        intl.formatMessage(
          { defaultMessage: 'Select at least {minLength}' },
          { minLength: params.minLength },
        );
      schema = schema.min(params.minLength, message);
    }

    if (params.maxLength) {
      const message =
        params.maxLengthMessage ??
        intl.formatMessage(
          { defaultMessage: 'Select no more than {maxLength}' },
          { maxLength: params.maxLength },
        );
      schema = schema.max(params.maxLength, message);
    }

    return schema;
  },
);
