import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { h3, h4 } from './CNNoteBodyPreview.css';

type Props = {
  body: string;
};

export function CNNoteBodyPreview({ body }: Props) {
  return (
    <Markdown
      remarkPlugins={[remarkBreaks, remarkGfm]}
      components={{
        h3: ({ children }) => <h3 className={h3}>{children}</h3>,
        h4: ({ children }) => <h4 className={h4}>{children}</h4>,
      }}
    >
      {body}
    </Markdown>
  );
}
