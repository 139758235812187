import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { appointmentKeys } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { ConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import { autosavedNotesQueryKey } from '@/shared/hooks/queries/autosave-notes.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getErrorMsg } from '@/shared/utils/helpers';

import { useDeleteAutosavedCnNote } from '../../shared/query.hooks';

type Props = {
  patientId: string;
  isOpen: boolean;
  close: () => void;
  onDeleted: () => void;
};

export function DeleteAutosaveModal({
  isOpen,
  close,
  onDeleted,
  patientId,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const { currentUserId: providerId } = useCurrentUser();
  const { mutate: deleteAutosavedNote } = useDeleteAutosavedCnNote(patientId, {
    async onSuccess() {
      close();
      onDeleted();
      await queryClient.invalidateQueries(
        appointmentKeys.list({
          apptStartTimeFrom: formatISO(startOfDay(new Date())),
          apptStartTimeTo: formatISO(endOfDay(new Date())),
          careProviderId: providerId,
        }),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.nextScheduled(patientId),
      );
      await queryClient.invalidateQueries(
        autosavedNotesQueryKey.patient(patientId),
      );
      toaster.success(
        intl.formatMessage({
          defaultMessage: 'Autosaved note has been deleted',
        }),
      );
    },
    onError(error: unknown) {
      toaster.error(getErrorMsg(error));
    },
  });

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={close}
      onConfirm={deleteAutosavedNote}
      confirmButtonText={<FormattedMessage defaultMessage="Discard updates" />}
      dialogTitle={
        <FormattedMessage defaultMessage="Discard updates to this autosave?" />
      }
      dialogDescription={
        <FormattedMessage defaultMessage="Are you sure you want to discard updates to this note?" />
      }
    />
  );
}
