import { useIntl } from 'react-intl';

import { displayVitalTimestampInPtTz } from '../datetimeUtils';
import { timebox } from './VitalsTable.css';

type VitalTimestampProps = {
  children: string;
};

export function VitalTimestamp({ children: timestamp }: VitalTimestampProps) {
  const intl = useIntl();
  return (
    <div className={timebox}>
      {displayVitalTimestampInPtTz(timestamp) ??
        intl.formatMessage({ defaultMessage: 'N/A' })}
    </div>
  );
}
