import cx from 'classnames';
import type { ReactElement } from 'react';

import { ellipses, step } from './ProgressStep.css';
import Ellipse from './ellipse.svg?react';

type Props = {
  active?: boolean;
  complete?: boolean;
  children: ReactElement;
  id: string;
};

export function ProgressStep({ id, active, complete, children }: Props) {
  return (
    <div
      className={cx(step.default, {
        [step.active]: active,
        [step.complete]: complete,
      })}
      id={id}
    >
      <Ellipse
        className={cx(ellipses.default, {
          [ellipses.active]: active,
          [ellipses.complete]: complete,
        })}
      />
      {children}
    </div>
  );
}
