import type {
  EncounterModuleInstance,
  EncounterTypeInputs,
  EncounterTypeModuleSchema,
} from '../../Notes.types';
import { EncounterModuleId } from '../../Notes.types';
import { useEncounterModule } from '../../note.queries';

export function useIsTimeTrackedTypeOfEncounterFromInstances(
  encounterModuleInstances: EncounterModuleInstance[],
) {
  return useGetIsTimeTrackedTypeOfEncounterFromInstances()(
    encounterModuleInstances,
  );
}

export function useIsTimeTrackedTypeOfEncounter(
  encounterTypeInputs?: EncounterTypeInputs,
) {
  return useGetIsTimeTrackedTypeOfEncounter()(encounterTypeInputs);
}

export function useGetIsTimeTrackedTypeOfEncounterFromInstances() {
  const getIsTimeTrackedTypeOfEncounter = useGetIsTimeTrackedTypeOfEncounter();
  return (encounterModuleInstances: EncounterModuleInstance[]) =>
    getIsTimeTrackedTypeOfEncounter(
      encounterModuleInstances.find(
        (instance) =>
          instance.encounter_module_id === EncounterModuleId.EncounterType,
      )?.inputs,
    );
}

function useGetIsTimeTrackedTypeOfEncounter() {
  const { encounterModule: encounterTypeModule } = useEncounterModule(
    EncounterModuleId.EncounterType,
  );
  const timeTrackedTypesOfEncounter = (
    encounterTypeModule?.schema as Maybe<EncounterTypeModuleSchema>
  )?.time_tracking_required?.type_of_encounter;

  return (encounterTypeInputs?: EncounterTypeInputs) =>
    encounterTypeInputs?.type_of_encounter &&
    timeTrackedTypesOfEncounter &&
    timeTrackedTypesOfEncounter.includes(encounterTypeInputs.type_of_encounter);
}
