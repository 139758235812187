import { type UseFormReturn, useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Button } from '@/shared/tempo/atom/Button';

import { RemovableRow } from '../RemovableRow';
import type { FormFields } from '../formConfig';
import { addItemButton, sectionTitle } from './carePlanSections.css';

type Props = {
  form: UseFormReturn<FormFields>;
  manualAutoSave: () => void;
};

export function PastSurgicalHistory({ form, manualAutoSave }: Props) {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'surgicalHistories',
  });

  return (
    <Form.Section
      title={intl.formatMessage({ defaultMessage: 'Past Surgical History' })}
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <RemovableRow
          key={field.id}
          onChange={() => manualAutoSave()}
          onRemove={() => remove(index)}
          hasError={!!form.formState.errors.surgicalHistories?.[index]}
        >
          <Form.TextField
            size={5}
            label={intl.formatMessage({
              defaultMessage: 'Diagnosis',
            })}
            name={`surgicalHistories.${index}.diagnosis`}
          />
          <Form.TextField
            size={6}
            label={intl.formatMessage({
              defaultMessage: 'Specialist/Surgeon/Hospital',
            })}
            name={`surgicalHistories.${index}.details`}
          />
        </RemovableRow>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() => append({ details: '', diagnosis: '' })}
        >
          <FormattedMessage defaultMessage="Add Medical History" />
        </Button>
      </div>
    </Form.Section>
  );
}
