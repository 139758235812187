import type { WidgetProps } from '@rjsf/core';

/**
 * rjsf omitExtraData/liveOmit functionality kicks in after two subsequent form changes
 * instead of after one as we would like it to, so we make each change
 * to be done twice so it works. The need to do this may be caused by our
 * schema / formData transformations or other custom things we are doing.
 */
export function getOmitExtraDataOnChange(
  rjsfOnChange: WidgetProps['onChange'],
) {
  return (value: unknown) => {
    rjsfOnChange(value);
    setTimeout(() => rjsfOnChange(value));
  };
}
