import type { PaginatedData } from './pagination.types';

export const DeviceTypes = {
  WEIGHT_SCALE: 'WEIGHT_SCALE',
  BLOOD_PRESSURE_METER: 'BLOOD_PRESSURE_METER',
  BLOOD_GLUCOSE_METER: 'BLOOD_GLUCOSE_METER',
} as const;

export type DeviceType = (typeof DeviceTypes)[keyof typeof DeviceTypes];

export const DeviceProvider = {
  BODYTRACE: 'BODYTRACE',
  BIOTEL: 'BIOTEL',
  SMART_METER: 'SMART_METER',
  WITHINGS: 'WITHINGS',
  TRANSTEK: 'TRANSTEK',
} as const;

export type DeviceProviderType = keyof typeof DeviceProvider;

export enum DeviceProvisioningStatus {
  Success = 'SUCCESS',
  InvalidDeviceId = 'INVALID_DEVICE_ID',
  ApiError = 'API_ERROR',
  ServerError = 'SERVER_ERROR',
  UnknownError = 'UNKNOWN_ERROR',
}

export type ParsedData = {
  provider: DeviceProviderType;
  device_type: DeviceType;
  imei: string;
  serial_number: string;
};

export type Device = {
  id: number;
  provider: DeviceProviderType;
  device_type: DeviceType;
  imei: string;
  serial_number: string;
  is_provisioned: boolean;
  provisioning_status: DeviceProvisioningStatus;
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;
};

// Devices list response
export type DevicesResponse = PaginatedData<Device>;
