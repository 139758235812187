import { ConditionProgram } from '@/shared/types/condition.types';

import { generalAssessmentAndPlanModule } from './assessmentAndPlanModule';
import { clinicalAttestationModule } from './clinicalAttestationModule';
import { patientNotesModule } from './patientNotesModule';

export const t2dAndHtn = {
  [ConditionProgram.T2DAndHTN]: {
    ...patientNotesModule,
    ...generalAssessmentAndPlanModule,
    ...clinicalAttestationModule,
  },
};
