import cx from 'classnames';
import { isValid, parse } from 'date-fns';
import { useState } from 'react';
import { useId } from 'react-aria';
import { FormattedMessage } from 'react-intl';

import { DatePicker } from '@/shared/common/DatePicker';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Label } from '@/shared/tempo/atom/Label';

import { datepicker, datepickerContainer } from './VitalReminders.css';

type Props = {
  initialDate: string | null;
  isSaving: boolean;
  onSave: (date: Date | null) => void;
};

// no idea why date-fns doesn't do this itself
function isValidDate(value: unknown): value is Date {
  return isValid(value);
}

export function RemindersPausedDate({ initialDate, isSaving, onSave }: Props) {
  const datePickerId = useId();
  const initSnooze = initialDate
    ? parse(initialDate, 'yyyy-MM-dd', new Date())
    : null;
  const [snooze, setSnooze] = useState<Date | null>(initSnooze);
  const [isInputValid, setIsInputValid] = useState(true);
  const isDirty = snooze?.getTime() !== initSnooze?.getTime();

  return (
    <div className={cx(flexContainer.column, gap.XS, datepickerContainer)}>
      <Label
        label={
          <FormattedMessage defaultMessage="SMS vital reminders paused until" />
        }
        labelProps={{ htmlFor: datePickerId }}
        hasError={!isInputValid}
      />
      <div className={cx(flexContainer.row, gap.S)}>
        <DatePicker
          classes={{ input: datepicker }}
          isDisabled={isSaving}
          value={snooze}
          onChange={(value: unknown) => {
            if (isValidDate(value) || value === null) {
              setIsInputValid(true);

              // we only care about the date, so zero out the time
              // to make comparison easier for isDirty
              value?.setHours(0, 0, 0, 0);
              setSnooze(value);
            } else {
              setIsInputValid(false);
            }
          }}
          InputProps={{ id: datePickerId }}
          isError={!isInputValid}
          disablePast
        />
        <Button
          variant="primary"
          onPress={() => onSave(snooze)}
          isDisabled={!isInputValid || !isDirty}
          isProcessing={isSaving}
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </div>
    </div>
  );
}
