import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { SharedChartPrep } from '../../shared/ChartPrep';
import { regVisitPath } from '../paths';

export function ChartPrep() {
  return (
    <SharedChartPrep
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={regVisitPath('/intro', '/patient-attendance')}
    />
  );
}
