import { EHR } from '@/shared/types/ehr.types';

import { EHR_CONFIG_MAP } from './ehrConfig';

export function getEhrName(ehr?: EHR) {
  if (!ehr || ehr === EHR.NON_INTEGRATED) {
    return null;
  }

  return EHR_CONFIG_MAP[ehr].name;
}
