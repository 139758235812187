import type { Theme } from './themes';
import { THEMES } from './themes';

export function userInitials(givenName: string, familyName: string) {
  return `${givenName.charAt(0)}${familyName.charAt(0)}`.toLocaleUpperCase();
}

// Hashes a user name to an avatar theme
export function getTheme(firstName: string, lastName: string): Theme {
  const stringToHash = `${firstName}${lastName}`;
  return THEMES[getThemeIndex(stringToHash)];
}

function hashString(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function getThemeIndex(str: string) {
  const hash = hashString(str);
  const index = Math.abs(hash) % THEMES.length;
  return index;
}
