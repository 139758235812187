import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import blueLogo from '@/shared/assets/blueLogo.png';
import emailSent from '@/shared/assets/emailSent.png';

import '../ForgotPasswordPage/ForgotPasswordPage.scss';

class ForgotPasswordPrompt extends Component {
  render() {
    return (
      <>
        <div className="forgot-password-success-logo">
          <img src={blueLogo} alt="blueLogo" />
          <div className="forgot-password-success-page-container">
            <div className="forgot-password-success-page-image">
              <img src={emailSent} alt="emailSent" />
            </div>
            <div className="forgot-password-success-title">
              <FormattedMessage defaultMessage="We sent a link to your email account to reset your password." />
              <div className="clear" />
              <div className="forgot-password-form-title-content">
                <FormattedMessage defaultMessage="Check your inbox, if you don't receive an email, please call Cadence at 1 (855) 613-0778." />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPasswordPrompt;
