import type { GoalModalState } from '../../Goals/goalModal.types';

export enum ModalType {
  Delete = 'delete',
  Approve = 'approve',
}

export type ModalState =
  | { type: ModalType.Delete }
  | { type: ModalType.Approve }
  | GoalModalState;
