import type {
  DiagnosisCodes,
  Patient,
  ProspectivePatient,
} from '@/shared/types/patient.types';

import {
  getPrimaryICD10CodesByProgram,
  getPrimaryICD10CodesFromProgramAndStatus,
} from './patients.utils';

export function getProblemList(diagnosisCodes?: DiagnosisCodes): string[] {
  if (!diagnosisCodes || !diagnosisCodes.code_names) {
    return [];
  }
  const codeNames = diagnosisCodes.code_names;
  return Object.entries(codeNames).map(([code_name, icd10]) => {
    if (!icd10?.code_description) {
      return code_name;
    }
    return icd10.code_description;
  });
}

/**
 * Returns the primary ICD-10 codes for prospective patient's conditions
 */
export function getPrimaryIcd10Codes(
  patient: ProspectivePatient | Patient,
): Nullable<string> {
  return 'program' in patient
    ? getPrimaryICD10CodesByProgram(patient.program)
    : getPrimaryICD10CodesFromProgramAndStatus(
        patient.programs,
        patient.status,
      );
}
