import { useCallback } from 'react';

import { useFlags } from '@/shared/hooks';
import type { CareModelVersion } from '@/shared/types/featureFlags.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import type { TypeOfEncounter } from '../../Notes.types';
import { EncounterModuleId } from '../../Notes.types';
import { ensureCorrectModulesArePresent } from '../../utils/encounterModuleInstanceUtils';
import { mergeModuleInstanceInputs } from '../../utils/encounterModuleUtils';
import { InitialEncounterTypeInstance } from '../../utils/encounterTypeUtils';
import { useGetFieldTemplateMap } from '../templates/hooks';
import type { TemplateContext } from '../templates/types';

function createTemplateFromType(
  newType: TypeOfEncounter | undefined,
  context: TemplateContext,
  careModelVersion: CareModelVersion,
  getFieldTemplateMap: (
    encounterType: TypeOfEncounter | undefined,
    moduleId: EncounterModuleId,
    context: TemplateContext,
  ) => Record<string, unknown>,
) {
  // Reset encounter module instances when changing encounter type
  const blankEncounterModuleInstances = [InitialEncounterTypeInstance];

  // Ensure that all correct modules are present before templating
  const transformedModuleInstances = ensureCorrectModulesArePresent(
    newType,
    blankEncounterModuleInstances,
    careModelVersion,
  );

  const templatedInstances = transformedModuleInstances.map((module) => {
    if (module.encounter_module_id === EncounterModuleId.EncounterType) {
      return {
        ...module,
        inputs: mergeModuleInstanceInputs(module.inputs, {
          type_of_encounter: newType,
          visit_layout: true,
        }),
      };
    }

    if (module.encounter_module_id === EncounterModuleId.Medications) {
      return {
        ...module,
        inputs: {},
      };
    }

    return {
      ...module,
      inputs: mergeModuleInstanceInputs(
        module.inputs,
        getFieldTemplateMap(newType, module.encounter_module_id, context),
      ),
    };
  });
  return templatedInstances;
}

export function useInjectTemplates() {
  const { setEncounterModuleInstances } = useNoteEditorContext();
  const getFieldTemplateMap = useGetFieldTemplateMap();
  const { careModelVersion } = useFlags();

  const injectTemplates = useCallback(
    (newType: TypeOfEncounter | undefined, context: TemplateContext) => {
      const templatedInstances = createTemplateFromType(
        newType,
        context,
        careModelVersion,
        getFieldTemplateMap,
      );
      setEncounterModuleInstances(templatedInstances);
    },
    [careModelVersion, getFieldTemplateMap, setEncounterModuleInstances],
  );

  return { injectTemplates };
}

export function useInjectTemplateValue() {
  const getFieldTemplateMap = useGetFieldTemplateMap();
  const { careModelVersion } = useFlags();

  const injectTemplates = useCallback(
    (newType: TypeOfEncounter | undefined, context: TemplateContext) => {
      const templatedInstances = createTemplateFromType(
        newType,
        context,
        careModelVersion,
        getFieldTemplateMap,
      );
      return templatedInstances;
    },
    [careModelVersion, getFieldTemplateMap],
  );

  return { injectTemplates };
}
