import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { AriaButtonProps, PressEvent } from 'react-aria';
import { FocusRing, useButton } from 'react-aria';

import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { Spinner } from '../Spinner';
import {
  button,
  buttonActive,
  buttonFocused,
  buttonSize,
  invisible,
  positionRelative,
  spinner,
} from './Button.css';

export type Variant = 'primary' | 'secondary' | 'tertiary';

type Props = {
  className?: string;
  classes?: {
    focused?: Partial<Record<Variant, string>>;
    active?: Partial<Record<Variant, string>>;
    default?: Partial<Record<Variant, string>>;
  };
  children: ReactNode;
  size?: 'default' | 'small';
  variant?: Variant;
  isProcessing?: boolean;
  isDisabled?: boolean;
  onPress?: (e: PressEvent) => void;
} & AriaButtonProps<'button'>;

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      classes = {},
      children,
      isProcessing,
      isDisabled,
      size = 'default',
      variant = 'primary',
      onPress,
      ...props
    },
    forwardedRef,
  ) => {
    const ref = useObjectRef(forwardedRef);
    const { buttonProps, isPressed } = useButton(
      { ...props, isDisabled: isProcessing || isDisabled, onPress },
      ref,
    );
    const activeClass = classes?.active?.[variant] ?? undefined;

    return (
      <FocusRing
        focusRingClass={cx(
          focusRingCss.keyboard,
          buttonFocused[variant],
          classes?.focused?.[variant],
        )}
      >
        <button
          type="button"
          {...props}
          {...buttonProps}
          disabled={isProcessing || buttonProps.disabled}
          ref={ref}
          className={cx(
            button[variant],
            classes?.default?.[variant],
            buttonSize[size],
            {
              [buttonActive[variant]]: isPressed,
              ...(activeClass && {
                [activeClass]: isPressed,
              }),
              [positionRelative]: isProcessing,
            },
            className,
          )}
        >
          {!isProcessing ? (
            children
          ) : (
            <>
              <Spinner size="small" variant="processing" className={spinner} />
              <div className={invisible}>{children}</div>
            </>
          )}
        </button>
      </FocusRing>
    );
  },
);
