import type { RpcTransport } from '@protobuf-ts/runtime-rpc';

import { webFetchTransport } from '@/shared/grpcWeb/transports';

type GrpcWebClientService<T> = new (transport: RpcTransport) => T;

/**
 * Takes in a proto-ts autogenerated gRPC Web Service Client class and returns
 * a pre-configured instance
 */
export function ClientFactory<T>(Clazz: GrpcWebClientService<T>): T {
  return new Clazz(webFetchTransport());
}
