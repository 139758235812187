import type { IntlShape } from 'react-intl';

import { validators } from '@/shared/common/Form/validations';

export type GoalProgressFormFields = {
  goalProgressRating: number;
};

export function getGoalProgressFormConfig(intl: IntlShape) {
  const { required, number } = validators(intl);

  return {
    fields: {
      goalProgressRating: {
        defaultValue: undefined,
        validation: required(number({ min: 1, max: 10 })),
      },
    },
  };
}
