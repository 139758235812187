import { useIntl } from 'react-intl';

import { CareProviderStatus } from '@/shared/generated/grpcGateway/cadence/models/models.pb';
import { ProviderStatus } from '@/shared/types/provider.types';

export function useProviderStatusI18nMapping() {
  const intl = useIntl();
  return {
    [ProviderStatus.PROSPECT]: intl.formatMessage({
      defaultMessage: 'Prospect',
    }),
    [ProviderStatus.ONBOARDED]: intl.formatMessage({
      defaultMessage: 'Onboarded',
    }),
    [ProviderStatus.DEACTIVATED]: intl.formatMessage({
      defaultMessage: 'Deactivated',
    }),
    [ProviderStatus.UNKNOWN]: intl.formatMessage({ defaultMessage: 'Unknown' }),
  };
}

export function useCareProviderStatusI18nMapping() {
  const intl = useIntl();
  return {
    [CareProviderStatus.PROSPECT]: intl.formatMessage({
      defaultMessage: 'Prospect',
    }),
    [CareProviderStatus.ONBOARDED]: intl.formatMessage({
      defaultMessage: 'Onboarded',
    }),
    [CareProviderStatus.DEACTIVATED]: intl.formatMessage({
      defaultMessage: 'Deactivated',
    }),
    [CareProviderStatus.UNKNOWN]: intl.formatMessage({
      defaultMessage: 'Unknown',
    }),
    [CareProviderStatus.STATUS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}
