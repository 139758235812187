import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { queriesAreLoading } from '@/reactQuery';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import {
  usePostMonitoringSession,
  usePutMonitoringSession,
} from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type { RouteParam } from '@/shared/types/route.types';

import type { TimeTrackingFormFields } from '../../ManualTimeTrackingForm';
import { deleteDraftButton } from './MutateEntryModal.css';
import {
  getManualEntryCreatePayload,
  getManualEntryUpdatePayload,
} from './payload.utils';

type Props = {
  entryId?: string;
  isLateEdit?: boolean;
  form: ConfiguredForm<TimeTrackingFormFields>;
  onClose: () => void;
  onDelete?: () => void;
};

export function ActionsFooter({
  onClose,
  entryId,
  isLateEdit,
  form,
  onDelete,
}: Props) {
  const { patientId }: RouteParam = useParams();
  const { currentUserFullName } = useCurrentUser();
  const saveCallbacks = useSaveMutationCallbacks(onClose);
  const createTimeEntry = usePostMonitoringSession(patientId, saveCallbacks);
  const updateTimeEntry = usePutMonitoringSession(patientId, saveCallbacks);
  const isUpdate = Boolean(entryId);
  const isMutating = queriesAreLoading([createTimeEntry, updateTimeEntry]);

  return (
    <Modal.Footer
      tertiary={
        isUpdate && !isLateEdit ? (
          <Button
            id="delete-draft"
            variant="tertiary"
            isDisabled={isMutating}
            className={deleteDraftButton}
            onPress={onDelete}
          >
            <FormattedMessage defaultMessage="Delete record" />
          </Button>
        ) : null
      }
    >
      <Button id="close" variant="secondary" onPress={onClose}>
        <FormattedMessage defaultMessage="Close" />
      </Button>
      <Button
        id="save-entry"
        type="submit"
        isDisabled={!form.canSubmit}
        isProcessing={isMutating}
        variant="primary"
        onPress={() => {
          form.handleSubmit((values) => {
            if (isUpdate && entryId) {
              updateTimeEntry.mutate(
                getManualEntryUpdatePayload(
                  patientId,
                  entryId,
                  values,
                  currentUserFullName,
                ),
              );
            } else {
              createTimeEntry.mutate(
                getManualEntryCreatePayload(
                  patientId,
                  values,
                  currentUserFullName,
                ),
              );
            }
          })();
        }}
      >
        <FormattedMessage defaultMessage="Save time record" />
      </Button>
    </Modal.Footer>
  );
}

function useSaveMutationCallbacks(onClose: () => void) {
  const intl = useIntl();
  const { toaster } = useToaster();
  return {
    session: {
      onSuccess: () => {
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Time record successfully saved',
          }),
        );
      },
      onError: () => {
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to save time record',
          }),
        );
      },
    },
    note: {
      onSuccess: () => {
        onClose();
      },
      onError: () => {
        toaster.error(
          intl.formatMessage({
            defaultMessage: "Failed to save time record's note",
          }),
        );
        onClose();
      },
    },
  };
}
