import { AsyncTitrationAsyncTitrationStatus as Status } from '@/shared/generated/grpcGateway/medication.pb';
import { NoteStatus } from '@/shared/generated/grpcGateway/note.pb';

import type { TitrationRecommendation } from './hooks';

export function isAsyncTitrationPending(
  titration?: TitrationRecommendation,
  noteId?: Maybe<number>,
) {
  if (!titration?.status || !titration?.initialReviewNoteStatus) {
    return false;
  }

  return (
    titration.status === Status.INITIALLY_REVIEWED &&
    (titration.initialReviewNoteStatus === NoteStatus.PUBLISHED ||
      titration.initialReviewNoteId === noteId)
  );
}
