import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { resultTableCell } from './results.css';

export function ResultCell({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLTableCellElement>) {
  return (
    <td {...props} className={cx(resultTableCell, className)}>
      {children}
    </td>
  );
}
