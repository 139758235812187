import { useMemo } from 'react';

import { CareModelVersion } from '@/shared/types/featureFlags.types';

import type {
  EncounterModuleInstance,
  EncounterTypeInputs,
} from '../Notes.types';
import { EncounterModuleId, TypeOfEncounter } from '../Notes.types';
import { useEncounterModule } from '../note.queries';
import { getInstance } from './encounterModuleInstanceUtils';

const POTENTIAL_FIELDS_TO_OMIT = [
  // These fields are removed from the schema in https://github.com/cadencerpm/monorepo/pull/3475
  // so we must check to see if the deployed schema contains these fields
  'sms',
  'phone_call',
  'patient_outreach_follow_up',
  // This field is removed from the schema because it is populated by the UI using the value of the visit_layout feature flag. Even once the feature flag is removed, keep omitted until it is removed from draft instances
  // https://cadencerpm.atlassian.net/browse/PLAT-4505
  'visit_layout',
];

/**
 * TODO: This and all references can be removed once we migrate all draft
 * notes to delete removed encounter instance properties
 * https://cadencerpm.atlassian.net/browse/PLAT-4152
 * and
 * https://cadencerpm.atlassian.net/browse/PLAT-4505
 */
export function useEncounterTypePropsToOmit() {
  const { encounterModule } = useEncounterModule(
    EncounterModuleId.EncounterType,
  );

  // If the encounterModule schema does not have sms or phone_number anymore, these fields
  // should be ommitted from encounter instances
  const schemaProps = encounterModule?.schema.properties;
  return useMemo(
    () =>
      POTENTIAL_FIELDS_TO_OMIT.filter(
        (fieldName) =>
          // If the field has been removed from the schema props, we must omit it
          schemaProps && !schemaProps[fieldName],
      ),
    [schemaProps],
  );
}

export function getEncounterTypeInstance(
  encounterModuleInstances: EncounterModuleInstance[],
) {
  return getInstance<EncounterTypeInputs>(
    encounterModuleInstances,
    EncounterModuleId.EncounterType,
  );
}

export function isEncounterTypeInstance(
  instance: EncounterModuleInstance<unknown>,
): instance is EncounterModuleInstance<EncounterTypeInputs> {
  return instance.encounter_module_id === EncounterModuleId.EncounterType;
}

export function getEncounterType(
  encounterModuleInstances: EncounterModuleInstance[],
) {
  return getEncounterTypeInstance(encounterModuleInstances)?.inputs
    ?.type_of_encounter;
}

/**
 * @returns true if note was created with the visit_layout feature flag enabled
 */
export function getIsFromVisitLayout(
  encounterModuleInstances: EncounterModuleInstance[],
) {
  return Boolean(
    getEncounterTypeInstance(encounterModuleInstances)?.inputs?.visit_layout,
  );
}

/**
 * @returns true if the encounter type indicates this visit is with a clinician,
 * and therefore should include the clinical modules (Medications, Symptoms, and Emergency Visits)
 */
export function isClinicianVisit(encounterType?: TypeOfEncounter) {
  return (
    isRegularVisitClinicianType(encounterType) ||
    isAdHocClinicalEncounterType(encounterType) ||
    encounterType === TypeOfEncounter.CCM_CARE_PLAN ||
    encounterType === TypeOfEncounter.TITRATION_OUTREACH
  );
}

export function isRegularVisitClinicianType(encounterType?: TypeOfEncounter) {
  return Boolean(
    encounterType &&
      [
        TypeOfEncounter.INITIAL_NP_VISIT,
        TypeOfEncounter.NP_VISIT,
        TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED,
        TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED,
        TypeOfEncounter.CCM_VISIT,
      ].includes(encounterType),
  );
}

export function isClinicalNavigatorEncounterType(
  encounterType?: TypeOfEncounter,
) {
  return Boolean(
    encounterType &&
      [
        TypeOfEncounter.INITIAL_CN_VISIT,
        TypeOfEncounter.CN_VISIT,
        TypeOfEncounter.CN_CHF_VISIT,
        TypeOfEncounter.INITIAL_CN_CHF_VISIT,
      ].includes(encounterType),
  );
}

export function isNPEncounterType(encounterType?: TypeOfEncounter) {
  return Boolean(
    encounterType &&
      [TypeOfEncounter.INITIAL_NP_VISIT, TypeOfEncounter.NP_VISIT].includes(
        encounterType,
      ),
  );
}

export function isPreV3ClinicalNavigatorEncounterType(
  careModelVersion: CareModelVersion,
  encounterType?: TypeOfEncounter,
) {
  return (
    careModelVersion !== CareModelVersion.V3 &&
    isClinicalNavigatorEncounterType(encounterType)
  );
}

export function isAdHocClinicalEncounterType(encounterType?: TypeOfEncounter) {
  return Boolean(
    encounterType &&
      [
        TypeOfEncounter.ALERT_DOCUMENTATION,
        TypeOfEncounter.PATIENT_INBOUND,
        TypeOfEncounter.RESULTS_FOLLOW_UP,
      ].includes(encounterType),
  );
}

export function isCCMVisit(encounterType?: TypeOfEncounter) {
  return (
    encounterType &&
    [TypeOfEncounter.CCM_CARE_PLAN, TypeOfEncounter.CCM_VISIT].includes(
      encounterType,
    )
  );
}

export function showClinicalAttestation(
  encounterType: TypeOfEncounter,
  careModelVersion: CareModelVersion,
) {
  if (encounterType === TypeOfEncounter.CCM_VISIT) {
    return true;
  }

  if (isPreV3ClinicalNavigatorEncounterType(careModelVersion, encounterType)) {
    return true;
  }

  return false;
}

export const InitialEncounterTypeInstance: EncounterModuleInstance<EncounterTypeInputs> =
  {
    encounter_module_id: EncounterModuleId.EncounterType,
    inputs: {},
  };
