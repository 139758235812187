import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { infoMessage } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import { DietForm } from '../../shared/generalAssessment';
import type { DietFormFields } from '../../shared/generalAssessment/formConfig';
import { getDietFormConfig } from '../../shared/generalAssessment/formConfig';
import { initVisitPath } from '../paths';

export function Diet() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<DietFormFields>(
    initVisitPath('/general-assessment', '/diet'),
    getDietFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Diet' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <Script>
        <FormattedMessage defaultMessage="Eating habits are another important piece of your overall health. Can you tell me a little bit about what a day of eating looks like for you? Do you like to cook? Do you often eat out?" />
      </Script>
      <InlineMessage className={infoMessage}>
        <FormattedMessage defaultMessage="This is a conversational moment to learn more about the patient's habits. Engage the patient here to learn more about their routine before drilling down into the questions below." />
      </InlineMessage>
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="I have a few specific questions to ask about your diet as well. How many times a week do you eat fast food or processed foods?" />
        </Script>
        <DietForm form={form} />
      </FieldGroup>
    </Wizard.Step>
  );
}
