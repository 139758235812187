import cx from 'classnames';
import { format, isValid, parseISO } from 'date-fns';
import { type ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RecentNoteModal } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/chfVisit/actionPlan/RecentNoteModal';
import { isCnNote } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/note.utils';
import { getEncounterType } from '@/pages/patients/patientDetails/ui/Notes/utils/encounterTypeUtils';
import FileIcon from '@/shared/assets/svgs/file.svg?react';
import { Table } from '@/shared/common/Table';
import {
  flexAlignItems,
  flexContainer,
  flexJustify,
} from '@/shared/jsStyle/flex.css';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import type { Note } from '@/shared/types/note.types';

import { metadata, noteDataRow, table } from './PatientActivity.css';

type Props = {
  children: ReactNode;
  note: Note;
};

export function NoteDataRow({ children, note }: Props) {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const noteType = isCnNote(note)
    ? note.clinical_navigator_note?.[0].visitType
    : getEncounterType(note.encounter_instances ?? []);
  const parsedDate = parseISO(note.updated_at ?? '');
  const date = isValid(parsedDate)
    ? format(parsedDate, 'MM/dd/yyyy')
    : intl.formatMessage({ defaultMessage: 'Invalid Date' });

  return (
    <>
      {modalOpen && (
        <RecentNoteModal
          note={note}
          onClose={() => setModalOpen(false)}
          title={noteType}
        />
      )}
      <Table.Row>
        <Table.NodeCell className={table.cell}>
          <div
            className={cx(
              flexContainer.row,
              flexAlignItems.center,
              flexJustify.spaceBetween,
            )}
          >
            <div>
              <div className={noteDataRow.symptoms}>{children}</div>
              <span className={metadata}>
                <FormattedMessage
                  defaultMessage="From {noteType} with {author} on {date}"
                  values={{
                    noteType:
                      noteType ||
                      intl.formatMessage({
                        defaultMessage: 'Unknown Note Type',
                      }),
                    author: note.author,
                    date,
                  }}
                />
              </span>
            </div>
            <IconButton
              size="small"
              variant="tertiary"
              onPress={() => setModalOpen(true)}
            >
              <FileIcon width="20" height="20" />
            </IconButton>
          </div>
        </Table.NodeCell>
      </Table.Row>
    </>
  );
}
