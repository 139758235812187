import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { patientNotesModule } from './__shared__/patientNotesModule';

export const welcomeCall = {
  [TypeOfEncounter.WELCOME_CALL]: {
    [ConditionProgram.CHF]: { ...patientNotesModule },
    [ConditionProgram.Hypertension]: { ...patientNotesModule },
    [ConditionProgram.T2DAndHTN]: { ...patientNotesModule },
    [ConditionProgram.TypeTwoDiabetes]: { ...patientNotesModule },
  },
};
