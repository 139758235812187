import { useIntl } from 'react-intl';

import { useToaster } from '@/shared/tempo/molecule/Toast';

export function useCopyToClipboard() {
  const { toaster } = useToaster();
  const intl = useIntl();

  return (text: string, successMessage?: string, errorMessage?: string) =>
    navigator.clipboard
      .writeText(text)
      .then(() =>
        toaster.success(
          successMessage ||
            intl.formatMessage({ defaultMessage: 'Copied to clipboard' }),
        ),
      )
      .catch(() =>
        toaster.error(
          errorMessage ||
            intl.formatMessage({
              defaultMessage: 'Failed to copy to clipboard',
            }),
        ),
      );
}
