import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useAssignedDevices } from '@/shared/hooks/queries/devices.queries';
import { usePatientContext } from '@/shared/hooks/queries/patientContext.queries';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';
import { useVitalsEngagement } from '@/shared/hooks/useVitalsEngagement';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';

import type { CnTemplateContext } from '../types';

export function useCnTemplateContext(patientId: string, noteId: Maybe<number>) {
  const flags = useFlags();
  const { data: context, isLoading: isContextLoading } =
    usePatientContext(patientId);
  const {
    data: patientVitalsContext,
    isLoading: isPatientVitalsContextLoading,
  } = usePatientVitalsContext(patientId);
  const contextVitals = patientVitalsContext?.contextVitals;
  const { data: devices, isLoading: isDevicesLoading } = useAssignedDevices(
    patientId,
    {},
  );
  const { isLoading: isLoadingVitalsEngagement, data: vitalsEngagement } =
    useVitalsEngagement(patientId);
  const { data: patientDetailsContext, isLoading: isPatientDetailsLoading } =
    usePatientDetails(patientId, false);
  const { data: patientMedications, isLoading: isPatientMedsLoading } =
    usePatientMedications(patientId);
  const deviceTypes = useMemo(
    () => uniq(devices?.data?.map((d) => d.device_type) || []),
    [devices?.data],
  );
  const intl = useIntl();

  const templateContext: CnTemplateContext = {
    ...(patientDetailsContext && {
      rpmConditions: getRpmConditionsFromProgramAndStatus(
        patientDetailsContext.programs,
        patientDetailsContext.status,
      ),
    }),
    ...context,
    ...contextVitals,
    vitalsEngagement,
    patientMedications,
    flags,
    deviceTypes,
    noteId,
    intl,
  };
  const isLoading = [
    isContextLoading,
    isPatientVitalsContextLoading,
    isDevicesLoading,
    isPatientDetailsLoading,
    isPatientMedsLoading,
    isLoadingVitalsEngagement,
  ].some(Boolean);

  return {
    isLoading,
    context: templateContext,
  };
}
