import { FormattedMessage } from 'react-intl';

import MedicalHistory from '@/shared/assets/svgs/medicalHistory.svg?react';
import { Button } from '@/shared/tempo/atom/Button';
import { color } from '@/shared/tempo/theme';

import { container, iconContainer, subText } from './EmptyState.css';

type Props = {
  onCreateGoal: () => void;
};

export function EmptyState({ onCreateGoal }: Props) {
  return (
    <div className={container}>
      <div className={iconContainer}>
        <MedicalHistory
          fill={color.Theme.Light.Primary}
          width="24"
          height="24"
        />
      </div>
      <div className={subText}>
        <FormattedMessage defaultMessage="Use goals to track care gaps and important tasks for the patient" />
      </div>
      <Button size="small" variant="tertiary" onPress={onCreateGoal}>
        <FormattedMessage defaultMessage="Create New Goal" />
      </Button>
    </div>
  );
}
