import { FormattedMessage } from 'react-intl';

export const PSYCHOSOCIAL_ASSESSMENT_OPTIONS = [
  {
    name: 'livingSituation',
    label: <FormattedMessage defaultMessage="Living Situation" />,
  },
  {
    name: 'food',
    label: <FormattedMessage defaultMessage="Food" />,
  },
  {
    name: 'transportation',
    label: <FormattedMessage defaultMessage="Transportation" />,
  },
  {
    name: 'utilities',
    label: <FormattedMessage defaultMessage="Utilities" />,
  },
  {
    name: 'safety',
    label: <FormattedMessage defaultMessage="Safety" />,
  },
  {
    name: 'physicalActivity',
    label: <FormattedMessage defaultMessage="Physical Activity" />,
  },
] as const;
