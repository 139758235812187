/* eslint-disable class-methods-use-this */
import { datadogLogs } from '@datadog/browser-logs';

import type { LogEntry } from '../logger.types';
import { LogEmitter } from './LogEmitter';

export class DatadogEmitter extends LogEmitter {
  debug({ message, metadata }: LogEntry): void {
    datadogLogs.logger.debug(message, metadata);
  }

  info({ message, metadata }: LogEntry): void {
    datadogLogs.logger.info(message, metadata);
  }

  warn({ message, metadata }: LogEntry): void {
    datadogLogs.logger.warn(message, metadata);
  }

  // noop, errors go to sentry for now
  error(): void {}
}
