import type { IntlFormatters } from 'react-intl';
import { useIntl } from 'react-intl';

import { EncounterModuleId, TypeOfEncounter } from '../../Notes.types';
import type { RequirableEncounterModule } from './EncounterModule';

type Messages = {
  title: string;
  description: string;
  optionLabel: string;
};

type MessagesByModule = {
  [key in RequirableEncounterModule]: Messages;
};

export function useEncounterModuleIntlMsgs(
  moduleId: RequirableEncounterModule,
  encounterType?: TypeOfEncounter,
) {
  const { formatMessage } = useIntl();

  const messagesByModule: MessagesByModule = {
    [EncounterModuleId.Medications]: {
      title: getMedicationsTitle(formatMessage),
      description: formatMessage({
        defaultMessage: 'Please list recent changes in medications',
      }),
      optionLabel: formatMessage({
        defaultMessage: 'Add medications',
      }),
    },
    [EncounterModuleId.Symptoms]: {
      title: getSymptomsTitle(formatMessage),
      description: formatMessage({
        defaultMessage: 'Please list recent changes in symptoms',
      }),
      optionLabel: formatMessage({
        defaultMessage: 'Add symptoms',
      }),
    },
    [EncounterModuleId.Hospitalization]: {
      title: getHospitalizationTitle(formatMessage, encounterType),
      description: formatMessage({
        defaultMessage: 'Please list any emergency visits',
      }),
      optionLabel: formatMessage({
        defaultMessage: 'Add visits',
      }),
    },
  };

  return messagesByModule[moduleId];
}

function getHospitalizationTitle(
  formatMessage: IntlFormatters['formatMessage'],
  encounterType?: TypeOfEncounter,
) {
  switch (encounterType) {
    case TypeOfEncounter.INITIAL_NP_VISIT:
    case TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED:
    case TypeOfEncounter.INITIAL_CN_VISIT:
      return formatMessage({
        defaultMessage: 'Emergency Visits in last 12 months',
      });
    case TypeOfEncounter.NP_VISIT:
    case TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED:
    case TypeOfEncounter.CN_VISIT:
    case TypeOfEncounter.CCM_VISIT:
    case TypeOfEncounter.CCM_CARE_PLAN:
      return formatMessage({
        defaultMessage: 'Emergency Visits in last 3 months',
      });
    default:
      return formatMessage({ defaultMessage: 'Emergency Visits' });
  }
}

export function getSymptomsTitle(
  formatMessage: IntlFormatters['formatMessage'],
) {
  return formatMessage({
    defaultMessage: 'Symptoms',
  });
}

export function getMedicationsTitle(
  formatMessage: IntlFormatters['formatMessage'],
) {
  return formatMessage({
    defaultMessage: 'Disease-specific Medications',
  });
}
