import cx from 'classnames';
import type { ReactNode } from 'react';

import { container, subContainer, title } from './ResourceTableContainer.css';

export function ResourceTableContainer({
  className,
  header,
  children,
}: {
  className?: string;
  header: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className={cx(container, className)}>
      <div className={subContainer}>{header}</div>
      {children}
    </div>
  );
}

export function ResourceTableTitle({
  children,
  resourceCount,
}: {
  children: ReactNode;
  resourceCount?: number;
}) {
  return (
    <span className={title}>
      {children}
      &nbsp;
      {resourceCount !== undefined ? `(${resourceCount})` : ''}
    </span>
  );
}
