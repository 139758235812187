import cx from 'classnames';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  container,
  mins,
  subContainer,
  timeContainer,
  title,
} from './TotalTimeDisplay.css';

type Props = {
  error: ReactNode;
  minutes: number;
};

export function TotalTimeDisplay({ minutes, error }: Props) {
  return (
    <div className={container}>
      <div className={subContainer}>
        <div
          className={cx(title.default, {
            [title.error]: !!error,
          })}
        >
          <FormattedMessage defaultMessage="Total time" />
        </div>
        <div className={timeContainer}>
          <span className={mins.text}>{minutes}</span>
          <span className={mins.suffix}>mins</span>
        </div>
      </div>
      {error}
    </div>
  );
}
