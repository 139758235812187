export const Sort = {
  Ascending: 'asc',
  Descending: 'desc',
  None: undefined,
} as const;

export type SortDirection = (typeof Sort)[keyof typeof Sort];
export type CompositeSortDirection = SortDirection[];

export type SortKey<T = string> = T;
export type CompositeSortKey<K> = SortKey<K>[];
