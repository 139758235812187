import { useIntl } from 'react-intl';

import { OpenInNewTabLink } from '@/shared/common/OpenInNewTabLink';

export function BillableTimeGuidanceLink() {
  const intl = useIntl();
  return (
    <OpenInNewTabLink
      text={intl.formatMessage({
        defaultMessage: 'Billable time guidance',
      })}
      href="https://www.notion.so/cadencesolutions/Time-Tracking-Job-Aid-c020d803898c4cff81ba3063ed886061"
    />
  );
}
