import cx from 'classnames';
import { useListBoxSection } from 'react-aria';
import type { ListState, Node, SelectionMode } from 'react-stately';

import { Option } from './Option';
import {
  header,
  list,
  nestedOption,
  section as sectionClass,
} from './Section.css';

type Props<T> = {
  state: ListState<T>;
  section: Node<T>;
  selectionMode?: SelectionMode;
};

export function Section<T>({ section, state, selectionMode }: Props<T>) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <li {...itemProps} className={cx(sectionClass, itemProps.className)}>
      {section.rendered && (
        <span {...headingProps} className={header}>
          {section.rendered}
        </span>
      )}
      <ul {...groupProps} className={list}>
        {[...(state.collection.getChildren?.(section.key) || [])].map(
          (node) => (
            <Option
              className={cx({ [nestedOption]: selectionMode !== 'multiple' })}
              key={node.key}
              item={node}
              state={state}
              selectionMode={selectionMode}
            />
          ),
        )}
      </ul>
    </li>
  );
}
