import { combineEpics } from 'redux-observable';

import createNpiEpic from './createNpiEpic';
import deleteNpiEpic from './deleteNpiEpic';
import fetchNpiByIdEpic from './fetchNpiByIdEpic';
import fetchNpiEpic from './fetchNpiEpic';
import updateNpiEpic from './updateNpiEpic';

const npiEpics = combineEpics(
  fetchNpiEpic,
  fetchNpiByIdEpic,
  createNpiEpic,
  updateNpiEpic,
  deleteNpiEpic,
);

export default npiEpics;
