import type { SxProps, Theme } from '@/deprecated/mui';

export const flexSection = (
  direction = 'row',
  justify = 'center',
  align = 'center',
  flex = 'flex',
) =>
  ({
    display: flex,
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
  }) as SxProps<Theme>;
