import type { AriaToastRegionProps } from '@react-aria/toast';
import type { ToastOptions } from '@react-stately/toast';
import { useToastState } from '@react-stately/toast';
import isEqual from 'lodash/isEqual';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { usePrevious } from '@/shared/hooks/usePreviousState';

import { ToastRegion } from './ToastRegion';
import { ToastContextProvider } from './ToasterContext';
import type { Config, ToastContents, Toaster } from './types';
import { isContentConfig } from './types';

type Props = {
  children: ReactNode;
} & AriaToastRegionProps;

const DEFAULT_OPTIONS: ToastOptions = {
  timeout: 4000,
};

export function ToastProvider({ children, ...props }: Props) {
  const state = useToastState<ToastContents>({
    maxVisibleToasts: 2,
  });
  const prevState = usePrevious(state);
  const stateChange = !isEqual(prevState, state);

  function add(content: ToastContents, options?: ToastOptions) {
    if (!content.content && !content.title) {
      return null;
    }

    return state.add(content, options);
  }

  const toaster: Toaster = useMemo(
    () => ({
      ...state,
      success(content, opts) {
        return add(
          {
            variant: 'success',
            ...toContentConfig(content),
          },
          { ...DEFAULT_OPTIONS, ...opts },
        );
      },
      info(content, opts) {
        return add(
          {
            variant: 'info',
            ...toContentConfig(content),
          },
          { ...DEFAULT_OPTIONS, ...opts },
        );
      },
      alert(content, opts) {
        return add(
          {
            variant: 'alert',
            ...toContentConfig(content),
          },
          { ...DEFAULT_OPTIONS, ...opts },
        );
      },
      error(content, opts) {
        return add(
          {
            variant: 'error',
            ...toContentConfig(content),
          },
          { ...DEFAULT_OPTIONS, ...opts },
        );
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateChange],
  );

  return (
    <ToastContextProvider value={{ toaster }}>
      {children}
      <ToastRegion {...props} state={state} />
    </ToastContextProvider>
  );
}

function toContentConfig<T>(content: T | Config<T>): Config<T> {
  if (isContentConfig(content)) {
    return { ...content };
  }
  return { content };
}
