import { usePatientVitalsAlertsByStatus } from '@/shared/hooks/queries';
import { AlertStatus } from '@/shared/types/alert.types';

export function usePatientVitalsAlerts(
  patientId: string,
  isProfilePage: boolean,
) {
  return usePatientVitalsAlertsByStatus({
    patientId,
    includeStatuses: [
      AlertStatus.Active,
      ...(isProfilePage ? [AlertStatus.Pending] : []),
    ],
  });
}

export function usePatientVitalsAlertsData(
  patientId: string,
  isProfilePage: boolean,
) {
  const patientVitalsAlertsQuery = usePatientVitalsAlerts(
    patientId,
    isProfilePage,
  );
  return patientVitalsAlertsQuery.data?.data ?? [];
}
