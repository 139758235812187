import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { useCNFormContext } from '../../../../CNFormContext';
import { useShowVitalsEducation } from '../../../../sectionUtils';
import { REGULAR_VISIT_SECTIONS } from '../../../metadata';
import type { SecondarySymptomsFormFields } from '../../../shared/SecondarySymptoms';
import {
  SharedSecondarySymptoms,
  getSecondarySymptomsFormConfig,
} from '../../../shared/SecondarySymptoms';
import { regVisitPath } from '../../paths';

export function SecondarySymptoms() {
  const intl = useIntl();
  const { program } = useCNFormContext();
  const form = useWizardFormFromConfig<SecondarySymptomsFormFields>(
    regVisitPath('/symptoms', '/secondary'),
    getSecondarySymptomsFormConfig(intl, program),
  );

  const showVitalsEducation = useShowVitalsEducation();

  return (
    <SharedSecondarySymptoms
      form={form}
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={
        showVitalsEducation
          ? regVisitPath('/product-support', '/feedback')
          : regVisitPath('/product-support', '/intro')
      }
    />
  );
}
