import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import AthenaLogo from '@/shared/assets/svgs/athena.svg?react';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import type { Hospital } from '@/shared/types/hospital.types';
import type { PatientDetails } from '@/shared/types/patient.types';

import { rpaButtonDisabledStyles } from '../PatientProfileActionButtonGroupContainer.css';
import { EhrCredentialsModal } from './EhrCredentialsModal';
import { useGoToPatientChart } from './useGoToPatientChart';

export const GoToChartRpaActionButton = ({
  patient,
  patientHospital,
}: {
  patient: PatientDetails;
  patientHospital: Hospital;
}) => {
  const {
    isEnabled,
    isCredentialsModalOpen,
    setIsCredentialsModalOpen,
    goToChart,
  } = useGoToPatientChart(patient.ehr_information?.mrn);

  const message = isEnabled ? (
    <FormattedMessage defaultMessage="Open Patient Chart" />
  ) : (
    <FormattedMessage defaultMessage="RPA Unavailable" />
  );

  return (
    <>
      <IconButton.Tooltip content={message}>
        <IconButton
          size="small"
          className={cx({ [rpaButtonDisabledStyles]: !isEnabled })}
          isDisabled={!isEnabled}
          variant="secondary"
          onPress={() => goToChart(patientHospital)}
        >
          <AthenaLogo />
        </IconButton>
      </IconButton.Tooltip>
      <EhrCredentialsModal
        onClose={() => setIsCredentialsModalOpen(false)}
        open={isCredentialsModalOpen}
        patientHospital={patientHospital}
        onSave={() => goToChart(patientHospital)}
      />
    </>
  );
};
