import { useIntl } from 'react-intl';

import { StatusChangeReason } from '@/shared/types/patient.types';

export enum DisenrollmentReasonCategory {
  DeviceIssues,
  PatientDrivenReasons,
  Ineligibility,
  ProviderDrivenReasons,
}

export const PRIMARY_TO_SECONDARY_REASON_MAP: Record<
  DisenrollmentReasonCategory,
  StatusChangeReason[]
> = {
  [DisenrollmentReasonCategory.DeviceIssues]: [
    StatusChangeReason.LackOfConnectivity,
    StatusChangeReason.ConcernsAboutDeviceAccuracy,
    StatusChangeReason.UnableToSafelyUseEquipment,
    StatusChangeReason.PrefersUsingPersonalDevices,
  ],
  [DisenrollmentReasonCategory.PatientDrivenReasons]: [
    StatusChangeReason.PreferPhysicianManage,
    StatusChangeReason.CausedStress,
    StatusChangeReason.DoesntWantToTakeVitals,
    StatusChangeReason.DoesntWantToHaveAppointments,
    StatusChangeReason.DoesntWantToReceiveAlertCalls,
    StatusChangeReason.PrivacyConcerns,
    StatusChangeReason.ReceivedAnUnexpectedBill,
    StatusChangeReason.FinancialReasons,
    StatusChangeReason.BelievesHealthGoalMet,
    StatusChangeReason.Deceased,
    StatusChangeReason.NoLongerAPatientOfTheProvider,
  ],
  [DisenrollmentReasonCategory.Ineligibility]: [
    StatusChangeReason.EnrolledInAlternateProgram,
    StatusChangeReason.HasCGM,
    StatusChangeReason.ClinicalExclusion,
    StatusChangeReason.LivesInFacilityThatManagesTheirMedication,
    StatusChangeReason.PrimaryResidenceOutsideCadenceFootprint,
  ],
  [DisenrollmentReasonCategory.ProviderDrivenReasons]: [
    StatusChangeReason.ProviderLeftPractice,
    StatusChangeReason.ProviderRequestsPatientGraduate,
    StatusChangeReason.ProviderToldPatientProgramNotNeeded,
  ],
};

export function useDisenrollmentReasonCategoryI18n() {
  const intl = useIntl();

  return {
    [DisenrollmentReasonCategory.DeviceIssues]: intl.formatMessage({
      defaultMessage: 'Device issues',
    }),
    [DisenrollmentReasonCategory.PatientDrivenReasons]: intl.formatMessage({
      defaultMessage: 'Patient-driven reasons',
    }),
    [DisenrollmentReasonCategory.Ineligibility]: intl.formatMessage({
      defaultMessage: 'Ineligibility',
    }),
    [DisenrollmentReasonCategory.ProviderDrivenReasons]: intl.formatMessage({
      defaultMessage: 'Provider-driven reasons',
    }),
  };
}
