import {
  type SectionPath,
  type StepPath,
  getFullStepPath,
} from '@/shared/common/Wizard/path.utils';

import { INIT_VISIT_SECTIONS } from '../metadata';

export function initVisitPath<
  P extends SectionPath<typeof INIT_VISIT_SECTIONS>,
>(basePath: P, stepPath: StepPath<typeof INIT_VISIT_SECTIONS, P>): string {
  return getFullStepPath(INIT_VISIT_SECTIONS, basePath, stepPath);
}
