import type { ReactNode } from 'react';

import TeamImage from '@/shared/assets/svgs/teamScene.svg?react';

import {
  container,
  fullContainer,
  image,
  title as titleClass,
} from './EmptyState.css';

interface Props {
  title?: ReactNode;
}

export function EmptyState({ title }: Props) {
  return (
    <div className={fullContainer}>
      <div className={container}>
        <TeamImage className={image} />
        {title && <p className={titleClass}>{title}</p>}
      </div>
    </div>
  );
}
