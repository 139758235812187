import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';

import { settings } from '@/config';
import { logger } from '@/logger';

import { authInterceptor, devToolsInterceptor } from '../interceptors';

export function webFetchTransport() {
  const grpcWebUrl = settings.GRPC_WEB_URL || '';
  if (!grpcWebUrl) {
    logger.error(
      'Expected GRPC_WEB_URL in webFetchTransport but was not provided',
    );
  }
  return new GrpcWebFetchTransport({
    baseUrl: grpcWebUrl,
    interceptors: [authInterceptor(), devToolsInterceptor()],
  });
}
