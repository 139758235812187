{{! This field template is exactly the same amongst all Condition Programs (except chf) as of right now }}
{{! Therefore it is stored in shared to be re-used }}
{{> header val="Action Plan" }}
{{> regular_visit_action_plan }}
<br/>

{{> header val="Assessment and Plan" }}
{{> assessment_and_plan }}
<br/>

{{> subheader val="Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Status of Clinical Goal" }}
{{> free_text_space }}

{{> subheader val="Patient Clinical Goal Reached" }}
<p>[Yes or No and explanation]</p>
<p>If not reached, please choose reason</p>
<p>- Provider preferences</p>
<p>- Patient preferences</p>
<p>- Medical reason</p>
<p>- Financial reason</p>
{{> free_text_space }}

{{> subheader val="Patient device utilization" }}
<p>1. Is the patient taking regular readings with all assigned devices?</p>
<p>2. [Goal for vital frequency]</p>
{{> free_text_space }}

{{> subheader val="Diet/Lifestyle Recommendation" }}
{{> free_text_space }}

{{> subheader val="Additional recommendations (labs/imaging/outreach to order physician)" }}
{{> free_text_space }}
