import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import { ExerciseForm } from '../../shared/generalAssessment';
import type { ExerciseFormFields } from '../../shared/generalAssessment/formConfig';
import { getExerciseFormConfig } from '../../shared/generalAssessment/formConfig';
import { cycle17InitVisitPath } from '../paths';

export function Exercise() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<ExerciseFormFields>(
    cycle17InitVisitPath('/general-assessment', '/exercise'),
    getExerciseFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Exercise' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Now I have a good sense of how you're eating during the day. Let's spend some time talking about your daily routine and any exercise." />
        </Script>
        <ExerciseForm form={form} />
      </FieldGroup>
    </Wizard.Step>
  );
}
