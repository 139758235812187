import {
  EncounterModuleId,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { draftJsFieldTemplate } from '../../../../template.utils';
import carePlanNoteBody from './care_plan_note_body.hbs';

const SHARED_MODULES = {
  [EncounterModuleId.CarePlan]: {
    care_plan_body: draftJsFieldTemplate(carePlanNoteBody),
  },
};

export const ccmCarePlanVisit = {
  [TypeOfEncounter.CCM_CARE_PLAN]: {
    [ConditionProgram.Hypertension]: { ...SHARED_MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...SHARED_MODULES },
    [ConditionProgram.T2DAndHTN]: { ...SHARED_MODULES },
    [ConditionProgram.CHF]: { ...SHARED_MODULES },
    [ConditionProgram.CCM]: { ...SHARED_MODULES },
  },
};
