import flatMap from 'lodash/flatMap';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import type { DataObject } from '@/shared/common/@deprecated/SchemaDrivenForm/';
import { INVALID_DATE } from '@/shared/common/@deprecated/SchemaDrivenForm/';

import {
  ADMISSION_REASON_FORMATTED_MESSAGES,
  ADMISSION_REASON_PREVIEW_MESSAGES,
  VISIT_TYPE_FORMATTED_MESSAGES,
} from '../NoteEditor/RecentHospitalizationForm';
import type { TypeOfEncounter } from '../Notes.types';
import { isAdHocClinicalEncounterType } from '../utils/encounterTypeUtils';
import { formatDate, joinMessagesGrammatically } from './formatUtils';

export interface HospitalizationInputs extends DataObject {
  visit_types?: {
    [key: string]: boolean;
  };
  admission_start_date?: string;
  admission_end_date?: string;
  location?: string;
  additional_information?: string;
  admission_reasons?: {
    [key: string]: boolean;
  };
}

export const formatHospitalizationInputs = (
  inputs: HospitalizationInputs,
  title: string,
  encounterType?: TypeOfEncounter,
  isPatientNoShow?: boolean,
  noEmergencyVisitsToReport?: boolean,
) => {
  const header = <b>{title}:</b>;

  if (noEmergencyVisitsToReport) {
    if (isPatientNoShow) {
      return null;
    }

    return (
      <>
        {header}
        <div>
          {isAdHocClinicalEncounterType(encounterType) ? (
            <FormattedMessage defaultMessage="Emergency visits were not reported or assessed." />
          ) : (
            <FormattedMessage defaultMessage="Patient does not report any new disease related visits to the ED or urgent care or hospital admissions." />
          )}
        </div>
      </>
    );
  }

  const reasonsForAdmission = getReasonsForAdmissionText(
    inputs.admission_reasons,
  );
  const dates = getDatesText(
    inputs.admission_start_date,
    inputs.admission_end_date,
  );
  const location = inputs.location ? (
    <FormattedMessage
      defaultMessage=" at {location}"
      values={{ location: inputs.location }}
    />
  ) : (
    ''
  );
  const additionalInfo = inputs.additional_information
    ? ['. ', inputs.additional_information]
    : '';
  const typeOfHospitalization = getTypeOfHospitalizationText(
    Boolean(reasonsForAdmission || dates || location || additionalInfo),
    inputs.visit_types,
  );

  // TODO: Improve translation so it's understandable by a translator
  // See https://github.com/cadencerpm/falcon/pull/589#discussion_r826554255
  return (
    <>
      {header}
      <div>
        {typeOfHospitalization}
        {reasonsForAdmission}
        {dates}
        {location}
        {additionalInfo}
      </div>
    </>
  );
};

const getTypeOfHospitalizationText = (
  isAnyOtherFieldFilled: boolean,
  visit_types?: HospitalizationInputs['visit_types'],
) => {
  const visitTypeKeys = Object.entries(visit_types ?? {})
    .filter(([, value]) => value === true)
    .map(([key]) => key);
  if (visitTypeKeys.length < 1) {
    return isAnyOtherFieldFilled ? (
      <FormattedMessage defaultMessage="Patient was admitted" />
    ) : null;
  }

  const messages = visitTypeKeys.map(getVisitTypeFormattedMessage);
  return flatMap(messages, (value, i, arr) =>
    i === arr.length - 1 ? value : [value, '/'],
  );
};

const getDatesText = (
  admissionStartDate?: HospitalizationInputs['admission_start_date'],
  admissionEndDate?: HospitalizationInputs['admission_end_date'],
) => {
  if (!admissionStartDate || admissionStartDate === INVALID_DATE) {
    return null;
  }

  return !admissionEndDate || admissionEndDate === INVALID_DATE ? (
    <FormattedMessage
      defaultMessage=" on {admissionStartDate}"
      values={{ admissionStartDate: formatDate(admissionStartDate) }}
    />
  ) : (
    <FormattedMessage
      defaultMessage=" from {admissionStartDate} to {admissionEndDate}"
      values={{
        admissionStartDate: formatDate(admissionStartDate),
        admissionEndDate: formatDate(admissionEndDate),
      }}
    />
  );
};

const getReasonsForAdmissionText = (
  reasons: HospitalizationInputs['admission_reasons'],
) => {
  if (!reasons) {
    return '';
  }

  const reasonKeys = Object.entries(reasons)
    .filter(([, value]) => value === true)
    .map(([key]) => key);

  // if we have "other" selected, shove it to the end of the array so that
  // the sentence reads better.
  const otherIdx = reasonKeys.indexOf('other');

  if (otherIdx !== -1) {
    reasonKeys.splice(otherIdx, 1);
    reasonKeys.push('other');
  }

  const reasonMessages = reasonKeys.map(getAdmissionReasonFormattedMessage);
  return joinMessagesGrammatically(
    reasonMessages,
    <FormattedMessage defaultMessage=" for " />,
  );
};

const getVisitTypeFormattedMessage = (enumText: string) => (
  <Fragment key={enumText}>
    {VISIT_TYPE_FORMATTED_MESSAGES[
      enumText as keyof typeof VISIT_TYPE_FORMATTED_MESSAGES
    ] || enumText}
  </Fragment>
);

const getAdmissionReasonFormattedMessage = (enumText: string) => {
  const previewMessage =
    ADMISSION_REASON_PREVIEW_MESSAGES[
      enumText as keyof typeof ADMISSION_REASON_PREVIEW_MESSAGES
    ];
  const message =
    ADMISSION_REASON_FORMATTED_MESSAGES[
      enumText as keyof typeof ADMISSION_REASON_FORMATTED_MESSAGES
    ];

  return previewMessage || message || enumText;
};
