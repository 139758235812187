type Settings = Partial<{
  API_URL: string;
  GRPC_WEB_URL: string;
  GO_API_URL: string;
  VITE_LOG_LEVEL: string;
  VITE_SENTRY_DSN: string;
  VITE_ENVIRONMENT: string; // value can be: development | production | preview-development | preview-production
  VITE_VERSION: string;
  VITE_DATADOG_APPLICATION_ID: string;
  VITE_DATADOG_CLIENT_TOKEN: string;
  VITE_LD_CLIENT_ID: string;
  VITE_ACUITY_SCHEDULING_URL: string;
  VITE_TWILIO_FLEX_URL: string;
  VITE_LOB_API_KEY: string;
  ZENDESK_EMAIL: string;
  ZENDESK_API_KEY: string;
}> & { NODE_ENV: string };

export const settings: Settings = {
  NODE_ENV: import.meta.env.MODE, // value can be: development | production, see details here: https://vitejs.dev/guide/env-and-mode.html
  API_URL: import.meta.env.VITE_API_URL,
  GRPC_WEB_URL: import.meta.env.VITE_GRPC_WEB_URL,
  // VITE_GO_API_URL is for local development only. In our deployed environments,
  // the golang api url hostname should be the same as the deadpool api hostname
  GO_API_URL: import.meta.env.VITE_GO_API_URL || import.meta.env.VITE_API_URL,
  VITE_LOG_LEVEL: import.meta.env.VITE_LOG_LEVEL,
  VITE_LD_CLIENT_ID: import.meta.env.VITE_LD_CLIENT_ID,
  VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  VITE_VERSION: import.meta.env.VITE_VERSION || 'local',
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  VITE_DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  VITE_ACUITY_SCHEDULING_URL: import.meta.env.VITE_ACUITY_SCHEDULING_URL,
  VITE_TWILIO_FLEX_URL: import.meta.env.VITE_TWILIO_FLEX_URL,
  VITE_LOB_API_KEY: import.meta.env.VITE_LOB_API_KEY,
  ZENDESK_EMAIL: import.meta.env.VITE_ZENDESK_EMAIL,
  ZENDESK_API_KEY: import.meta.env.VITE_ZENDESK_API_KEY,
};

const EnvLookup: Record<string, string> = {
  development: 'dev.my.cadence.care',
  production: 'my.cadence.care',
  'preview-development': 'dev.my.cadence.care',
  'preview-production': 'my.cadence.care',
};
export const getCurrentEnvHostname = () =>
  settings.VITE_ENVIRONMENT
    ? EnvLookup[settings.VITE_ENVIRONMENT]
    : EnvLookup.development;

const ZendeskEnvLookup: Record<string, string> = {
  development: 'cadencerpm1654100215.zendesk.com',
  production: 'cadencerpm.zendesk.com',
  'preview-development': 'cadencerpm1654100215.zendesk.com',
  'preview-production': 'cadencerpm.zendesk.com',
};

export const getCurrentEnvZendeskHostname = () =>
  settings.VITE_ENVIRONMENT
    ? ZendeskEnvLookup[settings.VITE_ENVIRONMENT]
    : ZendeskEnvLookup.development;

// Add app_version to window env for easier debugging
window.env = {
  app_version: settings.VITE_VERSION || '',
};
