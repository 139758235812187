import cx from 'classnames';
import first from 'lodash/first';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import LetterIcon from '@/shared/assets/svgs/letter.svg?react';
import {
  type CCMCarePlan,
  State,
} from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { type LobLetter } from '@/shared/generated/grpcGateway/patient_outreach.pb';
import { useSendCarePlanLetter } from '@/shared/hooks/queries/carePlan.queries';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Link } from '@/shared/tempo/atom/Link';
import { Tag } from '@/shared/tempo/atom/Tag';

import { CarePlanAlertBanner } from '../../../CarePlanAlertBanner';
import { ConfirmAddressModal } from './ConfirmAddressModal';
import { HistoryButton } from './HistoryButton';
import {
  container,
  list,
  listItem,
  mailButton,
  statusContainer,
} from './LetterSends.css';
import { LetterStatusText } from './LetterStatusText';
import { MailHistoryModal } from './MailHistoryModal';
import { StatusIndicator } from './StatusIndicator/StatusIndicator';
import {
  getLetterStatusMetadata,
  letterDelivered,
  letterInFailedStatus,
  letterInProgress,
} from './status.utils';

type Props = {
  patientId: string;
  carePlan: CCMCarePlan;
  allCarePlans: CCMCarePlan[];
};

enum ModalState {
  ConfirmAddress = 'confirm-address',
  MailHistory = 'mail-history',
}

export function LetterSends({ patientId, carePlan, allCarePlans }: Props) {
  const [modalState, setModalState] = useState<Nullable<ModalState>>(null);

  const isApproved = carePlan.state === State.APPROVED;
  const letterSends = orderBy(
    carePlan.sends || [],
    'sendTime',
    'desc',
  ) as LobLetter[];
  const mostRecentLetterSend = first(letterSends);
  const {
    mutate: sendCarePlanPdf,
    isLoading: isSendingCarePlanPdf,
    error: errorSendingCarePlanPdf,
  } = useSendCarePlanLetter();

  if (!isApproved) {
    return null;
  }

  const letterStatusMeta = getLetterStatusMetadata(mostRecentLetterSend);

  return (
    <>
      <div className={cx(flexContainer.column, gap.M)}>
        {(errorSendingCarePlanPdf ||
          letterInFailedStatus(mostRecentLetterSend)) && (
          <CarePlanAlertBanner
            title={
              <FormattedMessage defaultMessage="The Care Plan failed to be mailed" />
            }
          >
            <ul className={list}>
              <li className={listItem}>
                <FormattedMessage
                  defaultMessage="Review the patient's address in the <link>patient info</link> tab and confirm
              it's a valid address and try again."
                  values={{
                    link: (text: string) => (
                      <Link.Routed to={`/patients/${patientId}/patient-info`}>
                        {text}
                      </Link.Routed>
                    ),
                  }}
                />
              </li>
              <li className={listItem}>
                <FormattedMessage defaultMessage="If the problem persists, contact Cadence Platform Support." />
              </li>
            </ul>
          </CarePlanAlertBanner>
        )}
        <div className={container}>
          <div className={cx(flexContainer.row, gap.S)}>
            <LetterStatusText letter={mostRecentLetterSend} />
            {letterDelivered(letterStatusMeta) && (
              <HistoryButton
                onShowHistory={() => setModalState(ModalState.MailHistory)}
              />
            )}
          </div>
          {!letterInProgress(letterStatusMeta) && (
            <Button
              size="small"
              variant="secondary"
              className={mailButton}
              isProcessing={isSendingCarePlanPdf}
              onPress={() => setModalState(ModalState.ConfirmAddress)}
            >
              <Button.Icon>
                <LetterIcon />
              </Button.Icon>
              <FormattedMessage defaultMessage="Mail Care Plan" />
            </Button>
          )}
          {letterInProgress(letterStatusMeta) && (
            <div className={statusContainer}>
              <Tag variant={letterStatusMeta.variant}>
                {letterStatusMeta.label}
              </Tag>
              {letterStatusMeta.step !== null && (
                <StatusIndicator
                  totalSteps={3}
                  currentStep={letterStatusMeta.step}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {modalState === ModalState.ConfirmAddress && (
        <ConfirmAddressModal
          patientId={patientId}
          onClose={() => setModalState(null)}
          onConfirm={() => {
            sendCarePlanPdf(carePlan.name || '');
            setModalState(null);
          }}
        />
      )}
      {modalState === ModalState.MailHistory && (
        <MailHistoryModal
          allCarePlans={allCarePlans}
          onClose={() => setModalState(null)}
        />
      )}
    </>
  );
}
