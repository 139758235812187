import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@/deprecated/mui';
import CustomThresholdAlertIcon from '@/shared/assets/svgs/customThresholdAlert.svg?react';
import MultipleAlertsIcon from '@/shared/assets/svgs/multipleAlerts.svg?react';
import P0Alert from '@/shared/assets/svgs/p0-alert.svg?react';
import P1Alert from '@/shared/assets/svgs/p1-alert.svg?react';
import SpotAlertIcon from '@/shared/assets/svgs/spotAlert.svg?react';
import TrendAlertIcon from '@/shared/assets/svgs/trendAlert.svg?react';
import VerifiedAlertIcon from '@/shared/assets/svgs/verified-alert.svg?react';
import { useFlags } from '@/shared/hooks';
import {
  AlertPriority,
  AlertType,
  containsVerifiedAlert,
  getAlertType,
  getHighestAlertPriority,
} from '@/shared/patient/alerts';
import type { VitalsAlert } from '@/shared/types/alert.types';

import {
  iconsBackgroundContainer,
  verifiedAlert,
} from './PatientAlertStatusIcon.css';
import {
  alertTypeStyle,
  customArrow,
  customTooltip,
  hoverText,
  priorityAlert,
} from './PatientsTableRow.css';

export function PatientAlertStatusIcon({ alert }: { alert: VitalsAlert }) {
  return (
    <div className={iconsBackgroundContainer}>
      <PatientAlertsStatusIcon alerts={[alert]} />
    </div>
  );
}

export function PatientAlertsStatusIcon({ alerts }: { alerts: VitalsAlert[] }) {
  const { validatedAlerts } = useFlags();
  const alertType = getAlertType(alerts);
  const alertPriority = getHighestAlertPriority(alerts);
  const isVerified = validatedAlerts && containsVerifiedAlert(alerts);
  return (
    <Tooltip
      title={
        <div className={hoverText}>
          {isVerified && (
            <>
              <FormattedMessage defaultMessage="Validated" />
              <br />
            </>
          )}
          {alertTypesConfig[alertType].hoverName}
          <br />
          {alertPrioritiesConfig[alertPriority].hoverName}
        </div>
      }
      classes={{
        tooltip: customTooltip,
        arrow: customArrow,
      }}
      placement="top"
      arrow
    >
      <div className={priorityAlert}>
        {isVerified ? (
          <VerifiedAlertIcon className={verifiedAlert} />
        ) : (
          alertPrioritiesConfig[alertPriority].icon
        )}
        {alertTypesConfig[alertType].icon}
      </div>
    </Tooltip>
  );
}

const p0AlertConfig = {
  icon: <P0Alert />,
  hoverName: <FormattedMessage defaultMessage="High Severity" />,
};
const p1AlertConfig = {
  icon: <P1Alert />,
  hoverName: <FormattedMessage defaultMessage="Medium Severity" />,
};
const nonePriorityConfig = {
  icon: null,
  hoverName: null,
};
const alertPrioritiesConfig = {
  [AlertPriority.P0]: p0AlertConfig,
  [AlertPriority.P1]: p1AlertConfig,
  [AlertPriority.P2]: p1AlertConfig, // showing P2 as P1 in the UI
  [AlertPriority.None]: nonePriorityConfig,
};

const alertTypesConfig = {
  [AlertType.Custom]: {
    icon: <CustomThresholdAlertIcon className={alertTypeStyle} />,
    hoverName: <FormattedMessage defaultMessage="Custom Threshold" />,
  },
  [AlertType.Multiple]: {
    icon: <MultipleAlertsIcon className={alertTypeStyle} />,
    hoverName: <FormattedMessage defaultMessage="Multiple Alerts" />,
  },
  [AlertType.Trend]: {
    icon: <TrendAlertIcon className={alertTypeStyle} />,
    hoverName: <FormattedMessage defaultMessage="Trend Issue" />,
  },
  [AlertType.Spot]: {
    icon: <SpotAlertIcon className={alertTypeStyle} />,
    hoverName: <FormattedMessage defaultMessage="Spot Issue" />,
  },
};
