import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { Form } from '@/shared/common/Form';

import { ContextualGoalButton } from '../../Goals/ContextualGoalButton';
import { GoalMeasure } from '../../Goals/goals.types';
import { goalSectionContainer, sectionTitle } from './carePlanSections.css';

type Props = {
  noteId: number;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
};

export function Medications({ noteId, createGoalWithMeasure }: Props) {
  const intl = useIntl();
  const { patientId } = useParams<{ patientId: string }>();

  return (
    <Form.Section
      title={
        <div className={goalSectionContainer}>
          {intl.formatMessage({ defaultMessage: 'Medications' })}
          <ContextualGoalButton
            onPress={() => createGoalWithMeasure(GoalMeasure.Medications)}
          />
        </div>
      }
      classes={{ title: sectionTitle }}
    >
      <Form.GridItem size={11}>
        <PatientMedicationsList
          patientId={patientId}
          noteId={noteId}
          showRequiredActions={false}
          medPermissions={MedPermissions.Review}
        />
      </Form.GridItem>
      <Form.Toggle
        name="medsNotReviewed"
        label={intl.formatMessage({
          defaultMessage: 'Unable to verify medications at this time',
        })}
      />
    </Form.Section>
  );
}
