import { FormattedMessage, useIntl } from 'react-intl';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import { useCreateCcmGoal } from '@/shared/hooks/queries/ccmGoals.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { GoalForm } from '../GoalForm';
import { marshalFormDataToGoal } from '../dataTransformations';
import { type FormFields, getFormConfig } from '../formConfig';
import { type Goal, GoalStatus } from '../goals.types';

type Props = {
  defaults?: Partial<Goal>;
  onClose: () => void;
  patientId: string;
  carePlanId: string | undefined;
};

export function CreateGoalModal({
  onClose,
  defaults = {},
  patientId,
  carePlanId,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const form = useFormFromConfig<FormFields>(
    getFormConfig(intl, {
      status: GoalStatus.OPEN,
      ...defaults,
    }),
  );
  const { mutate: createGoal, isLoading: isCreatingGoal } =
    useCreateCcmGoal(patientId);

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="Create a goal" />}
      />
      <Modal.Body>
        <GoalForm form={form} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          isDisabled={!form.canSubmit}
          isProcessing={isCreatingGoal}
          onPress={() => {
            createGoal(
              {
                goal: marshalFormDataToGoal(form.getValues()),
                carePlanId,
              },
              {
                onSuccess() {
                  toaster.success(
                    intl.formatMessage({
                      defaultMessage: 'New goal created successfully',
                    }),
                  );
                  onClose();
                },
                onError() {
                  toaster.error(
                    intl.formatMessage({
                      defaultMessage: 'Failed to create goal',
                    }),
                  );
                },
              },
            );
          }}
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
