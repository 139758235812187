import isEqual from 'lodash/isEqual';

import { upsertItem } from '@/lang.utils';
import { usePrevious } from '@/shared/hooks';

import { useNoteEditorContext } from '../../NoteEditorContext';
import type { EncounterModuleInstance } from '../../Notes.types';
import { getEncounterTypeInstance } from '../../utils/encounterTypeUtils';

export function useEncounterModuleInstances() {
  const { encounterModuleInstances, setEncounterModuleInstances } =
    useNoteEditorContext();
  const encounterTypeInstance = getEncounterTypeInstance(
    encounterModuleInstances,
  );
  const prevInstances = usePrevious(encounterModuleInstances);

  function onChangeInstance(newInstance: EncounterModuleInstance) {
    const prevInstance = prevInstances?.find(
      (instance) =>
        instance.encounter_module_id === newInstance.encounter_module_id,
    );

    if (isEqual(newInstance, prevInstance)) {
      return;
    }

    const newInstances = upsertItem(
      encounterModuleInstances,
      newInstance,
      (instance) =>
        instance.encounter_module_id === newInstance.encounter_module_id,
    );
    setEncounterModuleInstances(newInstances);
  }

  return { encounterModuleInstances, encounterTypeInstance, onChangeInstance };
}
