import type { ReactNode } from 'react';

import type { BreadcrumbsProps } from '@/deprecated/mui';
import { Breadcrumbs as MUIBreadcrumbs } from '@/deprecated/mui';

const SEPARATOR = '›';

type Props = {
  children: ReactNode;
} & Omit<BreadcrumbsProps, 'separator'>;

export function Breadcrumbs({ children, ...props }: Props) {
  return (
    <MUIBreadcrumbs {...props} separator={SEPARATOR}>
      {children}
    </MUIBreadcrumbs>
  );
}
