import jwt_decode from 'jwt-decode';
import type { RefObject } from 'react';
import { Suspense, lazy, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { HomePage } from '@/pages/home/HomePage';
import { Sidebar } from '@/shared/common/Sidebar';
import { TwilioFlexContextProvider } from '@/shared/common/TwilioFlex';
import { useListenToTaskNotifications } from '@/shared/hooks';
import type { TwilioTask } from '@/shared/hooks/useTwilioFlexMessagesListener';
import { Spinner } from '@/shared/tempo/atom/Spinner';
import type { CognitoAccessToken } from '@/shared/types/cognito.types';
import { isAdmin } from '@/shared/utils/userRoles.utils';
import { store } from '@/store';

import { PatientsRoutes } from './PatientsRoutes';

// Code-split Admin Pages via dynamic import
const AdminContainer = lazy(() => import('./AdminContainer'));

export function Routes() {
  // Listen to task notification changes and update the react-query cache
  useListenToTaskNotifications();

  const [twilioFlexRef, setTwilioFlexRef] =
    useState<RefObject<HTMLIFrameElement> | null>(null);
  const [isFlexOpen, setIsFlexOpen] = useState(false);
  const [twilioTasks, setTwilioTasks] = useState(new Map<string, TwilioTask>());

  return (
    <TwilioFlexContextProvider
      value={{
        iframeRef: twilioFlexRef,
        setIframeRef: setTwilioFlexRef,
        isFlexOpen,
        setIsFlexOpen,
        tasks: twilioTasks,
        setTasks: setTwilioTasks,
      }}
    >
      <Sidebar />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/patients" component={PatientsRoutes} />
          <Route path="/home" component={HomePage} />
          {isAdmin(
            jwt_decode<CognitoAccessToken>(
              store.getState().auth.token.access_token,
            )['cognito:groups'],
          ) && <Route path="/admin" component={AdminContainer} />}
          <Redirect from="*" to="/patients" />
        </Switch>
      </Suspense>
    </TwilioFlexContextProvider>
  );
}
