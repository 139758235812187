import isSet from 'lodash/isSet';
import last from 'lodash/last';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useFlatPages } from '@/reactQuery';
import { InfiniteScrollLoader } from '@/shared/common/InfiniteScrollLoader';
import { useHealthSystemInfinite } from '@/shared/hooks/queries/health-system.queries';
import { MultiSelect } from '@/shared/tempo/@labs/molecule/MultiSelect';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';

import { TabKey } from '../TabKey';
import { useTaskFilterContext } from '../TaskFilterContext';
import {
  healthSystemSelectButton,
  healthSystemSelectFilterPopover,
  skeleton,
  skeletonContainer,
  subtleChevron,
} from './TaskFilters.css';

type Props = {
  tabKey: TabKey;
};

export function HealthSystemFilter({ tabKey }: Props) {
  const intl = useIntl();
  const { healthSystems: selectedHealthSystems, onHealthSystemsChange } =
    useTaskFilterContext();
  const healthSystemsQuery = useHealthSystemInfinite();
  const healthSystems = useFlatPages(healthSystemsQuery);
  const healthSystemIds = useMemo(
    () => healthSystems.map(({ id }) => id),
    [healthSystems],
  );

  if (healthSystemsQuery.isLoading) {
    return (
      <div className={skeletonContainer}>
        <Skeleton variant="text" className={skeleton} />
      </div>
    );
  }

  const lastHealthSystemId = last(healthSystemIds);
  const label = intl.formatMessage({
    defaultMessage: 'Hospital systems',
  });

  return (
    <MultiSelect
      variant="subtle"
      placeholder={label}
      aria-label={label}
      allSelectedLabel={intl.formatMessage({
        defaultMessage: 'All hospital systems',
      })}
      className={healthSystemSelectButton}
      classes={{
        popover: healthSystemSelectFilterPopover,
        chevron: subtleChevron,
      }}
      popover={{
        // we want `bottom start` on the Team tab so that our popover
        // isn't cramped by being so far to the left of the screen
        placement: tabKey === TabKey.Team ? 'bottom start' : 'bottom end',
        width: 'static',
      }}
      selectedKeys={selectedHealthSystems}
      onSelectionChange={(selection) => {
        if (!isSet(selection) || selection.size === healthSystemIds.length) {
          onHealthSystemsChange('all');
          return;
        }

        onHealthSystemsChange([...(selection as Set<number>)]);
      }}
    >
      <MultiSelect.Section
        title={intl.formatMessage({
          defaultMessage: 'Hospital systems',
        })}
      >
        <MultiSelect.Option key={MultiSelect.SELECT_ALL_KEY}>
          {intl.formatMessage({ defaultMessage: 'All' })}
        </MultiSelect.Option>
      </MultiSelect.Section>
      <MultiSelect.Section>
        {healthSystems.map(({ id, name }) => (
          <MultiSelect.Option key={id} textValue={name}>
            {name}
            {id === lastHealthSystemId && (
              <InfiniteScrollLoader query={healthSystemsQuery} as="span" />
            )}
          </MultiSelect.Option>
        ))}
      </MultiSelect.Section>
    </MultiSelect>
  );
}
