import orderBy from 'lodash/orderBy';

import type {
  PatientVitals,
  UnsupressedListPatientVitalsResponseVital,
  VitalsForDateType,
} from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';

// TO DO: delete after telemetry endpoint roll out
export const getNumTableRows = (vitals: Partial<PatientVitals>) =>
  Math.max(
    vitals[VitalType.Weight]?.length || 0,
    vitals[VitalType.BloodPressure]?.length || 0,
    vitals[VitalType.HeartRate]?.length || 0,
    vitals[VitalType.BloodGlucose]?.length || 0,
  );

export const tempGetNumTableRows = (vitals: VitalsForDateType) =>
  Math.max(
    vitals[VitalType.Weight.toUpperCase()]?.length || 0,
    vitals[VitalType.BloodPressure.toUpperCase()]?.length || 0,
    vitals[VitalType.HeartRate.toUpperCase()]?.length || 0,
    vitals[VitalType.BloodGlucose.toUpperCase()]?.length || 0,
  );

export const getVitalsByType = (
  vitals: VitalsForDateType,
  type: VitalType,
): UnsupressedListPatientVitalsResponseVital[] => {
  const vitalsByType = orderBy(
    vitals[type.toUpperCase()],
    ['timestamp'],
    ['asc'],
  );
  return vitalsByType as UnsupressedListPatientVitalsResponseVital[];
};
