// Supports array fields in react hook form, where the field name is in the format: "<fieldName>[<index>]" e.g. "doseQuantities[0]"
export function getArrayField(fieldName: string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^([^\[\]]+)\[(\d+)\]$/;
  const match = fieldName.match(regex);

  if (match) {
    return {
      fieldName: match[1],
      index: Number(match[2]),
    };
  }

  return undefined;
}
