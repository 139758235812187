import type { RpcInterceptor } from '@protobuf-ts/runtime-rpc';

const GRPC_WEB_DEV_TOOLS_REF = '__GRPCWEB_DEVTOOLS__';

/**
 * Enables the usage of the gRPC-Web Developer Tools extension with protobuf-ts
 * https://chrome.google.com/webstore/detail/grpc-web-developer-tools/kanmilmfkjnoladbbamlclhccicldjaj
 * Copied from: https://github.com/timostamm/protobuf-ts/issues/252#issuecomment-1129220082
 */
export function devToolsInterceptor(): RpcInterceptor {
  // Same-origin
  const TARGET_ORIGIN = window.location.origin;
  return {
    interceptUnary(next, method, input, options) {
      const call = next(method, input, options);
      const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
      const methodType = 'unary';

      call.then(
        (finishedUnaryCall) => {
          window.postMessage(
            {
              type: GRPC_WEB_DEV_TOOLS_REF,
              method: methodFullPath,
              methodType,
              request: finishedUnaryCall.request,
              response: finishedUnaryCall.response,
            },
            TARGET_ORIGIN,
          );

          return finishedUnaryCall;
        },
        (error) => {
          window.postMessage(
            {
              type: GRPC_WEB_DEV_TOOLS_REF,
              method: methodFullPath,
              methodType,
              request: call.request,
              error: {
                ...error,
                message: error.message,
              },
            },
            TARGET_ORIGIN,
          );
        },
      );

      return call;
    },
    interceptServerStreaming(next, method, input, options) {
      const call = next(method, input, options);
      const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
      const methodType = 'server_streaming';

      window.postMessage({
        type: GRPC_WEB_DEV_TOOLS_REF,
        method: methodFullPath,
        methodType,
        request: call.request,
      });

      call.responses.onMessage((message) => {
        window.postMessage(
          {
            type: GRPC_WEB_DEV_TOOLS_REF,
            method: methodFullPath,
            methodType,
            response: message,
          },
          TARGET_ORIGIN,
        );
      });

      call.responses.onError((error) => {
        window.postMessage(
          {
            type: GRPC_WEB_DEV_TOOLS_REF,
            method: methodFullPath,
            methodType,
            error: {
              ...error,
              message: error.message,
            },
          },
          TARGET_ORIGIN,
        );
      });

      call.responses.onComplete(() => {
        window.postMessage(
          {
            type: GRPC_WEB_DEV_TOOLS_REF,
            method: methodFullPath,
            methodType,
            response: 'EOF',
          },
          TARGET_ORIGIN,
        );
      });

      return call;
    },
  };
}
