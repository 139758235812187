import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { Button } from '@/shared/tempo/atom/Button';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function ReassociateCarePlanModal({
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const intl = useIntl();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Confirm care plan reassociation',
        })}
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="This will reassociate the current draft care plan with this care plan encounter. Are you sure you wish to continue?" />
      </Modal.Body>
      <Modal.Footer>
        <Button id="close" variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
        <Button variant="primary" onPress={() => onConfirm()}>
          <FormattedMessage defaultMessage="Confirm" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
