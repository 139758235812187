import * as yup from 'yup';

import { validator } from './factory';

type EnumValidatorParams = {
  source: Record<string, string>;
  pluck: 'keys' | 'values';
  emptyValue?: Maybe<string>;
  errorMessage?: string;
};

export const enumValidator = validator((intl, params: EnumValidatorParams) => {
  const { source, pluck, emptyValue } = params;
  const pluckFn = pluck === 'keys' ? Object.keys : Object.values;

  const errorMessage =
    params.errorMessage ??
    intl.formatMessage({
      defaultMessage: 'An option must be selected',
    });
  return yup
    .string()
    .oneOf([...pluckFn(source), ...([emptyValue] || [])], errorMessage)
    .typeError(errorMessage);
});
