import isEmpty from 'lodash/isEmpty';

import type {
  ActiveVitalsAlert,
  ReadingRelations,
  RelationTagsUnsuppressed,
} from '@/shared/types/alert.types';
import type { ReadingDataType } from '@/shared/types/patientSummary.types';

export const hasAlertForReading = (
  readingId: string,
  readingDataType: ReadingDataType,
  alerts: ActiveVitalsAlert[],
) =>
  !!alerts.find((alert) =>
    hasRelationForReading(
      readingId,
      readingDataType,
      alert.relations,
      alert.relation_tags_unsuppressed,
    ),
  );

const hasRelationForReading = (
  readingId: string,
  dataType: ReadingDataType,
  relations: ReadingRelations,
  relationTagsUnsuppressed?: RelationTagsUnsuppressed,
) => {
  const relationTagsForDataType = relationTagsUnsuppressed?.[dataType];

  // Fallback on checking relations object because old alerts won't have the
  // relationTags object populated and keyed by vitals. (The result of this is
  // that old alerts will highlight readings as long as that reading is in the
  // alert, even if that reading has no unsuppressed tags)
  if (!relationTagsForDataType) {
    return !!Object.entries(relations).find(
      ([relationDataType, relationReadingIds]) =>
        relationReadingIds.find(
          (id) => id === readingId && relationDataType === dataType,
        ),
    );
  }

  const unsuppressedTagsForThisReading = relationTagsForDataType?.[readingId];
  return !isEmpty(unsuppressedTagsForThisReading);
};
