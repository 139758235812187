import type { InputBase, InputDOMProps, ValueBase } from '@react-types/shared';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { Children, type ReactElement, isValidElement } from 'react';

import type { Props as SegmentProps } from './Segment';
import { SegmentIcon } from './SegmentIcon';
import { SegmentedButton } from './SegmentedButton';
import { segmentedButtonGroup } from './SegmentedButtonGroup.css';
import { useSegmentedButtonGroupState } from './segmentedButtonGroupState';

export type Props = {
  size?: 'default' | 'small';
  className?: string;
  children: ReactElement<SegmentProps>[];
} & ValueBase<string> &
  InputBase &
  InputDOMProps;

export function SegmentedButtonGroup({
  size = 'default',
  className,
  ...props
}: Props) {
  const { children } = props;
  const state = useSegmentedButtonGroupState(props);
  return (
    <div className={cx(segmentedButtonGroup, className)}>
      {children.map((segment, idx) => {
        const { children: segmentChildren } = segment.props;
        const variant = isIconOnlySegment(segmentChildren)
          ? 'icon-only'
          : 'default';

        return (
          <SegmentedButton
            {...segment.props}
            size={size}
            state={state}
            variant={variant}
            isFirst={idx === 0}
            isLast={idx === children.length - 1}
          >
            {segmentChildren}
          </SegmentedButton>
        );
      })}
    </div>
  );
}

function isIconOnlySegment(segmentChildren: ReactNode) {
  const children = Children.toArray(segmentChildren);
  const firstChild = children[0] as ReactElement;
  return (
    children.length === 1 &&
    isValidElement(firstChild) &&
    firstChild.type === SegmentIcon
  );
}
