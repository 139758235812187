/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EHRCredentialsSchema } from '../models/EHRCredentialsSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EhrCredentialsService {
    /**
     * Delete a EHR Health System Provider's credential information
     * @param careProviderId CareProvider ID
     * @param healthSystemId Health System ID
     * @returns void
     * @throws ApiError
     */
    public static deletePmsApiV1CareProviderHealthSystemsEhrCredentials(
        careProviderId: string,
        healthSystemId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pms/api/v1/care_provider/{care_provider_id}/health-systems/{health_system_id}/ehr-credentials',
            path: {
                'care_provider_id': careProviderId,
                'health_system_id': healthSystemId,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                404: `Resource Not Found`,
            },
        });
    }
    /**
     * Create a new EHR health system provider with provided EHR credentials.
     * @param careProviderId CareProvider ID
     * @param healthSystemId Health System ID
     * @param requestBody A JSON object containing EHR health system credentials
     * @returns void
     * @throws ApiError
     */
    public static postPmsApiV1CareProviderHealthSystemsEhrCredentials(
        careProviderId: string,
        healthSystemId: string,
        requestBody: EHRCredentialsSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/care_provider/{care_provider_id}/health-systems/{health_system_id}/ehr-credentials',
            path: {
                'care_provider_id': careProviderId,
                'health_system_id': healthSystemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                404: `Resource Not Found`,
            },
        });
    }
}
