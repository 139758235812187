import cx from 'classnames';
import get from 'lodash/get';
import type { ReactElement, ReactNode, ReactText } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type {
  FieldError,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  Merge,
  UseControllerReturn,
  UseFormReturn,
} from 'react-hook-form';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import type * as yup from 'yup';

import { FormHelperText } from '@/deprecated/mui';
import AlertTriangle from '@/shared/assets/svgs/alertTriangle.svg?react';
import { Label } from '@/shared/tempo/atom/Label';

import { useFormConfigCtx } from '../../FormConfigContext';
import { GridItem } from '../../GridItem';
import { getArrayField } from './arrayFieldUtils';
import {
  errorIcon,
  field,
  labelText,
  persistedHelpContainer,
  persistedHelpText,
} from './styles.css';

type RenderProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  controller: UseControllerReturn<TFieldValues, TName>;
  renderError: () => ReactNode;
  isRequired: boolean;
};

export type BaseFieldProps = {
  classes?: {
    root?: string;
    label?: string;
    helpText?: string;
  };
  label?: ReactElement | ReactText;
  name: string;
  help?: ReactElement | ReactText;
  persistedHelp?: ReactElement | ReactText;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
  required?: boolean;
  // TODO: Use generics to type value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (val: any, form: UseFormReturn) => void;
};

// Note: This method of implicitly deriving requiredness from the yup
// schema does not work when conditional yup methods like `when` or `transform`
// are used to add requiredness. See: https://github.com/jaredpalmer/formik/issues/1241#issuecomment-547452352
// In those cases, the `required` prop on fields should be set explicitly
function isYupFieldRequired(validation?: yup.AnySchema): boolean {
  const requirednessIndex = validation
    ?.describe()
    ?.tests?.findIndex((test) => test.name === 'required');

  if (requirednessIndex === undefined) {
    return false;
  }

  return requirednessIndex >= 0;
}

type WrapperProps = Pick<BaseFieldProps, 'className' | 'size'> & {
  children: ReactNode;
  useGrid: boolean;
};

// disable the grid layout if we need to (e.g. putting checkboxes in line)
function LayoutWrapper({ children, className, useGrid, size }: WrapperProps) {
  if (!useGrid) {
    return <div className={cx(field, className)}>{children}</div>;
  }

  return (
    <GridItem size={size} className={cx(field, className)}>
      {children}
    </GridItem>
  );
}

export function BaseField({
  classes,
  label,
  name,
  size = 6,
  children,
  help,
  persistedHelp,
  onChange,
  className,
  required,
  useGrid = true,
}: BaseFieldProps & {
  children?: (props: RenderProps) => ReactNode;

  // only used for checkboxes currently, in case we
  // want to display them in line rather than stacked
  useGrid?: boolean;
}) {
  const form = useFormContext();
  const {
    control,
    formState: { errors },
  } = form;
  const { fields } = useFormConfigCtx();

  // Observe changes to the value and trigger onChange
  const watchedValue = useWatch({ name, disabled: !onChange });

  useEffect(() => {
    onChange?.(watchedValue, form);

    // `form` is excluded from deps here because the reference
    // changes frequently but functionally it doesn't matter for
    // the purpose of this hook.
    // `onChange` is excluded from deps here because although
    // it's technically correct to include it, it introduces a
    // very easy avenue for getting into an infinite render loop
    // unless you enforce useCallback for all onChange values.
    // functionally it doesn't really matter as this effect only
    // controls _when_ onChange gets called, the actual subscription
    // is managed a few lines up via `useWatch`
  }, [watchedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const controller = useController({ name, control });

  const arrayField = getArrayField(name);
  const validationError = arrayField
    ? (errors[arrayField.fieldName] as FieldErrorsImpl)?.[arrayField.index]
    : get(errors, name);
  const error = Array.isArray(validationError)
    ? validationError.filter(Boolean)[0]
    : validationError;

  const fieldName = arrayField ? arrayField.fieldName : name;
  // Get requiredness set explicitly via props or implicitly from yup validation
  const isRequired =
    required || isYupFieldRequired(fields[fieldName]?.validation);

  const [overrideErrorPlacement, setOverrideErrorPlacement] = useState(false);
  const renderErrorOverride = useCallback(() => {
    if (!overrideErrorPlacement) {
      setOverrideErrorPlacement(true);
    }
    return <PersistedHelp error={error} />;
  }, [error, overrideErrorPlacement]);

  return (
    <LayoutWrapper
      className={cx(className, classes?.root)}
      size={size}
      useGrid={useGrid}
    >
      {label && (
        <Label
          className={cx(labelText.default, classes?.label)}
          label={label}
          labelProps={{ htmlFor: name }}
          description={help}
          descriptionProps={{ className: classes?.helpText }}
          isRequired={isRequired}
          hasError={!!error}
        />
      )}
      {children?.({ controller, isRequired, renderError: renderErrorOverride })}
      <PersistedHelp
        persistedHelp={persistedHelp}
        error={error}
        overrideErrorPlacement={overrideErrorPlacement}
      />
    </LayoutWrapper>
  );
}

function PersistedHelp({
  persistedHelp,
  error,
  overrideErrorPlacement,
}: {
  persistedHelp?: ReactElement | ReactText;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl>;
  overrideErrorPlacement?: boolean;
}) {
  if (error && overrideErrorPlacement) {
    return null;
  }

  if (!error && !persistedHelp) {
    return null;
  }

  let content = <></>;

  if (error) {
    content = (
      <>
        <AlertTriangle className={errorIcon} /> {error.message}
      </>
    );
  } else if (persistedHelp) {
    content = <>{persistedHelp}</>;
  }

  return (
    <div className={persistedHelpContainer}>
      <FormHelperText className={persistedHelpText}>{content}</FormHelperText>
    </div>
  );
}
