import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
  YesNoRadioGroup,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { Form } from '@/shared/common/Form';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import type { DietFormFields } from '../../shared/generalAssessment/formConfig';
import { getDietFormConfig } from '../../shared/generalAssessment/formConfig';
import { cycle17InitVisitPath } from '../paths';

export function Diet() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<DietFormFields>(
    cycle17InitVisitPath('/general-assessment', '/diet'),
    getDietFormConfig(
      intl,
      undefined,
      TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT,
    ),
  );
  const sugaryDrinks = form.watch('sugaryDrinks');

  return (
    <Wizard.Step
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Diet' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Eating habits are another important piece of your overall health. How many meals do you typically have each day? What do those meals usually consist of? Do you like to cook? Do you often eat out? Do you eat out at restaurants or get fast food more often?" />
        </Script>
        <Form.TextArea
          size={12}
          rows={3}
          name="currentDiet"
          label={<FormattedMessage defaultMessage="Current diet" />}
        />
        <Script>
          <FormattedMessage defaultMessage="How often do you eat processed foods? These are foods that you would get from the center aisles of the grocery store - like chips, cookies, or frozen dinners?" />
        </Script>
        <Form.TextArea
          size={12}
          rows={3}
          name="processedFoods"
          label={
            <FormattedMessage defaultMessage="Current processed food habits" />
          }
        />
        <YesNoRadioGroup
          name="sugaryDrinks"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              <FormattedMessage defaultMessage="Do you drink regular sodas, fruit juices, or sweet tea?" />
            </Script>
          }
        />
        {sugaryDrinks === 'true' && (
          <Form.TextField
            size={12}
            name="sugaryDrinksDescription"
            classes={{ label: scriptLabel }}
            label={
              <Script>
                <FormattedMessage defaultMessage="How many servings do you drink per day?" />
              </Script>
            }
            required
          />
        )}
        <Form.TextField
          size={12}
          name="waterPerDay"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              <FormattedMessage defaultMessage="How much water do you drink per day?" />
            </Script>
          }
          required
        />
        <YesNoRadioGroup
          name="addSalt"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
            </Script>
          }
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
