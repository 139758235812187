import { AppShell } from './AppShell';
import { ErrorBoundary } from './ErrorBoundary';

/**
 * This componet, sub-components, and utils are intended to provide the base
 * instrumentation and functionality needed for a Cadence Application.
 * Component heirarchy *does* matter.
 *
 * Example:
 * renderApp(() => (
 *  <CadenceApp>
 *    <CadenceApp.ErrorBoundary>
 *      <App />
 *    </CadenceApp.ErrorBoundary>
 *  </CadenceApp>
 * ));
 */
export { renderApp } from './renderApp';
export const CadenceApp = Object.assign(AppShell, {
  ErrorBoundary,
});
