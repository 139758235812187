import { format, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { formatProviderForScript } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import {
  verificationValue,
  verificationsList,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { usePatientContext, usePatientDetails } from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { displayHomeState } from '@/shared/patient/patientInfoUtils';

import { useCNFormContext } from '../../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../../metadata';
import { AdminProfileButton } from '../../../shared/AdminProfileButton';

export function IntroContactInfo() {
  const intl = useIntl();
  const { patientId } = useCNFormContext();
  const { currentUserFullName } = useCurrentUser();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    false,
    Boolean(patientId),
  );
  const timeEstimate = useTimeEstimate(
    intl.formatMessage({ defaultMessage: '1-3 min' }),
  );

  const patientName = `${patient?.first_name} ${patient?.last_name}`;

  return (
    <Wizard.Step
      isLoading={!patient || !context || isLoadingPatient || isLoadingContext}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
      subtitle={timeEstimate}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage
            defaultMessage="Hi, is this {patientName}? This is {username} calling from Cadence and {provider} on a recorded line. Before we begin I just need to make sure I have the correct information in front of me."
            values={{
              username: currentUserFullName,
              provider: formatProviderForScript(
                context?.primaryPhysicianGivenName,
                context?.primaryPhysicianFamilyName,
                context?.primaryPhysicianRole,
              ),
              patientName,
            }}
          />
        </Script>
        <ol className={verificationsList}>
          <li>
            <FormattedMessage
              defaultMessage="Confirm patient name: <verification>{patientName}</verification>"
              values={{
                patientName,
                verification,
              }}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Confirm patient date of birth: <verification>{dob}</verification>"
              values={{
                dob: patient
                  ? format(parseISO(patient.dob), 'MMMM d, yyyy')
                  : '',
                verification,
              }}
            />
          </li>
          <li>
            <FormattedMessage
              defaultMessage="Current location: <verification>{homeState}</verification>"
              values={{
                homeState: patient ? displayHomeState(patient) : '',
                verification,
              }}
            />
          </li>
        </ol>
        <AdminProfileButton />
        <InlineMessage>
          <FormattedMessage defaultMessage="If the patient is in a state that you are not licensed in, please reschedule the patient." />
        </InlineMessage>
      </FieldGroup>
    </Wizard.Step>
  );
}

function verification(children: string) {
  return <span className={verificationValue}>{children}</span>;
}
