{{> header val="General Assessment" }}
<br/>

{{> subheader val="Social History" }}
<p>1. History (past or current) of Tobacco use?</p>
<p>  - Current:</p>
<p>  - Past:</p>
<p>  - Any e-cigarettes or chewing tobacco?</p>
<p>2. Do you drink alcohol?</p>
<p>  - If Yes, beer, wine, and or liquor?</p>
<p>  - # of servings per week</p>
<p>3. Do you use marijuana or recreational drugs?</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>1. How many times a week do you eat fast food meals or snacks/desserts/or other sweets?</p>
<p>2. Do you add salt to meals regularly?</p>
<p>3. Do you drink regular sodas or glasses of sweet tea?</p>
<p>  a. If yes, # of servings per day?</p>
<p>4. How much water do you drink per day?</p>
<p>5. Do you weigh yourself? If yes, what is your most recent weight?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>Do you exercise regularly? What type? How long and how often?</p>
{{> free_text_space }}

{{> subheader val="Activities of Daily Living" }}
<p>Do you require any assistance with walking, eating, toileting, or dressing?</p>
{{> free_text_space }}

{{> header val="Action Plan"}}
<br/>

{{> subheader val="My Health Goal"}}
<p>The main thing I want to change about my health is: [choose from options below]</p>
<ul>
  <li>Become more physically active</li>
  <li>Take my medications more consistently</li>
  <li>Lower my weight</li>
  <li>Improve my food choices</li>
  <li>Reduce my stress</li>
  <li>Better understand my disease</li>
  <li>Get better sleep</li>
  <li>Quit smoking</li>
</ul>

{{> subheader val="Patient Education"}}
<p>Today, the following modules were reviewed with the patient:</p>
<p>[insert module name]</p>
<br/>

{{> subheader val="Patient self-defined action steps"}}
<p>Top things I can do to meet this health goal are:</p>
<p>1. Action step:</p>
<p>  a. Timing and Frequency:</p>
