import classnames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import { Stack } from '@/deprecated/mui';
import { conditionToAbbreviation } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import { Tag } from '@/shared/common/Tag';
import type { Condition } from '@/shared/types/clinicalprofile.types';

import styles from './styles.module.scss';

const cx = classnames.bind(styles);

type ConditionTagsProps = {
  conditions?: Condition[];
};

function NoCondition() {
  return (
    <span className={cx('error-text')}>
      <FormattedMessage defaultMessage="No Condition" />
    </span>
  );
}

export function ConditionTags({ conditions }: ConditionTagsProps) {
  return (
    <Stack direction="row" spacing={0.5}>
      {conditions?.length ? (
        conditions.map((c) => <Tag key={c}>{conditionToAbbreviation(c)}</Tag>)
      ) : (
        <NoCondition />
      )}
    </Stack>
  );
}
