import type { ReactNode } from 'react';

import { container } from './BoxContainer.css';

type Props = {
  variant?: 'default' | 'warning';
  children: ReactNode;
};

export function BoxContainer({ variant = 'default', children }: Props) {
  return <div className={container[variant]}>{children}</div>;
}
