import type { IntlShape } from 'react-intl';

import { ReadingDataType } from '@/shared/types/patientSummary.types';
import type { AllHeartRateTagType } from '@/shared/types/tagsAndThreshold.types';
import { HeartRateTagType } from '@/shared/types/tagsAndThreshold.types';
import type { HeartRateVital } from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';

import type {
  AlertLoggerInfo,
  HeartRateFormatterProps,
} from '../../AlertDescription.types';
import { HeartRateAlertFormatter } from '../alertDescriptionFormatters';
import type {
  AlertDescriptionStrategy,
  TagRelatedValuesRequest,
} from '../baseAlertDescriptionStrategy';
import { BaseAlertDescriptionStrategy } from '../baseAlertDescriptionStrategy';
import { getComparisonOperator } from '../sharedAlertsUtils';

export class HeartRateAlertStrategy
  extends BaseAlertDescriptionStrategy<AllHeartRateTagType, HeartRateVital>
  implements AlertDescriptionStrategy
{
  constructor(intl: IntlShape) {
    super(
      VitalType.HeartRate,
      Object.values(HeartRateTagType),
      new HeartRateAlertFormatter(intl),
      ReadingDataType.HeartRate,
    );
  }

  protected getTagRelatedValues(
    request: TagRelatedValuesRequest<AllHeartRateTagType, HeartRateVital>,
    _: HeartRateVital[],
    alertLoggerInfo: AlertLoggerInfo,
  ): HeartRateFormatterProps {
    const currentReading = request.relatedReading.pulse;
    const threshold = this.getThresholdValue(
      request.tag,
      request.relatedReading,
      alertLoggerInfo,
    );
    const operator = getComparisonOperator(currentReading, threshold, true);

    return {
      operator,
      values: {
        currentReading,
        threshold,
      },
    };
  }
}
