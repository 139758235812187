import { datadogLogs } from '@datadog/browser-logs';
import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import { settings } from '@/config';
import { getFalconVersion } from '@/monitoring.utils';

import reportWebVitals from '../reportWebVitals';

export async function instrumentApp() {
  if (settings.VITE_SENTRY_DSN !== undefined) {
    Sentry.init({
      dsn: settings.VITE_SENTRY_DSN,
      environment: settings.VITE_ENVIRONMENT,
      release: getFalconVersion(),
    });
  }

  if (
    settings.VITE_DATADOG_APPLICATION_ID !== undefined &&
    settings.VITE_DATADOG_CLIENT_TOKEN !== undefined
  ) {
    datadogRum.init({
      applicationId: settings.VITE_DATADOG_APPLICATION_ID,
      clientToken: settings.VITE_DATADOG_CLIENT_TOKEN,
      service: 'falcon',
      enableExperimentalFeatures: ['feature_flags'],
      env: settings.VITE_ENVIRONMENT,
      version: getFalconVersion(),
      sampleRate: 100,
      sessionSampleRate: 100,
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
      allowedTracingOrigins: [settings.API_URL || ''],
      trackLongTasks: true,
      trackUserInteractions: true,
      trackResources: true,
    });

    datadogRum.startSessionReplayRecording();
  }

  if (settings.VITE_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: settings.VITE_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'falcon',
      env: settings.VITE_ENVIRONMENT,
      version: getFalconVersion(),
      forwardErrorsToLogs: false,
      forwardConsoleLogs: [],
      forwardReports: [],
      sessionSampleRate: 100,
      telemetrySampleRate: 0,
    });
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
