import classnames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Snackbar } from '@/shared/common/Snackbar';
import { useCaregivers } from '@/shared/hooks/queries';
import type { Caregiver } from '@/shared/types/caregiver.types';
import type { RelationshipKey } from '@/shared/types/patient.types';
import { Relationship } from '@/shared/types/patient.types';
import type { PhoneType } from '@/shared/types/shared.types';

import { Contact } from '../Contact';
import { EmptySection } from '../EmptySection';
import { InfoRow } from '../InfoRow';
import { SectionHeader } from '../SectionHeader';
import { getAssociationsLink } from '../adminLinks';
import styles from './caregivers.module.scss';

const cx = classnames.bind(styles);

type CaregiverProps = {
  caregiver: Caregiver;
};

function CaregiverRow({ caregiver }: CaregiverProps) {
  const name = `${caregiver.firstName} ${caregiver.lastName}`;
  const relationship =
    Relationship[caregiver.patientRelationship as RelationshipKey];

  return (
    <>
      <InfoRow left={`${name}, ${relationship}`} />
      {caregiver.contacts?.map(({ contact, isPreferred }) => (
        <Contact
          key={`contact-${contact.id}`}
          isPreferred={isPreferred}
          phoneNumber={contact.phoneNumber}
          phoneType={contact.phoneType as PhoneType}
        />
      ))}
    </>
  );
}

type CaregiversProps = {
  patientId: string;
};

export function Caregivers({ patientId }: CaregiversProps) {
  const intl = useIntl();
  const { isLoading, error, data: caregivers } = useCaregivers(patientId);
  const associationsLink = getAssociationsLink(patientId, 'caregivers');
  const hasCaregivers = caregivers?.length;

  return (
    <>
      {error && <Snackbar message={error} variant="error" />}
      <SectionHeader editLink={hasCaregivers ? associationsLink : undefined}>
        <FormattedMessage defaultMessage="Caregiver(s)" />
      </SectionHeader>
      <LoadingPlaceholder isLoading={isLoading}>
        {hasCaregivers ? (
          caregivers.map(({ caregiver }) => (
            <div
              key={`caregiver-${caregiver.id}`}
              className={cx('caregiver-row')}
            >
              <CaregiverRow caregiver={caregiver} />
            </div>
          ))
        ) : (
          <EmptySection
            message={intl.formatMessage({
              defaultMessage: 'No assigned caregivers',
            })}
            action={intl.formatMessage({
              defaultMessage: 'Add caregiver',
            })}
            href={associationsLink}
          />
        )}
      </LoadingPlaceholder>
    </>
  );
}
