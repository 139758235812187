{{#if deviceTypes}}
  <ul>
  {{#ifContains deviceTypes "BLOOD_PRESSURE_METER" }}
    {{#with bloodPressure}}
      <li><strong>BP</strong> | <i>30-Day Avg:</i> {{bp systolic.avg30d diastolic.avg30d}} | <i>7-Day Avg:</i> {{bp systolic.avg7d diastolic.avg7d}} | <i>30-Day Min:</i> {{bp systolic.min30d diastolic.min30d}} | <i>30-Day Max:</i> {{bp systolic.max30d diastolic.max30d}}</li>
    {{/with}}
    {{#with heartRate}}
      <li><strong>HR</strong> | <i>30-Day Avg:</i> {{hr avg30d}} | <i>7-Day Avg:</i> {{hr avg7d}} | <i>30-Day Min:</i> {{hr min30d}} | <i>30-Day Max:</i> {{hr max30d}}</li>
    {{/with}}
  {{/ifContains}}

  {{#ifContains deviceTypes "WEIGHT_SCALE" }}
    {{#with weight}}
      <li><strong>Weight</strong> | <i>30-Day Avg:</i> {{weight avg30d}} | <i>7-Day Avg:</i> {{weight avg7d}} | <i>30-Day Min:</i> {{weight min30d}} | <i>30-Day Max:</i> {{weight max30d}}</li>
    {{/with}}
  {{/ifContains}}

  {{#ifContains deviceTypes "BLOOD_GLUCOSE_METER" }}
    {{#with bloodGlucose}}
      <li><strong>Blood Glucose</strong> | <i>30-Day Avg:</i> {{bg avg30d}} | <i>7-Day Avg:</i> {{bg avg7d}} | <i>30-Day Min:</i> {{bg min30d}} | <i>30-Day Max:</i> {{bg max30d}}</li>
    {{/with}}
  {{/ifContains}}
  </ul>
{{else}}
  <p>No assigned devices found</p>
{{/if}}
