import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';
import Session from '@/shared/utils/session';

import {
  FETCH_PATIENT_DETAILS,
  FETCH_PATIENT_DETAILS_FAILURE,
  patientDetailsFetched,
} from '../actionCreators';

const patientDetailsEpic = (action$: any) =>
  action$.pipe(
    ofType(FETCH_PATIENT_DETAILS),
    switchMap((action: TActionCreatorById) =>
      from(Session.Api.get(`/pms/api/v1/patients/${action.id}`)).pipe(
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      ),
    ),
    map((response: AxiosResponse<TPatientsDetailsPayload>) =>
      patientDetailsFetched(response.data),
    ),
    catchError((error, caught) =>
      merge(
        of({
          type: FETCH_PATIENT_DETAILS_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default patientDetailsEpic;
