import cx from 'classnames';
import { Component } from 'react';

import {
  FormControl,
  FormHelperText,
  TextField as MuiTextField,
  withStyles,
} from '@/deprecated/mui';

// TODO: update styles

const styles = () => ({
  label: {
    fontWeight: 800,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    color: 'black',
    lineHeight: 1,
  },
  input: {
    border: `1px solid #EAEAEA`,
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '5px',
    minHeight: '32px',
    color: 'black',
    borderRadius: '4px',
    fontFamily: 'Inter',
    '&:disabled': {
      color: '#898999',
    },
  },
  error: {
    borderColor: '#f04053ff',
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
type TextFieldProps = {
  input?: any;
  meta?: any;
  name: string;
  value: string;
  label?: any;
  classes: any;
  onChange: (event: { target: { value: string } }) => void;
  type?: string;
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  customStyle?: any;
  hasError?: boolean;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

class TextField extends Component<TextFieldProps> {
  render() {
    const {
      input,
      name,
      meta,
      value,
      label,
      classes,
      onChange,
      type,
      required,
      customStyle,
      fullWidth,
      hasError,
      ...custom
    } = this.props;
    const error = meta?.error;
    const touched = meta?.touched;

    return (
      <FormControl fullWidth={fullWidth}>
        <MuiTextField
          id={name}
          name={name}
          label={label}
          value={value}
          InputProps={{
            classes: {
              input: cx({
                [classes.input]: true,
                [classes.error]: hasError,
              }),
            },
            disableUnderline: true,
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          onChange={onChange}
          type={type || 'text'}
          required={required}
          style={customStyle}
          error={error && touched}
          variant="standard"
          {...input}
          {...custom}
        />
        {touched && error && (
          <FormHelperText error>
            <span
              style={{
                display: 'block',
                width: '300px',
                ...customStyle,
              }}
            >
              {error.message}
            </span>
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

// eslint-disable-next-line import/no-default-export, react-refresh/only-export-components
export default withStyles(styles)(TextField);
