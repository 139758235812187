import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import AlertTriangleIcon from '@/shared/assets/svgs/alertTriangle.svg?react';

import { medRowAlertIcon, medRowAlertSubtext } from './MedicationRow.css';
import { medCategoryAlert } from './styles.css';
import type { MedCategory } from './utils/requiredActions';
import { useHasRequiredMedActions } from './utils/requiredActions';

type Props = {
  patientId: string;
  category: MedCategory;
};

export function MedCategoryRequiredActions({ patientId, category }: Props) {
  const hasRequiredActions = useHasRequiredMedActions(
    patientId,
    true,
    category,
  );

  return hasRequiredActions ? (
    <div className={cx(medCategoryAlert, medRowAlertSubtext)}>
      <AlertTriangleIcon className={medRowAlertIcon} />
      <FormattedMessage defaultMessage="There are medications that require action" />
    </div>
  ) : null;
}
