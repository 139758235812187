export enum FormType {
  Review = 'review',
  Remove = 'remove',
  ManageRx = 'manageRx',
}

export enum TitrationFormType {
  Start = 'start',
  Titrate = 'titrate',
}
