import mergeWith from 'lodash/mergeWith';

/**
 * Merges encounter module instances without merging Raw DraftJS Contents
 */
export function mergeModuleInstanceInputs<
  T = Record<string, unknown>,
  V = Record<string, unknown>,
>(inputsA?: T, inputsB?: V) {
  return mergeWith({}, inputsA, inputsB, (objValue, srcValue) => {
    if (isRawDraftJsContent(objValue) || isRawDraftJsContent(srcValue)) {
      return srcValue;
    }
    return undefined;
  });
}

function isRawDraftJsContent(obj?: Record<string, unknown>) {
  return (
    obj &&
    typeof obj === 'object' &&
    Array.isArray(obj?.blocks) &&
    typeof obj?.entityMap === 'object'
  );
}
