import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Script } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { InlineMessage } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/InlineMessage';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Wizard } from '@/shared/common/Wizard';

import type { CNSectionMap } from '../../metadata';
import { ActionStepsField } from './ActionStepsField';
import { infoInline } from './SharedSetActionSteps.css';
import type { SetActionStepsFields } from './formConfig';

export type TActionStep = {
  step: string | undefined;
  frequency: string | undefined;
  dependencies: string | undefined;
};

type Props = {
  form: ConfiguredForm<SetActionStepsFields>;
  sections: CNSectionMap;
  nextPath: string;
  patientGoalText?: Nullable<string>;
  isLoading?: boolean;
};

export function SharedSetActionSteps({
  form,
  sections,
  nextPath,
  patientGoalText,
  isLoading,
}: Props) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Wizard.Step
      form={form}
      isLoading={isLoading}
      sections={sections}
      title={intl.formatMessage({
        defaultMessage: 'Action steps',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(nextPath);
      }}
    >
      <Script>
        <FormattedMessage
          defaultMessage={`The top things I can do to meet my health goal{goal, select, NOT_FOUND {} other {, "{goal}",}} are:`}
          values={{ goal: patientGoalText || 'NOT_FOUND' }}
        />
      </Script>
      <ActionStepsField
        name="actionSteps"
        label=""
        aria-label={intl.formatMessage({ defaultMessage: 'Action Steps' })}
        form={form}
        sections={sections}
      />
      <InlineMessage className={infoInline}>
        <FormattedMessage defaultMessage="Each action should include what they will do, how often, and any dependencies they may have in achieving the goal. The patient should be reasonably confident they can take these actions." />
      </InlineMessage>
    </Wizard.Step>
  );
}
