import type { UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery } from 'react-query';

import { useFlatPages } from '@/reactQuery';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type { Patient } from '@/shared/types/patient.types';

import { useFiltersQuery } from './useFiltersQuery.hook';

export function usePatientsInfiniteQuery(
  hasActiveAlert: boolean,
  params: { sortBy?: string; orderBy?: string } = {},
  config: UseInfiniteQueryOptions<PaginatedData<Patient>> = {},
) {
  const { sortBy, orderBy } = params;
  const { apiSearchParams } = useFiltersQuery();
  const patientsInfiniteQuery = useInfiniteQuery<PaginatedData<Patient>>(
    [
      'pms',
      'api',
      'v1',
      'patients',
      {
        ...(sortBy && { sort_by: sortBy }),
        ...(orderBy && { order_by: orderBy }),
        has_active_alert: hasActiveAlert,
        ...Object.fromEntries(apiSearchParams),
        market_id: apiSearchParams.getAll('market_id'),
        hospital_id: apiSearchParams.getAll('hospital_id'),
      },
      'infinite',
    ],
    config,
  );
  const totalPatients = patientsInfiniteQuery.data?.pages?.[0].metadata.total;
  const patients = useFlatPages(patientsInfiniteQuery);

  const isEmptyResult = !totalPatients && !patientsInfiniteQuery.isLoading;
  return {
    patientsInfiniteQuery,
    patients,
    isEmptyResult,
    totalPatients,
  };
}
