import classnames from 'classnames/bind';
import { useIntl } from 'react-intl';

import { Checkbox, FormControlLabel } from '@/deprecated/mui';

import { EmptySection } from '../EmptySection';
import styles from './patientGoals.module.scss';
import type { Goal } from './patientGoals.types';

const cx = classnames.bind(styles);

type GoalCheckboxProps = {
  checked: boolean;
  goal: string;
  onChange: () => void;
};

function GoalCheckbox({ checked = false, goal, onChange }: GoalCheckboxProps) {
  return (
    <FormControlLabel
      label={goal}
      control={
        <Checkbox
          className={cx('checkbox')}
          checked={checked}
          onChange={() => onChange()}
        />
      }
    />
  );
}

type PatientGoalsViewProps = {
  goals?: Goal[];
  onCheckToggled: (goalId: number) => void;
  onCreate: () => void;
};

export function PatientGoalsView({
  goals = [],
  onCheckToggled,
  onCreate,
}: PatientGoalsViewProps) {
  const intl = useIntl();

  if (!goals.length) {
    return (
      <EmptySection
        message={intl.formatMessage({
          defaultMessage: `There aren't any goals`,
        })}
        action={intl.formatMessage({
          defaultMessage: 'Create a goal',
        })}
        onAction={onCreate}
      />
    );
  }

  return (
    <div className={cx('container')}>
      {goals.map(({ is_completed, text, id }) => (
        <GoalCheckbox
          key={`goal-${id}`}
          checked={is_completed}
          goal={text}
          onChange={() => onCheckToggled(id)}
        />
      ))}
    </div>
  );
}
