import omit from 'lodash/omit';
import { useCallback, useEffect, useMemo } from 'react';
import type {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { useMarshaledTimeTrackingPayload } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/hooks/useMarshaledTimeTrackingPayload.hook';
import { TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/i18nMappings';
import { getMarkdownFromNode } from '@/pages/patients/patientDetails/ui/Notes/NotePreview/getMarkdownFromNode';
import {
  NotesFilterValue,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import {
  appointmentIdPayloadParam,
  invalidatePatientNoteQueries,
  noShowAppointmentIdPayloadParam,
  usePatientNotes,
  zendeskPayloadParam,
} from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import { timeTrackingFormValsToEntry } from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import type { TimeTrackingFormFields } from '@/pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm';
import { useFlatPages } from '@/reactQuery';
import type { WizardFormData } from '@/shared/common/Wizard/state';
import { useGetWizardStepValues } from '@/shared/common/Wizard/state';
import type {
  CancelablePromise,
  AutosavedNoteSchema as GeneratedAutosavedNoteSchema,
  NoteRequestSchema as GeneratedNoteRequestSchema,
  NoteSchema as GeneratedNoteSchema,
} from '@/shared/generated/api/pms';
import { PatientNotesService } from '@/shared/generated/api/pms';
import { invalidatePatientMonitoringSessionQueries } from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { NoteStatus, NoteType } from '@/shared/types/note.types';
import type {
  PaginatedData,
  PaginationMetadata,
} from '@/shared/types/pagination.types';

import { CN_SECTIONS_MAP } from '../CNWizardForm/sections/metadata';
import type {
  CNVisitType,
  StronglyTypeCnNoteField,
} from '../CNWizardForm/types';
import { useCnTemplateContext } from '../templates/hooks/useCnTemplateContext';
import { CN_EXPERIENCE_TEMPLATES_MAP } from '../templates/templates';
import { cnNoteKeys, cnNotePatientKeyBase } from './querykeys';

type AutosavedNoteSchema =
  StronglyTypeCnNoteField<GeneratedAutosavedNoteSchema>;
type NoteSchema = StronglyTypeCnNoteField<GeneratedNoteSchema>;
type NoteRequestSchema = StronglyTypeCnNoteField<GeneratedNoteRequestSchema>;

function useBaseNotePayload(cnVisitType: CNVisitType) {
  const { currentUserFullName } = useCurrentUser();
  return useMemo(() => {
    const title = getMarkdownFromNode(
      TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES[cnVisitType],
    );
    return {
      title,
      author: currentUserFullName,
      rtf_body: {
        blocks: [],
        entityMap: {},
      },
    };
  }, [cnVisitType, currentUserFullName]);
}

function usePutAutosaveCnNote(
  patientId: string,
  config: Omit<
    UseMutationOptions<AutosavedNoteSchema, unknown, AutosavedNoteSchema>,
    'mutationFn'
  > = {},
) {
  const client = useQueryClient();
  return useMutation(
    (payload: AutosavedNoteSchema) =>
      PatientNotesService.putPmsApiV1NotesPatientAutosaved(patientId, payload),
    {
      ...config,
      onSuccess: async (data, vars, ctx) => {
        if (config.onSuccess) {
          // On success overrides, and takes responsibility for invalidating
          await config.onSuccess(data, vars, ctx);
        } else {
          await client.invalidateQueries(cnNoteKeys.autosaved(patientId));
        }
      },
    },
  );
}

export function useAutosaveCnWizardState(
  patientId: string,
  cnVisitType: CNVisitType,
  zendeskTicketId: Nullable<number>,
  shouldInvalidate: boolean,
  appointmentId: Maybe<string>,
  noShowAppointmentId: Maybe<string>,
) {
  const client = useQueryClient();
  const { mutate: putAutosaveCnNote, ...mutationResult } = usePutAutosaveCnNote(
    patientId,
    {
      async onSuccess() {
        if (shouldInvalidate) {
          await client.invalidateQueries(cnNoteKeys.autosaved(patientId));
        }
      },
    },
  );
  const basePayload = useBaseNotePayload(cnVisitType);

  // TODO: Debounce this?
  const mutate = useCallback(
    (wizardFormData: WizardFormData) =>
      putAutosaveCnNote({
        ...basePayload,
        // Skipping body generation on autosave, it will be generated on publish for perf.
        // Must be at least of length 1
        body: ' ',
        clinical_navigator_note: [
          {
            form: wizardFormData,
            ...getRemappedVisitType(cnVisitType),
          },
        ],
        ...zendeskPayloadParam(zendeskTicketId),
        ...appointmentIdPayloadParam(appointmentId),
        ...noShowAppointmentIdPayloadParam(noShowAppointmentId),
      }),
    [
      appointmentId,
      basePayload,
      cnVisitType,
      noShowAppointmentId,
      putAutosaveCnNote,
      zendeskTicketId,
    ],
  );

  return {
    ...mutationResult,
    mutate,
  };
}

export function useAutosavedCnNote(
  patientId: string,
  config: UseQueryOptions<Maybe<AutosavedNoteSchema>> = {},
) {
  return useQuery({
    queryKey: cnNoteKeys.autosaved(patientId),
    queryFn: () =>
      PatientNotesService.getPmsApiV1NotesPatientAutosaved(patientId).then(
        (autosavedNote) => {
          if (!autosavedNote?.clinical_navigator_note) {
            return undefined;
          }

          const { visitType, originalVisitType } =
            autosavedNote.clinical_navigator_note[0];

          return {
            ...autosavedNote,
            clinical_navigator_note: [
              {
                ...autosavedNote.clinical_navigator_note[0],
                visitType: originalVisitType ?? visitType,
              },
            ],
          };
        },
      ) as CancelablePromise<Maybe<AutosavedNoteSchema>>,
    ...config,
  });
}

type CreateCnNotePayload = WizardFormData & {
  apptId: Maybe<string>;
  shouldEmrSync: boolean;
  zendeskTicketId: Maybe<number>;
  noShowApptId?: Maybe<string>;
};

export function useCreateCnNote(
  patientId: string,
  noteId: Maybe<number>,
  cnVisitType: CNVisitType,
  options: Omit<
    UseMutationOptions<NoteSchema, unknown, CreateCnNotePayload, unknown>,
    'mutationFn'
  > = {},
) {
  const client = useQueryClient();
  const baseNotePayload = useBaseNotePayload(cnVisitType);
  const noteBodyTemplateFn = CN_EXPERIENCE_TEMPLATES_MAP[cnVisitType];
  const { isLoading: loadingContext, context } = useCnTemplateContext(
    patientId,
    noteId,
  );
  const timeTracking = useTimeTracking(cnVisitType);

  return {
    loadingContext,
    ...useMutation(
      ({
        apptId,
        noShowApptId,
        shouldEmrSync,
        zendeskTicketId,
        ...wizardFormData
      }) =>
        PatientNotesService.postPmsApiV1NotesPatient(patientId, {
          ...baseNotePayload,
          appointment_id: apptId,
          should_emr_sync: shouldEmrSync,
          body: noteBodyTemplateFn({ ...wizardFormData, ...context }),
          time_tracking: timeTracking as NoteRequestSchema['time_tracking'],
          ...noShowAppointmentIdPayloadParam(noShowApptId),
          ...zendeskPayloadParam(zendeskTicketId),
          clinical_navigator_note: [
            {
              form: wizardFormData,
              ...getRemappedVisitType(cnVisitType),
            },
          ],
        }) as CancelablePromise<NoteSchema>,
      {
        ...options,
        onSuccess: async (...args) => {
          await invalidatePatientNoteQueries(patientId, client);
          await invalidatePatientMonitoringSessionQueries(patientId, client);
          await client.invalidateQueries(cnNotePatientKeyBase(patientId));
          await client.invalidateQueries(
            cnNoteKeys.publishedNotesInfinite(patientId, {
              is_clinical_navigator_note: true,
            }),
          );
          options.onSuccess?.(...args);
        },
      },
    ),
  };
}

export function useDeleteAutosavedCnNote(
  patientId: string,
  options: Omit<UseMutationOptions, 'mutationKey'> = {},
) {
  return useMutation(
    () => PatientNotesService.deletePmsApiV1NotesPatientAutosaved(patientId),
    options,
  );
}

const EMPTY_TIME_TRACKING: Partial<TimeTrackingFormFields> = {};
function useTimeTracking(cnVisitType: CNVisitType) {
  const timeTrackingFormVals =
    useGetWizardStepValues(
      CN_SECTIONS_MAP[cnVisitType],
      '/time-tracking',
      '/index',
    )<TimeTrackingFormFields>() || EMPTY_TIME_TRACKING;

  const timeEntry = timeTrackingFormValsToEntry(timeTrackingFormVals);
  return useMarshaledTimeTrackingPayload(timeEntry);
}

/**
 * Finds most recent published note where `findFunc` returns true
 */
const first = () => true;
export function useMostRecentPublishedNote(
  patientId: string,
  cnNotesOnly: boolean = false,
  predicate: (data: NoteSchema) => boolean = first,
  config: UseInfiniteQueryOptions<PaginatedData<NoteSchema>> = {},
) {
  const query = useInfinitePublishedNotes(patientId, cnNotesOnly, {
    ...config,
    staleTime: Infinity,
  });
  const { isFetching, hasNextPage, fetchNextPage } = query;
  const notes = useFlatPages<NoteSchema, 'data'>(query);
  const result = (notes.find(predicate) || null) as Nullable<NoteSchema>;
  const stillFetching = isFetching || (!!hasNextPage && result === null);

  useEffect(() => {
    if (!isFetching && hasNextPage && result === null) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, fetchNextPage, result]);

  return {
    ...omit(query, 'data'),
    isLoading: stillFetching,
    isFetching: stillFetching,
    note: result,
  };
}

function useInfinitePublishedNotes(
  patientId: string,
  cnNotesOnly: boolean = false,
  config: UseInfiniteQueryOptions<PaginatedData<NoteSchema>> = {},
) {
  return useInfiniteQuery<PaginatedData<NoteSchema>>(
    cnNoteKeys.publishedNotesInfinite(patientId, {
      is_clinical_navigator_note: cnNotesOnly,
    }),
    async (ctx) => {
      const resp = await PatientNotesService.getPmsApiV1NotesPatient(
        patientId,
        ctx.pageParam, // Page
        50, // Page size
        'updated_at',
        undefined,
        undefined,
        undefined,
        'desc',
        undefined,
        cnNotesOnly, // is_clinical_navigator_note
      );
      return {
        metadata: resp.metadata as PaginationMetadata,
        data: (resp.data || []) as NoteSchema[],
      };
    },
    {
      ...config,
      enabled: config.enabled && Boolean(patientId),
    },
  );
}

export function useHasClinicalEncounter(patientId: string) {
  const { items, infiniteQuery } = usePatientNotes(patientId, {
    noteTypes: [NoteType.Standard],
    notesFilter: NotesFilterValue.ClinicalEncounters,
    isNoShow: false,
  });

  return {
    isLoading: infiniteQuery.isLoading,
    hasClinicalEncounter: items.some(
      ({ status }) => status === NoteStatus.Published,
    ),
  };
}

function getRemappedVisitType(originalVisitType: CNVisitType): {
  visitType: CNVisitType;
  originalVisitType?: CNVisitType;
} {
  if (originalVisitType !== TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT) {
    return { visitType: originalVisitType };
  }

  return { visitType: TypeOfEncounter.INITIAL_CN_VISIT, originalVisitType };
}
