import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { badge } from './Badge.css';

type Props = {} & HTMLAttributes<HTMLSpanElement>;

export function Badge({ className, children, ...props }: Props) {
  return (
    <span {...props} className={cx(badge, className)}>
      {children}
    </span>
  );
}
