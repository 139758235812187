import type { DependencyList } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import type { HotkeyCallback, Options } from 'react-hotkeys-hook/dist/types';

const KEY_NAME_ESC = 'Escape';

// there's a bug with this library's handling of "esc"
// where it keeps triggering on any keypress after the
// initial trigger. this only seems to happen when we
// try to capture a keypress while an input is focused
// (enableOnFormTags and enableOnContentEditable). does
// not seem to be the case with any other keys ¯\_(ツ)_/¯
export function useEscHotkey(
  callback: HotkeyCallback,
  dependencies?: DependencyList,
  options?: Options,
) {
  useHotkeys(
    'esc',
    (kbEvent, hotkeysEvent) => {
      if (kbEvent.key !== KEY_NAME_ESC) {
        return false;
      }

      return callback(kbEvent, hotkeysEvent);
    },
    dependencies,
    options,
  );
}
