import { ofType } from 'redux-observable';
import { from, merge } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { logger } from '@/logger';
import { unregisterMonitoredUser } from '@/monitoring.utils';
import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';
import Session from '@/shared/utils/session';

import { LOGOUT, logout } from '../actionCreators';

const logoutEpic = (action$: any) =>
  action$.pipe(
    ofType(LOGOUT),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    switchMap((action: any) =>
      from(Session.Api.post('/auth/api/v1/logout')).pipe(
        tap(() => unregisterMonitoredUser()),
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      ),
    ),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map((response: any) => logout()),
    catchError((error, caught) => {
      logger.error('Failed to logout via API, logging out via client', error);
      window.localStorage.removeItem('persist:root');
      window.location.reload();
      return merge(caught);
    }),
  );

export default logoutEpic;
