import { Item, Section } from 'react-stately';

import { MultiSelect as BaseMultiSelect } from './MultiSelect';
import { SELECT_ALL_KEY } from './constants';

export const MultiSelect = Object.assign(BaseMultiSelect, {
  Option: Item,
  Section,
  SELECT_ALL_KEY,
});
