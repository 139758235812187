import type { ReactElement, ReactNode } from 'react';
import { cloneElement } from 'react';

import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import { container, indicator } from './styles.css';

type Props = {
  tooltip: ReactNode;
  children: ReactElement;
  variant: keyof typeof container & keyof typeof indicator;
};

export function BaseIndicator({ tooltip, variant, children }: Props) {
  return (
    <Tooltip content={tooltip} placement="bottom">
      <div className={container[variant]}>
        {cloneElement(children, { className: indicator[variant] })}
      </div>
    </Tooltip>
  );
}
