import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../../metadata';
import type { SetActionStepsFields } from '../../../shared/SetActionSteps';
import {
  SharedSetActionSteps,
  getSetActionStepsFormConfig,
} from '../../../shared/SetActionSteps';
import { cycle17InitVisitPath } from '../../paths';
import { usePatientGoalText } from '../usePatientGoal';

export function SetActionSteps() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<SetActionStepsFields>(
    cycle17InitVisitPath('/action-plan', '/set-action-steps'),
    getSetActionStepsFormConfig(intl),
  );
  const patientGoalText = usePatientGoalText();

  return (
    <SharedSetActionSteps
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      nextPath={cycle17InitVisitPath('/action-plan', '/review-action-steps')}
      patientGoalText={patientGoalText}
    />
  );
}
