import { useIntl } from 'react-intl';
import type { QueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { logger } from '@/logger';
import { cnNoteKeys } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/querykeys';
import {
  STALE_TIME,
  usePaginatedQuery,
  usePreventConcurrentMutations,
} from '@/reactQuery';
import type { QueryCallbacks } from '@/reactQuery/types';
import type { NoteSchema } from '@/shared/generated/api/pms';
import { PatientNotesService } from '@/shared/generated/api/pms';
import type {
  CreateNoteRequest,
  Note as PbNote,
} from '@/shared/generated/grpcGateway/note.pb';
import { NoteService } from '@/shared/generated/grpcGateway/note.pb';
import { useFlags } from '@/shared/hooks';
import { invalidatePatientMonitoringSessionQueries } from '@/shared/hooks/queries';
import {
  invalidateEncounterSuggestionsQuery,
  invalidateLastPublishedNpEncounterNoteQuery,
} from '@/shared/hooks/queries/encounter.queries';
import type { TimerState } from '@/shared/notes/Timer';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type {
  Note,
  NoteStatus,
  NoteType,
  TNoteBodyRTF,
} from '@/shared/types/note.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import Session from '@/shared/utils/session';

import type { EndFormValues } from './NoteEditor/noteFormState';
import type {
  EncounterModule,
  EncounterModuleId,
  EncounterModuleInstance,
  NotesFilterValue,
  TimeTracking,
} from './Notes.types';
import { getNotesFilterQuerystr } from './utils/getNotesFilterQuerystr';

const ENCOUNTER_MODULES_QUERY_KEY = ['pms', 'api', 'v1', 'encounter_modules'];

export function useEncounterModule(id: EncounterModuleId) {
  const { isLoading, data } = useEncounterModules();
  const encounterModule = getEncounterModuleById(data, id);

  return {
    isLoading,
    encounterModule,
  };
}

export function useEncounterModules() {
  return useQuery<PaginatedData<EncounterModule>>(ENCOUNTER_MODULES_QUERY_KEY, {
    staleTime: STALE_TIME.TEN_MINUTES,
  });
}

export function getEncounterModuleById(
  data: Maybe<PaginatedData<EncounterModule>>,
  id: EncounterModuleId,
) {
  return data?.data?.find((module) => module.id === id);
}

export const PATIENT_NOTES_QUERY_KEY_BASE = [
  'pms',
  'api',
  'v1',
  'notes',
  'patient',
];

export const patientNotesQueryKey = {
  draft: (patientId: string) => [
    ...PATIENT_NOTES_QUERY_KEY_BASE,
    patientId,
    'draft',
  ],
  createDraft: () => 'create_draft_note',
  createScheduledEncounter: () => 'create_scheduled_encounter',
  publish: () => 'publish_note',
};

type NoteFilterParams = {
  noteTypes?: NoteType[];
  notesFilter?: NotesFilterValue;
  isNoShow?: boolean;
};

type Callbacks<D = unknown, E = unknown> = {
  onSuccess?: (data?: D) => void;
  onError?: (err?: E) => void;
};

export function useCreateEncounter(callbacks: Callbacks<PbNote>) {
  const client = useQueryClient();

  return useMutation((req: CreateNoteRequest) => NoteService.CreateNote(req), {
    onSuccess: async (response) => {
      await client.invalidateQueries(PATIENT_NOTES_QUERY_KEY_BASE);
      callbacks.onSuccess?.(response);
    },
    onError: callbacks.onError,
  });
}

export function usePatientNotes(
  patientId: string,
  params: NoteFilterParams = {},
) {
  const { noteTypes, notesFilter, isNoShow } = params;

  const noteTypesQuerystring = noteTypes
    ? `&note_type=${noteTypes.join('&note_type=')}`
    : '';

  const notesFilterQuerystr = notesFilter
    ? getNotesFilterQuerystr(notesFilter)
    : '';

  const noShowQuerystring =
    isNoShow !== undefined ? `&is_no_show=${isNoShow}` : '';

  return usePaginatedQuery<Note>(
    `/${PATIENT_NOTES_QUERY_KEY_BASE.join(
      '/',
    )}/${patientId}?sort_by=updated_at&order_by=desc${noteTypesQuerystring}${notesFilterQuerystr}${noShowQuerystring}`,
    {
      enabled: !!patientId,
    },
  );
}

export function usePatientDraftNote(
  patientId: string,
  params: UseQueryOptions<Note | ''> = {},
) {
  return useQuery<Note | ''>(patientNotesQueryKey.draft(patientId), params);
}

export async function invalidatePatientNoteQueries(
  patientId: string,
  queryClient: QueryClient,
) {
  invalidateEncounterSuggestionsQuery(patientId, queryClient);
  invalidateLastPublishedNpEncounterNoteQuery(patientId, queryClient);

  return queryClient.invalidateQueries(
    [...PATIENT_NOTES_QUERY_KEY_BASE, patientId],
    {},
    { cancelRefetch: true },
  );
}

export type PatientNoteParams = {
  title: string;
  body: string;
  // TODO: Remove when switch over to V2 Rich Text Editor
  // https://cadencerpm.atlassian.net/browse/PLAT-4330
  rtfBody: TNoteBodyRTF;
  bodyHtml: Nullable<string>;
  author: string;
  labels: { id: number }[];
  actionRequired: boolean;
  urgent: boolean;
  shouldEMRSync: boolean;
  externalProviderId?: string;
  encounterModuleInstances: EncounterModuleInstance[];
  timeTracking: Partial<TimeTracking>;
  zendeskTicket?: Nullable<number>;
  appointmentId?: Nullable<string>;
  noShowAppointmentId?: Nullable<string>;
  endEncounter?: EndFormValues;
  timeElapsed?: Nullable<number>;
  status?: NoteStatus;
  shouldReplaceExistingDraft?: boolean;
};

type CreatePatientNoteParams = PatientNoteParams & {
  patientId: string;
};

export type ResolveAlertNoteParams = PatientNoteParams & {
  patientId: string;
};

export function useCreatePatientNote(timer: TimerState) {
  const { appointmentReminders } = useFlags();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const errorMessage = 'Failed to create a note';
  const onError = useMutationOnError(
    errorMessage,
    intl.formatMessage({
      defaultMessage: errorMessage,
    }),
  );
  return usePreventConcurrentMutations(
    async (note: CreatePatientNoteParams) =>
      Session.Api.post(
        `/${PATIENT_NOTES_QUERY_KEY_BASE.join('/')}/${note.patientId}`,
        {
          title: note.title,
          body: note.body,
          body_html: note.bodyHtml,
          rtf_body: note.rtfBody,
          author: note.author,
          labels: note.labels,
          action_required: note.actionRequired,
          urgent: note.urgent,
          should_emr_sync: note.shouldEMRSync,
          external_provider_id: note.externalProviderId,
          encounter_instances: note.encounterModuleInstances,
          time_tracking: note.timeTracking,
          appointment_id: note.appointmentId,
          no_show_appointment_id: note.noShowAppointmentId,
          ...zendeskPayloadParam(note.zendeskTicket),
          end_encounter_type: note.endEncounter?.endType,
          end_encounter_reason: note.endEncounter?.endReason,
          end_encounter_note: note.endEncounter?.endNote,
          ...timeElapsedPayloadParam(timer.timeElapsed, appointmentReminders),
        },
      ),
    {
      mutationKey: patientNotesQueryKey.publish(),
      onSuccess: async (data, variables) => {
        await invalidatePatientNoteQueries(variables.patientId, queryClient);
        await invalidatePatientMonitoringSessionQueries(
          variables.patientId,
          queryClient,
        );
      },
      onError,
    },
  );
}

type CreatePatientNoteDraftParams = Omit<
  CreatePatientNoteParams,
  'shouldEMRSync'
>;

export function useCreatePatientNoteDraft(timer: TimerState) {
  const { appointmentReminders } = useFlags();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const errorMessage = 'Failed to create a note draft';
  const onError = useMutationOnError(
    errorMessage,
    intl.formatMessage({
      defaultMessage: errorMessage,
    }),
  );
  const { toaster } = useToaster();
  return useMutation(
    patientNotesQueryKey.createDraft(),
    async (note: CreatePatientNoteDraftParams) =>
      Session.Api.post(
        `/${PATIENT_NOTES_QUERY_KEY_BASE.join('/')}/${note.patientId}/draft`,
        {
          title: note.title,
          body: note.body,
          body_html: note.bodyHtml,
          rtf_body: note.rtfBody,
          author: note.author,
          labels: note.labels,
          action_required: note.actionRequired,
          urgent: note.urgent,
          external_provider_id: note.externalProviderId,
          encounter_instances: note.encounterModuleInstances,
          should_purge_autosaved_note: true,
          time_tracking: note.timeTracking,
          appointment_id: note.appointmentId,
          no_show_appointment_id: note.noShowAppointmentId,
          ...zendeskPayloadParam(note.zendeskTicket),
          end_encounter_type: note.endEncounter?.endType,
          end_encounter_reason: note.endEncounter?.endReason,
          end_encounter_note: note.endEncounter?.endNote,
          ...timeElapsedPayloadParam(timer.timeElapsed, appointmentReminders),
        },
      ),
    {
      onSuccess: async (data, variables) => {
        await invalidatePatientNoteQueries(variables.patientId, queryClient);

        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Draft note created.',
          }),
        );
      },
      onError,
    },
  );
}

type UpdatePatientNoteDraftParams = CreatePatientNoteDraftParams;

// TODO: Add notes from DraftNoteSnackbar
export function useUpdatePatientNoteDraft(
  isEditingDraftNote: boolean,
  timer: TimerState,
) {
  const { appointmentReminders } = useFlags();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const errorMessage = 'Failed to update a draft';
  const onError = useMutationOnError(
    errorMessage,
    intl.formatMessage({
      defaultMessage: errorMessage,
    }),
  );
  const { toaster } = useToaster();
  return useMutation(
    async (note: UpdatePatientNoteDraftParams) =>
      Session.Api.put(
        `/${PATIENT_NOTES_QUERY_KEY_BASE.join('/')}/${note.patientId}/draft`,
        {
          title: note.title,
          body: note.body,
          body_html: note.bodyHtml,
          rtf_body: note.rtfBody,
          author: note.author,
          labels: note.labels,
          action_required: note.actionRequired,
          urgent: note.urgent,
          external_provider_id: note.externalProviderId,
          encounter_instances: note.encounterModuleInstances,
          should_purge_autosaved_note: !isEditingDraftNote, // updating shared draft note should not purge autosaved private note
          time_tracking: note.timeTracking,
          appointment_id: note.appointmentId,
          no_show_appointment_id: note.noShowAppointmentId,
          ...zendeskPayloadParam(note.zendeskTicket),
          end_encounter_type: note.endEncounter?.endType,
          end_encounter_reason: note.endEncounter?.endReason,
          end_encounter_note: note.endEncounter?.endNote,
          ...timeElapsedPayloadParam(timer.timeElapsed, appointmentReminders),
        },
      ),
    {
      onSuccess: async (data, variables) => {
        await invalidatePatientNoteQueries(variables.patientId, queryClient);

        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Draft note updated.',
          }),
        );
      },
      onError,
    },
  );
}

interface PublishDraftNoteParams {
  patientId: string;
  shouldEMRSync: boolean;
  appointmentId: Maybe<string>;
  noShowAppointmentId: Maybe<string>;
  zendeskTicket?: Nullable<number>;
}

export function usePublishDraftNote(
  isEditingDraftNote: boolean,
  timer: TimerState,
) {
  const { appointmentReminders } = useFlags();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const errorMessage = 'Failed to publish a draft';
  const onError = useMutationOnError(
    errorMessage,
    intl.formatMessage({
      defaultMessage: errorMessage,
    }),
  );
  const { toaster } = useToaster();
  return usePreventConcurrentMutations(
    async ({
      patientId,
      shouldEMRSync,
      zendeskTicket,
      appointmentId,
      noShowAppointmentId,
    }: PublishDraftNoteParams) =>
      Session.Api.post(
        `/${PATIENT_NOTES_QUERY_KEY_BASE.join('/')}/${patientId}/draft/publish`,
        {
          should_emr_sync: shouldEMRSync,
          should_purge_autosaved_note: !isEditingDraftNote, // publishing shared draft note should not purge autosaved private note
          appointment_id: appointmentId, // Appointment to associate encounter with
          no_show_appointment_id: noShowAppointmentId,
          ...timeElapsedPayloadParam(timer.timeElapsed, appointmentReminders),
          ...zendeskPayloadParam(zendeskTicket),
        },
      ),
    {
      onSuccess: async (data, variables) => {
        await invalidatePatientNoteQueries(variables.patientId, queryClient);
        await invalidatePatientMonitoringSessionQueries(
          variables.patientId,
          queryClient,
        );
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Draft note published.',
          }),
        );
      },
      onError,
    },
  );
}

interface RepublishNoteParams {
  patientId: string;
  noteId: string;
}

export function useRepublishNote() {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const errorMessage = 'Failed to republish note';
  const onError = useMutationOnError(
    errorMessage,
    intl.formatMessage({
      defaultMessage: errorMessage,
    }),
  );
  const { toaster } = useToaster();
  return useMutation(
    async ({ patientId, noteId }: RepublishNoteParams) =>
      Session.Api.post(
        `/${PATIENT_NOTES_QUERY_KEY_BASE.join(
          '/',
        )}/${patientId}/republish/${noteId}`,
      ),
    {
      onSuccess: async (data, variables) => {
        await invalidatePatientNoteQueries(variables.patientId, queryClient);
        await invalidatePatientMonitoringSessionQueries(
          variables.patientId,
          queryClient,
        );
        toaster.success(
          intl.formatMessage({
            defaultMessage: 'Note queued for republishing',
          }),
        );
      },
      onError,
    },
  );
}

function useMutationOnError(message: string, formattedMessage: string) {
  const { toaster } = useToaster();
  return (error: Error) => {
    if (error.message.includes('422')) {
      logger.error(`${message}: ${error.message}`);
    }
    toaster.error(formattedMessage);
  };
}

export function useDeleteDraftNoteByPatientId(
  patientId: string,
  successCallback: () => void = () => {},
  errorCallback: (error: unknown) => void = () => {},
) {
  const client = useQueryClient();
  const queryKeyBase = [...PATIENT_NOTES_QUERY_KEY_BASE, patientId];
  return useMutation(
    queryKeyBase,
    () => PatientNotesService.deletePmsApiV1NotesPatientDraft(patientId),
    {
      onSuccess: async () => {
        invalidateEncounterSuggestionsQuery(patientId, client);
        await client.invalidateQueries(queryKeyBase);
        successCallback();
      },
      onError: errorCallback,
    },
  );
}

export function timeElapsedPayloadParam(
  timeElapsed: Nullable<number>,
  flagEnabled: boolean,
) {
  if (flagEnabled || timeElapsed !== null) {
    return { time_elapsed: timeElapsed };
  }
  return {};
}

export function zendeskPayloadParam(zendeskTicket: Maybe<number>) {
  if (zendeskTicket !== null && zendeskTicket !== undefined) {
    return { zendesk_ticket_id: zendeskTicket };
  }
  return {};
}

export function noShowAppointmentIdPayloadParam(
  noShowAppointmentId: Maybe<string>,
) {
  if (noShowAppointmentId !== null && noShowAppointmentId !== undefined) {
    return { no_show_appointment_id: noShowAppointmentId };
  }
  return {};
}

export function appointmentIdPayloadParam(appointmentId: Maybe<string>) {
  if (appointmentId !== null && appointmentId !== undefined) {
    return { appointment_id: appointmentId };
  }
  return {};
}

export function useCreateNoteZendeskTicket(
  patientId: string,
  noteId: number,
  callbacks: Partial<QueryCallbacks<NoteSchema>> = {},
) {
  const client = useQueryClient();
  return useMutation(
    () =>
      PatientNotesService.postPmsApiV1PatientNotesZendeskTicket(
        patientId,
        noteId,
      ),
    {
      onSuccess: async (note) => {
        await client.invalidateQueries(PATIENT_NOTES_QUERY_KEY_BASE);
        await client.invalidateQueries(cnNoteKeys.autosaved(patientId));
        callbacks?.onSuccess?.(note);
      },
      onError: (error) => {
        callbacks?.onError?.(error);
      },
    },
  );
}

export function usePublishNote(
  patientId: string,
  callbacks: Partial<QueryCallbacks<NoteSchema>> = {},
) {
  const client = useQueryClient();

  return useMutation(
    (noteId: number) =>
      PatientNotesService.postPmsApiV1PatientNotesPublish(patientId, noteId),
    {
      mutationKey: patientNotesQueryKey.publish(),
      onSuccess: async (note) => {
        await client.invalidateQueries(PATIENT_NOTES_QUERY_KEY_BASE);
        await client.invalidateQueries(cnNoteKeys.autosaved(patientId));
        callbacks?.onSuccess?.(note);
      },
      onError: (error) => {
        callbacks?.onError?.(error);
      },
    },
  );
}

/**
 * When adding a new encounter module that has not yet been returned by the BE, this
 * util helps ensure we add it client-side.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function patchModule(
  resp: PaginatedData<EncounterModule>,
  moduleId: EncounterModuleId,
  module: EncounterModule,
) {
  return resp.data.find((m) => m.id === moduleId)
    ? resp
    : { ...resp, data: [...resp.data, module] };
}
