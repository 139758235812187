import { format, set } from 'date-fns';

import type {
  MeridianValue,
  TwelveHourTime,
  TwentyFourHourTime,
} from './types';
import { Meridian } from './types';

function isTimeFilled(time: string) {
  const [hours, mins] = time.split(':');
  return hours?.length > 0 && mins?.length === 2;
}

export function joinTime(hours: string, mins: string) {
  if (!hours && !mins) {
    return '';
  }
  return `${hours}:${mins}`;
}

function isValid(time: TwelveHourTime, meridian?: MeridianValue) {
  const [hours, minutes] = time.split(':');

  if (!meridian) {
    // Missing meridian
    return false;
  }
  if (!isTimeFilled(time)) {
    // Time is not fully filled out
    return false;
  }
  if (Number(hours) < 1 || Number(hours) > 12) {
    // Invalid hours
    return false;
  }
  if (Number(minutes) >= 60) {
    // Invalid minutes
    return false;
  }

  return true;
}

function isValid24Hour(time: TwentyFourHourTime) {
  const [hours, minutes] = time.split(':');

  if (!isTimeFilled(time)) {
    // Time is not fully filled out
    return false;
  }
  if (Number(hours) < 0 || Number(hours) > 23) {
    // Invalid hours
    return false;
  }
  if (Number(minutes) >= 60) {
    // Invalid minutes
    return false;
  }

  return true;
}

export function twelveToTwentyFourHourTime(
  time: TwelveHourTime,
  meridian?: MeridianValue,
): TwentyFourHourTime | null {
  if (isValid(time, meridian)) {
    const [hoursStr, minutesStr] = time.split(':');
    const hours = (Number(hoursStr) % 12) + (meridian === Meridian.PM ? 12 : 0);
    const minutes = Number(minutesStr);
    const timeDate = set(new Date(), { hours, minutes, seconds: 0 });
    return format(timeDate, 'HH:mm') as TwentyFourHourTime;
  }
  return null;
}

export function twentyFourHourToTwelveHourTime(
  time: TwentyFourHourTime,
): null | [TwelveHourTime, MeridianValue] {
  if (!isValid24Hour(time)) {
    return null;
  }

  const [hoursStr, minutesStr] = time.split(':');
  const meridian = Number(hoursStr) < 12 ? Meridian.AM : Meridian.PM;
  const minutes = Number(minutesStr);
  const hours = ((Number(hoursStr) + 11) % 12) + 1;

  const timeDate = set(new Date(), { hours, minutes, seconds: 0 });

  return [format(timeDate, 'hh:mm'), meridian];
}
