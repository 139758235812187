/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter long_type_string,add_pb_suffix,eslint_disable
// @generated from protobuf file "go/pms/pkg/task/task.proto" (package "go.pms.pkg.task", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { TaskService } from "./task_pb";
import type { TaskChangeNotificationResponse } from "./task_pb";
import type { TaskChangeNotificationRequest } from "./task_pb";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { ListCommentsResponse } from "./task_pb";
import type { ListCommentsRequest } from "./task_pb";
import type { UpdateCommentRequest } from "./task_pb";
import type { Comment } from "./task_pb";
import type { CreateCommentRequest } from "./task_pb";
import type { ListTasksResponse } from "./task_pb";
import type { ListTasksRequest } from "./task_pb";
import type { GetTaskRequest } from "./task_pb";
import type { Empty } from "./google/protobuf/empty_pb";
import type { DeleteTaskRequest } from "./task_pb";
import type { UpdateTaskRequest } from "./task_pb";
import type { CloseTaskRequest } from "./task_pb";
import type { OpenTaskRequest } from "./task_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Task } from "./task_pb";
import type { CreateTaskRequest } from "./task_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service go.pms.pkg.task.TaskService
 */
export interface ITaskServiceClient {
    /**
     * @generated from protobuf rpc: CreateTask(go.pms.pkg.task.CreateTaskRequest) returns (go.pms.pkg.task.Task);
     */
    createTask(input: CreateTaskRequest, options?: RpcOptions): UnaryCall<CreateTaskRequest, Task>;
    /**
     * @generated from protobuf rpc: OpenTask(go.pms.pkg.task.OpenTaskRequest) returns (go.pms.pkg.task.Task);
     */
    openTask(input: OpenTaskRequest, options?: RpcOptions): UnaryCall<OpenTaskRequest, Task>;
    /**
     * @generated from protobuf rpc: CloseTask(go.pms.pkg.task.CloseTaskRequest) returns (go.pms.pkg.task.Task);
     */
    closeTask(input: CloseTaskRequest, options?: RpcOptions): UnaryCall<CloseTaskRequest, Task>;
    /**
     * @generated from protobuf rpc: UpdateTask(go.pms.pkg.task.UpdateTaskRequest) returns (go.pms.pkg.task.Task);
     */
    updateTask(input: UpdateTaskRequest, options?: RpcOptions): UnaryCall<UpdateTaskRequest, Task>;
    /**
     * @generated from protobuf rpc: DeleteTask(go.pms.pkg.task.DeleteTaskRequest) returns (google.protobuf.Empty);
     */
    deleteTask(input: DeleteTaskRequest, options?: RpcOptions): UnaryCall<DeleteTaskRequest, Empty>;
    /**
     * @generated from protobuf rpc: GetTask(go.pms.pkg.task.GetTaskRequest) returns (go.pms.pkg.task.Task);
     */
    getTask(input: GetTaskRequest, options?: RpcOptions): UnaryCall<GetTaskRequest, Task>;
    /**
     * @generated from protobuf rpc: ListTasks(go.pms.pkg.task.ListTasksRequest) returns (go.pms.pkg.task.ListTasksResponse);
     */
    listTasks(input: ListTasksRequest, options?: RpcOptions): UnaryCall<ListTasksRequest, ListTasksResponse>;
    /**
     * @generated from protobuf rpc: CreateComment(go.pms.pkg.task.CreateCommentRequest) returns (go.pms.pkg.task.Comment);
     */
    createComment(input: CreateCommentRequest, options?: RpcOptions): UnaryCall<CreateCommentRequest, Comment>;
    /**
     * @generated from protobuf rpc: UpdateComment(go.pms.pkg.task.UpdateCommentRequest) returns (go.pms.pkg.task.Comment);
     */
    updateComment(input: UpdateCommentRequest, options?: RpcOptions): UnaryCall<UpdateCommentRequest, Comment>;
    /**
     * @generated from protobuf rpc: ListComments(go.pms.pkg.task.ListCommentsRequest) returns (go.pms.pkg.task.ListCommentsResponse);
     */
    listComments(input: ListCommentsRequest, options?: RpcOptions): UnaryCall<ListCommentsRequest, ListCommentsResponse>;
    /**
     * @generated from protobuf rpc: ChangeNotifications(go.pms.pkg.task.TaskChangeNotificationRequest) returns (stream go.pms.pkg.task.TaskChangeNotificationResponse);
     */
    changeNotifications(input: TaskChangeNotificationRequest, options?: RpcOptions): ServerStreamingCall<TaskChangeNotificationRequest, TaskChangeNotificationResponse>;
}
/**
 * @generated from protobuf service go.pms.pkg.task.TaskService
 */
export class TaskServiceClient implements ITaskServiceClient, ServiceInfo {
    typeName = TaskService.typeName;
    methods = TaskService.methods;
    options = TaskService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateTask(go.pms.pkg.task.CreateTaskRequest) returns (go.pms.pkg.task.Task);
     */
    createTask(input: CreateTaskRequest, options?: RpcOptions): UnaryCall<CreateTaskRequest, Task> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateTaskRequest, Task>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: OpenTask(go.pms.pkg.task.OpenTaskRequest) returns (go.pms.pkg.task.Task);
     */
    openTask(input: OpenTaskRequest, options?: RpcOptions): UnaryCall<OpenTaskRequest, Task> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<OpenTaskRequest, Task>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CloseTask(go.pms.pkg.task.CloseTaskRequest) returns (go.pms.pkg.task.Task);
     */
    closeTask(input: CloseTaskRequest, options?: RpcOptions): UnaryCall<CloseTaskRequest, Task> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CloseTaskRequest, Task>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTask(go.pms.pkg.task.UpdateTaskRequest) returns (go.pms.pkg.task.Task);
     */
    updateTask(input: UpdateTaskRequest, options?: RpcOptions): UnaryCall<UpdateTaskRequest, Task> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateTaskRequest, Task>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTask(go.pms.pkg.task.DeleteTaskRequest) returns (google.protobuf.Empty);
     */
    deleteTask(input: DeleteTaskRequest, options?: RpcOptions): UnaryCall<DeleteTaskRequest, Empty> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteTaskRequest, Empty>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTask(go.pms.pkg.task.GetTaskRequest) returns (go.pms.pkg.task.Task);
     */
    getTask(input: GetTaskRequest, options?: RpcOptions): UnaryCall<GetTaskRequest, Task> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTaskRequest, Task>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListTasks(go.pms.pkg.task.ListTasksRequest) returns (go.pms.pkg.task.ListTasksResponse);
     */
    listTasks(input: ListTasksRequest, options?: RpcOptions): UnaryCall<ListTasksRequest, ListTasksResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListTasksRequest, ListTasksResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateComment(go.pms.pkg.task.CreateCommentRequest) returns (go.pms.pkg.task.Comment);
     */
    createComment(input: CreateCommentRequest, options?: RpcOptions): UnaryCall<CreateCommentRequest, Comment> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateCommentRequest, Comment>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateComment(go.pms.pkg.task.UpdateCommentRequest) returns (go.pms.pkg.task.Comment);
     */
    updateComment(input: UpdateCommentRequest, options?: RpcOptions): UnaryCall<UpdateCommentRequest, Comment> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCommentRequest, Comment>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListComments(go.pms.pkg.task.ListCommentsRequest) returns (go.pms.pkg.task.ListCommentsResponse);
     */
    listComments(input: ListCommentsRequest, options?: RpcOptions): UnaryCall<ListCommentsRequest, ListCommentsResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCommentsRequest, ListCommentsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ChangeNotifications(go.pms.pkg.task.TaskChangeNotificationRequest) returns (stream go.pms.pkg.task.TaskChangeNotificationResponse);
     */
    changeNotifications(input: TaskChangeNotificationRequest, options?: RpcOptions): ServerStreamingCall<TaskChangeNotificationRequest, TaskChangeNotificationResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<TaskChangeNotificationRequest, TaskChangeNotificationResponse>("serverStreaming", this._transport, method, opt, input);
    }
}
