import cx from 'classnames';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import { DialogContent } from '@/deprecated/mui';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';

import { useModalContext } from '../ModalContext';
import {
  body,
  bodyLoadingSkeleton,
  loadingContainer,
  loadingContents,
} from './Body.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export function Body({ children, className }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { isLoading, setIsBodyScrollVisible } = useModalContext();

  useEffect(() => {
    let resizeObserver: Nullable<ResizeObserver> = null;
    if (ref.current) {
      resizeObserver = new ResizeObserver(() => {
        setIsBodyScrollVisible(getIsScrollVisible(ref.current));
      });
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [setIsBodyScrollVisible]);

  return (
    <DialogContent
      className={cx(body, className)}
      ref={ref}
      onScroll={() => setIsBodyScrollVisible(getIsScrollVisible(ref.current))}
    >
      <BodyLoadingPlaceholder isLoading={isLoading}>
        {children}
      </BodyLoadingPlaceholder>
    </DialogContent>
  );
}

function BodyLoadingPlaceholder({
  isLoading,
  children,
}: {
  children: ReactNode;
  isLoading: boolean;
}) {
  return (
    <div
      className={cx({
        [loadingContainer.default]: !isLoading,
        [loadingContainer.loading]: isLoading,
      })}
    >
      {isLoading && (
        <Skeleton variant="rectangular" className={bodyLoadingSkeleton} />
      )}
      <div
        className={cx({
          [loadingContents.default]: !isLoading,
          [loadingContents.loading]: isLoading,
        })}
      >
        {children}
      </div>
    </div>
  );
}

function getIsScrollVisible(div: Nullable<HTMLDivElement>) {
  if (div) {
    return div.scrollHeight > div.clientHeight;
  }
  return false;
}
