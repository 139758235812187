import { INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedScheduling } from '../../shared/Scheduling';
import { initVisitPath } from '../paths';

export function Scheduling() {
  return (
    <SharedScheduling
      sections={INIT_VISIT_SECTIONS}
      nextPath={initVisitPath('/time-tracking', '/index')}
    />
  );
}
