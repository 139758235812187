import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import { PRIMARY_TO_SECONDARY_REASON_MAP } from '@/pages/adminPanel/patient-enrollment-tracker/ui/shared/modals/EditModals/StatusChangeModal/StatusChangeForm/disenrollmentReasons';
import { ActionIconTagType } from '@/shared/common/ActionIconTag';
import { PatientStatusEnum } from '@/shared/generated/grpcGateway/pms.pb';
import { makeI18nHook } from '@/shared/hooks';
import {
  PatientStatus,
  StatusChangeReason,
} from '@/shared/types/patient.types';

export const useStatusChangeReasonMap = makeI18nHook(getStatusChangeReasonMap);

type PatientStatusMap = Optional<
  { [key in PatientStatus]: string },
  PatientStatus.Identified
>;

export const GRPC_PATIENT_STATUS_TO_LOCAL_ENUM_MAP = {
  [PatientStatusEnum.ENUM_UNSPECIFIED]: PatientStatus.Identified,
  [PatientStatusEnum.IDENTIFIED]: PatientStatus.Identified,
  [PatientStatusEnum.ELIGIBLE]: PatientStatus.Eligible,
  [PatientStatusEnum.NOT_ELIGIBLE]: PatientStatus.NotEligible,
  [PatientStatusEnum.PENDING]: PatientStatus.Identified,
  [PatientStatusEnum.PENDING_ORDER]: PatientStatus.Eligible,
  [PatientStatusEnum.PENDING_ENROLLMENT]: PatientStatus.Eligible,
  [PatientStatusEnum.NEEDS_REVIEW]: PatientStatus.NeedsReview,
  [PatientStatusEnum.ENROLLED]: PatientStatus.Enrolled,
  [PatientStatusEnum.NOT_ENROLLED]: PatientStatus.Disenrolled,
  [PatientStatusEnum.DISENROLLED]: PatientStatus.Disenrolled,
  [PatientStatusEnum.PROVIDER_DECLINED]: PatientStatus.ProviderDeclined,
  [PatientStatusEnum.PATIENT_DECLINED]: PatientStatus.PatientDeclined,
  [PatientStatusEnum.WAITING_FOR_PROVIDER]: PatientStatus.WaitingForProvider,
  [PatientStatusEnum.READY_TO_ENROLL]: PatientStatus.ReadyToEnroll,
  [PatientStatusEnum.APPROVED]: PatientStatus.Approved,
  [PatientStatusEnum.ORDER_PENDED]: PatientStatus.OrderPended,
  [PatientStatusEnum.DUPLICATE]: PatientStatus.Duplicate,
  [PatientStatusEnum.DELETED]: PatientStatus.Deleted,
};

/**
 * @param currentStatus Used for backward compatibility for deprecated statuses,
 * e.g. in dropdowns to show a deprecated status when it's already selected.
 */
export function usePatientStatusMap(
  currentStatus?: PatientStatus,
): PatientStatusMap {
  const intl = useIntl();

  function conditionalStatus(
    { status, label }: { status: PatientStatus; label: string },
    show: boolean = false,
  ) {
    if (currentStatus === status || show) {
      return { [status]: label };
    }
    return {};
  }

  return {
    ...conditionalStatus({
      status: PatientStatus.Identified,
      label: intl.formatMessage({
        defaultMessage: 'Identified',
      }),
    }),
    [PatientStatus.Eligible]: intl.formatMessage({
      defaultMessage: 'Eligible',
    }),
    [PatientStatus.NotEligible]: intl.formatMessage({
      defaultMessage: 'Not Eligible',
    }),
    [PatientStatus.NeedsReview]: intl.formatMessage({
      defaultMessage: 'Needs Review',
    }),
    [PatientStatus.WaitingForProvider]: intl.formatMessage({
      defaultMessage: 'Waiting for Provider',
    }),
    [PatientStatus.Approved]: intl.formatMessage({
      defaultMessage: 'Provider Approved',
    }),
    [PatientStatus.ProviderDeclined]: intl.formatMessage({
      defaultMessage: 'Provider Declined',
    }),
    [PatientStatus.OrderPended]: intl.formatMessage({
      defaultMessage: 'Order Pended',
    }),
    [PatientStatus.ReadyToEnroll]: intl.formatMessage({
      defaultMessage: 'Ready to Enroll',
    }),
    [PatientStatus.PatientDeclined]: intl.formatMessage({
      defaultMessage: 'Patient Declined',
    }),
    [PatientStatus.Enrolled]: intl.formatMessage({
      defaultMessage: 'Enrolled',
    }),
    [PatientStatus.Disenrolled]: intl.formatMessage({
      defaultMessage: 'Disenrolled',
    }),
    [PatientStatus.Duplicate]: intl.formatMessage({
      defaultMessage: 'Duplicate',
    }),
    [PatientStatus.Deleted]: intl.formatMessage({
      defaultMessage: 'Deleted',
    }),
  };
}

type StatusChangeReasonMap = {
  [key in StatusChangeReason]: string;
};

function getStatusChangeReasonMap(intl: IntlShape): StatusChangeReasonMap {
  return {
    [StatusChangeReason.PatientIsStable]: intl.formatMessage({
      defaultMessage: 'Patient is stable',
    }),
    [StatusChangeReason.NoMentionOfCadence]: intl.formatMessage({
      defaultMessage: 'No mention of Cadence',
    }),
    [StatusChangeReason.Rescheduled]: intl.formatMessage({
      defaultMessage: 'Rescheduled Cadence appointment',
    }),
    [StatusChangeReason.PatientStillConsidering]: intl.formatMessage({
      defaultMessage: 'Patient still considering',
    }),
    [StatusChangeReason.ReEnrolled]: intl.formatMessage({
      defaultMessage: 'Re-enrolled',
    }),
    [StatusChangeReason.Insurance]: intl.formatMessage({
      defaultMessage: 'Insurance',
    }),
    [StatusChangeReason.Clinical]: intl.formatMessage({
      defaultMessage: 'Clinical',
    }),
    [StatusChangeReason.Other]: intl.formatMessage({
      defaultMessage: 'Other',
    }),
    [StatusChangeReason.ProviderDeclined]: intl.formatMessage({
      defaultMessage: 'Provider declined',
    }),
    [StatusChangeReason.PatientDeclined]: intl.formatMessage({
      defaultMessage: 'Patient declined',
    }),
    [StatusChangeReason.FinancialConcerns]: intl.formatMessage({
      defaultMessage: 'Financial Concerns',
    }),
    [StatusChangeReason.EnrolledInAlternateProgram]: intl.formatMessage({
      defaultMessage: 'Patient is enrolled in an alternate RPM program',
    }),
    [StatusChangeReason.Deceased]: intl.formatMessage({
      defaultMessage: 'Patient is deceased',
    }),
    [StatusChangeReason.DoctorRecommendedDisenrollment]: intl.formatMessage({
      defaultMessage: 'Doctor recommended disenrollment (and reason)',
    }),
    [StatusChangeReason.LackOfConnectivity]: intl.formatMessage({
      defaultMessage: 'Lack of connectivity in patient home',
    }),
    [StatusChangeReason.LackOfParticipation]: intl.formatMessage({
      defaultMessage: 'Lack of participation (and reason)',
    }),
    [StatusChangeReason.LeavingThePractice]: intl.formatMessage({
      defaultMessage: 'Leaving the Practice',
    }),
    [StatusChangeReason.MovedToHospicePalliativeCare]: intl.formatMessage({
      defaultMessage: 'Moved to Hospice/Palliative Care',
    }),
    [StatusChangeReason.PrivacyConcerns]: intl.formatMessage({
      defaultMessage: 'Patient is concerned about privacy',
    }),
    [StatusChangeReason.UnableToSafelyUseEquipment]: intl.formatMessage({
      defaultMessage: 'Patient is unable to use devices',
    }),
    [StatusChangeReason.AtDischarge]: intl.formatMessage({
      defaultMessage: 'At Discharge',
    }),
    [StatusChangeReason.InClinic]: intl.formatMessage({
      defaultMessage: 'In Clinic',
    }),
    [StatusChangeReason.ClinicNoShow]: intl.formatMessage({
      defaultMessage: 'Clinic no-show',
    }),
    [StatusChangeReason.CadenceAppointmentNoShow]: intl.formatMessage({
      defaultMessage: 'Cadence appointment no-show',
    }),
    [StatusChangeReason.NotInterested]: intl.formatMessage({
      defaultMessage: 'Not interested',
    }),
    [StatusChangeReason.NoCellPhone]: intl.formatMessage({
      defaultMessage: 'No cell phone',
    }),
    [StatusChangeReason.FinancialReasons]: intl.formatMessage({
      defaultMessage: 'Patient no longer wants/can afford financial obligation',
    }),
    [StatusChangeReason.Hypertension]: intl.formatMessage({
      defaultMessage: 'Hypertension',
    }),
    [StatusChangeReason.CongestiveHeartFailure]: intl.formatMessage({
      defaultMessage: 'Congestive Heart Failure',
    }),
    [StatusChangeReason.Type2Diabetes]: intl.formatMessage({
      defaultMessage: 'Type 2 Diabetes',
    }),
    [StatusChangeReason.Type2DiabetesWithHypertension]: intl.formatMessage({
      defaultMessage: 'Type 2 Diabetes + Hypertension',
    }),
    [StatusChangeReason.Type2DiabetesWithCongestiveHeartFailure]:
      intl.formatMessage({
        defaultMessage: 'Type 2 Diabetes + Congestive Heart Failure',
      }),
    [StatusChangeReason.UnhappyWithDevices]: intl.formatMessage({
      defaultMessage: 'Unhappy with devices',
    }),
    [StatusChangeReason.AcuteVisit]: intl.formatMessage({
      defaultMessage: 'Acute visit',
    }),
    [StatusChangeReason.CausedStress]: intl.formatMessage({
      defaultMessage: 'Patient feels the program is causing them stress',
    }),
    [StatusChangeReason.NabOrdersCandidate]: intl.formatMessage({
      defaultMessage: 'List Candidate',
    }),
    [StatusChangeReason.NabOrdered]: intl.formatMessage({
      defaultMessage: 'EPL Ordered',
    }),
    [StatusChangeReason.Virtual]: intl.formatMessage({
      defaultMessage: 'Virtual',
    }),
    [StatusChangeReason.PendingOrderFailed]: intl.formatMessage({
      defaultMessage: 'Pending order failed',
    }),
    [StatusChangeReason.AppointmentBasedOrder]: intl.formatMessage({
      defaultMessage: 'Appointment Based Order',
    }),
    [StatusChangeReason.AbandonedEnrollment]: intl.formatMessage({
      defaultMessage: 'Abandoned enrollment',
    }),
    [StatusChangeReason.EngagementFatigue]: intl.formatMessage({
      defaultMessage: 'Engagement fatigue',
    }),
    [StatusChangeReason.PreferSelfManage]: intl.formatMessage({
      defaultMessage: 'Prefer self manage',
    }),
    [StatusChangeReason.PreferPhysicianManage]: intl.formatMessage({
      defaultMessage: 'Patient prefers care to be managed by physician',
    }),
    [StatusChangeReason.PatientIsASnowbird]: intl.formatMessage({
      defaultMessage: 'Patient is a snowbird',
    }),
    [StatusChangeReason.TooBusy]: intl.formatMessage({
      defaultMessage: 'Too busy',
    }),
    [StatusChangeReason.NoLongerAPatientOfTheProvider]: intl.formatMessage({
      defaultMessage: 'Patient has changed providers',
    }),
    [StatusChangeReason.Inactive]: intl.formatMessage({
      defaultMessage: 'Inactive',
    }),
    [StatusChangeReason.ConcernsAboutDeviceAccuracy]: intl.formatMessage({
      defaultMessage: 'Patient has concerns about device accuracy ',
    }),
    [StatusChangeReason.PrefersUsingPersonalDevices]: intl.formatMessage({
      defaultMessage: 'Patient prefers using personal devices',
    }),
    [StatusChangeReason.DoesntWantToTakeVitals]: intl.formatMessage({
      defaultMessage: `Patient doesn't want to take vitals`,
    }),
    [StatusChangeReason.DoesntWantToHaveAppointments]: intl.formatMessage({
      defaultMessage: `Patient doesn't want to have appointments`,
    }),
    [StatusChangeReason.DoesntWantToReceiveAlertCalls]: intl.formatMessage({
      defaultMessage: `Patient doesn't want to receive alert phone calls`,
    }),
    [StatusChangeReason.ReceivedAnUnexpectedBill]: intl.formatMessage({
      defaultMessage: 'Patient received an unexpected bill',
    }),
    [StatusChangeReason.BelievesHealthGoalMet]: intl.formatMessage({
      defaultMessage: 'Patient believes they have met their health goals',
    }),
    [StatusChangeReason.HasCGM]: intl.formatMessage({
      defaultMessage: 'Patient has a CGM',
    }),
    [StatusChangeReason.ClinicalExclusion]: intl.formatMessage({
      defaultMessage: 'Patient has a clinical exclusion',
    }),
    [StatusChangeReason.LivesInFacilityThatManagesTheirMedication]:
      intl.formatMessage({
        defaultMessage:
          'Patient lives in a facility that manages their medication',
      }),
    [StatusChangeReason.PrimaryResidenceOutsideCadenceFootprint]:
      intl.formatMessage({
        defaultMessage: `Patient's primary residence is outside Cadence's footprint`,
      }),
    [StatusChangeReason.ProviderLeftPractice]: intl.formatMessage({
      defaultMessage: 'Provider has left practice',
    }),
    [StatusChangeReason.ProviderRequestsPatientGraduate]: intl.formatMessage({
      defaultMessage: 'Provider requests patient graduate',
    }),
    [StatusChangeReason.ProviderToldPatientProgramNotNeeded]:
      intl.formatMessage({
        defaultMessage:
          'Provider has told patient they do not need to be in the program',
      }),
  };
}

export function useReasonGroups(status: PatientStatus) {
  const reasonGroups = REASON_GROUPS[status];

  if (!reasonGroups) {
    logger.error(`Patient has unexpected status: ${status}`);
    return [];
  }

  return reasonGroups;
}

export const REASON_GROUPS = {
  [PatientStatus.Disenrolled]: [
    ...Object.values(PRIMARY_TO_SECONDARY_REASON_MAP).flat(),
  ],
  [PatientStatus.Identified]: [],
  [PatientStatus.Eligible]: [
    StatusChangeReason.CongestiveHeartFailure,
    StatusChangeReason.Hypertension,
    StatusChangeReason.Type2Diabetes,
    StatusChangeReason.Type2DiabetesWithHypertension,
    StatusChangeReason.Type2DiabetesWithCongestiveHeartFailure,
  ],
  [PatientStatus.NotEligible]: [
    StatusChangeReason.Insurance,
    StatusChangeReason.Inactive,
    StatusChangeReason.Clinical,
    StatusChangeReason.Deceased,
    StatusChangeReason.Other,
  ],
  [PatientStatus.Enrolled]: [
    StatusChangeReason.AtDischarge,
    StatusChangeReason.InClinic,
    StatusChangeReason.ReEnrolled,
    StatusChangeReason.Virtual,
  ],
  [PatientStatus.ProviderDeclined]: [
    StatusChangeReason.NoCellPhone,
    StatusChangeReason.FinancialReasons,
    StatusChangeReason.PatientIsStable,
    StatusChangeReason.PatientIsASnowbird,
    StatusChangeReason.NotInterested,
    StatusChangeReason.Other,
  ],
  [PatientStatus.PatientDeclined]: [
    StatusChangeReason.NoCellPhone,
    StatusChangeReason.FinancialReasons,
    StatusChangeReason.PatientIsASnowbird,
    StatusChangeReason.PatientIsStable,
    StatusChangeReason.TooBusy,
    StatusChangeReason.NoLongerAPatientOfTheProvider,
    StatusChangeReason.NotInterested,
    StatusChangeReason.Other,
  ],
  [PatientStatus.NeedsReview]: [],
  [PatientStatus.Duplicate]: [],
  [PatientStatus.Deleted]: [],
  [PatientStatus.Approved]: [],
  [PatientStatus.OrderPended]: [],
  [PatientStatus.WaitingForProvider]: [
    StatusChangeReason.NabOrdersCandidate,
    StatusChangeReason.AcuteVisit,
    StatusChangeReason.ClinicNoShow,
    StatusChangeReason.NoMentionOfCadence,
    StatusChangeReason.PatientStillConsidering,
    StatusChangeReason.Other,
  ],
  [PatientStatus.ReadyToEnroll]: [
    StatusChangeReason.CadenceAppointmentNoShow,
    StatusChangeReason.Rescheduled,
    StatusChangeReason.PatientStillConsidering,
    StatusChangeReason.NabOrdered,
    StatusChangeReason.AppointmentBasedOrder,
  ],
};

export function useStatusText(status: PatientStatus, isActivated?: boolean) {
  const statusMap = usePatientStatusMap(status);
  const intl = useIntl();

  if (isActivated) {
    return {
      statusLabel: intl.formatMessage({ defaultMessage: 'Activated' }),
      tooltip: intl.formatMessage({
        defaultMessage: 'Patient has sent at least 1 reading',
      }),
    };
  }

  return {
    statusLabel: statusMap[status],
    tooltip:
      status === PatientStatus.Enrolled
        ? intl.formatMessage({
            defaultMessage:
              'Patient has given consent but has not sent first reading',
          })
        : null,
  };
}

export function getTagType(status: PatientStatus, isActivated?: boolean) {
  if (isActivated) {
    return ActionIconTagType.NEUTRAL;
  }

  switch (status) {
    case PatientStatus.ReadyToEnroll:
      return ActionIconTagType.WARNING;
    case PatientStatus.Enrolled:
      return ActionIconTagType.PRIMARY;
    case PatientStatus.Disenrolled:
    case PatientStatus.PatientDeclined:
    case PatientStatus.NotEligible:
    case PatientStatus.Deleted:
      return ActionIconTagType.ERROR;
    default:
      return ActionIconTagType.UNSET;
  }
}
