import { CHF_VISIT_SECTIONS } from '../../metadata';
import { SharedIntroConfirmData } from '../../shared/IntroConfirmData';
import { chfVisitPath } from '../paths';

export function IntroConfirmData() {
  return (
    <SharedIntroConfirmData
      sections={CHF_VISIT_SECTIONS}
      nextPath={chfVisitPath('/symptoms', '/index')}
    />
  );
}
