import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Button } from '@/shared/tempo/atom/Button';
import { Radio } from '@/shared/tempo/atom/Radio';
import { RadioGroup } from '@/shared/tempo/molecule/RadioGroup';

import type { MedicationFormProps } from '../MedicationForm';
import { StopMedicationForm } from '../StopMedicationForm';
import { formHeading } from '../styles.css';
import { TitrateMedicationForm } from './TitrateMedicationForm';
import { FormType } from './manageMedicationForms.type';
import { manageOptions, radioOption, selectedOption } from './styles.css';

type Props = MedicationFormProps & {
  onSuccess: () => void;
  initialFormType?: FormType;
};

export function ManageMedicationForms({
  initValues,
  onClose,
  onSuccess,
  initialFormType,
  ...rest
}: Props) {
  const intl = useIntl();
  const [formType, setFormType] = useState<FormType | null>(
    initialFormType ?? null,
  );
  function handleChangeFormType(type: string) {
    setFormType(type as FormType);
  }
  const label = intl.formatMessage({
    defaultMessage: 'Titrate or stop medication',
  });

  return (
    <div>
      {!initValues?.isAsyncTitration && (
        <>
          <div className={formHeading}>{label}</div>
          <RadioGroup
            onChange={handleChangeFormType}
            classes={{ root: manageOptions }}
            orientation="horizontal"
            aria-label={label}
            defaultValue={formType ?? undefined}
          >
            <Radio
              value={FormType.Stop}
              classes={{
                label: cx(radioOption, {
                  [selectedOption]: formType === FormType.Stop,
                }),
              }}
            >
              <FormattedMessage defaultMessage="Stop" />
            </Radio>
            <Radio
              value={FormType.Titrate}
              classes={{
                label: cx(radioOption, {
                  [selectedOption]: formType === FormType.Titrate,
                }),
              }}
            >
              <FormattedMessage defaultMessage="Titrate" />
            </Radio>
          </RadioGroup>
        </>
      )}
      {formType === FormType.Stop && (
        <StopMedicationForm
          initValues={initValues}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
      {formType === FormType.Titrate && (
        <TitrateMedicationForm
          initValues={initValues}
          onClose={onClose}
          onSuccess={onSuccess}
          {...rest}
        />
      )}
      {!formType && (
        <Form.Actions direction="row-reverse">
          <Button variant="primary" size="small" isDisabled>
            <FormattedMessage defaultMessage="Update" />
          </Button>
          <Button variant="secondary" size="small" onPress={onClose}>
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
        </Form.Actions>
      )}
    </div>
  );
}
