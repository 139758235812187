/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ToolboxService {
    /**
     * Publish orders for a patient
     * @param patientId patient_id
     * @returns void
     * @throws ApiError
     */
    public static postPmsApiV1ToolboxPatientOrders(
        patientId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/toolbox/patient/{patient_id}/orders',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `bad request`,
                403: `Forbidden`,
                422: `Unprocessable entity`,
            },
        });
    }
}
