import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PatientDetailsProvider } from '@/pages/patients/PatientProfile/PatientDetailContext';
import { Header, Page } from '@/shared/common/Page';
import { noScrollContainer } from '@/shared/common/Page/Page.css';
import type { PatientDetails } from '@/shared/types/patient.types';

import { PatientListSearchBar } from '../PatientListSearchBar';
import { PatientListSidebarPanel } from '../PatientListSidebarPanel';
import { useFiltersQuery } from '../hooks/useFiltersQuery.hook';
import { FiltersSection } from './FiltersSection';
import { actionStrip, page } from './PatientAlertsDashboardPage.css';
import { PatientAlertsTabs } from './PatientAlertsTabs';

export function PatientAlertsDashboardPage() {
  const [patientDetails, setPatientDetails] = useState<PatientDetails>(
    {} as PatientDetails,
  );
  const { searchFilter, clearSearchFilter } = useFiltersQuery();
  const intl = useIntl();

  return (
    <PatientDetailsProvider value={{ patientDetails, setPatientDetails }}>
      <Page
        classes={{ page, container: noScrollContainer }}
        documentTitle={intl.formatMessage({
          defaultMessage: 'Vitals Board',
        })}
        data-testid="patient-list-dashboard"
        sidePanel={<PatientListSidebarPanel />}
      >
        <Page.Header>
          <Header.ActionStrip
            className={actionStrip}
            right={
              <PatientListSearchBar
                autoFocus={false}
                onClose={() => {
                  // Go back to previous filters if search was performed
                  if (searchFilter) {
                    clearSearchFilter();
                  }
                }}
              />
            }
          />
          <Header.Content
            title={<FormattedMessage defaultMessage="Vitals Board" />}
          />
        </Page.Header>
        <Page.Body>
          <FiltersSection isOpen={!searchFilter} />
          <PatientAlertsTabs />
        </Page.Body>
      </Page>
    </PatientDetailsProvider>
  );
}
