import { settings } from '@/config';
import { getFalconVersion } from '@/monitoring.utils';
import { parsePatientIdFromPath } from '@/shared/common/Sidebar/SidebarMenu/SupportModal/issueReporter.util';

import { Logger } from './Logger';
import { ConsoleEmitter, SentryEmitter } from './emitters';
import { DatadogEmitter } from './emitters/DatadogEmitter';
import type { LogLevelsType } from './logger.types';
import { LogLevels } from './logger.types';

export const logger = new Logger(
  (settings.VITE_LOG_LEVEL || LogLevels.Warn) as LogLevelsType,
  [new ConsoleEmitter(), new SentryEmitter(), new DatadogEmitter()],
  () => {
    const patientIdFromUrl = parsePatientIdFromPath(window.location.pathname);
    return {
      environment: settings.VITE_ENVIRONMENT,
      release: getFalconVersion(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      userAgent: navigator.userAgent,
      ...(patientIdFromUrl && { patientIdFromUrl }),
    };
  },
);
