import type { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import type { SubmitButtonOverrideProps } from '@/shared/common/Wizard/Step';
import { Button } from '@/shared/tempo/atom/Button';

type Props<TFieldValues extends FieldValues> = {
  submitPath: string;
} & SubmitButtonOverrideProps<TFieldValues>;

export function TimeTrackingButton<TFieldValues extends FieldValues>({
  form,
  submitWithTiming,
  submitPath,
}: Props<TFieldValues>) {
  const history = useHistory();
  return (
    <Button
      size="small"
      type="submit"
      onPress={() => {
        submitWithTiming(true);
        history.push(submitPath);
      }}
      isDisabled={!!form && !form.formState.isValid}
    >
      <FormattedMessage defaultMessage="Track Time" />
    </Button>
  );
}
