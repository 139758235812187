import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { logger } from '@/logger';
import { useHasRequiredMedActions } from '@/pages/patients/PatientMedications/utils/requiredActions';
import { useUpsertPatientAutosavedNote } from '@/shared/hooks/queries/autosave-notes.queries';
import type { RouteParam } from '@/shared/types/route.types';

import { useNoteEditorContext } from '../NoteEditorContext';
import { NotePreview } from '../NotePreview';
import { EditableNoteType } from '../Notes.types';
import { RecentNotesHeader } from '../RecentNotes';
import {
  editorContainer,
  gridContainer,
  panelItem,
  previewContainer,
  twoColumnGrid,
  twoColumnPanel,
} from './NoteEditor.css';
import './NoteEditor.scss';
import { NoteEditorErrorBoundary } from './NoteEditorErrorBoundary';
import { NoteEditorForm } from './NoteEditorForm';
import { NoteEditorHeaderAndDialogs } from './NoteEditorHeaderAndDialogs';
import { useCloseAndClearNoteEditor } from './hooks/noteEditorVisibility.hooks';
import {
  useAutosaveNoteOnChanges,
  usePatientNoteParams,
} from './hooks/useAutosaveNote.hook';
import { NoteEditorLayout } from './noteEditor.types';
import { useNoteFormState } from './noteFormState';
import { useSyncScrollPosition } from './useSyncScrollPosition';
import { requireMedActionsInEncounter } from './utils/medReviewUtils';
import {
  ValidationErrors,
  useNoteEditorValidation,
  useNoteFormConfig,
} from './validation';

export function NoteEditor({
  draftNote,
  layout = NoteEditorLayout.TwoColumn,
}: {
  draftNote?: boolean;
  layout?: NoteEditorLayout;
}) {
  const [failedNoteRecovery, setFailedNoteRecovery] = useState(false);
  const {
    formConfig,
    enableValidation,
    disableValidation,
    noteFormSubmissionType,
  } = useNoteFormConfig();
  const {
    noteFormValues,
    setNoteFormValue,
    noteFormExtras,
    resetNoteFormState,
  } = useNoteFormState(formConfig);
  const { editingNote, setEditingNote, isSubmitting } = useNoteEditorContext();
  const { patientId }: RouteParam = useParams();

  const hasRequiredMedActions = useHasRequiredMedActions(patientId, true);
  const showRequiredActionsError =
    hasRequiredMedActions &&
    requireMedActionsInEncounter(
      noteFormSubmissionType,
      noteFormValues.encounterModuleInstances,
    );
  const { noteFormValidationResult, validate } = useNoteEditorValidation(
    noteFormValues,
    formConfig,
    enableValidation,
  );
  const onResetAddNote = useResetNoteEditor(
    resetNoteFormState,
    disableValidation,
  );
  useAutosaveNoteOnChanges(noteFormValues, noteFormExtras.timer, isSubmitting, {
    onSuccess: (autosaved) => {
      const creatingNewNote = !editingNote;
      const usingAutosavedNote =
        editingNote?.type &&
        [EditableNoteType.Autosaved, EditableNoteType.Alert].includes(
          editingNote.type,
        );

      if (autosaved && (creatingNewNote || usingAutosavedNote)) {
        setNoteFormValue('zendeskTicket', autosaved.zendesk_ticket_id);
        setNoteFormValue('appointmentId', autosaved.appointment_id);
        setNoteFormValue(
          'noShowAppointmentId',
          autosaved.no_show_appointment_id,
        );
        // Update editing note with the note id after saving
        setEditingNote({
          // If no editing note type, then we are creating a new autosave note
          ...(editingNote || { type: EditableNoteType.Autosaved }),
          note: autosaved,
        });
      }
    },
  });

  const { mutateAsync: upsertAutosavedNote } = useUpsertPatientAutosavedNote(
    patientId,
    noteFormExtras.timer,
    {},
  );

  const patientNoteParams = usePatientNoteParams(noteFormValues);
  const { containerRef, panelRef, onScroll } = useSyncScrollPosition(layout);

  useEffect(() => {
    // Bubble the error up to the global error boundary
    if (failedNoteRecovery) {
      throw new Error('Failed to recover autosaved note during crash');
    }
  }, [failedNoteRecovery]);

  return (
    <>
      <NoteEditorErrorBoundary
        onReset={onResetAddNote}
        onRecoveryFail={() => setFailedNoteRecovery(true)}
        onException={() => {
          logger.warn('Exception occurred in NoteEditor, autosaving note...');
          return upsertAutosavedNote(patientNoteParams);
        }}
      >
        <NoteEditorHeaderAndDialogs
          timer={noteFormExtras.timer}
          noteFormValues={noteFormValues}
          draftNote={draftNote}
          validate={validate}
          onResetAddNote={onResetAddNote}
          hasRequiredMedActions={hasRequiredMedActions}
          setNoteFormValue={setNoteFormValue}
        />
        <div
          className={cx(gridContainer, {
            [twoColumnGrid]: layout === NoteEditorLayout.TwoColumn,
          })}
          ref={containerRef}
          onScroll={onScroll}
        >
          <div
            className={cx(editorContainer, {
              [twoColumnPanel]: layout === NoteEditorLayout.TwoColumn,
            })}
            ref={panelRef}
            onScroll={onScroll}
          >
            <RecentNotesHeader noteFormValues={noteFormValues} />
            {layout === NoteEditorLayout.OneColumn && (
              <ValidationErrors
                className={panelItem}
                validationResult={noteFormValidationResult}
                hasRequiredMedActions={showRequiredActionsError}
              />
            )}
            <NoteEditorForm
              noteFormValues={noteFormValues}
              noteFormExtras={noteFormExtras}
              setNoteFormValue={setNoteFormValue}
              noteFormValidationResult={noteFormValidationResult}
              noteFormSubmissionType={noteFormSubmissionType}
            />
          </div>
          <div
            className={cx(previewContainer, {
              [twoColumnPanel]: layout === NoteEditorLayout.TwoColumn,
            })}
          >
            {layout === NoteEditorLayout.TwoColumn && (
              <ValidationErrors
                className={panelItem}
                validationResult={noteFormValidationResult}
                hasRequiredMedActions={showRequiredActionsError}
              />
            )}
            {layout === NoteEditorLayout.TwoColumn && (
              <NotePreview
                className={panelItem}
                noteFormValues={noteFormValues}
              />
            )}
          </div>
        </div>
      </NoteEditorErrorBoundary>
    </>
  );
}

function useResetNoteEditor(
  resetNoteForm: () => void,
  disableValidation: () => void,
) {
  const closeAndClearNoteEditor = useCloseAndClearNoteEditor();
  return () => {
    closeAndClearNoteEditor();
    disableValidation();
    resetNoteForm();
  };
}
