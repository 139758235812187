import type { RawDraftContentState } from 'draft-js';

import type {
  EncounterModuleInstance,
  EndEncounterType,
  TimeTracking,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import type { NoteLabel } from './noteLabels.types';

export enum NoteStatus {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
}

export enum NoteSyncStatus {
  Pending = 'PENDING',
  Syncing = 'SYNCING',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
  NeedsResync = 'NEEDS_RESYNC',
  InTriage = 'IN_TRIAGE',
  Waiting = 'WAITING',
}

export enum NoteType {
  AlertDismissal = 'ALERT_DISMISSAL',
  AlertResolution = 'ALERT_RESOLUTION',
  AlertAutoResolution = 'ALERT_AUTO_RESOLUTION',
  Standard = 'STANDARD',
}

export type TNoteBodyRTF = Optional<
  Optional<RawDraftContentState, 'blocks'>,
  'entityMap'
>;

export enum EndEncounterReason {
  NoShow = 'NO_SHOW',
  EndEarly = 'END_EARLY',
}

export interface Note {
  id: number;
  title: string;
  body: string;
  author: string;
  status?: NoteStatus;
  created_at: string;
  created_by: string;
  updated_at: string;
  labels: NoteLabel[];
  // TODO: Remove when switch over to V2 Rich Text Editor
  // https://cadencerpm.atlassian.net/browse/PLAT-4330
  rtf_body: Nullable<TNoteBodyRTF>;
  body_html: Nullable<string>;
  action_required?: boolean;
  urgent?: boolean;
  should_emr_sync?: boolean;
  emr_sync_status?: NoteSyncStatus;
  external_provider_id?: string;
  encounter_instances?: EncounterModuleInstance[];
  time_tracking?: Partial<TimeTracking>;
  note_type?: NoteType;
  zendesk_ticket_id?: Nullable<number>;
  appointment_id?: Nullable<string>;
  no_show_appointment_id?: Nullable<string>;
  clinical_navigator_note?: Array<Record<string, unknown>>;
  ui_state?: {
    vitals_alert_id?: number;
  };
  end_encounter_type?: Nullable<EndEncounterType>;
  end_encounter_reason?: Nullable<string>;
  end_encounter_note?: Nullable<string>;
  time_elapsed?: Nullable<number>;
}

export type RequiredNoteType = Omit<
  Note,
  'created_at' | 'updated_at' | 'created_by' | 'status'
>;
export type NoteResponse = RequiredNoteType;

export type AlertNoteContent = Omit<RequiredNoteType, 'id' | 'labels'> & {
  labels: { id: number }[];
};

// GET draft note payload
export type TDraftNotePayload = RequiredNoteType;
