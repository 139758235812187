import { format, isDate, parseISO } from 'date-fns';
import type { IntlShape } from 'react-intl';

import {
  type EmergencyVisitsFormFields,
  getReasonForVisitI18nMap,
  getTypeOfVisitI18nMap,
} from '../../../CNWizardForm/sections/shared/emergencyVisits';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';

type Params = {
  intl: IntlShape;
  edVisitData: Maybe<EmergencyVisitsFormFields>;
};

export function cnEmergencyVisits({ intl, edVisitData }: Params) {
  const md = new MarkdownBuilder();

  md.h3('Emergency Visits in last 3 months');

  // no visits, bail early
  if (edVisitData?.newVisits !== 'true') {
    md.p(
      'Patient does not report any new disease related visits to the ED or urgent care or hospital admissions.',
    );
    return md.toString();
  }

  const typeOfVisitI18nMap = getTypeOfVisitI18nMap(intl);
  const reasonForVisitI18nMap = getReasonForVisitI18nMap(intl);
  const typesOfVisit = (edVisitData.typeOfVisit ?? []).map(
    (typeOfVisit) => typeOfVisitI18nMap[typeOfVisit],
  );
  const reasonsForVisit = (edVisitData.reasonForVisit ?? []).map(
    (reasonForVisit) => reasonForVisitI18nMap[reasonForVisit],
  );

  let output = `${typesOfVisit.join('/')}`;

  const listFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  });
  output += ` for ${listFormatter.format(reasonsForVisit)}`;

  const formattedStartDate = formatDate(edVisitData.startDate);

  if (edVisitData.endDate) {
    output += ` from ${formattedStartDate} to ${formatDate(
      edVisitData.endDate,
    )}`;
  } else {
    output += ` on ${formattedStartDate}`;
  }

  if (edVisitData.location) {
    output += ` at ${edVisitData.location}`;
  }

  output += '.';

  if (edVisitData.additionalInformation) {
    output += ` ${edVisitData.additionalInformation}`;
  }

  md.p(output);

  return md.toString();
}

function formatDate(date: Maybe<Date | string>) {
  if (!date) {
    return '';
  }

  const parsed = typeof date === 'string' ? parseISO(date) : date;

  if (!isDate(parsed)) {
    return '';
  }

  return format(parsed, 'MM/dd/yyyy');
}
