import { ProgressBar } from './ProgressBar';
import { Step } from './Step';
import { Wizard as BaseWizard } from './Wizard';
import { ConfiguredWizardProvider } from './state';

// Export Wizard with subcomponents (i.e. Wizard.Step, Wizard.ProgressBar, etc...)
export const Wizard = Object.assign(BaseWizard, {
  Step,
  ProgressBar,
  Provider: ConfiguredWizardProvider,
});
