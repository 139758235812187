import { differenceInDays, parseISO, startOfDay } from 'date-fns';

export const isNDayBeforeTargetDate = (
  inputTimestamp: string,
  targetTimestamp: string,
  nDay: number,
) => {
  const inputDate = startOfDay(parseISO(inputTimestamp));
  const targetDate = startOfDay(parseISO(targetTimestamp));

  return differenceInDays(targetDate, inputDate) === nDay;
};

export function getComparisonOperator<B extends boolean>(
  left: number,
  right: number,
  useSymbol: B,
  isInclusive?: B,
): B extends true ? string : number;
export function getComparisonOperator(
  left: number,
  right: number,
  useSymbol = false,
  isInclusive = false,
) {
  if (useSymbol) {
    if (isInclusive) {
      return left <= right ? '≤' : '≥';
    }
    return left < right ? '<' : '>';
  }

  // This is used for all intlMessageFormatters in alertIntlMessageFormatter.ts
  return left > right ? 0 : 1;
}
