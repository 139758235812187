import { useEffect, useState } from 'react';

import type { PatientReminderNoteData } from '@/shared/types/patient.types';

import { ReminderNote } from './ReminderNote';
import { ReminderNoteEditor } from './ReminderNoteEditor';

type Props = {
  note: Nullable<PatientReminderNoteData>;
  isUpdatingReminderNote: boolean;
  onCancelNoteEdit: () => void;
  onReminderNoteAdded: (newNoteText: string) => void;
  onReminderNoteDelete: () => void;
};

export const ReminderNoteContainer = ({
  note,
  isUpdatingReminderNote,
  onCancelNoteEdit,
  onReminderNoteAdded,
  onReminderNoteDelete,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(!note);
  }, [note]);

  const handleOnCancelNoteEdit = () => {
    setIsEditing(false);
    if (!note) {
      onCancelNoteEdit();
    }
  };

  const handleOnNoteSave = (newNoteText: string) => {
    onReminderNoteAdded(newNoteText);
  };

  return isEditing || !note ? (
    <ReminderNoteEditor
      isUpdatingReminderNote={isUpdatingReminderNote}
      currentNoteText={note?.text || ''}
      cancelNoteEdit={handleOnCancelNoteEdit}
      saveNote={handleOnNoteSave}
    />
  ) : (
    <ReminderNote
      note={note}
      isUpdatingReminderNote={isUpdatingReminderNote}
      editReminderNote={() => setIsEditing(true)}
      deleteReminderNote={onReminderNoteDelete}
    />
  );
};
