import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import { Wizard } from '@/shared/common/Wizard';
import {
  useWizardFormFromConfig,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';

import { FieldGroup } from '../../../shared';
import { CallButton } from '../shared/CallButton';
import { NOTES_FORM_CONFIG } from './notesFormConfig';
import type { NotesFormFields } from './notesFormConfig';

export function Notes() {
  const intl = useIntl();
  const history = useHistory();
  const form = useWizardFormFromConfig<NotesFormFields>(
    '/notes/index',
    NOTES_FORM_CONFIG,
  );
  const { onNavigate, wizardFormData } = useWizardStateContext();

  useEffect(() => {
    if (wizardFormData.navigationHistory.length === 0) {
      onNavigate(
        { pathname: '/notes/index', search: '', state: '', hash: '' },
        'PUSH',
      );
    }
  }, [wizardFormData.navigationHistory, onNavigate]);

  return (
    <Wizard.Step
      title={intl.formatMessage({ defaultMessage: 'Notes' })}
      sections={[]} // TODO: remove from Wizard.Step component
      form={form}
      onNext={() => history.push('/time-tracking/index')}
    >
      <FieldGroup>
        <CallButton />
        <Form.TextArea
          size={12}
          rows={8}
          name="notes"
          label={<FormattedMessage defaultMessage="Notes" />}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
