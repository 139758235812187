import uniq from 'lodash/uniq';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
  YesNoRadioGroup,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import {
  scriptLabel,
  scriptText,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { verificationsList } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { usePatientDiseaseStateI18n } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/usePatientDiseaseStateI18n';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';
import { useAssignedDevices } from '@/shared/hooks/queries/devices.queries';
import { DeviceTypes } from '@/shared/types/device.types';

import { useCNFormContext } from '../../../CNFormContext';
import { AdminProfileButton } from '../../shared/AdminProfileButton';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';
import { cycle17InitVisitPath } from '../paths';
import { list } from './DeviceTips.css';
import {
  type DeviceTipsFormFields,
  getDeviceTipsFormConfig,
} from './formConfig';

export function DeviceTips() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<DeviceTipsFormFields>(
    cycle17InitVisitPath('/device-tips', '/index'),
    getDeviceTipsFormConfig(intl),
  );
  const sectionVisibility = useSectionVisibility(form);
  const { patientId } = useCNFormContext();
  const { diseaseState, isLoading: isLoadingDiseaseState } =
    usePatientDiseaseStateI18n(patientId);
  const { deviceList, isLoading: isLoadingDeviceList } =
    useDeviceListI18n(patientId);

  return (
    <Wizard.Step
      isLoading={isLoadingDiseaseState || isLoadingDeviceList}
      form={form}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Device Tips' })}
    >
      <FieldGroup>
        <YesNoRadioGroup
          name="smsReceived"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              <FormattedMessage
                defaultMessage="Let's start by discussing your devices. I see that you have a {deviceList} and have been able to take some readings. When you took your first reading, you should have received a text message congratulating you on getting started. Did you receive that message?"
                values={{ deviceList }}
              />
            </Script>
          }
        />
        {sectionVisibility.smsTroubleshooting && (
          <>
            <ol className={verificationsList}>
              <li>
                <FormattedMessage defaultMessage="Verify the number receiving texts." />{' '}
              </li>
              <li>
                <FormattedMessage defaultMessage="If it belongs to a caregiver, they will receive the texts. This number can be updated in Cadence if needed." />{' '}
              </li>
              <li>
                <FormattedMessage defaultMessage="Ok to try having the patient take a reading on the phone to see if we receive it." />{' '}
              </li>
              <li>
                <FormattedMessage defaultMessage="If they are not receiving text messages and all appears correct, transfer to patient success after the completion of the call." />{' '}
              </li>
            </ol>
            <AdminProfileButton />
          </>
        )}
        {sectionVisibility.smsSuccess && (
          <Script>
            <FormattedMessage defaultMessage="Great. You'll receive a text message each time you take a reading. That lets you know that we have received it. If you live in an area where cell service isn't great, there may be a delay in getting the text message. If you have weak cell service, you may see an error code such as ER4, 5, or 6. That is ok. Once cell service is available, the readings will be uploaded. If your reading is out of normal range, you may receive a message to check it again." />
          </Script>
        )}
        {sectionVisibility.tips && (
          <>
            <Script>
              <FormattedMessage defaultMessage="A few more tips about your devices:" />
            </Script>
            <ul className={list}>
              <li className={scriptText}>
                <FormattedMessage defaultMessage="If you are traveling, we do recommend taking your devices with you when possible. However, if you will be gone for an extended amount of time and will not be taking readings, please let us know ahead of time by calling or texting us." />
              </li>
              <li className={scriptText}>
                <FormattedMessage defaultMessage="Be sure that you are the only one using the devices. Each reading will transmit and may cause confusion if it does not match your usual readings." />
              </li>
            </ul>
            <Script>
              <FormattedMessage
                defaultMessage="The number that I called from today should be the same as the one that texts you. I would recommend saving this number in your phone as Cadence. This way you receive phone calls from us when needed. We are available to you 24/7 by phone or text if you have any questions or concerns regarding your {diseaseState} or devices."
                values={{ diseaseState }}
              />
            </Script>
          </>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}

function useSectionVisibility(form: ConfiguredForm<DeviceTipsFormFields>) {
  const smsReceived = form.watch('smsReceived');

  return {
    tips: !!smsReceived,
    smsTroubleshooting: smsReceived === 'false',
    smsSuccess: smsReceived === 'true',
  };
}

function useDeviceListI18n(patientId: string) {
  const intl = useIntl();
  const { data, isLoading } = useAssignedDevices(patientId, {});
  const i18nMap = {
    [DeviceTypes.BLOOD_GLUCOSE_METER]: intl.formatMessage({
      defaultMessage: 'blood glucose meter',
    }),
    [DeviceTypes.BLOOD_PRESSURE_METER]: intl.formatMessage({
      defaultMessage: 'blood pressure monitor',
    }),
    [DeviceTypes.WEIGHT_SCALE]: intl.formatMessage({ defaultMessage: 'scale' }),
  } as const;
  const listFormatter = new Intl.ListFormat('en', {
    style: 'long',
    type: 'conjunction',
  });
  const devices =
    data?.data.map(({ device_type }) => i18nMap[device_type]) ?? '';

  return { deviceList: listFormatter.format(uniq(devices)), isLoading };
}
