export const FETCH_PATIENT_DETAILS = 'FETCH_PATIENT_DETAILS';
export const FETCH_PATIENT_DETAILS_SUCCESS = 'FETCH_PATIENT_DETAILS_SUCCESS';
export const FETCH_PATIENT_DETAILS_FAILURE = 'FETCH_PATIENT_DETAILS_FAILURE';
export const FETCH_PATIENT_LABS = 'FETCH_PATIENT_LABS';
export const FETCH_PATIENT_LABS_SUCCESS = 'FETCH_PATIENT_LABS_SUCCESS';
export const FETCH_PATIENT_LABS_FAILURE = 'FETCH_PATIENT_LABS_FAILURE';
export const CLEAR_PATIENT_LABS = 'CLEAR_PATIENT_LABS';
export const FETCH_PATIENT_CONTACTS = 'FETCH_PATIENT_CONTACTS';
export const FETCH_PATIENT_CONTACTS_SUCCESS = 'FETCH_PATIENT_CONTACTS_SUCCESS';
export const FETCH_PATIENT_CONTACTS_FAILURE = 'FETCH_PATIENT_CONTACTS_FAILURE';

export const fetchPatientDetails = (id: string): TActionCreatorStringParam => ({
  id,
  type: FETCH_PATIENT_DETAILS,
});

export const patientDetailsFetched = (
  payload: TPatientsDetailsPayload,
): TPatientsDetailsResponse => ({
  payload,
  type: FETCH_PATIENT_DETAILS_SUCCESS,
});

export const patientDetailsError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: FETCH_PATIENT_DETAILS_FAILURE,
});

export const fetchPatientContacts = (
  id: string,
): TActionCreatorStringParam => ({
  id,
  type: FETCH_PATIENT_CONTACTS,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patientContactsFetched = (payload: any): any => ({
  payload,
  type: FETCH_PATIENT_CONTACTS_SUCCESS,
});

export const patientContactsError = (
  payload: TResponseError,
): TActionCreatorResponseError => ({
  payload,
  type: FETCH_PATIENT_CONTACTS_FAILURE,
});

export const fetchPatientLabs = (
  id: string,
  cmp: boolean | null,
  referenceLabId?: number,
): TActionCreatorMultipleParam => ({
  id,
  cmp,
  referenceLabId,
  type: FETCH_PATIENT_LABS,
});

export const patientLabsFetched = (payload: TLabsPayload): TLabsResponse => ({
  payload,
  type: FETCH_PATIENT_LABS_SUCCESS,
});

export const clearPatientLabs = () => ({
  type: CLEAR_PATIENT_LABS,
});
