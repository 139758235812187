import isObject from 'lodash/isObject';

import type { RapidWeightGainSubThreshold } from '@/shared/types/tagsAndThreshold.types';

import type {
  AlertMessageFormatterProps,
  FullBpReading,
} from '../../AlertDescription.types';

export function isFullBpReading(
  threshold: AlertMessageFormatterProps['values']['threshold'],
): threshold is FullBpReading {
  return !!(
    isObject(threshold) &&
    (threshold as FullBpReading).systolic &&
    (threshold as FullBpReading).diastolic
  );
}

export function isRapidWeightGainThreshold(
  threshold: AlertMessageFormatterProps['values']['threshold'],
): threshold is RapidWeightGainSubThreshold {
  return !!(
    isObject(threshold) &&
    (threshold as RapidWeightGainSubThreshold).one_day_change &&
    (threshold as RapidWeightGainSubThreshold).two_day_change
  );
}
