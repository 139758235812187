import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from '@/shared/common/Form/validations';

export type DeviceTipsFormFields = {
  smsReceived?: 'true' | 'false';
};

export function getDeviceTipsFormConfig(intl: IntlShape) {
  const { required } = validators(intl);

  return {
    fields: {
      smsReceived: {
        defaultValue: undefined,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
    },
  };
}
