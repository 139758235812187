import { FormattedMessage } from 'react-intl';

import UrgentIcon from '@/shared/assets/svgs/arrowFillUp.svg?react';

import { BaseIndicator } from './BaseIndicator';

type Props = {
  resolved: boolean;
};

export function UrgentIndicator({ resolved }: Props) {
  return (
    <BaseIndicator
      variant={resolved ? 'neutral' : 'danger'}
      tooltip={<FormattedMessage defaultMessage="Urgent" />}
    >
      <UrgentIcon />
    </BaseIndicator>
  );
}
