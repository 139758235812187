import isEmpty from 'lodash/isEmpty';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { INVALID_DATE } from '@/shared/common/@deprecated/SchemaDrivenForm/';

import {
  DOSE_REASONS,
  MEDICATION_CLASSES,
  MED_TITRATION_OPTION_PREVIEWS,
} from '../NoteEditor/MedicationsForm';
import type {
  BinaryQuestion,
  MedClass,
  MedInfo,
  MedicationsInputs,
  Reason,
} from '../NoteEditor/MedicationsForm/MedicationsInputs.types';
import { sortByMedClassName } from '../NoteEditor/MedicationsForm/sortingUtil';
import type { TypeOfEncounter } from '../Notes.types';
import { isAdHocClinicalEncounterType } from '../utils/encounterTypeUtils';
import { modulePreviewSection } from './NotePreview.css';
import {
  formatDate,
  joinMessages,
  joinMessagesGrammatically,
} from './formatUtils';

export function formatMedicationsInputs(
  inputs: MedicationsInputs,
  title: string,
  encounterType?: TypeOfEncounter,
  isPatientNoShow?: boolean,
  noMedsToReport?: boolean,
) {
  const header = <b>{title}:</b>;

  if (noMedsToReport) {
    if (isPatientNoShow) {
      return null;
    }

    return (
      <>
        {header}
        <div>{noMedsToReportMessage(encounterType)}</div>
      </>
    );
  }

  const medClasses = getPrescribedMedicationClasses(inputs);

  return (
    <>
      {header}
      {medClasses.map(([medClassKey, medClassValue]) => (
        <Fragment key={medClassKey}>
          {getMedClassText(medClassKey, medClassValue.med_info)}
        </Fragment>
      ))}
    </>
  );
}

function noMedsToReportMessage(encounterType?: TypeOfEncounter) {
  if (isAdHocClinicalEncounterType(encounterType)) {
    return (
      <FormattedMessage defaultMessage="Disease-specific medications were not reported or assessed." />
    );
  }
  return (
    <FormattedMessage defaultMessage="Patient is not on any disease-specific medications." />
  );
}

function getPrescribedMedicationClasses(inputs: MedicationsInputs) {
  return sortByMedClassName(inputs)
    .filter(isMedClassEntry)
    .filter(([, value]) => value.is_prescribed);
}

function isMedClassEntry(
  entry: [string, MedClass | boolean | undefined],
): entry is [string, MedClass] {
  return typeof entry[1] === 'object';
}

function getMedClassText(medClassKey: string, medInfo?: MedInfo) {
  const medClassLabel = <i>{getMedClassLabel(medClassKey)}:</i>;

  if (!medInfo) {
    return <div className={modulePreviewSection}>{medClassLabel}</div>;
  }

  const medPlan = getMedPlan(medInfo.med_titration);
  const formattedMedInfo = getMedInfo(
    medInfo.previous_dose,
    medInfo.med_dose,
    medInfo.med_frequency,
    medInfo.med_titration,
  );

  return (
    <div className={modulePreviewSection}>
      <span>
        <i>{medInfo.med_name}: </i>
        {joinMessages([
          medPlan && <strong>{medPlan}</strong>,
          formattedMedInfo && <>{formattedMedInfo}</>,
          getDate(medInfo.titration_date, medInfo.titration_end_date),
        ])}
      </span>
      {getReason(medInfo.titration_reason)}
      {getTargetDoseInfo(
        medInfo.is_target_dose,
        medInfo.reason_not_on_target_dose,
      )}
      {getMaxToleratedDoseInfo(
        medInfo.is_max_tolerated_dose,
        medInfo.reason_not_on_max_tolerated_dose,
      )}
    </div>
  );
}

function getMedClassLabel(medClassKey: string) {
  return MEDICATION_CLASSES[medClassKey as keyof typeof MEDICATION_CLASSES];
}

function getMedPlan(medPlanKey?: string) {
  return MED_TITRATION_OPTION_PREVIEWS[
    medPlanKey as keyof typeof MED_TITRATION_OPTION_PREVIEWS
  ];
}

function getDate(dateOfChange?: string, endDate?: string) {
  if (!dateOfChange || dateOfChange === INVALID_DATE) {
    return null;
  }

  if (endDate && endDate !== INVALID_DATE) {
    return (
      <FormattedMessage
        defaultMessage="from {startDate} to {endDate}"
        values={{
          startDate: formatDate(dateOfChange),
          endDate: formatDate(endDate),
        }}
      />
    );
  }

  return formatDate(dateOfChange);
}

function getMedInfo(
  previousDose?: string,
  medDose?: string,
  medFrequency?: string,
  medPlan?: MedInfo['med_titration'],
) {
  let doseMessage;

  if (medPlan === 'up-titrate' || medPlan === 'down-titrate') {
    if (previousDose) {
      doseMessage = medDose ? (
        <FormattedMessage
          defaultMessage="from {previousDose} to {medDose}"
          values={{ previousDose, medDose }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="from {previousDose}"
          values={{ previousDose }}
        />
      );
    } else if (medDose) {
      doseMessage = (
        <FormattedMessage defaultMessage="to {medDose}" values={{ medDose }} />
      );
    }
  } else {
    doseMessage = medDose;
  }

  const medInfoMessages = [doseMessage, medFrequency].filter(
    (msg): msg is string | JSX.Element => Boolean(msg),
  );
  if (isEmpty(medInfoMessages)) {
    return null;
  }

  return joinMessages(medInfoMessages);
}

function getReason(reasonForChange?: Reason) {
  if (!reasonForChange || isEmpty(reasonForChange)) {
    return null;
  }

  return (
    <div>
      {joinMessagesGrammatically(
        getReasonMessages(reasonForChange),
        <FormattedMessage defaultMessage="Reason: " />,
      )}
    </div>
  );
}

function getTargetDoseInfo(
  isTargetDose?: BinaryQuestion,
  reasonNotOnTargetDose?: Reason,
) {
  return getDoseInfo(
    <FormattedMessage defaultMessage="Target dose" />,
    isTargetDose,
    reasonNotOnTargetDose,
  );
}

function getMaxToleratedDoseInfo(
  isMaxToleratedDose?: BinaryQuestion,
  reasonNotOnMaxToleratedDose?: Reason,
) {
  return getDoseInfo(
    <FormattedMessage defaultMessage="Maximally tolerated dose" />,
    isMaxToleratedDose,
    reasonNotOnMaxToleratedDose,
  );
}

function getDoseInfo(
  message: JSX.Element,
  isAtDose?: BinaryQuestion,
  reasonNotAtDose?: Reason,
) {
  if (!isAtDose) {
    return null;
  }

  let result = (
    <FormattedMessage
      defaultMessage="{message}: {isAtDose}"
      values={{ message, isAtDose }}
    />
  );

  if (reasonNotAtDose) {
    const messages = joinMessagesGrammatically(
      getReasonMessages(reasonNotAtDose),
    );
    if (messages) {
      result = (
        <FormattedMessage
          defaultMessage="{result}, {messages}"
          values={{
            result,
            messages,
          }}
        />
      );
    }
  }

  return <div>{result}</div>;
}

function getReasonMessages(reasonObj: Reason) {
  const reasons = Object.entries(reasonObj)
    .filter(([, value]) => value === true)
    .map(([key]) => key);

  return reasons.map((r) => (
    <Fragment key={r}>{DOSE_REASONS[r as keyof typeof DOSE_REASONS]}</Fragment>
  ));
}
