import type { ReactNode } from 'react';

import { fieldLabel, fieldValue, header, subheader } from './Layout.css';

export function Header({ children }: { children: ReactNode }) {
  return <h2 className={header}>{children}</h2>;
}

export function Subheader({ children }: { children: ReactNode }) {
  return <h3 className={subheader}>{children}</h3>;
}

export function FieldLabel({ children }: { children: ReactNode }) {
  return <h4 className={fieldLabel}>{children}</h4>;
}

export function FieldValue({ children }: { children: ReactNode }) {
  return <p className={fieldValue}>{children}</p>;
}

export function Field({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <div>
      <FieldLabel>{label}</FieldLabel>
      <FieldValue>{value}</FieldValue>
    </div>
  );
}
