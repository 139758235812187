import cx from 'classnames';
import { useRef, useState } from 'react';
import type { AriaCheckboxProps } from 'react-aria';
import { VisuallyHidden, useCheckbox, useFocusRing } from 'react-aria';
import { useToggleState } from 'react-stately';

import {
  focusRingColor,
  focusRingErrorColor,
} from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { checkmark, label, square, svg } from './Checkbox.css';

export type Props = {
  isFocusDisabled?: boolean;
  hasError?: boolean;
  classes?: {
    label?: {
      default?: string;
      hovered?: string;
      focused?: string;
      checked?: string;
    };
  };
} & AriaCheckboxProps;

export function Checkbox({ isFocusDisabled, classes, ...props }: Props) {
  const { isDisabled, isIndeterminate, children, hasError } = props;
  const [isHovered, setIsHovered] = useState(false);
  const state = useToggleState(props);
  const ref = useRef(null);
  const { inputProps } = useCheckbox(props, state, ref);
  const { isFocusVisible: focused, focusProps } = useFocusRing({
    isTextInput: false,
  });
  const isSelected = state.isSelected && !isIndeterminate;
  const isFocusVisible = focused && !isFocusDisabled;

  return (
    <label
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={cx(
        label.default,
        {
          [label.focused]: isFocusVisible,
          [label.hovered]: isHovered,
        },
        classes?.label?.default,
        isSelected && classes?.label?.checked,
        isFocusVisible && classes?.label?.focused,
        isHovered && classes?.label?.hovered,
      )}
      aria-disabled={isDisabled}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={svg}
      >
        {isFocusVisible && (
          <rect
            x={-1}
            y={-1}
            width={26}
            height={26}
            rx="4"
            strokeWidth={6}
            stroke={hasError ? focusRingErrorColor : focusRingColor}
          />
        )}
        <rect
          x="0.5"
          y="0.5"
          width={23}
          height={23}
          rx="3.5"
          aria-disabled={isDisabled}
          className={cx(square.default, {
            [square.selected]: isSelected,
            [square.focused]: isFocusVisible,
            [square.hovered]: isHovered,
            [square.error]: hasError,
            [square.selectedError]: isSelected && hasError,
          })}
        />
        {isSelected && (
          <path
            className={cx({
              [checkmark.default]: !isDisabled,
              [checkmark.disabled]: isDisabled,
            })}
            transform="translate(4 4)"
            d="M14.2845 1.96125L5.07659 11.1691L1.71554 7.77306C1.54048 7.63302 1.26039 7.63302 1.12035 7.77306L0.105033 8.78838C-0.0350109 8.92842 -0.0350109 9.20851 0.105033 9.38357L4.7965 14.04C4.97155 14.2151 5.21663 14.2151 5.39169 14.04L15.895 3.53674C16.035 3.3967 16.035 3.11661 15.895 2.94155L14.8797 1.96125C14.7396 1.78619 14.4595 1.78619 14.2845 1.96125Z"
          />
        )}
        {isIndeterminate && <>{/* TODO: Support isIndeterminate */}</>}
      </svg>
      {children}
    </label>
  );
}
