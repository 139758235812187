import type { CSSProperties, ForwardedRef, ReactElement } from 'react';
import { forwardRef } from 'react';
import { Transition } from 'react-transition-group';
import type {
  TransitionProps,
  TransitionStatus,
} from 'react-transition-group/Transition';

type Props = TransitionProps & {
  children: ReactElement;
};

export const TRANSITION_DURATION = 200;

const defaultStyle: CSSProperties = {
  transition: `all ${TRANSITION_DURATION}ms ease-in-out`,
  opacity: 0,
  height: '100%',
  outline: 0,
};

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entering: { opacity: 1, transform: 'translateY(0)' },
  entered: { opacity: 1, transform: 'translateY(0)' },
  exiting: { opacity: 0, transform: 'translateY(100px)' },
  exited: { opacity: 0, transform: 'translateY(100px)' },
};

export const ModalTransition = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>) => (
    <Transition
      in={props.in}
      appear
      mountOnEnter
      unmountOnExit
      nodeRef={ref}
      timeout={TRANSITION_DURATION}
    >
      {(state) => (
        <div
          tabIndex={-1}
          ref={ref}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {props.children}
        </div>
      )}
    </Transition>
  ),
);
