import { useObjectRef } from '@react-aria/utils';
import type { RadioGroupState } from '@react-stately/radio';
import cx from 'classnames';
import { forwardRef, useContext, useState } from 'react';
import type { AriaRadioProps } from 'react-aria';
import { VisuallyHidden, useFocusRing, useRadio } from 'react-aria';

import {
  focusRingColor,
  focusRingErrorColor,
} from '@/shared/tempo/util/FocusRing/FocusRing.css';
import { RadioGroupContext } from '@/shared/tempo/util/RadioGroupContext';

import {
  label,
  radioCircle,
  radioDot,
  svg as svgCss,
  visuallyHidden,
} from './Radio.css';

type Props = {
  children?: React.ReactNode;
  state?: RadioGroupState;
  classes?: {
    label?: string;
  };
} & AriaRadioProps;

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ state: propState, classes, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const ctxState = useContext(RadioGroupContext);
    const [isHovered, setIsHovered] = useState(false);

    if (!propState && !ctxState) {
      throw new Error(
        'Radio must be used within a RadioGroup or receive state via props.',
      );
    }

    // this cast is safe since we bail above if we don't have either propState or ctxState
    const state = (propState ?? ctxState) as RadioGroupState;
    const { inputProps, isDisabled, isSelected } = useRadio(props, state, ref);
    const { isFocusVisible, focusProps } = useFocusRing();
    const isValid = !state.isInvalid;
    const { children } = props;

    return (
      <label
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cx(
          label.default,
          {
            [label.focused]: isFocusVisible,
            [label.hovered]: isHovered,
          },
          classes?.label,
        )}
        aria-disabled={isDisabled}
      >
        <VisuallyHidden className={visuallyHidden}>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>
        <svg width="24" height="24" aria-hidden="true" className={svgCss}>
          {isFocusVisible && (
            <circle
              cx="12"
              cy="12"
              r="17.5"
              fill={isValid ? focusRingColor : focusRingErrorColor}
            />
          )}
          <circle
            cx="12"
            cy="12"
            r="11.5"
            className={cx({
              [radioCircle.default]: isValid,
              [radioCircle.error]: !isValid,
              [radioCircle.hovered]: isHovered,
              [radioCircle.focused]: isFocusVisible,
            })}
            aria-disabled={isDisabled}
          />
          <circle
            cx="12"
            cy="12"
            r="8"
            stroke="none"
            className={cx({
              [radioDot.default]: !isSelected,
              [radioDot.selected]: isSelected && !isDisabled,
              [radioDot.disabled]: isSelected && isDisabled,
              [radioDot.error]: !isValid && isSelected && !isDisabled,
            })}
          />
        </svg>
        {children}
      </label>
    );
  },
);
