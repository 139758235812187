import type { ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';

import { NavigationSubscriber } from './NavigationSubscriber';
import { RouteNotFound } from './RouteNotFound';
import { Wizard as RootWizard } from './index';
import { useWizardStateContext } from './state';

type Props = {
  routes: Record<string, ComponentType<unknown>>;
};

export function Wizard({ routes }: Props) {
  const { onNavigate, sections } = useWizardStateContext();

  return (
    <>
      <RootWizard.ProgressBar sections={sections} />
      <NavigationSubscriber onNavigation={onNavigate} />
      <Switch>
        {sections.map((section) =>
          section.steps.map((step) => {
            const fullPath = `${section.basePath}${step.path}`;
            return (
              <Route
                exact
                key={fullPath}
                path={fullPath}
                component={routes[fullPath]}
              />
            );
          }),
        )}
        <Route path="*" component={RouteNotFound} />
      </Switch>
    </>
  );
}
