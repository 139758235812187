import type { UseInfiniteQueryResult } from 'react-query';

import { useFlatPages } from '@/reactQuery';
import { InfiniteScrollLoader } from '@/shared/common/InfiniteScrollLoader';
import type { PaginatedTasks } from '@/shared/hooks/queries/tasks.queries';
import { EmptyTasks as SharedEmptyTasks } from '@/shared/tasking/EmptyTasks';
import { TaskCardSkeleton } from '@/shared/tasking/TaskCard/TaskCardSkeleton';
import {
  ParentView,
  TaskList as SharedTaskList,
} from '@/shared/tasking/TaskList';

import { collapsedContainer } from './ProfileTaskList.css';

type Props = {
  query: UseInfiniteQueryResult<PaginatedTasks>;
};

export function ProfileTaskList({ query }: Props) {
  const tasks = useFlatPages(query);

  if (!tasks.length) {
    return <SharedEmptyTasks />;
  }

  return (
    <>
      <SharedTaskList
        tasks={tasks}
        parentView={ParentView.PatientProfile}
        taskHubOverlayState={null}
        classes={{
          container: collapsedContainer,
        }}
      />
      <InfiniteScrollLoader
        query={query}
        loadingPlaceholder={<TaskCardSkeleton />}
      />
    </>
  );
}
