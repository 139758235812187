/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WeeklySummaryEmailSchema } from '../models/WeeklySummaryEmailSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WeeklySummaryEmailService {
    /**
     * Send weekly summary email to a patient
     * @param requestBody A JSON object containing Weekly Summary Email data
     * @returns void
     * @throws ApiError
     */
    public static postNotificationApiV1CommunicationsWeeklySummaryEmail(
        requestBody: WeeklySummaryEmailSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/api/v1/communications/weekly_summary_email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }
}
