import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import earthImg from '@/shared/assets/earth.png';
import { SupportModal } from '@/shared/common/Sidebar/SidebarMenu/SupportModal';
import { Button } from '@/shared/tempo/atom/Button';

import { emptyState } from './Labs.css';

export function EmptyState() {
  const intl = useIntl();
  const [reportIssueOpen, setReportIssueOpen] = useState(false);

  return (
    <>
      <div className={emptyState.container}>
        <img
          src={earthImg}
          className={emptyState.image}
          alt={intl.formatMessage({
            defaultMessage: 'a drawing of earth',
          })}
        />
        <div className={emptyState.header}>
          <FormattedMessage defaultMessage="No matching lab results" />
        </div>
        <div className={emptyState.body}>
          <FormattedMessage
            defaultMessage="Try adjusting your filters to view other labs. {supportButton} if you discover labs present in the EMR that are missing in Cadence."
            values={{
              supportButton: (
                <Button
                  className={emptyState.supportLink}
                  variant="tertiary"
                  onPress={() => setReportIssueOpen(true)}
                >
                  <FormattedMessage defaultMessage="File a support ticket" />
                </Button>
              ),
            }}
          />
        </div>
      </div>
      <SupportModal
        open={reportIssueOpen}
        onClose={() => {
          setReportIssueOpen(false);
        }}
      />
    </>
  );
}
