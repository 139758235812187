import { parseISO } from 'date-fns';
import type { IntlShape } from 'react-intl';

import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';
import type { AllBloodGlucoseTagType } from '@/shared/types/tagsAndThreshold.types';
import {
  BloodGlucoseMonthlyAverageTagType,
  BloodGlucoseTagType,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType,
} from '@/shared/types/tagsAndThreshold.types';
import { convertToMgDl } from '@/shared/utils/unit-helpers';

import type { BgFormatterProps } from '../../AlertDescription.types';
import {
  isBgAvgTag,
  isBgRelatedTags,
  isBgWeeklyPercentageRelatedTags,
} from '../alertTagsUtil';
import { AlertFormatter } from './AlertFormatter';

export class BloodGlucoseAlertFormatter extends AlertFormatter<
  AllBloodGlucoseTagType,
  BgFormatterProps
> {
  constructor(
    private intl: IntlShape,
    private flags: FeatureFlagSet,
  ) {
    super();
  }

  protected unitConverter = convertToMgDl;

  protected unit = 'mg/dL';

  protected map: Map<AllBloodGlucoseTagType, string> = new Map<
    AllBloodGlucoseTagType,
    string
  >([
    [
      BloodGlucoseTagType.BloodGlucoseHighP0,
      this.intl.formatMessage({ defaultMessage: 'Very High BG' }),
    ],
    [
      BloodGlucoseTagType.BloodGlucoseHighP1,
      this.intl.formatMessage({ defaultMessage: 'High BG' }),
    ],
    [
      BloodGlucoseTagType.BloodGlucoseLowP0,
      this.intl.formatMessage({ defaultMessage: 'Very Low BG' }),
    ],
    [
      BloodGlucoseTagType.BloodGlucoseLowP1,
      this.intl.formatMessage({ defaultMessage: 'Low BG' }),
    ],
    [
      BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP0,
      this.intl.formatMessage({ defaultMessage: 'Very High 30D BG' }),
    ],
    [
      BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP1,
      this.intl.formatMessage({ defaultMessage: 'High BG' }),
    ],
    [
      BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP2,
      this.intl.formatMessage({ defaultMessage: 'High BG' }),
    ],
    [
      BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP0,
      this.intl.formatMessage({ defaultMessage: 'Very High BG' }),
    ],
    [
      BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP1,
      this.intl.formatMessage({ defaultMessage: 'High BG' }),
    ],
    [
      BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP2,
      this.intl.formatMessage({ defaultMessage: 'High BG' }),
    ],
  ]);

  getDescription(tag: AllBloodGlucoseTagType, props: BgFormatterProps): string {
    if (isBgRelatedTags(tag)) {
      const { values, unit, operator } = this.convertReadings(props);
      return `${values.currentReading} ${operator} ${values.threshold} ${unit}`;
    }

    if (isBgAvgTag(tag)) {
      const { operator, values, unit } = this.convertReadings(props);

      return this.intl.formatMessage(
        {
          defaultMessage: 'Avg Month BG {operator} {threshold} {unit}',
        },
        { operator, threshold: values.threshold, unit },
      );
    }

    if (isBgWeeklyPercentageRelatedTags(tag)) {
      const { operator, values, unit } = this.convertReadings(props);

      return this.intl.formatMessage(
        {
          defaultMessage: '30%+ Week BG {operator} {threshold} {unit}',
        },
        { operator, threshold: values.threshold, unit },
      );
    }

    return this.intl.formatMessage({ defaultMessage: 'Invalid Alert Type' });
  }

  public getTitle(
    tag: AllBloodGlucoseTagType,
    timestamp: string,
  ): string | undefined {
    if (
      tag !==
        BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP1 &&
      tag !== BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP1
    ) {
      return this.map.get(tag);
    }

    if (
      parseISO(timestamp) >
      parseISO(this.flags.bloodGlucoseP1TrendLabelVeryHigh)
    ) {
      return this.intl.formatMessage({ defaultMessage: 'Very High BG' });
    }
    return this.intl.formatMessage({ defaultMessage: 'High BG' });
  }
}
