import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import { MedicationChangeStatus } from '@/shared/generated/grpcGateway/medication.pb';

import { usePatientMedications } from '../patientMedications.queries';
import { isStructured, lastChange } from './medChangeUtils';
import { getMedGroups } from './sortMeds';

export function requiredActions(
  medChange: MedicationChange,
  isDiseaseSpecific: boolean,
) {
  return {
    structuring:
      isDiseaseSpecific &&
      !isStructured(medChange) &&
      medChange.status !== MedicationChangeStatus.INACTIVE,
    verification: medChange.status === MedicationChangeStatus.NEEDS_REVIEW,
  };
}

export enum MedCategory {
  DiseaseSpecific = 'diseaseSpecific',
  Other = 'other',
}

export function useHasRequiredMedActions(
  patientId: string,
  enabled = false,
  category?: MedCategory,
) {
  const { data } = usePatientMedications(patientId, { enabled });
  if (!enabled) {
    return false;
  }

  const { diseaseSpecificMeds, otherMeds } = getMedGroups(data);

  const dsmActions = diseaseSpecificMeds.map((m) => {
    const medChange = lastChange(m.medChanges);
    return medChange
      ? requiredActions(medChange, true)
      : { structuring: false, verification: false };
  });
  const omActions = otherMeds.map((mOrMc) =>
    requiredActions(mOrMc.medChange, false),
  );

  if (category === MedCategory.DiseaseSpecific) {
    return dsmActions.some(hasAction);
  }
  if (category === MedCategory.Other) {
    return omActions.some(hasAction);
  }
  return dsmActions.concat(omActions).some(hasAction);
}

function hasAction(action: { structuring: boolean; verification: boolean }) {
  return action.structuring || action.verification;
}
