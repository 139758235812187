import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import type { SortOption } from './types';

type Props = {
  selected?: SortOption;
  options: SortOption[];
  onChange: (option: SortOption) => void;
};

export function PatientSortSelect({ selected, options, onChange }: Props) {
  const intl = useIntl();
  return (
    <Select
      popover={{ width: 'min-select' }}
      selectedKey={!selected ? options[0].key : selected.key}
      onSelectionChange={(key) => {
        const option = getOptionByKey(key as string, options);
        if (!option) {
          // Log because this is a developer error
          logger.error(`No sort option found with key: ${key}`);
          return;
        }
        onChange(option);
      }}
      items={options}
      aria-label={intl.formatMessage({ defaultMessage: 'Appointments' })}
    >
      {(item) => <Select.Option key={item.key}>{item.label}</Select.Option>}
    </Select>
  );
}

function getOptionByKey(key: string, options: SortOption[]) {
  return options.find((option) => option.key === key);
}
