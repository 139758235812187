import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Snackbar } from '@/shared/common/Snackbar';
import {
  MIN_SEARCH_LENGTH,
  TypeaheadSearch,
} from '@/shared/common/TypeaheadSearch';
import type { PatientSearchConfig } from '@/shared/hooks/queries';
import {
  useDeprecatedPatientSearch,
  useResetSearch,
} from '@/shared/hooks/queries';

import { PatientResultRow } from './PatientResultRow';
import { SearchResultsContainer } from './SearchResultsContainer';

type PatientSearchTypeaheadProps = {
  autoFocus?: boolean;
  initialValue?: string;
  searchConfig?: PatientSearchConfig;
  onBlur?: () => void;
  onClose?: () => void;
  onSearch: (searchTerm: string | null) => void;
  onSelect: (patientId: string) => void;
};

const SEARCH_SOURCE = 'typeahead';

export function PatientSearchTypeahead({
  autoFocus = true,
  initialValue = '',
  searchConfig = {},
  onBlur,
  onClose,
  onSearch,
  onSelect,
}: PatientSearchTypeaheadProps) {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearch = useResetSearch();
  const {
    data: patients,
    isFetching,
    isError,
    isFetched,
    error,
  } = useDeprecatedPatientSearch(
    searchTerm,
    searchTerm?.length >= MIN_SEARCH_LENGTH,
    searchConfig,
    SEARCH_SOURCE,
  );

  return (
    <>
      {isError && <Snackbar message={error} variant="error" />}
      <TypeaheadSearch
        options={patients?.data}
        isLoading={isFetching}
        noOptionsText={
          isFetched && !patients?.data?.length
            ? intl.formatMessage({ defaultMessage: 'No patients found' })
            : intl.formatMessage({
                defaultMessage:
                  'Enter at least three characters to search for a patient',
              })
        }
        placeholder={intl.formatMessage({
          defaultMessage: 'Search by Name, MRN or ID',
        })}
        OptionComponent={PatientResultRow}
        ResultContainer={SearchResultsContainer}
        autoFocus={autoFocus}
        initialValue={initialValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(patientOrSearchTerm) => {
          if (typeof patientOrSearchTerm === 'string') {
            return patientOrSearchTerm;
          }

          return `${patientOrSearchTerm.first_name} ${
            patientOrSearchTerm.last_name
          } ${patientOrSearchTerm.mrns?.join(' ')} ${patientOrSearchTerm.id}`;
        }}
        onSelect={(patient) => onSelect(patient.id)}
        onSearch={onSearch}
        onSearchTermChange={setSearchTerm}
        onReset={() => resetSearch(searchTerm, SEARCH_SOURCE)}
        onBlur={onBlur}
        onClose={onClose}
      />
    </>
  );
}
