import cx from 'classnames';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import { useFetchQuery } from '@/reactQuery';
import DownArrow from '@/shared/assets/svgs/downArrow.svg?react';
import UpArrow from '@/shared/assets/svgs/upArrow.svg?react';
import { LabValueStatus } from '@/shared/generated/grpcGateway/labs.pb';
import { color } from '@/shared/generated/theme/variables';
import { usePresignedDocumentUrl } from '@/shared/hooks/queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { labValue, unitText } from './LabsTable.css';
import { PLACEHOLDER } from './LabsTableCells';

type LabCellContentProps = {
  value: Maybe<string>;
  s3Uri: Maybe<string>;
  status: LabValueStatus;
  idx: number;
  isSingle: boolean;
};

export function LabCellContent({
  value,
  s3Uri,
  status,
  idx,
  isSingle,
}: LabCellContentProps) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { fetch: fetchPresignedDoc, isLoading: isFetchingPresignedUrl } =
    useFetchQuery(usePresignedDocumentUrl);
  const hasAttachment = Boolean(s3Uri);
  async function onLabValueClick() {
    await fetchPresignedDoc(s3Uri || '', {
      enabled: hasAttachment,
      onSuccess: (resp) => window.open(resp.presigned_s3_url, '_newtab'),
      onError: (err) => {
        toaster.error(
          intl.formatMessage({ defaultMessage: 'Failed to open lab PDF' }),
        );
        logger.error(
          'Failed to retrieve lab PDF presigned URL',
          err instanceof Error ? err : undefined,
        );
      },
    });
  }

  const labValueInUnits = (
    <>
      <span
        className={cx({
          [labValue.attachment]: hasAttachment,
          [labValue.default]: !hasAttachment,
        })}
      >
        {value ||
          (isSingle
            ? intl.formatMessage({ defaultMessage: 'PDF' })
            : intl.formatMessage(
                { defaultMessage: 'Value {value}' },
                { value: idx + 1 },
              )) ||
          PLACEHOLDER}
      </span>
      <span className={unitText.attachment}> </span>
    </>
  );

  return (
    <div>
      {hasAttachment ? (
        <>
          <Button
            onPress={onLabValueClick}
            isProcessing={isFetchingPresignedUrl}
            variant="tertiary"
          >
            {labValueInUnits}
          </Button>
        </>
      ) : (
        labValueInUnits
      )}
      {status === LabValueStatus.LOW && (
        <DownArrow fill={color.Theme.Light.Danger} />
      )}
      {status === LabValueStatus.HIGH && (
        <UpArrow fill={color.Theme.Light.Danger} />
      )}
    </div>
  );
}
