import type { Key } from 'react';
import type { ItemProps } from 'react-stately';
import { Item } from 'react-stately';

type TabProps<T> = {
  className?: string;
  keepMounted?: boolean;
  onClick?: (key: Key) => void;
} & ItemProps<T>;

export const TabItem = Item as <T>(props: TabProps<T>) => JSX.Element;
