import { CadenceApp, renderApp } from '@/CadenceApp';

import { App } from './App';
import './index.scss';

renderApp(() => (
  <CadenceApp>
    <CadenceApp.ErrorBoundary>
      <App />
    </CadenceApp.ErrorBoundary>
  </CadenceApp>
));
