import type { IntlShape } from 'react-intl';

import { type IntroFormFields } from '../../../CNWizardForm/sections/shared/IntroPatientAttendance';
import { getAppointmentCancellationTypeI18n } from '../../../CNWizardForm/sections/shared/IntroPatientAttendance/appointmentCancellationTypeI18n';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';
import { sectionHasData } from '../util';

type Params = {
  intl: IntlShape;
  introData: Maybe<IntroFormFields>;
};

export function cnPatientNoShow({ intl, introData }: Params) {
  if (!sectionHasData(introData)) {
    return '';
  }

  const md = new MarkdownBuilder();
  md.p('Patient no show');

  const apptCancellationI18n = getAppointmentCancellationTypeI18n(intl);
  md.field(
    'Appointment cancellation type',
    introData?.cancellationReason
      ? apptCancellationI18n[introData?.cancellationReason]
      : null,
  );

  if (introData?.additionalCancellationInfo) {
    md.newline();
    md.p(introData?.additionalCancellationInfo);
  }

  return md.toString();
}
