import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { gridContainer } from '../CarePlanForm/CarePlanForm.css';
import { CreateGoalModal } from '../Goals/CreateGoalModal';
import { EditGoalModal } from '../Goals/EditGoalModal';
import { GoalsGrid } from '../Goals/GoalsGrid';
import { type GoalModalState, GoalModalType } from '../Goals/goalModal.types';
import { emptyState } from './CarePlanEmptyState.css';

type Props = {
  patientId: string;
  hasLegacyApprovedCarePlan: boolean;
};

export function CarePlanEmptyState({
  patientId,
  hasLegacyApprovedCarePlan,
}: Props) {
  const [openedModal, setOpenedModal] =
    useState<Nullable<GoalModalState>>(null);
  return (
    <>
      {hasLegacyApprovedCarePlan && (
        <div className={gridContainer}>
          <GoalsGrid
            patientId={patientId}
            onEditGoal={(goal) => {
              setOpenedModal({ type: GoalModalType.EditGoal, goal });
            }}
            onCreateGoal={() => {
              setOpenedModal({ type: GoalModalType.CreateGoal });
            }}
          />
        </div>
      )}
      <div
        className={
          hasLegacyApprovedCarePlan
            ? emptyState.container.legacyPatient
            : emptyState.container.default
        }
      >
        <div className={emptyState.content}>
          {hasLegacyApprovedCarePlan ? (
            <>
              <p className={emptyState.text}>
                <FormattedMessage defaultMessage="This patient is enrolled in CCM and may have an existing Care Plan in Google Drive." />
              </p>
              <p className={emptyState.text}>
                <FormattedMessage defaultMessage="To create a new Care Plan in Cadence, start a CCM Care Plan encounter." />
              </p>
            </>
          ) : (
            <>
              <p className={emptyState.text}>
                <FormattedMessage defaultMessage="This patient is eligible for CCM but does not yet have a care plan." />
              </p>
              <p className={emptyState.text}>
                <FormattedMessage defaultMessage="Start a Care Plan encounter to start building a care plan." />
              </p>
            </>
          )}
        </div>
      </div>
      {openedModal?.type === GoalModalType.CreateGoal && (
        <CreateGoalModal
          patientId={patientId}
          carePlanId={undefined}
          defaults={openedModal.defaults}
          onClose={() => setOpenedModal(null)}
        />
      )}
      {openedModal?.type === GoalModalType.EditGoal && (
        <EditGoalModal
          goal={openedModal.goal}
          onClose={() => setOpenedModal(null)}
        />
      )}
    </>
  );
}
