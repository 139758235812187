import { endOfDay, startOfDay } from 'date-fns';
import { useCallback } from 'react';

import { useListAppointments } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { useTwilioFlexContext } from '@/shared/common/TwilioFlex';
import { ACTIVE_TASK_STATUSES } from '@/shared/hooks/useTwilioFlexMessagesListener';

import { getNotificationDetails } from './utils';

export const useFlexAwareApptNotifications = (providerId: Maybe<string>) => {
  const today = new Date();
  const { data: todayApptData } = useListAppointments(
    {
      apptStartTimeFrom: startOfDay(today).toISOString(),
      apptStartTimeTo: endOfDay(today).toISOString(),
      careProviderId: providerId ?? '',
    },
    Boolean(providerId),
  );

  const { tasks } = useTwilioFlexContext();

  const activeTasks = Array.from(tasks.values()).filter((task) =>
    ACTIVE_TASK_STATUSES.includes(task.status),
  );

  const notificationsCallback = useCallback(() => {
    const notifDetails = getNotificationDetails(
      todayApptData?.appointments || [],
    );
    // Provider is currently in a flex task with the patient
    if (
      notifDetails.patientId &&
      activeTasks.some(
        (task) => task.data.patient_id === notifDetails.patientId,
      )
    ) {
      return {
        startingSoon: false,
        isLate: false,
        minutes: 0,
        appointmentId: null,
        patientId: null,
      };
    }
    return notifDetails;
  }, [activeTasks, todayApptData?.appointments]);

  return notificationsCallback;
};
