import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { SharedIntroConfirmData } from '../../shared/IntroConfirmData';
import { regVisitPath } from '../paths';

export function IntroConfirmData() {
  return (
    <SharedIntroConfirmData
      sections={REGULAR_VISIT_SECTIONS}
      nextPath={regVisitPath('/symptoms', '/index')}
    />
  );
}
