import Handlebars from 'handlebars';

import { TEMPLATE_HELPERS } from './helpers';
import { TEMPLATE_PARTIALS } from './partials';

// Exporting an isolated (non-global) pre-configured instance of handlebars
export const engine = (() => {
  const instance = Handlebars.create();
  instance.registerHelper(TEMPLATE_HELPERS);
  instance.registerPartial(TEMPLATE_PARTIALS);
  return instance;
})();
