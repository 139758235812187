import { FormattedMessage } from 'react-intl';

export const PATIENT_FOLLOW_UP_SECTIONS = [
  {
    basePath: '/notes',
    title: <FormattedMessage defaultMessage="Notes" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/time-tracking',
    title: <FormattedMessage defaultMessage="Time Tracking" />,
    steps: [{ path: '/index' }],
  },
] as const;
