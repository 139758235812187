import { divider, heading, row, valueText } from './EntryRow.css';

const EMPTY_PLACEHOLDER = '---';

type Props = {
  title: string;
  value?: string;
  lastRow?: boolean;
};

export function EntryRow({ title, value = EMPTY_PLACEHOLDER, lastRow }: Props) {
  return (
    <>
      <div className={row}>
        <div className={heading}>{title}</div>
        <div className={valueText}>{value}</div>
      </div>
      {!lastRow && <hr className={divider} />}
    </>
  );
}
