import type { IntlShape } from 'react-intl';

import { validators } from '@/shared/common/Form/validations';

import type { EndFormValues } from '../../noteFormState';

export type EndEncounterFormFields = {
  endEncounterType?: EndEncounterType;
  endEarlyReason?: EndEncounterReason | null;
  noShowReason?: EndEncounterReason | null;
  endCallNotes: string;
};

export enum EndEncounterType {
  NoShow = 'NO_SHOW',
  EndEarly = 'END_EARLY',
}

export enum EndEncounterReason {
  NoShow = 'NO_SHOW',
  PatientRequestReschedule = 'PATIENT_REQUEST_RESCHEDULE',
  PatientUnableToSpeak = 'PATIENT_UNABLE_TO_SPEAK',
  SchedulingErrorDisenrollment = 'SCHEDULING_ERROR_OR_DISENROLLMENT',
  PatientInNonCadenceState = 'PATIENT_IN_NON_CADENCE_STATE',
  PatientHospitalized = 'PATIENT_HOSPITALIZED',
  PatientRefusedAppointment = 'PATIENT_REFUSED_APPOINTMENT',
  PatientDissatisfied = 'PATIENT_DISSATISFIED',
  TimeConstraints = 'TIME_CONSTRAINTS',
  DeviceDelayTroubleshooting = 'DEVICE_DELAY_OR_TROUBLESHOOTING',
  Other = 'OTHER',
}

export function getEndEncounterEarlyFormConfig(
  intl: IntlShape,
  prevEndForm?: EndFormValues,
) {
  const { required, enumType, maxLengthString } = validators(intl);
  const defaultEndType = prevEndForm?.endType;
  const defaultEndReason = prevEndForm?.endReason;
  const defaultEndEarlyNote = prevEndForm?.endNote;

  return {
    fields: {
      endEncounterType: {
        defaultValue: defaultEndType ?? null,
        validation: required(
          enumType({ source: EndEncounterType, pluck: 'values' }),
        ),
      },
      endEarlyReason: {
        defaultValue: defaultEndReason ?? null,
        validation: enumType({
          source: EndEncounterReason,
          pluck: 'values',
        }).when('endEncounterType', {
          is: EndEncounterType.EndEarly,
          then: required,
        }),
      },
      noShowReason: {
        defaultValue: defaultEndReason ?? null,
        validation: enumType({
          source: EndEncounterReason,
          pluck: 'values',
        }).when('endEncounterType', {
          is: EndEncounterType.NoShow,
          then: required,
        }),
      },
      endCallNotes: {
        defaultValue: defaultEndEarlyNote ?? null,
        validation: maxLengthString({ maxLength: 255 }).nullable(),
      },
    },
  };
}
