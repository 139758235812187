import { isValid, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { getUserTimezone } from '@/shared/utils/time-helpers';

export function useFormatDateInUserTz() {
  const userTimezone = getUserTimezone();
  const intl = useIntl();
  return useCallback(
    (date: Date | string | undefined, format: string) => {
      if (!date) {
        return intl.formatMessage({ defaultMessage: 'N/A' });
      }
      const parsed = date instanceof Date ? date : parseISO(date);
      if (!isValid(parsed)) {
        return intl.formatMessage({ defaultMessage: 'N/A' });
      }
      return formatInTimeZone(parsed, userTimezone, format);
    },
    [intl, userTimezone],
  );
}
