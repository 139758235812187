/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SMSHistorySchema } from '../models/SMSHistorySchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SmsHistoryService {
    /**
     * Get notification audit history for a patient
     * @param patientId Patient ID to get sms history for
     * @returns SMSHistorySchema Successful operation
     * @throws ApiError
     */
    public static getNotificationApiV1CommunicationsSmsHistory(
        patientId: string,
    ): CancelablePromise<SMSHistorySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notification/api/v1/communications/sms_history/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }
}
