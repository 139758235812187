import cx from 'classnames';

import { Button } from '@/shared/tempo/atom/Button';
import { Tooltip, type TooltipProps } from '@/shared/tempo/atom/Tooltip';

import type { Props as SegmentProps } from './Segment';
import {
  segmentedButton,
  segmentedButtonDefault,
  segmentedButtonFocused,
} from './SegmentedButton.css';
import type { SegmentedButtonGroupState } from './segmentedButtonGroupState';

type Props = {
  size?: 'default' | 'small';
  className?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
  state: SegmentedButtonGroupState;
  variant?: 'default' | 'icon-only';
  isFirst: boolean;
  isLast: boolean;
} & SegmentProps;

export function SegmentedButton({
  value,
  children,
  state,
  className,
  tooltip = { content: null },
  size = 'default',
  variant = 'default',
  isFirst,
  isLast,
}: Props) {
  const { isDisabled, selectedValue, setSelectedValue } = state;
  return (
    <Tooltip {...tooltip}>
      <Button
        size={size}
        isDisabled={isDisabled}
        classes={{
          focused: segmentedButtonFocused,
          default: segmentedButtonDefault,
        }}
        className={cx(className, segmentedButton.default, {
          [segmentedButton.first]: isFirst,
          [segmentedButton.middle]: !isFirst && !isLast,
          [segmentedButton.last]: isLast,
          [segmentedButton.iconOnly]: variant === 'icon-only',
        })}
        onPress={() => setSelectedValue(value)}
        variant={
          selectedValue && selectedValue === value ? 'primary' : 'secondary'
        }
      >
        {children}
      </Button>
    </Tooltip>
  );
}
