import type { ReactNode } from 'react';

import type { PatientGoal } from '../PatientGoal';

export type LinkType = {
  title: ReactNode;
  href: string;
  section: EducationModuleSection;
  deprecated?: boolean;
};

export enum UnderstandDiseaseConditions {
  T2d = 'better_understand_my_disease_t2d',
  Htn = 'better_understand_my_disease_htn',
  Chf = 'better_understand_my_disease_chf',
}

export type EducationModule =
  | Exclude<PatientGoal, PatientGoal.BetterUnderstandMyDisease>
  | UnderstandDiseaseConditions
  | UnderstandDiseaseConditions;

export enum EducationModuleSection {
  // Shared
  HowMuchExerciseAndWhatKind = 'how_much_exercise_and_what_kind',
  WhyPhysicalActivityIsSoImportant = 'why_physical_activity_is_so_important',
  GettingStartedTipsForLongTermExerciseSuccess = 'getting_started_tips_for_long_term_exercise_success',
  StayingMotivatedToStayingActive = 'staying_motivated_to_staying_active',
  OverviewOfDietTypes = 'overview_of_diet_types',
  TheHealthyDietIngredientsAndTips = 'the_healthy_diet_ingredients_and_tips',
  ReadingLabelsAndUnderstandingPortionSize = 'reading_labels_and_understanding_portion_size',
  // Become more physically active
  BecomeMorePhysicallyActive = 'become_more_physically_active',
  ExerciseAndChronicDisease = 'exercise_and_chronic_disease',
  ExerciseAndArthritis = 'exercise_and_arthritis',
  StayingActiveInColdWeather = 'staying_active_in_cold_weather',
  StayingActiveInWarmWeather = 'staying_active_in_warm_weather',
  // Take my medications more consistently
  TakeMyMedicationsMoreConsistently = 'take_my_medications_more_consistently',
  TheImportanceOfMedicationAdherence = 'the_importance_of_medication_adherence',
  ReasonsForNotTakingMedications = 'reasons_for_not_taking_medications',
  GeneralTipsToImproveMedicationAdherence = 'general_tips_to_improve_medication_adherence',
  // Lower my weight
  PrinciplesOfHealthyWeightLossForLongTerm = 'principles_of_healthy_weight_loss_for_long_term',
  ChangeManagementForWeightLoss = 'change_management_for_weight_loss',
  // Improve my food choices
  ImproveMyDiet = 'improve_my_diet',
  ControllingSaltInYourDiet = 'controlling_salt_in_your_diet',
  GrainsAndFiber = 'grains_and_fiber',
  TheMediterraneanDiet = 'the_mediterranean_diet',
  // Reduce my stress
  ReduceMyStress = 'reduce_my_stress',
  UnderstandingChronicStress = 'understanding_chronic_stress',
  BasicStressManagementTips = 'basic_stress_management_tips',
  RelaxationThroughMindfulness = 'relaxation_through_mindfulness',
  // Better understand my disease (T2D)
  GeneralIntroductionToDiabetesType2 = 'general_introduction_to_diabetes_type_2',
  GeneralGuideToCheckingBloodSugarLevels = 'general_guide_to_checking_blood_sugar_levels',
  ManagingType2DiabetesThroughDiet = 'managing_type_2_diabetes_through_diet',
  NoMoreCarbConfusion = 'no_more_carb_confusion',
  AvoidingHighLowBloodGlucoseLevels = 'avoiding_high_low_blood_glucose_levels',
  DiabetesAndExercisePart1 = 'diabetes_and_exercise_part_1',
  DiabetesAndExercisePart2 = 'diabetes_and_exercise_part_2',
  // Better understand my disease (HTN)
  WhatIsHighBloodPressure = 'what_is_high_blood_pressure',
  ManagingYourBloodPressureOverviewPart1 = 'managing_your_blood_pressure_overview_part_1',
  ManagingYourBloodPressureOverviewPart2 = 'managing_your_blood_pressure_overview_part_2',
  // Better understand my disease (CHF)
  WhatIsHeartFailure = 'what_is_heart_failure',
  /** deprecated * */
  FirstLetsDefineHeartFailure = 'first_lets_define_heart_failure',
  LivingWithHeartFailure = 'living_with_heart_failure',
  MonitoringSymptomsOfHeartFailure = 'monitoring_symptoms_of_heart_failure',
  ExerciseAndActivityWithHeartFailure = 'exercise_and_activity_with_heart_failure',
  // Get better sleep
  WhatIsGoodSleep = 'what_is_good_sleep',
  TipsForBetterSleep = 'tips_for_better_sleep',
  AgingAndSleepPart1 = 'aging_and_sleep_part_1',
  AgingAndSleepPart2 = 'aging_and_sleep_part_2',
  // Quit Smoking
  SmokingCessationOverview = 'smoking_cessation_overview',
}
