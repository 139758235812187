import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedChartPrep } from '../../shared/ChartPrep';
import { cycle17InitVisitPath } from '../paths';

export function ChartPrep() {
  return (
    <SharedChartPrep
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      nextPath={cycle17InitVisitPath('/intro', '/patient-attendance')}
    />
  );
}
