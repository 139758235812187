import type { Call } from '@/shared/generated/grpcGateway/call.pb';
import {
  CallDirection,
  CallState,
} from '@/shared/generated/grpcGateway/call.pb';

import type { Conference } from './Messages';

// TODO: handle from BE and pass clean data to FE
export function processCallData(callData: Call[]): Conference[] {
  const uniqueCalls = new Map<string, Call[]>();

  (callData ?? []).forEach((call) => {
    // Determine the key (conferenceSid or callSid)
    const key = call.conferenceSid || call.callSid;

    if (!key) {
      return;
    }

    if (uniqueCalls.has(key)) {
      // If key exists, append the current call to the array
      const callList = uniqueCalls.get(key);
      if (callList) {
        callList.push(call);
      }
    } else {
      uniqueCalls.set(key, [call]);
    }
  });

  return Array.from(uniqueCalls.values()).map((calls) => {
    if (calls.length === 1) {
      const call = calls[0];
      return {
        uniqueId: call.callSid || '',
        direction: call.direction ?? CallDirection.DIRECTION_UNSPECIFIED,
        state: call.state ?? CallState.STATE_UNSPECIFIED,
        startTime: call.startTime ?? '',
        legs: [],
        patientDuration: call.duration ?? 0,
        recordingSid: call.recordingSid,
        noteId: call.noteId,
      };
    }
    if (calls.length === 2) {
      const patientCall = calls.find((call) => !call.careProviderId);
      const agentCall = calls.find((call) => Boolean(call.careProviderId));

      return {
        uniqueId: patientCall?.conferenceSid || '',
        direction:
          patientCall?.direction ?? CallDirection.DIRECTION_UNSPECIFIED,
        state: patientCall?.state ?? CallState.STATE_UNSPECIFIED,
        startTime: patientCall?.startTime ?? '',
        legs: [
          {
            careProviderFirstName: agentCall?.careProviderFirstName,
            careProviderLastName: agentCall?.careProviderLastName,
            careProviderRole: agentCall?.careProviderRole,
            duration: findDurationOverlap(patientCall, agentCall),
            isTransfer: false,
          },
        ],
        patientDuration: patientCall?.duration ?? 0,
        recordingSid: agentCall?.recordingSid,
        noteId: patientCall?.noteId,
      };
    }
    const patientCall = calls.find((call) => call.careProviderId === null);
    const agentCalls = calls.filter((call) => call.careProviderId !== null);

    return {
      uniqueId: patientCall?.conferenceSid || '',
      direction: patientCall?.direction ?? CallDirection.DIRECTION_UNSPECIFIED,
      state: patientCall?.state ?? CallState.STATE_UNSPECIFIED,
      startTime: patientCall?.startTime ?? '',
      legs: agentCalls.map((call) => ({
        careProviderFirstName: call.careProviderFirstName,
        careProviderLastName: call.careProviderLastName,
        careProviderRole: call.careProviderRole,
        duration: findDurationOverlap(patientCall, call),
        isTransfer: call.direction === CallDirection.TRANSFER,
      })),
      patientDuration: patientCall?.duration ?? 0,
      recordingSid: agentCalls.find((call) => Boolean(call.recordingSid))
        ?.recordingSid,
      noteId: patientCall?.noteId,
    };
  });
}

function findDurationOverlap(callOne?: Call, callTwo?: Call): number {
  if (
    !callOne?.startTime ||
    !callOne?.endTime ||
    !callTwo?.startTime ||
    !callTwo?.endTime
  ) {
    return 0;
  }

  const start1 = new Date(callOne.startTime);
  const end1 = new Date(callOne.endTime);
  const start2 = new Date(callTwo.startTime);
  const end2 = new Date(callTwo.endTime);

  const latestStart = new Date(Math.max(start1.getTime(), start2.getTime()));
  const earliestEnd = new Date(Math.min(end1.getTime(), end2.getTime()));

  let overlap = earliestEnd.getTime() - latestStart.getTime();

  if (overlap > 0) {
    overlap /= 1000;
  } else {
    overlap = 0;
  }
  return overlap;
}
