import { useIntl } from 'react-intl';

import { Toggle } from '@/shared/tempo/atom/Toggle';

type Props = {
  value?: boolean;
  onChange: (value: boolean) => void;
};

export function PatientDeclinedMedReviewToggle({ value, onChange }: Props) {
  const intl = useIntl();
  const label = intl.formatMessage({
    defaultMessage: 'Patient declined to answer',
  });

  return (
    <Toggle aria-label={label} onChange={onChange} isSelected={value}>
      <Toggle.Label>{label}</Toggle.Label>
    </Toggle>
  );
}
