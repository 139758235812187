import type { Dispatch, SetStateAction } from 'react';

import { ConfirmationModal } from './ConfirmationModal';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { CreateEntryModal } from './MutateEntryModal';
import { ViewEntryModal } from './ViewEntryModal';
import type { ModalState } from './modalState.types';
import { ModalType } from './modalState.types';

type Props = {
  modalState: Nullable<ModalState>;
  setModalState: Dispatch<SetStateAction<Nullable<ModalState>>>;
};

export function TimeTrackingModals({ modalState, setModalState }: Props) {
  const onClose = () => setModalState(null);

  switch (modalState?.type) {
    case ModalType.CreateConfirmation:
      return (
        <ConfirmationModal
          onClose={onClose}
          onManualConfirm={() =>
            setModalState({ type: ModalType.CreateTimeEntry })
          }
        />
      );
    case ModalType.CreateTimeEntry:
      return <CreateEntryModal onClose={onClose} />;
    case ModalType.UpdateTimeEntry:
      return (
        <CreateEntryModal
          entryId={modalState.entryId}
          onClose={onClose}
          onDelete={() =>
            setModalState({
              type: ModalType.DeleteConfirmation,
              entryId: modalState.entryId,
            })
          }
        />
      );
    case ModalType.ViewTimeEntry:
      return <ViewEntryModal entryId={modalState.entryId} onClose={onClose} />;
    case ModalType.DeleteConfirmation:
      return (
        <DeleteConfirmationModal
          entryId={modalState.entryId}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
}
