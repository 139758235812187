import cx from 'classnames';
import type { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';

import { portrait } from './Avatar.css';
import { useAvatarContext } from './avatarContext';

type Props = {
  src: string;
} & HTMLAttributes<HTMLDivElement>;

export function Portrait({ src, ...props }: Props) {
  const intl = useIntl();
  const { size } = useAvatarContext();
  return (
    <img
      {...props}
      alt={intl.formatMessage({ defaultMessage: 'Avatar portrait' })}
      src={src}
      className={cx(portrait[size])}
    />
  );
}
