import cx from 'classnames';
import { formatDistanceToNow, isValid, parseISO } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Script } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { useGetNextRecommendedAppointment } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { SchedulePatientModal } from '@/shared/common/SchedulePatientModal';
import { Wizard } from '@/shared/common/Wizard';
import { usePatientContext, usePatientDetails } from '@/shared/hooks/queries';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Button } from '@/shared/tempo/atom/Button';
import { ConditionProgram } from '@/shared/types/condition.types';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';
import { helperList, helperText, scheduleButton } from './SharedScheduling.css';

type Props = {
  sections: CNSectionMap;
  nextPath: string;
};

export function SharedScheduling({ sections, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { patientId, program } = useCNFormContext();
  const { data: patient } = usePatientDetails(patientId, false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: patientContext, isLoading: isContextLoading } =
    usePatientContext(patientId);

  const { data: recommendedAppt, isLoading: isRecommendationLoading } =
    useGetNextRecommendedAppointment(patientId);

  const apptRole = recommendedAppt?.appointmentTypeName?.includes('NP')
    ? 'NP'
    : 'CN';
  const recommendedDate = parseISO(recommendedAppt?.startDate ?? '');
  const providerName = formatProviderForScript(
    patientContext?.primaryPhysicianGivenName,
    patientContext?.primaryPhysicianFamilyName,
    patientContext?.primaryPhysicianRole,
  );

  return (
    <Wizard.Step
      sections={sections}
      title={intl.formatMessage({
        defaultMessage: 'Schedule next appointment',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(nextPath);
      }}
    >
      <LoadingPlaceholder
        isLoading={isContextLoading || isRecommendationLoading}
      >
        <div className={cx(flexContainer.column, gap.M)}>
          <Script>
            <FormattedMessage defaultMessage="Our last thing to do is schedule your next appointment. Remember, you may hear from the Cadence team before then if a nurse wants to check in on any readings they see. But you will not have any scheduled phone appointments before this visit we schedule right now." />
          </Script>
          <Script>
            {apptRole === 'NP' ? (
              <FormattedMessage defaultMessage="For your next visit, " />
            ) : (
              <FormattedMessage defaultMessage="Based on your readings, " />
            )}
            <FormattedMessage
              defaultMessage="we're going to schedule you with a {apptRole}{apptRelativeTime, select, NOT_FOUND {} other { in {apptRelativeTime}}}."
              values={{
                apptRole,
                apptRelativeTime: isValid(recommendedDate)
                  ? formatDistanceToNow(recommendedDate)
                  : 'NOT_FOUND',
              }}
            />
          </Script>
          {apptRole === 'NP' && (
            <>
              <Script>
                <FormattedMessage defaultMessage="We think that in addition to the behavioral and lifestyle changes we have been working on, you would benefit from seeing one of our nurse practitioners who can help make slight adjustments in your medications." />
              </Script>
              <Script>
                <FormattedMessage
                  defaultMessage="As the diet and lifestyle changes are implemented, if your readings continue to improve, we can always have you see the Nurse Practitioner or {providerName} again to lower or even come off your medications possibly. However, until then we want to make sure your readings are within range, and this is the best way to ensure that occurs."
                  values={{ providerName }}
                />
              </Script>
            </>
          )}
          <div className={helperText}>
            <span>
              <FormattedMessage defaultMessage="Remember to confirm that the patient doesn't have any conflicts prior to booking their next appointment:" />
            </span>
            <ul className={helperList}>
              <li>
                <FormattedMessage
                  defaultMessage="Do you have an in-person, telephone, or video appointment with {providerName} on this date?"
                  values={{ providerName }}
                />
              </li>
              <li>
                <FormattedMessage defaultMessage="Are you planning on being out of town on this date?" />
              </li>
            </ul>
          </div>
          <Button
            variant="secondary"
            className={scheduleButton}
            onPress={() => setIsDialogOpen(true)}
          >
            <Button.Icon>
              <CalendarIcon />
            </Button.Icon>
            <FormattedMessage defaultMessage="Schedule Appointment" />
          </Button>
          <Script>
            <FormattedMessage defaultMessage="Between now and your next appointment, please continue taking regular readings, striving for every day!" />
          </Script>
          {program !== ConditionProgram.CHF && (
            <Script>
              <FormattedMessage defaultMessage="I'm excited to hear about your progress on your new goals, and we will review them at your next visit." />
            </Script>
          )}
          <Script>
            <FormattedMessage defaultMessage="Do you have any questions for me before we hang up?" />
          </Script>
          <Script>
            <FormattedMessage defaultMessage="Thank you for your time and for being part of the Cadence program. I am excited to see what we accomplish together!" />
          </Script>
        </div>
      </LoadingPlaceholder>
      {patient && (
        <SchedulePatientModal
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          patientDetails={patient}
        />
      )}
    </Wizard.Step>
  );
}
