/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufEmpty from "./google/protobuf/empty.pb"
import * as CadenceModelsV1Models from "./cadence/models/models.pb"
import * as GoogleProtobufField_mask from "./google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "./google/protobuf/wrappers.pb"
import * as fm from "./fetch.pb"

export enum CallDirection {
  DIRECTION_UNSPECIFIED = "DIRECTION_UNSPECIFIED",
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
  TRANSFER = "TRANSFER",
}

export enum CallState {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  INITIATED = "INITIATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  UNANSWERED = "UNANSWERED",
  CALLBACK_REQUESTED = "CALLBACK_REQUESTED",
}

export type AddCallEncounterRequest = {
  name?: string
  noteId?: number
}

export type RemoveCallEncounterRequest = {
  name?: string
}

export type GetCallRequest = {
  name?: string
}

export type Conference = {
  name?: string
  patientCallSid?: string
  direction?: CallDirection
  state?: CallState
  patientId?: GoogleProtobufWrappers.StringValue
  caregiverId?: GoogleProtobufWrappers.StringValue
  contactId?: GoogleProtobufWrappers.StringValue
  patientDuration?: GoogleProtobufWrappers.Int32Value
  recordingSid?: GoogleProtobufWrappers.StringValue
  patientStartTime?: GoogleProtobufTimestamp.Timestamp
  patientEndTime?: GoogleProtobufTimestamp.Timestamp
  mainCareProviderLeg?: ConferenceLeg
  transferLegs?: ConferenceLeg[]
  noteIds?: number[]
  careProviderIds?: string[]
}

export type ConferenceLeg = {
  careProvider?: CadenceModelsV1Models.CareProvider
  duration?: GoogleProtobufWrappers.Int32Value
  calculatedCallDuration?: GoogleProtobufWrappers.Int32Value
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  wrapUpStartTime?: GoogleProtobufTimestamp.Timestamp
  wrapUpEndTime?: GoogleProtobufTimestamp.Timestamp
  calculatedWrapUpDuration?: GoogleProtobufWrappers.Int32Value
  zendeskTicketId?: GoogleProtobufWrappers.Int32Value
  noteId?: GoogleProtobufWrappers.Int32Value
  callSid?: GoogleProtobufWrappers.StringValue
}

export type Call = {
  name?: string
  callSid?: string
  direction?: CallDirection
  state?: CallState
  careProviderId?: GoogleProtobufWrappers.StringValue
  careProviderFirstName?: GoogleProtobufWrappers.StringValue
  careProviderLastName?: GoogleProtobufWrappers.StringValue
  careProviderRole?: GoogleProtobufWrappers.StringValue
  patientId?: GoogleProtobufWrappers.StringValue
  contactId?: GoogleProtobufWrappers.StringValue
  noteId?: GoogleProtobufWrappers.Int32Value
  zendeskTicketId?: GoogleProtobufWrappers.Int32Value
  duration?: GoogleProtobufWrappers.Int32Value
  recordingSid?: GoogleProtobufWrappers.StringValue
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: GoogleProtobufWrappers.StringValue
  updateUser?: GoogleProtobufWrappers.StringValue
  caregiverId?: GoogleProtobufWrappers.StringValue
  conferenceSid?: GoogleProtobufWrappers.StringValue
  wrapUpStartTime?: GoogleProtobufTimestamp.Timestamp
  wrapUpEndTime?: GoogleProtobufTimestamp.Timestamp
}

export type ListCallsRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListCallsResponse = {
  calls?: Call[]
  nextPageToken?: string
  totalSize?: number
}

export type ListConferencesRequest = {
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListConferencesResponse = {
  conferences?: Conference[]
  nextPageToken?: string
  totalSize?: number
}

export type RemoveConferenceNoteRequest = {
  name?: string
  noteId?: number
}

export type AddConferenceNoteRequest = {
  name?: string
  noteId?: number
  careProviderId?: string
}

export type UpsertCallRequest = {
  call?: Call
  workerEmail?: GoogleProtobufWrappers.StringValue
  phoneNumber?: string
}

export type MarkCallUnansweredRequest = {
  callSid?: string
  duration?: GoogleProtobufWrappers.Int32Value
  endTime?: GoogleProtobufTimestamp.Timestamp
  recordingSid?: GoogleProtobufWrappers.StringValue
}

export type MarkCallAsCallbackRequest = {
  callSid?: string
  duration?: GoogleProtobufWrappers.Int32Value
  endTime?: GoogleProtobufTimestamp.Timestamp
  recordingSid?: GoogleProtobufWrappers.StringValue
}

export type AddCallToConferenceRequest = {
  callSid?: string
  conferenceSid?: string
}

export type AddWrapUpTimeRequest = {
  callSid?: string
  wrapUpStartTime?: GoogleProtobufTimestamp.Timestamp
  wrapUpEndTime?: GoogleProtobufTimestamp.Timestamp
}

export class CallService {
  static CreateCall(req: UpsertCallRequest, initReq?: fm.InitReq): Promise<Call> {
    return fm.fetchReq<UpsertCallRequest, Call>(`/rpm/v1/call`, {...initReq, method: "POST", body: JSON.stringify(req["call"], fm.replacer)})
  }
  static GetCall(req: GetCallRequest, initReq?: fm.InitReq): Promise<Call> {
    return fm.fetchReq<GetCallRequest, Call>(`/rpm/v1/call/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListCalls(req: ListCallsRequest, initReq?: fm.InitReq): Promise<ListCallsResponse> {
    return fm.fetchReq<ListCallsRequest, ListCallsResponse>(`/rpm/v1/calls?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListConferences(req: ListConferencesRequest, initReq?: fm.InitReq): Promise<ListConferencesResponse> {
    return fm.fetchReq<ListConferencesRequest, ListConferencesResponse>(`/rpm/v1/conferences?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static RemoveConferenceNote(req: RemoveConferenceNoteRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<RemoveConferenceNoteRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/conferences/${req["name"]}:removeNote`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AddConferenceNote(req: AddConferenceNoteRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AddConferenceNoteRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/conferences/${req["name"]}:addNote`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CompleteCall(req: UpsertCallRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UpsertCallRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:complete`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static MarkCallUnanswered(req: MarkCallUnansweredRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<MarkCallUnansweredRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:markCallUnanswered`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static MarkCallAsCallback(req: MarkCallAsCallbackRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<MarkCallAsCallbackRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:markCallAsCallback`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AddCallToConference(req: AddCallToConferenceRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AddCallToConferenceRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:addCallToConference`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AddWrapUpTime(req: AddWrapUpTimeRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AddWrapUpTimeRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:addWrapUpTime`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AddCallEncounter(req: AddCallEncounterRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<AddCallEncounterRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:addCallEncounter`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RemoveCallEncounter(req: RemoveCallEncounterRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<RemoveCallEncounterRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/call:removeCallEncounter`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}