import {
  CREATE_NPI,
  CREATE_NPI_FAILURE,
  CREATE_NPI_SUCCESS,
  DELETE_NPI_FAILURE,
  DELETE_NPI_SUCCESS,
  FETCH_NPI,
  FETCH_NPI_BY_ID,
  FETCH_NPI_BY_ID_FAILURE,
  FETCH_NPI_BY_ID_SUCCESS,
  FETCH_NPI_FAILURE,
  FETCH_NPI_SUCCESS,
  UPDATE_NPI,
  UPDATE_NPI_FAILURE,
  UPDATE_NPI_SUCCESS,
} from '../actionCreators';
import initialState from './initialState';
import type { NpiState } from './npi.types';

const addNpi = (state: any, action: any) =>
  state.data.length < state.metadata.per_page
    ? state.data.concat([action.payload])
    : state.data;

const updateNpi = (state: any, action: any) =>
  state.data.map(
    (npi: {
      created_at: string;
      created_by: string;
      id: number;
      npi: string;
      updated_at: string;
      updated_by: string;
    }) => {
      if (npi.id === action.payload.id) {
        return action.payload;
      }
      return npi;
    },
  );

function npiReducer(state = initialState, action: any): NpiState {
  switch (action.type) {
    case FETCH_NPI:
      return {
        ...state,
        status: {
          isProcessing: true,
          isSuccess: false,
        },
        isDeleted: false,
        error: null,
      };
    case FETCH_NPI_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: {
          isProcessing: false,
          isSuccess: true,
        },
        error: null,
      };
    case FETCH_NPI_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: {
          isProcessing: false,
          isSuccess: false,
        },
      };
    case FETCH_NPI_BY_ID:
      return {
        ...state,
        error: null,
      };
    case FETCH_NPI_BY_ID_SUCCESS:
      return {
        ...state,
        selectedNpi: action.payload,
        error: null,
      };
    case FETCH_NPI_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_NPI:
      return {
        ...state,
        isSubmitting: true,
        isCreated: false,
        error: null,
      };
    case CREATE_NPI_SUCCESS:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          total: state.metadata.total + 1,
        },
        isSubmitting: false,
        isCreated: true,
        data: addNpi(state, action),
        error: null,
      };
    case CREATE_NPI_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isCreated: false,
        error: action.payload,
      };
    case UPDATE_NPI:
      return {
        ...state,
        isSubmitting: true,
        isUpdated: false,
        error: null,
      };
    case UPDATE_NPI_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: true,
        data: updateNpi(state, action),
        error: null,
      };
    case UPDATE_NPI_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isUpdated: false,
        error: action.payload,
      };
    case DELETE_NPI_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        error: null,
      };
    case DELETE_NPI_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default npiReducer;
