import { DeprecatedHeader } from './@deprecated/DeprecatedHeader';
import { Body } from './Body';
import { ActionStrip, Content, Header as HeaderContainer } from './Header';
import { Page as PageContainer } from './Page';
import {
  Body as SidePanelBody,
  SidePanel as SidePanelCmp,
  Footer as SidePanelFooter,
  Header as SidePanelHeader,
} from './SidePanel';

export * from './SidePanel/sidePanel.types';
export * from './SidePanel/SidePanelContext';

// Export Page with subcomponents (i.e. Page.Header, Page.Body)
export const Page = Object.assign(PageContainer, {
  DeprecatedHeader,
  Header: HeaderContainer,
  Body,
});

// Export SidePanel with subcomponents (i.e. SidePanel.Header, SidePanel.Body, SidePanel.Footer)
export const SidePanel = Object.assign(SidePanelCmp, {
  Header: SidePanelHeader,
  Body: SidePanelBody,
  Footer: SidePanelFooter,
});

export const Header = Object.assign(HeaderContainer, {
  ActionStrip,
  Content,
});

// Publish shared header styles
export * from './styles.css';
