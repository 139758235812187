import type { OverlayTriggerState } from 'react-stately';

import { createCtx } from '@/shared/hooks/createCtx';
import type { Provider } from '@/shared/types/provider.types';

import type { ParentView } from './types';

type TaskListContext = {
  loggedInProvider: Provider;
  parentView: ParentView;
  taskHubOverlayState: Nullable<OverlayTriggerState>;
};

export const [useTaskListContext, TaskListContextProvider] =
  createCtx<TaskListContext>('TaskListContext');
