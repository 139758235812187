import type { Props as AccordionProps } from '@/shared/common/Accordion';
import { Accordion } from '@/shared/common/Accordion';

import type { SectionProps } from '../Section';
import { sectionTitle } from '../Section/section.css';
import { CardSection } from './CardSection';

type Props = SectionProps &
  Omit<
    AccordionProps,
    'header' | 'shouldUnmountChildren' | 'clickableHeader' | 'classes'
  >;

export function CollapsibleCardSection({
  children,
  className,
  title,
  classes,
  ...rest
}: Props) {
  return (
    <CardSection classes={{ container: className, ...classes }}>
      <Accordion
        header={title}
        shouldUnmountChildren={false}
        clickableHeader
        classes={{ headerText: sectionTitle }}
        {...rest}
      >
        {children}
      </Accordion>
    </CardSection>
  );
}
