import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Header, Page } from '@/shared/common/Page';
import { usePatientDetails } from '@/shared/hooks/queries';

import { PatientProfileActionButtonGroup } from './PatientProfileActionButtonGroup';
import { v2headerContainer } from './PatientProfileHeader.css';
import { PatientProfileHeaderTags } from './PatientProfileHeader/PatientProfileHeaderTags';

type Props = {
  patientId: string;
  children: ReactNode;
};
export function PatientProfileHeader({ patientId, children }: Props) {
  const intl = useIntl();

  const { data } = usePatientDetails(patientId, true);
  const patient = data?.patient;

  return (
    <Page.Header>
      <>
        <Header.ActionStrip
          className={v2headerContainer}
          backNavigation={{
            link: '/patients',
            label: intl.formatMessage({ defaultMessage: 'Patients' }),
          }}
          scrollTitle={
            patient && `${patient?.givenName} ${patient?.familyName}`
          }
          right={
            <>
              <PatientProfileHeaderTags />
              <PatientProfileActionButtonGroup />
            </>
          }
        />
        {children}
      </>
    </Page.Header>
  );
}
