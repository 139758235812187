import pick from 'lodash/pick';

import type {
  Medication,
  MedicationChange,
  RxNorm,
} from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
  RxNormDeliveryMechanism,
} from '@/shared/generated/grpcGateway/medication.pb';

export enum DoseType {
  PILL = 'pill',
  LIQUID = 'liquid',
}

export function isStructured(medChange: MedicationChange) {
  return medChange.doseQuantities?.length && medChange.frequencies?.length;
}

export function getSortedMedChanges(
  medChanges: MedicationChange[],
): MedicationChange[] {
  return [...medChanges]?.sort((a, b) => {
    const emrDateA = emrDate(a);
    const emrDateB = emrDate(b);
    if (emrDateA && emrDateB) {
      return emrDateA < emrDateB ? 1 : -1;
    }

    return compareDate(a.updatedDate, b.updatedDate);
  });
}

export function compareDate(a?: string, b?: string) {
  if (a === b) {
    return 0;
  }
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  // Compare dates directly instead of converting to Date objects so we have precision to the microsecond
  return a < b ? 1 : -1;
}

export function sortName(medChange: MedicationChange) {
  return (
    medChange.rxnorm?.components?.[0]?.ingredient || medChange.medicationName
  );
}

export function emrDate(medChange: MedicationChange) {
  if (medChange.changeType !== MedicationChangeChangeType.EMR_CAPTURE) {
    return null;
  }
  const emrDates = [
    medChange.ehrMedication?.startedAt,
    medChange.ehrMedication?.orderedAt,
    medChange.ehrMedication?.filledAt,
  ].filter((date) => date);

  if (!emrDates.length) {
    return null;
  }

  return emrDates
    .map((date) => new Date(date as string))
    .reduce((d1, d2) => (d1 > d2 ? d1 : d2));
}

export function lastChange(medChanges?: MedicationChange[]) {
  if (!medChanges?.length) {
    return undefined;
  }
  const sortedMedChanges = getSortedMedChanges(medChanges);
  return sortedMedChanges[0];
}

export function previousChange(medChanges?: MedicationChange[]) {
  if (!medChanges?.length || medChanges.length < 2) {
    return undefined;
  }
  const sortedMedChanges = getSortedMedChanges(medChanges);
  return sortedMedChanges[1];
}

// Filters out titration changes made in the current note
export function lastMedReviewChange(
  currNoteId: number,
  medChanges?: MedicationChange[],
) {
  const medReviewChanges = medChanges?.filter(
    ({ changeType, noteId }) =>
      !(
        noteId === currNoteId &&
        changeType &&
        [
          MedicationChangeChangeType.START,
          MedicationChangeChangeType.STOP,
          MedicationChangeChangeType.TITRATION,
        ].includes(changeType)
      ),
  );
  return lastChange(medReviewChanges);
}

export function lastStructuredChange(med: Medication) {
  let latestMedChange = lastChange(med.medChanges);
  if (!latestMedChange) {
    return null;
  }

  // If stop titration, show med info from previous structured change
  if (latestMedChange.changeType === MedicationChangeChangeType.STOP) {
    const structuredMedChanges = med.medChanges?.filter(isStructured);
    const latestStructuredChange = lastChange(structuredMedChanges);

    if (latestStructuredChange) {
      latestMedChange = {
        ...latestMedChange,
        ...pick(latestStructuredChange, [
          'rxnorm',
          'frequencies',
          'doseQuantities',
        ]),
      };
    }
  }

  return latestMedChange;
}

export function hasMultipleActiveDoses(med: Maybe<Medication>) {
  if (!med?.medChanges || med.medChanges.length < 2) {
    return false;
  }

  // get last 2 medication changes and see if they are both Needs Review EMR Captures
  const last2MedChanges = med.medChanges.slice(-2);
  return last2MedChanges.every(
    (medChange) =>
      medChange.changeType === MedicationChangeChangeType.EMR_CAPTURE &&
      medChange.status === MedicationChangeStatus.NEEDS_REVIEW,
  );
}

export function hasActiveEhrMedication(
  med: Maybe<Medication>,
  noteId: Maybe<number>,
) {
  // We want to ignore non disease specific or unreferenced meds
  if (!med || !med.isDiseaseSpecific || !med.medChanges) {
    return true;
  }

  // ignore medications that are inactive
  const latestChange = lastChange(med.medChanges);
  if (
    latestChange &&
    latestChange?.status === MedicationChangeStatus.INACTIVE
  ) {
    return true;
  }

  // if there are any med changes during this encounter, do not show icon
  const noteMedChanges = med.medChanges.filter(
    (mc) => noteId && mc.noteId === noteId,
  );
  if (noteMedChanges.length > 0) {
    return true;
  }

  // otherwise check that at least 1 entry has an ehr medication

  return (
    med.medChanges
      .map((mc) => mc.ehrMedication)
      .filter((ehrMed) => ehrMed && ehrMed?.name !== '' && ehrMed?.active)
      .length > 0
  );
}

export function getDoseType(rxNorm: Maybe<RxNorm>) {
  if (!rxNorm || rxNorm.id === '0') {
    return DoseType.PILL;
  }
  switch (rxNorm.deliveryMechanism) {
    case RxNormDeliveryMechanism.CAPSULE:
      return DoseType.PILL;
    case RxNormDeliveryMechanism.TABLET:
      return DoseType.PILL;
    case RxNormDeliveryMechanism.INJECTION:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.TOPICAL_OINTMENT:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.TRANSDERMAL_PATCH:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.SUBCUTANEOUS_SOLUTION:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.SUBCUTANEOUS_PEN_INJECTOR:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.PEN_INJECTOR:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.INTRAVENOUS_SOLUTION:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.ORAL_SOLUTION:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.AUTO_INJECTOR:
      return DoseType.LIQUID;
    case RxNormDeliveryMechanism.CARTRIDGE:
      return DoseType.LIQUID;
    default:
      return DoseType.PILL;
  }
}

export function isTitrationChange(changeType?: MedicationChangeChangeType) {
  return (
    changeType &&
    [
      MedicationChangeChangeType.START,
      MedicationChangeChangeType.STOP,
      MedicationChangeChangeType.TITRATION,
    ].includes(changeType)
  );
}

export function isUnreferenced(rxnorm: Maybe<RxNorm>) {
  return rxnorm?.id === undefined || rxnorm?.id === '0';
}

export function medHasNeverBeenReviewedOrTitrated(medication: Medication) {
  const { medChanges } = medication;
  if (!medChanges?.length) {
    return true;
  }
  // Medication is considered to have never been reviewed / titrated if
  // the only med change entries are EMR Capture Changes with Needs review status
  return medChanges.every(
    ({ changeType, status }) =>
      changeType === MedicationChangeChangeType.EMR_CAPTURE &&
      status === MedicationChangeStatus.NEEDS_REVIEW,
  );
}
