import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Modal } from '@/shared/common/Modal';
import { NativeLink } from '@/shared/tempo/atom/Link/NativeLink';

import { BASE_SUPPORT_MODAL_ROUTE, useCategories } from '../useCategories';
import { categoryList, itemContainer } from './page.css';

export function Categories() {
  const categories = useCategories();

  return (
    <Modal.Body>
      <div className={categoryList.list}>
        {categories.map(({ description, to, label, icon }) => (
          <LinkWrapper link={to}>
            <div className={categoryList.item}>
              {icon}
              <div>
                <div className={categoryList.title}>{label}</div>
                <div className={categoryList.description}>{description}</div>
              </div>
            </div>
          </LinkWrapper>
        ))}
      </div>
    </Modal.Body>
  );
}

function LinkWrapper({
  link,
  children,
}: {
  link: string;
  children: ReactNode;
}) {
  // Use react router for support modal SPA links
  if (link.startsWith(BASE_SUPPORT_MODAL_ROUTE)) {
    return (
      <Link to={link} key={link} className={itemContainer}>
        {children}
      </Link>
    );
  }
  // Other links are opened in a new tab
  return (
    <NativeLink
      href={link}
      key={link}
      className={itemContainer}
      target="_blank"
    >
      {children}
    </NativeLink>
  );
}
