import { CHF_VISIT_SECTIONS } from '../../metadata';
import { SharedDeviceUsage } from '../../shared/ProductSupport';
import { chfVisitPath } from '../paths';

export function DeviceUsage() {
  return (
    <SharedDeviceUsage
      sections={CHF_VISIT_SECTIONS}
      highUsagePath={chfVisitPath('/med-review', '/index')}
      lowUsagePath={chfVisitPath('/product-support', '/low-device-usage')}
    />
  );
}
