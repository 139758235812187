import { useIntl } from 'react-intl';

import { UserAvatar } from '@/shared/common/UserAvatar';
import type { TaskQueue } from '@/shared/generated/grpcGateway/task.pb';

import {
  queueDetails,
  teamQueueHeader,
  teamQueueSection,
} from './TaskModal.css';

type Props = {
  queue: TaskQueue;
};

export function TeamQueue({ queue }: Props) {
  const intl = useIntl();
  const nameSplit = queue.queueName?.split(' ');

  return (
    <div className={teamQueueSection}>
      <div className={teamQueueHeader}>
        {intl.formatMessage({ defaultMessage: 'Team Queues' })}
      </div>
      <div className={queueDetails}>
        {nameSplit && (
          <UserAvatar
            size="small"
            firstName={nameSplit[0]}
            // if we have a single word for the queue name, this is undefined
            // so we need to provide something for UserAvatar to pretend is
            // a last name
            lastName={nameSplit[1] ?? ''}
          />
        )}
        {queue.queueName}
      </div>
    </div>
  );
}
