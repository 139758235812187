import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LabelledCheckbox } from '@/shared/common/LabelledCheckbox';
import { usePrevious } from '@/shared/hooks';
import { usePatchPatient } from '@/shared/hooks/queries';
import type { Patient } from '@/shared/types/patient.types';
import type { PatientNotificationSettings } from '@/shared/types/shared.types';

export type Props = {
  patient: Patient;
  patientNotifications: PatientNotificationSettings;
  customClass?: string;
};

export function AppointmentsReminders({
  patient,
  patientNotifications,
  customClass,
}: Props) {
  const patchPatient = usePatchPatient(patient.id || '');

  const [isChecked, setIsChecked] = useState(
    patientNotifications.appointments_reminder,
  );
  const previousIsChecked = usePrevious(isChecked);

  useEffect(() => {
    setIsChecked(Boolean(patientNotifications?.appointments_reminder));
  }, [patientNotifications]);

  const handleAppointmentsRemindersToggle = (checked: boolean) => {
    // optimistic update
    setIsChecked(checked);
    if (checked !== previousIsChecked) {
      patchPatient.mutate(
        {
          notification_settings: {
            ...patientNotifications,
            appointments_reminder: checked,
          },
        },
        {
          onError: () => {
            // roll back the optimistic update
            setIsChecked(!checked);
          },
        },
      );
    }
  };

  return (
    <>
      <LabelledCheckbox
        label={<FormattedMessage defaultMessage="SMS appointment reminders" />}
        customClassName={customClass}
        id="appointments-reminders-checkbox"
        checked={isChecked ?? false}
        disabled={patchPatient.isLoading}
        onChange={(e) => handleAppointmentsRemindersToggle(e.target.checked)}
        fontSize="16px"
        customStyles={{
          width: 'fit-content',
          marginLeft: '-6px',
        }}
        customLabelStyles={{ fontWeight: 400 }}
      />
    </>
  );
}
