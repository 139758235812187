import cx from 'classnames';

import { circular } from '@/shared/jsStyle/utils.css';
import type { TooltipProps } from '@/shared/tempo/atom/Tooltip';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

type Props = TooltipProps;

export function IconButtonTooltip({ children, classes, ...props }: Props) {
  return (
    <Tooltip
      {...props}
      classes={{
        ...classes,
        triggerContainer: cx(circular, classes?.triggerContainer),
      }}
    >
      {children}
    </Tooltip>
  );
}
