import { useQuery } from 'react-query';

import { CACHE_TIME } from '@/reactQuery';

type PresignedDocument = {
  presigned_s3_url: string;
};

export function usePresignedDocumentUrl(
  s3Url: string,
  config?: {
    enabled?: boolean;
    onSuccess?: (data: PresignedDocument) => void;
    onError?: (err: unknown) => void;
  },
) {
  const encodedUrl = encodeURIComponent(s3Url);
  return useQuery<PresignedDocument>(`/vision/api/v1/document/${encodedUrl}`, {
    ...config,
    // Since presigned urls are meant to expire quickly, we don't want to cache this request
    cacheTime: CACHE_TIME.NONE,
  });
}
