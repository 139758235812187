import type { IntlShape } from 'react-intl';

import { getSectionProgressMap } from '@/shared/common/Wizard/ProgressBar/useSectionProgressMap';
import type { SectionStepsState } from '@/shared/common/Wizard/state';

import type { CNSectionMap } from '../../../CNWizardForm/sections/metadata';
import { getEndCallReasonI18n } from '../../../CNWizardForm/sections/shared/EndCall/endCallReasonI18n';
import {
  type EndCallFormFields,
  EndCallReason,
} from '../../../CNWizardForm/sections/shared/EndCall/formConfig';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';
import { sectionHasData } from '../util';

type Params = {
  intl: IntlShape;
  endCallData: Maybe<EndCallFormFields>;
  sectionData: SectionStepsState;
  sections: CNSectionMap;
};

export function cnEarlyEndCall({
  intl,
  endCallData,
  sectionData,
  sections,
}: Params) {
  if (!sectionHasData(endCallData)) {
    return '';
  }

  const md = new MarkdownBuilder();

  const endCallI18n = getEndCallReasonI18n(intl);
  md.newline();
  md.p('Call was ended prematurely');
  md.field(
    'Reason',
    endCallData?.endCallReason ? endCallI18n[endCallData.endCallReason] : null,
  );

  if (endCallData?.endCallReason === EndCallReason.TimeConstraints) {
    const progressMap = getSectionProgressMap(sections, sectionData);
    const sectionNames = getSectionNames();
    const incompleteSections = Object.keys(progressMap).reduce((acc, key) => {
      const name = sectionNames[key];

      if (progressMap[key] || !name) {
        return acc;
      }

      return [...acc, name];
    }, [] as string[]);

    if (incompleteSections.length) {
      const listFormatter = new Intl.ListFormat('en', {
        style: 'long',
        type: 'disjunction',
      });

      md.newline();
      md.p(
        `Was not able to assess ${listFormatter.format(incompleteSections)}.`,
      );
    }
  }

  if (endCallData?.endCallNotes) {
    md.newline();
    md.p(endCallData.endCallNotes);
  }

  return md.toString();
}

function getSectionNames(): Record<string, string> {
  return {
    '/symptoms': 'symptoms',
    '/product-support': 'product support',
    '/med-review': 'med review',
    '/general-assessment': 'general assessment',
    '/action-plan': 'action plan',
    '/emergency-visits': 'emergency visits',
  };
}
