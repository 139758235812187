type NoteLike = {
  clinical_navigator_note?: Array<Record<string, unknown>>;
};

export function isCnNote(note: NoteLike) {
  if (note.clinical_navigator_note?.[0]) {
    return true;
  }
  return false;
}
