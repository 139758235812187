import type { ReactNode } from 'react';

import { subHeader } from './SupportModal.css';

type Props = {
  children: ReactNode;
};

export function SubHeader({ children }: Props) {
  return <div className={subHeader}>{children}</div>;
}
