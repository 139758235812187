import { useWizardStateContext } from '@/shared/common/Wizard/state';

import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { SharedReviewActionSteps } from '../../shared/ReviewActionSteps';
import type { TActionStep } from '../../shared/SetActionSteps';
import { titrationOutreachPath } from '../paths';
import { usePatientGoalText } from './usePatientGoal';

export function ReviewActionSteps() {
  const patientGoalText = usePatientGoalText();
  const {
    wizardFormData: { data: stepData },
  } = useWizardStateContext();
  const actionSteps = (stepData[
    titrationOutreachPath('/action-plan', '/set-action-steps')
  ]?.values?.actionSteps || []) as TActionStep[];

  return (
    <SharedReviewActionSteps
      sections={TITRATION_OUTREACH_SECTIONS}
      nextPath={titrationOutreachPath('/scheduling', '/index')}
      actionSteps={actionSteps}
      patientGoalText={patientGoalText}
    />
  );
}
