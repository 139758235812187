import cx from 'classnames';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import { MedicationChangeStatus } from '@/shared/generated/grpcGateway/medication.pb';

import { previewListItem } from '../patientDetails/ui/Notes/NotePreview/NotePreview.css';
import { medInfoItem, medNameText } from './styles.css';
import { unstructuredMedInfo } from './unstructuredMedInfo';
import { isStructured, isUnreferenced } from './utils/medChangeUtils';
import { getDoseStr, getRxNormStr } from './utils/medInfoUtils';

export function MedInfo({
  medChange,
  isPreview = false,
  noteId,
}: {
  medChange: MedicationChange;
  isPreview?: boolean;
  noteId?: number;
}) {
  const intl = useIntl();

  const {
    rxnorm,
    medicationName,
    doseQuantities,
    frequencies,
    ehrMedication,
    status,
    reason,
  } = medChange;

  const isInactive = status === MedicationChangeStatus.INACTIVE;

  let content = isStructured(medChange) ? (
    <>
      {isUnreferenced(rxnorm) && (
        <MedInfoItem
          className={medNameText}
          isInactive={isInactive}
          isPreview={isPreview}
        >
          {medicationName}
        </MedInfoItem>
      )}
      {rxnorm && !isUnreferenced(rxnorm) && (
        <MedInfoItem isInactive={isInactive} isPreview={isPreview}>
          {getRxNormStr(rxnorm, intl)},
        </MedInfoItem>
      )}
      <MedInfoItem isInactive={isInactive} isPreview={isPreview}>
        {getDoseStr(intl, doseQuantities, frequencies, rxnorm)}
      </MedInfoItem>
    </>
  ) : (
    <MedInfoItem
      isInactive={isInactive}
      isPreview={isPreview}
      isStructured={false}
    >
      {unstructuredMedInfo(ehrMedication)}
    </MedInfoItem>
  );

  if (!isPreview) {
    return <div>{content}</div>;
  }

  // TODO: Remove !isPreview check once strikethrough is supported in EMR note publishing
  if (isInactive) {
    content = (
      <>
        [{intl.formatMessage({ defaultMessage: 'Not active: ' })}
        {content}]
      </>
    );
  }

  return (
    <li className={previewListItem}>
      {content}
      {reason && medChange.noteId === noteId && (
        <>
          <br />
          {reason}
        </>
      )}
    </li>
  );
}

function MedInfoItem({
  isInactive = false,
  isStructured: isStructuredProp = true,
  children,
  className,
  isPreview,
}: {
  isInactive?: boolean;
  isStructured?: boolean;
  children: ReactNode;
  className?: string;
  isPreview: boolean;
}) {
  const cn = cx({ [medInfoItem]: isStructuredProp }, className);

  // TODO: Remove !isPreview check once strikethrough is supported in EMR note publishing
  if (isInactive && !isPreview) {
    // Use del element so that can be detected as strikethrough content when translating to markdown
    return <del className={cn}>{children}</del>;
  }
  return <span className={cn}>{children}</span>;
}
