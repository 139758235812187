import classnames from 'classnames/bind';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useOnClickOutside } from '@/shared/hooks';

import styles from '../styles.module.scss';
import { useSidePanelCtx } from './SidePanelContext';
import type { SidePanelVariant } from './sidePanel.types';
import { SidePanelType } from './sidePanel.types';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
  autoDismiss?: boolean;
  initialIsVisible?: boolean;
  variant?: SidePanelVariant;
};

export function SidePanel({
  children,
  variant = { type: SidePanelType.Normal },
  autoDismiss = false,
  initialIsVisible = false,
}: Props) {
  const { state, handlers } = useSidePanelCtx();

  const { isExpanded, isVisible, isFullscreen, whitelistElements } = state;
  const { setIsVisible, setType } = handlers;

  // Set initial state from props
  useEffect(() => {
    setIsVisible(initialIsVisible);
    setType(variant.type);
  }, [setIsVisible, initialIsVisible, setType, variant.type]);

  const isDockable = variant.type === SidePanelType.Dockable;

  const onClickOutsideNode = useOnClickOutside<HTMLDivElement>(
    () => setIsVisible(false),
    {
      listening: autoDismiss && isVisible,
      whitelistElements,
    },
  );

  return (
    <>
      {isVisible && (
        <div
          ref={onClickOutsideNode}
          className={cx('side-panel', {
            fullscreen: isFullscreen,
            expanded: !isFullscreen && isExpanded,
            docked: isDockable && !isVisible,
          })}
        >
          {children}
        </div>
      )}
      {isDockable && !isVisible && (
        <button
          className={cx('docked-panel-button')}
          type="button"
          onClick={() => setIsVisible(true)}
        >
          {variant.dockTabTitle}
        </button>
      )}
    </>
  );
}
