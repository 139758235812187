import cx from 'classnames';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import type {
  ChangeEvent,
  ForwardedRef,
  HTMLAttributes,
  JSXElementConstructor,
} from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import type { FilterOptionsState, PopperProps } from '@/deprecated/mui';
import {
  Autocomplete,
  IconButton,
  TextField,
  createFilterOptions,
} from '@/deprecated/mui';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import SearchIcon from '@/shared/assets/svgs/search.svg?react';
import { brandColor } from '@/shared/jsStyle/utils.css';
import { color, typography } from '@/shared/tempo/theme';

import { addInputClassname, addInputWrapperClassname } from '../Input';
import { ExtraWidePopper, WidePopper } from './Popper';
import { ResultTable } from './results/ResultTable';
import {
  disabledColor,
  resultContainer,
  tableContainer,
} from './results/results.css';
import { autoCompletePopper, searchIcon } from './styles.css';
import { AdornmentPosition, InputSize } from './types';

const DEBOUNCE_TIME_MS = 500;

export const MIN_SEARCH_LENGTH = 3;

enum AdornmentType {
  Mixed,
  Close,
  Search,
}

export type TypeaheadSearchProps<T> = {
  options: Maybe<T[]>;
  isLoading: boolean;
  noOptionsText: string;
  placeholder: string;
  OptionComponent: JSXElementConstructor<
    HTMLAttributes<HTMLTableRowElement> & { option: T; optionLabel: string }
  >;
  ResultContainer: JSXElementConstructor<HTMLAttributes<HTMLElement>>;
  PopperComponent?: JSXElementConstructor<PopperProps>;
  fullWidth?: boolean;
  autoFocus?: boolean;
  initialValue?: string;
  closeOnSearch?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  freeSolo?: boolean;
  minSearchLength?: number;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  getOptionLabel: (optionOrString: T | string) => string;
  adornmentPosition?: AdornmentPosition;
  inputSize?: InputSize;
  resetValueOnBlur?: boolean;
  onSelect: (selected: T) => void;
  onSearchTermChange: (searchTerm: string) => void;
  onSearch?: (searchTerm: string | null) => void;
  onReset?: () => void;
  onBlur?: () => void;
  onClose?: () => void;
  // Quick prop to turn on attempted Tempo styles to this component. It should really be rewritten without MUI :)
  hasTempoStyles?: boolean;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
};

function makeListbox(
  ResultContainer: JSXElementConstructor<HTMLAttributes<HTMLElement>>,
) {
  return forwardRef(
    (
      { children, className, ...props }: HTMLAttributes<HTMLDivElement>,
      ref: ForwardedRef<HTMLDivElement>,
    ) => (
      <div {...props} ref={ref} className={cx(className, resultContainer)}>
        <div className={tableContainer}>
          <ResultTable>
            <ResultContainer>{children}</ResultContainer>
          </ResultTable>
        </div>
      </div>
    ),
  );
}

/**
 * @param noOptionsText - Pass i18n text to be shown when there are zero options. It's shown only when freeSolo is false.
 */
export function TypeaheadSearch<T>({
  options,
  isLoading,
  noOptionsText,
  placeholder,
  OptionComponent,
  ResultContainer,
  PopperComponent = options ? ExtraWidePopper : WidePopper,
  fullWidth = false,
  autoFocus = false,
  initialValue = '',
  closeOnSearch = true,
  disabled = false,
  hasError = false,
  freeSolo = true,
  minSearchLength = MIN_SEARCH_LENGTH,
  isOptionEqualToValue,
  getOptionLabel,
  adornmentPosition = AdornmentPosition.End,
  inputSize = InputSize.Small,
  resetValueOnBlur = false,
  onSelect,
  onSearch,
  onSearchTermChange,
  onReset,
  onBlur,
  onClose,
  hasTempoStyles,
  filterOptions,
}: TypeaheadSearchProps<T>) {
  const hackToResetAutocomplete = useRef(performance.now());
  const [open, setOpen] = useState(false);

  // liveValue is used to control the input so that we can manipulate it
  // whenever we need to
  const [liveValue, setLiveValue] = useState(initialValue);

  const debouncedSetSearchTerm = useMemo(
    () => debounce(onSearchTermChange, DEBOUNCE_TIME_MS),
    [onSearchTermChange],
  );

  const reset = useCallback(() => {
    debouncedSetSearchTerm.cancel();
    setOpen(false);
    setLiveValue('');
    hackToResetAutocomplete.current = performance.now();
    onReset?.();
  }, [debouncedSetSearchTerm, onReset]);

  useEffect(() => {
    if (disabled) {
      reset();
    }
  }, [disabled, reset]);

  return (
    <Autocomplete
      // mui autocomplete maintains some internal state that is impossible to reset
      // programmatically, so in order to reset that state we need to force a re-render.
      // we do that by setting the ref to a new value every time we reset the autocomplete.
      //
      // gross.
      key={hackToResetAutocomplete.current}
      classes={
        hasTempoStyles
          ? {
              popper: autoCompletePopper,
            }
          : undefined
      }
      style={{ width: fullWidth ? 'unset' : '370px' }}
      options={options || []}
      loading={isLoading}
      popupIcon={<SearchIcon />}
      ListboxComponent={makeListbox(ResultContainer)}
      PopperComponent={PopperComponent}
      disableCloseOnSelect={false}
      open={open}
      disabled={disabled}
      // tells mui to trim the input value when deciding if it should render an option or not
      filterOptions={filterOptions || createFilterOptions({ trim: true })}
      // onOpen is called when clicking out of the input and then back to reopen it
      onOpen={(e) => {
        // Using event target value as liveValue is out of date in this callback
        if (
          (e as ChangeEvent<HTMLInputElement>).target.value.length >=
          minSearchLength
        ) {
          setOpen(true);
        }
      }}
      // onClose is called when pressing an enter in input while list box is open
      onClose={() => {
        if (closeOnSearch || !liveValue) {
          setOpen(false);
        }
      }}
      // freeSolo is required for us to treat this like a search box, where pressing
      // enter "selects" a value even if it's not one from the list
      freeSolo={freeSolo}
      // we handle clearing on our own so we can have more granular control over
      // what gets updated and how
      disableClearable
      sx={{
        // mui applies a rotate(180deg) transform to the popupIcon which makes
        // sense in the context of a caret opening/closing, but makes slightly
        // less sense when your magnifying glass is flipping over and looking
        // weird. so, just turning it off.
        '.MuiAutocomplete-popupIndicator': {
          transform: 'none',
        },
        '.MuiTextField-root .Mui-disabled': {
          background: color.Theme.Light['Base Background Alt'],
          color: color.Theme.Light['Base Font Subtle'],
          WebkitTextFillColor: color.Theme.Light['Base Font Subtle'],
        },
        // override mui adding an insane amount of padding-right when freeSolo
        // has been disabled
        '.MuiInputBase-adornedEnd.MuiInputBase-root': {
          paddingRight: '9px',
        },
        '.MuiAutocomplete-inputRoot': {
          background: color.Palette.Neutral.White,
          paddingTop: 0,
          paddingBottom: 0,
          ...(adornmentPosition !== AdornmentPosition.StartAndEnd && {
            paddingLeft: 0,
          }),
        },
      }}
      loadingText={<FormattedMessage defaultMessage="Searching..." />}
      noOptionsText={noOptionsText}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={
            hasTempoStyles
              ? {
                  '.MuiAutocomplete-popper': {
                    zIndex: 10000,
                  },
                }
              : {
                  '.MuiAutocomplete-input':
                    inputSize === InputSize.Small
                      ? typography.Body.Default
                      : { ...typography.Body.Lead, height: '27px' },
                }
          }
          hiddenLabel
          autoFocus={autoFocus}
          variant="outlined"
          placeholder={placeholder}
          error={hasError}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              ...addInputClassname(params.inputProps),
            },
            ...(adornmentPosition === AdornmentPosition.StartAndEnd && {
              startAdornment: (
                <Adornment
                  hasTempoStyles={hasTempoStyles}
                  disabled={disabled}
                  adornmentType={AdornmentType.Search}
                />
              ),
            }),
            endAdornment: (
              <Adornment
                hasTempoStyles={hasTempoStyles}
                hasLiveValue={Boolean(liveValue)}
                disabled={disabled}
                onClose={onClose}
                reset={reset}
                adornmentType={
                  adornmentPosition === AdornmentPosition.End
                    ? AdornmentType.Mixed
                    : AdornmentType.Close
                }
              />
            ),
          }}
          className={addInputWrapperClassname(disabled, false, hasError)}
        />
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(props, option) => (
        <OptionComponent
          // mui has unnecessarily strict typing for the `props` argument here,
          // expecting that all usages will use an <li> element. docs, however,
          // indicate that it can be any ReactNode, so i feel safe force casting
          // to make typescript happy here.
          {...(props as unknown as HTMLAttributes<HTMLTableRowElement>)}
          option={option}
          optionLabel={
            // See RxNormTypeahead getOptionLabel function for why this title hack is being used
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            hasTempoStyles && (option as any).title
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (option as any).title
              : getOptionLabel(option)
          }
        />
      )}
      getOptionLabel={getOptionLabel}
      inputValue={liveValue}
      onChange={(_, optionOrString) => {
        if (isNull(optionOrString) || isString(optionOrString)) {
          onSearch?.(optionOrString);
          return;
        }

        onSelect(optionOrString);
      }}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setLiveValue(value);
          // If we no longer have a value, trigger close
          if (!value) {
            onClose?.();
          }
        }

        // "reset" is triggered when a user selects
        // a value from the dropdown. we don't need
        // to trigger another update in this scenario.
        if (reason === 'reset' || !value) {
          reset();
          return;
        }

        if (value.length < minSearchLength) {
          setOpen(false);
        } else if (value.length >= minSearchLength) {
          setOpen(true);
        }

        debouncedSetSearchTerm(value);
      }}
      onBlur={() => {
        setOpen(false);

        if (resetValueOnBlur) {
          setLiveValue('');
        }

        if (onBlur) {
          onBlur();
        }
      }}
    />
  );
}

function Adornment({
  disabled,
  hasLiveValue = false,
  reset = noop,
  onClose = noop,
  adornmentType,
  hasTempoStyles,
}: {
  disabled: boolean;
  hasLiveValue?: boolean;
  reset?: () => void;
  onClose?: () => void;
  adornmentType: AdornmentType;
  hasTempoStyles?: boolean;
}) {
  const isCloseIconToBeShown =
    (adornmentType === AdornmentType.Mixed && hasLiveValue) ||
    adornmentType === AdornmentType.Close;

  if (isCloseIconToBeShown && !hasLiveValue) {
    return null;
  }

  return isCloseIconToBeShown ? (
    <IconButton
      disabled={disabled}
      onClick={() => {
        if (!hasLiveValue) {
          return;
        }

        reset();

        if (onClose) {
          onClose();
        }
      }}
      size="small"
    >
      <CloseIcon width="15px" />
    </IconButton>
  ) : (
    <SearchIcon
      className={cx(disabled ? disabledColor : brandColor, {
        [searchIcon]: hasTempoStyles,
      })}
    />
  );
}
