import type {
  QueryParamsFilterLookup,
  QueryParamsOrderByLookup,
} from '../patients-grpc';
import { OpType } from '../patients-grpc';

export const CALL_QUERY_PARAMS_FILTER_LOOKUP: QueryParamsFilterLookup = {
  patientId: {
    renames: ['patientId'],
    op: OpType.EQUAL,
  },
  caregiverId: {
    renames: ['caregiverId'],
    op: OpType.EQUAL,
  },
  state: {
    renames: ['state'],
    op: OpType.EQUAL,
  },
  noteInfo: {
    nativeFilterGetter: ({ noteId }) => {
      if (noteId) {
        return `noteId = "${noteId}"`;
      }
      return '';
    },
  },
  user: {
    nativeFilterGetter: ({ patientId, caregiverId }) => {
      if (caregiverId) {
        return `caregiverId = "${caregiverId}"`;
      }
      if (patientId) {
        return `patientId = "${patientId}"`;
      }
      return '';
    },
  },
};

export const CALL_QUERY_PARAMS_ORDER_BY_LOOKUP: QueryParamsOrderByLookup = {
  startTime: {
    rename: 'start_time',
    default: true,
  },
  type: {},
};
