import { min } from 'date-fns';

import type { Patient } from '@/shared/types/patient.types';
import { parseISODateInUTC } from '@/shared/utils/time-helpers';

export function getPatientEnrollmentDate({
  rpm_consent_date,
  ccm_consent_date,
}: Partial<Patient> = {}) {
  const rpmConsentDate = rpm_consent_date
    ? // Discarding time part of rpm_consent_date as it's a remnant of
      // the browser timezone on rpm consent date change in Patient Admin
      parseISODateInUTC(rpm_consent_date.split('T')[0])
    : null;
  const ccmConsentDate = ccm_consent_date
    ? parseISODateInUTC(ccm_consent_date)
    : null;
  if (!rpmConsentDate) {
    return ccmConsentDate;
  }
  if (!ccmConsentDate) {
    return rpmConsentDate;
  }

  return min([rpmConsentDate, ccmConsentDate]);
}
