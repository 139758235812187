import { TwilioFlexNoteButton } from '@/pages/patients/patientDetails/ui/Notes/TwilioFlex';
import { ZendeskNoteButton } from '@/pages/patients/patientDetails/ui/Notes/Zendesk';
import { useFlags } from '@/shared/hooks';

import { useCNFormContext } from '../../CNFormContext';

export function CallButton() {
  const { showTwilioFlexModal, useTwilioForCalls } = useFlags();
  const { patientId, noteId, zendeskTicketId, contacts, caregivers } =
    useCNFormContext();

  if (showTwilioFlexModal && useTwilioForCalls) {
    return (
      <TwilioFlexNoteButton
        patientId={patientId}
        variant="text"
        noteId={noteId}
        caregivers={caregivers}
        contacts={contacts}
      />
    );
  }

  return (
    <ZendeskNoteButton
      variant="text"
      patientId={patientId}
      zendeskTicket={zendeskTicketId}
      noteId={noteId}
    />
  );
}
