import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../../metadata';
import { DailyLivingForm } from '../../shared/generalAssessment';
import type { DailyLivingFormFields } from '../../shared/generalAssessment/formConfig';
import { getDailyLivingFormConfig } from '../../shared/generalAssessment/formConfig';
import { cycle17InitVisitPath } from '../paths';

export function DailyLiving() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<DailyLivingFormFields>(
    cycle17InitVisitPath('/general-assessment', '/daily-living'),
    getDailyLivingFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({
        defaultMessage: 'Activities of Daily Living',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
    >
      <FieldGroup>
        <DailyLivingForm
          form={form}
          requiresAssistanceLabel={
            <Script>
              <FormattedMessage defaultMessage="Thank you! And lastly, I want to ask you a few specific questions about mobility. Do you require any assistance with walking, eating, using the bathroom, or getting dressed?" />
            </Script>
          }
          classes={{ requiresAssistanceLabel: scriptLabel }}
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
