import { parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useApproveCarePlan } from '@/shared/hooks/queries/carePlan.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { dateToGrpcDate } from '@/shared/utils/grpc';
import { getUserTimezone } from '@/shared/utils/time-helpers';

import { formContainer } from './ApproveModal.css';
import { getFormConfig } from './formConfig';

type Props = {
  patientId: string;
  carePlanName: string;
  onClose: () => void;
};

export function ApproveModal({ patientId, carePlanName, onClose }: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId ?? '',
    true,
    !!patientId,
  );
  const { mutate: approveCarePlan, isLoading } = useApproveCarePlan({
    onSuccess: onClose,
    onError: () => {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to mark care plan as approved',
        }),
      );
    },
  });

  const ccmConsentDate = patient?.patient?.ccmConsentDate
    ? parseISO(patient.patient.ccmConsentDate)
    : undefined;
  const consentTimezone = patient?.patient?.timeZone || getUserTimezone();
  const zonedDate = ccmConsentDate
    ? utcToZonedTime(ccmConsentDate, consentTimezone)
    : undefined;

  const form = useFormFromConfig({
    ...getFormConfig(intl, zonedDate),
    triggerReset: isLoadingPatient,
  });

  function onSave() {
    const { approvalDate, approvalTime } = form.getValues();
    const [hours, minutes] = approvalTime.split(':');

    const approvalDateTime: GoogleDateTime = {
      ...dateToGrpcDate(approvalDate),
      hours: parseInt(hours, 10),
      minutes: parseInt(minutes, 10),
      seconds: 0,
      nanos: 0,
      timeZone: { id: getUserTimezone() },
    };

    approveCarePlan({
      name: carePlanName,
      approvalDate: approvalDateTime,
    });
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={
          <FormattedMessage defaultMessage="Confirm that the provider has approved this care plan" />
        }
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="Enter the date the referring provider signed this care plan. This will confirm that the provider approves this care plan and commit it as the current active care plan for this patient." />
        <div className={formContainer}>
          <Form form={form}>
            <Form.Row>
              <Form.DatePicker
                size={6}
                name="approvalDate"
                label={
                  <FormattedMessage defaultMessage="Care plan approval date" />
                }
                help={
                  <FormattedMessage defaultMessage="Date approved by referring provider" />
                }
              />
              <Form.TimePicker
                size={6}
                name="approvalTime"
                help={
                  <FormattedMessage defaultMessage="Time approved by referring provider" />
                }
                label={
                  <FormattedMessage defaultMessage="Care plan approval time" />
                }
              />
            </Form.Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          isDisabled={!form.canSubmit}
          isProcessing={isLoading}
          onPress={onSave}
        >
          <FormattedMessage defaultMessage="Save Care Plan" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
