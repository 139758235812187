import cx from 'classnames';
import { useIntl } from 'react-intl';

import {
  container,
  containerVariant,
  stripeOne,
  stripeThree,
  stripeTwo,
} from './Spinner.css';

type Props = {
  className?: string;
  size?: 'large' | 'default' | 'small';
  variant?: 'default' | 'processing';
};

export function Spinner({
  className,
  size = 'default',
  variant = 'default',
}: Props) {
  const intl = useIntl();
  return (
    <div
      className={cx(container[size], containerVariant[variant], className)}
      role="progressbar"
      aria-label={
        variant === 'processing'
          ? intl.formatMessage({ defaultMessage: 'Processing...' })
          : intl.formatMessage({ defaultMessage: 'Loading...' })
      }
    >
      <div className={stripeOne[size]} />
      <div className={stripeTwo[size]} />
      <div className={stripeThree[size]} />
    </div>
  );
}
