import type { ReactNode } from 'react';

import { variants } from './CounterBox.css';

export function CounterBox({
  variant = 'default',
  children,
}: {
  variant?: keyof typeof variants;
  children: ReactNode;
}) {
  return <div className={variants[variant]}>{children}</div>;
}
