import {
  type SectionPath,
  type StepPath,
  getFullStepPath,
} from '@/shared/common/Wizard/path.utils';

import { TITRATION_OUTREACH_SECTIONS } from '../metadata';

export function titrationOutreachPath<
  P extends SectionPath<typeof TITRATION_OUTREACH_SECTIONS>,
>(
  basePath: P,
  stepPath: StepPath<typeof TITRATION_OUTREACH_SECTIONS, P>,
): string {
  return getFullStepPath(TITRATION_OUTREACH_SECTIONS, basePath, stepPath);
}
