import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import { usePageContext } from '../PageContext';
import {
  mainContainer,
  mainContent,
  titleAndLeft,
  title as titleStyle,
} from './Header.css';

type Props = {
  title: ReactNode;
  left?: JSX.Element;
  right?: JSX.Element;
  children?: ReactNode;
};

export function Content({ title, left, right, children }: Props) {
  const headerTitleRef = useRef<HTMLDivElement>(null);
  const { headerText, setHeaderText } = usePageContext();
  useEffect(() => {
    if (headerTitleRef.current) {
      const headerTextContent = headerTitleRef.current.textContent;
      if (headerTextContent !== headerText) {
        setHeaderText(headerTextContent);
      }
    }
  }, [headerText, setHeaderText, title]);

  return (
    <div className={mainContainer}>
      <div className={mainContent}>
        <div className={titleAndLeft}>
          <span className={titleStyle} ref={headerTitleRef}>
            {title}
          </span>
          <span>{left}</span>
        </div>
        <div>{right}</div>
      </div>
      {children}
    </div>
  );
}
