import { FormattedMessage, useIntl } from 'react-intl';

import { getProblemList } from '@/pages/adminPanel/patient-enrollment-tracker/ui/shared/icd10CodeUtils';
import { Form } from '@/shared/common/Form';
import { useRouteParamPatientDetails } from '@/shared/hooks/queries';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';

import {
  emptyText,
  list,
  listSkeleton,
  sectionTitle,
} from './carePlanSections.css';

export function ProblemList() {
  const intl = useIntl();
  const { data, isLoading } = useRouteParamPatientDetails();
  const problemList = getProblemList(data?.diagnosis_codes);

  return (
    <Form.Section
      title={intl.formatMessage({ defaultMessage: 'Problem List' })}
      classes={{ title: sectionTitle }}
    >
      <Skeleton isLoading={isLoading} className={listSkeleton}>
        {problemList.length > 0 ? (
          <ul className={list}>
            {problemList.map((description) => (
              <li key={description}>{description}</li>
            ))}
          </ul>
        ) : (
          <div className={emptyText}>
            <FormattedMessage defaultMessage="None" />
          </div>
        )}
      </Skeleton>
    </Form.Section>
  );
}
