import { useEffect } from 'react';

/**
 * Better to use this hook than use eslint disable all over the place for this
 * one of the most common cases of disabling eslint.
 */
export function useOnMount(func: Function) {
  useEffect(() => {
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
