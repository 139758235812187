import type { AriaListBoxProps } from 'react-aria';
import { useListState } from 'react-stately';

import { ControlledListBox } from './ControlledListBox';

type Props<T extends object> = {
  className?: string;
} & AriaListBoxProps<T>;

export function ListBox<T extends object>({ className, ...props }: Props<T>) {
  const state = useListState(props);
  return <ControlledListBox className={className} {...props} state={state} />;
}
