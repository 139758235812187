import cx from 'classnames';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { useTabContext } from '@/deprecated/mui';

import { container } from './TabPanel.css';

type Props = {
  children: ReactNode;
  className?: string;
  value: string;
  keepMounted?: boolean;
};

export function TabPanel({
  children,
  className,
  keepMounted,
  value: id,
  ...props
}: Props) {
  const context = useTabContext();

  if (context === null) {
    throw new Error('No TabContext provided');
  }
  const tabId = context.value;

  const [visited, setVisited] = useState(false);
  useEffect(() => {
    if (id === tabId && keepMounted) {
      setVisited(true);
    }
  }, [id, keepMounted, tabId]);

  return (
    <div
      {...props}
      className={cx(container[id === tabId ? 'visible' : 'hidden'], className)}
    >
      {(id === tabId || (visited && keepMounted)) && children}
    </div>
  );
}
