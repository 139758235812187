import { FormattedMessage } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { Button } from '@/shared/tempo/atom/Button';
import type { NoteType } from '@/shared/types/note.types';

import { NotesList } from '../NotesList';

type Props = {
  onClose: () => void;
  noteTypes?: NoteType[];
  patientId: string;
};

export function RecentNotesDialog({ onClose, noteTypes, patientId }: Props) {
  return (
    <Modal open onClose={onClose} size="large">
      <Modal.Header
        title={<FormattedMessage defaultMessage="Recent Visit Notes" />}
      />
      <Modal.Body>
        <NotesList patientId={patientId} noteTypes={noteTypes} readOnly />
      </Modal.Body>
      <Modal.Footer>
        <Button id="close-recent-notes" variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
