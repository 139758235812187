/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EHRHealthSystemProviderCredentialsSchema } from '../models/EHRHealthSystemProviderCredentialsSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class EhrHealthSystemProviderService {
    /**
     * Get EHR health system provider credentials information
     * @param careProviderId CareProvider ID
     * @param healthSystemId Health System ID
     * @returns EHRHealthSystemProviderCredentialsSchema Successful Operation
     * @throws ApiError
     */
    public static getPmsApiV1CareProviderHealthSystems(
        careProviderId: string,
        healthSystemId: string,
    ): CancelablePromise<EHRHealthSystemProviderCredentialsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/care_provider/{care_provider_id}/health-systems/{health_system_id}',
            path: {
                'care_provider_id': careProviderId,
                'health_system_id': healthSystemId,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                404: `Resource Not Found`,
            },
        });
    }
}
