/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "./cadence/models/models.pb"
import * as GoogleProtobufEmpty from "./google/protobuf/empty.pb"
import * as GoEhrPkgEmr_adapterEmr_adapter from "./ehr/pkg/emr_adapter/emr_adapter.pb"
import * as fm from "./fetch.pb"

export enum EhrSyncTaskRequestPatientType {
  PATIENT_TYPE_UNSPECIFIED = "PATIENT_TYPE_UNSPECIFIED",
  PROSPECTIVE = "PROSPECTIVE",
  ENROLLED = "ENROLLED",
}

export enum EhrSyncTaskRequestScope {
  SCOPE_UNDEFINED = "SCOPE_UNDEFINED",
  APPOINTMENTS = "APPOINTMENTS",
  DEMOGRAPHICS = "DEMOGRAPHICS",
  VITALS = "VITALS",
  INSURANCES = "INSURANCES",
  PROBLEM_CODES = "PROBLEM_CODES",
  LABS = "LABS",
  MEDICATIONS = "MEDICATIONS",
  ORDERS = "ORDERS",
  CLINICAL_DOCUMENTS = "CLINICAL_DOCUMENTS",
}

export type SynchronizePatientRequest = {
  name?: string
  scopes?: EhrSyncTaskRequestScope[]
}

export type EhrSyncTaskRequest = {
  mrn?: string
  cadenceId?: string
  hospitalEhrInfo?: CadenceModelsV1Models.HospitalEhrInfo
  patientType?: EhrSyncTaskRequestPatientType
  scopes?: EhrSyncTaskRequestScope[]
}

export type PatientEMRSync = {
  syncTaskRequest?: EhrSyncTaskRequest
  appointments?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientAppointmentInfo
  demographics?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatient
  conditions?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientConditionInfo
  insurances?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientInsuranceInfo
  labs?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientLabsInfo
  vitals?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientVitalsInfo
  medications?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientMedicationInfo
  orders?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientOrderInfo
  clinicalDocuments?: GoEhrPkgEmr_adapterEmr_adapter.EMRPatientClinicalDocumentsInfo
}

export class SynchronizationService {
  static SynchronizePatient(req: SynchronizePatientRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<SynchronizePatientRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/patients/${req["name"]}:synchronize`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}