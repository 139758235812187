import type { Node } from '@react-types/shared';
import cx from 'classnames';
import { type ReactNode, useRef } from 'react';
import type { AriaSelectProps } from 'react-aria';
import { HiddenSelect, useSelect } from 'react-aria';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useSelectState } from 'react-stately';

import { ListBox } from '@/shared/tempo/@labs/atom/ListBox';
import { Popover } from '@/shared/tempo/@labs/molecule/Popover';
import { Label } from '@/shared/tempo/atom/Label';
import type { EnforcedA11yLabel } from '@/shared/tempo/shared/types';

import {
  focusRingCss,
  innerValue,
  label as labelCss,
  listBoxPopover,
} from './Select.css';
import { SelectButton } from './SelectButton';
import type { SelectPopoverProps } from './types';
import { useSyncSelectPopoverWidth } from './useSyncSelectPopoverWidth';

export type Props<T> = {
  className?: string;
  hasError?: boolean;
  placeholder?: string;
  selectionLabel?: (selectedItem: Node<T>) => ReactNode;
  popover?: SelectPopoverProps;
  variant?: 'prominent' | 'subtle';
  classes?: {
    chevron?: string;
    popover?: string;
    valueLabel?: string;
  };
} & AriaSelectProps<T> &
  EnforcedA11yLabel;

export function Select<T extends object>(props: Props<T>) {
  const {
    isRequired,
    placeholder,
    className,
    label,
    hasError,
    popover,
    selectionLabel,
    variant = 'prominent',
    classes,
  } = props;
  const intl = useIntl();
  const state = useSelectState(props);
  const ref = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { triggerProps, valueProps, menuProps, labelProps } = useSelect(
    props,
    state,
    ref,
  );

  useSyncSelectPopoverWidth(state, ref, popoverRef, {
    width: popover?.width,
  });

  return (
    <>
      {label && (
        <Label
          className={labelCss}
          label={label}
          labelProps={labelProps}
          isRequired={isRequired}
        />
      )}
      <HiddenSelect state={state} triggerRef={ref} {...props} />
      <SelectButton
        {...triggerProps}
        ref={ref}
        hasError={hasError}
        state={state}
        variant={variant}
        className={cx(
          {
            [focusRingCss.keyboardWithError]: state.isFocused && hasError,
            [focusRingCss.keyboard]:
              state.isFocused && !hasError && variant === 'prominent',
          },
          className,
        )}
        classes={{ chevron: classes?.chevron }}
      >
        <span
          {...valueProps}
          className={cx(valueProps.className, innerValue, classes?.valueLabel)}
        >
          {getLabel(intl, state.selectedItem, placeholder, selectionLabel)}
        </span>
      </SelectButton>
      {state.isOpen && (
        <Popover
          className={cx(listBoxPopover, classes?.popover)}
          state={state}
          ref={popoverRef}
          triggerRef={ref}
          placement={popover?.placement || 'bottom'}
        >
          <ListBox.Controlled {...menuProps} state={state} />
        </Popover>
      )}
    </>
  );
}

function getLabel<T>(
  intl: IntlShape,
  selectedItem: Node<T>,
  placeholder: Maybe<string>,
  selectionLabel?: (selectedItem: Node<T>) => ReactNode,
) {
  if (!selectedItem) {
    return (
      placeholder || intl.formatMessage({ defaultMessage: 'Select an option' })
    );
  }
  return selectionLabel ? selectionLabel(selectedItem) : selectedItem.rendered;
}
