import { FormattedMessage } from 'react-intl';

export const REGULAR_VISIT_SECTIONS = [
  {
    basePath: '/chart-prep',
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="Chart Prep" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/intro',
    title: <FormattedMessage defaultMessage="Intro" />,
    steps: [
      { path: '/patient-attendance' },
      { path: '/patient-identification' },
    ],
  },
  {
    basePath: '/symptoms',
    title: <FormattedMessage defaultMessage="Symptoms" />,
    steps: [{ path: '/index' }, { path: '/secondary' }],
  },
  {
    basePath: '/product-support',
    title: <FormattedMessage defaultMessage="Product Support" />,
    steps: [
      { path: '/feedback' },
      // TODO: Remove the following paths when showVitalsEducation is fully rolled out
      { path: '/intro' },
      { path: '/device-usage' },
      { path: '/low-device-usage' },
    ],
  },
  {
    basePath: '/vital-progress',
    title: <FormattedMessage defaultMessage="Vital Progress" />,
    steps: [{ path: '/intro' }, { path: '/recommendation' }],
  },
  {
    basePath: '/med-review',
    title: <FormattedMessage defaultMessage="Med Review" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/general-assessment',
    title: <FormattedMessage defaultMessage="General Assessment" />,
    steps: [
      { path: '/tobacco-use' },
      { path: '/diet' },
      { path: '/exercise' },
      { path: '/daily-living' },
    ],
  },
  {
    basePath: '/action-plan',
    title: <FormattedMessage defaultMessage="Action Plan" />,
    steps: [
      { path: '/prev-goal-progress' },
      { path: '/prev-goal-progress/discuss' },
      { path: '/no-prev-goal' },
      { path: '/set-goal' },
      { path: '/education-modules-review' },
      { path: '/set-action-steps' },
      { path: '/review-action-steps' },
    ],
  },
  {
    basePath: '/scheduling',
    title: <FormattedMessage defaultMessage="Scheduling" />,

    steps: [{ path: '/index' }],
  },
  {
    basePath: '/time-tracking',
    title: <FormattedMessage defaultMessage="Time Tracking" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/end-call',
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="End early" />,
    steps: [{ path: '/index' }],
  },
] as const;
