/* eslint-disable no-alert */
import type { ContentBlock, ContentState } from 'draft-js';
import { CompositeDecorator, EditorState, Modifier } from 'draft-js';

import { RtfLink } from './RtfLink';

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export function getRtfContentDecorator() {
  return new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: RtfLink,
    },
  ]);
}

export const onAddLink = (
  editorState: EditorState,
  setEditorState: (newEditorState: EditorState) => void,
) => {
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);

  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const selectedText = currentBlock.getText().slice(start, end);

  if (selectedText !== '') {
    const linkUrl = window.prompt('Add link (e.g. https://example.com)');

    currentContent.createEntity('LINK', 'MUTABLE', {
      url: linkUrl,
    });

    const entityKey = currentContent.getLastCreatedEntityKey();
    const textWithEntity = Modifier.applyEntity(
      currentContent,
      selection,
      entityKey,
    );
    const newState = EditorState.createWithContent(
      textWithEntity,
      getRtfContentDecorator(),
    );

    setEditorState(newState);
  } else {
    const linkUrl = window.prompt('Add link (e.g. https://example.com)');

    if (linkUrl) {
      const displayLink = window.prompt('Add text to be displayed in note');

      if (displayLink) {
        currentContent.createEntity('LINK', 'MUTABLE', {
          url: linkUrl,
        });

        const entityKey = currentContent.getLastCreatedEntityKey();
        const textWithEntity = Modifier.insertText(
          currentContent,
          selection,
          displayLink,
          undefined,
          entityKey,
        );
        const newState = EditorState.createWithContent(
          textWithEntity,
          getRtfContentDecorator(),
        );

        setEditorState(newState);
      }
    }
  }
};
