import { parseISO } from 'date-fns';
import first from 'lodash/first';
import type { QueryClient } from 'react-query';
import { useQuery } from 'react-query';

import { EncounterType } from '@/shared/generated/grpcGateway/cadence/models/models.pb';
import {
  NoteStatus,
  PatientService,
} from '@/shared/generated/grpcGateway/pms.pb';
import { useFlags } from '@/shared/hooks/useFlags';

const encounterKeys = {
  lastNpEncounter: (patientId: string, noteStatus?: NoteStatus) =>
    [
      'patient',
      patientId,
      'lastNpEncounter',
      ...(noteStatus ? [noteStatus] : []),
    ] as const,
  suggestion: (patientId: string) =>
    ['patient', patientId, 'encounterSuggestion'] as const,
};

export function useLastNpEncounterQuery(
  patientId: string,
  noteStatus?: NoteStatus,
) {
  const queryResult = useQuery(
    encounterKeys.lastNpEncounter(patientId, noteStatus),
    () =>
      PatientService.ListEncounters({
        parent: patientId,
        pageSize: 1,
        filter: `type = "${EncounterType.REGULAR_NP_VISIT}" AND NOT isNoShow${
          noteStatus ? ` AND noteStatus = "${noteStatus}"` : ''
        }`,
      }),
  );

  return {
    ...queryResult,
    lastNpEncounter: first(queryResult.data?.encounters),
  };
}

export async function invalidateLastPublishedNpEncounterNoteQuery(
  patientId: string,
  queryClient: QueryClient,
) {
  return queryClient.invalidateQueries(
    encounterKeys.lastNpEncounter(patientId, NoteStatus.PUBLISHED),
  );
}

export function useEncounterSuggestion(patientId: string) {
  return useQuery(encounterKeys.suggestion(patientId), () =>
    PatientService.SuggestEncounters({ parent: patientId }),
  );
}

export async function invalidateEncounterSuggestionsQuery(
  patientId: string,
  queryClient: QueryClient,
) {
  return queryClient.invalidateQueries(encounterKeys.suggestion(patientId));
}

export function useIsPastSuggestedNPVisitDate(patientId: string) {
  const { showSchedulingSuggestions } = useFlags();
  const { data } = useEncounterSuggestion(patientId);
  const suggestion = data?.suggestion;

  return Boolean(
    showSchedulingSuggestions &&
      suggestion &&
      suggestion.type === EncounterType.REGULAR_NP_VISIT &&
      suggestion.suggestedTime &&
      new Date() > parseISO(suggestion.suggestedTime),
  );
}
