import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { STALE_TIME } from '@/reactQuery';
import type {
  ListReferenceLabsRequest,
  ListReferenceLabsResponse,
  PatientLabs,
  PatientLabsAndGroups,
} from '@/shared/generated/grpcGateway/labs.pb';
import { LabsService } from '@/shared/generated/grpcGateway/labs.pb';
import { idToGrpcName } from '@/shared/utils/grpc';

const REFERENCE_LAB_QUERY_KEY_BASE = [
  'rpm',
  'v1',
  'labs',
  'reference',
] as const;

const referenceLabKeys = {
  referenceList: () => [...REFERENCE_LAB_QUERY_KEY_BASE] as const,
};

const PATIENT_LAB_QUERY_KEY_BASE = ['rpm', 'v1', 'patients', 'labs'] as const;
const PATIENT_LABS_AND_GROUPS_QUERY_KEY_BASE = [
  'rpm',
  'v1',
  'patients',
  'labsAndGroups',
] as const;

export const patientLabKeys = {
  patientLabs: (patientId: string) =>
    [...PATIENT_LAB_QUERY_KEY_BASE, patientId] as const,
};

export const patientLabsAndGroupsKeys = {
  patientLabs: (patientId: string) =>
    [...PATIENT_LABS_AND_GROUPS_QUERY_KEY_BASE, patientId] as const,
};

export function useReferenceLabs(
  params: ListReferenceLabsRequest = {},
  options?: UseQueryOptions<ListReferenceLabsResponse>,
) {
  return useQuery({
    // This data is pretty static and shouldn't change often, so we can safely increase the stale time
    staleTime: STALE_TIME.TEN_MINUTES,
    queryKey: referenceLabKeys.referenceList(),
    queryFn: () => LabsService.ListReferenceLabs(params),
    ...options,
  });
}

export function usePatientLabs(
  patientId: string,
  options?: UseQueryOptions<PatientLabs>,
) {
  return useQuery({
    queryKey: patientLabKeys.patientLabs(patientId),
    queryFn: () =>
      LabsService.GetPatientLabs({
        name: idToGrpcName('patients', patientId, 'labs'),
      }),
    ...options,
  });
}

export function usePatientLabsAndGroups(
  patientId: string,
  options?: UseQueryOptions<PatientLabsAndGroups>,
) {
  return useQuery({
    queryKey: patientLabsAndGroupsKeys.patientLabs(patientId),
    queryFn: () =>
      LabsService.GetPatientLabsAndGroups({
        name: idToGrpcName('patients', patientId, 'labsAndGroups'),
      }),
    ...options,
    enabled: Boolean(patientId) && (options?.enabled ?? true),
  });
}
