import { string } from 'yup';

export type NotesFormFields = { notes: string };

export const NOTES_FORM_CONFIG = {
  fields: {
    notes: {
      defaultValue: undefined,
      validation: string(),
    },
  },
};
