import { format, isBefore, isValid, parseISO, subMonths } from 'date-fns';

export const displayVitalTimestampInPtTz = (timestamp: string) => {
  // Vital timestamps are already returned in pt's timezone. 'Z' is UTC time, hence need to slice.
  let ptTimestamp = '';
  if (timestamp.endsWith('Z')) {
    ptTimestamp = timestamp.slice(0, -1);
  }

  const parsedTime = parseISO(ptTimestamp);
  if (!isValid(parsedTime)) {
    return undefined;
  }
  return format(parsedTime, 'h:mma');
};

// TO DO: delete post telemetry endpoint roll out
export const displayVitalTimestamp = (timestamp: string) =>
  format(parseISO(timestamp), 'h:mma');

export const isDateValid = (date?: GoogleDate) =>
  date && (date.year !== 0 || date.month !== 0 || date.day !== 0);

export const isOneMonthAgo = (inputDate: GoogleDate, numberOfMonth = 1) => {
  const oneMonthAgo = subMonths(new Date(), numberOfMonth);
  const input = new Date(inputDate.year, inputDate.month - 1, inputDate.day);

  return isBefore(input, oneMonthAgo);
};
