import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { initVisitPath } from '../../initialVisit/paths';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { LastDiscussed } from '../../shared/LastDiscussed';
import { DietForm } from '../../shared/generalAssessment';
import type { DietFormFields } from '../../shared/generalAssessment/formConfig';
import { getDietFormConfig } from '../../shared/generalAssessment/formConfig';
import { regVisitPath } from '../paths';

export function Diet() {
  const intl = useIntl();
  const history = useHistory();
  const path = regVisitPath('/general-assessment', '/diet');
  const { isLoading, data } = useRecentNoteFormData<DietFormFields>([
    path,
    initVisitPath('/general-assessment', '/diet'),
  ]);
  const form = useWizardFormFromConfig<DietFormFields>(path, {
    ...getDietFormConfig(intl, data),
    triggerReset: !isLoading,
  });

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      isLoading={isLoading}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Diet' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() =>
        history.push(regVisitPath('/general-assessment', '/exercise'))
      }
    >
      <LastDiscussed hasData={!!data}>
        <DietForm form={form} />
      </LastDiscussed>
    </Wizard.Step>
  );
}
