import { TotalTimeDisplay } from '@/pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm/shared';

import type { NoteFieldValidationResult } from '../../validation';
import { ValidationMessage } from '../ValidationMessage';

type Props = {
  minutes: number;
  fieldValidationResult?: NoteFieldValidationResult;
};

export function TimeTrackingTotalTimeDisplay({
  minutes,
  fieldValidationResult,
}: Props) {
  return (
    <TotalTimeDisplay
      minutes={minutes}
      error={
        fieldValidationResult ? (
          <ValidationMessage
            validationMessage={fieldValidationResult?.message}
          />
        ) : null
      }
    />
  );
}
