import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const PlusIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 3.75C11.25 3.33579 11.5858 3 12 3C12.4142 3 12.75 3.33579 12.75 3.75V20.25C12.75 20.6642 12.4142 21 12 21C11.5858 21 11.25 20.6642 11.25 20.25V3.75Z"
        fill="currentColor"
      />
      <path
        d="M20.25 11.0184C20.6642 11.0184 21 11.3542 21 11.7684C21 12.1826 20.6642 12.5184 20.25 12.5184L3.75 12.5184C3.33579 12.5184 3 12.1826 3 11.7684C3 11.3542 3.33579 11.0184 3.75 11.0184L20.25 11.0184Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);

export default PlusIcon;
