import { useIntl } from 'react-intl';

// TODO: Enable / remove this when we have the data
const SHOW_ESTIMATES = false;

export function useTimeEstimate(estimate: string) {
  const intl = useIntl();
  if (!SHOW_ESTIMATES) {
    return undefined;
  }
  return intl.formatMessage(
    { defaultMessage: 'Est. Time: {estimate}' },
    { estimate },
  );
}
