import {
  type EncounterModuleInstance,
  TypeOfEncounter,
} from '../../Notes.types';
import {
  getEncounterType,
  getEncounterTypeInstance,
  isClinicalNavigatorEncounterType as isCnVisit,
  isNPEncounterType as isNpVisit,
} from '../../utils/encounterTypeUtils';
import { NoteFormSubmissionType } from '../validation';

export function requireMedActionsInEncounter(
  submissionType: NoteFormSubmissionType,
  encounterData: EncounterModuleInstance<{}>[],
) {
  if (submissionType !== NoteFormSubmissionType.Publish) {
    return false;
  }

  const encounterInputs = getEncounterTypeInstance(encounterData)?.inputs || {};

  const {
    patient_no_show: patientNoShow,
    has_meds_to_report: hasMedsToReport,
  } = encounterInputs;

  // Don't show required actions if either "Patient no show" or "Patient declined to answer" toggle is on
  if (patientNoShow || !hasMedsToReport) {
    return false;
  }

  return showMedReview(encounterData);
}

export function showMedReview(encounterData: EncounterModuleInstance<{}>[]) {
  const encounterType = getEncounterType(encounterData);

  return (
    isCnVisit(encounterType) ||
    isNpVisit(encounterType) ||
    encounterType === TypeOfEncounter.CCM_VISIT
  );
}
