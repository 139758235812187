import type { ReactElement } from 'react';
import { useState } from 'react';

import { PatientInfoContextProvider } from './PatientInfoContext';

export const NumberOfPatientInfoColumnsProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [numberOfPatientInfoColumns, setNumberOfPatientInfoColumns] =
    useState(0);
  return (
    <PatientInfoContextProvider
      value={{ numberOfPatientInfoColumns, setNumberOfPatientInfoColumns }}
    >
      {children}
    </PatientInfoContextProvider>
  );
};
