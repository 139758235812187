import cx from 'classnames';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TooltipProps } from '@/deprecated/mui';
import { Button } from '@/deprecated/mui';

import { Tooltip } from '../Tooltip';
import { actionIconTagContainer, loadingIconStyle } from './ActionIconTag.css';
import type { ActionIconTagType } from './types';

type TagProps = {
  icon?: ReactNode;
  tagLabel: ReactNode;
  tagType: ActionIconTagType;
  className?: string;
  disabled?: boolean;
  endIcon?: ReactNode;
  tooltipMessage?: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
};

type ClickableTagProps = TagProps & {
  onClick?: () => void;
};

type Props = TagProps &
  ClickableTagProps & {
    isLoading?: boolean;
    clickable?: boolean;
  };

const LoadingActionIconTag = () => (
  <Button disabled className={loadingIconStyle} variant="text" disableRipple>
    <FormattedMessage defaultMessage="Loading..." />
  </Button>
);

const ActionIconClickableTag = ({
  tagType,
  endIcon,
  icon,
  tagLabel,
  onClick,
  disabled = false,
  tooltipMessage,
  className,
  tooltipPlacement,
}: ClickableTagProps) => (
  <Tooltip
    disabled={disabled}
    tooltipMessage={tooltipMessage}
    tooltipPlacement={tooltipPlacement}
  >
    <Button
      disabled={disabled}
      className={cx(actionIconTagContainer[tagType], className)}
      variant="text"
      onClick={onClick}
      disableRipple
    >
      {icon}
      {tagLabel}
      {endIcon}
    </Button>
  </Tooltip>
);

const ActionIconNonClickableTag = ({
  tagType,
  endIcon,
  icon,
  tagLabel,
  disabled,
  tooltipMessage,
  tooltipPlacement,
  className,
}: TagProps) => (
  <Tooltip
    disabled={disabled}
    tooltipMessage={tooltipMessage}
    tooltipPlacement={tooltipPlacement}
  >
    <div className={cx(actionIconTagContainer[tagType], className)}>
      {icon}
      {tagLabel}
      {endIcon}
    </div>
  </Tooltip>
);

export const ActionIconTag = ({
  isLoading,
  clickable = false,
  ...rest
}: Props) => {
  if (isLoading) {
    return <LoadingActionIconTag />;
  }
  return clickable ? (
    <ActionIconClickableTag {...rest} />
  ) : (
    <ActionIconNonClickableTag {...rest} />
  );
};
