import { useIntl } from 'react-intl';

import { Table } from '@/shared/common/Table';
import { VitalType } from '@/shared/types/vitals.types';

import { table } from './PatientActivity.css';
import { VitalCell } from './VitalCell';
import type { VitalTypes } from './useRelevantVitalTypes';
import type { PatientActivityVitals } from './useVitalsAndGoals';

type Props = {
  vitalsAndGoals: PatientActivityVitals;
  vitalTypes: VitalTypes;
};

export function VitalsTable({ vitalsAndGoals, vitalTypes }: Props) {
  const intl = useIntl();
  const {
    [VitalType.BloodPressure]: bp,
    [VitalType.HeartRate]: hr,
    [VitalType.Weight]: weight,
    [VitalType.BloodGlucose]: bg,
  } = vitalsAndGoals;

  return (
    <Table>
      <Table.Header
        columns={[
          {
            title: intl.formatMessage({
              defaultMessage: '30-day vital averages',
            }),
            className: table.headerCell,
            colSpan: 2,
          },
        ]}
      />
      <Table.Body>
        {vitalTypes[VitalType.BloodPressure].isIncluded && (
          <Table.Row>
            <Table.TextCell className={table.cell}>
              {intl.formatMessage({ defaultMessage: 'Blood Pressure' })}
            </Table.TextCell>
            <VitalCell
              reading={bp?.avg}
              goal={`<${bp?.goal}`}
              atGoal={bp?.atGoal}
            />
          </Table.Row>
        )}
        {vitalTypes[VitalType.HeartRate].isIncluded && (
          <Table.Row>
            <Table.TextCell className={table.cell}>
              {intl.formatMessage({ defaultMessage: 'Heart Rate' })}
            </Table.TextCell>
            <VitalCell reading={hr?.avg} />
          </Table.Row>
        )}
        {vitalTypes[VitalType.Weight].isIncluded && (
          <Table.Row>
            <Table.TextCell className={table.cell}>
              {intl.formatMessage({ defaultMessage: 'Weight' })}
            </Table.TextCell>
            <VitalCell reading={weight?.avg} />
          </Table.Row>
        )}
        {vitalTypes[VitalType.BloodGlucose].isIncluded && (
          <Table.Row>
            <Table.TextCell className={table.cell}>
              {intl.formatMessage({ defaultMessage: 'Blood Glucose' })}
            </Table.TextCell>
            <VitalCell
              reading={bg?.avg}
              goal={`<${bg?.goal}`}
              atGoal={bg?.atGoal}
            />
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
