import { Body } from './Body';
import { Footer } from './Footer';
import { Header } from './Header';
import { ModalContainer } from './ModalContainer';

export { MODAL_OPEN_ATTR } from './constants';

// Export Modal with subcomponents (i.e. Modal.Header, Modal.Body, Modal.Footer...)
export const Modal = Object.assign(ModalContainer, {
  Footer,
  Body,
  Header,
});
