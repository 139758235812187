import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import UserIcon from '@/shared/assets/svgs/user.svg?react';
import type { Task } from '@/shared/tasking/types';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

type Props = {
  task: Task;
};

export function PatientProfileButton({ task }: Props) {
  const history = useHistory();

  return (
    <Tooltip content={<FormattedMessage defaultMessage="Chart" />}>
      <IconButton
        size="small"
        variant="tertiary"
        onPress={() => history.push(`/patients/${task.patientId}`)}
      >
        <UserIcon />
      </IconButton>
    </Tooltip>
  );
}
