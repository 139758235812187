import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { AnchorHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import type { AriaLinkOptions } from 'react-aria';
import { FocusRing, useLink } from 'react-aria';

import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { link } from './styles.css';

type Props = {
  className?: string;
  children: ReactNode;
} & AnchorHTMLAttributes<HTMLAnchorElement> &
  AriaLinkOptions;

export const NativeLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className, children, ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);
    const { linkProps, isPressed } = useLink(props, ref);

    return (
      <FocusRing focusRingClass={cx(focusRingCss.keyboard)}>
        <a
          {...linkProps}
          {...props}
          ref={ref}
          className={cx(
            { [link.default]: !isPressed, [link.active]: isPressed },
            className,
          )}
        >
          {children}
        </a>
      </FocusRing>
    );
  },
);
