import { Item, Section } from 'react-stately';

import { Menu as BaseMenu } from './Menu';
import { MenuTrigger } from './MenuTrigger';

export const Menu = Object.assign(BaseMenu, {
  Item,
  Section,
  Trigger: MenuTrigger,
});
