import cx from 'classnames';
import isSet from 'lodash/isSet';
import { type Key, useRef } from 'react';
import type { AriaListBoxProps, AriaSelectOptions } from 'react-aria';
import { HiddenSelect, useSelect } from 'react-aria';
import { FormattedMessage } from 'react-intl';
import {
  useListState,
  useMenuTriggerState,
  useSelectState,
} from 'react-stately';

import { ListBox } from '@/shared/tempo/@labs/atom/ListBox';
import { Popover } from '@/shared/tempo/@labs/molecule/Popover';
import { SelectButton } from '@/shared/tempo/@labs/molecule/Select/SelectButton';
import { useSyncSelectPopoverWidth } from '@/shared/tempo/@labs/molecule/Select/useSyncSelectPopoverWidth';
import { Label } from '@/shared/tempo/atom/Label';
import type { EnforcedA11yLabel } from '@/shared/tempo/shared/types';

import type { SelectPopoverProps } from '../Select/types';
import {
  contents,
  focusRingCss,
  label as labelCss,
  listBoxPopover,
} from './MultiSelect.css';
import { SelectionTagView } from './SelectionTagView';
import { SubtleSelectionTagView } from './SubtleSelectionTagView';
import { SELECT_ALL_KEY } from './constants';
import { getSelectableItems } from './getSelectableItems';

type Props<T> = {
  className?: string;
  hasError?: boolean;
  placeholder?: string;
  popover?: SelectPopoverProps;
  isLoading?: boolean;
  ignoreKeys?: Iterable<Key>;
  variant?: 'prominent' | 'subtle';
  classes?: {
    chevron?: string;
    popover?: string;
  };
  allSelectedLabel?: string;
} & AriaSelectOptions<T> &
  Omit<AriaListBoxProps<T>, 'selectionMode'> &
  EnforcedA11yLabel;

export function MultiSelect<T extends object>({
  variant = 'prominent',
  ...props
}: Props<T>) {
  const {
    placeholder,
    className,
    label,
    hasError,
    popover,
    disabledKeys,
    isLoading,
    ignoreKeys = [],
    'aria-label': ariaLabel,
    classes,
    allSelectedLabel,
  } = props;
  const listState = useListState({
    ...props,
    allowDuplicateSelectionEvents: false,
    selectionMode: 'multiple',
    onSelectionChange: (selection) => {
      // if we aren't including a "Select all" option, no need to run through all
      // of the nonsense below. can just pass straight through.
      if (!listState.collection.getItem(SELECT_ALL_KEY)) {
        props.onSelectionChange?.(selection);
        return;
      }

      // i don't know if this is even possible. types indicate that it's not but i don't
      // know if i trust them entirely. if it's not a Set, it _should_ be the string
      // literal "all", but just playing it safe here
      if (!isSet(selection) && selection !== 'all') {
        props.onSelectionChange?.(selection);
        return;
      }

      const items = getSelectableItems(listState);

      // we're not including SELECT_ALL_KEY because the consumer is not interested in it.
      if (selection === 'all') {
        const keys = new Set(
          items.map(({ key }) => key).filter((key) => key !== SELECT_ALL_KEY),
        );
        props.onSelectionChange?.(keys);

        return;
      }

      const prev = listState.selectionManager.selectedKeys;
      const next = selection;

      const itemsCount = items.length;
      const nextHasSelectAll = next.has(SELECT_ALL_KEY);
      const prevHasSelectAll = prev.has(SELECT_ALL_KEY);

      // we want to selectAll() in either of the following scenarios:
      //   a) the user is explicitly selecting the SELECT_ALL_KEY option
      //   b) the user is selecting the last selectable option other than
      //      the SELECT_ALL_KEY option
      const isSelectingAll = nextHasSelectAll && !prevHasSelectAll;
      const isSelectingLastItem =
        next.size === itemsCount - 1 && !nextHasSelectAll && !prevHasSelectAll;

      if (isSelectingAll || isSelectingLastItem) {
        listState.selectionManager.selectAll();
        return;
      }

      // we want to clearSelection() only when the user is explicitly deselecting
      // the SELECT_ALL_KEY option. we also need to check the next size because we only
      // want to clear the selection when we already have everything else selected.
      // each of the branches below trigger at least one more call to onSelectionChange
      // so we need to guard against that
      const isDeselectingAll = !nextHasSelectAll && prevHasSelectAll;

      if (isDeselectingAll && next.size === itemsCount - 1) {
        listState.selectionManager.clearSelection();
        return;
      }

      // we want to deselect SELECT_ALL_KEY when SELECT_ALL_KEY is currently selected and
      // the user is deselecting another option (so it no longer makes sense to
      // show the SELECT_ALL_KEY option as selected since we don't have every item selected).
      // similar to the above, we need to inspect the new size to make sure we
      // don't wind up in an endless loop
      if (nextHasSelectAll && next.size < itemsCount) {
        const nextKeys = new Set(next);
        nextKeys.delete(SELECT_ALL_KEY);
        listState.selectionManager.setSelectedKeys(nextKeys);

        return;
      }

      // if we made it this far, we've finally settled and can notify
      // the consumer that a selection change has indeed occurred
      props.onSelectionChange?.(selection);
    },
  });
  const triggerState = useMenuTriggerState(props);
  const selectState = useSelectState(props);
  const state = { ...selectState, ...listState, ...triggerState };
  const ref = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const { triggerProps, valueProps, menuProps, labelProps } = useSelect(
    props,
    state,
    ref,
  );

  useSyncSelectPopoverWidth(state, ref, popoverRef, {
    width: popover?.width,
  });

  const selectedKeys = [...state.selectionManager.selectedKeys].filter(
    (k) => ![...ignoreKeys].includes(k),
  );

  const renderSelectionLabel = () => {
    if (allSelectedLabel && selectedKeys.includes(SELECT_ALL_KEY)) {
      return allSelectedLabel;
    }

    if (selectedKeys.length > 0) {
      const SelectionTagViewComponent =
        variant === 'prominent' ? SelectionTagView : SubtleSelectionTagView;

      return (
        <SelectionTagViewComponent
          state={state}
          isLoading={isLoading}
          disabledKeys={disabledKeys}
          ariaLabel={ariaLabel}
          selectButtonRef={ref}
        />
      );
    }

    return (
      placeholder || <FormattedMessage defaultMessage="Select an option" />
    );
  };

  return (
    <>
      {label && (
        <Label className={labelCss} label={label} labelProps={labelProps} />
      )}
      <HiddenSelect
        state={state}
        triggerRef={ref}
        {...props}
        aria-multiselectable
      />
      <SelectButton
        {...triggerProps}
        ref={ref}
        hasError={hasError}
        state={state}
        variant={variant}
        className={cx(
          {
            [focusRingCss.keyboardWithError]: state.isFocused && hasError,
            [focusRingCss.keyboard]:
              state.isFocused && !hasError && variant === 'prominent',
          },
          className,
        )}
        classes={{ chevron: classes?.chevron }}
      >
        <span {...valueProps} className={contents}>
          {renderSelectionLabel()}
        </span>
      </SelectButton>
      {state.isOpen && (
        <Popover
          className={cx(listBoxPopover, classes?.popover)}
          state={state}
          ref={popoverRef}
          triggerRef={ref}
          placement={popover?.placement || 'bottom'}
        >
          <ListBox.Controlled
            {...menuProps}
            state={state}
            selectionMode="multiple"
          />
        </Popover>
      )}
    </>
  );
}
