import { useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { PatientListDashboard } from '@/pages/patients/PatientListDashboard';
import { PatientListProvider } from '@/pages/patients/PatientListDashboard/PatientListContext';
import { PatientProfile } from '@/pages/patients/PatientProfile';
import type { Patient } from '@/shared/types/patient.types';

const PatientsRoutesRoutes = () => {
  const [patients, setPatients] = useState<Patient[]>([]);

  return (
    <Switch>
      <PatientListProvider
        value={{
          patients,
          setPatients,
        }}
      >
        <Route path="/patients" component={PatientListDashboard} exact />
        <Route path="/patients/:patientId" component={PatientProfile} />
      </PatientListProvider>
      <Redirect from="*" to="/patients" />
    </Switch>
  );
};

export const PatientsRoutes = withRouter(PatientsRoutesRoutes);
