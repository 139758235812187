import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { ReasonForVisit, TypeOfVisit } from './types';

export function useTypeOfVisitI18nMap() {
  const intl = useIntl();
  return getTypeOfVisitI18nMap(intl);
}

export function getTypeOfVisitI18nMap(
  intl: IntlShape,
): Record<TypeOfVisit, string> {
  return {
    [TypeOfVisit.UrgentCare]: intl.formatMessage({
      defaultMessage: 'Urgent care',
    }),
    [TypeOfVisit.EmergencyDepartment]: intl.formatMessage({
      defaultMessage: 'Emergency department',
    }),
    [TypeOfVisit.HospitalAdmission]: intl.formatMessage({
      defaultMessage: 'Hospital admission',
    }),
  };
}

export function useReasonForVisitI18nMap() {
  const intl = useIntl();
  return getReasonForVisitI18nMap(intl);
}

export function getReasonForVisitI18nMap(
  intl: IntlShape,
): Record<ReasonForVisit, string> {
  return {
    [ReasonForVisit.CHF]: intl.formatMessage({
      defaultMessage: 'CHF',
    }),
    [ReasonForVisit.HTN]: intl.formatMessage({
      defaultMessage: 'Hypertension',
    }),
    [ReasonForVisit.T2D]: intl.formatMessage({
      defaultMessage: 'Type 2 diabetes',
    }),
    [ReasonForVisit.Other]: intl.formatMessage({
      defaultMessage: 'Other',
    }),
  };
}
