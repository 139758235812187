import cx from 'classnames';
import type { ReactNode, RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowLeftIcon from '@/shared/assets/svgs/arrow-left.svg?react';
import { IconButton } from '@/shared/tempo/atom/IconButton';

import { PAGE_CONTAINER_ID, PAGE_ID } from '../Page';
import { usePageContext } from '../PageContext';
import {
  animatedBreadcrumb,
  animatedBreadcrumbBase,
  animatedTitle,
  animatedTitleBase,
  backButton,
  container,
  title as titleClass,
} from './ActionStrip.css';

export const ACTION_STRIP_ID = 'page-header-action-strip';

type Props = {
  left?: ReactNode;
  right?: ReactNode;
  scrollTitle?: string;
  backNavigation?: {
    link: string;
    label: ReactNode;
  };
  className?: string;
};

export function ActionStrip({
  left,
  right,
  scrollTitle,
  backNavigation,
  className,
}: Props) {
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const showScrollTitle = useShouldShowScrollTitle(containerRef);

  const clickHandler = useCallback(() => {
    if (backNavigation) {
      history.push(backNavigation.link);
      return;
    }
    history.goBack();
  }, [history, backNavigation]);

  const { headerText } = usePageContext();
  const title = scrollTitle || headerText;

  return (
    <div
      className={cx(container.main, className)}
      id={ACTION_STRIP_ID}
      ref={containerRef}
    >
      <div className={container.left}>
        {backNavigation && (
          <>
            <span>
              <IconButton
                size="small"
                variant="tertiary"
                className={backButton}
                onPress={clickHandler}
              >
                <ArrowLeftIcon />
              </IconButton>
            </span>
            <span
              className={cx(animatedBreadcrumbBase, {
                [animatedBreadcrumb.visible]: !showScrollTitle,
                [animatedBreadcrumb.hidden]: showScrollTitle,
              })}
            >
              {backNavigation.label}
            </span>
          </>
        )}
        <span
          className={cx(animatedTitleBase, {
            [animatedTitle.visible]: showScrollTitle,
            [animatedTitle.hidden]: !showScrollTitle,
          })}
        >
          <span className={titleClass}>{title}</span>
        </span>
        {left && left}
      </div>
      <div className={container.right}>{right && right}</div>
    </div>
  );
}

function useShouldShowScrollTitle(containerRef: RefObject<HTMLDivElement>) {
  const [showScrollTitle, setShowScrollTitle] = useState(false);
  const onScroll = useCallback(
    (e: Event) => {
      if (
        ((e.target instanceof HTMLElement && e.target?.scrollTop) || 0) >
        (containerRef.current?.getBoundingClientRect().height ||
          Number.POSITIVE_INFINITY)
      ) {
        setShowScrollTitle(true);
      } else {
        setShowScrollTitle(false);
      }
    },
    [containerRef],
  );

  useEffect(() => {
    document
      .getElementById(PAGE_CONTAINER_ID)
      ?.addEventListener('scroll', onScroll);
    document.getElementById(PAGE_ID)?.addEventListener('scroll', onScroll);
    return () => {
      document
        .getElementById(PAGE_CONTAINER_ID)
        ?.removeEventListener('scroll', onScroll);
      document.getElementById(PAGE_ID)?.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return showScrollTitle;
}
