export enum TypeOfVisit {
  UrgentCare = 'URGENT_CARE',
  EmergencyDepartment = 'EMERGENCY_DEPARTMENT',
  HospitalAdmission = 'HOSPITAL_ADMISSION',
}

export enum ReasonForVisit {
  CHF = 'CHF',
  HTN = 'HTN',
  T2D = 'T2D',
  Other = 'OTHER',
}
