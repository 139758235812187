import type { Field, FieldProps } from '@rjsf/core';

import { descriptionField } from './DescriptionField.css';

type DescriptionFieldProps = {
  description?: string | React.ReactElement;
} & FieldProps;

export const DescriptionField: Field = ({
  description,
}: DescriptionFieldProps) =>
  description ? <div className={descriptionField}>{description}</div> : null;
