import isArray from 'lodash/isArray';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useFlags } from '@/shared/hooks';

import { ALL_MARKETS_KEY } from '../PatientAlertsDashboardPage/FiltersSection/MarketFilterDropdown';
import {
  CareProviderFilter,
  ConditionFilter,
  LocationFilter,
} from '../types/filter.types';

const ALL_MARKETS = [ALL_MARKETS_KEY];

export function useFiltersQuery() {
  const { defaultToMyPatientsView } = useFlags();
  const { search: locationSearch, state: locationState } = useLocation();
  const history = useHistory();
  const params = useMemo(
    () => new URLSearchParams(locationSearch),
    [locationSearch],
  );

  const defaultCpFilter = defaultToMyPatientsView
    ? CareProviderFilter.MyPatients
    : CareProviderFilter.AllPatients;
  const careProviderFilter = params.get('care_provider') || defaultCpFilter;
  const hospitalFilter = useMemo(
    () => params.getAll('hospital') || [LocationFilter.default],
    [params],
  );
  const marketFilter = useMemo(() => {
    const markets = params.getAll('markets');
    if (!markets.length) {
      return ALL_MARKETS;
    }
    return markets;
  }, [params]);
  const conditionFilter = params.get('condition') || ConditionFilter.default;
  const searchFilter = params.get('search');

  const apiSearchParams = useMemo<URLSearchParams>(() => {
    if (searchFilter) {
      // TODO: support dob search
      return new URLSearchParams({ fnormrn: searchFilter });
    }

    const searchParams = new URLSearchParams();
    if (careProviderFilter === CareProviderFilter.MyPatients) {
      searchParams.set('only_my_patients', 'true');
    }

    if (hospitalFilter) {
      hospitalFilter.forEach((location) => {
        if (location !== LocationFilter.AllLocations) {
          searchParams.append('hospital_id', location);
        }
      });
    }

    if (marketFilter) {
      marketFilter.forEach((market) => {
        if (market !== ALL_MARKETS_KEY) {
          searchParams.append('market_id', market);
        }
      });
    }

    if (conditionFilter !== ConditionFilter.AllConditions) {
      searchParams.set('condition', conditionFilter);
    }

    return searchParams;
  }, [
    searchFilter,
    careProviderFilter,
    hospitalFilter,
    marketFilter,
    conditionFilter,
  ]);

  const setParams = (
    name: string,
    value: string | string[],
    clearExistingParams?: boolean,
    state?: object,
  ) => {
    const newParams = new URLSearchParams(
      clearExistingParams ? undefined : locationSearch,
    );
    if (isArray(value)) {
      newParams.delete(name);
      value.forEach((location) => newParams.append(name, location));
    } else {
      newParams.set(name, value);
    }
    history.push({
      search: newParams.toString(),
      ...(state && { state }),
    });
  };

  const setCareProviderFilter = (value: CareProviderFilter) =>
    setParams('care_provider', value);

  const setHospitalFilter = (value: LocationFilter[] | string[]) => {
    setParams('hospital', value);
  };

  const setConditionFilter = (value: ConditionFilter | string) =>
    setParams('condition', value);

  const setMarketFilter = (value: string[]) => setParams('markets', value);

  const getPreviousLocationSearch = () =>
    (locationState as { previousLocationSearch: string })
      ?.previousLocationSearch ?? locationSearch;
  const setSearchFilter = (value: string) =>
    setParams('search', value, true, {
      previousLocationSearch: getPreviousLocationSearch(),
    });
  const clearSearchFilter = () => {
    const newParams = new URLSearchParams(getPreviousLocationSearch());
    newParams.delete('search');
    history.push({ search: newParams.toString() });
  };

  return {
    apiSearchParams,
    careProviderFilter,
    hospitalFilter,
    conditionFilter,
    marketFilter,
    searchFilter,
    setCareProviderFilter,
    setHospitalFilter,
    setConditionFilter,
    setSearchFilter,
    clearSearchFilter,
    setMarketFilter,
  };
}
