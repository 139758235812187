import { useHistory } from 'react-router-dom';

import { useWizardStateContext } from '@/shared/common/Wizard/state';
import { ConditionProgram } from '@/shared/types/condition.types';

import { useCNFormContext } from '../../../CNFormContext';
import { SharedVitalRecommendation } from '../../shared/VitalProgress/VitalRecommendation';
import { cycle17InitVisitPath } from '../paths';

export function VitalRecommendation() {
  const history = useHistory();
  const { program } = useCNFormContext();
  const { skipSectionStep } = useWizardStateContext();

  return (
    <SharedVitalRecommendation
      onNext={() => {
        const scriptPath = cycle17InitVisitPath('/action-plan', '/script');

        if (program === ConditionProgram.CHF) {
          skipSectionStep(scriptPath);
          skipSectionStep(cycle17InitVisitPath('/action-plan', '/set-goal'));
          history.push(
            cycle17InitVisitPath('/action-plan', '/education-modules-review'),
          );
        } else {
          history.push(scriptPath);
        }
      }}
    />
  );
}
