import { useIntl } from 'react-intl';

import { GoalUnmetReason } from './Notes.types';

export function useGoalUnmetReasonsI18n() {
  const intl = useIntl();
  return {
    [GoalUnmetReason.ProviderPreference]: intl.formatMessage({
      defaultMessage: 'Provider Preference',
    }),
    [GoalUnmetReason.PatientPreference]: intl.formatMessage({
      defaultMessage: 'Patient Preference',
    }),
    [GoalUnmetReason.MedicalReason]: intl.formatMessage({
      defaultMessage: 'Medical Reason',
    }),
    [GoalUnmetReason.FinancialReason]: intl.formatMessage({
      defaultMessage: 'Financial Reason',
    }),
    [GoalUnmetReason.Other]: intl.formatMessage({ defaultMessage: 'Other' }),
  };
}
