import type { SectionMeta, SectionPath, StepPath } from '../path.utils';
import { getFullStepPath } from '../path.utils';
import { useWizardStateContext } from './WizardStateContext';
import type { SectionStepsState } from './useWizardState';

export function useGetWizardStepValues<
  S extends Readonly<SectionMeta[]>,
  P extends SectionPath<S>,
>(sections: S, basePath: P, stepPath: StepPath<S, P>) {
  const {
    wizardFormData: { data },
  } = useWizardStateContext();

  return getWizardStepValues(data, sections, basePath, stepPath);
}

export function getWizardStepValues<
  S extends Readonly<SectionMeta[]>,
  P extends SectionPath<S>,
>(data: SectionStepsState, sections: S, basePath: P, stepPath: StepPath<S, P>) {
  return function getWizardStepVals<TVals>() {
    return data[getFullStepPath(sections, basePath, stepPath)]?.values as
      | TVals
      | undefined;
  };
}
