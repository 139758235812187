import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import {
  useWizardFormFromConfig,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';

import { useCNFormContext } from '../../../CNFormContext';
import { useShouldMoveUpActionPlan } from '../../../sectionUtils';
import { initVisitPath } from '../../initialVisit/paths';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { LastDiscussed } from '../../shared/LastDiscussed';
import { DailyLivingForm } from '../../shared/generalAssessment';
import type { DailyLivingFormFields } from '../../shared/generalAssessment/formConfig';
import { getDailyLivingFormConfig } from '../../shared/generalAssessment/formConfig';
import { regVisitPath } from '../paths';
import { useFindPreviousNoteWithPatientGoal } from './useFindPreviousNoteWithPatientGoal';

export function DailyLiving() {
  const intl = useIntl();
  const history = useHistory();
  const { skipSectionStep } = useWizardStateContext();

  const path = regVisitPath('/general-assessment', '/daily-living');
  const { patientId } = useCNFormContext();
  const { isLoading: isLoadingPrevDailyLiving, data } =
    useRecentNoteFormData<DailyLivingFormFields>([
      path,
      initVisitPath('/general-assessment', '/daily-living'),
    ]);
  const form = useWizardFormFromConfig<DailyLivingFormFields>(path, {
    ...getDailyLivingFormConfig(intl, data),
    triggerReset: !isLoadingPrevDailyLiving,
  });
  const { note: noteWithPatientGoal, isLoading: isLoadingNoteWithPrevGoal } =
    useFindPreviousNoteWithPatientGoal(patientId);

  const shouldMoveUpActionPlan = useShouldMoveUpActionPlan();

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      form={form}
      isLoading={
        isLoadingPrevDailyLiving ||
        (!shouldMoveUpActionPlan && isLoadingNoteWithPrevGoal)
      }
      title={intl.formatMessage({
        defaultMessage: 'Activities of Daily Living',
      })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        if (shouldMoveUpActionPlan) {
          history.push(regVisitPath('/scheduling', '/index'));
          return;
        }

        if (noteWithPatientGoal) {
          // if our patient did have a previous goal, we're skipping the step
          // related to no previous goals to trigger the progress bar.
          skipSectionStep(regVisitPath('/action-plan', '/no-prev-goal'));

          history.push(regVisitPath('/action-plan', '/prev-goal-progress'));
        } else {
          // if our patient did not have a previous goal, we're skipping the
          // steps related to previous goals to trigger the progress bar.
          skipSectionStep(regVisitPath('/action-plan', '/prev-goal-progress'));
          skipSectionStep(
            regVisitPath('/action-plan', '/prev-goal-progress/discuss'),
          );

          history.push(regVisitPath('/action-plan', '/no-prev-goal'));
        }
      }}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Finally, have you had any significant changes in your functional status, like any new assistance with walking, eating, using the bathroom, or getting dressed?" />
        </Script>
        <LastDiscussed hasData={!!data}>
          <DailyLivingForm
            form={form}
            requiresAssistanceLabel={
              <FormattedMessage defaultMessage="Do you require any assistance with walking, eating, using the bathroom, or getting dressed?" />
            }
          />
        </LastDiscussed>
      </FieldGroup>
    </Wizard.Step>
  );
}
