import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { useCarePlanDraft } from '@/shared/hooks/queries/carePlan.queries';
import { grpcNameToId } from '@/shared/utils/grpc';

import { TypeOfEncounter } from '../../Notes.types';
import { ConfirmationDialog } from '../dialogs';
import type { ChangeEncounterTypeState } from './TypeOfEncounterForm';
import { WarnAssociatedCarePlanDialog } from './WarnAssociatedCarePlanDialog';

type Props = {
  state: ChangeEncounterTypeState;
  onClose: () => void;
};

export function ConfirmEncounterTypeChangeDialog({ state, onClose }: Props) {
  const intl = useIntl();
  const { params } = useRouteMatch<{ patientId: string }>();
  const { data, isLoading } = useCarePlanDraft(params.patientId, {
    enabled:
      state.modalOpen &&
      state.type === 'default' &&
      state.prevEncounterType === TypeOfEncounter.CCM_CARE_PLAN,
  });

  if (!state.modalOpen) {
    return null;
  }

  const { modalOpen: isOpen, onConfirm } = state;

  // Check if current CCM Care Plan Encounter is associated with a care plan
  if (
    isLoading ||
    (state.type === 'default' &&
      state.prevEncounterType === TypeOfEncounter.CCM_CARE_PLAN &&
      state.noteId === data?.noteId)
  ) {
    return (
      <WarnAssociatedCarePlanDialog
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        carePlanId={grpcNameToId(data?.name ?? '')}
      />
    );
  }

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      dialogTitle={intl.formatMessage({
        defaultMessage: 'Change encounter type',
      })}
      dialogDescription={intl.formatMessage({
        defaultMessage:
          'Are you sure you want to change encounter types? If you change encounter types some of the content entered could be lost.',
      })}
      cancelButtonText={intl.formatMessage({ defaultMessage: 'Close' })}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Change encounter type',
      })}
    />
  );
}
