import cx from 'classnames';
import { useRef } from 'react';
import { useButton } from 'react-aria';
import { FormattedMessage } from 'react-intl';

import { Pause, Play } from '@/shared/assets/svgs';

import { container, counter, icon, unit } from './Timer.css';

type Ms = number;

type Urgency = 'none' | 'moderate' | 'severe';

export type Props = {
  totalTime: Ms;
  timerRunning: boolean;
  isPauseable: boolean;
  onToggle: () => void;
  className?: string;
  urgency?: Urgency;
};

export function Timer({
  totalTime,
  timerRunning,
  isPauseable,
  onToggle,
  className,
  urgency = 'none',
}: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: isPauseable ? onToggle : undefined,
    },
    ref,
  );
  const minutes = Math.floor(totalTime / 1000 / 60);
  const Icon = timerRunning ? Pause : Play;

  return (
    <button
      type="button"
      ref={ref}
      {...(isPauseable && buttonProps)}
      className={cx(className, container[urgency], {
        [container.running]: timerRunning,
        [container.paused]: !timerRunning,
        [container.notPauseable]: !isPauseable,
      })}
    >
      <span>
        <FormattedMessage
          defaultMessage="<counter>{minutes}</counter> <unit>min</unit>"
          values={{
            minutes: minutes.toString().padStart(2, '0'),
            counter: (text: string) => (
              <span className={counter[urgency]}>{text}</span>
            ),
            unit: (text: string) => (
              <span className={unit[urgency]}>{text}</span>
            ),
          }}
        />
      </span>
      {isPauseable && <Icon className={icon} />}
    </button>
  );
}
