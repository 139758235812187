import { FormattedMessage } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { usePatientDetails } from '@/shared/hooks/queries';
import { Button } from '@/shared/tempo/atom/Button';

import { container } from '../CarePlanPreview.css';
import { addressContainer } from './ConfirmAddressModal.css';

type Props = {
  patientId: string;
  onClose: () => void;
  onConfirm: () => void;
};

export function ConfirmAddressModal({ patientId, onClose, onConfirm }: Props) {
  const { data: patient, isLoading } = usePatientDetails(
    patientId ?? '',
    true,
    !!patientId,
  );

  const primaryAddress = patient?.addresses?.find(({ primary }) => primary);
  return (
    <Modal open onClose={onClose} isLoading={isLoading}>
      <Modal.Header
        title={
          <FormattedMessage defaultMessage="Confirm the patient's mailing address" />
        }
      />
      <Modal.Body>
        <div className={container}>
          <div>
            <FormattedMessage defaultMessage="Please confirm the patient's mail address to have this care plan printed and mailed directly to the patient." />
          </div>
          <div>
            <FormattedMessage defaultMessage="Update the patient's profile to edit the mailing address if not correct." />
          </div>
          <div className={addressContainer}>
            {primaryAddress ? (
              <>
                <div>
                  {[patient?.patient?.givenName, patient?.patient?.familyName]
                    .filter(Boolean)
                    .join(' ')}
                </div>
                {primaryAddress.streetAddress} {primaryAddress.premise}
                <div>
                  {primaryAddress.locality}, {primaryAddress.region}{' '}
                  {primaryAddress.postalCode}
                </div>
              </>
            ) : (
              <div>
                <FormattedMessage defaultMessage="Patient is missing primary address" />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={() => onClose()}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button onPress={onConfirm}>
          <FormattedMessage defaultMessage="Confirm & Mail" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
