import { useState } from 'react';

import FileIcon from '@/shared/assets/svgs/file.svg?react';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { NoteType } from '@/shared/types/note.types';

import { usePatientNotes } from '../note.queries';
import { RecentNotesDialog } from './RecentNotesDialog';

type Props = {
  patientId: string;
};

export function RecentNotesButton({ patientId }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const noteTypes = [NoteType.Standard, NoteType.AlertResolution];
  const { items: notes } = usePatientNotes(patientId, { noteTypes });
  const hasNotes = notes.length > 0;

  return (
    <>
      {modalOpen && (
        <RecentNotesDialog
          patientId={patientId}
          noteTypes={noteTypes}
          onClose={() => setModalOpen(false)}
        />
      )}
      <IconButton
        size="small"
        variant="secondary"
        isDisabled={!hasNotes}
        onPress={() => setModalOpen(true)}
      >
        <FileIcon width="20" height="20" />
      </IconButton>
    </>
  );
}
