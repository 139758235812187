import cx from 'classnames';
import type { ComponentType, ReactNode } from 'react';

import { Box } from '@/deprecated/mui';
import { Spinner } from '@/shared/tempo/atom/Spinner';

import {
  displayContents,
  hidden,
  loadingPlaceholder,
  overlay,
} from './styles.css';

type LoadingPlaceholderProps = {
  children?: ReactNode;
  className?: string;
  isLoading: boolean;
  placeholder?: ReactNode;
  wrapper?: ComponentType;
  keepMounted?: boolean;
};

export function LoadingPlaceholder({
  children,
  isLoading,
  className,
  wrapper,
  placeholder,
  keepMounted,
}: LoadingPlaceholderProps) {
  const Wrapper = wrapper ?? Box;

  const loader = placeholder || (
    <Spinner className={cx({ [overlay]: keepMounted })} />
  );

  return (
    // In order for keepMounted to truly prevent the children from being re-mounted, the
    // wrapper component must remain the same element for the lifecycle of the component.
    <Wrapper
      className={cx(
        /*
         * When we aren't loading, use displayContents to ensure the wrapping component
         * doesn't interrupt the styling of the child contents.
         */
        { [loadingPlaceholder]: isLoading, [displayContents]: !isLoading },
        className,
      )}
    >
      {isLoading && loader}
      {(!isLoading || (isLoading && keepMounted)) && (
        <div
          className={cx({ [hidden]: isLoading, [displayContents]: !isLoading })}
        >
          {children}
        </div>
      )}
    </Wrapper>
  );
}
