import cx from 'classnames';
import type { ReactNode } from 'react';

import {
  infoRow,
  infoRowRight,
  infoRowSubdued,
  infoRowSubtext,
} from './InfoRow.css';

type Props = {
  left?: ReactNode;
  leftSubtext?: ReactNode;
  right?: ReactNode;
  rightSubtext?: ReactNode;
  leftSubdued?: boolean;
  rightSubdued?: boolean;
};

export function InfoRow({
  left,
  leftSubdued,
  leftSubtext,
  right,
  rightSubdued,
  rightSubtext,
}: Props) {
  return (
    <div className={infoRow}>
      <div>
        <span className={cx({ [infoRowSubdued]: leftSubdued })}>{left}</span>
        {leftSubtext && <span className={infoRowSubtext}>{leftSubtext}</span>}
      </div>
      <div className={infoRowRight}>
        <span className={cx({ [infoRowSubdued]: rightSubdued })}>{right}</span>
        {rightSubtext && <span className={infoRowSubtext}>{rightSubtext}</span>}
      </div>
    </div>
  );
}
