import type { AnyAction } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { batchDispatchMiddleware, enableBatching } from 'redux-batched-actions';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer } from 'redux-persist';
import type { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

import { epics } from './epics';
import initialState from './initialState';
import reducers from './reducers';
import type { RootState } from './redux.types';

const composeEnhancers =
  window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  transforms: [],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore<
  RootState & PersistPartial,
  AnyAction,
  unknown,
  unknown
>(
  enableBatching(persistedReducer),
  initialState,
  composeEnhancers(applyMiddleware(epicMiddleware, batchDispatchMiddleware)),
);

epicMiddleware.run(epics);
