import cx from 'classnames';
import type { ReactNode } from 'react';

import type { BaseFieldProps } from '@/shared/common/Form/fields/BaseField';
import { BaseField } from '@/shared/common/Form/fields/BaseField';
import { MinutesPicker } from '@/shared/common/MinutesPicker';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import {
  container,
  errorContainer,
  errorLabel,
  headerDescription,
  headerTextContainer,
  iconContainer,
  labelContainer,
  minutesPickerContainer,
  tooltip as tooltipClass,
} from './TimeTrackerMinutesField.css';

type Props = {
  min?: number;
  icon: ReactNode;
  description?: ReactNode;
  tooltip?: string;
} & BaseFieldProps;

export function TimeTrackerMinutesField({
  min = 0,
  icon,
  label,
  description,
  tooltip,
  isDisabled,
  ...props
}: Props) {
  return (
    <div className={flexContainer.row}>
      <div className={iconContainer}>{icon}</div>
      <BaseField {...props}>
        {({ controller, renderError }) => (
          <div className={container}>
            <div className={headerTextContainer}>
              <div
                className={cx(labelContainer, {
                  [errorLabel]: !!controller.fieldState.error,
                })}
              >
                {label} *
              </div>
              <div className={headerDescription}>{description}</div>
              <div className={errorContainer}>{renderError()}</div>
            </div>
            <div className={minutesPickerContainer}>
              <Tooltip
                content={tooltip}
                placement="left"
                className={tooltipClass}
              >
                <MinutesPicker
                  min={min}
                  isDisabled={isDisabled}
                  error={!!controller.fieldState.error}
                  {...controller.field}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </BaseField>
    </div>
  );
}
