export function SlackLink() {
  // no point in i18n for the channel name since it won't change regardless
  return (
    <a
      href="https://cadence-tyz9473.slack.com/archives/C03HMTR19MH"
      target="_blank"
      rel="noreferrer"
    >
      #help-triage
    </a>
  );
}
