import { INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedChartPrep } from '../../shared/ChartPrep';
import { initVisitPath } from '../paths';

export function ChartPrep() {
  return (
    <SharedChartPrep
      sections={INIT_VISIT_SECTIONS}
      nextPath={initVisitPath('/intro', '/patient-attendance')}
    />
  );
}
