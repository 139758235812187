import type { BaseFieldProps } from '../BaseField';
import { TextField } from '../TextField';

const PHONE_MASK = '{+1} (000) 000-0000';

// onChange is purposefully omitted here
// there's a bug where once onChange is passed in, the mask
// receives two updates for every one change, and the 2nd
// update pass in an outdated value
export function PhoneNumber({
  ...baseProps
}: Omit<BaseFieldProps, 'onChange'>) {
  return <TextField {...baseProps} mask={PHONE_MASK} />;
}

export function UnmaskedPhoneNumber({ ...baseProps }: BaseFieldProps) {
  return <TextField {...baseProps} />;
}
