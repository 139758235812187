import type { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ChevronLeft } from '@/shared/assets/svgs';
import { Button } from '@/shared/tempo/atom/Button';

import type { StepFormValues } from '../state';
import type { BackBtnRenderFn } from './Step';

type BackButtonProps<TFieldValues extends FieldValues> = {
  backButton?: BackBtnRenderFn | false;
  submitWithTiming: () => TFieldValues | StepFormValues;
};

export function BackButton<TFieldValues extends FieldValues>({
  backButton,
  submitWithTiming,
}: BackButtonProps<TFieldValues>) {
  const history = useHistory();
  function goBack() {
    submitWithTiming();
    history.goBack();
  }

  if (backButton) {
    return <>{backButton({ onBack: goBack })}</>;
  }

  return (
    <Button size="small" variant="tertiary" onPress={goBack}>
      <Button.Icon>
        <ChevronLeft />
      </Button.Icon>
      <FormattedMessage defaultMessage="Previous" />
    </Button>
  );
}
