import { useEffect, useMemo, useRef } from 'react';
import { Overlay, useOverlay, useOverlayTrigger } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';

import { MODAL_OPEN_ATTR } from '@/shared/common/Modal';
import { useEscHotkey } from '@/shared/hooks';

import { settings } from '../../../config';
import { logger } from '../../../logger';
import {
  container,
  hiddenModal,
  modal,
  twilioFlexIframeContainer,
} from './TwilioFlex.css';
import { useTwilioFlexContext } from './TwilioFlexContext';

type Props = {
  state: OverlayTriggerState;
};

export function TwilioFlexModal({ state }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  const twilioFlexUrl = settings.VITE_TWILIO_FLEX_URL;
  const newIframeRef = useRef<HTMLIFrameElement>(null);
  const { setIframeRef } = useTwilioFlexContext();

  useEffect(() => {
    setIframeRef(newIframeRef);
    if (!twilioFlexUrl) {
      logger.error(
        'Environment variable VITE_TWILIO_FLEX_URL was expected but not found',
      );
    }
  }, [twilioFlexUrl, setIframeRef]);

  const { overlayProps: modalProps } = useOverlay(
    {
      isDismissable: true,
      isOpen: state.isOpen,
      onClose: () => {
        state.close();
      },
      shouldCloseOnInteractOutside() {
        return !document.querySelector(`[${MODAL_OPEN_ATTR}="true"]`);
      },
      isKeyboardDismissDisabled: true,
    },
    ref,
  );

  useEscHotkey(
    () => {
      state.close();
      return true;
    },
    [],
    { enableOnFormTags: false, enableOnContentEditable: false },
  );

  const modalClassName = useMemo(
    () => `${modal} ${state.isOpen ? '' : hiddenModal}`,
    [state.isOpen],
  );

  return (
    <Overlay>
      <div {...modalProps} ref={ref} className={modalClassName}>
        <div {...overlayProps} className={container}>
          <div className={twilioFlexIframeContainer}>
            <iframe
              ref={newIframeRef}
              allow="geolocation;autoplay;microphone;display-capture;midi;encrypted-media;clipboard-write;"
              id="twilio-flex-cadence-iframe"
              src={`${twilioFlexUrl}/?path=/agent-desktop`}
              title="Twilio Flex"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </Overlay>
  );
}
