import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { ReferenceMedication } from '@/shared/generated/grpcGateway/medication.pb';

import { container, section } from './medicationDetails.css';

type Props = {
  referenceMed: ReferenceMedication;
};

export function MedicationDetails({ referenceMed }: Props) {
  const { requiredLabs, sideEffects } = referenceMed;

  if (!requiredLabs && !sideEffects) {
    return null;
  }

  return (
    <div className={container}>
      {requiredLabs && (
        <Section
          header={<FormattedMessage defaultMessage="Required labs" />}
          copy={requiredLabs}
        />
      )}
      {sideEffects && (
        <Section
          header={<FormattedMessage defaultMessage="Side effects" />}
          copy={sideEffects}
        />
      )}
    </div>
  );
}

function Section({ header, copy }: { header: ReactNode; copy: string }) {
  return (
    <div>
      <h6 className={section.header}>{header}</h6>
      <p className={section.copy}>{copy}</p>
    </div>
  );
}
