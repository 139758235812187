import { type AxiosError, HttpStatusCode } from 'axios';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { AppointmentAvailability } from '@/shared/generated/grpcGateway/scheduling.pb';
import { Select } from '@/shared/tempo/@labs/molecule/Select';
import { Button } from '@/shared/tempo/atom/Button';

import { selectContainer } from './AvailabilitySelector.css';
import { BoxContainer } from './BoxContainer';
import { ErrorDisplay } from './ErrorDisplay';
import { DateSelector } from './dateSelectors/DateSelector';

type Props = {
  isLoading?: boolean;
  fetchError: AxiosError;
  searchDate: Date;
  setSearchDate: (date: Date) => void;
  availabilities: AppointmentAvailability[];
  onSelect: (availability: AppointmentAvailability) => void;
  formatInPatientTimezone: (
    date: Maybe<string | Date>,
    fmt: string,
  ) => Nullable<string>;
};

export function AvailabilitySelector({
  fetchError,
  searchDate,
  setSearchDate,
  availabilities,
  onSelect,
  formatInPatientTimezone,
  isLoading,
}: Props) {
  const intl = useIntl();
  const slots = useMemo(
    () => orderBy(availabilities, 'datetime', 'asc'),
    [availabilities],
  );
  const [slot, setSlot] = useState<Maybe<AppointmentAvailability>>(slots[0]);
  const formattedDate = format(searchDate, 'eeee, MMM d');

  useEffect(() => {
    setSlot(slots[0]);
    // Reset the initial value whenever available slots change
  }, [slots]);

  const dateSelector = (
    <DateSelector value={searchDate} onChange={setSearchDate} />
  );

  if (!isLoading) {
    if (fetchError) {
      let errorMsg = (
        <FormattedMessage
          defaultMessage="Failed to check availability for date {date}."
          values={{ date: formattedDate }}
        />
      );
      if (fetchError.response?.status === HttpStatusCode.NotFound) {
        errorMsg = (
          <div>
            <div>
              <FormattedMessage defaultMessage="Failed to check availability for patient." />
            </div>
            <div>
              <FormattedMessage defaultMessage="Please ensure patient is assigned to a hospital and associated with an EHR." />
            </div>
          </div>
        );
      }
      return (
        <ErrorDisplay>
          {dateSelector}
          {errorMsg}
        </ErrorDisplay>
      );
    }
    if (!slots.length) {
      return (
        <ErrorDisplay>
          {dateSelector}
          <FormattedMessage
            defaultMessage="Please select another date. There are no available times on {date}."
            values={{ date: format(searchDate, 'MMM d') }}
          />
        </ErrorDisplay>
      );
    }
  }

  return (
    <BoxContainer>
      {dateSelector}
      <div className={selectContainer}>
        <Select
          placeholder={
            isLoading
              ? intl.formatMessage({ defaultMessage: 'Loading...' })
              : undefined
          }
          isDisabled={isLoading}
          selectedKey={!slot ? slots[0]?.datetime : slot.datetime}
          onSelectionChange={(key) =>
            setSlot(slots.find((a) => a.datetime === key))
          }
          items={slots}
          aria-label={intl.formatMessage({
            defaultMessage: 'Appointments',
          })}
        >
          {(item) => (
            <Select.Option key={item.datetime}>
              {formatInPatientTimezone(item.datetime, 'h:mm a')}
            </Select.Option>
          )}
        </Select>
      </div>
      <Button
        isProcessing={isLoading}
        isDisabled={!slot}
        variant="secondary"
        size="small"
        onPress={() => {
          if (slot) {
            onSelect(slot);
          }
        }}
      >
        <FormattedMessage defaultMessage="Select" />
      </Button>
    </BoxContainer>
  );
}
