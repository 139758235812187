import type { ReactNode } from 'react';

import { label } from './Label.css';

type Props = {
  children: ReactNode;
};

export function Label({ children }: Props) {
  return <span className={label}>{children}</span>;
}
