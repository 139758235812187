import type { QueryKey, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { PatientContext } from '@/shared/generated/grpcGateway/pms.pb';
import { PatientService } from '@/shared/generated/grpcGateway/pms.pb';

const RPM_PATIENT_QUERY_KEY_BASE = ['rpm', 'api', 'v1', 'patients'] as const;

const patientKeys = {
  context: (patientId: string) =>
    [...RPM_PATIENT_QUERY_KEY_BASE, patientId, 'context'] as const,
};

export function usePatientContext(
  patientId: string,
  params: UseQueryOptions<PatientContext> = {},
) {
  return useQuery(
    patientKeys.context(patientId) as QueryKey,
    () => PatientService.GetPatientContext({ name: patientId }),
    { ...params, enabled: params.enabled && Boolean(patientId) },
  );
}
