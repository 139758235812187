import { ParentView } from '@/shared/tasking/TaskList';
import { useTaskListContext } from '@/shared/tasking/TaskList/taskListContext';

export function useShouldShowPatientDetails() {
  const { parentView } = useTaskListContext();

  return (
    parentView === ParentView.TaskHub || parentView === ParentView.HomePage
  );
}
