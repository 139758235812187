import type { DefaultValues } from 'react-hook-form';
import * as yup from 'yup';

import type { CyclicalDependencies, FieldsMap } from './form.types';

type Options = {
  includeUndefinedVals?: boolean;
};

export function getDefaults<TFieldValues>(
  fieldMap: FieldsMap,
  options: Options = {},
) {
  return Object.entries(fieldMap).reduce((acc, [fieldName, field]) => {
    if (
      options.includeUndefinedVals ||
      typeof field?.defaultValue !== 'undefined'
    ) {
      return Object.assign(acc, {
        [fieldName]: field?.defaultValue,
      });
    }

    return acc;
  }, {} as DefaultValues<TFieldValues>);
}

export function getValidationSchema(
  fieldMap: FieldsMap,
  cyclicalDependencies?: CyclicalDependencies,
) {
  const fieldSchemas = Object.fromEntries(
    Object.entries(fieldMap).map(([fieldName, fieldConfig]) => [
      fieldName,
      fieldConfig.validation ?? yup.mixed(),
    ]),
  );
  return yup.object().shape(fieldSchemas, cyclicalDependencies);
}
