import classnames from 'classnames/bind';
import { format, parseISO } from 'date-fns';

import styles from './medicalInfo.module.scss';

const cx = classnames.bind(styles);

type MedicalInfoRowProps = {
  author: string;
  createdAt: string;
  note: string;
};

export function MedicalInfoRow({
  author,
  createdAt,
  note,
}: MedicalInfoRowProps) {
  return (
    <li>
      <div className={cx('medical-info-container')}>
        <span>{note}</span>
        <span className={cx('medical-info-author')}>
          {author} {format(parseISO(createdAt), 'MM/dd/yyyy')}
        </span>
      </div>
    </li>
  );
}
