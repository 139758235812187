import cx from 'classnames';
import { subDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory, useParams } from 'react-router-dom';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { MODAL_OPEN_ATTR } from '@/shared/common/Modal';
import { Page } from '@/shared/common/Page';
import { noScrollContainer } from '@/shared/common/Page/Page.css';
import { usePatientTitle } from '@/shared/common/Page/useDocumentTitle.hooks';
import {
  useFetchPatientDetails,
  useFlags,
  useIsCnExperience,
} from '@/shared/hooks';
import { useWasPatientEnrolled } from '@/shared/hooks/queries';
import { TaskModal } from '@/shared/tasking/TaskModal';
import { useCurrentRoutedTab } from '@/shared/tempo/@labs/atom/Tabs';
import type { RouteParam } from '@/shared/types/route.types';

import { NumberOfPatientInfoColumnsProvider } from '../PatientInfoSummary/numberOfPatientInfoColumns';
import { ConfiguredNoteEditorContextProvider } from '../patientDetails/ui/Notes/NoteEditorProvider';
import { CNNotesSidebarPanel } from './CNNotesSidebarPanel';
import { DebugModal } from './DebugModal';
import { smallDrawer } from './NoteDrawer/NoteDrawer.css';
import { PatientDetailsProvider } from './PatientDetailContext';
import { PatientNotesSidebarPanel } from './PatientNotesSidebarPanel';
import { NotesLoadingStateContextProvider } from './PatientNotesSidebarPanel/NotesLoadingStateProvider';
import { PatientOverview } from './PatientOverview';
import { bodyContainer, overflowHidden, page } from './PatientProfile.css';
import { PatientProfileContent, Tabs } from './PatientProfileContent';
import { PatientProfileHeader } from './PatientProfileHeader';
import {
  PatientProfileModal,
  PatientProfileProvider,
} from './context/PatientProfileCtx';

export function PatientProfile() {
  const { patientId }: RouteParam = useParams();
  const { debugTools } = useFlags();
  const { isCnExperience, isLoading: isLoadingCnExperienceCheck } =
    useIsCnExperience();

  const { patientDetails, isLoading, setPatientDetails } =
    useFetchPatientDetails(patientId);

  // Values for PatientProfileProvider
  const [vitalsStartDate, setVitalsStartDate] = useState(
    subDays(new Date(), 6), // Getting vitals for 7 days: from 6 days ago to today
  );
  const [vitalsEndDate, setVitalsEndDate] = useState(new Date());
  const [currentModal, setCurrentModal] =
    useState<Nullable<PatientProfileModal>>(null);

  useHotkeys(
    ['meta+shift+i'],
    () => setCurrentModal(PatientProfileModal.Debug),
    {
      enabled: () =>
        debugTools && !document.querySelector(`[${MODAL_OPEN_ATTR}="true"]`),
    },
  );

  const patientDocumentTitle = usePatientTitle(
    patientDetails.first_name,
    patientDetails.last_name,
  );

  const currentPatientTab = useCurrentRoutedTab(
    Object.values(Tabs),
    Tabs.Vitals,
  );

  const history = useHistory();
  const wasPatientEnrolled = useWasPatientEnrolled(patientId);
  if (wasPatientEnrolled === false) {
    history.push(`/admin/patient/${patientId}`);
    return null;
  }

  return (
    <>
      <PatientProfileProvider
        // key is important here to ensure we re-render the tree
        // when switching patients.
        key={patientId}
        value={{
          vitalsStartDate,
          setVitalsStartDate,
          vitalsEndDate,
          setVitalsEndDate,
          currentModal,
          setCurrentModal,
        }}
      >
        <PatientDetailsProvider value={{ patientDetails, setPatientDetails }}>
          <ConfiguredNoteEditorContextProvider patientId={patientId}>
            <Page
              variant="deprecated"
              classes={{ page, container: noScrollContainer }}
              documentTitle={patientDocumentTitle}
              sidePanel={
                <LoadingPlaceholder
                  className={smallDrawer}
                  isLoading={isLoadingCnExperienceCheck}
                >
                  {isCnExperience ? (
                    <CNNotesSidebarPanel />
                  ) : (
                    <NotesLoadingStateContextProvider>
                      <PatientNotesSidebarPanel />
                    </NotesLoadingStateContextProvider>
                  )}
                </LoadingPlaceholder>
              }
            >
              <LoadingPlaceholder
                isLoading={
                  isLoading || isEmpty(patientDetails) || !wasPatientEnrolled
                }
              >
                {patientDetails && (
                  <>
                    <PatientProfileHeader patientId={patientId}>
                      <NumberOfPatientInfoColumnsProvider>
                        <PatientOverview patientId={patientId} />
                      </NumberOfPatientInfoColumnsProvider>
                    </PatientProfileHeader>
                    <Page.Body
                      className={cx(bodyContainer, {
                        [overflowHidden]: currentPatientTab === Tabs.Messages,
                      })}
                    >
                      <PatientProfileContent />
                    </Page.Body>
                  </>
                )}
              </LoadingPlaceholder>
            </Page>
          </ConfiguredNoteEditorContextProvider>
        </PatientDetailsProvider>
      </PatientProfileProvider>
      {currentModal === PatientProfileModal.CreateTask && (
        <TaskModal
          open
          patientId={patientId}
          onClose={() => setCurrentModal(null)}
        />
      )}
      <DebugModal
        open={currentModal === PatientProfileModal.Debug}
        patientId={patientId}
        onClose={() => setCurrentModal(null)}
      />
    </>
  );
}
