import { useLDClient } from 'launchdarkly-react-client-sdk';
import * as murmurhash from 'murmurhash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthState } from '@/pages/auth/auth.selectors';

export const useLDClientIdentify = () => {
  const LDClient = useLDClient();
  const { isAuthenticated, email } = useSelector(selectAuthState);
  const [ldError, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (isAuthenticated) {
      LDClient?.identify(
        {
          key: String(murmurhash.v2(email)),
          email,
        },
        undefined,
        (error) => setError(error),
      );
    } else {
      LDClient?.identify({ anonymous: true });
    }
  }, [isAuthenticated, email, LDClient]);

  return { ldError };
};
