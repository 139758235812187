import type { CSSProperties } from 'react';
import type { PlainChildrenProps } from 'react-intersection-observer';
import InView from 'react-intersection-observer';
import type { UseInfiniteQueryResult } from 'react-query';

type Props = {
  query: UseInfiniteQueryResult;
  loadingPlaceholder?: React.ReactNode;
  height?: CSSProperties['height'];
  as?: PlainChildrenProps['as'];
};

export function InfiniteScrollLoader({
  query,
  loadingPlaceholder,
  height,
  as = 'div',
}: Props) {
  if (!query.hasNextPage) {
    return null;
  }

  return (
    <>
      <InView
        as={as}
        style={{ minHeight: height }}
        onChange={(isInView) => {
          if (isInView && query.hasNextPage) {
            query.fetchNextPage();
          }
        }}
      />
      {query.isFetchingNextPage && loadingPlaceholder}
    </>
  );
}
