import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { resultTableBody } from './results.css';

export function ResultBody({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody {...props} className={cx(resultTableBody, className)}>
      {children}
    </tbody>
  );
}
