export const ALL_VALUE = '';

/**
 *  Returns list that accounts for special All behavior
 *  @param values - Original list stored in Select component.
 *  Values are in the order that they were clicked
 * */
export function getFilteredItemValues(values: string[]) {
  // Clears any existing items if user clicks All option
  if (values.length > 1 && values[values.length - 1] === ALL_VALUE) {
    return [];
  }
  // Clears All option the first time user clicks an item
  if (values.length > 1 && values[0] === ALL_VALUE) {
    return values.slice(1);
  }
  // Clears any existing items if all selected items are unclicked
  if (values.length === 0 || (values.length === 1 && values[0] === ALL_VALUE)) {
    return [];
  }

  // Otherwise no transformation is needed
  return values;
}
