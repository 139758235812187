import type { TreeState } from '@react-stately/tree';
import type { Node } from '@react-types/shared';
import type { AriaMenuSectionProps } from 'react-aria';
import { useMenuSection, useSeparator } from 'react-aria';

import {
  divider,
  listReset,
} from '@/shared/tempo/shared/styles/listStyles.css';

import { MenuItem } from './MenuItem';
import { section as sectionClass } from './MenuSection.css';

type Props<T> = {
  section: Node<T>;
  state: TreeState<T>;
} & Omit<AriaMenuSectionProps, 'isVirtualized'>;

export function MenuSection<T>({ section, state }: Props<T>) {
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const { separatorProps } = useSeparator({
    elementType: 'li',
  });

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className={divider} />
      )}
      <li {...itemProps}>
        {section.rendered && (
          <div {...headingProps} className={sectionClass.text}>
            {section.rendered}
          </div>
        )}
        <ul {...groupProps} className={listReset}>
          {[...section.childNodes].map((node) => (
            <MenuItem key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}
