import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ChevronUp from '@/shared/assets/svgs/chevron-up.svg?react';
import ChevronDown from '@/shared/assets/svgs/chevron.svg?react';
import PlusIcon from '@/shared/assets/svgs/plus.svg?react';
import { useListCcmGoals } from '@/shared/hooks/queries/ccmGoals.queries';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';

import { GoalCard } from '../GoalCard';
import { type Goal, GoalStatus } from '../goals.types';
import { EmptyState } from './EmptyState';
import {
  container,
  grid,
  gridWrapper,
  header,
  placeholderCard,
} from './GoalsGrid.css';

type Props = {
  patientId: string;
  onEditGoal: (goal: Goal) => void;
  onCreateGoal: () => void;
};

export function GoalsGrid({ patientId, onCreateGoal, onEditGoal }: Props) {
  const intl = useIntl();
  const { data, isLoading: isLoadingGoals } = useListCcmGoals({
    filter: `patientId="${patientId}"`,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const gridRef = useRef<Nullable<HTMLDivElement>>(null);
  const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const [numOfColumns, setNumOfColumns] = useState(0);

  function calculateColumns() {
    if (!gridRef.current) return;
    const gridElement = gridRef.current;
    const computedStyle = window.getComputedStyle(gridElement);
    const columnCount = computedStyle
      .getPropertyValue('grid-template-columns')
      .split(' ').length;
    setNumOfColumns(columnCount);
  }

  const goals = processGoals(data?.ccmGoals);
  const completedGoals = goals.filter(
    (g) => g.status === GoalStatus.COMPLETE,
  ).length;
  const activeGoals = goals.filter(
    (g) =>
      ![GoalStatus.ABANDONED, GoalStatus.COMPLETE].includes(
        g.status as GoalStatus,
      ),
  ).length;

  const numOfGoals = goals.length;
  const numOfRows = useMemo(
    () => Math.ceil(numOfGoals / numOfColumns),
    [numOfGoals, numOfColumns],
  );

  // Use ResizeObserver to watch for changes in the size of the gridWrapper
  useOnMount(() => {
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => calculateColumns());
    resizeObserver.observe(wrapperRef.current);
    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  });
  useEffect(() => {
    // Calculate column count when the component goals change
    calculateColumns();
  }, [goals]);

  if (isLoadingGoals) {
    return (
      <>
        <div className={header.container}>
          <div className={header.titleContainer}>
            <div className={header.title}>
              <FormattedMessage defaultMessage="Goals" />
            </div>
          </div>
        </div>
        <div className={gridWrapper} ref={wrapperRef}>
          <div
            className={isExpanded ? grid.expanded : grid.collapsed}
            ref={gridRef}
          >
            {[...Array(numOfColumns)].map((idx) => (
              <div className={placeholderCard} key={idx}>
                <Skeleton variant="rectangular" width="100%" height="100%" />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  if (!numOfGoals) {
    return (
      <div className={container}>
        <EmptyState onCreateGoal={onCreateGoal} />
      </div>
    );
  }

  return (
    <>
      <div className={header.container}>
        <div className={header.titleContainer}>
          <div className={header.title}>
            <FormattedMessage defaultMessage="Goals" />
          </div>
          <div className={header.activeGoals}>
            {[
              activeGoals > 0 &&
                intl.formatMessage(
                  { defaultMessage: '{activeGoals} active' },
                  { activeGoals },
                ),
              completedGoals > 0 &&
                intl.formatMessage(
                  { defaultMessage: '{completedGoals} completed' },
                  { completedGoals },
                ),
            ]
              .filter(Boolean)
              .join(' • ')}
          </div>
        </div>
        <Button size="small" variant="tertiary" onPress={onCreateGoal}>
          <Button.Icon>
            <PlusIcon />
          </Button.Icon>
          <FormattedMessage defaultMessage="Create Goal" />
        </Button>
      </div>
      <div className={gridWrapper} ref={wrapperRef}>
        <div
          className={isExpanded ? grid.expanded : grid.collapsed}
          ref={gridRef}
        >
          {goals.map((g) => (
            <GoalCard key={g.name} goal={g} onEdit={() => onEditGoal(g)} />
          ))}
        </div>
        {numOfRows > 1 && (
          <div className={flexContainer.center}>
            <Button
              variant="tertiary"
              onPress={() => setIsExpanded(!isExpanded)}
            >
              <Button.Icon>
                {isExpanded ? <ChevronUp /> : <ChevronDown />}
              </Button.Icon>
              {isExpanded ? (
                <FormattedMessage defaultMessage="Show Less" />
              ) : (
                <FormattedMessage
                  defaultMessage="Show {hiddenAmount} More"
                  values={{ hiddenAmount: numOfGoals - numOfColumns }}
                />
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

function processGoals(goals: Maybe<Goal[]>) {
  const statusPriority: Record<GoalStatus, number> = {
    [GoalStatus.OPEN]: 1,
    [GoalStatus.IN_PROGRESS]: 2,
    [GoalStatus.ABANDONED]: 3,
    [GoalStatus.COMPLETE]: 4,
    [GoalStatus.GOAL_STATUS_UNSPECIFIED]: 5,
  };
  if (!goals) {
    return [];
  }
  return orderBy(goals, [(goal) => statusPriority[goal.status as GoalStatus]]);
}
