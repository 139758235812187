import { ResponsivePopper } from './Popper';
import { TypeaheadSearch as TypeaheadSearchComponent } from './TypeaheadSearch';
import { ResultBody } from './results/ResultBody';
import { ResultCell } from './results/ResultCell';
import { ResultHeaderCell } from './results/ResultHeaderCell';
import { ResultHeaderRow } from './results/ResultHeaderRow';
import { ResultRow } from './results/ResultRow';
import { ResultTable } from './results/ResultTable';
import { resultTableCellTruncate } from './results/results.css';

export type { TypeaheadSearchProps } from './TypeaheadSearch';

export const TypeaheadSearch = Object.assign(TypeaheadSearchComponent, {
  ResponsivePopper,
  ResultTable,
  ResultHeaderRow,
  ResultHeaderCell,
  ResultBody,
  ResultRow,
  ResultCell,
});

export const styles = {
  resultTableCellTruncate,
};

export { MIN_SEARCH_LENGTH } from './TypeaheadSearch';
export { AdornmentPosition, InputSize } from './types';
