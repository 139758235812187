import type { Interval } from 'date-fns';
import {
  differenceInMinutes,
  endOfMonth,
  isWithinInterval,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import type { MonitoringSession } from '@/shared/types/monitoringSession.types';

const getBillingMonthInterval = (timezone: string): Interval => {
  const billingTzNow = utcToZonedTime(new Date(), timezone);

  const startOfMonthUtc = startOfMonth(billingTzNow);
  const endOfMonthUtc = endOfMonth(billingTzNow);

  const startOfMonthBillingTz = zonedTimeToUtc(startOfMonthUtc, timezone);
  const endOfMonthBillingTz = zonedTimeToUtc(endOfMonthUtc, timezone);

  return {
    start: startOfMonthBillingTz,
    end: endOfMonthBillingTz,
  };
};

const getCurrentBillingMonthDurationForTimeEntry = (
  entry: MonitoringSession,
  timezone: string,
) => {
  const billingMonthInterval = getBillingMonthInterval(timezone);

  const { start_datetime: startDateTime, end_datetime: endDateTime } = entry;
  const endDate = parseISO(endDateTime);
  const startDate = parseISO(startDateTime);

  if (isWithinInterval(endDate, billingMonthInterval)) {
    return differenceInMinutes(endDate, startDate);
  }

  return 0;
};

export const getCumulativeTime = (
  timeEntries: MonitoringSession[],
  timezone: string,
): Number =>
  // Get the current year and month in UTC
  timeEntries.reduce((acc, curr) => {
    const duration = getCurrentBillingMonthDurationForTimeEntry(curr, timezone);

    return acc + duration;
  }, 0);
