import type { FocusEventHandler, ReactNode } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';

import { Form } from '../Form';
import { FormConfigProvider } from '../FormConfigContext';
import type { ConfiguredForm } from './types';

type RenderProps<TFieldValues extends FieldValues> = {
  form: ConfiguredForm<TFieldValues>;
  canSubmit: boolean;
};

type Props<TFieldValues extends FieldValues> = {
  children: ReactNode | ((props: RenderProps<TFieldValues>) => ReactNode);
  onSubmit?: SubmitHandler<TFieldValues>;
  onBlur?: FocusEventHandler<HTMLFormElement>;
  form: ConfiguredForm<TFieldValues>;
  className?: string;
};

export function FormContainer<TFieldValues extends FieldValues = FieldValues>({
  children,
  onSubmit,
  form,
  className,
  ...rest
}: Props<TFieldValues>) {
  return (
    <FormConfigProvider value={form.config}>
      <Form form={form} onSubmit={onSubmit} className={className} {...rest}>
        {children}
      </Form>
    </FormConfigProvider>
  );
}
