import { FormattedMessage, useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { Script } from '../../../../shared';
import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { SharedMedReview } from '../../shared/MedReview';
import type { MedReviewFormFields } from '../../shared/MedReview/formConfig';
import { getMedReviewFormConfig } from '../../shared/MedReview/formConfig';
import { titrationOutreachPath } from '../paths';

export function MedReview() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<MedReviewFormFields>(
    titrationOutreachPath('/med-review', '/index'),
    getMedReviewFormConfig(intl),
  );

  return (
    <SharedMedReview
      form={form}
      sections={TITRATION_OUTREACH_SECTIONS}
      nextPath={titrationOutreachPath('/action-plan', '/prev-goal-progress')}
      intro={
        <>
          <Script>
            <FormattedMessage defaultMessage="Let's confirm the dosage and frequency of your medications. Then I'll explain the proposed medication change." />
          </Script>
          <Script>
            <FormattedMessage defaultMessage="I have a list that was provided by your doctor. As you know, it's important to take your medications consistently, and if you feel like that's something you struggle with, we can talk about it later in our appointment." />
          </Script>
        </>
      }
    />
  );
}
