<p>My Health Goal</p>
<p>- The main thing I want to change about my health is</p>
<p>  - <i>[choose existing from prior visit or from options below]</i></p>
<p>  - Become more physically active</p>
<p>  - Take my medications more consistently</p>
<p>  - Improve my food choices</p>
<p>  - Reduce my stress</p>
<p>  - Improve my disease related symptoms</p>
<p>  - Cut down on smoking</p>
<p>  - Work on something that has been bothering me</p>
<br/>

<p>Patient self-defined action steps:</p>
<p>Top three things I can do to meet this health goal are:</p>
<p>  1. Action step:</p>
<p>    a. Timing and Frequency:</p>
<p>- Patient comments on self-assessment of progress:</p>
<p>- Updated Patient self-defined action steps (change if patient preference):</p>
