import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import type { TimerState } from './types';

export function useTimer(initialTimeElapsed = 0): TimerState {
  const startTime = useRef(Date.now());
  const [timeElapsed, setTimeElapsed] = useState(initialTimeElapsed);
  const [timerRunning, setTimerRunning] = useState(false);

  // Update when the initial value changes
  useEffect(() => {
    setTimeElapsed(initialTimeElapsed);
  }, [initialTimeElapsed]);

  const syncTimeElapsed = useCallback(() => {
    if (timerRunning) {
      const now = Date.now();
      const newTimeElapsed = timeElapsed + (now - startTime.current);
      setTimeElapsed(newTimeElapsed);
      startTime.current = now;
      return newTimeElapsed;
    }
    return timeElapsed;
  }, [timeElapsed, timerRunning]);

  const updateTimerRunning = useCallback(
    (newTimerRunning: boolean) => {
      if (!newTimerRunning) {
        // When stopping timer, update time elapsed
        syncTimeElapsed();
      } else {
        // Update start time when starting the timer/resuming
        startTime.current = Date.now();
      }
      setTimerRunning(newTimerRunning);
    },
    [syncTimeElapsed],
  );

  useInterval(
    () => {
      syncTimeElapsed();
    },
    // 10 second interval here is arbitrary. we're only displaying minutes
    // so we don't need to update the timer super frequently.
    // Note: Since we are submitting, this will trigger an autosave
    timerRunning ? 10 * 1000 : null,
  );

  return {
    timeElapsed,
    timerRunning,
    setTimeElapsed,
    setTimerRunning: updateTimerRunning,
  };
}
