import type { HTMLAttributes } from 'react';

import { resultTableHead } from './results.css';

export function ResultHeaderRow({
  children,
  ...props
}: HTMLAttributes<HTMLTableRowElement>) {
  return (
    <thead className={resultTableHead}>
      <tr {...props}>{children}</tr>
    </thead>
  );
}
