import classnames from 'classnames/bind';
import type { FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Button } from '@/shared/tempo/atom/Button';

import styles from './patientGoals.module.scss';
import type { Goal } from './patientGoals.types';
import {
  MAX_GOALS,
  NEW_GOAL_NAME_PREFIX,
  getFormConfig,
  getFormName,
} from './patientGoals.utils';

const cx = classnames.bind(styles);

// ensures we always have at least `maxGoals` inputs by creating empty goal inputs to fill in
// any remaining spaces.
function buildInputs(goals: Goal[], maxGoals: number) {
  const inputs = goals.map((goal) => (
    <Form.TextArea
      key={`goal-textarea-${goal.id}`}
      size={12}
      label=""
      name={getFormName(goal)}
    />
  ));

  for (let i = inputs.length; i < maxGoals; i++) {
    inputs.push(
      <Form.TextArea
        key={`goal-textarea-${i}`}
        size={12}
        label=""
        name={`${NEW_GOAL_NAME_PREFIX}-${i}`}
      />,
    );
  }

  return inputs;
}

type PatientGoalsEditProps = {
  goals?: Goal[];
  isSaving: boolean;
  onCancel: () => void;
  onSave: (goals: FieldValues) => void;
};

export function PatientGoalsEdit({
  goals = [],
  isSaving,
  onCancel,
  onSave,
}: PatientGoalsEditProps) {
  const formConfig = getFormConfig(goals);
  const form = useFormFromConfig(formConfig);

  return (
    <Form form={form} onSubmit={onSave}>
      {({ canSubmit }) => (
        <>
          {buildInputs(goals, MAX_GOALS)}
          <Form.Actions className={cx('action-row')}>
            <Button
              id="patient-goals-form-cancel"
              type="button"
              onPress={onCancel}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              id="patient-goals-form-submit"
              type="submit"
              variant="primary"
              isDisabled={!canSubmit}
              isProcessing={isSaving}
            >
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </Form.Actions>
        </>
      )}
    </Form>
  );
}
