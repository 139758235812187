import {
  type SectionPath,
  type StepPath,
  getFullStepPath,
} from '@/shared/common/Wizard/path.utils';

import { CHF_VISIT_SECTIONS } from './metadata';

export function chfVisitPath<P extends SectionPath<typeof CHF_VISIT_SECTIONS>>(
  basePath: P,
  stepPath: StepPath<typeof CHF_VISIT_SECTIONS, P>,
): string {
  return getFullStepPath(CHF_VISIT_SECTIONS, basePath, stepPath);
}
