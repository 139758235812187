import type { CSSProperties } from 'react';

import { CustomRtfEditorStyles, RtfEditorStyles } from './types';

export const RTF_STYLE_MAP: Partial<
  Record<CustomRtfEditorStyles | RtfEditorStyles, CSSProperties>
> = {
  [RtfEditorStyles.HIGHLIGHT]: {
    backgroundColor: '#F0C32333',
  },
  [CustomRtfEditorStyles.TEMPLATE_HEADER]: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};
