import { FormattedMessage } from 'react-intl';

import { programShortLabel, vitalType } from '../../vitalEducation/scriptUtils';
import {
  ConditionProgram,
  VitalAverageLevel,
} from '../../vitalEducation/types';
import type {
  ScriptContext,
  VitalAvgLevelScriptMap,
} from '../../vitalEducation/types';
import { RecommendationsList } from './RecommendationsList';

export const RECOMMENDATION_SCRIPTS: VitalAvgLevelScriptMap = {
  [VitalAverageLevel.One]: ({ vitalAvg, conditionProgram }: ScriptContext) => (
    <>
      <FormattedMessage
        defaultMessage="Since you have reached your goal, we want to make sure to keep you there. Here are a few things you can do to help keep your {vital} well controlled:"
        values={{ vitalAvg, vital: vitalType(conditionProgram) }}
      />
      <RecommendationsList conditionProgram={conditionProgram} />
    </>
  ),
  [VitalAverageLevel.Two]: ({ vitalAvg, conditionProgram }: ScriptContext) => (
    <>
      <FormattedMessage
        defaultMessage="As your {vital} is under good control, the key is to stay on track, remember the following things that can keep your {vital} well controlled:"
        values={{ vitalAvg, vital: vitalType(conditionProgram) }}
      />
      <RecommendationsList conditionProgram={conditionProgram} />
    </>
  ),
  [VitalAverageLevel.Three]: ({
    vitalAvg,
    conditionProgram,
  }: ScriptContext) => (
    <>
      <FormattedMessage
        defaultMessage="At this stage in your {program} management, there are definitely things you can do to try and further improve your {vital} control. We, at Cadence, have got your back and will work with you each step of the way. Some of the things you can work on to improve your {vital} include:"
        values={{
          vitalAvg,
          vital: vitalType(conditionProgram),
          program: programShortLabel(conditionProgram),
        }}
      />
      <RecommendationsList conditionProgram={conditionProgram} />
    </>
  ),
  [VitalAverageLevel.Four]: ({ vitalAvg, conditionProgram }: ScriptContext) => (
    <>
      <FormattedMessage
        defaultMessage="There are definitely things you can do to try and further improve your {vital} control. This is exactly what we're here to support you with at Cadence. We're here for you every step of the way. Some of the things you can work on to improve your {vital} include:"
        values={{ vitalAvg, vital: vitalType(conditionProgram) }}
      />
      <RecommendationsList conditionProgram={conditionProgram} />
    </>
  ),
  [VitalAverageLevel.Five]: ({ vitalAvg }: ScriptContext) => (
    <FormattedMessage
      defaultMessage="This patient's vital average is {vitalAvg} and should be escalated to an NP"
      values={{ vitalAvg }}
    />
  ),
  [VitalAverageLevel.Unknown]: ({ conditionProgram }: ScriptContext) => (
    <>
      <FormattedMessage
        defaultMessage="The key to improving and maintaining your health is to stay on track. Remember the following things can keep your {vital} well controlled:"
        values={{
          vital:
            conditionProgram === ConditionProgram.HEART_FAILURE ? (
              <FormattedMessage defaultMessage="heart condition" />
            ) : (
              vitalType(conditionProgram)
            ),
        }}
      />
      <RecommendationsList conditionProgram={conditionProgram} />
    </>
  ),
};
