import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { InProgressNoteBox } from '@/pages/patients/patientDetails/ui/Notes/InProgressNoteBox';
import { ConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import { EMPTY_WIZARD_STATE } from '@/shared/common/Wizard/state';
import { useQueryParams } from '@/shared/hooks';
import { useDeleteAutosavedNoteByPatientId } from '@/shared/hooks/queries/autosave-notes.queries';

import { DrawerState, NoteDrawer } from '../NoteDrawer';
import { CNWizardForm } from './CNWizardForm';
import { isCnNote } from './shared/note.utils';
import { useAutosavedCnNote } from './shared/query.hooks';
import { cnNoteKeys } from './shared/querykeys';
import { usePatientProgram } from './shared/usePatientProgram';

export function CNNotesSidebarPanel() {
  const intl = useIntl();
  const [initWizardState, setInitWizardState] = useState(EMPTY_WIZARD_STATE);
  const [isEditing, setIsEditing] = useState(false);
  const [isNewNoteConfirmationOpen, setIsNewNoteConfirmationOpen] =
    useState(false);
  const drawerState = isEditing ? DrawerState.Editing : DrawerState.Collapsed;
  const { patientId } = useParams<{ patientId: string }>();
  const { data: autosavedNote, isLoading: isLoadingAutosavedNote } =
    useAutosavedCnNote(patientId);
  const deleteAutoSavedNote = useDeleteAutosavedNoteByPatientId(patientId);
  const { program, isLoading: isLoadingProgram } = usePatientProgram(patientId);
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();
  const noteId = params.get('noteId');

  // The effect below reads in the query params to open the note editor
  // and subsequently removes those params so that the note editor can be closed properly
  useEffect(() => {
    if (
      !isLoadingAutosavedNote &&
      autosavedNote &&
      noteId &&
      autosavedNote?.id?.toString() === noteId
    ) {
      params.delete('noteId');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
      setInitWizardState(
        autosavedNote.clinical_navigator_note?.[0]?.form || EMPTY_WIZARD_STATE,
      );
      setIsEditing(true);
    }
  }, [
    autosavedNote,
    noteId,
    isLoadingAutosavedNote,
    params,
    history,
    location.pathname,
  ]);

  return (
    <NoteDrawer
      state={drawerState}
      isLoading={isLoadingAutosavedNote || isLoadingProgram}
      onClickNewNote={() => {
        if (autosavedNote) {
          setIsNewNoteConfirmationOpen(true);
        } else {
          setInitWizardState(EMPTY_WIZARD_STATE);
          setIsEditing(true);
        }
      }}
    >
      {!isEditing && autosavedNote && isCnNote(autosavedNote) && (
        <InProgressNoteBox
          variant="autosaved"
          onOpen={() => {
            setInitWizardState(
              autosavedNote.clinical_navigator_note?.[0]?.form ||
                EMPTY_WIZARD_STATE,
            );
            setIsEditing(true);
          }}
        />
      )}
      {isEditing && (
        <CNWizardForm
          // Re-mount when note id changes
          key={noteId}
          initialState={initWizardState}
          autosavedNote={autosavedNote}
          program={program}
          onStopEditing={() => {
            setIsEditing(false);
            setInitWizardState(EMPTY_WIZARD_STATE);
          }}
          onReset={() => setInitWizardState(EMPTY_WIZARD_STATE)}
        />
      )}
      <ConfirmationDialog
        isOpen={isNewNoteConfirmationOpen}
        onCancel={() => setIsNewNoteConfirmationOpen(false)}
        onConfirm={() =>
          deleteAutoSavedNote.mutate(undefined, {
            onSuccess: async () => {
              queryClient.resetQueries(cnNoteKeys.autosaved(patientId));
              setIsNewNoteConfirmationOpen(false);
              setIsEditing(true);
            },
          })
        }
        onSecondaryAction={() => {
          setInitWizardState(
            autosavedNote?.clinical_navigator_note?.[0]?.form ||
              EMPTY_WIZARD_STATE,
          );
          setIsEditing(true);
          setIsNewNoteConfirmationOpen(false);
        }}
        confirmButtonText={intl.formatMessage({
          defaultMessage: 'Create new note',
        })}
        dialogTitle={intl.formatMessage({
          defaultMessage: 'Create a new note',
        })}
        dialogDescription={intl.formatMessage({
          defaultMessage:
            'Are you sure you want to create a new note? This will discard your current autosaved note.',
        })}
        secondaryButtonText={intl.formatMessage({
          defaultMessage: 'View autosaved note',
        })}
      />
    </NoteDrawer>
  );
}
