import cx from 'classnames';
import { useIntl } from 'react-intl';

import type { NumberPickerProps } from '../NumberPicker';
import { NumberPicker } from '../NumberPicker';
import { suffixText } from './style.css';

type Props = { isDisabled?: boolean } & Omit<
  NumberPickerProps,
  'placeholder' | 'disabled'
>;

export function MinutesPicker({
  min = Number.NEGATIVE_INFINITY,
  max = Number.POSITIVE_INFINITY,
  className,
  ...rest
}: Props) {
  const intl = useIntl();
  return (
    <NumberPicker
      {...rest}
      placeholder="00"
      min={min}
      max={Math.min(max, 999)}
      className={cx(className)}
      suffix={{
        text: intl.formatMessage({ defaultMessage: 'mins' }),
        class: suffixText,
      }}
    />
  );
}
