{{> header val="General Assessment" }}
<br/>

{{> subheader val="Social History" }}
<p>Tobacco/Alcohol/Drugs- any changes since last visit? Review past history and ask patients if any changes?</p>
{{> free_text_space }}

{{> subheader val="Diet" }}
<p>Review past diet discussions with patient. Has anything changed since the last visit?</p>
{{> free_text_space }}

{{> subheader val="Exercise" }}
<p>Review past exercise discussions with patient. Has anything changed since the last visit?</p>
{{> free_text_space }}

<p>Review past discussions on weight with patient. Has anything changed since the last visit?</p>
{{> free_text_space }}

{{> subheader val="Activities of Daily Living" }}
<p>Any significant changes in functional status (need new assistance with walking, eating, toileting, or dressing?)</p>
{{> free_text_space }}

{{> header val="Action Plan"}}
<br/>

<p>Review action plan from last visit</p>
<ul>
  <li>Patient comments on self-assessment of progress: </li>
  <li>Updated Patient self-defined action steps: </li>
</ul>

{{> subheader val="My Health Goal"}}
<p>The main thing I want to change about my health is: [choose from options below]</p>
<ul>
  <li>Become more physically active</li>
  <li>Take my medications more consistently</li>
  <li>Lower my weight</li>
  <li>Improve my food choices</li>
  <li>Reduce my stress</li>
  <li>Better understand my disease</li>
  <li>Get better sleep</li>
  <li>Quit smoking</li>
</ul>

{{> subheader val="Patient Education" }}
<p>Today, the following modules were reviewed with the patient:</p>
<p>[insert module name]</p>
{{> free_text_space }}

{{> subheader val="Patient self-defined action steps"}}
<p>Top things I can do to meet this health goal are:</p>
<p>1. Action step:</p>
<p>  a. Timing and Frequency:</p>
