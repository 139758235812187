import type { ContentState } from 'draft-js';
import type { ReactNode } from 'react';

type Props = {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode;
};

export function RtfLink(props: Props) {
  const { contentState, entityKey, children } = props;

  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a
      rel="nofollow noreferrer"
      href={url}
      target="_blank"
      style={{ color: '#2458C5', fontStyle: 'italic' }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => {
        const link = e.target.parentElement.parentElement;
        const linkUrl = link.href;
        window.open(linkUrl, '_blank');
      }}
    >
      {children}
    </a>
  );
}
