import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { settings } from '@/config';
import { registerMonitoredUser } from '@/monitoring.utils';
import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';

import {
  LOGIN_REQUEST_SSO,
  LOGIN_REQUEST_SSO_FAILURE,
  loginSSORequested,
} from '../actionCreators';

const loginSSOEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(LOGIN_REQUEST_SSO),
    switchMap((action: any) =>
      from(
        fetch(
          `${settings.API_URL}/auth/api/v1/oauth2/callback${action.querystring}`,
          {
            method: 'GET',
            credentials: 'include',
          },
        )
          .then((res: any) => {
            if (!res.ok) {
              return Promise.reject(res);
            }

            return res.json();
          })
          .then((data) => data),
      ).pipe(
        tap(() => registerMonitoredUser(state$.value.auth.email)),
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      ),
    ),
    map((response: any) => loginSSORequested(response)),
    catchError((error, caught) =>
      merge(
        of({
          type: LOGIN_REQUEST_SSO_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default loginSSOEpic;
