import type { Dispatch, RefObject, SetStateAction } from 'react';

import { createCtx } from '@/shared/hooks/createCtx';
import type { TwilioTask } from '@/shared/hooks/useTwilioFlexMessagesListener';

export type TwilioFlexContext = {
  iframeRef: RefObject<HTMLIFrameElement> | null;
  setIframeRef: Dispatch<SetStateAction<RefObject<HTMLIFrameElement> | null>>;
  isFlexOpen: boolean;
  setIsFlexOpen: Dispatch<SetStateAction<boolean>>;
  tasks: Map<string, TwilioTask>;
  setTasks: Dispatch<SetStateAction<Map<string, TwilioTask>>>;
};

export const [useTwilioFlexContext, TwilioFlexContextProvider] =
  createCtx<TwilioFlexContext>('TwilioFlexContext');
