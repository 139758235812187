import type { QueryParamsFilterLookup } from '../patients-grpc';
import { OpType } from '../patients-grpc';

export const CONFERENCE_QUERY_PARAMS_FILTER_LOOKUP: QueryParamsFilterLookup = {
  patientId: {
    renames: ['patientId'],
    op: OpType.EQUAL,
  },
  caregiverIds: {
    renames: ['caregiverIds'],
    op: OpType.CONTAINS,
  },
  noteInfo: {
    nativeFilterGetter: ({ noteId }) => {
      if (noteId) {
        return `noteIds : ${noteId}`;
      }
      return '';
    },
  },
  careProviderInfo: {
    nativeFilterGetter: ({ careProviderId }) => {
      if (careProviderId) {
        return `careProviderIds : "${careProviderId}"`;
      }
      return '';
    },
  },
};
