import { FormattedMessage, useIntl } from 'react-intl';

import { Breadcrumbs as SharedBreadcrumbs } from '@/shared/common/Breadcrumbs';
import { RoutedLink } from '@/shared/tempo/atom/Link/RoutedLink';

import { breadcrumb } from './SupportModal.css';
import { BASE_SUPPORT_MODAL_ROUTE } from './useCategories';

type Props = {
  activePage?: string;
};

export function Breadcrumbs({ activePage }: Props) {
  const intl = useIntl();

  if (!activePage) {
    return (
      <SharedBreadcrumbs>
        <SharedBreadcrumbs.Active className={breadcrumb}>
          <FormattedMessage defaultMessage="Cadence Platform Support" />
        </SharedBreadcrumbs.Active>
      </SharedBreadcrumbs>
    );
  }
  return (
    <SharedBreadcrumbs classes={{ separator: breadcrumb }}>
      <RoutedLink to={BASE_SUPPORT_MODAL_ROUTE} className={breadcrumb}>
        {intl.formatMessage({ defaultMessage: 'Cadence Platform Support' })}
      </RoutedLink>
      <SharedBreadcrumbs.Active className={breadcrumb}>
        {activePage}
      </SharedBreadcrumbs.Active>
    </SharedBreadcrumbs>
  );
}
