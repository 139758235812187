import cx from 'classnames';
import { useRef } from 'react';
import type { AriaTagProps } from 'react-aria';
import { useFocusRing, usePress, useTag } from 'react-aria';
import type { ListState } from 'react-stately';

import CloseCircleIcon from '@/shared/assets/svgs/closeCircle.svg?react';
import type { Props as TagProps } from '@/shared/tempo/atom/Tag';
import { Tag } from '@/shared/tempo/atom/Tag';

import { close, content, tagAndContainer } from './GroupTag.css';

type Props<T> = {
  className?: string;
  state: ListState<T>;
} & AriaTagProps<T> &
  TagProps & { isDisabled?: boolean };

export function GroupTag<T extends object>({
  item,
  state,
  className,
  variant = 'default',
  ...props
}: Props<T>) {
  const ref = useRef(null);
  const { focusProps, isFocusVisible } = useFocusRing({ within: true });
  const { rowProps, gridCellProps, removeButtonProps, allowsRemoving } = useTag(
    { item, ...props },
    state,
    ref,
  );

  const { pressProps: closePressProps } = usePress({
    onPress: (e) => {
      removeButtonProps.onPress?.(e);
    },
  });

  return (
    <div
      ref={ref}
      {...rowProps}
      {...focusProps}
      className={cx(tagAndContainer, className)}
      data-focus-visible={isFocusVisible}
    >
      <Tag
        {...gridCellProps}
        {...props}
        className={tagAndContainer}
        leftIcon={
          allowsRemoving && props.isDisabled !== true ? (
            <CloseCircleIcon
              {...removeButtonProps}
              {...closePressProps}
              className={close[variant]}
            />
          ) : undefined
        }
      >
        <span className={content}>{item.rendered}</span>
      </Tag>
    </div>
  );
}
