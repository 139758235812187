import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from '@/shared/common/Form/validations';

export type IntroFormFields = {
  patientAttendance: 'true' | 'false';
  cancellationReason: AppointmentCancellationType;
  additionalCancellationInfo: string;
  languageInterpreter?: string;
  nonPatientInfo?: string;
};

export enum AppointmentCancellationType {
  NoShow = 'NO_SHOW',
  PatientRequestReschedule = 'PATIENT_REQUEST_RESCHEDULE',
  PatientHospitalized = 'PATIENT_HOSPITALIZED',
  SchedulingErrorDisenrollment = 'SCHEDULING_ERROR_OR_DISENROLLMENT',
}

export function getIntroductionFormConfig(intl: IntlShape) {
  const { required, enumType, maxLengthString } = validators(intl);

  return {
    fields: {
      patientAttendance: {
        defaultValue: undefined,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      cancellationReason: {
        defaultValue: undefined,
        validation: enumType({
          source: AppointmentCancellationType,
          pluck: 'values',
        }).when('patientAttendance', {
          is: 'false',
          then: required,
        }),
      },
      additionalCancellationInfo: {
        default: undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
      languageInterpreter: {
        default: undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
      nonPatientInfo: {
        default: undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
    },
  };
}
