import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { validators } from '@/shared/common/Form/validations';

import type { CNVisitType } from '../../../../types';

export type DietFormFields = {
  currentDiet: string;
  processedFoods?: string;
  sugaryDrinks: 'true' | 'false';
  sugaryDrinksDescription?: string;
  waterPerDay: string;
  addSalt: 'true' | 'false';
};

export function getDietFormConfig(
  intl: IntlShape,
  values?: DietFormFields,
  visitType?: CNVisitType,
) {
  const { required } = validators(intl);

  return {
    fields: {
      currentDiet: {
        defaultValue: values?.currentDiet,
        validation: required(yup.string()),
      },
      ...(visitType === TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT && {
        processedFoods: {
          defaultValue: values?.processedFoods,
          validation: required(yup.string()),
        },
      }),
      sugaryDrinks: {
        defaultValue: values?.sugaryDrinks,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      sugaryDrinksDescription: {
        defaultValue: values?.sugaryDrinksDescription,
        validation: yup.string().when('sugaryDrinks', {
          is: 'true',
          then: required,
        }),
      },
      waterPerDay: {
        defaultValue: values?.waterPerDay,
        validation: required(yup.string()),
      },
      addSalt: {
        defaultValue: values?.addSalt,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
    },
  };
}
