import { useParams } from 'react-router-dom';

import { PatientCumulativeTimeTracker } from '@/pages/patients/PatientCumulativeTimeTracker';
import { useRouteParamPatientDetails } from '@/shared/hooks/queries';
import { useListenToPatientChangeNotifications } from '@/shared/hooks/useListenToPatientChangeNotifications';

import { headerTags } from '../PatientProfileHeader.css';
import { VitalsEngagementStatus } from '../VitalsEngagementStatus';
import { PatientStatusTag } from './PatientStatusTag';

export function PatientProfileHeaderTags() {
  const { patientId } = useParams<{ patientId: string }>();
  const { data: patient } = useRouteParamPatientDetails();
  // Only pass in patient id which enables listener if feature flag is on and patient is not activated
  const targetPatientId = !patient?.activated_at ? patient?.id : undefined;
  useListenToPatientChangeNotifications(targetPatientId);

  // TODO: loading state?
  // https://cadencerpm.atlassian.net/browse/PLAT-6115
  if (!patient) {
    return null;
  }

  return (
    <div className={headerTags}>
      <PatientStatusTag patient={patient} />
      <VitalsEngagementStatus patientId={patientId} />
      <PatientCumulativeTimeTracker />
    </div>
  );
}
