import isObject from 'lodash/isObject';
import { isValidElement } from 'react';

export type UiLabel = JSX.Element | UiLabelMap;

type UiLabelMap = {
  [key: string]: JSX.Element;
  default: JSX.Element;
};

function isLabelMap(label: UiLabel): label is UiLabelMap {
  return isObject(label);
}

export type UiSchemaLabels = {
  [key: string]: UiLabel;
};

export function getUiSchemaFromLabels(
  labels: UiSchemaLabels,
  { inline } = { inline: false },
) {
  return Object.fromEntries(
    Object.entries(labels).map(([key, label]) => [
      key,
      {
        'ui:label': label,
        ...(inline && {
          'ui:options': {
            inline: true,
          },
        }),
      },
    ]),
  );
}

/**
 * Gets UI label from title if it exists.
 *
 * This supports using the "title" keyword to conditionally apply UI schema,
 * e.g. show a different label for a field when an if/then clause is satisfied
 *
 * Implementation rationale: Since the uiSchema object does not support
 * conditional rules and rjsf will throw an error if any unsupported keywords
 * are used, we are using the "title" to add a key to the schema conditionally,
 * and then adding the applicable UI schema under that key in the uiSchema object
 */
export function getUiLabel(uiLabel: UiLabel, title?: string) {
  if (isValidElement(uiLabel)) {
    return uiLabel;
  }

  if (isLabelMap(uiLabel)) {
    if (title) {
      return uiLabel[title as keyof typeof uiLabel];
    }
    return uiLabel.default;
  }

  return uiLabel;
}
