import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AsyncTitrationMedicationWasNotTitratedReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { useRejectionReasonI18n } from '../hooks/useRejectionReasonI18n';
import { notTitrating } from './SuggestedTitration.css';

type Props = {
  isDisabled?: boolean;
  onChange: (reason: Reason) => void;
};

export function NotTitratingReasonSelect({
  isDisabled = false,
  onChange,
}: Props) {
  const reasonI18n = useRejectionReasonI18n();
  const [value, setValue] = useState<Reason | null>(null);

  return (
    <Select
      label={
        <FormattedMessage defaultMessage="Select a reason why a medication wasn't titrated" />
      }
      className={notTitrating.select}
      selectedKey={value}
      onSelectionChange={(key) => {
        setValue(key as Reason);
        onChange(key as Reason);
      }}
      isDisabled={isDisabled}
    >
      <Select.Option key={Reason.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER}>
        {reasonI18n[Reason.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER]}
      </Select.Option>
      <Select.Option key={Reason.MANAGED_BY_EXTERNAL_PROVIDER}>
        {reasonI18n[Reason.MANAGED_BY_EXTERNAL_PROVIDER]}
      </Select.Option>
      <Select.Option key={Reason.AGE_OR_CONDITION}>
        {reasonI18n[Reason.AGE_OR_CONDITION]}
      </Select.Option>
      <Select.Option key={Reason.SIDE_EFFECT_AT_HIGHER_DOSE}>
        {reasonI18n[Reason.SIDE_EFFECT_AT_HIGHER_DOSE]}
      </Select.Option>
      <Select.Option key={Reason.NEW_PRESCRIPTION_REQUIRED}>
        {reasonI18n[Reason.NEW_PRESCRIPTION_REQUIRED]}
      </Select.Option>
      <Select.Option key={Reason.MEDICATION_NOT_ACCURATE}>
        {reasonI18n[Reason.MEDICATION_NOT_ACCURATE]}
      </Select.Option>
      <Select.Option key={Reason.HEART_RATE_TOO_LOW}>
        {reasonI18n[Reason.HEART_RATE_TOO_LOW]}
      </Select.Option>
      <Select.Option key={Reason.KIDNEY_FUNCTION_TOO_LOW}>
        {reasonI18n[Reason.KIDNEY_FUNCTION_TOO_LOW]}
      </Select.Option>
      <Select.Option key={Reason.ELECTROLYTE_ABNORMALITY}>
        {reasonI18n[Reason.ELECTROLYTE_ABNORMALITY]}
      </Select.Option>
      <Select.Option key={Reason.ADJUSTED_ANOTHER_MEDICATION}>
        {reasonI18n[Reason.ADJUSTED_ANOTHER_MEDICATION]}
      </Select.Option>
      <Select.Option key={Reason.NEED_LABS}>
        {reasonI18n[Reason.NEED_LABS]}
      </Select.Option>
      <Select.Option key={Reason.ACUTE_MEDICAL_ISSUE}>
        {reasonI18n[Reason.ACUTE_MEDICAL_ISSUE]}
      </Select.Option>
      <Select.Option key={Reason.PROVIDER_GOAL_DIFFERS}>
        {reasonI18n[Reason.PROVIDER_GOAL_DIFFERS]}
      </Select.Option>
      <Select.Option key={Reason.MEDICATION_VERIFICATION}>
        {reasonI18n[Reason.MEDICATION_VERIFICATION]}
      </Select.Option>
    </Select>
  );
}
