import { FormattedMessage } from 'react-intl';

import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import { Button } from '@/shared/tempo/atom/Button';

import { actionIcon } from './HistoryButton.css';

type Props = {
  onShowHistory: () => void;
};

export function HistoryButton({ onShowHistory }: Props) {
  return (
    <Button variant="tertiary" size="small" onPress={onShowHistory}>
      <Button.Icon>
        <CalendarIcon className={actionIcon} />
      </Button.Icon>
      <FormattedMessage defaultMessage="History" />
    </Button>
  );
}
