import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import type { TitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { isAsyncTitrationPending } from '@/components/AsyncTitration/statuses';
import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';

import { medRowSubtext, previousDose } from './MedicationRow.css';
import { getProactiveTitrationDoseStrings } from './utils/medInfoUtils';

type Props = {
  medChange: MedicationChange;
  status?: MedicationChangeStatus;
  titrationRecommendation?: TitrationRecommendation;
  noteId?: Maybe<number>;
};

export function MedicationSubtext({
  medChange,
  status,
  titrationRecommendation,
  noteId,
}: Props) {
  const intl = useIntl();
  const { reason, changeType } = medChange;

  const subtext = getSubtext(
    intl,
    reason,
    changeType,
    status,
    titrationRecommendation,
    medChange,
    noteId,
  );

  if (!subtext) {
    return null;
  }

  // the div wrapping {subtext} allows inner content to wrap
  // with respect to the margin on the outer div here. please
  // do not remove it
  return (
    <div className={medRowSubtext}>
      <div>{subtext}</div>
    </div>
  );
}

function getSubtext(
  intl: IntlShape,
  reason?: string,
  changeType?: MedicationChangeChangeType,
  status?: MedicationChangeStatus,
  titrationRecommendation?: TitrationRecommendation,
  medChange?: MedicationChange,
  noteId?: Maybe<number>,
) {
  if (
    medChange &&
    titrationRecommendation?.rxNorm &&
    isAsyncTitrationPending(titrationRecommendation, noteId)
  ) {
    const { current, recommended } = getProactiveTitrationDoseStrings(
      intl,
      medChange,
      titrationRecommendation,
    );

    return intl.formatMessage(
      {
        defaultMessage:
          'Titration: {currentDailyDosage} → {recommendedDailyDosage}',
      },
      {
        currentDailyDosage: <span className={previousDose}>{current}</span>,
        recommendedDailyDosage: recommended,
      },
    );
  }

  switch (changeType) {
    case MedicationChangeChangeType.REVIEW:
      return reason;
      break;

    case MedicationChangeChangeType.STOP:
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Stopped- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Stopped' });

    case MedicationChangeChangeType.START:
      if (status === MedicationChangeStatus.INACTIVE) {
        return null;
      }
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Started- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Started' });

    case MedicationChangeChangeType.TITRATION:
      if (status === MedicationChangeStatus.INACTIVE) {
        return null;
      }
      return reason
        ? intl.formatMessage(
            { defaultMessage: 'Titrated- {reason}' },
            { reason },
          )
        : intl.formatMessage({ defaultMessage: 'Titrated' });
    default:
      return null;
  }

  return null;
}
