import type { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

import type { RootState } from '@/store/redux.types';

import { getLoginUrl } from './getLoginUrl';

type PrivateRouteProps = Partial<RouteComponentProps> & {
  ComponentRoutes: ComponentType<RouteComponentProps>;
  environment?: string;
  loginUrlOverride?: string;
};

export function PrivateRoute({
  ComponentRoutes,
  environment,
  loginUrlOverride,
  ...props
}: PrivateRouteProps) {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated,
  );
  const renderFunc = (componentProps: RouteComponentProps) => {
    if (isAuthenticated) {
      return <ComponentRoutes {...componentProps} />;
    }

    const loginUrl = loginUrlOverride ?? getLoginUrl(environment);

    return (
      <Redirect
        to={{
          ...props.location,
          pathname: loginUrl,
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route {...props} render={renderFunc} />;
}
