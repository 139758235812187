import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { formatProviderForScript } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/script.utils';
import { usePatientDiseaseStateI18n } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/usePatientDiseaseStateI18n';
import { Wizard } from '@/shared/common/Wizard';
import { usePatientContext } from '@/shared/hooks/queries';

import { useCNFormContext } from '../../../CNFormContext';
import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';

export function IntroCadenceDescription() {
  const intl = useIntl();
  const { patientId } = useCNFormContext();
  const { data: context, isLoading: isLoadingContext } =
    usePatientContext(patientId);
  const { diseaseState, isLoading: isLoadingDiseaseState } =
    usePatientDiseaseStateI18n(patientId);

  return (
    <Wizard.Step
      isLoading={!context || isLoadingContext || isLoadingDiseaseState}
      sections={CYCLE_17_INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage
            defaultMessage="Thanks for confirming that information with me! I'm happy to welcome you to the Cadence Remote Patient Monitoring program today. I'm one of our Clinical Navigators here and my job as a member of your care team is to support you in managing your {diseaseState} through behavioral and lifestyle changes."
            values={{
              diseaseState,
            }}
          />
        </Script>
        <Script>
          <FormattedMessage
            defaultMessage="{provider} believes this consistent care will keep you healthy, prevent complications in the future, and give them a better idea of how you're doing between your office visits."
            values={{
              provider: formatProviderForScript(
                context?.primaryPhysicianGivenName,
                context?.primaryPhysicianFamilyName,
                context?.primaryPhysicianRole,
              ),
            }}
          />
        </Script>
      </FieldGroup>
    </Wizard.Step>
  );
}
