import type { IntlShape } from 'react-intl';

import { MarkdownBuilder } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/templates/engine/MarkdownBuilder';
import type {
  MedicationChange,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import { AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';

import { getMedicationStrings } from './getMedicationStrings';
import type { MedicationInfo, VitalStrings } from './types';

export function getTitrationOutreachNoteBody(
  intl: IntlShape,
  changesWithAsyncTitration: MedicationInfo[],
  vitals: VitalStrings,
  medReviewBody: string,
  isNoShow: boolean,
): MarkdownBuilder {
  const md = new MarkdownBuilder();

  if (!isNoShow) {
    md.p('Spoke with patient and reviewed most recent BP trends:');
    md.uli(`30 day average blood pressure: ${vitals.bp}`);
    md.uli('Verified medications in Cadence chart as accurate.');
    md.newline();
  } else {
    md.p(`Patient's 30 day blood pressure average: ${vitals.bp}`);
    md.newline();
  }

  md.newline();
  md.concat(medReviewBody);
  md.newline();

  changesWithAsyncTitration.forEach(([referenceMed, change]) => {
    const status = change.asyncTitration?.status as AsyncTitrationStatus;

    if (
      !isNoShow &&
      [
        AsyncTitrationStatus.PATIENT_CONSENTED,
        AsyncTitrationStatus.PATIENT_REJECTED,
      ].includes(status)
    ) {
      appendPatientConsentLanguage(md, intl, change, referenceMed);
    } else if (isNoShow) {
      appendNoShowLanguage(md, intl, change, referenceMed);
    }

    if (status === AsyncTitrationStatus.PATIENT_CONSENTED) {
      appendPatientConsentedTitrationBody(md, referenceMed);
    }

    if (status === AsyncTitrationStatus.PATIENT_REJECTED) {
      appendPatientRejectedTitrationBody(md);
    }
  });

  md.newline();

  if (!isNoShow) {
    md.p(
      'Advised patient to call us back if they have any questions or concerns and that we have nursing staff available 24 hours a day/7 days a week.',
    );
  } else {
    md.p(
      'I attempted to contact the patient to discuss medication changes, but was unable to reach them.',
    );
  }

  return md;
}

function appendPatientConsentLanguage(
  md: MarkdownBuilder,
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
) {
  const { isValid, clinicianName, medicationName, dosage, frequencyText } =
    getMedicationStrings(intl, med, referenceMed);

  if (!isValid) {
    return;
  }

  md.p(
    `Per ${clinicianName}, NP advised patient to increase ${medicationName} to ${dosage} ${frequencyText}.`,
  );
}

function appendNoShowLanguage(
  md: MarkdownBuilder,
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
) {
  const { isValid, clinicianName, medicationName, dosage, frequencyText } =
    getMedicationStrings(intl, med, referenceMed);

  if (!isValid) {
    return;
  }

  md.p(
    `${clinicianName}, NP recommends increasing ${medicationName} to ${dosage} ${frequencyText}.`,
  );
}

function appendPatientConsentedTitrationBody(
  md: MarkdownBuilder,
  referenceMed: ReferenceMedication,
) {
  md.p('Patient agrees to plan.');

  if (referenceMed.sideEffects) {
    md.p(
      `Discussed possible side effects that could include: ${referenceMed.sideEffects}.`,
    );
  }

  if (referenceMed.requiredLabs) {
    md.p(
      `This medication needs labs to be drawn ${referenceMed.requiredLabs}.`,
    );
  }
}

function appendPatientRejectedTitrationBody(md: MarkdownBuilder) {
  md.p('Patient does not agree to medication change at this time.');
}
