import { useIntl } from 'react-intl';

import { Select } from '@/shared/tempo/@labs/molecule/Select';
import type { TimePeriod } from '@/shared/utils/time-helpers';
import { TWELVE_HOURS_RANGE } from '@/shared/utils/time-helpers';

import { timeSelect } from './VitalReminders.css';
import { getTimePeriodsOptions } from './vitalReminders.utils';

export type Props = {
  initHour: string;
  initAMPM: TimePeriod;
  disabled: boolean;
  onHourChange: (hour: string) => void;
  onAMPMChange: (ampm: TimePeriod) => void;
};
export const TimeInputs = ({
  initHour,
  initAMPM,
  disabled,
  onHourChange,
  onAMPMChange,
}: Props) => {
  const intl = useIntl();
  return (
    <>
      <Select
        className={timeSelect}
        isDisabled={disabled}
        aria-label={intl.formatMessage({ defaultMessage: 'Hour' })}
        selectedKey={initHour}
        onSelectionChange={(value) => onHourChange(String(value))}
      >
        {TWELVE_HOURS_RANGE.map((hour) => (
          <Select.Option key={hour}>{hour}</Select.Option>
        ))}
      </Select>
      <Select
        className={timeSelect}
        isDisabled={disabled}
        aria-label={intl.formatMessage({ defaultMessage: 'AM/PM' })}
        selectedKey={initAMPM}
        onSelectionChange={(value) => onAMPMChange(value as TimePeriod)}
      >
        {getTimePeriodsOptions(intl).map(({ value, text }) => (
          <Select.Option key={value}>{text}</Select.Option>
        ))}
      </Select>
    </>
  );
};
