import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';
import { GRPC_RESOURCE_NAME } from '@/shared/hooks/queries/tasks.queries';
import type { Provider } from '@/shared/types/provider.types';

import { urgencyToPriority } from '../TaskCard/utils';
import type { CreateTaskPayload } from '../types';

export type FormFields = {
  taskTypeId?: string;
  assignee?: Provider;
  is_urgent: boolean;
  note: string;
  assigneeType: 'team' | 'individual';
};

export const MAX_NOTE_LENGTH = 400;

export function getFormConfig(intl: IntlShape): FormConfig {
  const { required, maxLengthString } = validators(intl);

  return {
    fields: {
      taskTypeId: {
        validation: yup.string(),
      },
      assignee: {
        validation: yup.mixed().when('assigneeType', {
          is: 'individual',
          then: (schema) => required(schema),
        }),
      },
      assigneeType: {
        defaultValue: 'team',
      },
      is_urgent: {
        defaultValue: false,
        validation: yup.boolean(),
      },
      note: {
        validation: required(
          maxLengthString({
            maxLength: MAX_NOTE_LENGTH,
            errorMessage: intl.formatMessage({
              defaultMessage: 'Note may not exceed 400 characters',
            }),
          }),
        ),
      },
    },
  };
}

export function formDataToTaskPayload(
  { note, assignee, is_urgent: isUrgent, taskTypeId }: FormFields,
  patientId: string,
): CreateTaskPayload {
  return {
    patientId,
    description: note,
    priority: urgencyToPriority(isUrgent),
    ...(assignee && {
      assignee: { name: GRPC_RESOURCE_NAME.careProvider(assignee.id) },
    }),
    ...(taskTypeId && { taskTypeId }),
  };
}
