import {
  CLEAR_PATIENT_LABS,
  FETCH_PATIENT_LABS,
  FETCH_PATIENT_LABS_FAILURE,
  FETCH_PATIENT_LABS_SUCCESS,
} from '../actionCreators';

const initialState = {
  status: {
    isProcessing: true,
    isSuccess: false,
  },
  data: [],
  metadata: {
    page: 1,
    pages: 1,
    per_page: 10,
    total: 0,
  },
  error: null,
};

function labsReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_PATIENT_LABS:
      return {
        ...state,
        status: {
          isProcessing: true,
          isSuccess: false,
        },
        error: null,
      };
    case FETCH_PATIENT_LABS_SUCCESS:
      return {
        ...state,
        status: {
          isProcessing: false,
          isSuccess: true,
        },
        data: action.payload.data,
        metadata: {
          ...state.metadata,
          page: 1,
          total: action.payload.data.length,
        },
        error: null,
      };
    case FETCH_PATIENT_LABS_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: {
          isProcessing: false,
          isSuccess: false,
        },
      };
    case CLEAR_PATIENT_LABS:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}

export default labsReducer;
