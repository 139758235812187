import { useIntl } from 'react-intl';

import InfoCircle from '@/shared/assets/svgs/info-circle.svg?react';

import { emptyStateContainer, emptyStateIcon } from './CallTracking.css';

export function CallTrackingEmptyState() {
  const intl = useIntl();

  return (
    <div className={emptyStateContainer}>
      <InfoCircle className={emptyStateIcon} />
      <div>
        {intl.formatMessage({
          defaultMessage: 'No calls have been associated with this encounter',
        })}
      </div>
    </div>
  );
}
