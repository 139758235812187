import type { HTMLAttributes, JSXElementConstructor } from 'react';
import { useIntl } from 'react-intl';
import type { UseQueryResult } from 'react-query';

import type { PopperProps } from '@/deprecated/mui';
import { IconButton } from '@/deprecated/mui';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import SearchIcon from '@/shared/assets/svgs/search.svg?react';
import {
  AdornmentPosition,
  InputSize,
  MIN_SEARCH_LENGTH,
  TypeaheadSearch,
} from '@/shared/common/TypeaheadSearch';
import type { PaginatedData } from '@/shared/types/pagination.types';

import {
  container,
  labelContainer,
  searchIcon,
  selectedValueContainer,
} from './TypeaheadSelect.css';

export type TypeaheadSelectProps<T, D extends string = 'data'> = {
  value?: string;
  onChange: (option: string | undefined) => void;
  setSearchTerm: (searchTerm: string) => void;
  minSearchLength?: number;
  query: UseQueryResult<PaginatedData<T, D>>;
  queryDataField?: D | 'data';
  OptionComponent: JSXElementConstructor<
    HTMLAttributes<HTMLTableRowElement> & { option: T; optionLabel: string }
  >;
  ResultContainer: Parameters<typeof TypeaheadSearch>[0]['ResultContainer'];
  placeholder: string;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  PopperComponent?: JSXElementConstructor<PopperProps>;
  disabled?: boolean;
  hasError?: boolean;
};
/**
 * TypeaheadSelect is a wrapper around TypeaheadSearch that makes a select from it instead of being a search input
 * @param value - Selected option value used to display proper label with getOptionLabel
 * @param onChange - Used to set value in parent component
 * @param setSearchTerm - Used to set search term in parent component
 * @param minSearchLength - How many chars search term needs to have for results table to be shown.
 * @param query - Query to used for getting options
 * @param queryDataField - Field in query data to use as options
 * @param placeholder - Placeholder for search input
 * @param getOptionLabel - Used to filter in-memory options with search string and to display selected option label
 * @param getOptionValue - Used to pick option to display label for with getOptionLabel and to get value passed to onChange
 * @param OptionComponent - Search result table row
 * @param ResultContainer - Search result table with header and with body with children as rows
 */
export function TypeaheadSelect<T, D extends string = 'data'>({
  value,
  onChange,
  setSearchTerm,
  minSearchLength = MIN_SEARCH_LENGTH,
  query,
  queryDataField = 'data',
  placeholder,
  getOptionLabel,
  getOptionValue,
  OptionComponent,
  ResultContainer,
  disabled,
  hasError,
  PopperComponent,
}: TypeaheadSelectProps<T, D>) {
  const intl = useIntl();
  const options = (query.data as { [key: string]: T[] } | undefined)?.[
    queryDataField
  ];
  return (
    <div className={container}>
      {value && options ? (
        <div className={selectedValueContainer}>
          <SearchIcon className={searchIcon} width="24px" />
          <div className={labelContainer}>
            {getOptionLabel(
              options.find((option) => getOptionValue(option) === value) ??
                ({} as T),
            )}
          </div>
          <IconButton onClick={() => onChange(undefined)} size="small">
            <CloseIcon width="15px" />
          </IconButton>
        </div>
      ) : (
        <TypeaheadSearch
          fullWidth
          options={options}
          isLoading={query.isFetching}
          noOptionsText={intl.formatMessage({
            defaultMessage: 'No results found',
          })}
          isOptionEqualToValue={(option1, option2) =>
            getOptionValue(option1) === getOptionValue(option2)
          }
          getOptionLabel={(optionOrSearchTerm) =>
            typeof optionOrSearchTerm === 'string'
              ? optionOrSearchTerm
              : getOptionLabel(optionOrSearchTerm)
          }
          onSelect={(option) => onChange(getOptionValue(option))}
          onReset={() => onChange(undefined)}
          onSearchTermChange={setSearchTerm}
          placeholder={query.isLoading ? '...' : placeholder}
          OptionComponent={OptionComponent}
          ResultContainer={ResultContainer}
          resetValueOnBlur={!value}
          adornmentPosition={AdornmentPosition.StartAndEnd}
          inputSize={InputSize.Large}
          disabled={disabled}
          hasError={hasError}
          PopperComponent={PopperComponent}
          minSearchLength={minSearchLength}
          autoFocus={!value}
          freeSolo={false}
        />
      )}
    </div>
  );
}
