import { useIntl } from 'react-intl';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { validators } from '@/shared/common/Form/validations';
import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';

export type StopMedFormFields = {
  endDate?: Date;
  reason?: string;
};

export function serializeStopMedFormFields(
  values: StopMedFormFields,
  noteId: Maybe<number>,
) {
  return {
    noteId: noteId || undefined,
    endDate: values.endDate?.toISOString(),
    reason: values.reason,
  };
}

export function useStopMedicationForm(initValues?: MedicationChange) {
  const intl = useIntl();
  const { required, date, maxLengthString } = validators(intl);

  return useFormFromConfig<StopMedFormFields>({
    fields: {
      endDate: {
        defaultValue: new Date(),
        validation: required(date()),
      },
      reason: {
        defaultValue: initValues?.reason || undefined,
        validation: maxLengthString({ maxLength: 255 }),
      },
    },
  });
}
