import type { IntlFormatters } from 'react-intl';

import {
  getMedicationsTitle,
  getSymptomsTitle,
} from '../VisitLayout/useEncounterModuleIntlMessages';

export function getFieldLabels(formatMessage: IntlFormatters['formatMessage']) {
  return {
    has_meds_to_report: getMedicationsTitle(formatMessage),
    has_symptoms_to_report: getSymptomsTitle(formatMessage),
    has_emergency_visits_to_report: formatMessage({
      defaultMessage: 'Emergency Visits',
    }),
  };
}
