import { Page } from '@/shared/common/Page';

import { Greeting } from './Greeting';
import { HomeActionArea } from './HomeActionArea';
import { page, pageBody } from './HomePage.css';

export function HomePage() {
  return (
    <Page classes={{ page }}>
      <Page.Body className={pageBody}>
        <Greeting />
        <HomeActionArea />
      </Page.Body>
    </Page>
  );
}
