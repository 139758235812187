import { useIntl } from 'react-intl';

import type { DeviceType } from '@/shared/types/device.types';
import { DeviceTypes } from '@/shared/types/device.types';

export function useDeviceTypeI18nMap(): Record<DeviceType, string> {
  const intl = useIntl();

  return {
    [DeviceTypes.BLOOD_GLUCOSE_METER]: intl.formatMessage({
      defaultMessage: 'Blood Glucose Meter',
    }),
    [DeviceTypes.BLOOD_PRESSURE_METER]: intl.formatMessage({
      defaultMessage: 'Blood Pressure Meter',
    }),
    [DeviceTypes.WEIGHT_SCALE]: intl.formatMessage({
      defaultMessage: 'Weight Scale',
    }),
  };
}
