import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import type { IntroFormFields } from '../../shared/IntroPatientAttendance';
import { getIntroductionFormConfig } from '../../shared/IntroPatientAttendance';
import { SharedIntroPatientAttendance } from '../../shared/IntroPatientAttendance/SharedIntroPatientAttendance';
import { titrationOutreachPath } from '../paths';

export function IntroPatientAttendance() {
  const intl = useIntl();
  const form = useWizardFormFromConfig<IntroFormFields>(
    titrationOutreachPath('/intro', '/patient-attendance'),
    getIntroductionFormConfig(intl),
  );

  return (
    <SharedIntroPatientAttendance
      form={form}
      sections={TITRATION_OUTREACH_SECTIONS}
      nextPath={titrationOutreachPath('/intro', '/patient-identification')}
      timeTrackingPath={titrationOutreachPath('/time-tracking', '/index')}
    />
  );
}
