import type { PatientContextVitalsVitalSigns } from '@/shared/generated/grpcGateway/telemetry.pb';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { ConditionProgram } from '@/shared/types/condition.types';
import { DeviceTypes } from '@/shared/types/device.types';
import type { Device } from '@/shared/types/device.types';

export function isHtn(program: Nullable<ConditionProgram>) {
  return (
    program === ConditionProgram.Hypertension ||
    program === ConditionProgram.T2DAndHTN
  );
}

export function isT2d(program: Nullable<ConditionProgram>) {
  return (
    program === ConditionProgram.TypeTwoDiabetes ||
    program === ConditionProgram.T2DAndHTN
  );
}

export function hasT2dWithScaleOnly(
  conditions: RpmCondition[],
  devices: Device[] | undefined,
) {
  const hasT2d = conditions.includes(Condition.TypeTwoDiabetes);
  const hasHtn = conditions.includes(Condition.Hypertension);
  if (hasT2d && !hasHtn) {
    const hasGlucometer = devices?.some(
      (device) => device.device_type === DeviceTypes.BLOOD_GLUCOSE_METER,
    );
    const hasScale = devices?.some(
      (device) => device.device_type === DeviceTypes.WEIGHT_SCALE,
    );
    return !hasGlucometer && hasScale;
  }
  return false;
}

export function hasHtnWithNoBpVitals(
  conditions: RpmCondition[],
  vitalsContext: PatientContextVitalsVitalSigns | undefined,
) {
  const hasHtn = conditions.includes(Condition.Hypertension);
  const hasNoBpVitals = vitalsContext?.bloodPressure?.diastolic?.avg30d === 0;
  return hasHtn && hasNoBpVitals;
}
