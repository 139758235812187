import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';

type OnClickOutsideFunc = () => void;

type ClickOutsideConfig = {
  listening?: boolean;
  skipIf?: (e: MouseEvent) => boolean;
  whitelistElements?: RefObject<HTMLDivElement>[];
};

const isClickingWhiteListElements = (
  e: MouseEvent,
  whitelistElements: RefObject<HTMLDivElement>[],
) =>
  whitelistElements.find(
    (whitelistElement) => whitelistElement?.current?.contains(e.target as Node),
  );

export function useOnClickOutside<T extends HTMLElement>(
  onClickOutside: OnClickOutsideFunc,
  {
    listening = true,
    skipIf = () => false,
    whitelistElements = [],
  }: ClickOutsideConfig = {},
) {
  // Handles clicking outside a returned ref
  const node = useRef<T>(null);
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (skipIf(e)) {
        return;
      }
      if (isClickingWhiteListElements(e, whitelistElements)) {
        return;
      }
      if (node?.current?.contains(e.target as Node)) {
        // click inside ref
        return;
      }
      // click outside of ref
      onClickOutside();
    },
    [whitelistElements, onClickOutside, skipIf],
  );

  useEffect(() => {
    if (listening) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [listening, handleClickOutside]);

  return node;
}
