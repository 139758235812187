import { useIntl } from 'react-intl';

import type { SelectChangeEvent } from '@/shared/common/Select';
import { Option, Select } from '@/shared/common/Select';

import { NotesFilterValue } from './Notes.types';
import { dropdownContainer } from './NotesFilterDropdown.css';

type Props = {
  onSelect: (value: NotesFilterValue) => void;
  value: NotesFilterValue;
};

export function NotesFilterDropdown({ onSelect, value }: Props) {
  const intl = useIntl();

  function handleSelect(event: SelectChangeEvent<NotesFilterValue>) {
    onSelect(event.target.value as NotesFilterValue);
  }

  return (
    <div className={dropdownContainer}>
      <Select fullWidth onChange={handleSelect} value={value}>
        <Option value={NotesFilterValue.AllNotes}>
          {intl.formatMessage({ defaultMessage: 'All Notes' })}
        </Option>
        <Option value={NotesFilterValue.ClinicalNotes}>
          {intl.formatMessage({ defaultMessage: 'Clinical Notes' })}
        </Option>
        {/* TODO: Nested visit filter options https://cadencerpm.atlassian.net/browse/PLAT-5012 */}
        <Option value={NotesFilterValue.NonClinicalNotes}>
          {intl.formatMessage({ defaultMessage: 'Non-Clinical Notes' })}
        </Option>
        <Option value={NotesFilterValue.AlertNotes}>
          {intl.formatMessage({ defaultMessage: 'Alert Notes' })}
        </Option>
        <Option value={NotesFilterValue.PatientNoShows}>
          {intl.formatMessage({ defaultMessage: 'Patient No Shows' })}
        </Option>
      </Select>
    </div>
  );
}
