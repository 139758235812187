import { FormattedMessage, useIntl } from 'react-intl';

import {
  FieldGroup,
  Script,
  YesNoRadioGroup,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import AlertTriangle from '@/shared/assets/svgs/alertTriangle.svg?react';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Wizard } from '@/shared/common/Wizard';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';
import { DiseaseSpecificSymptoms } from './DiseaseSpecificSymptoms';
import {
  alertWrapper,
  heading,
  icon,
  iconWrapper,
  instructions,
  textWrapper,
} from './SharedSymptoms.css';
import { SlackLink } from './SlackLink';
import type { SymptomsFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<SymptomsFormFields>;
  sections: CNSectionMap;
};

export function SharedSymptoms({ form, sections }: Props) {
  const intl = useIntl();
  const { program, cnVisitType } = useCNFormContext();
  const sectionVisibility = useSectionVisibility(form);

  return (
    <Wizard.Step
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Symptoms' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      form={form}
    >
      <FieldGroup>
        <YesNoRadioGroup
          name="newConcerns"
          classes={{ label: scriptLabel }}
          label={
            <Script>
              {cnVisitType === TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT ? (
                <FormattedMessage defaultMessage="How have you been feeling over the past two weeks? Do you have any new concerns or recent changes to your health?" />
              ) : (
                <FormattedMessage defaultMessage="How have you been feeling since the last visit with Cadence? Do you have any new concerns or recent changes to your health?" />
              )}
            </Script>
          }
        />
        {sectionVisibility.patientReportedSymptoms && (
          <>
            <Script>
              <FormattedMessage defaultMessage="I'm sorry to hear you're not feeling well. Tell me about what has been going on." />
            </Script>
            <Form.TextArea
              required
              size={12}
              rows={3}
              name="patientReportedSymptoms"
              label={
                <FormattedMessage defaultMessage="Patient reported issue(s)" />
              }
            />
          </>
        )}
        {!!program && sectionVisibility.hasSymptoms && (
          <DiseaseSpecificSymptoms program={program} />
        )}
        {sectionVisibility.escalationConfirmation && (
          <>
            <div className={alertWrapper}>
              <div className={iconWrapper}>
                <AlertTriangle className={icon} />
              </div>
              <div className={textWrapper}>
                <span className={heading}>
                  <FormattedMessage
                    defaultMessage="Submit an urgent Cadence task and slack the {slackLink} channel"
                    values={{ slackLink: <SlackLink /> }}
                  />
                </span>
                <span className={instructions}>
                  <FormattedMessage
                    defaultMessage="Submit an urgent Cadence task to the assignee: Triage RN Queue. Priority is Urgent. Send a Slack message in {slackLink}."
                    values={{ slackLink: <SlackLink /> }}
                  />
                </span>
              </div>
            </div>
            <Script>
              <FormattedMessage defaultMessage="Based on your response, I'm going to reach out to one of our nurses for an immediate evaluation. Stay on the line with me while I share your case with them." />
            </Script>
            <YesNoRadioGroup
              name="clinicalEscalationConfirmed"
              label={
                <FormattedMessage defaultMessage="Was clinical escalation confirmed?" />
              }
            />
          </>
        )}
        {sectionVisibility.escalationDetails && (
          <Form.TextArea
            required
            size={12}
            rows={3}
            name="escalationDetails"
            label={<FormattedMessage defaultMessage="Escalation details" />}
          />
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}

function useSectionVisibility(form: ConfiguredForm<SymptomsFormFields>) {
  const newConcerns = form.watch('newConcerns');
  const patientReportedSymptoms = form.watch('patientReportedSymptoms');
  const symptoms = form.watch([
    'newWeakness',
    'fainting',
    'slurredSpeech',
    'confusion',
    'chestPain',
    'dizziness',
  ]);
  const escalationConfirmed = form.watch('clinicalEscalationConfirmed');

  return {
    patientReportedSymptoms: newConcerns === 'true',
    hasSymptoms: !!patientReportedSymptoms,
    escalationConfirmation: symptoms.some((value) => value === 'true'),
    escalationDetails: escalationConfirmed === 'true',
  };
}
