import type { IntlShape } from 'react-intl';
import type * as yup from 'yup';

type CurriedWithOptionalParams<P, S> = (intl: IntlShape) => (params?: P) => S;
type CurriedWithRequiredParams<P, S> = (intl: IntlShape) => (params: P) => S;

export type CurriedValidator<P, S> =
  | CurriedWithOptionalParams<P, S>
  | CurriedWithRequiredParams<P, S>;

/**
 * Factory method for creating validators that all have access to internationalization
 * via currying. If params are passed into the factory, then params will be required
 * by the validator, otherwise they will be optional.
 */
export function validator<P, S extends yup.AnySchema>(
  func: (intl: IntlShape, params: P) => S,
): CurriedValidator<P, S> {
  return (intl: IntlShape) => (params: P) => func(intl, params);
}
