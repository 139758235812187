import { Table } from '@/shared/common/Table';
import type {
  LabValueStatus,
  ReferenceLab,
} from '@/shared/generated/grpcGateway/labs.pb';

import type { LabsWithRefInfo } from '../types';
import { LabCellContent } from './LabCellContent';
import type { GroupedLabs } from './LabsTable';
import { tableCell } from './LabsTable.css';

type LabResultCellsProps = {
  columns: { title: string }[];
  groupedLabs: GroupedLabs;
  referenceLab: ReferenceLab;
};

export const PLACEHOLDER = '---';

export function LabResultCells({
  columns,
  groupedLabs,
  referenceLab,
}: LabResultCellsProps) {
  const relevantColumns = columns.filter(({ title }) => !!title);

  const cells = relevantColumns.map(({ title }, idx) => {
    const labs = groupedLabs[title];

    // Filter out labs that don't match the row analyte
    const relevantLabs = labs.filter(
      (lab) => lab.referenceName === referenceLab.name,
    );

    if (!relevantLabs.length) {
      return (
        <Table.TextCell key={`${title}-missing-${idx}`} className={tableCell}>
          {PLACEHOLDER}
        </Table.TextCell>
      );
    }

    const parsedLabs = relevantLabs.map((lab) => {
      // some lab values are ranges that parseFloat doesn't choke on. for example,
      // 0.1-0.12 will be parsed as 0.1, losing the rest of the range. this regex
      // ensures that our string looks like an actual number (with optional negative
      // at the start) before we try to parse it.
      const isNumeric = /^-?[\d.]+$/.test(lab.value ?? '');
      const parsedValue = isNumeric ? parseFloat(lab.value as string) : NaN;

      const modifiedLab: LabsWithRefInfo = {
        ...lab,
        value: Number.isNaN(parsedValue) ? lab.value : parsedValue.toString(),
      };

      return {
        value: modifiedLab.value,
        s3Uri: modifiedLab.s3Uri,
        status: modifiedLab.valueStatus as LabValueStatus,
      };
    });

    return (
      <LabResultCell key={`${title}-${referenceLab.name}`} labs={parsedLabs} />
    );
  });

  return <>{cells}</>;
}

type LabResultCellProps = {
  labs: {
    value: Maybe<string>;
    s3Uri: Maybe<string>;
    status: LabValueStatus;
  }[];
};

function LabResultCell({ labs }: LabResultCellProps) {
  const cell = labs.map((lab, idx) => (
    <LabCellContent
      key={`${lab.value}-${idx}`}
      {...lab}
      idx={idx}
      isSingle={labs.length === 1}
    />
  ));

  return <Table.NodeCell className={tableCell}> {cell} </Table.NodeCell>;
}
