import { INIT_VISIT_SECTIONS } from '../../metadata';
import { SharedDeviceUsage } from '../../shared/ProductSupport';
import { initVisitPath } from '../paths';
import { useDeviceUsageNextPath } from './useDeviceUsageNextPath';

export function DeviceUsage() {
  const highUsagePath = useDeviceUsageNextPath();
  return (
    <SharedDeviceUsage
      sections={INIT_VISIT_SECTIONS}
      highUsagePath={highUsagePath}
      lowUsagePath={initVisitPath('/product-support', '/low-device-usage')}
    />
  );
}
