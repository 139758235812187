/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutosavedNoteSchema } from '../models/AutosavedNoteSchema';
import type { DeleteAutosavedNoteSchema } from '../models/DeleteAutosavedNoteSchema';
import type { DraftNoteSchema } from '../models/DraftNoteSchema';
import type { NoteListSchema } from '../models/NoteListSchema';
import type { NoteRequestSchema } from '../models/NoteRequestSchema';
import type { NoteSchema } from '../models/NoteSchema';
import type { PublishDraftNoteSchema } from '../models/PublishDraftNoteSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PatientNotesService {
    /**
     * Returns a list of notes for a given patient
     * @param patientId Patient UUID
     * @param page Page number
     * @param pageSize Page size
     * @param sortBy Field to sort by
     * @param title Note title to search for
     * @param author Note author name to search for
     * @param createdBy Note author ID to filter by
     * @param orderBy Type of the ordering
     * @param noteType The types of notes to return
     * @param isClinicalNavigatorNote Whether or not to return clinical navigator notes
     * @returns NoteListSchema
     * @throws ApiError
     */
    public static getPmsApiV1NotesPatient(
        patientId: string,
        page?: number,
        pageSize?: number,
        sortBy?: string,
        title?: string,
        author?: string,
        createdBy?: string,
        orderBy: 'asc' | 'desc' = 'asc',
        noteType?: Array<'STANDARD' | 'ALERT_DISMISSAL' | 'ALERT_RESOLUTION' | 'ALERT_AUTO_RESOLUTION'>,
        isClinicalNavigatorNote?: boolean,
    ): CancelablePromise<NoteListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/notes/patient/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
                'title': title,
                'author': author,
                'created_by': createdBy,
                'order_by': orderBy,
                'note_type': noteType,
                'is_clinical_navigator_note': isClinicalNavigatorNote,
            },
        });
    }
    /**
     * Create a new Patient Note with provided data
     * @param patientId Patient UUID
     * @param requestBody A JSON object for a new Patient Note
     * @returns NoteSchema Successful Operation
     * @throws ApiError
     */
    public static postPmsApiV1NotesPatient(
        patientId: string,
        requestBody: NoteRequestSchema,
    ): CancelablePromise<NoteSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/notes/patient/{patient_id}',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Bad Request`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Delete the autosaved note for a specific patient and the current user
     * Delete the autosaved note for a specific patient and the current user
     * @param patientId Patient UUID
     * @param requestBody A JSON object containing note information
     * @returns void
     * @throws ApiError
     */
    public static deletePmsApiV1NotesPatientAutosaved(
        patientId: string,
        requestBody?: DeleteAutosavedNoteSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pms/api/v1/notes/patient/{patient_id}/autosaved',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource Not Found`,
            },
        });
    }
    /**
     * Gets the autosaved note for a specific patient and the current user
     * Gets the autosaved note added for a specific patient and the current user
     * @param patientId Patient UUID
     * @returns AutosavedNoteSchema
     * @throws ApiError
     */
    public static getPmsApiV1NotesPatientAutosaved(
        patientId: string,
    ): CancelablePromise<AutosavedNoteSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/notes/patient/{patient_id}/autosaved',
            path: {
                'patient_id': patientId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource Not Found`,
            },
        });
    }
    /**
     * Upserts the autosaved note for a specific patient and the current user
     * Creates an autosaved note for a specific patient and the current user
     * @param patientId Patient UUID
     * @param requestBody A JSON object containing note information
     * @returns AutosavedNoteSchema
     * @throws ApiError
     */
    public static putPmsApiV1NotesPatientAutosaved(
        patientId: string,
        requestBody: AutosavedNoteSchema,
    ): CancelablePromise<AutosavedNoteSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pms/api/v1/notes/patient/{patient_id}/autosaved',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource Not Found`,
            },
        });
    }
    /**
     * Delete the draft note for a specific patient
     * Delete the draft note for a specific patient
     * @param patientId Patient UUID
     * @returns void
     * @throws ApiError
     */
    public static deletePmsApiV1NotesPatientDraft(
        patientId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pms/api/v1/notes/patient/{patient_id}/draft',
            path: {
                'patient_id': patientId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource Not Found`,
            },
        });
    }
    /**
     * Gets the draft note added for a specific patient.
     * Gets the draft note added for a specific patient
     * @param patientId Patient UUID
     * @returns DraftNoteSchema
     * @throws ApiError
     */
    public static getPmsApiV1NotesPatientDraft(
        patientId: string,
    ): CancelablePromise<DraftNoteSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pms/api/v1/notes/patient/{patient_id}/draft',
            path: {
                'patient_id': patientId,
            },
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Creates a draft note for a specific patient
     * Creates a draft note for a specific patient
     * @param patientId Patient UUID
     * @param requestBody A JSON object containing note information
     * @returns DraftNoteSchema
     * @throws ApiError
     */
    public static postPmsApiV1NotesPatientDraft(
        patientId: string,
        requestBody: DraftNoteSchema,
    ): CancelablePromise<DraftNoteSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/notes/patient/{patient_id}/draft',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Updates a draft note for a specific patient
     * Updates a draft note for a specific patient
     * @param patientId Patient UUID
     * @param requestBody A JSON object containing note information
     * @returns DraftNoteSchema
     * @throws ApiError
     */
    public static putPmsApiV1NotesPatientDraft(
        patientId: string,
        requestBody: DraftNoteSchema,
    ): CancelablePromise<DraftNoteSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pms/api/v1/notes/patient/{patient_id}/draft',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Publishes a draft note added for a specific patient
     * Publishes a draft note added for a specific patient
     * @param patientId Patient UUID
     * @param requestBody A JSON object for publishing a patient draft Note
     * @returns NoteSchema
     * @throws ApiError
     */
    public static postPmsApiV1NotesPatientDraftPublish(
        patientId: string,
        requestBody?: PublishDraftNoteSchema,
    ): CancelablePromise<NoteSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/notes/patient/{patient_id}/draft/publish',
            path: {
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Republishes a patient note to the EMR that failed to sync
     * Republishes a patient note to the EMR that failed to sync
     * @param patientId Patient UUID
     * @param noteId Note ID
     * @returns NoteSchema
     * @throws ApiError
     */
    public static postPmsApiV1NotesPatientRepublish(
        patientId: string,
        noteId: number,
    ): CancelablePromise<NoteSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/notes/patient/{patient_id}/republish/{note_id}',
            path: {
                'patient_id': patientId,
                'note_id': noteId,
            },
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Publishes a note
     * Publishes a note
     * @param patientId Patient UUID
     * @param noteId Note ID
     * @returns NoteSchema
     * @throws ApiError
     */
    public static postPmsApiV1PatientNotesPublish(
        patientId: string,
        noteId: number,
    ): CancelablePromise<NoteSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/patient/{patient_id}/notes/{note_id}/publish',
            path: {
                'patient_id': patientId,
                'note_id': noteId,
            },
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }
    /**
     * Create and associate a zendesk ticket with the given note
     * Creates and associates a zendesk ticket with the given note
     * @param patientId Patient UUID
     * @param noteId Note ID
     * @returns NoteSchema
     * @throws ApiError
     */
    public static postPmsApiV1PatientNotesZendeskTicket(
        patientId: string,
        noteId: number,
    ): CancelablePromise<NoteSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pms/api/v1/patient/{patient_id}/notes/{note_id}/zendesk-ticket',
            path: {
                'patient_id': patientId,
                'note_id': noteId,
            },
            errors: {
                401: `Unauthorized`,
                403: `forbidden`,
                422: `Unprocessable Entity`,
            },
        });
    }
}
