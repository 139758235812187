import { addDays, format, subDays } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { DatePicker } from '@/shared/common/DatePicker';

import type { NoteFieldValidationResult } from '../../validation';
import { TimeTrackerLabel } from '../TimeTrackerLabel';
import { ValidationMessage } from '../ValidationMessage';
import { validationError } from './DateOfEncounter.css';

export function DateOfEncounter({
  onChange,
  value,
  validationResult,
}: {
  onChange: (value: Date | null) => void;
  value?: Date;
  validationResult?: NoteFieldValidationResult;
}) {
  const messageOverride = useValidationMessageOverride(validationResult);
  return (
    <div>
      <TimeTrackerLabel
        message={<FormattedMessage defaultMessage="Date of encounter" />}
        error={Boolean(validationResult)}
      />
      <DatePicker
        onChange={(date) => onChange(date as Nullable<Date>)}
        value={value ?? null}
        isError={Boolean(validationResult)}
      />
      <ValidationMessage
        validationMessage={validationResult?.message}
        messageOverride={messageOverride}
        className={validationError}
      />
    </div>
  );
}

function useValidationMessageOverride(
  validationResult?: NoteFieldValidationResult,
) {
  if (!validationResult || !validationResult.type || !validationResult.params) {
    return null;
  }

  switch (validationResult.type) {
    case 'required':
      return <FormattedMessage defaultMessage="Required" />;
    case 'typeError':
      return <FormattedMessage defaultMessage="Must be a valid date" />;
    case 'max':
      return (
        <FormattedMessage
          defaultMessage="Must be before {afterDate}"
          values={{
            afterDate: format(
              addDays(validationResult.params.max as Date, 1),
              'MM/dd/yyyy',
            ),
          }}
        />
      );
    case 'min':
      return (
        <FormattedMessage
          defaultMessage="Must be after {afterDate}"
          values={{
            afterDate: format(
              subDays(validationResult.params.min as Date, 1),
              'MM/dd/yyyy',
            ),
          }}
        />
      );
    default:
      return null;
  }
}
