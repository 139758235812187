import { useRef } from 'react';

/**
 * Returns a prefix-unique render key. Whenever 'refresh()' function is called,
 * a new unique key is returned.
 */
export function useUniqueRenderKey(prefix: string) {
  const uniqueRenderKey = useRef(0);

  return {
    key: `${prefix}-${uniqueRenderKey.current}`,
    refresh: () => {
      uniqueRenderKey.current += 1;
    },
  };
}
