import { FormattedMessage } from 'react-intl';

import { Form } from '@/shared/common/Form';

import { EndEncounterReason } from './formConfig';

export function NoShowReasons() {
  return (
    <>
      <Form.RadioGroup
        required
        size={12}
        name="noShowReason"
        label={
          <FormattedMessage defaultMessage="Select the reason this visit was not completed" />
        }
        orientation="vertical"
      >
        <Form.Radio value={EndEncounterReason.NoShow}>
          <FormattedMessage defaultMessage="No show" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientRequestReschedule}>
          <FormattedMessage defaultMessage="Patient requested reschedule" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientHospitalized}>
          <FormattedMessage defaultMessage="Patient hospitalized" />
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.SchedulingErrorDisenrollment}>
          <FormattedMessage defaultMessage="Scheduling error / disenrollment" />
        </Form.Radio>
      </Form.RadioGroup>
    </>
  );
}
