import { VitalAverageLevel } from './types';

export function getBpVitalLevel(
  avgSystolicLast30D: number,
  avgDiastolicLast30D: number,
) {
  // Patient is considered hypotensive so use generic scripting
  if (avgSystolicLast30D < 90 || avgDiastolicLast30D < 60) {
    return VitalAverageLevel.Unknown;
  }
  if (avgSystolicLast30D < 130 && avgDiastolicLast30D < 80) {
    return VitalAverageLevel.One;
  }
  if (avgSystolicLast30D < 140 && avgDiastolicLast30D < 90) {
    return VitalAverageLevel.Two;
  }
  if (avgSystolicLast30D < 160 && avgDiastolicLast30D < 100) {
    return VitalAverageLevel.Three;
  }
  return VitalAverageLevel.Four;
}

export function getBpVitalStr(avgSystolic: number, avgDiastolic: number) {
  return `${avgSystolic}/${avgDiastolic}`;
}

export function getBgVitalLevel(avgGlucoseLast30d: number) {
  // Patient is considered hypoglycemic so use generic scripting
  if (avgGlucoseLast30d < 70) {
    return VitalAverageLevel.Unknown;
  }
  if (avgGlucoseLast30d < 154) {
    return VitalAverageLevel.One;
  }
  if (avgGlucoseLast30d <= 183) {
    return VitalAverageLevel.Two;
  }
  if (avgGlucoseLast30d <= 200) {
    return VitalAverageLevel.Three;
  }
  return VitalAverageLevel.Four;
}
