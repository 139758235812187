import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { FieldGroup } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { ChevronLeft } from '@/shared/assets/svgs';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Wizard } from '@/shared/common/Wizard';
import { Button } from '@/shared/tempo/atom/Button';

import type { CNSectionMap } from '../../metadata';
import { TimeTrackingButton } from '../TimeTrackingButton';
import type { EndCallFormFields } from './formConfig';
import { EndCallReason } from './formConfig';

type Props = {
  form: ConfiguredForm<EndCallFormFields>;
  sections: CNSectionMap;
  nextPath: string;
};

export function SharedEndCall({ form, sections, nextPath }: Props) {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Wizard.Step
      form={form}
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'End early' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => history.push(nextPath)}
      backButton={({ onBack }) => (
        <Button size="small" variant="tertiary" onPress={onBack}>
          <Button.Icon>
            <ChevronLeft />
          </Button.Icon>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
      )}
      submitButton={({ ...submitBtnProps }) => (
        <TimeTrackingButton {...submitBtnProps} submitPath={nextPath} />
      )}
    >
      <FieldGroup>
        <Form.RadioGroup
          required
          size={12}
          name="endCallReason"
          label={
            <FormattedMessage defaultMessage="Select the reason for ending this encounter" />
          }
          orientation="vertical"
        >
          <Form.Radio value={EndCallReason.PatientUnableToSpeak}>
            <FormattedMessage defaultMessage="Patient unable to speak at this time" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.SchedulingErrorDisenrollment}>
            <FormattedMessage defaultMessage="Scheduling error / disenrollment" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.PatientInNonCadenceState}>
            <FormattedMessage defaultMessage="Patient in non-Cadence state" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.PatientHospitalized}>
            <FormattedMessage defaultMessage="Patient currently hospitalized" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.PatientRefusedAppointment}>
            <FormattedMessage defaultMessage="Patient refused appointment" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.PatientDissatisfied}>
            <FormattedMessage defaultMessage="Patient dissatisfied" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.TimeConstraints}>
            <FormattedMessage defaultMessage="Time constraints" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.DeviceDelayTroubleshooting}>
            <FormattedMessage defaultMessage="Device delay / troubleshooting" />
          </Form.Radio>
          <Form.Radio value={EndCallReason.Other}>
            <FormattedMessage defaultMessage="Other" />
          </Form.Radio>
        </Form.RadioGroup>
        <Form.TextArea
          size={12}
          rows={4}
          label={intl.formatMessage({ defaultMessage: 'Notes' })}
          name="endCallNotes"
        />
      </FieldGroup>
    </Wizard.Step>
  );
}
