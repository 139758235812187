import { eachDayOfInterval, getUnixTime, parseISO } from 'date-fns';

import { logger } from '@/logger';
import type {
  ListPatientVitalsResponseVital,
  ListPatientVitalsResponseVitalType,
} from '@/shared/generated/grpcGateway/telemetry.pb';
import { color } from '@/shared/tempo/theme';
import type {
  BloodGlucoseVital,
  BloodPressureVital,
  HeartRateVital,
  TVitalsListPayload,
  VitalsForDateType,
  VitalsGraphs,
  WeightVital,
} from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';

import { convertToLbs, convertToMgDl, convertToMmHg } from './unit-helpers';

export const mapGraphData = (data: TVitalsListPayload) => {
  const COMMON_MARK_STYLES = {
    stroke: color.Theme.Light.Primary,
    fill: color.Theme.Light.Primary,
  };

  const result: VitalsGraphs = {
    blood_pressure: {
      diastolic: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
      systolic: {
        data: [],
        markStyle: { ...COMMON_MARK_STYLES, fill: color.Palette.Neutral.White },
      },
    },
    heart_rate: {
      base: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
    },
    weight: {
      base: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
    },
    blood_glucose: {
      base: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
    },
  };
  if (!data) {
    return result;
  }

  (Object.keys(data) as (keyof TVitalsListPayload)[]).forEach((key) =>
    data[key].forEach((reading) => {
      if (result?.[key]) {
        switch (key) {
          case VitalType.BloodPressure:
            result[key].diastolic.data.push({
              x: getUnixTime(parseISO(reading.timestamp)),
              y: convertToMmHg((reading as BloodPressureVital).diastolic),
            });
            result[key].systolic.data.push({
              x: getUnixTime(parseISO(reading.timestamp)),
              y: convertToMmHg((reading as BloodPressureVital).systolic),
            });
            break;
          case VitalType.HeartRate:
            result[key].base.data.push({
              x: getUnixTime(parseISO(reading.timestamp)),
              y: (reading as HeartRateVital).pulse,
            });
            break;
          case VitalType.Weight:
            result[key].base.data.push({
              x: getUnixTime(parseISO(reading.timestamp)),
              y: convertToLbs((reading as WeightVital).weight),
            });
            break;
          case VitalType.BloodGlucose:
            result[key].base.data.push({
              x: getUnixTime(parseISO(reading.timestamp)),
              y: convertToMgDl((reading as BloodGlucoseVital).glucose_level),
            });
            break;
          default:
            logger.warn(`Unhandled vital reading of type '${key}'`);
        }
      }
    }),
  );

  return result;
};

export const getXAxisTickValues = (days: number[]) =>
  [...days].reverse().filter((day, index) => {
    // Show every second X axis tick label when date range is 2 weeks or 30 days
    if (days.length >= 16 && days.length <= 32) {
      return index % 2 === 0;
    }
    // Show every 5th X axis tick label when date range is between 30days and 3 months
    if (days.length > 32 && days.length <= 94) {
      return index % 5 === 0;
    }
    // Show every 10th X axis tick label when date range is between 3 months and 6 months
    if (days.length > 94 && days.length <= 186) {
      return index % 10 === 0;
    }
    // Show every 30th X axis tick label when date range is between 6 months and 1 year
    if (days.length > 186 && days.length <= 368) {
      return index % 30 === 0;
    }
    // Show every X axis tick label when date range is 1 week
    return true;
  });

export const getGraphDaysSpan = (startDate: Date, endDate: Date) =>
  eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((date) => getUnixTime(date));

// This ensure empty chart has a height
const DEFAULT_Y_AXIS_MIN_VALUE = 0;
const DEFAULT_Y_AXIS_MAX_VALUE = 200;

// This ensure the larges y value won't be
// too close to the top xAxis
const DEFAULT_Y_AXIS_MARGIN = 20;

export const getYAxisDomain = (graphs: { [graphName: string]: GraphInfo }) => {
  const yAxisValues = Object.values(graphs).flatMap(({ data }) =>
    data.map(({ y }) => Number(y)),
  );

  if (!yAxisValues.length) {
    return [DEFAULT_Y_AXIS_MIN_VALUE, DEFAULT_Y_AXIS_MAX_VALUE];
  }

  const minValue = Math.floor(Math.min(...yAxisValues));
  const maxValue = Math.max(...yAxisValues);
  return [
    Math.max(minValue - DEFAULT_Y_AXIS_MARGIN, 0),
    maxValue + DEFAULT_Y_AXIS_MARGIN,
  ];
};

export const groupVitalsByType = (
  vitals: ListPatientVitalsResponseVital[],
): VitalsForDateType => {
  const result: VitalsForDateType = {};
  if (!vitals) {
    return result;
  }
  vitals.forEach((vital) => {
    const type = vital?.type as ListPatientVitalsResponseVitalType;
    if (!result[type]) {
      result[type] = [];
    }
    result[type].push(vital);
  });

  return result;
};

export const tempMapGraphData = (data: VitalsForDateType) => {
  const COMMON_MARK_STYLES = {
    stroke: color.Theme.Light.Primary,
    fill: color.Theme.Light.Primary,
  };

  const result: VitalsGraphs = {
    blood_pressure: {
      diastolic: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
      systolic: {
        data: [],
        markStyle: { ...COMMON_MARK_STYLES, fill: color.Palette.Neutral.White },
      },
    },
    heart_rate: {
      base: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
    },
    weight: {
      base: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
    },
    blood_glucose: {
      base: {
        data: [],
        markStyle: COMMON_MARK_STYLES,
      },
    },
  };
  if (!data) {
    return result;
  }

  (Object.keys(data) as (keyof VitalsForDateType)[]).forEach((key) =>
    data[key].forEach((reading) => {
      if (typeof key === 'string') {
        const vitalKey = key.toLowerCase() as VitalType;
        switch (vitalKey) {
          case VitalType.BloodPressure:
            result[vitalKey].diastolic.data.push({
              x: getUnixTime(parseISO(reading?.timestamp || '')),
              y: convertToMmHg((reading?.secondaryValue as number) || 0),
            });
            result[vitalKey].systolic.data.push({
              x: getUnixTime(parseISO(reading?.timestamp || '')),
              y: convertToMmHg((reading?.value as number) || 0),
            });
            break;
          case VitalType.HeartRate:
            result[vitalKey].base.data.push({
              x: getUnixTime(parseISO(reading?.timestamp || '')),
              y: reading?.value as number,
            });
            break;
          case VitalType.Weight:
            result[vitalKey].base.data.push({
              x: getUnixTime(parseISO(reading?.timestamp || '')),
              y: convertToLbs((reading?.value as number) || 0),
            });
            break;
          case VitalType.BloodGlucose:
            result[vitalKey].base.data.push({
              x: getUnixTime(parseISO(reading?.timestamp || '')),
              y: convertToMgDl((reading?.value as number) || 0),
            });
            break;
          default:
            logger.warn(`Unhandled vital reading of type '${key}'`);
        }
      }
    }),
  );

  return result;
};
