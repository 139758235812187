export enum TriggerBy {
  CLICK = 'click',
  HOVER = 'hover',
  MANUAL = 'manual',
}

export type Trigger =
  | { type: TriggerBy.HOVER }
  | { type: TriggerBy.CLICK }
  | { type: TriggerBy.MANUAL; open: boolean; onClose: () => void };
