export enum ActionIconTagType {
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  CAUTION = 'caution',
  ERROR = 'error',
  PRIMARY = 'primary',
  UNSET = 'unset',
  OUTLINE = 'outline',
  ERROR_OUTLINE = 'errorOutline',
}
