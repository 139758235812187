import {
  acuityTierToPatientTierFilter,
  conditionConfidenceToHighConfidenceFilter,
  conditionsToProgramsFilter,
} from './converters';
import type { QueryParamsFilterLookup } from './grpc-filter-builder';
import { OpType } from './grpc-filter-builder';
import type { QueryParamsOrderByLookup } from './grpc-orderby-builder';

export const PATIENTS_QUERY_PARAMS_ORDER_BY_LOOKUP: QueryParamsOrderByLookup = {
  status: {},
  upcoming_appointment_date: {
    rename: 'upcoming_appointment_time',
    default: true,
  },
  first_name: {
    rename: 'given_name',
  },
  last_name: {
    rename: 'family_name',
  },
  bmi: {},
  primary_insurance_name: {
    rename: 'primary_insurance',
  },
  secondary_insurance_name: {
    rename: 'secondary_insurance',
  },
  visited_emergency_department: {},
  status_date: {
    rename: 'last_status_note_change_date',
  },
  a1c: {
    rename: 'observations.a1c',
  },
  ejection_fraction_lower: {
    rename: 'observations.ef_lower',
  },
  ejection_fraction_upper: {
    rename: 'observations.ef_upper',
  },
  last_blood_pressure_average_systolic: {
    rename: 'observations.last_blood_pressure_average_systolic',
  },
  last_blood_pressure_average_diastolic: {
    rename: 'observations.last_blood_pressure_average_diastolic',
  },
  last_blood_pressure_max_systolic: {
    rename: 'observations.last_blood_pressure_max_systolic',
  },
  last_blood_pressure_max_diastolic: {
    rename: 'observations.last_blood_pressure_max_diastolic',
  },
  last_blood_pressure_systolic: {
    rename: 'observations.last_blood_pressure_systolic',
  },
  last_blood_pressure_diastolic: {
    rename: 'observations.last_blood_pressure_diastolic',
  },
  order_date: {},
  latest_nab_list_generated_at: {
    rename: 'latest_epl_list_generation_time',
  },
  enrollment_score: {},
};

export const PATIENTS_QUERY_PARAMS_FILTER_LOOKUP: QueryParamsFilterLookup = {
  appt_from: {
    renames: ['upcomingAppointmentTime'],
    op: OpType.GREATER_THAN_OR_EQUAL,
  },
  appt_to: {
    renames: ['upcomingAppointmentTime'],
    op: OpType.LESS_THAN_OR_EQUAL,
  },
  dob_from: {
    renames: ['dob'],
    op: OpType.GREATER_THAN_OR_EQUAL,
  },
  dob_to: {
    renames: ['dob'],
    op: OpType.LESS_THAN_OR_EQUAL,
  },
  last_clinic_appt_from: {
    renames: ['lastClinicApptTime'],
    op: OpType.GREATER_THAN_OR_EQUAL,
  },
  last_clinic_appt_to: {
    renames: ['lastClinicApptTime'],
    op: OpType.LESS_THAN_OR_EQUAL,
  },
  status_date_start: {
    renames: ['lastStatusNoteChangeDate'],
    op: OpType.GREATER_THAN_OR_EQUAL,
  },
  status_date_end: {
    renames: ['lastStatusNoteChangeDate'],
    op: OpType.LESS_THAN_OR_EQUAL,
  },
  id: {
    renames: ['id'],
    op: OpType.EQUAL,
  },
  fnormrn: {
    renames: ['fullName', 'mrn'],
    op: OpType.ILIKE,
    valueMapper: (val: string) => `%${val}%`,
  },
  last_clinic_appt_provider_name: {
    renames: ['lastClinicApptProvider'],
    op: OpType.EQUAL,
  },
  scheduling_care_provider_name: {
    renames: ['schedulingCareProvider'],
    op: OpType.EQUAL,
  },
  identified_provider_npi_id: {
    renames: ['identifiedProviderNpiId'],
    op: OpType.EQUAL,
  },
  status_reason: {
    renames: ['lastStatusNoteReason'],
    op: OpType.EQUAL,
  },
  address_region: {
    renames: ['addressRegion'],
    op: OpType.EQUAL,
  },
  hospital_id: {
    renames: ['hospitalId'],
    op: OpType.EQUAL,
  },
  status: {
    renames: ['status'],
    op: OpType.EQUAL,
  },
  care_provider_id: {
    renames: ['careProviders'],
    op: OpType.CONTAINS,
  },
  npi_id: {
    renames: ['referringProviderNpiId'],
    op: OpType.EQUAL,
    valueMapper: (val: string[]) => val.map((v) => Number(v)),
  },
  usual_provider_name: {
    renames: ['usualProvider'],
    op: OpType.EQUAL,
  },
  nab_list_id: {
    renames: ['eplListIds'],
    op: OpType.CONTAINS,
  },
  condition: {
    nativeFilterGetter: conditionsToProgramsFilter,
  },
  acuity_tier: {
    nativeFilterGetter: acuityTierToPatientTierFilter,
  },
  condition_confidence: {
    nativeFilterGetter: conditionConfidenceToHighConfidenceFilter,
  },
};
