import { useEffect } from 'react';
import type { QueryKey, UseQueryOptions } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

/**
 * Used in situations where you don't want to refetch data during the
 * lifetime of the component.
 */
export function useQueryNoRefetch<T>(
  queryKey: QueryKey,
  params?: UseQueryOptions<T>,
) {
  const query = useQuery<T>(queryKey, { ...params, staleTime: Infinity });
  const client = useQueryClient();

  // don't need exhaustive deps here as we're just clearing this on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => client.removeQueries(queryKey), []);

  return query;
}
