import cx from 'classnames';
import { format, isValid, parseISO } from 'date-fns';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';

import logoMark from '@/shared/assets/logoMark.png';
import AlertTriangle from '@/shared/assets/svgs/alertTriangle.svg?react';
import InboundCallIcon from '@/shared/assets/svgs/inbound-call.svg?react';
import OutboundCallIcon from '@/shared/assets/svgs/outbound-call.svg?react';
import SmsIcon from '@/shared/assets/svgs/sms.svg?react';
import { UserAvatar } from '@/shared/common/UserAvatar';
import { CallState } from '@/shared/generated/grpcGateway/call.pb';
import type { Call } from '@/shared/generated/grpcGateway/call.pb';
import { useRecordingStream } from '@/shared/hooks/queries/communications.queries';
import { Avatar } from '@/shared/tempo/atom/Avatar';

import type { Conference, Recipient, SMSMessage } from './Messages';
import {
  alertIcon,
  cadenceAvatar,
  callBodyContainer,
  callBodyContentContainer,
  callBodyHeaderContainer,
  callBodyIconDetails,
  callBodyIconText,
  container,
  ellipse,
  errorContext,
  exchangeDateText,
  logo,
  messageBody,
  senderContext,
  senderText,
  smsBodyContainer,
  smsBodyText,
  smsIcon,
} from './Messages.css';

type Props = {
  message: Nullable<SMSMessage>;
  conference: Nullable<Conference>;
  recipient: Recipient;
};

const ERROR_STATUS = ['undelivered', 'failed'];

export function ConversationExchange({
  message,
  conference,
  recipient,
}: Props) {
  const isInbound = message
    ? message.direction === 'inbound'
    : conference?.direction === 'INBOUND';

  const mainConferenceProvider = conference?.legs.filter(
    (confLeg) => !confLeg.isTransfer,
  )[0];

  return (
    <div
      className={cx({
        [container.patient]: isInbound,
        [container.system]: !isInbound,
      })}
    >
      {message && ERROR_STATUS.includes(message.status) && (
        <ErrorContext text={message?.error_message ?? ''} />
      )}
      <div
        className={cx({
          [messageBody.system]: !isInbound,
          [messageBody.patient]: isInbound,
          [messageBody.error]: message && ERROR_STATUS.includes(message.status),
        })}
      >
        {message && <MessageBody text={message.body} />}
        {conference && (
          <>
            <CallBody isInbound={isInbound} conference={conference} />
            {conference.recordingSid && (
              <AudioPlayer recordingSid={conference.recordingSid} />
            )}
          </>
        )}
      </div>
      <SenderContext
        isInbound={isInbound}
        provider={{
          careProviderFirstName:
            mainConferenceProvider?.careProviderFirstName ?? undefined,
          careProviderLastName:
            mainConferenceProvider?.careProviderLastName ?? undefined,
          careProviderRole:
            mainConferenceProvider?.careProviderRole ?? undefined,
        }}
        recipient={recipient}
        exchangeDate={
          conference ? conference.startTime ?? '' : message?.date_created ?? ''
        }
      />
    </div>
  );
}

type MessageBodyProps = {
  text: string;
};
function MessageBody({ text }: MessageBodyProps) {
  return (
    <div className={smsBodyContainer}>
      <div className={smsIcon}>
        <SmsIcon />
      </div>
      <div className={smsBodyText}>{text}</div>
    </div>
  );
}

type CallBodyProps = {
  isInbound: boolean;
  conference: Conference;
};
function CallBody({ isInbound, conference }: CallBodyProps) {
  const intl = useIntl();

  const { legs, patientDuration, state } = conference;

  let durationMsg = '';
  if (legs.length === 1) {
    durationMsg = getDurationMessage(legs[0].duration);
  } else {
    durationMsg = getDurationMessage(patientDuration);
  }

  return (
    <div className={callBodyContainer}>
      <div className={callBodyHeaderContainer}>
        <div>{isInbound ? <InboundCallIcon /> : <OutboundCallIcon />}</div>
        <div className={callBodyIconText}>
          {isInbound
            ? intl.formatMessage({ defaultMessage: 'Inbound Call' })
            : intl.formatMessage({ defaultMessage: 'Outbound Call' })}
        </div>
        {state === CallState.UNANSWERED && (
          <div className={callBodyIconDetails}>{`${intl.formatMessage({
            defaultMessage: 'Unanswered',
          })} ${durationMsg}`}</div>
        )}
        {state === CallState.INITIATED && (
          <div className={callBodyIconDetails}>{`${intl.formatMessage({
            defaultMessage: 'Initiated',
          })}`}</div>
        )}
        {state === CallState.COMPLETED && (
          <div className={callBodyIconDetails}>{durationMsg}</div>
        )}
        {state === CallState.CALLBACK_REQUESTED && (
          <div className={callBodyIconDetails}>{`${intl.formatMessage({
            defaultMessage: 'Callback Requested',
          })}`}</div>
        )}
      </div>
      {legs.length > 1 &&
        legs.map((leg) => (
          <div className={callBodyContentContainer}>
            <div className={ellipse} />
            <span className={senderText}>
              {leg.isTransfer &&
                intl.formatMessage(
                  {
                    defaultMessage:
                      'Transferred to {firstName} {lastName}, {recipientLabel}',
                  },
                  {
                    firstName: leg.careProviderFirstName,
                    lastName: leg.careProviderLastName,
                    recipientLabel: leg.careProviderRole,
                  },
                )}
              {!leg.isTransfer &&
                intl.formatMessage(
                  {
                    defaultMessage:
                      'Answered by {firstName} {lastName}, {recipientLabel}',
                  },
                  {
                    firstName: leg.careProviderFirstName,
                    lastName: leg.careProviderLastName,
                    recipientLabel: leg.careProviderRole,
                  },
                )}
            </span>
            <div className={callBodyIconDetails}>
              {getDurationMessage(leg.duration)}
            </div>
          </div>
        ))}
    </div>
  );
}

function getDurationMessage(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  let durationMsg = '';
  if (seconds !== 0) {
    durationMsg += `${seconds}s`;
  }
  if (minutes !== 0) {
    durationMsg = `${minutes} mins${seconds ? `, ${durationMsg}` : ''}`;
  }
  return durationMsg;
}

type SenderProvider = Pick<
  Call,
  'careProviderFirstName' | 'careProviderLastName' | 'careProviderRole'
>;

type SenderContextProps = {
  isInbound: boolean;
  recipient: Recipient;
  provider: SenderProvider;
  exchangeDate: string;
};

function SenderContext({
  isInbound,
  recipient,
  provider,
  exchangeDate,
}: SenderContextProps) {
  const intl = useIntl();

  if (provider.careProviderFirstName && provider.careProviderLastName) {
    return (
      <div className={senderContext}>
        <UserAvatar
          firstName={provider.careProviderFirstName}
          lastName={provider.careProviderLastName}
          size="small"
        />
        <span className={senderText}>{formatProviderName(intl, provider)}</span>
        <span className={exchangeDateText}>{formatDate(exchangeDate)}</span>
      </div>
    );
  }

  return (
    <div className={senderContext}>
      {isInbound && (
        <UserAvatar
          firstName={recipient?.firstName ?? ''}
          lastName={recipient?.lastName ?? ''}
          size="small"
        />
      )}
      {!isInbound && (
        <Avatar size="small" className={cadenceAvatar}>
          <img className={logo} alt="Cadence logo" src={logoMark} />
        </Avatar>
      )}
      <span className={senderText}>
        {isInbound &&
          intl.formatMessage(
            { defaultMessage: '{firstName} {lastName}, {recipientLabel}' },
            {
              firstName: recipient.firstName,
              lastName: recipient.lastName,
              recipientLabel: recipient.isCaregiver ? 'Caregiver' : 'Patient',
            },
          )}
        {!isInbound && <FormattedMessage defaultMessage="Cadence System" />}
      </span>
      <span className={exchangeDateText}>{formatDate(exchangeDate)}</span>
    </div>
  );
}

type ErrorContextProps = {
  text: string;
};

function ErrorContext({ text }: ErrorContextProps) {
  const intl = useIntl();

  return (
    <div className={errorContext}>
      <AlertTriangle className={alertIcon} />
      {intl.formatMessage(
        {
          defaultMessage: 'Failed to send message: {errorMessage}',
        },
        {
          errorMessage: text,
        },
      )}
    </div>
  );
}

export function AudioPlayer({ recordingSid }: { recordingSid: string }) {
  const { data: audioUrl, isLoading, error } = useRecordingStream(recordingSid);

  if (isLoading) return <div>Loading audio...</div>;
  if (error) return <div>Error loading audio</div>;

  return audioUrl ? (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls src={audioUrl} title={`${recordingSid}.mp3`} />
  ) : null;
}

function formatDate(date: Maybe<string>) {
  if (!date) {
    return null;
  }
  const parsedDate = parseISO(date);
  return isValid(parsedDate) ? format(parsedDate, 'MM/dd/yyyy h:mm a') : null;
}

function formatProviderName(
  intl: IntlShape,
  {
    careProviderFirstName,
    careProviderLastName,
    careProviderRole,
  }: SenderProvider,
) {
  if (careProviderRole) {
    return intl.formatMessage(
      {
        defaultMessage: '{firstName} {lastName}, {recipientLabel}',
      },
      {
        firstName: careProviderFirstName,
        lastName: careProviderLastName,
        recipientLabel: careProviderRole,
      },
    );
  }

  return intl.formatMessage(
    {
      defaultMessage: '{firstName} {lastName}',
    },
    {
      firstName: careProviderFirstName,
      lastName: careProviderLastName,
    },
  );
}
