import { FormattedMessage } from 'react-intl';

import { Block } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Block/Block';

import type { TActionStep } from '../SetActionSteps';
import { actionStepslist, goalReview } from './SharedReviewActionSteps.css';

type Props = {
  actionSteps: TActionStep[];
  patientGoalText: Nullable<string>;
};

export function GoalAndStepsBlock({ actionSteps, patientGoalText }: Props) {
  return (
    <Block className={goalReview}>
      <FormattedMessage
        defaultMessage={`To "{patientGoalText}" I will:`}
        values={{ patientGoalText }}
      />
      <ul className={actionStepslist}>
        {actionSteps.map(({ step, frequency, dependencies }, idx) => (
          <li key={`${step}-${idx}`}>
            <FormattedMessage
              defaultMessage="{step}, {frequency}{dependencies, select, NOT_FOUND {} other {, if {dependencies}}}"
              values={{
                step: step?.trim(),
                frequency: frequency?.trim(),
                dependencies: dependencies?.trim() || 'NOT_FOUND',
              }}
            />
          </li>
        ))}
      </ul>
    </Block>
  );
}
