import type { ToastOptions, ToastState } from '@react-stately/toast';
import type { ReactNode } from 'react';

export type Variant = 'success' | 'info' | 'error' | 'alert';

export type Config<T> = { content: T; title?: T };

export function isContentConfig<T>(
  content: T | Config<T>,
): content is Config<T> {
  return (content as Config<T>).content !== undefined;
}

type ToasterMethods<T> = {
  [v in Variant]: (
    content: T | Config<T>,
    options?: ToastOptions,
  ) => Maybe<string>;
};

export type ToastContents = {
  variant: Variant;
  content: ReactNode;
  title?: ReactNode;
};

export type Toaster = ToastState<ToastContents> & ToasterMethods<ReactNode>;
