import { ConditionProgram } from '@/shared/types/condition.types';

import { generalAssessmentAndPlanModule } from './assessmentAndPlanModule';
import { patientNotesModule } from './patientNotesModule';

export const t2d = {
  [ConditionProgram.TypeTwoDiabetes]: {
    ...patientNotesModule,
    ...generalAssessmentAndPlanModule,
  },
};
