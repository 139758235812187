import cx from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import { cloneElement } from 'react';

import { IconButton } from '@/shared/tempo/atom/IconButton';

import { adornment, container, input } from './InputAdorner.css';

type Props = {
  children: ReactElement;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
};

export function InputAdorner({
  children,
  leftAdornment,
  rightAdornment,
}: Props) {
  return (
    <div className={container}>
      {leftAdornment && (
        <i
          className={cx(adornment.left, {
            [adornment.static]:
              (leftAdornment as Maybe<ReactElement>)?.type !== IconButton,
          })}
        >
          {leftAdornment}
        </i>
      )}
      {cloneElement(children, {
        className: cx({
          [input.withLeftAdornment]: !!leftAdornment,
          [input.withRightAdornment]: !!rightAdornment,
        }),
      })}
      {rightAdornment && (
        <i
          className={cx(adornment.right, {
            [adornment.static]:
              (rightAdornment as Maybe<ReactElement>)?.type !== IconButton,
          })}
        >
          {rightAdornment}
        </i>
      )}
    </div>
  );
}
