import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuItem } from '@/deprecated/mui';
import ArrowLeftIcon from '@/shared/assets/svgs/arrow-left.svg?react';
import TrayIcon from '@/shared/assets/svgs/tray.svg?react';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { MenuTooltip } from '@/shared/common/Sidebar/MenuTooltip';
import {
  activeMenuItem,
  menuItem,
  sidebarIcon,
  sidebarIconContainer,
} from '@/shared/common/Sidebar/Sidebar.css';
import { SidebarTray } from '@/shared/common/SidebarTray';
import { useCareProvider } from '@/shared/hooks/queries';
import {
  useAssignedTasksInfinite,
  useTask,
} from '@/shared/hooks/queries/tasks.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { flexAlignItems, flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { TaskDetail } from '@/shared/tasking/TaskDetail';
import { ParentView } from '@/shared/tasking/TaskList';
import { TaskListContextProvider } from '@/shared/tasking/TaskList/taskListContext';
import { TaskState } from '@/shared/tasking/types';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';
import { grpcNameToId } from '@/shared/utils/grpc';

import { TabKey } from './TabKey';
import { ConfiguredTaskFilterContext } from './TaskFilterContext';
import { positionedNotificationBadge } from './TaskHub.css';
import { TaskHubBody } from './TaskHubBody';

const DEFAULT_TAB = TabKey.Open;

type Props = {
  onOpenChange: (isOpen: boolean) => void;
};

export function TaskHubTray({ onOpenChange }: Props) {
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null);
  const [openTab, setOpenTab] = useState<TabKey>(DEFAULT_TAB);
  const label = <FormattedMessage defaultMessage="Task Hub" />;
  const tasksQuery = useAssignedTasksInfinite({ state: TaskState.OPENED });
  const { currentUserId } = useCurrentUser();
  const { data: loggedInProvider } = useCareProvider(currentUserId);
  const taskCount = tasksQuery.data?.pages[0]?.totalSize ?? 0;
  const { data: activeTask } = useTask(activeTaskId ?? '', {
    enabled: !!activeTaskId,
  });

  if (!loggedInProvider) {
    return <LoadingPlaceholder isLoading />;
  }

  return (
    <ConfiguredTaskFilterContext>
      <SidebarTray
        content={({ state }) => (
          <>
            <SidebarTray.Header onClose={state.close}>
              {activeTask ? (
                <div
                  className={cx(
                    flexContainer.row,
                    flexAlignItems.center,
                    gap.M,
                  )}
                >
                  <IconButton
                    size="small"
                    variant="tertiary"
                    onPress={() => setActiveTaskId(null)}
                  >
                    <ArrowLeftIcon />
                  </IconButton>
                  <FormattedMessage defaultMessage="All tasks" />
                </div>
              ) : (
                <FormattedMessage defaultMessage="Task Hub" />
              )}
            </SidebarTray.Header>
            {activeTask ? (
              <TaskListContextProvider
                value={{
                  loggedInProvider,
                  parentView: ParentView.TaskHub,
                  taskHubOverlayState: null,
                }}
              >
                <TaskDetail task={activeTask} />
              </TaskListContextProvider>
            ) : (
              <TaskHubBody
                state={state}
                parentView={ParentView.TaskHub}
                onOpenTaskDetail={(task) =>
                  setActiveTaskId(grpcNameToId(task.name) ?? null)
                }
                selectedTab={openTab}
                onSelectedTabChange={setOpenTab}
              />
            )}
          </>
        )}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setActiveTaskId(null);
            setOpenTab(DEFAULT_TAB);
          }

          onOpenChange(isOpen);
        }}
      >
        {({ pressProps, state }) => (
          <MenuTooltip label={label}>
            <div className={cx({ [activeMenuItem]: state.isOpen })}>
              <MenuItem {...pressProps} className={menuItem}>
                <div className={sidebarIconContainer}>
                  <TrayIcon className={sidebarIcon} />
                  {taskCount > 0 && (
                    <NotificationBadge
                      count={taskCount}
                      className={positionedNotificationBadge}
                    />
                  )}
                </div>
              </MenuItem>
            </div>
          </MenuTooltip>
        )}
      </SidebarTray>
    </ConfiguredTaskFilterContext>
  );
}
