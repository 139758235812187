import type { HTMLAttributes } from 'react';

import { EHR } from '@/shared/types/ehr.types';

import { EHR_CONFIG_MAP } from './ehrConfig';

type Props = HTMLAttributes<SVGElement> & {
  ehr?: EHR;
};

export function EhrIcon({ ehr, ...rest }: Props) {
  if (!ehr || ehr === EHR.NON_INTEGRATED) {
    return null;
  }

  const IconComponent = EHR_CONFIG_MAP[ehr].icon;

  return <IconComponent {...rest} />;
}
