import IntlMessageFormat from 'intl-messageformat';
import type { IntlShape } from 'react-intl';

import { logger } from '@/logger';
import type {
  AllDryWeightTagType,
  AllWeightGainTagType,
} from '@/shared/types/tagsAndThreshold.types';
import {
  DryWeightTagType,
  WeightGainTagType,
} from '@/shared/types/tagsAndThreshold.types';
import { convertToLbs } from '@/shared/utils/unit-helpers';

import type {
  DryWeightFormatterProps,
  RapidGainFormatterProps,
  WeeklyGainFormatterProps,
} from '../../AlertDescription.types';
import {
  isDryWeightRelatedTags,
  isRapidWeightGainRelatedTags,
  isWeeklyWeightGainRelatedTags,
} from '../alertTagsUtil';
import { AlertFormatter } from './AlertFormatter';
import { isRapidWeightGainThreshold } from './alertDescriptionTypePredicates';

type WeightProps =
  | RapidGainFormatterProps
  | WeeklyGainFormatterProps
  | DryWeightFormatterProps;

export class WeightFormatter extends AlertFormatter<
  AllWeightGainTagType | AllDryWeightTagType,
  WeightProps
> {
  constructor(private intl: IntlShape) {
    super();
  }

  protected map: Map<AllWeightGainTagType | AllDryWeightTagType, string> =
    new Map<AllWeightGainTagType | AllDryWeightTagType, string>([
      [
        WeightGainTagType.RapidWeightGainP0,
        this.intl.formatMessage({ defaultMessage: 'Rapid Weight Gain' }),
      ],
      [
        WeightGainTagType.WeeklyWeightGainP0,
        this.intl.formatMessage({ defaultMessage: 'Weekly Weight Gain' }),
      ],
      [
        DryWeightTagType.DryWeightGainP0,
        this.intl.formatMessage({ defaultMessage: 'Dry Weight Gain' }),
      ],
      [
        DryWeightTagType.DryWeightLossP1,
        this.intl.formatMessage({ defaultMessage: 'Dry Weight Loss' }),
      ],
    ]);

  private dryWeightFormatter = new IntlMessageFormat(
    `{ delta, plural,
        =0 {lb}
        other {# lbs}
     } {operator, plural,
        =0 {over}
        =1 {under}
        other {# under}
     } dry weight ({currentReading, plural,
        =0 {lb}
        other {# lbs}
       }
       {operator, plural,
        =0 {>}
        =1 {<}
        other {# <}
      }
      {threshold, plural,
        =0 {lb}
        other {# lbs}
       })`,
    'en-US',
  );

  private rapidGainFormatter = new IntlMessageFormat(
    `>{threshold, plural,
        =0 {lb}
        other {# lbs}
     } on consecutive days ({initialReading, plural,
        =0 {lb}
        other {# lbs}
     } to {dayBeforeReading, plural,
        =0 {lb}
        other {# lbs}
     } to {currentReading, plural,
        =0 {lb}
        other {# lbs}
     })  `,
    'en-US',
  );

  private weeklyGainFormatter = new IntlMessageFormat(
    `>{threshold, plural,
        =0 {lb}
        other {# lbs}
     } over the last week ({initialReading, plural,
        =0 {lb}
        other {# lbs}
     } to {currentReading, plural,
        =0 {lb}
        other {# lbs}
     })  `,
    'en-US',
  );

  public getDescription(
    tag: AllWeightGainTagType | AllDryWeightTagType,
    props: WeightProps,
  ): string {
    if (isRapidWeightGainRelatedTags(tag)) {
      const { unit, values } = this.convertReadings(props);
      let { threshold } = values;
      if (isRapidWeightGainThreshold(threshold)) {
        threshold = threshold.one_day_change;
      } else {
        logger.error(
          'Rapid weight gain tag has unexpected threshold structure',
        );
      }

      return this.rapidGainFormatter.format({
        ...values,
        unit,
        threshold,
      }) as string;
    }

    if (isWeeklyWeightGainRelatedTags(tag)) {
      const { unit, values } = this.convertReadings(props);

      return this.weeklyGainFormatter.format({ unit, ...values }) as string;
    }

    if (isDryWeightRelatedTags(tag)) {
      const { operator, unit, values } = this.convertReadings(props);

      return this.dryWeightFormatter.format({
        operator,
        unit,
        ...values,
      }) as string;
    }

    return this.intl.formatMessage({ defaultMessage: 'Invalid Alert Type' });
  }

  protected unitConverter = convertToLbs;

  protected unit = 'lbs';
}
