import { useIntl } from 'react-intl';

import { useRejectionReasonI18n } from '@/components/AsyncTitration/hooks/useRejectionReasonI18n';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { useReferenceMedications } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';

import { TEMPLATE_HELPERS } from '../../../NoteEditor/templates/engine';
import { TypeOfEncounter } from '../../../Notes.types';
import { getAsyncReviewNoteBody } from './getAsyncReviewNoteBody';
import { getSortedRelevantMedChanges } from './getSortedRelevantMedChanges';
import { getTitrationOutreachNoteBody } from './getTitrationOutreachNoteBody';
import type { AsyncTitrationEncounterType } from './types';

export function shouldShowAsyncTitration(
  encounterType?: TypeOfEncounter,
): encounterType is AsyncTitrationEncounterType {
  if (!encounterType) {
    return false;
  }

  return [
    TypeOfEncounter.ASYNC_REVIEW,
    TypeOfEncounter.TITRATION_OUTREACH,
  ].includes(encounterType);
}

export function useGetAsyncTitrationNoteBody(
  patientId: string,
  noteId: Maybe<number>,
) {
  const intl = useIntl();
  const rejectionReasonsI18n = useRejectionReasonI18n();
  const { data: patientMeds } = usePatientMedications(patientId);
  const { data: patientVitals } = usePatientVitalsContext(patientId);
  const { data: referenceMeds } = useReferenceMedications();

  if (!patientMeds || !patientVitals || !referenceMeds || !noteId) {
    return () => null;
  }

  return (
    encounterType: AsyncTitrationEncounterType,
    isNoShow: boolean,
    medReviewBody: Nullable<string>,
  ) => {
    if (!medReviewBody) {
      return null;
    }

    const changesWithAsyncTitration = getSortedRelevantMedChanges(
      encounterType,
      noteId,
      patientMeds.referencedMedications,
      referenceMeds.referenceMedications,
    );

    if (!changesWithAsyncTitration.length) {
      return null;
    }

    const systolicAvg =
      patientVitals.contextVitals?.bloodPressure?.systolic?.avg30d;
    const diastolicAvg =
      patientVitals.contextVitals?.bloodPressure?.diastolic?.avg30d;
    const vitals = { bp: TEMPLATE_HELPERS.bp(systolicAvg, diastolicAvg) };

    if (encounterType === TypeOfEncounter.ASYNC_REVIEW) {
      return getAsyncReviewNoteBody(
        intl,
        changesWithAsyncTitration,
        vitals,
        medReviewBody,
        rejectionReasonsI18n,
      ).toString();
    }

    if (encounterType === TypeOfEncounter.TITRATION_OUTREACH) {
      return getTitrationOutreachNoteBody(
        intl,
        changesWithAsyncTitration,
        vitals,
        medReviewBody,
        isNoShow,
      ).toString();
    }

    return null;
  };
}
