export type AddressValues = {
  street_address: string;
  premise: string;
  postal_code: string;
  locality: string;
  region: string;
};

const ADDRESS_FIELDS: (keyof AddressValues)[] = [
  'street_address',
  'premise',
  'postal_code',
  'locality',
  'region',
];

export function getAddressDependentFields(forField: string) {
  return ADDRESS_FIELDS.filter((field) => field !== forField);
}

export function isAddressFieldRequired(
  field: keyof AddressValues,
  allAddressValues: AddressValues,
) {
  if (field !== 'premise' && allAddressValues[field]) {
    return true;
  }

  const dependentFields = getAddressDependentFields(field);

  for (let i = 0; i < dependentFields.length; i++) {
    if (allAddressValues[dependentFields[i]]) {
      return true;
    }
  }

  return false;
}
