import type { RefObject } from 'react';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import type { ListState, MenuTriggerState, SelectState } from 'react-stately';

import { Skeleton } from '@/shared/tempo/atom/Skeleton';

import { hiddenCount, subtleTag, subtleTagGroup } from './SelectionTagView.css';
import { SELECT_ALL_KEY } from './constants';
import { useHideOverflowTags } from './useHideOverflowTags';

type SelectedItemsProps<T> = {
  ariaLabel?: string;
  isLoading?: boolean;
  selectButtonRef: RefObject<HTMLButtonElement>;
  state: MenuTriggerState & SelectState<T> & ListState<T>;
};

export function SubtleSelectionTagView<T>({
  ariaLabel,
  state,
  isLoading,
  selectButtonRef,
}: SelectedItemsProps<T>) {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  // Hide the tags that overflow outside the div
  const { hiddenTagCount } = useHideOverflowTags(ref, selectButtonRef);

  return (
    <>
      <div
        ref={ref}
        className={subtleTagGroup}
        aria-label={
          ariaLabel
            ? intl.formatMessage(
                {
                  defaultMessage: 'Selected values for {ariaLabel}',
                },
                { ariaLabel },
              )
            : undefined
        }
      >
        {[...state.selectionManager.selectedKeys]
          .filter((key) => key !== SELECT_ALL_KEY)
          .map((key) => {
            const text = state.collection.getItem(key)?.rendered;
            return (
              <span key={key} className={subtleTag}>
                {isLoading ? <Skeleton variant="text" width={80} /> : text}
              </span>
            );
          })}
      </div>
      {hiddenTagCount > 0 && (
        <span className={hiddenCount}>+{hiddenTagCount}</span>
      )}
    </>
  );
}
