import type {
  FinishedServerStreamingCall,
  FinishedUnaryCall,
  ServerStreamingCall,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc';
import get from 'lodash/get';

import { logger } from '@/logger';

type Config = { retries?: number; interval?: number; exponential?: boolean };

// Compatible ServerStreamingCall type with UnaryCall
type CompatStreamingCall<I extends object, O extends object> = Omit<
  ServerStreamingCall<I, O>,
  'responses'
>;

export async function retry<
  I extends object = object,
  O extends object = object,
>(
  callFn: () => UnaryCall<I, O>,
  config?: Config,
): Promise<FinishedUnaryCall<I, O>>;

export async function retry<
  I extends object = object,
  O extends object = object,
>(
  callFn: () => CompatStreamingCall<I, O>,
  config?: Config,
): Promise<FinishedServerStreamingCall<I, O>>;

export async function retry<
  I extends object = object,
  O extends object = object,
>(
  callFn: () => CompatStreamingCall<I, O> | UnaryCall<I, O>,
  { retries = 10, interval = 250, exponential = false }: Config = {},
): Promise<FinishedServerStreamingCall<I, O> | FinishedUnaryCall<I, O> | null> {
  let call: CompatStreamingCall<I, O> | UnaryCall<I, O> | undefined;
  try {
    call = callFn();
    return await (call as unknown as PromiseLike<
      FinishedServerStreamingCall<I, O>
    >);
  } catch (error) {
    // User cancelled requests should not be retried
    if (call && get(error, ['code']) === 'CANCELLED') {
      return Promise.resolve(null);
    }
    const callName = call?.method.name;
    logger.warn(
      `gRPC web request '${callName}' failed and will retry in ${interval}ms...`,
    );
    await wait(interval);
    if (retries === 0) {
      throw new Error(
        `Maximum retries exceeded for gRPC web request: ${callName}`,
      );
    }
    return await retry(callFn, {
      retries: retries - 1,
      interval: !exponential ? interval * 1.5 : interval,
    });
  }
}

function wait(interval: number = 0) {
  return new Promise((resolve) => setTimeout(resolve, interval));
}
