import { ConditionFilterDropdown } from './ConditionFilterDropdown';
import { container } from './FiltersSection.css';
import { MarketFilterDropdown } from './MarketFilterDropdown';
import { PatientViewSegmentedButton } from './PatientViewSegmentedButton';

type Props = {
  isOpen: boolean;
};

export function FiltersSection({ isOpen }: Props) {
  if (!isOpen) {
    return null;
  }
  return (
    <div className={container}>
      <PatientViewSegmentedButton />
      <MarketFilterDropdown />
      <ConditionFilterDropdown />
    </div>
  );
}
