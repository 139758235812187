import type { IntlShape } from 'react-intl';

import { CHF_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type { EducationModulesReviewFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EducationModulesReview';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { cnActionPlan } from '../../shared/cnActionPlan';

export function chfVisitActionPlan(data: SectionStepsState, intl: IntlShape) {
  const educationModuleData = getWizardStepValues(
    data,
    CHF_VISIT_SECTIONS,
    '/action-plan',
    '/education-modules-review',
  )<EducationModulesReviewFormFields>();

  return cnActionPlan({
    intl,
    visitType: TypeOfEncounter.CN_CHF_VISIT,
    educationModuleData,

    // we do not capture these for CHF patients
    patientGoalData: null,
    actionStepsData: null,
    goalProgressData: null,
    goalDiscussionData: null,
  });
}
