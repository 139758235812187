import { ProviderTeamRole } from '@/shared/types/provider.types';

import { useCareProvider } from './queries';
import { useCurrentUser } from './useCurrentUser';

export function useIsCnExperience() {
  const { currentUserId: providerId } = useCurrentUser();

  const { data: careProvider, isLoading } = useCareProvider(providerId, {
    staleTime: Infinity,
  });

  return {
    isLoading,
    isCnExperience: careProvider?.team_role === ProviderTeamRole.CN,
  };
}
