import cx from 'classnames';
import type { ReactNode } from 'react';

import { container } from './Block.css';

type Props = {
  className?: string;
  children: ReactNode;
};

export function Block({ children, className }: Props) {
  return <div className={cx(container, className)}>{children}</div>;
}
