import type { IntlShape } from 'react-intl';

import {
  BloodPressureValueType,
  ReadingDataType,
} from '@/shared/types/patientSummary.types';
import type { AllBloodPressureTagType } from '@/shared/types/tagsAndThreshold.types';
import { BloodPressureTagType } from '@/shared/types/tagsAndThreshold.types';
import type { BloodPressureVital } from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';

import type {
  AlertLoggerInfo,
  BgFormatterProps,
} from '../../AlertDescription.types';
import { BloodPressureAlertFormatter } from '../alertDescriptionFormatters';
import type {
  AlertDescriptionStrategy,
  TagRelatedValuesRequest,
} from '../baseAlertDescriptionStrategy';
import { BaseAlertDescriptionStrategy } from '../baseAlertDescriptionStrategy';
import { getComparisonOperator } from '../sharedAlertsUtils';

export class BloodPressureAlertStrategy
  extends BaseAlertDescriptionStrategy<
    AllBloodPressureTagType,
    BloodPressureVital
  >
  implements AlertDescriptionStrategy
{
  constructor(intl: IntlShape) {
    super(
      VitalType.BloodPressure,
      Object.values(BloodPressureTagType),
      new BloodPressureAlertFormatter(intl),
      ReadingDataType.BloodPressure,
    );
  }

  protected getTagRelatedValues(
    request: TagRelatedValuesRequest<
      AllBloodPressureTagType,
      BloodPressureVital
    >,
    _: BloodPressureVital[],
    alertLoggerInfo: AlertLoggerInfo,
  ): BgFormatterProps {
    const currentReading = this.getReadingValue(
      request.tag,
      request.relatedReading,
    );
    const threshold = this.getThresholdValue(
      request.tag,
      request.relatedReading,
      alertLoggerInfo,
    );

    const operator = getComparisonOperator(
      currentReading,
      threshold,
      true,
      true,
    );

    return {
      operator,
      values: {
        currentReading,
        threshold,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private isSystolicRelatedTags(tag: AllBloodPressureTagType) {
    const systolicRelatedTags = Object.values(BloodPressureTagType).filter(
      (t) => t.includes('SYSTOLIC_'),
    );
    return [...systolicRelatedTags].includes(tag);
  }

  // eslint-disable-next-line class-methods-use-this
  private isDiastolicRelatedTags(tag: AllBloodPressureTagType) {
    const diastolicRelatedTags = Object.values(BloodPressureTagType).filter(
      (t) => t.includes('DIASTOLIC_'),
    );
    return [...diastolicRelatedTags].includes(tag);
  }

  private getReadingValue(
    tag: AllBloodPressureTagType,
    reading: BloodPressureVital,
  ): number {
    if (this.isDiastolicRelatedTags(tag)) {
      return reading?.[BloodPressureValueType.Diastolic];
    }

    if (this.isSystolicRelatedTags(tag)) {
      return reading?.[BloodPressureValueType.Systolic];
    }

    return (
      reading?.[BloodPressureValueType.Diastolic] * (2 / 3) +
      reading?.[BloodPressureValueType.Systolic] * (1 / 3)
    );
  }
}
