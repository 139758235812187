/* eslint-disable react-refresh/only-export-components */
import { FormattedMessage } from 'react-intl';

import { PatientGoal } from '../PatientGoal';
import type { EducationModule, LinkType } from './types';
import { EducationModuleSection, UnderstandDiseaseConditions } from './types';

const HowMuchExerciseAndWhatKind = {
  title: (
    <FormattedMessage defaultMessage="Exercise: How Much and What Kind?" />
  ),
  section: EducationModuleSection.HowMuchExerciseAndWhatKind,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.khblimv4jg11',
};

const WhyPhysicalActivityIsSoImportant = {
  title: (
    <FormattedMessage defaultMessage="Why Physical Activity is So Important" />
  ),
  section: EducationModuleSection.WhyPhysicalActivityIsSoImportant,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.92yokotk7j83',
};

const GettingStartedTipsForLongTermExerciseSuccess = {
  title: (
    <FormattedMessage defaultMessage="Getting Started - Tips for Long-term Exercise Success" />
  ),
  section: EducationModuleSection.GettingStartedTipsForLongTermExerciseSuccess,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.itw8j1ugx6oc',
};

const StayingMotivatedToStayingActive = {
  title: <FormattedMessage defaultMessage="Staying Motivated to Stay Active" />,
  section: EducationModuleSection.StayingMotivatedToStayingActive,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.zd2ok86plr2v',
};

const OverviewOfDietTypes = {
  title: <FormattedMessage defaultMessage="Overview of Diet Types" />,
  section: EducationModuleSection.OverviewOfDietTypes,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.a9s1f2804ihu',
};

const TheHealthyDietIngredientsAndTips = {
  title: (
    <FormattedMessage defaultMessage="The Healthy Diet - Ingredients and Tips" />
  ),
  section: EducationModuleSection.TheHealthyDietIngredientsAndTips,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.fyjavvxqy974',
};

const ReadingLabelsAndUnderstandingPortionSize = {
  title: (
    <FormattedMessage defaultMessage="Reading labels and Understanding Portion Size" />
  ),
  section: EducationModuleSection.ReadingLabelsAndUnderstandingPortionSize,
  href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.6nlxl0mw3ffn',
};

export const EDUCATION_MODULES: Record<EducationModule, LinkType[]> = {
  [PatientGoal.BecomePhysicallyActive]: [
    {
      title: (
        <FormattedMessage defaultMessage="Become More Physically Active" />
      ),
      section: EducationModuleSection.BecomeMorePhysicallyActive,
      href: 'https://docs.google.com/document/d/1Ed2D75oTJRfT6oIuIc9d_l1Qoj3X2CgVnJwrUhaLNis/edit',
    },
    WhyPhysicalActivityIsSoImportant,
    HowMuchExerciseAndWhatKind,
    GettingStartedTipsForLongTermExerciseSuccess,
    StayingMotivatedToStayingActive,
    {
      title: <FormattedMessage defaultMessage="Exercise and Chronic Disease" />,
      section: EducationModuleSection.ExerciseAndChronicDisease,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.g15exummf0pk',
    },
    {
      title: <FormattedMessage defaultMessage="Exercise and Arthritis" />,
      section: EducationModuleSection.ExerciseAndArthritis,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.71obwha3ygsc',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Staying Active in Cold Weather" />
      ),
      section: EducationModuleSection.StayingActiveInColdWeather,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.8nffykss2eiz',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Staying Active in Warm Weather" />
      ),
      section: EducationModuleSection.StayingActiveInWarmWeather,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.i0yw96woj0fq',
    },
  ],
  [PatientGoal.TakeMedicationsConsistently]: [
    {
      title: (
        <FormattedMessage defaultMessage="Take My Medications More Consistently" />
      ),
      section: EducationModuleSection.TakeMyMedicationsMoreConsistently,
      href: 'https://docs.google.com/document/d/1jv3iYcfp2-Gyt_p1FnQDowrg2bxGRl_s_If2NCOX4l8/edit',
    },
    {
      title: (
        <FormattedMessage defaultMessage="The Importance of Medication Adherence" />
      ),
      section: EducationModuleSection.TheImportanceOfMedicationAdherence,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.31kj61shxfje',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Reasons for Not Taking Medications, and What to Do about It" />
      ),
      section: EducationModuleSection.ReasonsForNotTakingMedications,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.deegsi2dw93i',
    },
    {
      title: (
        <FormattedMessage defaultMessage="General Tips to Improve Medication Adherence" />
      ),
      section: EducationModuleSection.GeneralTipsToImproveMedicationAdherence,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.joknqjz172g5',
    },
  ],
  [PatientGoal.LowerMyWeight]: [
    {
      title: (
        <FormattedMessage defaultMessage="Principles of Healthy Weight Loss for the Long Term" />
      ),
      section: EducationModuleSection.PrinciplesOfHealthyWeightLossForLongTerm,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.pdy04a6o2cw2',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Change Management for Weight Loss" />
      ),
      section: EducationModuleSection.ChangeManagementForWeightLoss,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.okbi2t3zkssx',
    },
    OverviewOfDietTypes,
    TheHealthyDietIngredientsAndTips,
    ReadingLabelsAndUnderstandingPortionSize,
    WhyPhysicalActivityIsSoImportant,
    HowMuchExerciseAndWhatKind,
    GettingStartedTipsForLongTermExerciseSuccess,
    StayingMotivatedToStayingActive,
  ],
  [PatientGoal.ImproveMyFoodChoices]: [
    {
      title: <FormattedMessage defaultMessage="Improve My Food Choices" />,
      section: EducationModuleSection.ImproveMyDiet,
      href: 'https://docs.google.com/document/d/1DklG6e5xEcMk8nl78T8c3LZ4gd_iKUQsL3-isXsSWIg/edit',
    },
    OverviewOfDietTypes,
    TheHealthyDietIngredientsAndTips,
    ReadingLabelsAndUnderstandingPortionSize,
    {
      title: (
        <FormattedMessage defaultMessage="Controlling Salt in Your Diet" />
      ),
      section: EducationModuleSection.ControllingSaltInYourDiet,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.cw6jsoyqeyxd',
    },
    {
      title: <FormattedMessage defaultMessage="Grains and Fiber" />,
      section: EducationModuleSection.GrainsAndFiber,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.589z3lalbtc1',
    },
    {
      title: <FormattedMessage defaultMessage="The Mediterranean Diet" />,
      section: EducationModuleSection.TheMediterraneanDiet,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.l7odkdjp869y',
    },
  ],
  [PatientGoal.ReduceMyStress]: [
    {
      title: <FormattedMessage defaultMessage="Reduce My Stress" />,
      section: EducationModuleSection.ReduceMyStress,
      href: 'https://docs.google.com/document/d/1FGwfrkKwZoFsy3Fg5yMwc3AqtZeAKZp7vPXCLJEzFrY/edit',
    },
    {
      title: <FormattedMessage defaultMessage="Understanding Chronic Stress" />,
      section: EducationModuleSection.UnderstandingChronicStress,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.mbt15l4lh7k9',
    },
    {
      title: <FormattedMessage defaultMessage="Basic Stress Management Tips" />,
      section: EducationModuleSection.BasicStressManagementTips,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.wblb87vxqgi',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Relaxation through Mindfulness" />
      ),
      section: EducationModuleSection.RelaxationThroughMindfulness,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.jbfzmjme6hn5',
    },
  ],
  [UnderstandDiseaseConditions.T2d]: [
    {
      title: (
        <FormattedMessage defaultMessage="General Introduction to Diabetes: Type 2" />
      ),
      section: EducationModuleSection.GeneralIntroductionToDiabetesType2,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.npibewurpe6s',
    },
    {
      title: (
        <FormattedMessage defaultMessage="General Guide to Checking Blood Sugar Levels" />
      ),
      section: EducationModuleSection.GeneralGuideToCheckingBloodSugarLevels,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.ao5047yykqeu',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Managing Type 2 Diabetes Through Diet" />
      ),
      section: EducationModuleSection.ManagingType2DiabetesThroughDiet,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.h97q3hadbrod',
    },
    {
      title: <FormattedMessage defaultMessage="No More Carb Confusion" />,
      section: EducationModuleSection.NoMoreCarbConfusion,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.vzoksht2wfes',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Avoiding High-Low Blood Glucose Levels" />
      ),
      section: EducationModuleSection.AvoidingHighLowBloodGlucoseLevels,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.erys2luc48vv',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Diabetes and Exercise, Part 1" />
      ),
      section: EducationModuleSection.DiabetesAndExercisePart1,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.2mye8tg7saiz',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Diabetes and Exercise, Part 2" />
      ),
      section: EducationModuleSection.DiabetesAndExercisePart2,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.z85s9op3rs4f',
    },
  ],
  [UnderstandDiseaseConditions.Htn]: [
    {
      title: <FormattedMessage defaultMessage="What is High Blood Pressure?" />,
      section: EducationModuleSection.WhatIsHighBloodPressure,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.d6qyfi1o3eay',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Managing Your Blood Pressure: Overview Part 1" />
      ),
      section: EducationModuleSection.ManagingYourBloodPressureOverviewPart1,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.tbf0sfcly3v',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Managing Your Blood Pressure: Overview Part 2" />
      ),
      section: EducationModuleSection.ManagingYourBloodPressureOverviewPart2,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.5zr2voy2kcdv',
    },
  ],
  [UnderstandDiseaseConditions.Chf]: [
    {
      title: <FormattedMessage defaultMessage="What is Heart Failure?" />,
      section: EducationModuleSection.WhatIsHeartFailure,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.dy3xtaogsmux',
    },
    {
      deprecated: true,
      title: (
        <FormattedMessage defaultMessage="First, Let's Define Heart Failure" />
      ),
      section: EducationModuleSection.FirstLetsDefineHeartFailure,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.wdcwermwur5z',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Monitoring Symptoms of Heart Failure" />
      ),
      section: EducationModuleSection.MonitoringSymptomsOfHeartFailure,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.3anmtxkrtqtd',
    },
    {
      title: <FormattedMessage defaultMessage="Living with Heart Failure" />,
      section: EducationModuleSection.LivingWithHeartFailure,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.e6dz7qo5d5np',
    },
    {
      title: (
        <FormattedMessage defaultMessage="Exercise and Activity with Heart Failure" />
      ),
      section: EducationModuleSection.ExerciseAndActivityWithHeartFailure,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.1581d54oqsuj',
    },
  ],
  [PatientGoal.GetBetterSleep]: [
    {
      title: <FormattedMessage defaultMessage="What is Good Sleep" />,
      section: EducationModuleSection.WhatIsGoodSleep,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.3ota7frqtdz1',
    },
    {
      title: <FormattedMessage defaultMessage="Tips for Better Sleep" />,
      section: EducationModuleSection.TipsForBetterSleep,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.ed8mh1kzf13e',
    },
    {
      title: <FormattedMessage defaultMessage="Aging and Sleep, Part 1" />,
      section: EducationModuleSection.AgingAndSleepPart1,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.1skm0z76n2z9',
    },
    {
      title: <FormattedMessage defaultMessage="Aging and Sleep, Part 2" />,
      section: EducationModuleSection.AgingAndSleepPart2,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.8yonqprtm0v9',
    },
  ],
  [PatientGoal.QuitSmoking]: [
    {
      title: <FormattedMessage defaultMessage="Smoking Cessation Overview" />,
      section: EducationModuleSection.SmokingCessationOverview,
      href: 'https://docs.google.com/document/d/17qpj90AwwUr6sPZ3J1GOz5tG7nY7o_JyPHMkUdtKjZQ/edit#heading=h.22gxhpf8p2d8',
    },
  ],
};
