import { useMemo } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

// factory function intended to be used with k:v mappings of some known value
// (e.g. an enum) to the "friendly" text to display. for example:
//
// {
//   [PatientStatus.NotEligible]: intl.formatMessage({
//     defaultMessage: 'Not Eligible',
//   }),
//   ...etc
// }
//
// this factory allows us to remove a lot of boilerplate around actually
// consuming one of these functions.
export function makeI18nHook<T>(mappingFn: (intl: IntlShape) => T) {
  return function useI18nMap(): T {
    const intl = useIntl();
    const map = useMemo(() => mappingFn(intl), [intl]);

    return map;
  };
}
