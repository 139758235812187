/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter long_type_string,add_pb_suffix,eslint_disable
// @generated from protobuf file "go/pms/pkg/task/task.proto" (package "go.pms.pkg.task", syntax proto3)
// tslint:disable
import { Empty } from "./google/protobuf/empty_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FieldMask } from "./google/protobuf/field_mask_pb";
import { StringValue } from "./google/protobuf/wrappers_pb";
import { Timestamp } from "./google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message go.pms.pkg.task.Task
 */
export interface Task {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string uid = 2;
     */
    uid: string;
    /**
     * @generated from protobuf field: go.pms.pkg.task.Task.TaskType type = 3;
     */
    type: Task_TaskType;
    /**
     * @generated from protobuf field: go.pms.pkg.task.CareProvider assignee = 4;
     */
    assignee?: CareProvider;
    /**
     * @generated from protobuf field: int32 priority = 5;
     */
    priority: number;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp create_time = 7;
     */
    createTime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp update_time = 8;
     */
    updateTime?: Timestamp;
    /**
     * @generated from protobuf field: go.pms.pkg.task.Task.State state = 9;
     */
    state: Task_State;
    /**
     * @generated from protobuf field: repeated go.pms.pkg.task.Comment comments = 10;
     */
    comments: Comment[];
    /**
     * @generated from protobuf field: google.protobuf.StringValue patient_id = 11;
     */
    patientId?: StringValue;
    /**
     * @generated from protobuf field: go.pms.pkg.task.CareProvider creator = 12;
     */
    creator?: CareProvider;
    /**
     * @generated from protobuf field: string etag = 13;
     */
    etag: string;
}
/**
 * @generated from protobuf enum go.pms.pkg.task.Task.TaskType
 */
export enum Task_TaskType {
    /**
     * @generated from protobuf enum value: TASK_TYPE_UNSPECIFIED = 0;
     */
    TASK_TYPE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: ESCALATION = 1;
     */
    ESCALATION = 1,
    /**
     * @generated from protobuf enum value: INDIRECT_REVIEW = 2;
     */
    INDIRECT_REVIEW = 2,
    /**
     * @generated from protobuf enum value: OTHER = 3;
     */
    OTHER = 3,
    /**
     * @generated from protobuf enum value: REMINDER = 4;
     */
    REMINDER = 4
}
/**
 * @generated from protobuf enum go.pms.pkg.task.Task.State
 */
export enum Task_State {
    /**
     * @generated from protobuf enum value: STATE_UNSPECIFIED = 0;
     */
    STATE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: OPENED = 1;
     */
    OPENED = 1,
    /**
     * @generated from protobuf enum value: CLOSED = 2;
     */
    CLOSED = 2
}
/**
 * @generated from protobuf message go.pms.pkg.task.Comment
 */
export interface Comment {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string uid = 2;
     */
    uid: string;
    /**
     * @generated from protobuf field: string body = 3;
     */
    body: string;
    /**
     * @generated from protobuf field: go.pms.pkg.task.CareProvider author = 4;
     */
    author?: CareProvider;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp create_time = 5;
     */
    createTime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp update_time = 6;
     */
    updateTime?: Timestamp;
    /**
     * @generated from protobuf field: string task_id = 7;
     */
    taskId: string;
    /**
     * @generated from protobuf field: string etag = 8;
     */
    etag: string;
}
/**
 * @generated from protobuf message go.pms.pkg.task.CareProvider
 */
export interface CareProvider {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string uid = 2;
     */
    uid: string;
    /**
     * @generated from protobuf field: string given_name = 3;
     */
    givenName: string;
    /**
     * @generated from protobuf field: string family_name = 4;
     */
    familyName: string;
    /**
     * @generated from protobuf field: string role = 5;
     */
    role: string;
}
/**
 * @generated from protobuf message go.pms.pkg.task.ListTasksRequest
 */
export interface ListTasksRequest {
    /**
     * @generated from protobuf field: string filter = 1;
     */
    filter: string;
    /**
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask read_mask = 4;
     */
    readMask?: FieldMask;
    /**
     * @generated from protobuf field: string order_by = 5;
     */
    orderBy: string;
    /**
     * @generated from protobuf field: string follower = 6;
     */
    follower: string;
}
/**
 * @generated from protobuf message go.pms.pkg.task.ListTasksResponse
 */
export interface ListTasksResponse {
    /**
     * @generated from protobuf field: repeated go.pms.pkg.task.Task tasks = 1;
     */
    tasks: Task[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
    /**
     * @generated from protobuf field: int32 total_size = 3;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message go.pms.pkg.task.CreateTaskRequest
 */
export interface CreateTaskRequest {
    /**
     * @generated from protobuf field: go.pms.pkg.task.Task task = 1;
     */
    task?: Task;
}
/**
 * @generated from protobuf message go.pms.pkg.task.UpdateTaskRequest
 */
export interface UpdateTaskRequest {
    /**
     * @generated from protobuf field: go.pms.pkg.task.Task task = 1;
     */
    task?: Task;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
     */
    updateMask?: FieldMask;
}
/**
 * @generated from protobuf message go.pms.pkg.task.GetTaskRequest
 */
export interface GetTaskRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask read_mask = 2;
     */
    readMask?: FieldMask;
}
/**
 * @generated from protobuf message go.pms.pkg.task.OpenTaskRequest
 */
export interface OpenTaskRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string etag = 2;
     */
    etag: string;
}
/**
 * @generated from protobuf message go.pms.pkg.task.CloseTaskRequest
 */
export interface CloseTaskRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string etag = 2;
     */
    etag: string;
}
/**
 * @generated from protobuf message go.pms.pkg.task.DeleteTaskRequest
 */
export interface DeleteTaskRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string etag = 2;
     */
    etag: string;
}
/**
 * @generated from protobuf message go.pms.pkg.task.ListCommentsRequest
 */
export interface ListCommentsRequest {
    /**
     * @generated from protobuf field: string parent = 1;
     */
    parent: string;
    /**
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask read_mask = 4;
     */
    readMask?: FieldMask;
}
/**
 * @generated from protobuf message go.pms.pkg.task.ListCommentsResponse
 */
export interface ListCommentsResponse {
    /**
     * @generated from protobuf field: repeated go.pms.pkg.task.Comment comments = 1;
     */
    comments: Comment[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
    /**
     * @generated from protobuf field: int32 total_size = 3;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message go.pms.pkg.task.CreateCommentRequest
 */
export interface CreateCommentRequest {
    /**
     * @generated from protobuf field: string parent = 1;
     */
    parent: string;
    /**
     * @generated from protobuf field: go.pms.pkg.task.Comment comment = 2;
     */
    comment?: Comment;
}
/**
 * @generated from protobuf message go.pms.pkg.task.UpdateCommentRequest
 */
export interface UpdateCommentRequest {
    /**
     * @generated from protobuf field: go.pms.pkg.task.Comment comment = 1;
     */
    comment?: Comment;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
     */
    updateMask?: FieldMask;
}
/**
 * @generated from protobuf message go.pms.pkg.task.GetCommentRequest
 */
export interface GetCommentRequest {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.FieldMask read_mask = 2;
     */
    readMask?: FieldMask;
}
/**
 * @generated from protobuf message go.pms.pkg.task.TaskChangeNotificationRequest
 */
export interface TaskChangeNotificationRequest {
}
/**
 * @generated from protobuf message go.pms.pkg.task.TaskChangeNotificationResponse
 */
export interface TaskChangeNotificationResponse {
    /**
     * @generated from protobuf field: go.pms.pkg.task.Task task = 1;
     */
    task?: Task;
    /**
     * @generated from protobuf field: go.pms.pkg.task.TaskChangeNotificationResponse.ChangeType type = 2;
     */
    type: TaskChangeNotificationResponse_ChangeType;
    /**
     * @generated from protobuf field: go.pms.pkg.task.Task pre_change_task = 3;
     */
    preChangeTask?: Task;
}
/**
 * @generated from protobuf enum go.pms.pkg.task.TaskChangeNotificationResponse.ChangeType
 */
export enum TaskChangeNotificationResponse_ChangeType {
    /**
     * @generated from protobuf enum value: CHANGE_TYPE_UNSPECIFIED = 0;
     */
    CHANGE_TYPE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: CREATE = 1;
     */
    CREATE = 1,
    /**
     * @generated from protobuf enum value: UPDATE = 2;
     */
    UPDATE = 2,
    /**
     * @generated from protobuf enum value: DELETE = 3;
     */
    DELETE = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Task$Type extends MessageType<Task> {
    constructor() {
        super("go.pms.pkg.task.Task", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IMMUTABLE"] } },
            { no: 2, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "type", kind: "enum", T: () => ["go.pms.pkg.task.Task.TaskType", Task_TaskType], options: { "cadence.aip.field": { filter: true }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 4, name: "assignee", kind: "message", T: () => CareProvider, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 5, name: "priority", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "cadence.aip.field": { filter: true }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 7, name: "create_time", kind: "message", T: () => Timestamp, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 8, name: "update_time", kind: "message", T: () => Timestamp, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 9, name: "state", kind: "enum", T: () => ["go.pms.pkg.task.Task.State", Task_State], options: { "cadence.aip.field": { filter: true }, "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 10, name: "comments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Comment, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 11, name: "patient_id", kind: "message", T: () => StringValue, options: { "cadence.aip.field": { filter: true }, "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 12, name: "creator", kind: "message", T: () => CareProvider, options: { "google.api.field_behavior": ["IMMUTABLE"] } },
            { no: 13, name: "etag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "cadence.aip.fields": [{ name: "creator.name", filter: "STRING", filterMapping: "creator_id" }, { name: "assignee.name", filter: "STRING", filterMapping: "assignee_id" }], "google.api.resource": { type: "cadencerpm.com/Task", pattern: ["task/{name}"] } });
    }
    create(value?: PartialMessage<Task>): Task {
        const message = { name: "", uid: "", type: 0, priority: 0, description: "", state: 0, comments: [], etag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Task>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Task): Task {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string uid */ 2:
                    message.uid = reader.string();
                    break;
                case /* go.pms.pkg.task.Task.TaskType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* go.pms.pkg.task.CareProvider assignee */ 4:
                    message.assignee = CareProvider.internalBinaryRead(reader, reader.uint32(), options, message.assignee);
                    break;
                case /* int32 priority */ 5:
                    message.priority = reader.int32();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* google.protobuf.Timestamp create_time */ 7:
                    message.createTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createTime);
                    break;
                case /* google.protobuf.Timestamp update_time */ 8:
                    message.updateTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updateTime);
                    break;
                case /* go.pms.pkg.task.Task.State state */ 9:
                    message.state = reader.int32();
                    break;
                case /* repeated go.pms.pkg.task.Comment comments */ 10:
                    message.comments.push(Comment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.StringValue patient_id */ 11:
                    message.patientId = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.patientId);
                    break;
                case /* go.pms.pkg.task.CareProvider creator */ 12:
                    message.creator = CareProvider.internalBinaryRead(reader, reader.uint32(), options, message.creator);
                    break;
                case /* string etag */ 13:
                    message.etag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Task, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string uid = 2; */
        if (message.uid !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uid);
        /* go.pms.pkg.task.Task.TaskType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* go.pms.pkg.task.CareProvider assignee = 4; */
        if (message.assignee)
            CareProvider.internalBinaryWrite(message.assignee, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 priority = 5; */
        if (message.priority !== 0)
            writer.tag(5, WireType.Varint).int32(message.priority);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* google.protobuf.Timestamp create_time = 7; */
        if (message.createTime)
            Timestamp.internalBinaryWrite(message.createTime, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp update_time = 8; */
        if (message.updateTime)
            Timestamp.internalBinaryWrite(message.updateTime, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* go.pms.pkg.task.Task.State state = 9; */
        if (message.state !== 0)
            writer.tag(9, WireType.Varint).int32(message.state);
        /* repeated go.pms.pkg.task.Comment comments = 10; */
        for (let i = 0; i < message.comments.length; i++)
            Comment.internalBinaryWrite(message.comments[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue patient_id = 11; */
        if (message.patientId)
            StringValue.internalBinaryWrite(message.patientId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* go.pms.pkg.task.CareProvider creator = 12; */
        if (message.creator)
            CareProvider.internalBinaryWrite(message.creator, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string etag = 13; */
        if (message.etag !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.etag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.Task
 */
export const Task = new Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Comment$Type extends MessageType<Comment> {
    constructor() {
        super("go.pms.pkg.task.Comment", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IMMUTABLE"] } },
            { no: 2, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 4, name: "author", kind: "message", T: () => CareProvider, options: { "google.api.field_behavior": ["IMMUTABLE"] } },
            { no: 5, name: "create_time", kind: "message", T: () => Timestamp, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 6, name: "update_time", kind: "message", T: () => Timestamp, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 7, name: "task_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 8, name: "etag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "google.api.resource": { type: "cadencerpm.com/Comment", pattern: ["task/{task}/comment/{name}"] } });
    }
    create(value?: PartialMessage<Comment>): Comment {
        const message = { name: "", uid: "", body: "", taskId: "", etag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Comment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Comment): Comment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string uid */ 2:
                    message.uid = reader.string();
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                case /* go.pms.pkg.task.CareProvider author */ 4:
                    message.author = CareProvider.internalBinaryRead(reader, reader.uint32(), options, message.author);
                    break;
                case /* google.protobuf.Timestamp create_time */ 5:
                    message.createTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createTime);
                    break;
                case /* google.protobuf.Timestamp update_time */ 6:
                    message.updateTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updateTime);
                    break;
                case /* string task_id */ 7:
                    message.taskId = reader.string();
                    break;
                case /* string etag */ 8:
                    message.etag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Comment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string uid = 2; */
        if (message.uid !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uid);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        /* go.pms.pkg.task.CareProvider author = 4; */
        if (message.author)
            CareProvider.internalBinaryWrite(message.author, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp create_time = 5; */
        if (message.createTime)
            Timestamp.internalBinaryWrite(message.createTime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp update_time = 6; */
        if (message.updateTime)
            Timestamp.internalBinaryWrite(message.updateTime, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string task_id = 7; */
        if (message.taskId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.taskId);
        /* string etag = 8; */
        if (message.etag !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.etag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.Comment
 */
export const Comment = new Comment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CareProvider$Type extends MessageType<CareProvider> {
    constructor() {
        super("go.pms.pkg.task.CareProvider", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IMMUTABLE"] } },
            { no: 2, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 3, name: "given_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 4, name: "family_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } },
            { no: 5, name: "role", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OUTPUT_ONLY"] } }
        ], { "google.api.resource": { type: "cadencerpm.com/CareProvider", pattern: ["careProvider/{name}"] } });
    }
    create(value?: PartialMessage<CareProvider>): CareProvider {
        const message = { name: "", uid: "", givenName: "", familyName: "", role: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CareProvider>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CareProvider): CareProvider {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string uid */ 2:
                    message.uid = reader.string();
                    break;
                case /* string given_name */ 3:
                    message.givenName = reader.string();
                    break;
                case /* string family_name */ 4:
                    message.familyName = reader.string();
                    break;
                case /* string role */ 5:
                    message.role = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CareProvider, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string uid = 2; */
        if (message.uid !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uid);
        /* string given_name = 3; */
        if (message.givenName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.givenName);
        /* string family_name = 4; */
        if (message.familyName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.familyName);
        /* string role = 5; */
        if (message.role !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.role);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.CareProvider
 */
export const CareProvider = new CareProvider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTasksRequest$Type extends MessageType<ListTasksRequest> {
    constructor() {
        super("go.pms.pkg.task.ListTasksRequest", [
            { no: 1, name: "filter", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 4, name: "read_mask", kind: "message", T: () => FieldMask, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 5, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 6, name: "follower", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<ListTasksRequest>): ListTasksRequest {
        const message = { filter: "", pageSize: 0, pageToken: "", orderBy: "", follower: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTasksRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTasksRequest): ListTasksRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string filter */ 1:
                    message.filter = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                case /* google.protobuf.FieldMask read_mask */ 4:
                    message.readMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.readMask);
                    break;
                case /* string order_by */ 5:
                    message.orderBy = reader.string();
                    break;
                case /* string follower */ 6:
                    message.follower = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTasksRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string filter = 1; */
        if (message.filter !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.filter);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        /* google.protobuf.FieldMask read_mask = 4; */
        if (message.readMask)
            FieldMask.internalBinaryWrite(message.readMask, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 5; */
        if (message.orderBy !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.orderBy);
        /* string follower = 6; */
        if (message.follower !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.follower);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.ListTasksRequest
 */
export const ListTasksRequest = new ListTasksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTasksResponse$Type extends MessageType<ListTasksResponse> {
    constructor() {
        super("go.pms.pkg.task.ListTasksResponse", [
            { no: 1, name: "tasks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Task },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "total_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListTasksResponse>): ListTasksResponse {
        const message = { tasks: [], nextPageToken: "", totalSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListTasksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTasksResponse): ListTasksResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated go.pms.pkg.task.Task tasks */ 1:
                    message.tasks.push(Task.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                case /* int32 total_size */ 3:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTasksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated go.pms.pkg.task.Task tasks = 1; */
        for (let i = 0; i < message.tasks.length; i++)
            Task.internalBinaryWrite(message.tasks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        /* int32 total_size = 3; */
        if (message.totalSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.ListTasksResponse
 */
export const ListTasksResponse = new ListTasksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTaskRequest$Type extends MessageType<CreateTaskRequest> {
    constructor() {
        super("go.pms.pkg.task.CreateTaskRequest", [
            { no: 1, name: "task", kind: "message", T: () => Task, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<CreateTaskRequest>): CreateTaskRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTaskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTaskRequest): CreateTaskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* go.pms.pkg.task.Task task */ 1:
                    message.task = Task.internalBinaryRead(reader, reader.uint32(), options, message.task);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateTaskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* go.pms.pkg.task.Task task = 1; */
        if (message.task)
            Task.internalBinaryWrite(message.task, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.CreateTaskRequest
 */
export const CreateTaskRequest = new CreateTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTaskRequest$Type extends MessageType<UpdateTaskRequest> {
    constructor() {
        super("go.pms.pkg.task.UpdateTaskRequest", [
            { no: 1, name: "task", kind: "message", T: () => Task, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "update_mask", kind: "message", T: () => FieldMask, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<UpdateTaskRequest>): UpdateTaskRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateTaskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateTaskRequest): UpdateTaskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* go.pms.pkg.task.Task task */ 1:
                    message.task = Task.internalBinaryRead(reader, reader.uint32(), options, message.task);
                    break;
                case /* google.protobuf.FieldMask update_mask */ 2:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateTaskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* go.pms.pkg.task.Task task = 1; */
        if (message.task)
            Task.internalBinaryWrite(message.task, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.FieldMask update_mask = 2; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.UpdateTaskRequest
 */
export const UpdateTaskRequest = new UpdateTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaskRequest$Type extends MessageType<GetTaskRequest> {
    constructor() {
        super("go.pms.pkg.task.GetTaskRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "cadencerpm.care/Task" } } },
            { no: 2, name: "read_mask", kind: "message", T: () => FieldMask, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetTaskRequest>): GetTaskRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTaskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTaskRequest): GetTaskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.FieldMask read_mask */ 2:
                    message.readMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.readMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTaskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.FieldMask read_mask = 2; */
        if (message.readMask)
            FieldMask.internalBinaryWrite(message.readMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.GetTaskRequest
 */
export const GetTaskRequest = new GetTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenTaskRequest$Type extends MessageType<OpenTaskRequest> {
    constructor() {
        super("go.pms.pkg.task.OpenTaskRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "cadencerpm.com/Task" } } },
            { no: 2, name: "etag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<OpenTaskRequest>): OpenTaskRequest {
        const message = { name: "", etag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenTaskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenTaskRequest): OpenTaskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string etag */ 2:
                    message.etag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenTaskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string etag = 2; */
        if (message.etag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.etag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.OpenTaskRequest
 */
export const OpenTaskRequest = new OpenTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseTaskRequest$Type extends MessageType<CloseTaskRequest> {
    constructor() {
        super("go.pms.pkg.task.CloseTaskRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "cadencerpm.com/Task" } } },
            { no: 2, name: "etag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<CloseTaskRequest>): CloseTaskRequest {
        const message = { name: "", etag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CloseTaskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseTaskRequest): CloseTaskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string etag */ 2:
                    message.etag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloseTaskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string etag = 2; */
        if (message.etag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.etag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.CloseTaskRequest
 */
export const CloseTaskRequest = new CloseTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTaskRequest$Type extends MessageType<DeleteTaskRequest> {
    constructor() {
        super("go.pms.pkg.task.DeleteTaskRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "cadencerpm.com/Task" } } },
            { no: 2, name: "etag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<DeleteTaskRequest>): DeleteTaskRequest {
        const message = { name: "", etag: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteTaskRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTaskRequest): DeleteTaskRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string etag */ 2:
                    message.etag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteTaskRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string etag = 2; */
        if (message.etag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.etag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.DeleteTaskRequest
 */
export const DeleteTaskRequest = new DeleteTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCommentsRequest$Type extends MessageType<ListCommentsRequest> {
    constructor() {
        super("go.pms.pkg.task.ListCommentsRequest", [
            { no: 1, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "cadencerpm.care/Task" } } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 4, name: "read_mask", kind: "message", T: () => FieldMask, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<ListCommentsRequest>): ListCommentsRequest {
        const message = { parent: "", pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCommentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCommentsRequest): ListCommentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parent */ 1:
                    message.parent = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                case /* google.protobuf.FieldMask read_mask */ 4:
                    message.readMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.readMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCommentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parent = 1; */
        if (message.parent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parent);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        /* google.protobuf.FieldMask read_mask = 4; */
        if (message.readMask)
            FieldMask.internalBinaryWrite(message.readMask, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.ListCommentsRequest
 */
export const ListCommentsRequest = new ListCommentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCommentsResponse$Type extends MessageType<ListCommentsResponse> {
    constructor() {
        super("go.pms.pkg.task.ListCommentsResponse", [
            { no: 1, name: "comments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Comment },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "total_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListCommentsResponse>): ListCommentsResponse {
        const message = { comments: [], nextPageToken: "", totalSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCommentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCommentsResponse): ListCommentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated go.pms.pkg.task.Comment comments */ 1:
                    message.comments.push(Comment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                case /* int32 total_size */ 3:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListCommentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated go.pms.pkg.task.Comment comments = 1; */
        for (let i = 0; i < message.comments.length; i++)
            Comment.internalBinaryWrite(message.comments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        /* int32 total_size = 3; */
        if (message.totalSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.ListCommentsResponse
 */
export const ListCommentsResponse = new ListCommentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCommentRequest$Type extends MessageType<CreateCommentRequest> {
    constructor() {
        super("go.pms.pkg.task.CreateCommentRequest", [
            { no: 1, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { childType: "cadencecare.com/Comment" } } },
            { no: 2, name: "comment", kind: "message", T: () => Comment, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<CreateCommentRequest>): CreateCommentRequest {
        const message = { parent: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCommentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCommentRequest): CreateCommentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parent */ 1:
                    message.parent = reader.string();
                    break;
                case /* go.pms.pkg.task.Comment comment */ 2:
                    message.comment = Comment.internalBinaryRead(reader, reader.uint32(), options, message.comment);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCommentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parent = 1; */
        if (message.parent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parent);
        /* go.pms.pkg.task.Comment comment = 2; */
        if (message.comment)
            Comment.internalBinaryWrite(message.comment, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.CreateCommentRequest
 */
export const CreateCommentRequest = new CreateCommentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateCommentRequest$Type extends MessageType<UpdateCommentRequest> {
    constructor() {
        super("go.pms.pkg.task.UpdateCommentRequest", [
            { no: 1, name: "comment", kind: "message", T: () => Comment, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "update_mask", kind: "message", T: () => FieldMask, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<UpdateCommentRequest>): UpdateCommentRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateCommentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateCommentRequest): UpdateCommentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* go.pms.pkg.task.Comment comment */ 1:
                    message.comment = Comment.internalBinaryRead(reader, reader.uint32(), options, message.comment);
                    break;
                case /* google.protobuf.FieldMask update_mask */ 2:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateCommentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* go.pms.pkg.task.Comment comment = 1; */
        if (message.comment)
            Comment.internalBinaryWrite(message.comment, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.FieldMask update_mask = 2; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.UpdateCommentRequest
 */
export const UpdateCommentRequest = new UpdateCommentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCommentRequest$Type extends MessageType<GetCommentRequest> {
    constructor() {
        super("go.pms.pkg.task.GetCommentRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"], "google.api.resource_reference": { type: "cadencerpm.care/Comment" } } },
            { no: 2, name: "read_mask", kind: "message", T: () => FieldMask, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetCommentRequest>): GetCommentRequest {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCommentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCommentRequest): GetCommentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.FieldMask read_mask */ 2:
                    message.readMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.readMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCommentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.FieldMask read_mask = 2; */
        if (message.readMask)
            FieldMask.internalBinaryWrite(message.readMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.GetCommentRequest
 */
export const GetCommentRequest = new GetCommentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskChangeNotificationRequest$Type extends MessageType<TaskChangeNotificationRequest> {
    constructor() {
        super("go.pms.pkg.task.TaskChangeNotificationRequest", []);
    }
    create(value?: PartialMessage<TaskChangeNotificationRequest>): TaskChangeNotificationRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaskChangeNotificationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskChangeNotificationRequest): TaskChangeNotificationRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TaskChangeNotificationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.TaskChangeNotificationRequest
 */
export const TaskChangeNotificationRequest = new TaskChangeNotificationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskChangeNotificationResponse$Type extends MessageType<TaskChangeNotificationResponse> {
    constructor() {
        super("go.pms.pkg.task.TaskChangeNotificationResponse", [
            { no: 1, name: "task", kind: "message", T: () => Task, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "type", kind: "enum", T: () => ["go.pms.pkg.task.TaskChangeNotificationResponse.ChangeType", TaskChangeNotificationResponse_ChangeType], options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "pre_change_task", kind: "message", T: () => Task, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<TaskChangeNotificationResponse>): TaskChangeNotificationResponse {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TaskChangeNotificationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TaskChangeNotificationResponse): TaskChangeNotificationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* go.pms.pkg.task.Task task */ 1:
                    message.task = Task.internalBinaryRead(reader, reader.uint32(), options, message.task);
                    break;
                case /* go.pms.pkg.task.TaskChangeNotificationResponse.ChangeType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* go.pms.pkg.task.Task pre_change_task */ 3:
                    message.preChangeTask = Task.internalBinaryRead(reader, reader.uint32(), options, message.preChangeTask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TaskChangeNotificationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* go.pms.pkg.task.Task task = 1; */
        if (message.task)
            Task.internalBinaryWrite(message.task, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* go.pms.pkg.task.TaskChangeNotificationResponse.ChangeType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* go.pms.pkg.task.Task pre_change_task = 3; */
        if (message.preChangeTask)
            Task.internalBinaryWrite(message.preChangeTask, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.task.TaskChangeNotificationResponse
 */
export const TaskChangeNotificationResponse = new TaskChangeNotificationResponse$Type();
/**
 * @generated ServiceType for protobuf service go.pms.pkg.task.TaskService
 */
export const TaskService = new ServiceType("go.pms.pkg.task.TaskService", [
    { name: "CreateTask", options: { "google.api.http": { post: "/rpm/v1/task", body: "task" }, "google.api.method_signature": ["task"] }, I: CreateTaskRequest, O: Task },
    { name: "OpenTask", options: { "google.api.http": { post: "/rpm/v1/{name=task/*}:open", body: "*" } }, I: OpenTaskRequest, O: Task },
    { name: "CloseTask", options: { "google.api.http": { post: "/rpm/v1/{name=task/*}:close", body: "*" } }, I: CloseTaskRequest, O: Task },
    { name: "UpdateTask", options: { "google.api.http": { patch: "/rpm/v1/{task.name=task/*}", body: "task" }, "google.api.method_signature": ["task,update_mask"] }, I: UpdateTaskRequest, O: Task },
    { name: "DeleteTask", options: { "google.api.http": { delete: "/rpm/v1/{name=task/*}/{etag}" }, "google.api.method_signature": ["name"] }, I: DeleteTaskRequest, O: Empty },
    { name: "GetTask", options: { "google.api.http": { get: "/rpm/v1/{name=task/*}" }, "google.api.method_signature": ["name"] }, I: GetTaskRequest, O: Task },
    { name: "ListTasks", options: { "google.api.http": { get: "/rpm/v1/task" }, "google.api.method_signature": [""] }, I: ListTasksRequest, O: ListTasksResponse },
    { name: "CreateComment", options: { "google.api.http": { post: "/rpm/v1/{parent=task/*}/comment", body: "comment" }, "google.api.method_signature": ["parent,comment"] }, I: CreateCommentRequest, O: Comment },
    { name: "UpdateComment", options: { "google.api.http": { patch: "/rpm/v1/{comment.name=task/*/comment/*}", body: "comment" }, "google.api.method_signature": ["comment,update_mask"] }, I: UpdateCommentRequest, O: Comment },
    { name: "ListComments", options: { "google.api.http": { get: "/rpm/v1/{parent=task/*}/comment" }, "google.api.method_signature": ["parent"] }, I: ListCommentsRequest, O: ListCommentsResponse },
    { name: "ChangeNotifications", serverStreaming: true, options: { "google.api.http": { get: "/rpm/v1/task:changeNotifications" } }, I: TaskChangeNotificationRequest, O: TaskChangeNotificationResponse }
]);
