import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { PatientGoal } from '../PatientGoal';

export function getPatientGoalI18n(
  intl: IntlShape,
): Record<PatientGoal, string> {
  return {
    [PatientGoal.BecomePhysicallyActive]: intl.formatMessage({
      defaultMessage: 'Become more physically active',
    }),
    [PatientGoal.BetterUnderstandMyDisease]: intl.formatMessage({
      defaultMessage: 'Better understand my disease',
    }),

    [PatientGoal.GetBetterSleep]: intl.formatMessage({
      defaultMessage: 'Get better sleep',
    }),
    [PatientGoal.ImproveMyFoodChoices]: intl.formatMessage({
      defaultMessage: 'Improve my food choices',
    }),
    [PatientGoal.LowerMyWeight]: intl.formatMessage({
      defaultMessage: 'Lower my weight',
    }),
    [PatientGoal.QuitSmoking]: intl.formatMessage({
      defaultMessage: 'Quit smoking',
    }),
    [PatientGoal.ReduceMyStress]: intl.formatMessage({
      defaultMessage: 'Reduce my stress',
    }),
    [PatientGoal.TakeMedicationsConsistently]: intl.formatMessage({
      defaultMessage: 'Take my medications more consistently',
    }),
  };
}

export function usePatientGoalI18n(): Record<PatientGoal, string> {
  const intl = useIntl();
  return getPatientGoalI18n(intl);
}
