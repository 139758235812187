import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { labelText } from '@/shared/common/Form/fields/BaseField/styles.css';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { useTwilioFlexContext } from '@/shared/common/TwilioFlex';
import {
  CONFERENCE_QUERY_KEYS,
  useAddConferenceNote,
  useListConferences,
} from '@/shared/hooks/queries/conference.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { ACTIVE_TASK_STATUSES } from '@/shared/hooks/useTwilioFlexMessagesListener';
import { MultiSelect } from '@/shared/tempo/@labs/molecule/MultiSelect';
import { Select } from '@/shared/tempo/@labs/molecule/Select';
import { Label } from '@/shared/tempo/atom/Label';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import {
  callAssocLabel,
  callAssocSubLabel,
  callDropDown,
  callTrackingContainer,
} from './CallTracking.css';
import { CallTrackingEmptyState } from './CallTrackingEmptyState';
import { CallTrackingRow } from './CallTrackingRow';

type Props = {
  patientId: string;
  noteId: number;
};

export function CallTracking({ patientId, noteId }: Props) {
  const intl = useIntl();
  const { currentUserId } = useCurrentUser();
  const queryClient = useQueryClient();
  const { toaster } = useToaster();

  const callsForNoteParams = useMemo(
    () => ({
      patientId,
      noteInfo: {
        noteId,
      },
    }),
    [noteId, patientId],
  );

  const userCallsParams = useMemo(
    () => ({
      patientId,
      careProviderInfo: {
        careProviderId: currentUserId,
      },
    }),
    [currentUserId, patientId],
  );

  const { isLoading: isLoadingNoteCalls, data: callsForNote } =
    useListConferences(callsForNoteParams, true, false);

  const { isLoading: isLoadingUserCalls, data: userCalls } = useListConferences(
    userCallsParams,
    true,
    false,
  );

  const onUpdateNoteAssoc = useCallback(async () => {
    await queryClient.invalidateQueries(
      CONFERENCE_QUERY_KEYS.list(callsForNoteParams),
    );
    await queryClient.invalidateQueries(
      CONFERENCE_QUERY_KEYS.list(userCallsParams),
    );
  }, [callsForNoteParams, queryClient, userCallsParams]);

  const { tasks } = useTwilioFlexContext();

  const activeTasks = Array.from(tasks.values()).filter((task) =>
    ACTIVE_TASK_STATUSES.includes(task.status),
  );

  useEffect(() => {
    onUpdateNoteAssoc();
  }, [activeTasks.length, onUpdateNoteAssoc]);

  const { mutate: addConferenceNote, isLoading: isAddingConferenceNote } =
    useAddConferenceNote(noteId, currentUserId, onUpdateNoteAssoc);

  const selectCallLabel = intl.formatMessage({
    defaultMessage: 'Select calls to add to this encounter',
  });

  const associationCutoffDate = new Date();
  associationCutoffDate.setDate(associationCutoffDate.getDate() - 5);

  const isNewerThanCutoff = (date: Date) => date > associationCutoffDate;

  const userCallsWithoutNote = (userCalls?.conferences || []).filter(
    (conf) =>
      (conf.noteIds || []).length === 0 &&
      isNewerThanCutoff(parseISO(conf.patientStartTime ?? '')),
  );

  return (
    <div className={callTrackingContainer}>
      <Label
        className={callAssocLabel}
        label={
          <FormattedMessage defaultMessage="Calls associated with this encounter" />
        }
      />
      <div className={callAssocSubLabel}>
        {intl.formatMessage({
          defaultMessage:
            'Auto-associated calls may be incorrect. Please confirm call accuracy before finalizing total time.',
        })}
      </div>
      <LoadingPlaceholder isLoading={isLoadingUserCalls || isLoadingNoteCalls}>
        {(callsForNote?.conferences || []).map((conference) => (
          <CallTrackingRow
            key={conference.name}
            conference={conference}
            noteId={noteId}
            onRemoveNoteAssoc={onUpdateNoteAssoc}
          />
        ))}
        {(callsForNote?.conferences || []).length === 0 && (
          <CallTrackingEmptyState />
        )}
        {userCallsWithoutNote.length > 0 && (
          <>
            <Label
              className={labelText.default}
              label={
                <FormattedMessage defaultMessage="Add additional calls to this encounter" />
              }
            />
            <MultiSelect
              className={callDropDown}
              aria-label={selectCallLabel}
              placeholder={selectCallLabel}
              isLoading={
                isLoadingUserCalls ||
                isLoadingNoteCalls ||
                isAddingConferenceNote
              }
              onSelectionChange={(key) => {
                const selected = key instanceof Set ? Array.from(key) : [key];
                if (selected.length > 0) {
                  addConferenceNote(selected[0] as string, {
                    onError: (err) => {
                      toaster.error(
                        intl.formatMessage(
                          {
                            defaultMessage:
                              'Failed to add {conferenceSid}: {message}',
                          },
                          {
                            conferenceSid: key as string,
                            message: getGrpcErrorMessage(err),
                          },
                        ),
                      );
                    },
                  });
                }
              }}
              selectedKeys={[]}
              items={userCallsWithoutNote}
            >
              {(conference) => (
                <Select.Option key={conference.name}>
                  {format(
                    parseISO(conference.patientStartTime ?? ''),
                    "MM/dd/yyyy 'at' h:mm a",
                  )}{' '}
                  {formatDropdownDuration(conference.patientDuration ?? 0)}
                </Select.Option>
              )}
            </MultiSelect>
          </>
        )}
      </LoadingPlaceholder>
    </div>
  );
}

function formatDropdownDuration(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return minutes <= 1
    ? `(${minutes} min, ${remainingSeconds}s)`
    : `(${minutes} mins, ${remainingSeconds}s)`;
}
