import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import { validators } from '@/shared/common/Form/validations';

export type ExerciseFormFields = {
  currentExercise: 'true' | 'false';
  currentExerciseDescription?: string;
};

export function getExerciseFormConfig(
  intl: IntlShape,
  values?: ExerciseFormFields,
) {
  const { required } = validators(intl);

  return {
    fields: {
      currentExercise: {
        defaultValue: values?.currentExercise,
        validation: required(yup.string().oneOf(['true', 'false'])),
      },
      currentExerciseDescription: {
        defaultValue: values?.currentExerciseDescription,
        validation: yup.string().when('currentExercise', {
          is: 'true',
          then: required,
        }),
      },
    },
  };
}
