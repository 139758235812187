import type { Schema } from '../Schema.types';
import type { DataObject } from '../SchemaDrivenForm';
import { inlineDefinitions } from './inlineDefinitions';
import { mergeConditionalStatements } from './mergeConditionalStatements';
import { mergeDependencies } from './mergeDependencies';
import { replaceKeywords } from './replaceKeywords';

/**
 * Transforms schema so that it will work in our deployed environment.
 *
 * This is needed because rjsf's implementation of certain JSON Schema keywords,
 * including `if/then/else`, requires 'CSP: unsafe-eval` being enabled (see
 * https://github.com/rjsf-team/react-jsonschema-form/issues/1464), but it is
 * too much of a security risk for us to enable that.
 *
 * Until there is an alternative provided by rjsf (see
 * https://github.com/rjsf-team/react-jsonschema-form/issues/2693), our
 * workaround is to evaluate the predicates based on the current form data, and
 * use that pass in a schema stripped of the keywords and unapplciable subschemas
 *
 * @param schema - Original schema
 * @param formData
 * @returns new schema without unsupported keywords
 */
export function transformSchemaForRjsf<T extends DataObject>(
  schema: Schema,
  formData: T,
) {
  let rjsfFormSchema = replaceKeywords(schema);
  rjsfFormSchema = mergeDependencies(rjsfFormSchema);
  rjsfFormSchema = inlineDefinitions(rjsfFormSchema);
  rjsfFormSchema = mergeConditionalStatements(rjsfFormSchema, formData);
  return rjsfFormSchema;
}
