import type { AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import omitBy from 'lodash/omitBy';
import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { PatientCaregiverSchema } from '@/shared/generated/api/pms';
import Session from '@/shared/utils/session';

import type {
  Caregiver,
  StandaloneCaregiver,
} from '../../types/caregiver.types';
import type { Metadata } from '../../types/shared.types';

export const CAREGIVERS_QUERY_KEY_BASE = ['pms', 'api', 'v1', 'patients'];

type CaregiversResponse = {
  metadata: Metadata;
  caregivers: StandaloneCaregiver[];
};

export const getPatientCaregiversQueryKeyBase = (
  patientId: string,
  caregiverId?: string,
) =>
  [...CAREGIVERS_QUERY_KEY_BASE, patientId, 'caregivers', caregiverId].filter(
    Boolean,
  );

export function useCaregivers(
  patientId: string,
  config: UseQueryOptions<
    CaregiversResponse,
    Error,
    StandaloneCaregiver[]
  > = {},
) {
  return useQuery<CaregiversResponse, Error, StandaloneCaregiver[]>(
    getPatientCaregiversQueryKeyBase(patientId),
    {
      ...config,
      select: (data) => camelizeKeys(data.caregivers) as StandaloneCaregiver[],
    },
  );
}

export function useCaregiverDetails(
  patientId: string,
  caregiverId: string,
  enabled = true,
) {
  return useQuery<PatientCaregiverSchema>(
    getPatientCaregiversQueryKeyBase(patientId, caregiverId),
    {
      enabled,
    },
  );
}

// If we want to update an existing caregiver, we need to pass in all the
// required fields plus any updated fields. BUT we can't pass
// in address/contacts as "undefined" or "null".
const safeGuardedPutCaregiver = (caregiver: Caregiver): Partial<Caregiver> =>
  omitBy(caregiver, (objectValue, objectKey) => {
    const REMOVE_IF_EMPTY = ['contacts', 'address'];
    return (
      REMOVE_IF_EMPTY.includes(objectKey) &&
      (objectValue === null || objectValue === undefined)
    );
  });

export function useUpdateCaregiver(
  patientId: string,
  params: {
    onSuccess?: (data: AxiosResponse<StandaloneCaregiver>) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const client = useQueryClient();

  return useMutation(
    (standaloneCaregiver: StandaloneCaregiver) => {
      const payload = decamelizeKeys({
        ...standaloneCaregiver,
        caregiver: safeGuardedPutCaregiver(standaloneCaregiver.caregiver),
      });
      return Session.Api.put<StandaloneCaregiver>(
        `/pms/api/v1/patients/${patientId}/caregivers/${standaloneCaregiver.caregiverId}`,
        payload,
      );
    },
    {
      onSuccess: async (data) => {
        await client.invalidateQueries(
          getPatientCaregiversQueryKeyBase(patientId),
        );
        params.onSuccess?.(data);
      },
      onError: (err) => {
        params.onError?.(err);
      },
    },
  );
}

export function useDeleteCaregiver(
  patientId: string,
  params: {
    onSuccess?: () => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  const client = useQueryClient();

  return useMutation(
    (caregiverId: string) =>
      Session.Api.delete(
        `/pms/api/v1/patients/${patientId}/caregivers/${caregiverId}`,
      ),
    {
      onSuccess: async () => {
        await client.invalidateQueries(
          getPatientCaregiversQueryKeyBase(patientId),
        );
        params.onSuccess?.();
      },
      onError: (err) => {
        params.onError?.(err);
      },
    },
  );
}
