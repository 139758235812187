import type { Task } from '@/shared/tasking/types';
import { TaskState } from '@/shared/tasking/types';

import { isUnassigned } from '../utils';
import { OpenTaskTitle } from './OpenTaskTitle';
import { QueueTaskTitle } from './QueueTaskTitle';
import { ResolvedTaskTitle } from './ResolvedTaskTitle';

type Props = {
  task: Task;
};

export function CardTitle({ task }: Props) {
  const { state } = task;

  if (isUnassigned(task)) {
    return <QueueTaskTitle task={task} />;
  }

  if (state === TaskState.OPENED) {
    return <OpenTaskTitle task={task} />;
  }

  if (state === TaskState.CLOSED) {
    return <ResolvedTaskTitle task={task} />;
  }

  return null;
}
