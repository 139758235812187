import { FormattedMessage } from 'react-intl';

import { Button } from '@/shared/tempo/atom/Button';

import { container, text, trim, wrapper } from './InProgressNoteBox.css';

type Props = {
  onOpen: () => void;
  isLoading?: boolean;
  variant: 'draft' | 'autosaved';
};

export function InProgressNoteBox({ onOpen, isLoading, variant }: Props) {
  return (
    <div className={container}>
      <div className={wrapper[variant]}>
        <span className={trim[variant]} />
        <span className={text}>
          {variant === 'draft' ? (
            <FormattedMessage defaultMessage="Saved draft" />
          ) : (
            <FormattedMessage defaultMessage="Autosaved note" />
          )}
        </span>
        <Button variant="tertiary" onPress={onOpen} isDisabled={isLoading}>
          <FormattedMessage defaultMessage="Open" />
        </Button>
      </div>
    </div>
  );
}
