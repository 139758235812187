import type { UserRole } from '@/shared/hooks/queries/auth.queries';

const superAdminRoles: UserRole[] = ['SUPER_ADMIN'];
const adminRoles: UserRole[] = ['SUPER_ADMIN', 'ADMIN'];
const billingAdminRoles: UserRole[] = ['BILLING_ADMIN'];

export const isAdmin = (roles: UserRole[] | undefined): boolean =>
  !!roles?.some((role: UserRole) => adminRoles.includes(role));

export const isBillingAdmin = (roles: UserRole[] | undefined): boolean =>
  !!roles?.some((role: UserRole) => billingAdminRoles.includes(role));

export const isSuperAdmin = (roles: UserRole[] | undefined): boolean =>
  !!roles?.some((role: UserRole) => superAdminRoles.includes(role));
