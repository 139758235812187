import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const LinkIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6375 11.4387C12.8758 10.6297 11.8429 10.1752 10.7659 10.1752C9.68886 10.1752 8.65595 10.6297 7.89424 11.4387L5.02169 14.4898C4.25996 15.2992 3.83203 16.3969 3.83203 17.5415C3.83203 18.686 4.25996 19.7838 5.02169 20.5931C5.78341 21.4024 6.81653 21.8571 7.89377 21.8571C8.97101 21.8571 10.0041 21.4024 10.7659 20.5931L12.2017 19.0675M10.7661 14.4898C11.5278 15.2988 12.5607 15.7533 13.6377 15.7533C14.7147 15.7533 15.7476 15.2988 16.5093 14.4898L19.3819 11.4387C20.1436 10.6294 20.5715 9.53165 20.5715 8.38707C20.5715 7.24248 20.1436 6.14478 19.3819 5.33544C18.6201 4.52609 17.587 4.07141 16.5098 4.07141C15.4325 4.07141 14.3994 4.52609 13.6377 5.33544L12.2019 6.86101"
        stroke="#3B3A45"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default LinkIcon;
