import cx from 'classnames';
import type { ReactNode } from 'react';

import AlertTriangle from '@/shared/assets/svgs/alertTriangle.svg?react';

import { errorIcon, validationError } from './ValidationMessage.css';

export function ValidationMessage({
  validationMessage,
  messageOverride,
  className,
}: {
  validationMessage: ReactNode;
  messageOverride?: ReactNode;
  className?: string;
}) {
  return validationMessage ? (
    <div className={cx(validationError, className)}>
      <AlertTriangle className={errorIcon} />
      {messageOverride ?? validationMessage}
    </div>
  ) : null;
}
