import first from 'lodash/first';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';

import type {
  LabGroupWithReferenceLabs,
  LabsAndGroups,
  ReferenceLab,
  ReferenceLabWithLabs,
} from '@/shared/generated/grpcGateway/labs.pb';
import { parseGrpcDate } from '@/shared/utils/grpc';

import type { FilterState, LabGroupAndDate, LabsWithRefInfo } from './types';

export function filterLabGroups(
  labGroupsWithReferenceLabs: LabGroupWithReferenceLabs[],
  filterState: FilterState,
) {
  let filteredLabs = [...labGroupsWithReferenceLabs];

  if (filterState.analytes.length) {
    filteredLabs = filteredLabs.map((labGroup) => ({
      ...labGroup,
      referenceLabs: labGroup.referenceLabs?.filter((refLabWithLab) =>
        filterState.analytes.includes(
          refLabWithLab.referenceLab?.labName as string,
        ),
      ),
    }));
  }

  if (filterState.labGroup) {
    filteredLabs = filteredLabs.filter(
      (labGroup) => labGroup.labGroup === filterState.labGroup,
    );
  }

  return filteredLabs;
}

/* Returns the latest dates and corresponding labs per group */
export function getLatestLabGroupsWithDatesAndLabs(
  labGroups: LabGroupWithReferenceLabs[],
) {
  const latestLabs: Record<string, { date: Date; labs: LabsWithRefInfo[] }> =
    {};

  labGroups?.forEach((labGroup) => {
    const labDates = getLabDates(labGroup.referenceLabs ?? []);

    // get latest date per group
    const latestDate: Date = new Date(
      Math.max(...labDates?.map((date) => date.getTime())),
    );

    // tag each lab with reference info and filter on latest date
    const labs: LabsWithRefInfo[] =
      labGroup.referenceLabs
        ?.flatMap((refLab) =>
          (refLab.labs ?? []).map((lab) => ({
            ...(lab as LabsAndGroups),
            referenceName: refLab.referenceLab?.name ?? '',
            referenceLabName: refLab.referenceLab?.labName ?? '',
            referenceUnit: refLab.referenceLab?.unit ?? '',
          })),
        )
        ?.filter(
          (lab) =>
            parseGrpcDate(lab.observationDate as GoogleDate)?.getTime() ===
            latestDate?.getTime(),
        ) ?? [];

    // Check if there is no lab in the array with the same date and value
    const uniqueLabs = uniqWith(labs, (lab1, lab2) => {
      const date1 = parseGrpcDate(lab1.observationDate as GoogleDate);
      const date2 = parseGrpcDate(lab2.observationDate as GoogleDate);

      return date1?.getTime() === date2?.getTime() && lab1.value === lab2.value;
    });

    const sortedUniqueLabs = sortBy(
      uniqueLabs,
      (lab) => parseGrpcDate(lab.observationDate as GoogleDate)?.getTime(),
    );

    if (labDates.length) {
      latestLabs[labGroup.labGroup as string] = {
        date: latestDate,
        labs: sortedUniqueLabs,
      };
    }
  });

  return latestLabs;
}

function getLabDates(referenceLabs: ReferenceLabWithLabs[]): Date[] {
  return referenceLabs
    ?.flatMap(
      (referenceLab) =>
        referenceLab.labs?.map((lab) =>
          parseGrpcDate(lab.observationDate as GoogleDate),
        ) ?? [],
    )
    .filter((labDate) => labDate !== undefined) as Date[];
}

/* Returns the latest lab groups with date only. Makes handling of types in LabFilters easier. */
export function getLatestLabGroupsWithDates(
  labGroups: LabGroupWithReferenceLabs[],
): LabGroupAndDate[] {
  const labGroupWithDatesAndLabs =
    getLatestLabGroupsWithDatesAndLabs(labGroups);

  const latestPanelDates: LabGroupAndDate[] = Object.keys(
    labGroupWithDatesAndLabs,
  ).map((group) => {
    const { date } = labGroupWithDatesAndLabs[group];
    const latestDate = new Date(date.getTime());
    return { group, date: latestDate };
  });

  return latestPanelDates;
}

/* Returns the lab group with the latest date */
export function getDefaultLabGroup(labGroupToDates: LabGroupAndDate[]) {
  const latestPanel = Object.values(labGroupToDates)
    .filter((a) => a.date)
    .sort((a, b) => b.date.getTime() - a.date.getTime())
    .map((a) => a.group);

  return first(latestPanel) as string;
}

/* Checks if there are labs to display */
export function areLabsEmpty(labGroups: LabGroupWithReferenceLabs[]) {
  return labGroups
    .flatMap((labGroup) => labGroup.referenceLabs ?? [])
    .every((refLab) => refLab.labs?.length === 0);
}

export function getRefLabsAndTaggedLabs(
  labGroups: LabGroupWithReferenceLabs[],
) {
  const referenceLabs = labGroups
    .flatMap((labGroup) => labGroup.referenceLabs ?? [])
    .map((refLab) => refLab.referenceLab) as ReferenceLab[];

  // tag labs with reference lab info
  const taggedLabs = labGroups
    .flatMap((labGroup) => labGroup.referenceLabs ?? [])
    .flatMap((refLab) =>
      (refLab.labs ?? []).map((lab) => ({
        ...lab,
        referenceName: refLab.referenceLab?.name,
        referenceLabName: refLab.referenceLab?.labName,
        referenceUnit: refLab.referenceLab?.unit,
      })),
    ) as LabsWithRefInfo[];

  return { referenceLabs, taggedLabs };
}
