import { useIntl } from 'react-intl';

import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { useCNFormContext } from '../../../CNFormContext';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import type { SymptomsFormFields } from '../../shared/Symptoms';
import { SharedSymptoms, getSymptomsFormConfig } from '../../shared/Symptoms';
import { regVisitPath } from '../paths';

export function Symptoms() {
  const intl = useIntl();
  const { program } = useCNFormContext();
  const form = useWizardFormFromConfig<SymptomsFormFields>(
    regVisitPath('/symptoms', '/index'),
    getSymptomsFormConfig(intl, program),
  );

  return <SharedSymptoms form={form} sections={REGULAR_VISIT_SECTIONS} />;
}
