/**
 * Joins paths together and always omits leading / trailing slashes
 */
export function joinPaths(...paths: string[]) {
  return paths
    .reduce(
      (pathParts, curr) => pathParts.concat(curr.split('/')),
      [] as string[],
    )
    .filter(Boolean)
    .join('/');
}

export function parentPath(
  pathName: string,
  levels: number = 1,
  preserveSearch = false,
) {
  const pathParts = pathName.split('/');
  const parentPathParts = pathParts.slice(0, pathParts.length - levels);
  return `${parentPathParts.join('/')}${
    preserveSearch ? window.location.search : ''
  }`;
}
