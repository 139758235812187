import { FormattedMessage } from 'react-intl';

import { usePatientDetails } from '@/shared/hooks/queries';
import { useJitQueryEnabler } from '@/shared/hooks/useJitQueryEnabler';
import type { Task } from '@/shared/tasking/types';

import { title } from './styles.css';
import { useShouldShowPatientDetails } from './useShouldShowPatientDetails';

type Props = {
  task: Task;
};

export function ResolvedTaskTitle({ task }: Props) {
  const { patientId } = task;
  const { queryEnabled, inViewRef } = useJitQueryEnabler();
  const shouldShowPatientDetails = useShouldShowPatientDetails();
  const { data: patientDetails } = usePatientDetails(
    patientId || '',
    true,
    queryEnabled && shouldShowPatientDetails && Boolean(patientId),
  );
  let text;

  if (shouldShowPatientDetails && patientDetails?.patient) {
    text = (
      <FormattedMessage
        defaultMessage="Task for {patient} resolved"
        values={{
          patient: `${patientDetails?.patient?.givenName} ${patientDetails?.patient?.familyName}`,
        }}
      />
    );
  } else {
    text = <FormattedMessage defaultMessage="Task resolved" />;
  }

  return (
    <div className={title} ref={inViewRef}>
      {text}
    </div>
  );
}
