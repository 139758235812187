import type { Schema } from '@/shared/common/@deprecated/SchemaDrivenForm/';

import type { DataValue } from '../SchemaDrivenForm';

/**
 * Creates a new schema by running `schema` and all it's subschemas through
 * the mapperFn
 *
 * @param schema - Original schema
 * @param mapperFn
 * @param values - Values that this schema describes
 * @returns new schema
 */
export function mapSchema(
  schema: Schema,
  mapperFn: (subschema: Schema, values?: DataValue) => Schema,
  values?: DataValue,
): Schema {
  let newSchema = mapperFn(schema, values);

  if (newSchema.properties) {
    newSchema = {
      ...newSchema,
      properties: Object.fromEntries(
        Object.entries(newSchema.properties).map(([name, subschema]) => [
          name,
          mapSchema(
            subschema,
            mapperFn,
            typeof values === 'object' ? values?.[name] : undefined,
          ),
        ]),
      ),
    };
  }

  if (newSchema.dependencies) {
    newSchema = {
      ...newSchema,
      dependencies: Object.fromEntries(
        Object.entries(newSchema.dependencies).map(([name, subschema]) => {
          if (Array.isArray(subschema)) {
            return [name, subschema];
          }

          return [name, mapSchema(subschema, mapperFn, values)];
        }),
      ),
    };
  }

  if (newSchema.allOf) {
    newSchema = {
      ...newSchema,
      allOf: newSchema.allOf.map((subschema) =>
        mapSchema(subschema, mapperFn, values),
      ),
    };
  }

  if (newSchema.then) {
    newSchema = {
      ...newSchema,
      then: mapSchema(newSchema.then, mapperFn, values),
    };
  }

  if (newSchema.else) {
    newSchema = {
      ...newSchema,
      else: mapSchema(newSchema.else, mapperFn, values),
    };
  }

  return newSchema;
}
