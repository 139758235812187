import { FormattedMessage, useIntl } from 'react-intl';

import type { MedicationChange } from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

import {
  useReviewMedication,
  useStructureMedication,
} from '../patientMedications.queries';
import { isUnreferenced } from '../utils/medChangeUtils';
import { MedicationForm } from './MedicationForm';
import type { MedPayload } from './editMedicationFormConfig';
import { formHeading } from './styles.css';
import type { RxNormRestriction } from './types';

export function EditMedicationForm({
  initValues,
  medChangeId,
  onClose,
  referenceId,
  existingReferencedMedIds,
  rxNormRestriction,
  onSuccess,
}: {
  initValues: MedicationChange;
  medChangeId: string;
  onClose: () => void;
  referenceId?: string;
  existingReferencedMedIds?: Maybe<string>[];
  rxNormRestriction: RxNormRestriction;
  onSuccess: () => void;
}) {
  const intl = useIntl();
  const { toaster } = useToaster();

  const structureMedMutation = useStructureMedication(medChangeId, onSuccess);
  const reviewMedMutation = useReviewMedication(onSuccess);

  function reviewMed(payload: MedPayload) {
    reviewMedMutation.mutate(
      {
        ...payload,
        ...(shouldCreateNewMedChange(initValues, payload)
          ? {}
          : { medicationChangeId: initValues.name }),
      },
      {
        onSuccess: onClose,
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: 'Failed to update medication: {message}',
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  function structureMed(payload: MedPayload, reviewFunction?: () => void) {
    structureMedMutation.mutate(payload, {
      onSuccess: () => {
        if (reviewFunction) {
          reviewFunction();
        } else {
          onClose();
        }
      },
      onError: (err) => {
        toaster.error(
          intl.formatMessage(
            {
              defaultMessage: 'Failed to update medication: {message}',
            },
            { message: getGrpcErrorMessage(err) },
          ),
        );
      },
    });
  }
  function handleSubmitForm(payload: MedPayload) {
    const isCurrentUnreferencedMed = isUnreferenced(initValues.rxnorm);
    const isAttemptingToReferenceMed = payload.rxnormId !== undefined;
    const movingToNeedsReview =
      payload.status === MedicationChangeStatus.NEEDS_REVIEW;

    if (
      movingToNeedsReview ||
      (isCurrentUnreferencedMed && !isAttemptingToReferenceMed)
    ) {
      // When the requested status is Needs Review or we aren't adding a reference medication
      // We update the existing medication change (structure it) -- even if a different status/reason is sent
      structureMed(payload);
    } else if (isCurrentUnreferencedMed && isAttemptingToReferenceMed) {
      // When we are adding a reference medication (rxnorm) to an unreferened medication
      // We update the existing medication change (structure it) and create a new med change (review it)
      structureMed({ rxnormId: payload.rxnormId, status: payload.status }, () =>
        reviewMed(payload),
      );
    } else {
      // Otherwise we just want to create a new medication change (review it)
      reviewMed(payload);
    }
  }

  return (
    <>
      <div className={formHeading}>
        <FormattedMessage defaultMessage="Med Review corrections" />
      </div>
      <MedicationForm
        initValues={initValues}
        onSubmit={handleSubmitForm}
        onClose={onClose}
        isProcessing={
          structureMedMutation.isLoading || reviewMedMutation.isLoading
        }
        referenceId={referenceId}
        existingReferencedMedIds={existingReferencedMedIds}
        rxNormRestriction={rxNormRestriction}
      />
    </>
  );
}

function shouldCreateNewMedChange(
  existingChange: MedicationChange,
  newChange: MedPayload,
): boolean {
  // Always create a review if existing change type is not Review
  if (existingChange.changeType !== MedicationChangeChangeType.REVIEW) {
    return true;
  }

  // Always create a review on a new note
  if (
    !existingChange.noteId ||
    !newChange.noteId ||
    existingChange.noteId !== newChange.noteId
  ) {
    return true;
  }

  // Otherwise update the existing med change
  return false;
}
