import type { ReactNode } from 'react';

import { wrapper } from './FieldGroup.css';

type Props = {
  children: ReactNode;
};

export function FieldGroup({ children }: Props) {
  return <div className={wrapper}>{children}</div>;
}
