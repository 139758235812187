import * as yup from 'yup';

export type ProductFeedbackFormFields = {
  productFeedback?: string;
};

export function getProductFeedbackFormConfig() {
  return {
    fields: {
      productFeedback: {
        defaultValue: undefined,
        validation: yup.string(),
      },
    },
  };
}
