import last from 'lodash/last';
import { useIntl } from 'react-intl';

import { UserAvatar } from '@/shared/common/UserAvatar';
import { useSetTaskState } from '@/shared/hooks/queries/tasks.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import type { Task } from '../types';
import { TaskState } from '../types';
import { CardSection } from './CardSection';
import { CardTitle } from './CardTitle';
import { snippetText } from './TaskCard.css';
import { TaskHeader } from './TaskHeader';
import { TimestampWithContext } from './Timestamp/TimestampWithContext';
import { UnassignedAvatar } from './UnassignedAvatar';
import { isUnassigned, lastActivityAt } from './utils';

type Props = {
  task: Task;
  hideSnippet?: boolean;
  onSelfAssignTask?: () => void;
};

const SNIPPET_MAX_LENGTH = 56;

export function TaskCardCompactContent({
  task,
  onSelfAssignTask,
  hideSnippet = false,
}: Props) {
  const { assignee } = task;
  const intl = useIntl();
  const { toaster } = useToaster();
  const { mutate: setTaskState } = useSetTaskState(task, {
    onSuccess: (newState) => {
      toaster.success(
        newState === TaskState.CLOSED
          ? intl.formatMessage({
              defaultMessage: 'Task resolved. Nice work! 🎉',
            })
          : intl.formatMessage({ defaultMessage: 'Task reopened' }),
      );
    },
    onError: () => {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to update task',
        }),
      );
    },
  });
  const snippet = task.comments.length
    ? last(task.comments)?.body
    : task.description;
  const truncatedSnippet =
    snippet && snippet.length > SNIPPET_MAX_LENGTH
      ? `${snippet.slice(0, SNIPPET_MAX_LENGTH)}...`
      : snippet;

  return (
    <CardSection>
      <CardSection.LeftColumn>
        {!isUnassigned(task) && assignee ? (
          <UserAvatar
            firstName={assignee.givenName}
            lastName={assignee.familyName}
          />
        ) : (
          <UnassignedAvatar task={task} />
        )}
      </CardSection.LeftColumn>
      <CardSection.RightColumn>
        <TaskHeader
          task={task}
          onResolve={() => setTaskState(TaskState.CLOSED)}
          onReopen={() => setTaskState(TaskState.OPENED)}
          onSelfAssignTask={onSelfAssignTask}
        />
        <CardTitle task={task} />
        {!hideSnippet && <div className={snippetText}>{truncatedSnippet}</div>}
        <TimestampWithContext
          ts={lastActivityAt(task)}
          numMessages={task.comments.length}
        />
      </CardSection.RightColumn>
    </CardSection>
  );
}
