import type { IntroFormFields } from '../../../CNWizardForm/sections/shared/IntroPatientAttendance';
import { MarkdownBuilder } from '../../engine/MarkdownBuilder';

type Params = {
  introData: Maybe<IntroFormFields>;
};
export function cnIntro({ introData }: Params) {
  const md = new MarkdownBuilder();

  if (introData?.languageInterpreter) {
    md.field('Language interpreter:', introData.languageInterpreter);
  }

  if (introData?.nonPatientInfo) {
    md.field('Speaking on behalf of patient:', introData.nonPatientInfo);
  }

  return md.toString();
}
