import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import type {
  MutationFunction,
  QueryObserverBaseResult,
  QueryObserverOptions,
  QueryObserverSuccessResult,
} from 'react-query/types/core/types';
import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
} from 'react-query/types/react/types';

import { logger } from '@/logger';
import type {
  AipPaginatedData,
  AipPaginatedPartialData,
  DataListPartialRecord,
  DataListRecord,
  PaginatedData,
} from '@/shared/types/pagination.types';

export function usePaginatedQuery<T>(
  url: string,
  options: UseInfiniteQueryOptions<PaginatedData<T>> = {},
) {
  const queryKey = useMemo(() => {
    const [endpointWithoutParams, queryParamsString] = url.split('?');
    const params: { [key: string]: unknown } = {};

    // support multiple query params with the same key by building an
    // array of values for each key if needed
    new URLSearchParams(queryParamsString).forEach((value, key) => {
      if (!params[key]) {
        params[key] = value;
        return;
      }

      if (Array.isArray(params[key])) {
        // typescript isn't smart enough to know that params[key] is an array
        // despite the check above, so we need to cast it
        (params[key] as unknown[]).push(value);
        return;
      }

      params[key] = [params[key], value];
    });

    return [
      ...endpointWithoutParams.split('/').slice(1),
      {
        ...params,
      },
      'infinite',
    ];
  }, [url]);

  const infiniteQuery = useInfiniteQuery<PaginatedData<T>>(queryKey, options);
  const total = infiniteQuery.data?.pages?.[0].metadata.total;
  const items = useFlatPages(infiniteQuery);
  const isEmptyResult = !total && !infiniteQuery.isLoading;
  const hasMoreToFetch = items.length < (total ?? Infinity);

  return {
    infiniteQuery,
    total,
    items,
    isEmptyResult,
    hasMoreToFetch,
  };
}

export type FlatPages<T, DataKey extends string> = FlatArray<
  DataListRecord<T, DataKey>[DataKey][],
  1
>[];

type FlatPagesPartial<T, DataKey extends string> = FlatArray<
  DataListPartialRecord<T, DataKey>[DataKey][],
  1
>[];

export function useFetchAllPages<T, DataKey extends string = 'data'>(
  infiniteQuery: UseInfiniteQueryResult<PaginatedData<T, DataKey>>,
  onComplete?: (data: FlatPages<T, DataKey>) => void,
  dataKey: string = 'data',
) {
  const { hasNextPage, isFetching, fetchNextPage, isSuccess } = infiniteQuery;
  const data = useFlatPages(infiniteQuery, dataKey);

  // Fetch pages serially
  useEffect(() => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetching, fetchNextPage]);

  // Invoke callback on completion
  useEffect(() => {
    if (!hasNextPage && isSuccess) {
      onComplete?.(data);
    }
  }, [hasNextPage, isSuccess, data, onComplete]);

  return infiniteQuery;
}

/**
 * note: there is a typescript bug which causes issues with inferring types
 * in this function (https://github.com/microsoft/TypeScript/issues/46234)
 *
 * if the types match up and TS is complaining, you'll need to explicitly
 * pass in the generics rather than allowing them to be inferred.
 */
export function useFlatPages<T, DataKey extends string>(
  infiniteQuery: UseInfiniteQueryResult<PaginatedData<T, DataKey>>,
  dataKey?: string,
): FlatPages<T, DataKey>;
export function useFlatPages<T, DataKey extends string>(
  infiniteQuery: UseInfiniteQueryResult<AipPaginatedData<T, DataKey>>,
  dataKey?: string,
): FlatPages<T, DataKey>;
export function useFlatPages<T, DataKey extends string = 'data'>(
  infiniteQuery: UseInfiniteQueryResult<DataListRecord<T, DataKey>>,
  dataKey = 'data',
) {
  return useMemo(
    () =>
      infiniteQuery.data?.pages
        .map((page: DataListRecord<T, DataKey>) => page[dataKey as DataKey])
        .flat() ?? [],
    [infiniteQuery.data?.pages, dataKey],
  );
}

export function usePartialFlatPages<T, DataKey extends string = 'data'>(
  infiniteQuery: UseInfiniteQueryResult<AipPaginatedPartialData<T, DataKey>>,
  dataKey = 'data',
): FlatPagesPartial<T, DataKey> {
  return useMemo(
    () =>
      infiniteQuery.data?.pages
        ?.map(
          (page: DataListPartialRecord<T, DataKey>) => page[dataKey as DataKey],
        )
        ?.flat() ?? [],
    [infiniteQuery.data?.pages, dataKey],
  );
}

/**
 * This hook provides a way to reuse existing react-query based hooks
 * in event handlers and effects with async/await when parameters aren't
 * known before handling the event / effect, so we need to do a fetch on demand.
 * @param queryHook Hook which returns react-query query and accepts
 * request params and react-query options.
 * @return A function which can be used in async/await fashion to get
 * fetched react-query query. It can be used many times sequentially like:
 * const patientsByNpiQuery = await fetchQuery({ npiId: <some_npi_id> });
 * const patientsByIdQuery = await fetchQuery({ id: <some_id> });
 * However using it in parallel with e.g. Promise.all isn't supported.
 */
export function useFetchQuery<
  P extends Record<never, never>,
  O extends QueryObserverOptions<D, E>,
  D,
  E,
>(queryHook: (params: P, options: O) => QueryObserverBaseResult<D, E>) {
  const optionOverrides = { enabled: false, keepPreviousData: false };
  const [[params, options, resolveAfterRender], setState] = useState<
    [P, O, (() => void) | null]
  >([{} as P, {} as O, null]);

  const query = queryHook(params, { ...options, ...optionOverrides });

  useEffect(() => {
    resolveAfterRender?.();
  }, [resolveAfterRender]);

  async function fetch(queryParams: P, queryOptions: O = {} as O) {
    await new Promise<void>((resolve) => {
      setState([queryParams, queryOptions, resolve]);
    });
    return query.refetch({ throwOnError: true }) as Promise<
      QueryObserverSuccessResult<D, E>
    >;
  }

  return { fetch, ...query };
}

export function queriesAreLoading(queries: Array<{ isLoading: boolean }>) {
  return queries.some((query) => query.isLoading);
}

export function queriesAreFetching(queries: Array<{ isFetching: boolean }>) {
  return queries.some((query) => query.isFetching);
}

export function errorInQueries(queries: Array<{ isError: boolean }>) {
  return queries.some((query) => query.isError);
}

export function getFirstError(queries: Array<{ error?: unknown }>) {
  return queries.find((query) => query.error)?.error;
}

export function usePreventConcurrentMutations<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
) {
  const mutation = useMutation(mutationFn, options);

  return {
    ...mutation,
    mutate: (params: TVariables, opts?: typeof options) => {
      if (!mutation.isLoading) {
        mutation.mutate(params, opts);
      } else {
        logger.warn('Prevented concurrent mutation');
      }
    },
  };
}
