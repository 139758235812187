import cx from 'classnames';
import type { HTMLAttributes, LabelHTMLAttributes, ReactNode } from 'react';

import { description as descriptionCss, label as labelCss } from './Label.css';
import { RequiredIndicator } from './RequiredIndicator';

type Props = {
  label: string | ReactNode;
  className?: string;
  description?: string | ReactNode;
  descriptionProps?: HTMLAttributes<HTMLSpanElement>;
  hasError?: boolean;
  isRequired?: boolean;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
};

export function Label({
  label,
  className,
  description,
  descriptionProps,
  hasError,
  isRequired,
  labelProps,
}: Props) {
  return (
    <div className={className}>
      <label
        {...labelProps}
        className={cx(
          {
            [labelCss.default]: !hasError,
            [labelCss.error]: hasError,
          },
          labelProps?.className,
        )}
      >
        {label}
        {isRequired && <RequiredIndicator />}
      </label>
      {description && (
        <p
          {...descriptionProps}
          className={cx(descriptionCss, descriptionProps?.className)}
        >
          {description}
        </p>
      )}
    </div>
  );
}
