import {
  ActionPlanScript,
  EducationModulesReview,
  ReviewActionSteps,
  SetActionSteps,
  SetGoal,
} from './actionPlan';
import { ChartPrep } from './chartPrep';
import { DeviceTips } from './deviceTips';
import { EmergencyVisits } from './emergencyVisits';
import { EndCall } from './endCall';
import { DailyLiving, Diet, Exercise, TobaccoUse } from './generalAssessment';
import {
  IntroCadenceDescription,
  IntroContactInfo,
  IntroPatientAttendance,
  IntroProgramDescription,
  IntroQuestions,
} from './introduction';
import { MedReview } from './medReview';
import {
  ProfileCompletion,
  ProfileCompletionInactive,
} from './profileCompletion';
import { Scheduling } from './scheduling';
import { Symptoms } from './symptoms';
import { SecondarySymptoms } from './symptoms/SecondarySymptoms';
import { TimeTracking } from './timeTracking';
import { VitalProgressIntro } from './vitalProgress';
import { VitalRecommendation } from './vitalProgress/VitalRecommendation';

export const CYCLE_17_INIT_VISIT_ROUTES = {
  '/chart-prep/index': ChartPrep,
  '/intro/patient-attendance': IntroPatientAttendance,
  '/intro/contact-info': IntroContactInfo,
  '/intro/cadence-description': IntroCadenceDescription,
  '/intro/program-description': IntroProgramDescription,
  '/intro/questions': IntroQuestions,
  '/symptoms/index': Symptoms,
  '/symptoms/secondary': SecondarySymptoms,
  '/emergency-visits/index': EmergencyVisits,
  '/profile-completion/index': ProfileCompletion,
  '/profile-completion/not-activated': ProfileCompletionInactive,
  '/device-tips/index': DeviceTips,
  '/vital-progress/intro': VitalProgressIntro,
  '/vital-progress/recommendation': VitalRecommendation,
  '/action-plan/script': ActionPlanScript,
  '/action-plan/set-goal': SetGoal,
  '/action-plan/education-modules-review': EducationModulesReview,
  '/action-plan/set-action-steps': SetActionSteps,
  '/action-plan/review-action-steps': ReviewActionSteps,
  '/general-assessment/tobacco-use': TobaccoUse,
  '/general-assessment/diet': Diet,
  '/general-assessment/exercise': Exercise,
  '/general-assessment/daily-living': DailyLiving,
  '/med-review/index': MedReview,
  '/scheduling/index': Scheduling,
  '/time-tracking/index': TimeTracking,
  '/end-call/index': EndCall,
};
