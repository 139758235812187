import type { UseQueryOptions, UseQueryResult } from 'react-query';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { useTableSort } from '@/shared/common/Table';
import { getSortParams } from '@/shared/common/Table/sort/sort.utils';
import type { HealthSystemSchema } from '@/shared/generated/api/pms';
import type { HealthSystem } from '@/shared/types/healthSystem.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type {
  CompositeSortDirection,
  CompositeSortKey,
  SortDirection,
} from '@/shared/types/sorting.types';
import Session from '@/shared/utils/session';

const HEALTH_SYSTEM_QUERY_KEY_BASE = [
  'pms',
  'api',
  'v1',
  'health_systems',
] as const;

type HealthSystemInfiniteParams = {
  sort_by?: CompositeSortKey<SortableHealthSystemFields>;
  order_by?: CompositeSortDirection;
};

export type SortableHealthSystemFields =
  | 'name'
  | 'created_at'
  | 'updated_at'
  | 'primary_contact_email';

const healthSystemKeys = {
  list: () => [...HEALTH_SYSTEM_QUERY_KEY_BASE] as const,
  byId: (id: number | string) => [...HEALTH_SYSTEM_QUERY_KEY_BASE, id] as const,
  infinite: (params?: HealthSystemInfiniteParams) =>
    [...HEALTH_SYSTEM_QUERY_KEY_BASE, params, 'infinite'] as const,
};

export function useHealthSystemInfinite() {
  const { sortState, handleSort } = useTableSort<SortableHealthSystemFields>({
    sortKey: ['name'],
    sortDir: ['asc'],
  });

  const { sortKey, sortDir } = getSortParams(sortState);

  const infiniteQuery = useInfiniteQuery<PaginatedData<HealthSystem>>(
    healthSystemKeys.infinite({
      ...(sortKey && { sort_by: sortKey }),
      ...(sortDir && { order_by: sortDir }),
    }),
  );
  return {
    ...infiniteQuery,
    sortState,
    handleSort,
  };
}

export function useHealthSystems(
  params?: {
    name?: string;
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: SortDirection;
  },
  options?: UseQueryOptions<PaginatedData<HealthSystem>>,
) {
  return useQuery<PaginatedData<HealthSystem>>(
    [
      ...healthSystemKeys.list(),
      {
        ...(params?.name && { name: params.name }),
        ...(params?.page && { page: params.page }),
        ...(params?.pageSize && { page_size: params.pageSize }),
        ...(params?.sortBy && { sort_by: params.sortBy }),
        ...(params?.orderBy && { order_by: params.orderBy }),
      },
    ],
    {
      ...options,
    },
  );
}

function useStaleHealthSystems() {
  return useQuery<PaginatedData<HealthSystem>>(healthSystemKeys.list(), {
    // health systems are effectively static data, so i'm comfortable saying
    // that we'll never need to refetch this over the lifetime of the session.
    staleTime: Infinity,
  });
}

export function useHealthSystemById(
  heathSystemId: number | string,
  config?: UseQueryOptions<HealthSystem>,
): UseQueryResult<HealthSystem, unknown> {
  return useQuery(healthSystemKeys.byId(heathSystemId), {
    enabled: config?.enabled ?? true,
  });
}

export function useHealthSystemLookupMap() {
  const { data, ...rest } = useStaleHealthSystems();

  const map = (data?.data ?? []).reduce(
    (acc, system) => {
      acc[system.id] = system;
      return acc;
    },
    {} as { [id: number]: HealthSystem },
  );

  return { data: map, ...rest };
}

export function useUpdateHealthSystem(heathSystemId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: HealthSystemSchema) =>
      Session.Api.put<HealthSystemSchema>(
        `/pms/api/v1/health_systems/${heathSystemId}`,
        payload,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(healthSystemKeys.list());
      },
    },
  );
}

export function useDeleteHealthSystem(heathSystemId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      Session.Api.delete<HealthSystemSchema>(
        `/pms/api/v1/health_systems/${heathSystemId}`,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(healthSystemKeys.infinite());
      },
    },
  );
}

export function useCreateHealthSystem() {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: HealthSystemSchema) =>
      Session.Api.post<HealthSystemSchema>(
        `pms/api/v1/health_systems`,
        payload,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(healthSystemKeys.list());
      },
    },
  );
}
