import cx from 'classnames';
import { format } from 'date-fns';
import first from 'lodash/first';
import { FormattedMessage } from 'react-intl';

import { Calendar } from '@/shared/assets/svgs';
import { getPbPatientDetailsStateInfo } from '@/shared/common/SchedulePatientModal/SmartScheduler/patient.utils';
import { UserAvatar } from '@/shared/common/UserAvatar';
import type { CareProviderDetails } from '@/shared/generated/grpcGateway/medication.pb';
import {
  usePatientDetails,
  useProviderStateLicensureByState,
} from '@/shared/hooks/queries';

import {
  calendarIcon,
  container,
  removeBottomBorder,
} from './approvingProviderDetails.css';

type Props = {
  patientId?: string;
  providerId?: string;
  provider: CareProviderDetails;
  approvalDate: Date;

  // in some read-only contexts, this component is the
  // last one in an already-bordered container and we
  // need to kill the bottom border so that they don't
  // overlap and look horrible
  withBottomBorder: boolean;
};

export function ApprovingProviderDetails({
  patientId,
  providerId,
  provider,
  approvalDate,
  withBottomBorder,
}: Props) {
  const { data: patient } = usePatientDetails(
    patientId ?? '',
    true,
    !!patientId,
  );
  const patientState = patient
    ? getPbPatientDetailsStateInfo(patient)?.abbreviation
    : undefined;
  const { data: licenseData } = useProviderStateLicensureByState(
    providerId ?? '',
    patientState,
  );
  const license = first(licenseData?.stateLicensure);
  const { givenName = '', familyName = '' } = provider;

  return (
    <div
      className={cx(container, {
        [removeBottomBorder]: !withBottomBorder,
      })}
    >
      <Calendar className={calendarIcon} />
      <FormattedMessage
        defaultMessage="{date} by {avatar} {fullName}{role, select, EMPTY {} other {, {role}}}"
        values={{
          date: format(approvalDate, "MMM do, yyyy 'at' h:mmaa"),
          avatar: (
            <UserAvatar
              firstName={givenName}
              lastName={familyName}
              size="small"
            />
          ),
          fullName: `${givenName} ${familyName}`,
          role: license?.licenseType || 'EMPTY',
        }}
      />
    </div>
  );
}
