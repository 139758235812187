import { format, fromUnixTime } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import type { TooltipProps } from 'recharts';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { hintContainer } from './VitalsScatterplotChart.css';

export function HintBody({ payload }: TooltipProps<ValueType, NameType>) {
  const [xItem, yItem] = payload as { value: number }[];

  return (
    <div className={hintContainer}>
      {xItem && yItem && (
        <>
          <div>
            <FormattedMessage
              defaultMessage="Date: {date}"
              values={{
                date: format(
                  fromUnixTime(xItem?.value),
                  'MM/dd/yyyy • hh:mm a',
                ),
              }}
            />
          </div>
          <div>
            <FormattedMessage
              defaultMessage="Reading: {reading}"
              values={{
                reading: yItem?.value,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
