**Cadence Encounter Type:**

Clinical visit

{{ regularVisitIntro data }}

{{#if (isRegularVisitPatientNoShow data) }}
{{ regularVisitPatientNoShow data intl }}
{{else}}
### HPI
{{> patient_summary }}

#### Recent Vital Trends:
{{> vitals_list }}

{{ regularVisitActionPlan data intl }}

{{ regularVisitSymptoms data rpmConditions intl }}

{{ regularVisitProductSupport data vitalsEngagement }}

{{ regularVisitMedicationReview data noteId patientMedications flags }}

{{ regularVisitGeneralAssessment data }}

{{ regularVisitEarlyEndCall data intl }}
{{/if}}
