import { validate } from 'jsonschema';

import type { Schema } from './Schema.types';
import type { DataObject } from './SchemaDrivenForm';
import { transformSchemaForRjsf } from './schemaTransform/transformSchemaForRjsf';

export function validateSchemaDrivenFormData(
  formData: DataObject,
  schema: Schema,
) {
  // Transforming the schema the same way as for form generation provides
  // validation results which match shown inputs the best.
  return validate(formData, transformSchemaForRjsf(schema, formData), {
    allowUnknownAttributes: false,
    skipAttributes: [
      // Need to disable validation of unknown attributes for "definitions" attribute
      // as there is a bug in jsonschema package, causing an error that it's
      // unknown attribute, where the definitions attribute is supported.
      // It's verified in unit tests for this function.
      'definitions',
      // Custom attribute added for validating time tracking
      'time_tracking_required',
    ],
  });
}
