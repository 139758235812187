import type {
  ValidationError as JsonSchemaValidationError,
  ValidatorResult as JsonSchemaValidatorResult,
} from 'jsonschema';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import last from 'lodash/last';
import uniqBy from 'lodash/uniqBy';
import type { IntlShape } from 'react-intl';
import { ValidationError as YupValidationError, mixed } from 'yup';

import type {
  DataObject,
  Schema,
  UiSchemaLabels,
} from '@/shared/common/@deprecated/SchemaDrivenForm/';
import {
  getUiLabel,
  validateSchemaDrivenFormData,
} from '@/shared/common/@deprecated/SchemaDrivenForm/';
import { formattedMessageToString } from '@/shared/utils/i18n';

import { validator } from './factory';

type Labels = UiSchemaLabels | Record<string, string>;

type JsonSchemaParams = {
  jsonSchema: Schema;
  labels: Labels;
};

export const jsonSchemaValidator = validator((intl, params: JsonSchemaParams) =>
  mixed().test((schemaDrivenFormData: DataObject) =>
    validateJsonSchemaData(intl, params, schemaDrivenFormData),
  ),
);

export function validateJsonSchemaData(
  intl: IntlShape,
  params: JsonSchemaParams,
  schemaDrivenFormData: DataObject,
) {
  const jsonSchemaValidationResult = validateSchemaDrivenFormData(
    schemaDrivenFormData,
    params.jsonSchema,
  );
  const yupValidationErrors = getYupValidationErrors(
    intl,
    params.labels,
    jsonSchemaValidationResult,
  );
  return yupValidationErrors.length
    ? new YupValidationError(yupValidationErrors)
    : true;
}

function getYupValidationErrors(
  intl: IntlShape,
  labels: Labels,
  validatorResult: JsonSchemaValidatorResult,
) {
  // - Filtering out 'allOf' errors as these just state that there are related
  // to it errors which are in the errors array.
  // - Filtering out 'dependencies' errors are these are duplicates of other
  // errors
  // - Deduplicating errors
  // which are duplicated in case of more complex schemas.
  const jsonSchemaErrors = uniqBy(
    validatorResult.errors.filter(
      (error) => !['allOf', 'dependencies'].includes(error.name),
    ),
    (error) => `${JSON.stringify(error.path)}-${error.message}`,
  );
  return jsonSchemaErrors.map((error) => {
    const yupError = new YupValidationError(
      getValidationMessage(intl, labels, error),
    );
    yupError.params = { argument: error.argument, name: error.name };
    return yupError;
  });
}

function getValidationMessage(
  intl: IntlShape,
  labels: Labels,
  error: JsonSchemaValidationError,
) {
  if (error.name === 'required') {
    const requiredPropertyName = error.argument;
    return isObject(error.schema)
      ? getRequiredErrorMessage(
          intl,
          labels,
          requiredPropertyName,
          error.schema.properties?.[requiredPropertyName],
        )
      : getRequiredErrorMessage(intl, labels, requiredPropertyName);
  }

  const propertyName = String(last(error.path));
  if (isObjectPropertyThatRequiresAtLeastOneTrueValue(error)) {
    return getRequiredErrorMessage(intl, labels, propertyName, error.schema);
  }

  return intl.formatMessage(
    {
      defaultMessage: '{propertyLabel} {errorMessage}',
    },
    {
      propertyLabel: getPropertyLabel(labels, propertyName, error.schema),
      errorMessage: error.message,
    },
  );
}

/**
 * See https://stackoverflow.com/a/31960040 for an explanation of this schema
 */
function isObjectPropertyThatRequiresAtLeastOneTrueValue(
  error: JsonSchemaValidationError,
) {
  return (
    error.name === 'not' &&
    isEqual(error.argument, { additionalProperties: { enum: [false] } })
  );
}

function getRequiredErrorMessage(
  intl: IntlShape,
  labels: Labels,
  propertyName: string,
  schema?: string | Schema,
) {
  const propertyLabel = getPropertyLabel(labels, propertyName, schema);

  if (propertyLabel.endsWith('s')) {
    return intl.formatMessage(
      { defaultMessage: '{propertyLabel} are required' },
      { propertyLabel },
    );
  }

  return intl.formatMessage(
    { defaultMessage: '{propertyLabel} is required' },
    { propertyLabel },
  );
}

function getPropertyLabel(
  labels: Labels,
  fieldName: string,
  schema?: string | Schema,
) {
  const uiLabel = labels[fieldName];
  const title = isObject(schema) ? schema.title : undefined;
  return typeof uiLabel === 'string'
    ? uiLabel
    : formattedMessageToString(getUiLabel(uiLabel, title));
}
