import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

import { mergeModuleInstanceInputs } from '@/pages/patients/patientDetails/ui/Notes/utils/encounterModuleUtils';
import { usePrevious } from '@/shared/hooks';

import type { Schema } from '../Schema.types';
import type { DataObject, DataValue } from '../SchemaDrivenForm';

/**
 * Populate default values and reinitialize form if initialFormData changes.
 *
 * The reinitialization behavior is similar to that of redux-form's
 * `enableReinitialize` config option, allowing the form to be externally
 * controllable in reaction to an event (e.g. user copy forwards a note)
 *
 * @param {Object} schema - JSON Schema for this form
 * @param {Function} onFormChange - Callback that populates the new initialized form data
 * @param {Object} initialFormData - Existing data to load initial values from (e.g. from a draft or from a copied note)
 */
export function useInitializeFormData<T extends DataObject>(
  schema: Schema,
  onFormChange: (inputs: T) => void,
  initialFormData?: T,
) {
  const prevInitialFormData = usePrevious(initialFormData);

  useEffect(() => {
    const defaultValues = getDefaultValuesForObject(schema);
    const newInputs = mergeModuleInstanceInputs(defaultValues, initialFormData);

    if (!isEqual(prevInitialFormData, initialFormData) || !initialFormData) {
      onFormChange(newInputs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData, prevInitialFormData]);
}

function getDefaultValuesForObject(schema: Schema): DataObject {
  if (!schema.properties) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(schema.properties)
      .filter(
        ([propertyName]) =>
          // Don't populate default values for `dependencies`, since the
          // dependency is triggered if the property is present
          // (e.g. for the Symptoms form, if {has_chf: false} the rjsf form
          // would render all dependent symptom properties)
          !schema.dependencies ||
          !Object.keys(schema.dependencies).includes(propertyName),
      )
      .map(([propertyName, propertySchema]) => [
        propertyName,
        getDefaultValues(propertySchema),
      ]),
  );
}

function getDefaultValues(schema: Schema): DataValue {
  // See Schema type definition for comments on how we use `default: null` to override the default values below
  if (schema.default === null) {
    return undefined;
  }

  if (schema.type === 'boolean') {
    return false;
  }
  if (schema.type === 'object') {
    return schema.properties ? getDefaultValuesForObject(schema) : undefined;
  }

  return undefined;
}
