// This file is a direct copy from
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
import { createContext, useContext } from 'react';

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A extends {} | null>(name: string) {
  const ctx = createContext<A | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (c === undefined)
      throw new Error(`useCtx: ${name} must be inside a Provider with a value`);
    return c;
  }
  return [useCtx, ctx.Provider, ctx.Consumer] as const; // 'as const' makes TypeScript infer a tuple
}
