import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const UnderlineIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9119 5V13.1114C15.9119 14.9329 14.6293 16.2443 12.5 16.2443C10.3707 16.2443 9.08812 14.9329 9.08812 13.1114V5H7.54406V13.2329C7.54406 15.7829 9.49904 17.6529 12.5 17.6529C15.501 17.6529 17.4559 15.7829 17.4559 13.2329V5H15.9119Z"
        fill="#3B3A45"
      />
      <path d="M6 20.8343H19V22H6V20.8343Z" fill="#3B3A45" />
    </svg>
  </SvgIcon>
);

export default UnderlineIcon;
