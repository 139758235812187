import { Active } from './Active';
import { Breadcrumbs as BreadcrumbsSubcomponent } from './Breadcrumbs';
import { Link, RouterLink } from './Link';

// Export Breadcrumbs with subcomponents (i.e. Form.Link, Form.RouterLink, etc...)
export const Breadcrumbs = Object.assign(BreadcrumbsSubcomponent, {
  Link,
  RouterLink,
  Active,
});
