import cx from 'classnames';
import type { Key } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import type { AriaTabListProps } from 'react-aria';
import { useTabList } from 'react-aria';
import { useTabListState } from 'react-stately';

import { Tab } from './Tab';
import { TabPanel } from './TabPanel';
import { activeTabIndicator, tabs } from './Tabs.css';

type Props<T> = {
  className?: string;
  onTabClick?: (key: Key) => void;
} & AriaTabListProps<T>;

export function Tabs<T extends object>({
  className,
  onTabClick,
  ...props
}: Props<T>) {
  const state = useTabListState(props);
  const ref = useRef<HTMLDivElement>(null);
  const { tabListProps } = useTabList(props, state, ref);
  const [activeTabStyle, setActiveTabStyle] = useState({
    width: 0,
    transform: 'translateX(0)',
  });

  useLayoutEffect(() => {
    const activeTab = ref.current?.querySelector<HTMLDivElement>(
      '[role=tab][aria-selected=true]',
    );

    setActiveTabStyle({
      width: activeTab?.offsetWidth ?? 0,
      transform: `translateX(${activeTab?.offsetLeft ?? 0}px`,
    });

    // state.collection is included here because we have variable width
    // tab titles and want to make sure to update the indicator when the
    // tab title changes.
  }, [state.selectedKey, state.collection]);

  return (
    <>
      <div {...tabListProps} ref={ref} className={cx(tabs, className)}>
        {[...state.collection].map((item) => (
          <Tab key={item.key} item={item} state={state} onClick={onTabClick} />
        ))}
        <span
          className={activeTabIndicator}
          style={{
            ...activeTabStyle,
          }}
        />
      </div>
      {[...state.collection].map((item) => (
        <TabPanel key={`panel-${item.key}`} state={state} item={item} />
      ))}
    </>
  );
}
