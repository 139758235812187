import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import { CHF_VISIT_SECTIONS } from './chfVisit';
import { CYCLE_17_INIT_VISIT_SECTIONS } from './cycle17InitialVisit';
import { INIT_VISIT_SECTIONS } from './initialVisit';
import { PATIENT_FOLLOW_UP_SECTIONS } from './patientFollowUp';
import { REGULAR_VISIT_SECTIONS } from './regularVisit';
import { TITRATION_OUTREACH_SECTIONS } from './titrationOutreachVisit';

export {
  INIT_VISIT_SECTIONS,
  REGULAR_VISIT_SECTIONS,
  CHF_VISIT_SECTIONS,
  PATIENT_FOLLOW_UP_SECTIONS,
  CYCLE_17_INIT_VISIT_SECTIONS,
  TITRATION_OUTREACH_SECTIONS,
};

export const CN_SECTIONS_MAP = {
  [TypeOfEncounter.INITIAL_CN_VISIT]: INIT_VISIT_SECTIONS,
  [TypeOfEncounter.CN_VISIT]: REGULAR_VISIT_SECTIONS,
  [TypeOfEncounter.INITIAL_CN_CHF_VISIT]: CHF_VISIT_SECTIONS,
  [TypeOfEncounter.CN_CHF_VISIT]: CHF_VISIT_SECTIONS,
  [TypeOfEncounter.PATIENT_FOLLOW_UP]: PATIENT_FOLLOW_UP_SECTIONS,
  [TypeOfEncounter.CYCLE_17_INITIAL_CN_VISIT]: CYCLE_17_INIT_VISIT_SECTIONS,
  [TypeOfEncounter.CN_TITRATION_OUTREACH]: TITRATION_OUTREACH_SECTIONS,
};

export type CNSectionMap =
  | typeof INIT_VISIT_SECTIONS
  | typeof REGULAR_VISIT_SECTIONS
  | typeof CHF_VISIT_SECTIONS
  | typeof CYCLE_17_INIT_VISIT_SECTIONS
  | typeof TITRATION_OUTREACH_SECTIONS;
