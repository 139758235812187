import type { HTMLAttributes } from 'react';

import { resultTableRow } from './results.css';

type Props = HTMLAttributes<HTMLTableRowElement> & {
  onSelected?: () => void;
  optionLabel?: string;
};

// sometimes optionLabel makes it down to the DOM and causes
// react to complain about unknown props. we aren't using it
// here, but we're pulling it off of `props` so that react
// doesn't complain.
export function ResultRow({
  optionLabel,
  onClick,
  onSelected,
  children,
  ...props
}: Props) {
  return (
    <tr
      {...props}
      className={resultTableRow}
      onClick={(event) => {
        // onClick and onSelected look redundant, but onClick is
        // used internally by mui whereas onSelected is exposed as
        // the public API for this wrapper.
        onSelected?.();
        onClick?.(event);
      }}
    >
      {children}
    </tr>
  );
}
