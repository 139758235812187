import {
  type ChangeEvent,
  type ChangeEventHandler,
  Children,
  type ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

import CloseCircle from '@/shared/assets/svgs/closeCircle.svg?react';
import { Form, Fields as FormFields } from '@/shared/common/Form';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { color } from '@/shared/tempo/theme';

import { removeButton } from './RemovableRow.css';

type Props = {
  children: ReactNode;
  hasError: boolean;
  enabled?: boolean;
  onRemove: () => void;
  onChange?: ChangeEventHandler<unknown>;
};

type WithOptionalOnChange<P> = P & {
  onChange?: ChangeEventHandler<unknown>;
};

export function RemovableRow({
  children,
  hasError,
  enabled = true,
  onRemove,
  onChange,
}: Props) {
  if (!enabled) {
    return <Form.Row>{children}</Form.Row>;
  }

  /**
   * Ensures that any nested child Form field component will invoke the onChange
   * function from the parent RemovableRow
   */
  function injectOnChange(c: ReactNode): ReactNode {
    return Children.map(c, (child) => {
      if (isValidElement<Record<string, unknown>>(child)) {
        const isFormField =
          typeof child.type === 'function' &&
          Object.values(FormFields)
            .map((f) => f.name)
            .includes(child.type.name);

        if (isFormField) {
          const originalOnChange = child.props.onChange as Maybe<
            ChangeEventHandler<unknown>
          >;
          return cloneElement<WithOptionalOnChange<typeof child.props>>(child, {
            // Inject new on change that call both RemovableRow's onChange, as well
            // as the original onChange if present
            onChange: (event: ChangeEvent<unknown>) => {
              onChange?.(event);
              originalOnChange?.(event);
            },
          });
        }

        // If the child has its own children, recursively search and inject into them
        if (child.props && child.props.children) {
          return cloneElement(child, {
            children: injectOnChange(child.props.children),
          });
        }
      }
      return child;
    });
  }

  const childrenWithOnChange = onChange ? injectOnChange(children) : children;

  return (
    <Form.Row>
      {childrenWithOnChange}
      <IconButton
        variant="tertiary"
        size="small"
        onPress={onRemove}
        className={hasError ? removeButton.error : removeButton.default}
      >
        <CloseCircle fill={color.Theme.Light.Danger} />
      </IconButton>
    </Form.Row>
  );
}
