/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react';

import { getRumSessionUrl } from '@/monitoring.utils';

import type { LogEntry } from '../logger.types';
import { LogEmitter } from './LogEmitter';

export class SentryEmitter extends LogEmitter {
  // Send errors to Sentry
  error(entry: LogEntry, error?: Error): void {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          message: entry.message,
          ddRumSessionUrl: getRumSessionUrl(),
        },
      });
    } else {
      Sentry.captureMessage(entry.message, Sentry.Severity.Error);
    }
  }

  /* All Other Log Methods Are No Ops */

  debug(): void {}

  info(): void {}

  warn(): void {}
}
