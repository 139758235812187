import type { Condition } from '@/shared/types/clinicalprofile.types';

export function parseConditions(conditions?: string | Condition[]) {
  if (Array.isArray(conditions)) {
    return conditions;
  }
  return (conditions?.split(',') || []) as Condition[];
}

export function serializeConditions<T>(conditions?: Maybe<T[]>) {
  if (Array.isArray(conditions)) {
    return conditions.sort().join(',');
  }
  return conditions;
}

export const isManualConditionSelectionAllowed = (
  allowManualPatientRpmConditionSelection: string[],
  healthSystemId?: string,
) =>
  Boolean(
    allowManualPatientRpmConditionSelection.includes('all') ||
      (healthSystemId &&
        allowManualPatientRpmConditionSelection.includes(healthSystemId)),
  );
