import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { resultTableHeaderCell } from './results.css';

export function ResultHeaderCell({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLTableCellElement>) {
  return (
    <th {...props} className={cx(resultTableHeaderCell, className)}>
      {children}
    </th>
  );
}
