import cx from 'classnames';
import type { HTMLAttributes, ReactElement } from 'react';
import { cloneElement } from 'react';

import { iconContainer, tag } from './Tag.css';

export type TagVariant =
  | 'default'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'error'
  | 'unstyled';

export type Props = {
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  variant?: TagVariant;
} & HTMLAttributes<HTMLDivElement>;

const SVG_SIZE = { width: 16, height: 16 };

export function Tag({
  children,
  className,
  leftIcon,
  rightIcon,
  variant = 'default',
  ...props
}: Props) {
  return (
    <div {...props} className={cx(tag[variant], className)}>
      {leftIcon && (
        <div className={iconContainer.left}>
          {cloneElement(leftIcon, SVG_SIZE)}
        </div>
      )}
      {children}
      {rightIcon && (
        <div className={iconContainer.right}>
          {cloneElement(rightIcon, SVG_SIZE)}
        </div>
      )}
    </div>
  );
}
