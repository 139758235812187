import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { validators } from '@/shared/common/Form/validations';
import { Modal } from '@/shared/common/Modal';
import type { EHRCredentialsSchema } from '@/shared/generated/api/pms';
import { useCreateEhrHealthSystemProviderCredentials } from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { EHR } from '@/shared/types/ehr.types';
import type { Hospital } from '@/shared/types/hospital.types';
import type { EhrCredentials } from '@/shared/utils/rpa-helpers';

type Props = {
  open: boolean;
  onClose: () => void;
  patientHospital: Hospital;
  onSave: () => void;
};

function useFormConfig(): FormConfig {
  const intl = useIntl();
  const { required } = validators(intl);

  return {
    fields: {
      username: {
        validation: required(yup.string()),
      },
      password: {
        validation: required(yup.string()),
      },
    },
  };
}

export function EhrCredentialsModal({
  onClose,
  open,
  patientHospital,
  onSave,
}: Props) {
  const form = useFormFromConfig<EhrCredentials>(useFormConfig());
  const intl = useIntl();
  const { toaster } = useToaster();
  const { currentUserId } = useCurrentUser();
  const healthSystemId = patientHospital.health_system_id.toString();
  const createMutation = useCreateEhrHealthSystemProviderCredentials(
    currentUserId,
    healthSystemId,
  );

  const handleSubmit = useCallback(
    (values: EhrCredentials) => {
      const requestBody: EHRCredentialsSchema = {
        ...values,
        ehr: patientHospital.ehr_information?.ehr || EHR.Athena,
      };

      createMutation.mutate(requestBody, {
        onSuccess: () => {
          toaster.success(
            intl.formatMessage({
              defaultMessage: `Login saved`,
            }),
          );
          onClose();
          onSave();
        },
      });
    },
    [createMutation, patientHospital, toaster, intl, onClose, onSave],
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header
        title={
          <FormattedMessage defaultMessage="Save EHR login for Cadence RPA" />
        }
      />
      <Form form={form} onSubmit={handleSubmit}>
        <Modal.Body>
          <>
            <FormattedMessage defaultMessage="In order to enable Cadence RPA for external EHR systems, we need to save login credentials. This is a one-time operation on first interaction." />
            <Form.Row>
              <Form.TextField
                size={12}
                name="username"
                label={<FormattedMessage defaultMessage="Username" />}
              />
            </Form.Row>
            <Form.Row>
              <Form.TextField
                size={12}
                name="password"
                inputType="password"
                label={<FormattedMessage defaultMessage="Password" />}
              />
            </Form.Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="ehr-credentials-save"
            type="button"
            variant="secondary"
            onPress={onClose}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          <Button
            id="ehr-credentials-save"
            isDisabled={!form.canSubmit}
            type="submit"
            variant="primary"
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
