import cx from 'classnames';
import flatten from 'lodash/flatten';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Option } from '@/shared/common/Select';
import type {
  ReferenceMedication,
  RxNorm,
} from '@/shared/generated/grpcGateway/medication.pb';
import {
  ReferenceMedicationMedicationClass,
  RxNormDeliveryMechanism,
} from '@/shared/generated/grpcGateway/medication.pb';
import { flexAlignItems, flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Tag } from '@/shared/tempo/atom/Tag';

import { getRxNormStr } from '../utils/medInfoUtils';
import {
  autocompleteOption,
  existingLabel,
  medClassLabel,
  rxnormLabel,
} from './RxNormInput.css';
import {
  medClassLabels,
  rxNormDeliveryMechanismLabels,
} from './formFieldLabels';
import { sortRxNormOptions } from './medClassUtil';
import { medFormInput, proposalTag } from './styles.css';
import type { RxNormOptionType } from './types';
import { RxNormRestriction } from './types';

type Props = {
  referenceMeds?: ReferenceMedication[];
  referenceId?: string;
  existingReferencedMedIds?: Maybe<string>[];
  rxNormRestriction?: RxNormRestriction;
  proposedRxNorm?: RxNorm;
};

// TODO: Implement search request once implemented on endpoint rather than rely on all reference meds being fetched up front
// https://cadencerpm.atlassian.net/browse/PLAT-6359
export function RxNormInput({
  referenceMeds,
  referenceId,
  existingReferencedMedIds,
  rxNormRestriction,
  proposedRxNorm,
}: Props) {
  const intl = useIntl();
  const fieldLabel = intl.formatMessage({
    defaultMessage: 'Medication + dosage',
  });

  const form = useFormContext();

  // Make this autocomplete open state controlled so we can close the options menu on blur
  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const getOptionLabel = getOptionLabelFn(intl);

  const referencedMedOptions = getOptions(
    referenceMeds,
    rxNormRestriction,
    referenceId,
    existingReferencedMedIds,
  );

  if (
    rxNormRestriction &&
    [
      RxNormRestriction.CURRENT_MED,
      RxNormRestriction.NON_EXISTING_MEDS,
    ].includes(rxNormRestriction) &&
    referenceId
  ) {
    return (
      <Form.Select
        label={fieldLabel}
        name="rxnormId"
        size={12}
        items={referencedMedOptions}
      >
        {(opt) => (
          <Form.Select.Option key={opt.norm.id}>
            <span
              className={cx(flexContainer.row, flexAlignItems.center, gap.XS)}
            >
              {proposedRxNorm?.id === opt.norm.id && (
                <Tag variant="info" className={proposalTag}>
                  <FormattedMessage defaultMessage="Proposed" />
                </Tag>
              )}
              {getOptionLabel(opt)}
            </span>
          </Form.Select.Option>
        )}
      </Form.Select>
    );
  }

  const allowFreeTextInput =
    rxNormRestriction ===
      RxNormRestriction.NON_EXISTING_MEDS_AND_CURRENT_FREE_TEXT ||
    rxNormRestriction === RxNormRestriction.NON_EXISTING_MEDS_FREE_TEXT;
  const autocompleteOptions = [
    ...referencedMedOptions,
    ...(inputValue && allowFreeTextInput ? [{ inputValue }] : []),
  ];
  return (
    // TODO: remove med class string from input once selected
    // https://cadencerpm.atlassian.net/browse/PLAT-6286
    <Form.Autocomplete
      classes={{ input: medFormInput }}
      name="rxnorm"
      label={fieldLabel}
      placeholder={intl.formatMessage({
        defaultMessage: 'e.g. Amlodopine 5mg',
      })}
      disableCloseOnSelect={false}
      options={autocompleteOptions}
      size={12}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) =>
        option.norm?.id === value.norm?.id
      }
      getOptionDisabled={(option) => Boolean(option.disabled)}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      noOptionsText={<FormattedMessage defaultMessage="No results found" />}
      renderOption={(props, option) => {
        if (option.inputValue) {
          return (
            <Option {...props} className={autocompleteOption}>
              <FormattedMessage
                defaultMessage={"Press Enter to add ''{inputValue}''"}
                values={{ inputValue: option.inputValue }}
              />
            </Option>
          );
        }
        return (
          <Option
            {...props}
            key={option.norm?.id}
            className={autocompleteOption}
            disabled={Boolean(option.disabled)}
          >
            <div className={rxnormLabel}>{getRxnormLabel(intl, option)}</div>
            {option.disabled && (
              <div className={existingLabel}>
                <FormattedMessage defaultMessage="Existing" />
              </div>
            )}
            <Tag className={medClassLabel}>
              {getMedClassesLabel(intl, option.medClasses)}
            </Tag>
          </Option>
        );
      }}
      onKeyDown={(e) => {
        const prevInput = (e.target as HTMLInputElement).value;

        // If allowFreeTextInput is on, set the value instead of clearing on blur
        if (allowFreeTextInput && e.key === 'Enter') {
          e.preventDefault();
          form.setValue('rxnorm', {
            inputValue: prevInput,
          });
          setOpen(false);
          form.trigger('rxnorm');
          return;
        }

        // Note: This needs to happen on keydown so that we update the options by the time the MUI autocomplete checks whether the input value is an option. If we do it on key up, there is a flash of the option not matching
        if (e.key.length === 1) {
          setInputValue(prevInput + e.key);
        }
        if (e.key === 'Backspace') {
          setInputValue(prevInput.slice(0, -1));
        }
      }}
      popupIcon={null}
    />
  );
}

function getMedClassesLabel(
  intl: IntlShape,
  medClasses?: ReferenceMedicationMedicationClass[],
) {
  const medClassesI18nMap = medClassLabels(intl);
  return medClasses
    ?.map((m) => {
      if (
        m === ReferenceMedicationMedicationClass.MEDICATION_CLASS_UNSPECIFIED
      ) {
        return '';
      }
      return medClassesI18nMap[m];
    })
    .join(', ');
}

function getRxnormLabel(intl: IntlShape, option: RxNormOptionType) {
  const { norm } = option;

  const deliveryMechanismLabel =
    norm?.deliveryMechanism &&
    norm?.deliveryMechanism !==
      RxNormDeliveryMechanism.DELIVERY_MECHANISM_UNSPECIFIED
      ? rxNormDeliveryMechanismLabels(intl)[
          norm?.deliveryMechanism
        ].toLowerCase()
      : '';
  const rxnormStr = norm ? getRxNormStr(norm, intl) : '';

  return `${rxnormStr} ${deliveryMechanismLabel}`;
}

function getOptions(
  refMeds?: ReferenceMedication[],
  rxNormRestriction?: RxNormRestriction,
  referenceId?: Maybe<string>,
  existingReferencedMedIds?: Maybe<string>[],
) {
  if (!refMeds) {
    return [];
  }

  let filteredRefMeds;
  let disabledRefMeds: Maybe<string>[];
  if (rxNormRestriction === RxNormRestriction.CURRENT_MED && referenceId) {
    filteredRefMeds = refMeds?.filter((m) => m.id === Number(referenceId));
    disabledRefMeds = [];
  } else if (
    rxNormRestriction === RxNormRestriction.NON_EXISTING_MEDS ||
    rxNormRestriction === RxNormRestriction.NON_EXISTING_MEDS_FREE_TEXT
  ) {
    filteredRefMeds = refMeds;
    disabledRefMeds = existingReferencedMedIds || [];
  } else if (
    rxNormRestriction ===
    RxNormRestriction.NON_EXISTING_MEDS_AND_CURRENT_FREE_TEXT
  ) {
    filteredRefMeds = refMeds;
    disabledRefMeds = (existingReferencedMedIds || []).filter(
      (v) => v !== referenceId,
    );
  } else {
    filteredRefMeds = refMeds;
    disabledRefMeds = [];
  }
  const rxNormOptions = filteredRefMeds.map((m) => {
    const disabledRefMed = disabledRefMeds.includes((m.id || '').toString());
    return getRxNormOptions(m, disabledRefMed);
  });
  return flatten(rxNormOptions).sort(sortRxNormOptions);
}
function getRxNormOptions(med: ReferenceMedication, disabledMed: boolean) {
  return (
    med.rxNorms?.map((norm) => ({
      medClasses: med.medClasses,
      norm,
      disabled: disabledMed,
    })) || []
  );
}

function getOptionLabelFn(intl: IntlShape) {
  return function getOptionLabel(option: RxNormOptionType) {
    // TODO: clean up id check logic https://cadencerpm.atlassian.net/browse/PLAT-6388
    if (!option.norm?.id || option.norm?.id === '0') {
      return option.inputValue || '';
    }

    let label = getRxnormLabel(intl, option);
    const medClassesLabel = getMedClassesLabel(intl, option.medClasses);
    if (medClassesLabel) {
      label = `${label} [${medClassesLabel}]`;
    }

    return label;
  };
}
