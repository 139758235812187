import cx from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import { hide, pulsate, skeleton } from './Skeleton.css';

type Props = {
  isLoading?: boolean;
  variant?: 'text' | 'rectangular' | 'circular';
  className?: string;
  children?: ReactNode;
  height?: string | number;
  width?: string | number;
} & HTMLAttributes<HTMLSpanElement>;

export const Skeleton = forwardRef<HTMLSpanElement, Props>(
  (
    {
      variant = 'text',
      className,
      width,
      height,
      children,
      isLoading,
      ...props
    },
    forwardedRef,
  ) => {
    if (children && !isLoading) {
      return <>{children}</>;
    }
    return (
      <span
        ref={forwardedRef}
        className={cx(skeleton[variant], pulsate, className)}
        style={{ ...(height && { height }), ...(width && { width }) }}
        {...props}
      >
        {children && <span className={hide}>{children}</span>}
      </span>
    );
  },
);
