import { format, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Typography } from '@/deprecated/mui';
import { PatientAlertStatusIcon } from '@/pages/patients/PatientListDashboard/PatientsTable/PatientAlertsStatusIcon';
import { PatientProfileDialogTriggerButton } from '@/pages/patients/PatientProfile/PatientProfileDialogTriggerButton';
import { flexSection } from '@/shared/jsStyle';
import type { VitalsAlert } from '@/shared/types/alert.types';

import { MarkAlertAsReviewedDialog } from './MarkAlertAsReviewedDialog';
import { fullWidthRow } from './PatientAlertDetail.styles.css';

export const AlertDescriptionHeader = ({
  alert,
  isProfilePage,
  onMarkAsReviewed,
}: {
  alert: VitalsAlert;
  isProfilePage: boolean;
  onMarkAsReviewed: (alert: VitalsAlert, closeDialog: () => void) => void;
}) => {
  const intl = useIntl();

  const alertDate = alert.patient_day;
  return (
    <>
      <Box sx={fullWidthRow}>
        <Typography
          style={{
            fontSize: '14px',
            color: '#575768',
            whiteSpace: 'nowrap',
          }}
        >
          <span>{format(parseISO(alertDate), 'MM/dd/yyyy')}</span>
        </Typography>
        {!isProfilePage && (
          <PatientProfileDialogTriggerButton
            buttonInfo={{
              label: intl.formatMessage({
                defaultMessage: 'Mark as reviewed',
              }),
              variant: 'tertiary',
            }}
          >
            {({ isDialogOpen, closeDialog }) => (
              <MarkAlertAsReviewedDialog
                isDialogOpen={isDialogOpen}
                closeDialog={closeDialog}
                onMarkAsReviewed={() => onMarkAsReviewed(alert, closeDialog)}
              />
            )}
          </PatientProfileDialogTriggerButton>
        )}
      </Box>
      {isProfilePage && (
        <Box
          sx={{
            ...flexSection('row', 'flex-start', 'center'),
            padding: '8px 0 12px',
          }}
        >
          <PatientAlertStatusIcon alert={alert} />
          <Typography
            style={{
              fontSize: '18px',
              color: '#3B3A45',
              paddingLeft: 5,
            }}
          >
            <FormattedMessage defaultMessage="Abnormal Vital" />
          </Typography>
        </Box>
      )}
    </>
  );
};
