import { differenceInMinutes, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { useCommunicationTypeMap } from '@/pages/patients/patientDetails/ui/shared/i18n/useCommunicationTypeMap';
import AlertIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import {
  CommunicationType,
  type MonitoringSession,
} from '@/shared/types/monitoringSession.types';
import { type Note } from '@/shared/types/note.types';

import {
  alertIcon,
  container,
  heading,
  list,
} from './OldTimeEntryEditWarning.css';

export function OldTimeEntryEditWarning({
  timeEntry,
  timeEntryNote,
}: {
  timeEntry: MonitoringSession;
  timeEntryNote: Note;
}) {
  const communicationTypeI18n = useCommunicationTypeMap();
  const duration = differenceInMinutes(
    parseISO(timeEntry.end_datetime),
    parseISO(timeEntry.start_datetime),
  );
  const communicationType = CommunicationType.PhoneAndSms;
  return (
    <div className={container}>
      <p className={heading}>
        <AlertIcon className={alertIcon} />
        <FormattedMessage defaultMessage="To edit this time tracking entry, which was created in an older version, please fill the correct information in the fields below." />
      </p>
      <ul className={list}>
        <li>
          <FormattedMessage
            defaultMessage="Duration: {duration} mins"
            values={{ duration }}
          />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="Communication type: {communicationType}"
            values={{
              communicationType: communicationTypeI18n[communicationType],
            }}
          />
        </li>
        <li>
          <FormattedMessage
            defaultMessage="Note: {note}"
            values={{ note: timeEntryNote.body }}
          />
        </li>
      </ul>
    </div>
  );
}
