import type { IntlShape } from 'react-intl';

import { logger } from '@/logger';
import type { PatientClinicalProfile } from '@/shared/generated/grpcGateway/pms.pb';
import { ReadingDataType } from '@/shared/types/patientSummary.types';
import type {
  AllDryWeightTagType,
  AllWeightGainTagType,
} from '@/shared/types/tagsAndThreshold.types';
import {
  DryWeightTagType,
  WeightGainTagType,
  WeightTagThresholdKey,
} from '@/shared/types/tagsAndThreshold.types';
import type { WeightVital } from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';
import { convertToGrams, convertToLbs } from '@/shared/utils/unit-helpers';

import type {
  AlertLoggerInfo,
  AlertMessageFormatterProps,
  DryWeightFormatterProps,
  RapidGainFormatterProps,
  WeeklyGainFormatterProps,
} from '../../AlertDescription.types';
import { WeightFormatter } from '../alertDescriptionFormatters';
import {
  isWeeklyWeightGainRelatedTags,
  isWeightGainRelatedTags,
} from '../alertTagsUtil';
import type {
  AlertDescriptionStrategy,
  TagRelatedValuesRequest,
} from '../baseAlertDescriptionStrategy';
import { BaseAlertDescriptionStrategy } from '../baseAlertDescriptionStrategy';
import {
  getComparisonOperator,
  isNDayBeforeTargetDate,
} from '../sharedAlertsUtils';

export class WeightAlertStrategy
  extends BaseAlertDescriptionStrategy<
    AllWeightGainTagType | AllDryWeightTagType,
    WeightVital
  >
  implements AlertDescriptionStrategy
{
  constructor(
    private patientProfile: Maybe<PatientClinicalProfile>,
    intl: IntlShape,
  ) {
    super(
      VitalType.Weight,
      [...Object.values(WeightGainTagType), ...Object.values(DryWeightTagType)],
      new WeightFormatter(intl),
      ReadingDataType.Weight,
    );
  }

  protected getTagRelatedValues(
    request: TagRelatedValuesRequest<AllWeightGainTagType, WeightVital>,
    contextReadings: WeightVital[],
    alertLoggerInfo: AlertLoggerInfo,
  ): AlertMessageFormatterProps {
    if (isWeightGainRelatedTags(request.tag)) {
      return this.getWeightGainRelatedValue(
        request.tag,
        contextReadings,
        request.relatedReading,
        alertLoggerInfo,
      );
    }

    const dryWeight = convertToLbs(this.patientProfile?.dryWeight || 0);

    if (!dryWeight) {
      logger.warn(
        `Getting dry weight alert:${alertLoggerInfo.alertId} but patient:${alertLoggerInfo.patientId} has no dry weight! Using default value 150 lbs`,
      );
    }

    return WeightAlertStrategy.getDryWeightChangeRelatedValue(
      convertToGrams(dryWeight || 150),
      request.relatedReading,
    );
  }

  protected tagThresholdLookUp(
    tag: AllWeightGainTagType,
    patientId: string,
  ): string {
    if (tag.includes('_GAIN')) {
      return WeightTagThresholdKey.GainP0;
    }
    if (tag.includes('_LOSS')) {
      return WeightTagThresholdKey.LossP1;
    }

    return super.tagThresholdLookUp(tag, patientId);
  }

  private static getDryWeightChangeRelatedValue(
    dryWeight: number,
    relatedReading: WeightVital,
  ): DryWeightFormatterProps {
    return {
      operator: getComparisonOperator(relatedReading.weight, dryWeight, false),
      values: {
        delta: Math.max(
          Math.abs(relatedReading.weight - dryWeight),
          45.3592, // 1g this likely won't happen adding here just to be safe
        ),
        currentReading: relatedReading.weight,
        threshold: dryWeight,
      },
    };
  }

  private getWeightGainRelatedValue(
    tag: AllWeightGainTagType,
    vitals: WeightVital[],
    relatedReading: WeightVital,
    alertLoggerInfo: AlertLoggerInfo,
  ): RapidGainFormatterProps | WeeklyGainFormatterProps {
    const thresholdValue = this.getThresholdValue(
      tag,
      relatedReading,
      alertLoggerInfo,
    );

    const dayBeforeReading =
      WeightAlertStrategy.getLatestReadingOnNDayBeforeCurrentDate(
        vitals,
        relatedReading.timestamp,
        1,
      );

    const twoDaysBeforeReadings =
      WeightAlertStrategy.getLatestReadingOnNDayBeforeCurrentDate(
        vitals,
        relatedReading.timestamp,
        2,
      );

    const sevenDaysBeforeReadings =
      WeightAlertStrategy.getLatestReadingOnNDayBeforeCurrentDate(
        vitals,
        relatedReading.timestamp,
        7,
      );

    return isWeeklyWeightGainRelatedTags(tag)
      ? {
          values: {
            threshold: thresholdValue,
            currentReading: relatedReading.weight,
            initialReading: sevenDaysBeforeReadings?.weight,
          },
          operator: '',
        }
      : {
          values: {
            threshold: thresholdValue,
            currentReading: relatedReading.weight,
            dayBeforeReading: dayBeforeReading?.weight,
            initialReading: twoDaysBeforeReadings?.weight,
          },
          operator: '',
        };
  }

  private static getLatestReadingOnNDayBeforeCurrentDate(
    vitals: WeightVital[], // Can use function overload for other types as well as of now only weight needs this function
    currentDate: string, // PatientTimeZoneDate in ISO
    nDayBefore: number,
  ) {
    return vitals
      .filter((vitalReading) =>
        isNDayBeforeTargetDate(vitalReading.timestamp, currentDate, nDayBefore),
      )
      .sort(
        (x, y) =>
          new Date(y.timestamp).valueOf() - new Date(x.timestamp).valueOf(),
      )[0];
  }
}
