import { useEffect, useState } from 'react';

import type { Hospital, Provider } from '@/shared/types/staff.types';
import Session from '@/shared/utils/session';

type StaffResponse = {
  clinicians: Provider[];
  hospitals: Hospital[];
  referring_physician: Provider;
};

export function useStaff(
  patientId: string,
): [boolean, unknown, StaffResponse | undefined] {
  const [staff, setStaff] = useState<StaffResponse>();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    Session.Api.get<StaffResponse>(`/pms/api/v1/contacts/${patientId}/staff`)
      .then((response) => {
        setStaff(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, [patientId]);

  return [isLoading, error, staff];
}
