export type GoalDiscussFormFields = {
  actionPlanNotes: string;
};

export function getGoalDiscussFormConfig() {
  return {
    fields: {
      actionPlanNotes: {
        defaultValue: undefined,
      },
    },
  };
}
