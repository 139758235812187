import cx from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import { stack } from './Stack.css';

type Props = {
  className?: string;
  children: ReactNode[];
} & HTMLAttributes<HTMLDivElement>;

export const Stack = forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...props }, ref) => (
    <div {...props} ref={ref} className={cx(stack, className)}>
      {children}
    </div>
  ),
);
