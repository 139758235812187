import { useHistory } from 'react-router-dom';

import { useQueryParams } from './useQueryParams';

const noop = () => {};

// openScheduler is here to allow us to navigate to a patient profile with
// the scheduling modal open. as of the time of this comment, it's only being
// used from within our regal.io instance
export function useShouldAutoOpenScheduler() {
  const queryParams = useQueryParams();
  const history = useHistory();
  const shouldAutoOpenScheduler = queryParams.has('openScheduler');

  return {
    shouldAutoOpenScheduler,
    onCloseScheduler: shouldAutoOpenScheduler
      ? () => history.push({ search: '' })
      : noop,
  };
}
