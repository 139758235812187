import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { initVisitPath } from '../../initialVisit/paths';
import { REGULAR_VISIT_SECTIONS } from '../../metadata';
import { LastDiscussed } from '../../shared/LastDiscussed';
import { TobaccoUseForm } from '../../shared/generalAssessment';
import type { TobaccoUseFormFields } from '../../shared/generalAssessment/formConfig';
import { getTobaccoUseFormConfig } from '../../shared/generalAssessment/formConfig';
import { regVisitPath } from '../paths';

export function TobaccoUse() {
  const intl = useIntl();
  const history = useHistory();
  const path = regVisitPath('/general-assessment', '/tobacco-use');
  const { isLoading, data } = useRecentNoteFormData<TobaccoUseFormFields>([
    path,
    initVisitPath('/general-assessment', '/tobacco-use'),
  ]);
  const form = useWizardFormFromConfig<TobaccoUseFormFields>(path, {
    ...getTobaccoUseFormConfig(intl, data),
    triggerReset: !isLoading,
  });

  return (
    <Wizard.Step
      sections={REGULAR_VISIT_SECTIONS}
      isLoading={isLoading}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Tobacco Use' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => history.push(regVisitPath('/general-assessment', '/diet'))}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="I know we reviewed some of your diet and lifestyle history at your last visit. Today, we're briefly going to touch on those and document any changes." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="As a reminder, the reason we go over this is because diet and lifestyle are very important factors to your health." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="They can be one of the most impactful ways we can work together to meet your health goals." />
        </Script>
        <LastDiscussed hasData={false}>
          <TobaccoUseForm form={form} />
        </LastDiscussed>
      </FieldGroup>
    </Wizard.Step>
  );
}
