import type { ReactNode } from 'react';

import { EllipseIcon } from '@/shared/assets/icons/Ellipse';

import type { BaseCellProps } from './BaseCell';
import { BaseCell } from './BaseCell';
import { booleanCell } from './cells.css';

type Props = {
  children: Maybe<boolean>;
  trueLabel: ReactNode;
  falseLabel: ReactNode;
} & BaseCellProps;

export function BooleanCell({
  children,
  trueLabel,
  falseLabel,
  ...baseProps
}: Props) {
  return (
    <BaseCell {...baseProps}>
      <div className={booleanCell.wrapper}>
        <EllipseIcon
          className={children ? booleanCell.dot.on : booleanCell.dot.off}
        />
        {children ? trueLabel : falseLabel}
      </div>
    </BaseCell>
  );
}
