import type { SvgIconProps } from '@/deprecated/mui';
import { SvgIcon } from '@/deprecated/mui';

const ItalicIcon = (props: SvgIconProps) => (
  <SvgIcon fontSize="inherit" {...props} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 19H11.5375L13.2052 8.83743H11.6678L10 19ZM12.9055 7.1172C13.5049 7.1172 14 6.64083 14 6.0586C14 5.47637 13.5049 5 12.9055 5C12.3062 5 11.8111 5.47637 11.8111 6.0586C11.8111 6.64083 12.3062 7.1172 12.9055 7.1172Z"
        fill="#3B3A45"
      />
    </svg>
  </SvgIcon>
);

export default ItalicIcon;
