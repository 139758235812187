{{> header val="HPI" }}
{{> patient_summary }}
<br/>

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>

{{> subheader val="Problem List" }}
{{> problem_list }}
<br/>

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{! Hypertension Only Section }}
{{> subheader val="Last Basic Metabolic Panel (BMP) and Lipid Panel" }}
{{> free_text_space }}
{{! End hypertension only section }}
{{/if}}

{{> subheader val="Medication allergies" }}
{{> free_text_space }}
