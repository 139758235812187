import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CircleSuccessIcon from '@/shared/assets/svgs/circle-success.svg?react';
import SearchIcon from '@/shared/assets/svgs/search2.svg?react';
import {
  ResourceTableContainer,
  ResourceTableTitle,
} from '@/shared/common/ResourceTableContainer';
import type { Patient } from '@/shared/types/patient.types';

import { PatientListEmptyResults } from '../PatientListEmptyResults';
import { PatientsTable } from '../PatientsTable';
import { usePatientsInfiniteQuery } from '../hooks/queries.hooks';
import {
  PatientSortSelect,
  useAllPatientsSortOptions,
} from './PatientsSortSelect';
import { tableContainer } from './tableContainer.css';

type Props = {
  isSearched?: boolean;
  onClickPatient: (patient: Patient) => void;
};

export function PatientsWithoutActiveAlerts({
  isSearched = false,
  onClickPatient,
}: Props) {
  const intl = useIntl();
  const sortOptions = useAllPatientsSortOptions();
  const defaultSortOption = sortOptions[0];
  const [sortOption, setSortOption] = useState(defaultSortOption);

  const { patientsInfiniteQuery, patients, isEmptyResult } =
    usePatientsInfiniteQuery(
      false,
      { sortBy: sortOption.sortBy, orderBy: sortOption.orderBy },
      { refetchOnWindowFocus: true },
    );

  return (
    <ResourceTableContainer
      className={tableContainer}
      header={
        <>
          <ResourceTableTitle>
            {isSearched ? (
              <FormattedMessage defaultMessage="Search results" />
            ) : (
              <FormattedMessage defaultMessage="Patients without alerts" />
            )}
          </ResourceTableTitle>
          {!isEmptyResult && (
            <PatientSortSelect
              onChange={setSortOption}
              options={sortOptions}
              selected={sortOption}
            />
          )}
        </>
      }
    >
      {isEmptyResult ? (
        <PatientListEmptyResults
          icon={isSearched ? <SearchIcon /> : <CircleSuccessIcon />}
          message={
            isSearched
              ? intl.formatMessage({
                  defaultMessage:
                    'There are no patients related to this search.',
                })
              : intl.formatMessage({
                  defaultMessage: "There aren't any patients without alerts",
                })
          }
        />
      ) : (
        <PatientsTable
          patients={patients}
          onClickPatient={onClickPatient}
          onPageRequested={() => patientsInfiniteQuery.fetchNextPage()}
          isFetching={patientsInfiniteQuery.isFetching}
          hasNextPage={patientsInfiniteQuery.hasNextPage ?? false}
        />
      )}
    </ResourceTableContainer>
  );
}
