import cx from 'classnames';
import type { ReactNode } from 'react';

import { Table as MuiTable } from '@/deprecated/mui';

import { striped as stripedClass } from './styles.css';

type Props = {
  children: ReactNode;
  striped?: boolean;
  className?: string;
  stickyHeader?: boolean;
};

export function Table({ children, className, striped, stickyHeader }: Props) {
  return (
    <MuiTable
      className={cx(className, { [stripedClass]: striped })}
      stickyHeader={stickyHeader}
    >
      {children}
    </MuiTable>
  );
}
