import {
  EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
} from '../../Notes.types';

/**
 * Sets the 'patient_no_show' status for the EncounterType module.
 * @param encounterModuleInstances The current list of encounter module instances.
 * @param onChangeInstance Function to update a module instance.
 * @param isNoShow The new 'no show' status to set.
 */
export function setPatientNoShow(
  isNoShow: boolean,
  encounterModuleInstances: Array<EncounterModuleInstance<EncounterTypeInputs>>,
  onChangeInstance: (
    instance: EncounterModuleInstance<EncounterTypeInputs>,
  ) => void,
) {
  const instance = encounterModuleInstances.find(
    (i) => i.encounter_module_id === EncounterModuleId.EncounterType,
  );

  if (instance) {
    const updatedInstance: EncounterModuleInstance<EncounterTypeInputs> = {
      ...instance,
      inputs: {
        ...instance.inputs,
        patient_no_show: isNoShow,
      },
    };
    onChangeInstance(updatedInstance);
  }
}
