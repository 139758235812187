import { format, parseISO } from 'date-fns';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import {
  lastStructuredChange,
  medHasNeverBeenReviewedOrTitrated,
  previousChange,
} from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import {
  getDoseStr,
  getRxNormStr,
} from '@/pages/patients/PatientMedications/utils/medInfoUtils';
import { getMedGroups } from '@/pages/patients/PatientMedications/utils/sortMeds';
import type {
  Medication,
  MedicationChange,
} from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';

import type { EncounterModuleInstance } from '../../Notes.types';
import { TypeOfEncounter } from '../../Notes.types';
import {
  getEncounterType,
  isAdHocClinicalEncounterType,
  isRegularVisitClinicianType,
} from '../../utils/encounterTypeUtils';

export function showDiseaseSpecificMeds(
  encounterModuleInstances: EncounterModuleInstance[],
) {
  const encounterType = getEncounterType(encounterModuleInstances);

  return (
    isRegularVisitClinicianType(encounterType) ||
    isAdHocClinicalEncounterType(encounterType) ||
    encounterType === TypeOfEncounter.CCM_CARE_PLAN
  );
}

export function useGetDiseaseSpecificMedsNoteBody(
  patientId: string,
  noteId: Maybe<number>,
): (encounterType: TypeOfEncounter | undefined) => Nullable<string> {
  const intl = useIntl();
  const { data } = usePatientMedications(patientId);
  const { diseaseSpecificMeds } = getMedGroups(data);

  if (!data || !noteId) {
    return () => null;
  }

  const title = intl.formatMessage({ defaultMessage: 'Medications' });
  return (encounterType) => {
    const medsMarkdown = getMedicationsMarkdown(
      intl,
      diseaseSpecificMeds,
      noteId,
      encounterType,
    );
    return `### ${title}\n${medsMarkdown}`;
  };
}

function getMedicationsMarkdown(
  intl: IntlShape,
  diseaseSpecificMeds: Medication[],
  currNoteId: number,
  encounterType: TypeOfEncounter | undefined,
) {
  const NO_MEDS_REPORTED_OR_ASSESSED = intl.formatMessage({
    defaultMessage:
      'Disease-specific medications were not reported or assessed.',
  });

  const medsWithLastChangeTuples = diseaseSpecificMeds.map(
    (m) =>
      [m, lastStructuredChange(m)] as [Medication, Nullable<MedicationChange>],
  );

  const anyTitrationsInEncounter = medsWithLastChangeTuples.some(
    ([, medChange]) =>
      medChange?.noteId === currNoteId &&
      [
        MedicationChangeChangeType.START,
        MedicationChangeChangeType.STOP,
        MedicationChangeChangeType.TITRATION,
      ].includes(medChange?.changeType as MedicationChangeChangeType),
  );

  // For Alert Documentation encounters, don't show "Continue"s if no titrations
  // for this encounter
  if (
    encounterType === TypeOfEncounter.ALERT_DOCUMENTATION &&
    !anyTitrationsInEncounter
  ) {
    return NO_MEDS_REPORTED_OR_ASSESSED;
  }

  const dsMedChanges = medsWithLastChangeTuples
    .map(([m, medChange]) => {
      if (!medChange || medHasNeverBeenReviewedOrTitrated(m)) {
        return null;
      }

      const { changeType, status, reason } = medChange;
      const isInactive = status === MedicationChangeStatus.INACTIVE;
      const isCurrNoteChange = medChange.noteId === currNoteId;

      // Filter out any meds stopped or marked as inactive in a previous note
      if (isInactive && !isCurrNoteChange) {
        return null;
      }

      // Change type shown is from changes made in current note -- if latest med change is from a previous note, show it as "Continue"
      const currNoteChangeType = isCurrNoteChange ? changeType : undefined;

      const medInfo = getMedInfo(
        intl,
        currNoteChangeType,
        medChange,
        isInactive,
        previousChange(m.medChanges),
      );

      const changeTypeMarkdown = getChangeTypeMarkdown(
        intl,
        isInactive,
        currNoteChangeType,
      );
      const patientNotes = getPatientNotesMarkdown(reason, isCurrNoteChange);
      return `- ${changeTypeMarkdown}${medInfo}${patientNotes}`;
    })
    .filter((medChange) => medChange !== null) as string[];

  // If no disease-specific meds exist
  // If no disease-specific meds were reviewed
  // If all disease-specific meds were previously reported as inactive (or stopped)
  if (dsMedChanges.length === 0) {
    return NO_MEDS_REPORTED_OR_ASSESSED;
  }
  return dsMedChanges.join('\n');
}

function formatDate(date: Maybe<string>) {
  return date ? format(parseISO(date), 'MM/dd/yy') : '--/--/----';
}

function getChangeTypeMarkdown(
  intl: IntlShape,
  isInactive = false,
  changeType?: MedicationChangeChangeType,
) {
  let changeTypeLabel;
  switch (changeType) {
    case MedicationChangeChangeType.START:
      changeTypeLabel = intl.formatMessage({ defaultMessage: 'Started' });
      break;
    case MedicationChangeChangeType.STOP:
      changeTypeLabel = intl.formatMessage({ defaultMessage: 'Stopped' });
      break;
    case MedicationChangeChangeType.TITRATION:
      changeTypeLabel = intl.formatMessage({ defaultMessage: 'Titrated' });
      break;
    default:
      if (isInactive) {
        return '';
      }
      changeTypeLabel = intl.formatMessage({ defaultMessage: 'Continued' });
  }
  return `***${changeTypeLabel}*** `;
}

function getPatientNotesMarkdown(
  reason: Maybe<string>,
  isCurrNoteChange: boolean,
) {
  return reason && isCurrNoteChange ? `\n${reason}` : '';
}

function getMedStr(intl: IntlShape, medChange: MedicationChange) {
  const { rxnorm, doseQuantities, frequencies } = medChange;
  if (!rxnorm) {
    logger.error('Disease-specific medication missing rxnorm');
    return '';
  }

  const rxnormStr = getRxNormStr(rxnorm, intl);
  const doseStr = getDoseStr(intl, doseQuantities, frequencies, rxnorm);
  return `${rxnormStr}, ${doseStr}`;
}

function getMedInfo(
  intl: IntlShape,
  changeType: Maybe<MedicationChangeChangeType>,
  medChange: MedicationChange,
  isInactive: boolean,
  prevChange: Maybe<MedicationChange>,
) {
  const medStr = getMedStr(intl, medChange);
  const { startDate, endDate } = medChange;
  switch (changeType) {
    case MedicationChangeChangeType.START:
      return intl.formatMessage(
        {
          defaultMessage: '{medStr}, on {startDate}',
        },
        { medStr, startDate: formatDate(startDate) },
      );
    case MedicationChangeChangeType.STOP:
      return intl.formatMessage(
        {
          defaultMessage: '{medStr}, on {endDate}',
        },
        { medStr, endDate: formatDate(endDate) },
      );
      break;
    case MedicationChangeChangeType.TITRATION: {
      const prevMedStr = prevChange
        ? getMedStr(intl, prevChange)
        : intl.formatMessage({ defaultMessage: 'N/A' });
      return intl.formatMessage(
        {
          defaultMessage: 'from {prevMedStr} to {medStr}, on {startDate}',
        },
        { medStr, prevMedStr, startDate: formatDate(startDate) },
      );
    }
    default:
      if (isInactive) {
        return intl.formatMessage(
          {
            defaultMessage: '[Not active: {medStr}]',
          },
          { medStr },
        );
      }
      return medStr;
  }
}
