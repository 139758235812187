import { useDeviceHealth } from '@/shared/hooks/queries/device-health.queries';
import { useAssignedDevices } from '@/shared/hooks/queries/devices.queries';

export type DevicesWithHealth = ReturnType<
  typeof useDevicesWithHealth
>['devices'];
export type DeviceWithHealth = NonNullable<Unpacked<DevicesWithHealth>>;

export function useDevicesWithHealth(patientId: Maybe<string>) {
  const { data: devices, isLoading: isLoadingDevices } = useAssignedDevices(
    patientId || '',
    { sortBy: 'provider' },
    !!patientId,
  );

  const { data: deviceHealth, isLoading: isLoadingDeviceHealth } =
    useDeviceHealth(patientId || '');

  return {
    devices:
      devices?.data.map(
        ({ id, provider, serial_number, device_type, ...deviceRest }) => {
          const rpcDeviceHealth = deviceHealth?.devices?.find((device) =>
            device.name ? device.name === id.toString() : false,
          );
          return {
            provider,
            serial_number,
            device_type,
            ...deviceRest,
            ...rpcDeviceHealth,
          };
        },
      ) || [],
    isLoading: isLoadingDevices || isLoadingDeviceHealth,
  };
}
