import { Item, Section } from 'react-stately';

import { Select as BaseSelect } from './Select';

export const Select = Object.assign(BaseSelect, {
  Option: Item,
  Section,
});

export type { Props } from './Select';
