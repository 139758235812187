export enum ModalType {
  CreateConfirmation = 'create_confirmation',
  CreateTimeEntry = 'create_time_entry',
  UpdateTimeEntry = 'update_time_entry',
  ViewTimeEntry = 'view_time_entry',
  DeleteConfirmation = 'delete_confirmation',
}

export type ModalState =
  | { type: ModalType.CreateConfirmation }
  | { type: ModalType.CreateTimeEntry }
  | { type: ModalType.DeleteConfirmation; entryId: string }
  | { type: ModalType.UpdateTimeEntry; entryId: string }
  | { type: ModalType.ViewTimeEntry; entryId: string };
