import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import TrayIcon from '@/shared/assets/svgs/tray.svg?react';
import { SimpleEmptyState as EmptyState } from '@/shared/common/EmptyState';

import { emptyStateTitle, trayIcon } from './EmptyTasks.css';

type Props = {
  children?: ReactNode;
};

export function EmptyTasks({ children }: Props) {
  return (
    <EmptyState image={<TrayIcon className={trayIcon} />}>
      <div className={emptyStateTitle}>
        <FormattedMessage defaultMessage="No tasks at the moment. Double check the filters to avoid missing something." />
      </div>
      {children}
    </EmptyState>
  );
}
