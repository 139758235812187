import { differenceInMinutes, parseISO } from 'date-fns';
import { type IntlShape } from 'react-intl';

import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { NoteVisitType } from '@/shared/generated/grpcGateway/note.pb';
import type { AppointmentDetails } from '@/shared/generated/grpcGateway/scheduling.pb';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';

enum AppointmentName {
  InitialNPVisit = 'INITIAL NP VISIT',
  InitialCNVisit = 'INITIAL CN VISIT',
  RegularNPVisit = 'REGULAR NP VISIT',
  RegularCNVisit = 'REGULAR CN VISIT',
  RegularCCMVisit = 'CCM REGULAR VISIT',
  WelcomeCall = 'WELCOME CALL',
  CCMCarePlanVisit = 'CCM CARE PLAN VISIT',
}

export const NOTIFICATION_TIME_RANGE_IN_MIN = 5;

export type NotificationInfo = {
  startingSoon: boolean;
  isLate: boolean;
  minutes: Maybe<number>;
  appointmentId: Maybe<string>;
  patientId: Maybe<string>;
};

export function getNoteMetadataFromApptName(
  intl: IntlShape,
  apptName: Maybe<string>,
  rpmConditions: RpmCondition[],
) {
  if (apptName?.toUpperCase().includes(AppointmentName.InitialNPVisit)) {
    return {
      encounterType: TypeOfEncounter.INITIAL_NP_VISIT,
      visitType: NoteVisitType.INITIAL_NP_VISIT,
      title: intl.formatMessage({
        defaultMessage: 'Initial NP Visit',
      }),
    };
  }
  if (apptName?.toUpperCase().includes(AppointmentName.RegularNPVisit)) {
    return {
      encounterType: TypeOfEncounter.NP_VISIT,
      visitType: NoteVisitType.REGULAR_NP_VISIT,
      title: intl.formatMessage({
        defaultMessage: 'Regular NP Visit',
      }),
    };
  }
  if (apptName?.toUpperCase().includes(AppointmentName.InitialCNVisit)) {
    return {
      encounterType: rpmConditions.includes(Condition.CHF)
        ? TypeOfEncounter.INITIAL_CN_CHF_VISIT
        : TypeOfEncounter.INITIAL_CN_VISIT,
      visitType: rpmConditions.includes(Condition.CHF)
        ? NoteVisitType.INITIAL_CN_CHF_VISIT
        : NoteVisitType.INITIAL_CN_VISIT,
      title: intl.formatMessage({
        defaultMessage: 'Initial CN Visit',
      }),
    };
  }
  if (apptName?.toUpperCase().includes(AppointmentName.RegularCNVisit)) {
    return {
      encounterType: rpmConditions.includes(Condition.CHF)
        ? TypeOfEncounter.CN_CHF_VISIT
        : TypeOfEncounter.CN_VISIT,
      visitType: rpmConditions.includes(Condition.CHF)
        ? NoteVisitType.CN_CHF_VISIT
        : NoteVisitType.REGULAR_CN_VISIT,
      title: intl.formatMessage({
        defaultMessage: 'Regular CN Visit',
      }),
    };
  }
  if (apptName?.toUpperCase().includes(AppointmentName.RegularCCMVisit)) {
    return {
      encounterType: TypeOfEncounter.CCM_VISIT,
      visitType: NoteVisitType.REGULAR_CCM_VISIT,
      title: intl.formatMessage({
        defaultMessage: 'CCM Regular Visit',
      }),
    };
  }
  if (apptName?.toUpperCase().includes(AppointmentName.WelcomeCall)) {
    return {
      encounterType: TypeOfEncounter.WELCOME_CALL,
      visitType: NoteVisitType.WELCOME_CALL,
      title: intl.formatMessage({ defaultMessage: 'Welcome Call' }),
    };
  }
  if (apptName?.toUpperCase().includes(AppointmentName.CCMCarePlanVisit)) {
    return {
      encounterType: TypeOfEncounter.CCM_CARE_PLAN,
      visitType: NoteVisitType.CCM_CARE_PLAN,
      title: intl.formatMessage({
        defaultMessage: 'CCM Care Plan Visit',
      }),
    };
  }

  return {
    title: intl.formatMessage({ defaultMessage: 'Unknown Visit' }),
  };
}

export function getNotificationDetails(
  appointments: AppointmentDetails[],
): NotificationInfo {
  const now = new Date();
  for (let i = 0; i < appointments.length; i += 1) {
    const appt = appointments[i];
    const apptDateTime = parseISO(appt.startTime ?? '');
    const timeToAppt = differenceInMinutes(apptDateTime, now, {
      roundingMethod: 'ceil',
    });

    if (
      !appt.hasCalls &&
      timeToAppt >= 0 &&
      timeToAppt <= NOTIFICATION_TIME_RANGE_IN_MIN
    ) {
      return {
        startingSoon: true,
        isLate: false,
        minutes: Math.abs(timeToAppt),
        appointmentId: appt.name,
        patientId: appt.patientId,
      };
    }

    if (
      !appt.hasCalls &&
      timeToAppt >= -NOTIFICATION_TIME_RANGE_IN_MIN &&
      timeToAppt < 0
    ) {
      return {
        startingSoon: false,
        isLate: true,
        minutes: Math.abs(timeToAppt),
        appointmentId: appt.name,
        patientId: appt.patientId,
      };
    }
  }

  return {
    startingSoon: false,
    isLate: false,
    minutes: 0,
    appointmentId: null,
    patientId: null,
  };
}
