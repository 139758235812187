import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { Wizard } from '@/shared/common/Wizard';
import { useWizardFormFromConfig } from '@/shared/common/Wizard/state';

import { INIT_VISIT_SECTIONS } from '../../metadata';
import {
  PatientGoalRadioSection,
  getPatientGoalFormConfig,
} from '../../shared/setPatientGoal';
import { initVisitPath } from '../paths';

export function SetGoal() {
  const intl = useIntl();
  const history = useHistory();
  const form = useWizardFormFromConfig(
    initVisitPath('/action-plan', '/set-goal'),
    getPatientGoalFormConfig(intl),
  );

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      form={form}
      title={intl.formatMessage({ defaultMessage: 'Patient Goal' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        history.push(
          initVisitPath('/action-plan', '/education-modules-review'),
        );
      }}
    >
      <PatientGoalRadioSection />
    </Wizard.Step>
  );
}
