import { parseISO } from 'date-fns';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { Table } from '@/shared/common/Table';
import type { NotificationAudit } from '@/shared/hooks/queries';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import { dateCell } from './CommunicationRow.css';
import { NotificationAuditStatusTag } from './NotificationAuditStatusTag';
import {
  UNKNOWN_COMM_TYPE,
  useCommunicationTypei18nMap,
} from './useCommunicationTypei18nMap';

type Props = {
  entry: NotificationAudit;
  patientTimezone?: string;
};

export const CommunicationRow = forwardRef(
  (
    { entry, patientTimezone }: Props,
    ref?: Nullable<ForwardedRef<HTMLTableRowElement>>,
  ) => {
    const {
      message,
      subject,
      notification_status: notificationStatus,
      notification_type: notificationType,
      recipient,
      timestamp,
      error,
    } = entry;
    const communicationTypeMap = useCommunicationTypei18nMap();
    const body = message || subject;

    return (
      <Table.Row ref={ref}>
        <Table.NodeCell>
          <Tooltip content={recipient} placement="bottom-start">
            <Tag>
              {communicationTypeMap[notificationType || UNKNOWN_COMM_TYPE]}
            </Tag>
          </Tooltip>
        </Table.NodeCell>
        <Table.DateCell
          className={dateCell}
          format="MM/dd/yyyy h:mma z"
          timezone={patientTimezone}
        >
          {timestamp ? parseISO(`${timestamp}Z`) : null}
        </Table.DateCell>
        <Table.NodeCell>
          <NotificationAuditStatusTag
            status={notificationStatus}
            error={getError(error)}
          />
        </Table.NodeCell>
        <Table.NodeCell>{body}</Table.NodeCell>
      </Table.Row>
    );
  },
);

function getError(error: NotificationAudit['error']) {
  const errorMsg = [error?.message, error?.secondary_message]
    .filter(Boolean)
    .join(' ');

  if (!error || !errorMsg) {
    return null;
  }

  return `${error.code}: ${errorMsg}`;
}
