import type { AxiosResponse } from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';
import Session from '@/shared/utils/session';

import { DELETE_NPI, DELETE_NPI_FAILURE, npiDeleted } from '../actionCreators';

const deleteNpiEpic = (action$: any) =>
  action$.pipe(
    ofType(DELETE_NPI),
    switchMap((action: any) =>
      from(Session.Api.delete(`/pms/api/v1/npis/${action.npiId}`)).pipe(
        takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST))),
      ),
    ),
    map((response: AxiosResponse<TNPIDeletedPayload>) =>
      npiDeleted(response.data),
    ),
    catchError((error, caught) =>
      merge(
        of({
          type: DELETE_NPI_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default deleteNpiEpic;
