import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

import { removeItem } from '@/lang.utils';
import { useFlags } from '@/shared/hooks';
import type { CareModelVersion } from '@/shared/types/featureFlags.types';
import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { useNoteEditorContext } from '../NoteEditorContext';
import { getModuleList } from '../NotePreview/getVisitLayoutOutput';
import type { EncounterModuleInstance, TypeOfEncounter } from '../Notes.types';
import { EncounterModuleId as ModuleId } from '../Notes.types';
import { adjustEncounterTypeInstance } from './adjustEncounterTypeInstance';
import {
  getEncounterType,
  getIsFromVisitLayout,
  isClinicalNavigatorEncounterType,
  isClinicianVisit,
  isPreV3ClinicalNavigatorEncounterType,
} from './encounterTypeUtils';
import { EMPTY_RTF_BODY, isRtfBodyEmpty } from './rtfBodyUtil';
import { getInstancesMigratedToVisitLayout } from './visitLayoutMigrationUtils';

export function useAdjustEncounterModuleInstances(
  descriptionBody: TNoteBodyRTF,
  setBody: (body: TNoteBodyRTF) => void,
) {
  const {
    encounterModuleInstances,
    setEncounterModuleInstances,
    isEditorOpen,
  } = useNoteEditorContext();
  const { careModelVersion } = useFlags();

  useEffect(() => {
    if (!isEditorOpen) {
      return;
    }

    const encounterType = getEncounterType(encounterModuleInstances);
    let newInstances = adjustEncounterTypeInstance(
      encounterModuleInstances,
      careModelVersion,
    );

    newInstances = ensureCorrectModulesArePresent(
      encounterType,
      newInstances,
      careModelVersion,
    );

    const isFromVisitLayout = getIsFromVisitLayout(newInstances);
    if (!isFromVisitLayout && !isRtfBodyEmpty(descriptionBody)) {
      newInstances = getInstancesMigratedToVisitLayout(
        newInstances,
        descriptionBody,
      );
      setBody(EMPTY_RTF_BODY);
    }

    if (!isEqual(encounterModuleInstances, newInstances)) {
      setEncounterModuleInstances(newInstances);
    }

    // Do not add setBody to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    descriptionBody,
    encounterModuleInstances,
    setEncounterModuleInstances,
    isEditorOpen,
  ]);
}

/**
 * Ensures that required modules are present so that validation is properly applied,
 * and ensure that modules are removed if the encounter type is changed to one where
 * that module is no longer relevant
 */
export function ensureCorrectModulesArePresent(
  encounterType: TypeOfEncounter | undefined,
  encounterModuleInstances: EncounterModuleInstance[],
  careModelVersion: CareModelVersion,
) {
  let newInstances = [...encounterModuleInstances];

  if (encounterType) {
    [
      ModuleId.PatientNotes,
      ModuleId.CarePlan,
      ModuleId.GeneralAssessmentAndPlan,
      ModuleId.ClinicalAttestation,
      ModuleId.ClinicalGoalReached,
      ModuleId.Medications,
    ].forEach((moduleId) => {
      const instance = getInstance(newInstances, moduleId);
      const shouldHaveInstance = getModuleList(
        careModelVersion,
        encounterType,
      ).includes(moduleId);

      if (!instance && shouldHaveInstance) {
        newInstances = newInstances.concat({
          encounter_module_id: moduleId,
          inputs: {},
        });
      } else if (instance && !shouldHaveInstance) {
        newInstances = filterInstance(newInstances, moduleId);
      }
    });

    // Only remove and do not add instances for clinician modules because the module instance is only added if the user selects the "Add ___" option
    newInstances = removeClinicianEncounterModules(
      newInstances,
      encounterType,
      careModelVersion,
    );
  }

  return newInstances;
}

/**
 * Ensure that clinician-related encounter module instances are removed if the
 * encounter type is one that should not show them
 */
function removeClinicianEncounterModules(
  encounterModuleInstances: EncounterModuleInstance[],
  encounterType: TypeOfEncounter,
  careModelVersion: CareModelVersion,
) {
  let result = encounterModuleInstances;
  let toRemove = [ModuleId.Medications, ModuleId.Hospitalization];
  const isCnVisit = isClinicalNavigatorEncounterType(encounterType);
  const isOldCnVisit = isPreV3ClinicalNavigatorEncounterType(
    careModelVersion,
    encounterType,
  );

  // pre-v3 CN visits should be treated like clinical visits, so we skip them entirely here.
  // there's a bit of a special case for v3 CN visits since we _do_ want to include symptoms
  // but want to leave out meds and hospitalization.
  if (!isClinicianVisit(encounterType) && !isOldCnVisit) {
    if (!isCnVisit) {
      toRemove = [...toRemove, ModuleId.Symptoms];
    }

    toRemove.forEach((moduleId) => {
      const instance = getInstance(encounterModuleInstances, moduleId);
      if (instance) {
        result = filterInstance(encounterModuleInstances, moduleId);
      }
    });
  }

  return result;
}

function filterInstance(
  instances: EncounterModuleInstance[],
  moduleId: ModuleId,
) {
  return removeItem(
    instances,
    (instance: EncounterModuleInstance) =>
      instance.encounter_module_id === moduleId,
  );
}

export function getInstance<T>(
  instances: EncounterModuleInstance[],
  moduleId: ModuleId,
) {
  return instances.find(
    (instance: EncounterModuleInstance) =>
      instance.encounter_module_id === moduleId,
  ) as EncounterModuleInstance<T> | undefined;
}
