import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { settings } from '@/config';
import { CANCEL_PENDING_REQUEST } from '@/shared/actionCreators';

import {
  CONFIRM_USER,
  CONFIRM_USER_FAILURE,
  userConfirmed,
} from '../actionCreators';

const confirmUserEpic = (action$: any) =>
  action$.pipe(
    ofType(CONFIRM_USER),
    switchMap((action: any) =>
      from(
        axios.post(
          `${settings.API_URL}/auth/api/v1/login/new-password-required`,
          {
            email: action.email,
            previous_password: action.previousPassword,
            proposed_password: action.proposedPassword,
          },
        ),
      ).pipe(takeUntil(action$.pipe(ofType(CANCEL_PENDING_REQUEST)))),
    ),
    map((response: AxiosResponse) => userConfirmed(response.data)),
    catchError((error, caught) =>
      merge(
        of({
          type: CONFIRM_USER_FAILURE,
          payload: error,
          error: true,
        }),
        caught,
      ),
    ),
  );

export default confirmUserEpic;
