import {
  FETCH_PATIENT_CONTACTS_FAILURE,
  FETCH_PATIENT_DETAILS,
  FETCH_PATIENT_DETAILS_FAILURE,
  FETCH_PATIENT_DETAILS_SUCCESS,
} from '../actionCreators';
import initialState from './initialState';
import type { PatientDetailState } from './patientDetail.type';

function patientDetailsReducer(
  state = initialState,
  action: any,
): PatientDetailState {
  switch (action.type) {
    case FETCH_PATIENT_DETAILS:
      return {
        ...state,
        status: {
          isProcessing: true,
          isSuccess: false,
        },
        error: null,
      };
    case FETCH_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: {
          isProcessing: false,
          isSuccess: true,
        },
        error: null,
      };
    case FETCH_PATIENT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: {
          isProcessing: false,
          isSuccess: false,
        },
      };
    case FETCH_PATIENT_CONTACTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default patientDetailsReducer;
