import { FormattedMessage } from 'react-intl';

import BulletedListIcon from '@/shared/assets/svgs/bulletedListIcon.svg?react';
import UserIcon from '@/shared/assets/svgs/user.svg?react';
import {
  Segment,
  SegmentedButtonGroup,
} from '@/shared/tempo/@labs/molecule/SegmentedButtonGroup';

import { useFiltersQuery } from '../../hooks/useFiltersQuery.hook';
import { CareProviderFilter } from '../../types/filter.types';

export function PatientViewSegmentedButton() {
  const { careProviderFilter, setCareProviderFilter } = useFiltersQuery();

  return (
    <SegmentedButtonGroup
      size="default"
      defaultValue={careProviderFilter}
      value={careProviderFilter}
      onChange={(filter) => setCareProviderFilter(filter as CareProviderFilter)}
    >
      <Segment
        value={CareProviderFilter.MyPatients}
        tooltip={{ content: <FormattedMessage defaultMessage="My patients" /> }}
      >
        <Segment.Icon>
          <UserIcon />
        </Segment.Icon>
      </Segment>
      <Segment
        value={CareProviderFilter.AllPatients}
        tooltip={{
          content: <FormattedMessage defaultMessage="All patients" />,
        }}
      >
        <Segment.Icon>
          <BulletedListIcon />
        </Segment.Icon>
      </Segment>
    </SegmentedButtonGroup>
  );
}
