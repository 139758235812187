export enum Language {
  // Supported languages
  English = 'ENGLISH',
  Spanish = 'SPANISH',

  // Unsupported languages
  Arabic = 'ARABIC',
  Chinese = 'CHINESE',
  Filipino = 'FILIPINO',
  French = 'FRENCH',
  German = 'GERMAN',
  Haitian = 'HAITIAN_CREOLE',
  Hindi = 'HINDI',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Korean = 'KOREAN',
  Persian = 'PERSIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Ukrainian = 'UKRAINIAN',
  Urdu = 'URDU',
  Vietnamese = 'VIETNAMESE',
  Other = 'OTHER',
}
