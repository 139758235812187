import sortBy from 'lodash/sortBy';

import { MEDICATION_CLASSES } from './formattedMessages';

/**
 * @param {Object.<med_class_key, any>} values - Object with medication class keys
 * @returns {Array} - Entries sorted by the name of the medication class
 */
export function sortByMedClassName<T>(values: { [medClassKey: string]: T }) {
  return sortBy(
    Object.entries(values),
    ([medClassKey]) =>
      MEDICATION_CLASSES[medClassKey as keyof typeof MEDICATION_CLASSES]?.props
        .defaultMessage,
  );
}
