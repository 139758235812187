import { FormattedMessage } from 'react-intl';

import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { VitalType } from '@/shared/types/vitals.types';

import { goalTag } from './PatientActivity.css';
import { usePatientConditions } from './usePatientConditions';
import type { VitalTypes } from './useRelevantVitalTypes';
import type { PatientActivityVitals } from './useVitalsAndGoals';

type Props = {
  patientId: string;
  vitalsAndGoals: PatientActivityVitals;
  vitalTypes: VitalTypes;
};

export function GoalTag({ patientId, vitalsAndGoals, vitalTypes }: Props) {
  // explicitly not supporting CHF for mvp
  const { hasHtn, hasT2d } = usePatientConditions(patientId);
  const atGoal = isAtGoal(
    vitalsAndGoals,
    hasHtn,
    hasT2d,
    vitalTypes[VitalType.BloodGlucose].isIncluded,
  );

  return (
    <Tooltip
      content={
        <>
          {hasHtn && (
            <FormattedMessage
              defaultMessage="30-day avg BP is {atGoal, select, true { below } other { above } } {goal}"
              values={{
                goal: vitalsAndGoals[VitalType.BloodPressure]?.goal,
                atGoal: vitalsAndGoals[VitalType.BloodPressure]?.atGoal,
              }}
            />
          )}
          <br />
          {hasT2d && vitalTypes[VitalType.BloodGlucose].isIncluded && (
            <FormattedMessage
              defaultMessage="30-day avg BG is {atGoal, select, true { below } other { above } } {goal}"
              values={{
                goal: vitalsAndGoals[VitalType.BloodGlucose]?.goal,
                atGoal: vitalsAndGoals[VitalType.BloodGlucose]?.atGoal,
              }}
            />
          )}
        </>
      }
    >
      <Tag variant={atGoal ? 'success' : 'danger'} className={goalTag}>
        {atGoal ? (
          <FormattedMessage defaultMessage="At goal" />
        ) : (
          <FormattedMessage defaultMessage="Not at goal" />
        )}
      </Tag>
    </Tooltip>
  );
}

function isAtGoal(
  vitalsAndGoals: PatientActivityVitals,
  hasHtn: boolean = false,
  hasT2d: boolean = false,
  hasBgMeter: boolean = false,
) {
  if (hasHtn && !hasT2d) {
    return vitalsAndGoals[VitalType.BloodPressure]?.atGoal;
  }

  if (!hasHtn && hasT2d && hasBgMeter) {
    return vitalsAndGoals[VitalType.BloodGlucose]?.atGoal;
  }

  if (hasHtn && hasT2d) {
    return (
      vitalsAndGoals[VitalType.BloodPressure]?.atGoal &&
      (!hasBgMeter || vitalsAndGoals[VitalType.BloodGlucose]?.atGoal)
    );
  }

  return true;
}
