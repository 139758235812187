import { container, dot, line } from './StatusIndicator.css';
import Dot from './dot.svg?react';

type Props<T extends number> = {
  totalSteps: T;
  currentStep: IntRange<0, T>;
};

export function StatusIndicator<T extends number>({
  totalSteps,
  currentStep,
}: Props<T>) {
  return (
    <span className={container}>
      <div className={line} />
      {[...new Array(totalSteps)].map((_, idx) => {
        if (idx === currentStep) {
          return <Dot className={dot.current} />;
        }

        if (idx < currentStep) {
          return <Dot className={dot.completed} />;
        }

        return <Dot className={dot.incomplete} />;
      })}
    </span>
  );
}
