export enum LoadingId {
  TemplateContext = 'template_context',
}

export type NotesLoadingStateContext = {
  useWatchNoteLoadingState: (state: LoadingState) => void;
  updateLoadingState: (state: LoadingState) => void;
  getLoadingState: (id: LoadingId) => LoadingState | undefined;
  isLoading: boolean;
};

export type LoadingState = {
  id: LoadingId;
  isLoading: boolean;
};
