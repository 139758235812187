import { useMemo } from 'react';

import type { SectionMeta, SectionPath } from '../path.utils';
import { getFullStepPath } from '../path.utils';
import type { SectionStepsState } from '../state';
import { useWizardStateContext } from '../state';

export function useSectionProgressMap(sections: Readonly<SectionMeta[]>) {
  const {
    wizardFormData: { data: stepData },
  } = useWizardStateContext();

  return useMemo(
    () => getSectionProgressMap(sections, stepData),
    [sections, stepData],
  );
}

export function getSectionProgressMap(
  sections: Readonly<SectionMeta[]>,
  stepData: SectionStepsState,
) {
  return sections.reduce(
    (acc, section) => {
      const allSectionsComplete = section.steps.every((step) => {
        const fullPath = getFullStepPath(sections, section.basePath, step.path);
        return stepData[fullPath]?.isComplete || false;
      });
      return {
        ...acc,
        [section.basePath]: allSectionsComplete,
      };
    },
    {} as Record<SectionPath, boolean>,
  );
}
