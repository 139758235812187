import classnames from 'classnames/bind';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { page } from './Page.css';
import { PageContextProvider } from './PageContext';
import { SidePanelProvider } from './SidePanel/SidePanelContext';
import { useSidePanelState } from './SidePanel/useSidePanelState.hooks';
import styles from './styles.module.scss';
import { useDocumentTitle } from './useDocumentTitle.hooks';

const cx = classnames.bind(styles);

export const PAGE_CONTAINER_ID = 'main-page-container';
export const PAGE_ID = 'main-page';

type Props = {
  children: ReactNode;
  documentTitle?: string;
  sidePanel?: JSX.Element;
  // TODO: Remove once all deprecated variants are removed
  variant?: 'deprecated' | 'default';
  'data-testid'?: string;
  classes?: {
    container?: string;
    page?: string;
  };
};

export function Page({
  children,
  sidePanel,
  documentTitle,
  classes = {},
  variant = 'default',
  ...rest
}: Props) {
  const [headerText, setHeaderText] = useState<Maybe<string>>();
  const stateAndHandlers = useSidePanelState({});
  const { state } = stateAndHandlers;

  useDocumentTitle({ title: documentTitle });

  return (
    <PageContextProvider value={{ headerText, setHeaderText }}>
      <SidePanelProvider value={stateAndHandlers}>
        <div
          id={PAGE_CONTAINER_ID}
          data-testid={rest['data-testid']}
          className={cx('page-container', page[variant], classes.container, {
            'no-padding': state.isFullscreen,
          })}
        >
          <div
            id={PAGE_ID}
            className={cx('page', classes.page, {
              narrow: state.isExpanded,
            })}
          >
            {children}
          </div>
          {sidePanel}
        </div>
      </SidePanelProvider>
    </PageContextProvider>
  );
}
