import jwt_decode from 'jwt-decode';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { ScheduleTray } from '@/components/ScheduleTray/ScheduleTray';
import { TaskHubTray } from '@/components/TaskHubTray';
import { MenuItem, MenuList } from '@/deprecated/mui';
import { requestLogout } from '@/pages/auth/actionCreators';
import logoMark from '@/shared/assets/logoMark.png';
import { useFlags } from '@/shared/hooks';
import { useTwilioFlexMessagesListener } from '@/shared/hooks/useTwilioFlexMessagesListener';
import type {
  CognitoAccessToken,
  CognitoIdToken,
} from '@/shared/types/cognito.types';
import type { RootState } from '@/store/redux.types';

import { TwilioFlex, useTwilioFlexContext } from '../TwilioFlex';
import { MenuTooltip } from './MenuTooltip';
import {
  activeMenuItem,
  logo,
  menuItem,
  menuList,
  mutedActiveMenuItem,
  sidebarIconContainer,
  sidebarWrapper,
  upperSidebarWrapper,
} from './Sidebar.css';
import { SidebarMenu } from './SidebarMenu';
import { getMenuLinks } from './sidebarItems';

export function Sidebar() {
  const ldFlags = useFlags();
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const [taskHubOpen, setTaskHubOpen] = useState(false);
  const [scheduleTrayOpen, setScheduleTrayOpen] = useState(false);
  const [reportIssueOpen, setReportIssueOpen] = useState(false);
  const { isFlexOpen, setIsFlexOpen } = useTwilioFlexContext();
  useTwilioFlexMessagesListener();

  const currentUserInfo = jwt_decode<CognitoIdToken>(token.id_token);
  const currentUserRole = jwt_decode<CognitoAccessToken>(token.access_token)[
    'cognito:groups'
  ];

  const isAdminPage = location.pathname.startsWith('/admin');
  const menuLinksConfig = getMenuLinks(intl, isAdminPage, ldFlags);
  const otherMenuItemActive =
    isFlexOpen || taskHubOpen || reportIssueOpen || scheduleTrayOpen;
  const menuLinks = menuLinksConfig?.map((menuLink) => (
    <MenuTooltip label={menuLink.label} key={menuLink.to}>
      <NavLink
        to={menuLink.to}
        activeClassName={
          otherMenuItemActive ? mutedActiveMenuItem : activeMenuItem
        }
      >
        <MenuItem className={menuItem}>
          <div className={sidebarIconContainer}>{menuLink.icon}</div>
        </MenuItem>
      </NavLink>
    </MenuTooltip>
  ));

  const sidebarMenuProps = {
    logout: () => dispatch(requestLogout()),
    userInfo: {
      given_name: currentUserInfo.given_name,
      family_name: currentUserInfo.family_name,
    },
    role: currentUserRole,
  };

  return (
    <div id="sidebar" data-testid="sidebar" className={sidebarWrapper}>
      <div className={upperSidebarWrapper}>
        <Link to="/patients">
          <img className={logo} alt="Cadence logo" src={logoMark} />
        </Link>
        <MenuList className={menuList}>
          {menuLinks}
          {!isAdminPage && <TaskHubTray onOpenChange={setTaskHubOpen} />}
          {ldFlags.showTwilioFlexModal && (
            <TwilioFlex onOpenChange={setIsFlexOpen} />
          )}
          {ldFlags.showScheduleTray && (
            <ScheduleTray onOpenChange={setScheduleTrayOpen} />
          )}
        </MenuList>
      </div>
      <SidebarMenu
        isAdminPage={isAdminPage}
        onReportIssueOpenChange={setReportIssueOpen}
        {...sidebarMenuProps}
      />
    </div>
  );
}
