import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { useSidePanelCtx } from '@/shared/common/Page';
import { Button } from '@/shared/tempo/atom/Button';

interface MarkAlertAsReviewedDialogProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
  onMarkAsReviewed?: () => void;
}

export const MarkAlertAsReviewedDialog = ({
  isDialogOpen,
  closeDialog,
  onMarkAsReviewed,
}: MarkAlertAsReviewedDialogProps) => {
  const {
    handlers: { addWhitelistElement },
  } = useSidePanelCtx();
  const intl = useIntl();
  const node = useRef<HTMLDivElement>(null);
  useEffect(() => {
    addWhitelistElement(node);
  }, [addWhitelistElement]);
  return (
    <Modal open={isDialogOpen} onClose={closeDialog} ref={node}>
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Mark alert as reviewed',
        })}
      />
      <Modal.Body>
        <FormattedMessage defaultMessage="Are you sure you want to mark this alert as reviewed?" />
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="close-schedule-patient"
          onPress={closeDialog}
          variant="secondary"
        >
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          id="close-schedule-patient"
          onPress={onMarkAsReviewed}
          variant="primary"
        >
          <FormattedMessage defaultMessage="Mark as reviewed" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
