import cx from 'classnames';
import { endOfDay, format, formatISO, parseISO, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useState } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { NoShowDetails } from '@/components/ScheduleTray/NoShowDetails';
import { useCreateNoteFromAppointment } from '@/components/ScheduleTray/useCreateNoteFromAppointment';
import { ConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import { useCreateEncounter } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import DraftFileIcon from '@/shared/assets/svgs/file.svg?react';
import NewFileIcon from '@/shared/assets/svgs/fileNew.svg?react';
import { NoteStatus as ApptNoteStatus } from '@/shared/generated/grpcGateway/pms.pb';
import type { AppointmentDetails } from '@/shared/generated/grpcGateway/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import {
  autosavedNotesQueryKey,
  useDeleteAutosavedNoteByPatientId,
  usePatientAutosavedNote,
} from '@/shared/hooks/queries/autosave-notes.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getErrorMsg } from '@/shared/utils/helpers';
import { getUserTimezone } from '@/shared/utils/time-helpers';

import { cnNoteKeys } from '../CNNotesSidebarPanel/shared/querykeys';
import {
  appointmentDetailsCol,
  encounterButton,
  lastSyncedContainer,
  openDraftEncounterButton,
} from './PatientScheduling.css';
import { appointmentKeys } from './appointments.queries';

type Props = {
  appointment?: AppointmentDetails;
  patientId: string;
};
export function NextScheduledVisit({ appointment, patientId }: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const { currentUserId: providerId } = useCurrentUser();

  const { startEncounterFromAppointment } = useFlags();
  const { data: patientDetails } = usePatientDetails(patientId, true);
  const [isNewNoteConfirmationOpen, setIsNewNoteConfirmationOpen] =
    useState(false);

  const deleteAutoSavedNote = useDeleteAutosavedNoteByPatientId(
    appointment?.patientId ?? '',
    () => {},
    (error: unknown) => {
      toaster.error(getErrorMsg(error));
    },
  );
  const { data: autosavedNote } = usePatientAutosavedNote(
    appointment?.patientId ?? '',
    { enabled: Boolean(appointment) },
  );

  const apptDateTime = parseISO(appointment?.startTime ?? '');

  const { mutate: createEncounter, isLoading: isSaving } = useCreateEncounter({
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(cnNoteKeys.autosaved(patientId));
      await queryClient.invalidateQueries(
        autosavedNotesQueryKey.patient(patientId),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.nextScheduled(patientId),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.list({
          apptStartTimeFrom: formatISO(startOfDay(apptDateTime)),
          apptStartTimeTo: formatISO(endOfDay(apptDateTime)),
          careProviderId: providerId,
        }),
      );
      setIsNewNoteConfirmationOpen(false);
      history.push({
        pathname: `/patients/${patientId}`,
        search: `?noteId=${response?.name}`,
      });
    },
    onError: (err: unknown) => {
      toaster.error(getErrorMsg(err));
    },
  });

  const { isLoading: isLoadingContext, createNoteFromAppointment } =
    useCreateNoteFromAppointment(appointment, createEncounter);

  if (!appointment) {
    return (
      <div>
        <span>
          {intl.formatMessage({
            defaultMessage: 'No upcoming visits found',
          })}
        </span>
        <span className={lastSyncedContainer}>
          {intl.formatMessage({
            defaultMessage: 'Updates every 5 minutes',
          })}
        </span>
      </div>
    );
  }

  const isNewEncounter =
    appointment.noteStatus === ApptNoteStatus.NOTE_STATUS_UNSPECIFIED;

  const isPublishedNoShow =
    appointment.noteStatus === ApptNoteStatus.PUBLISHED &&
    appointment.patientNoShow;
  const noShowAttemptNotes = (appointment.noShowAttemptNotes || []).filter(
    (noShowNote) => noShowNote.noteStatus === ApptNoteStatus.PUBLISHED,
  );

  return (
    <>
      <div className={appointmentDetailsCol}>
        <div>
          {nextScheduledVisitMessage(
            intl,
            appointment,
            patientDetails?.patient?.timeZone,
          )}
        </div>
        {startEncounterFromAppointment && isPublishedNoShow && (
          <NoShowDetails noShowAttemptNotes={noShowAttemptNotes} />
        )}
        {startEncounterFromAppointment && (
          <Button
            variant={
              isNewEncounter || isPublishedNoShow ? 'primary' : 'secondary'
            }
            type="submit"
            size="small"
            isDisabled={isLoadingContext}
            isProcessing={isSaving}
            className={cx(encounterButton, {
              [openDraftEncounterButton]: !isNewEncounter && !isPublishedNoShow,
            })}
            onPress={
              isNewEncounter || isPublishedNoShow
                ? () => {
                    if (autosavedNote) {
                      setIsNewNoteConfirmationOpen(true);
                    } else {
                      createNoteFromAppointment();
                    }
                  }
                : () => {
                    history.push({
                      pathname: `/patients/${patientId}`,
                      search: `?noteId=${appointment.noteId}`,
                    });
                  }
            }
          >
            {isNewEncounter || isPublishedNoShow ? (
              <>
                <Button.Icon>
                  <NewFileIcon />
                </Button.Icon>
                <FormattedMessage defaultMessage="Start Note" />
              </>
            ) : (
              <>
                <Button.Icon>
                  <DraftFileIcon />
                </Button.Icon>
                <FormattedMessage defaultMessage="Open draft" />
              </>
            )}
          </Button>
        )}
      </div>
      <ConfirmationDialog
        isOpen={isNewNoteConfirmationOpen}
        onCancel={() => setIsNewNoteConfirmationOpen(false)}
        onConfirm={() => {
          deleteAutoSavedNote.mutate(undefined, {
            onSuccess: () => {
              createNoteFromAppointment();
            },
          });
        }}
        confirmButtonText={intl.formatMessage({
          defaultMessage: 'Create Note',
        })}
        dialogTitle={intl.formatMessage({
          defaultMessage: 'Create a new note from appointment',
        })}
        dialogDescription={intl.formatMessage({
          defaultMessage:
            'Are you sure you want to create a new note? This will discard your current autosaved note not associated to this appointment.',
        })}
      />
    </>
  );
}

function nextScheduledVisitMessage(
  intl: IntlShape,
  appt: AppointmentDetails,
  patientTimezone?: string,
) {
  const timezone = patientTimezone || getUserTimezone();

  return intl.formatMessage(
    {
      defaultMessage: `Next {apptType} on {apptDate} at {apptTime} with {careProviderName}, {careProviderRole}`,
    },
    {
      apptType: appt.appointmentCanonicalName,
      apptDate: appt.startTime
        ? format(new Date(appt.startTime), 'MM/dd/yyyy')
        : intl.formatMessage({ defaultMessage: 'N/A' }),
      apptTime: appt.startTime
        ? formatInTimeZone(new Date(appt.startTime), timezone, 'h:mmaaa z')
        : intl.formatMessage({ defaultMessage: 'N/A' }),
      careProviderName: `${appt.careProviderFirstName} ${appt.careProviderLastName}`,
      careProviderRole: appt.careProviderRole || 'NOT_FOUND',
    },
  );
}
