import classnames from 'classnames/bind';
import type { ReactNode } from 'react';

import styles from '../styles.module.scss';

const cx = classnames.bind(styles);

type Props = {
  children: ReactNode;
};

export function Body({ children }: Props) {
  return <div className={cx('container-content')}>{children}</div>;
}
