import cx from 'classnames';
import { format, isValid, parseISO } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

import { usePatientNotes } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import UserIcon from '@/shared/assets/svgs/user.svg?react';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Table } from '@/shared/common/Table';
import { usePatientDetails } from '@/shared/hooks/queries';
import { removeMargin } from '@/shared/jsStyle/utils.css';
import { Link } from '@/shared/tempo/atom/Link';
import { getSymptomsFromNote } from '@/shared/utils/note-symptom-data';

import { GoalTag } from './GoalTag';
import { NoteDataTable } from './NoteDataTable';
import {
  container,
  header,
  iconCircle,
  metadata,
  table,
} from './PatientActivity.css';
import { VitalsTable } from './VitalsTable';
import { useRelevantVitalTypes } from './useRelevantVitalTypes';
import { useVitalsAndGoals } from './useVitalsAndGoals';

type Props = {
  patientId: string;
};

export function PatientActivity({ patientId }: Props) {
  const intl = useIntl();
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    true,
    Boolean(patientId),
  );
  const { data: vitalsAndGoals, isLoading: isLoadingVitalsAndGoals } =
    useVitalsAndGoals(patientId);
  const { vitalTypes, isLoading: isLoadingVitalTypes } =
    useRelevantVitalTypes(patientId);
  const { infiniteQuery: patientNotesQuery, items: recentNotes } =
    usePatientNotes(patientId);

  if (
    patientNotesQuery.isLoading ||
    isLoadingVitalsAndGoals ||
    isLoadingVitalTypes ||
    isLoadingPatient
  ) {
    return <LoadingPlaceholder isLoading />;
  }

  const lastSyncDate = parseISO(patient?.patient?.lastEhrSyncDate ?? '');
  const notesWithSymptoms = recentNotes
    .map((note) => ({
      note,
      symptoms: getSymptomsFromNote(note),
    }))
    .filter(({ symptoms }) => symptoms.length > 0);

  return (
    <div className={container}>
      <div className={header}>
        <div className={iconCircle}>
          <UserIcon />
        </div>
        <FormattedMessage defaultMessage="Patient Activity" />
      </div>
      {vitalsAndGoals && vitalTypes && (
        <>
          <GoalTag
            patientId={patientId}
            vitalsAndGoals={vitalsAndGoals}
            vitalTypes={vitalTypes}
          />
          <VitalsTable
            vitalsAndGoals={vitalsAndGoals}
            vitalTypes={vitalTypes}
          />
        </>
      )}
      <NoteDataTable notesWithSymptoms={notesWithSymptoms} />
      <Table>
        <Table.Header
          columns={[
            {
              title: intl.formatMessage({ defaultMessage: 'Labs' }),
              className: table.headerCell,
            },
          ]}
        />
        <Table.Body>
          <Table.Row>
            <Table.NodeCell className={table.cell}>
              <Link.Routed to={`/patients/${patientId}/labs`}>
                {intl.formatMessage({ defaultMessage: 'View labs' })}
              </Link.Routed>
              <p className={cx(metadata, removeMargin)}>
                <FormattedMessage
                  defaultMessage="Last update {date}"
                  values={{
                    date: isValid(lastSyncDate)
                      ? format(lastSyncDate, 'MM/dd/yy')
                      : intl.formatMessage({ defaultMessage: 'Unknown' }),
                  }}
                />
              </p>
            </Table.NodeCell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}
