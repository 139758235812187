import type { ReactNode } from 'react';

import { stickyFooter } from './Messages.css';

type Props = {
  children: ReactNode;
};

export function Footer({ children }: Props) {
  return <div className={stickyFooter}>{children}</div>;
}
