import type { ReactNode } from 'react';

import type { BaseFieldProps } from '@/shared/common/Form/fields/BaseField';
import { BaseField } from '@/shared/common/Form/fields/BaseField';

import { TimeTrackerTasksCheckboxGroup } from '../shared';

type Option = {
  value: string;
  label: ReactNode;
};

type Props = {
  options: Option[];
} & BaseFieldProps;

export function TimeTrackerTasksField({ options, ...props }: Props) {
  return (
    <BaseField {...props}>
      {({ controller: { field } }) => (
        <TimeTrackerTasksCheckboxGroup
          value={field.value || []}
          onChange={field.onChange}
        />
      )}
    </BaseField>
  );
}
