import type { RawDraftContentBlock } from 'draft-js';
import omit from 'lodash/omit';

import type { TNoteBodyRTF } from '@/shared/types/note.types';

import type { EncounterModuleInstance } from '../Notes.types';
import { EncounterModuleId } from '../Notes.types';
import { getInstance } from './encounterModuleInstanceUtils';
import { getEncounterTypeInstance } from './encounterTypeUtils';

export function getInstancesMigratedToVisitLayout(
  instances: EncounterModuleInstance[],
  descriptionBody: TNoteBodyRTF,
) {
  return instances.map((instance) => {
    // Append the additional_information and description fields into the new notes_body field
    if (instance.encounter_module_id === EncounterModuleId.PatientNotes) {
      const additionalInfo =
        getEncounterTypeInstance(instances)?.inputs.additional_information;

      let newRtfBlocks = descriptionBody.blocks || [];
      if (additionalInfo) {
        const additionalInfoRtfBlock: RawDraftContentBlock = {
          key: 'additional-info',
          text: additionalInfo,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        };
        newRtfBlocks = [additionalInfoRtfBlock, ...newRtfBlocks];
      }

      return {
        ...instance,
        inputs: {
          ...instance.inputs,
          notes_body: {
            entityMap: descriptionBody.entityMap,
            blocks: newRtfBlocks,
          },
        },
      };
    }

    // Remove additional_information field, which is being copied into the notes_body field
    if (instance.encounter_module_id === EncounterModuleId.EncounterType) {
      return {
        ...instance,
        inputs: {
          ...omit(instance.inputs, 'additional_information'),
          // TODO: Remove this when feature flag is removed, making sure to follow https://cadencerpm.atlassian.net/browse/PLAT-4505
          visit_layout: true,
          has_meds_to_report: getReportingFieldValue(
            instances,
            EncounterModuleId.Medications,
          ),
          has_symptoms_to_report: getReportingFieldValue(
            instances,
            EncounterModuleId.Symptoms,
          ),
          has_emergency_visits_to_report: getReportingFieldValue(
            instances,
            EncounterModuleId.Hospitalization,
          ),
        },
      };
    }

    return instance;
  });
}

function getReportingFieldValue(
  instances: EncounterModuleInstance[],
  moduleId: EncounterModuleId,
) {
  return getInstance(instances, moduleId) ? true : undefined;
}
