import type { ReactNode } from 'react';

import { Select as MUISelect } from '@/deprecated/mui';
import {
  addInputClassname,
  addInputWrapperClassname,
} from '@/shared/common/Input';
import { SelectArrow } from '@/shared/common/SelectArrow';

import type { BaseFieldProps } from '../../BaseField';
import { BaseField } from '../../BaseField';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  parseValue?: (val: string) => unknown;
  inputClass?: string;
  ariaLabel?: string;
} & BaseFieldProps;

// TODO: Do we want this to be a native select?
// Should we use MUI's MenuItem to create non-native styles options?
export function Select({
  children,
  disabled,
  parseValue,
  inputClass,
  ariaLabel,
  ...baseProps
}: Props) {
  const { name } = baseProps;
  return (
    <BaseField {...baseProps}>
      {({ controller: { field, fieldState } }) => (
        <>
          <MUISelect
            {...field}
            disabled={disabled || baseProps.isDisabled}
            error={!!fieldState.error}
            className={addInputWrapperClassname(
              baseProps.isDisabled,
              baseProps.isReadOnly,
              !!fieldState.error,
              inputClass,
            )}
            id={name}
            labelId={name}
            native
            variant="outlined"
            inputProps={addInputClassname({ name, id: name })}
            onChange={({ target: { value } }) => {
              field.onChange(parseValue ? parseValue(value) : value);
            }}
            IconComponent={() => <SelectArrow />}
            aria-label={ariaLabel}
          >
            {children}
          </MUISelect>
        </>
      )}
    </BaseField>
  );
}
