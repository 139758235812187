import DOMPurify from 'dompurify';

// DOMPurify remove target attrs by default, so this changes the default behavior to open all links in a new window safely (rel="noopener")
// https://github.com/cure53/DOMPurify/blob/main/demos/README.md#hook-to-open-all-links-in-a-new-window-link
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export function sanitizeHTML(html: string) {
  return DOMPurify.sanitize(html);
}
