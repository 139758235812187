import { FormattedMessage } from 'react-intl';

import {
  Script,
  YesNoRadioGroup,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptLabel } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';

import type { ExerciseFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<ExerciseFormFields>;
};

export function ExerciseForm({ form }: Props) {
  const currentExercise = form.watch('currentExercise');

  return (
    <>
      <YesNoRadioGroup
        name="currentExercise"
        classes={{ label: scriptLabel }}
        label={
          <Script>
            <FormattedMessage defaultMessage="Do you exercise regularly?" />
          </Script>
        }
      />
      {currentExercise === 'true' && (
        <>
          <Script>
            <FormattedMessage defaultMessage="What type of exercise do you do? How often?" />
          </Script>
          <Form.TextArea
            size={12}
            rows={3}
            name="currentExerciseDescription"
            label={<FormattedMessage defaultMessage="Exercise activity" />}
            required
          />
        </>
      )}
    </>
  );
}
