import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';

import type { FormFields } from './formConfig';
import type { Goal } from './goals.types';

export function marshalFormDataToGoal(
  data: FormFields,
  prevData: Goal = {},
): Goal {
  const goalData = mergeWith(
    cloneDeep(prevData),
    cloneDeep(data),
    (_: unknown, srcValue: unknown, key: string) => {
      if (key === 'goalTasks') {
        return srcValue;
      }
      // For other keys, use default merging behavior
      return undefined;
    },
  );

  return {
    ...goalData,
    goalTasks: goalData.goalTasks?.filter((t) => !!t.description) || [],
  };
}

export function serializeGoalToFormData(
  data: FormFields,
  prevData: Goal = {},
): Goal {
  const goalData = merge(cloneDeep(prevData || {}), cloneDeep(data));
  return {
    ...goalData,
    goalTasks: goalData.goalTasks?.filter((t) => !!t.description) || [],
  };
}
