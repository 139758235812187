import cx from 'classnames';
import { useState } from 'react';

import type { Task } from '../types';
import { activeContainer, containerCompact } from './TaskCard.css';
import { TaskCardCompactContent } from './TaskCardCompactContent';
import { TaskCardContextProvider } from './taskCardContext';
import { isResolved } from './utils';

type Props = {
  task: Task;
  isActive?: boolean;
  onOpenTaskDetail?: (task: Task) => void;
};

export function TaskCardCompact({
  task,
  onOpenTaskDetail,
  isActive = false,
}: Props) {
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TaskCardContextProvider
      value={{ isHovering, isEditingComment, setIsEditingComment }}
    >
      <div
        className={cx(containerCompact.default, {
          [activeContainer]: isActive,
          [containerCompact.resolved]: isResolved(task),
        })}
        onFocus={() => setIsHovering(true)}
        onBlur={() => setIsHovering(false)}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          onOpenTaskDetail?.(task);
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onOpenTaskDetail?.(task);
          }
        }}
      >
        <TaskCardCompactContent
          task={task}
          onSelfAssignTask={() => onOpenTaskDetail?.(task)}
        />
      </div>
    </TaskCardContextProvider>
  );
}
