import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { CCMCarePlan } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { State as CarePlanState } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { Tag } from '@/shared/tempo/atom/Tag';

import { statusTag } from './StatusTag.css';

type Props = {
  carePlan: CCMCarePlan;
};

const STATE_TAG_LABEL: Record<CarePlanState, ReactNode> = {
  [CarePlanState.APPROVED]: (
    <FormattedMessage defaultMessage="Active Care Plan" />
  ),
  [CarePlanState.DRAFT]: <FormattedMessage defaultMessage="Draft" />,
  [CarePlanState.ARCHIVED_APPROVED]: (
    <FormattedMessage defaultMessage="Archived" />
  ),
  [CarePlanState.UNASSIGNED_APPROVAL]: (
    <FormattedMessage defaultMessage="Unassigned Approval" />
  ),
  [CarePlanState.CLINICALDOC_NOT_FOUND]: (
    <FormattedMessage defaultMessage="No Clinical Document Found" />
  ),
  [CarePlanState.STATE_UNSPECIFIED]: (
    <FormattedMessage defaultMessage="Unknown" />
  ),
};

export function StatusTag({ carePlan }: Props) {
  const isActive = carePlan?.state === CarePlanState.APPROVED;
  const isUnassignedApproval =
    carePlan?.state === CarePlanState.UNASSIGNED_APPROVAL;
  const isNoClinicalDocFound =
    carePlan?.state === CarePlanState.CLINICALDOC_NOT_FOUND;

  const getVariant = () => {
    if (isActive) return 'success';
    if (isUnassignedApproval || isNoClinicalDocFound) return 'error';
    return 'default';
  };

  return (
    <Tag className={statusTag} variant={getVariant()}>
      {STATE_TAG_LABEL[carePlan?.state ?? CarePlanState.STATE_UNSPECIFIED]}
    </Tag>
  );
}
