import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

type AthenaDepartment = { name: string; id: string };

type AthenaProvider = {
  provider_name: string;
  provider_id: string;
  provider_type: string;
};

const athenaKeyPrefix = (practiceId: string) => [
  'ehr',
  'api',
  'v1',
  'athena',
  practiceId,
];

const getAthenaDepartments = (practiceId: string) => [
  ...athenaKeyPrefix(practiceId),
  'departments',
];

const getAthenaProviders = (practiceId: string) => [
  ...athenaKeyPrefix(practiceId),
  'providers',
];

export function useAthenaDepartments(
  practiceId?: string,
  config?: UseQueryOptions<{ departments: AthenaDepartment[] }>,
) {
  return useQuery<{ departments: AthenaDepartment[] }>(
    getAthenaDepartments(practiceId || ''),
    {
      enabled: Boolean(practiceId),
      refetchOnWindowFocus: false,
      ...config,
    },
  );
}

type UseAthenaProvidersOptions = {
  enabled: boolean;
};

export function useAthenaProviders(
  practiceId?: string,
  options?: UseAthenaProvidersOptions,
) {
  return useQuery<{ providers: AthenaProvider[] }>(
    getAthenaProviders(practiceId || ''),
    {
      enabled: options?.enabled !== false && Boolean(practiceId),
      refetchOnWindowFocus: false,
    },
  );
}
