import {
  type SectionPath,
  type StepPath,
  getFullStepPath,
} from '@/shared/common/Wizard/path.utils';

import { CYCLE_17_INIT_VISIT_SECTIONS } from '../metadata';

export function cycle17InitVisitPath<
  P extends SectionPath<typeof CYCLE_17_INIT_VISIT_SECTIONS>,
>(
  basePath: P,
  stepPath: StepPath<typeof CYCLE_17_INIT_VISIT_SECTIONS, P>,
): string {
  return getFullStepPath(CYCLE_17_INIT_VISIT_SECTIONS, basePath, stepPath);
}
