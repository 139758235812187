import { useMemo } from 'react';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import {
  AsyncTitrationAsyncTitrationStatus,
  type Medication,
} from '@/shared/generated/grpcGateway/medication.pb';

export function usePendingTitrationList(meds?: Medication[]) {
  return useMemo(
    () =>
      (meds ?? []).flatMap(
        (med) =>
          med.medChanges
            ?.map((medChange) =>
              getTitrationRecommendation(medChange, undefined, true, false),
            )
            .filter(
              (asyncTitration) =>
                asyncTitration.status ===
                AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED,
            ),
      ),
    [meds],
  );
}
