import {
  addDays,
  formatDistance,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { UseInfiniteQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

import { Divider, IconButton } from '@/deprecated/mui';
import { PatientListEmptyResults } from '@/pages/patients/PatientListDashboard/PatientListEmptyResults';
import { usePatientProfileCtx } from '@/pages/patients/PatientProfile/context/PatientProfileCtx';
import type { UnsuppressedReadings } from '@/pages/utils/suppressed-vitals-helper';
import {
  getPendedAlertVitals,
  getUnsuppressedReadings,
  groupVitalsByDateAndType,
} from '@/pages/utils/suppressed-vitals-helper';
import { useFetchAllPages, useFlatPages } from '@/reactQuery';
import GraphIcon from '@/shared/assets/icons/GraphIcon';
import TableIcon from '@/shared/assets/icons/TableIcon';
import VitalsIcon from '@/shared/assets/icons/VitalsIcon';
import SearchIcon from '@/shared/assets/svgs/search2.svg?react';
import { InfiniteScrollLoader } from '@/shared/common/InfiniteScrollLoader';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { SkeletonTable } from '@/shared/common/SkeletonTable';
import { Snackbar } from '@/shared/common/Snackbar';
import { VitalsGraphLegend } from '@/shared/common/VitalsGraphLegend';
import { VitalsScatterplotChart } from '@/shared/common/VitalsScatterplotChart';
import type { VitalsAlertSchema } from '@/shared/generated/api/pms';
import { type ListPatientVitalsResponseVital } from '@/shared/generated/grpcGateway/telemetry.pb';
import {
  useGetAlertsSummary,
  useListPatientVitals,
} from '@/shared/hooks/queries/vitals-summary.queries';
import { VitalsTable } from '@/shared/patient/VitalsTable/VitalsTable';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type { RouteParam } from '@/shared/types/route.types';
import type { VitalsForDateType } from '@/shared/types/vitals.types';
import { VitalType } from '@/shared/types/vitals.types';
import { getErrorMsg } from '@/shared/utils/helpers';
import { formatISODateInUTC } from '@/shared/utils/time-helpers';
import {
  groupVitalsByType,
  tempMapGraphData,
} from '@/shared/utils/vitals-graph-helpers';

import { TimeRangeDropdown } from './TimeRangeDropdown';
import { columnWidth } from './Vitals.css';
import './Vitals.scss';
import { VitalsDropdown } from './VitalsDropdown';

type RequestParams = {
  patientId: string;
  dateFrom: string;
  dateTo: string;
};

type VitalsViewProps = {
  requestParams: RequestParams;
  showLoadingSpinner?: boolean;
};

type VitalsGraphsViewProps = {
  selectedVital: VitalType;
  requestParams: RequestParams;
};

const VitalsGraphView = ({
  selectedVital,
  requestParams,
}: VitalsGraphsViewProps) => {
  const vitalsQuery = useFetchAllPages(
    useListPatientVitals(requestParams) as UseInfiniteQueryResult<
      PaginatedData<ListPatientVitalsResponseVital, 'data'>
    >,
  );
  const isExhausted = !vitalsQuery.hasNextPage && vitalsQuery.isSuccess;

  const vitals = useFlatPages(vitalsQuery, 'data');
  const vitalsByType: VitalsForDateType = groupVitalsByType(
    vitals?.filter(Boolean)?.length > 0 ? vitals : [],
  );
  const { vitalsStartDate, vitalsEndDate } = usePatientProfileCtx();
  const graph = tempMapGraphData(vitalsByType as VitalsForDateType);

  return (
    <>
      <div
        className="vitals-graph-container"
        data-testid="vitals-graph-container"
      >
        {!isExhausted ? (
          <LoadingPlaceholder isLoading />
        ) : (
          <VitalsScatterplotChart
            graphs={graph[selectedVital]}
            startDate={startOfDay(vitalsStartDate)}
            endDate={startOfDay(addDays(vitalsEndDate, 1))}
          />
        )}
      </div>
      <VitalsGraphLegend vitalType={selectedVital} />
      {vitalsQuery.error && (
        <Snackbar variant="error" message={getErrorMsg(vitalsQuery.error)} />
      )}
    </>
  );
};

export const VitalsView = ({
  requestParams,
  showLoadingSpinner = true,
}: VitalsViewProps) => {
  const intl = useIntl();
  const vitalsQuery = useListPatientVitals(requestParams);
  const vitals = useFlatPages<ListPatientVitalsResponseVital, 'data'>(
    vitalsQuery,
  );
  const groupedVitals = groupVitalsByDateAndType(vitals);

  const {
    data: patientAlerts,
    isLoading: isLoadingAlerts,
    isError: isErrorAlerts,
    error: alertsError,
  } = useGetAlertsSummary(requestParams);

  const unsupressedReadings =
    !isLoadingAlerts && !isErrorAlerts
      ? getUnsuppressedReadings(patientAlerts?.data as VitalsAlertSchema[])
      : ({} as UnsuppressedReadings);

  const unsupressedVitals = getPendedAlertVitals(
    groupedVitals,
    unsupressedReadings,
  );

  const { dateFrom, dateTo } = requestParams;
  // Subtracting one day as we want to count days in inclusive manner
  const daysDelta = formatDistance(
    subDays(parseISO(dateFrom), 1),
    parseISO(dateTo),
  );
  return (
    <>
      <LoadingPlaceholder
        isLoading={vitalsQuery.isLoading || isLoadingAlerts}
        placeholder={showLoadingSpinner ? undefined : <></>}
      >
        <div className="table-body-container">
          {unsupressedVitals && !isEmpty(unsupressedVitals) ? (
            <>
              <VitalsTable
                patientId={requestParams.patientId}
                vitalsByDateMap={unsupressedVitals}
              />
              <InfiniteScrollLoader
                query={vitalsQuery}
                loadingPlaceholder={
                  <SkeletonTable className={columnWidth} columns={5} rows={2} />
                }
              />
            </>
          ) : (
            <PatientListEmptyResults
              icon={<SearchIcon />}
              message={intl.formatMessage(
                {
                  defaultMessage: 'No vitals in the past {days}',
                },
                { days: daysDelta },
              )}
            />
          )}
        </div>
      </LoadingPlaceholder>
      {vitalsQuery.error && (
        <Snackbar variant="error" message={getErrorMsg(vitalsQuery.error)} />
      )}
      {alertsError && (
        <Snackbar variant="error" message={getErrorMsg(alertsError)} />
      )}
    </>
  );
};

export const Vitals = () => {
  const { vitalsStartDate, vitalsEndDate, setVitalsStartDate } =
    usePatientProfileCtx();
  const [isGraphDisplayed, setIsGraphDisplayed] = useState(false);
  const [selectedVital, setSelectedVital] = useState(VitalType.BloodPressure);

  const { patientId }: RouteParam = useParams();

  const requestParams = {
    patientId,
    dateFrom: encodeURI(formatISODateInUTC(vitalsStartDate)),
    dateTo: encodeURI(formatISODateInUTC(vitalsEndDate)),
  };
  return (
    <div className="table-container vitals-table" style={{ maxHeight: 'none' }}>
      <div className="vitals-header-container">
        <div className="vitals-title">
          <VitalsIcon />
          <span className="vitals-title-text">
            <FormattedMessage defaultMessage="Vitals" />
          </span>
        </div>
        <div className="vitals-dropdowns">
          {isGraphDisplayed && (
            <VitalsDropdown
              onVitalSelect={setSelectedVital}
              selectedVital={selectedVital}
            />
          )}
          <TimeRangeDropdown onStartDateSelect={setVitalsStartDate} />
        </div>
        <IconsSection
          isGraphDisplayed={isGraphDisplayed}
          onIconClick={setIsGraphDisplayed}
        />
      </div>
      <Divider className="table-divider" />
      {isGraphDisplayed ? (
        <VitalsGraphView
          selectedVital={selectedVital}
          requestParams={requestParams}
        />
      ) : (
        <VitalsView requestParams={requestParams} />
      )}
    </div>
  );
};

const IconsSection = ({
  isGraphDisplayed,
  onIconClick,
}: {
  isGraphDisplayed: boolean;
  onIconClick: Function;
}) => (
  <div>
    <IconButton
      id="tableIcon"
      data-testid="vitals-table-icon-button"
      size="small"
      onClick={() => onIconClick(false)}
    >
      <TableIcon active={isGraphDisplayed} />
    </IconButton>
    <IconButton
      id="graphIcon"
      data-testid="vitals-graph-icon-button"
      size="small"
      onClick={() => onIconClick(true)}
    >
      <GraphIcon active={isGraphDisplayed} />
    </IconButton>
  </div>
);
