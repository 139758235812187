import { intlReducer } from 'react-intl-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import npiReducer from '@/pages/adminPanel/npi/reducer';
import authReducer from '@/pages/auth/reducer';
import patientDetailsReducer from '@/pages/patients/patientDetails/reducer';
import labsReducer from '@/pages/patients/patientDetails/reducer/labsReducer';
import patientContactsReducer from '@/pages/patients/patientDetails/reducer/patientContactsReducer';

const reducers = combineReducers({
  intl: intlReducer,
  auth: authReducer,
  patientDetails: patientDetailsReducer,
  patientContacts: patientContactsReducer,
  labs: labsReducer,
  npi: npiReducer,
  form: formReducer,
});

// eslint-disable-next-line import/no-default-export
export default reducers;
