import type { WidgetProps } from '@rjsf/core';
import type { ChangeEvent } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@/deprecated/mui';
import { logger } from '@/logger';
import { flexContainer } from '@/shared/jsStyle/flex.css';

import { formLabel, radioGroupGrid } from '../SchemaDrivenForm.css';
import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';

export function RadioWidget({
  onChange: rjsfOnChange,
  value,
  options,
  uiSchema: {
    enumLabels,
    'ui:hiddenOptions': hiddenOptions,
    'ui:grid': isGrid = false,
  },
}: WidgetProps) {
  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  const { enumOptions } = options;

  if (!Array.isArray(enumOptions)) {
    logger.error('Unexpected enumOptions data type');
    return null;
  }

  return (
    <RadioGroup
      className={isGrid ? radioGroupGrid : flexContainer.row}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
      value={value || null}
    >
      {enumOptions
        .filter((option) => !hiddenOptions?.includes(option.value))
        .map((option) => (
          <FormControlLabel
            key={option.value}
            classes={{
              label: formLabel,
            }}
            control={<Radio />}
            label={enumLabels?.[option.value] ?? option.label}
            value={option.value}
          />
        ))}
    </RadioGroup>
  );
}
