/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OutboundSMSSchema } from '../models/OutboundSMSSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class OutboundSmsService {
    /**
     * Send an sms to a patient
     * @param requestBody A JSON object containing Outbound SMS data
     * @returns any Successful operation
     * @throws ApiError
     */
    public static postNotificationApiV1CommunicationsSendSms(
        requestBody: OutboundSMSSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notification/api/v1/communications/send_sms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }
}
