import type { Dispatch, SetStateAction } from 'react';

import { createCtx } from '@/shared/hooks/createCtx';

type ModalContext = {
  onClose: () => void;
  isLoading: boolean;
  disabled: boolean;
  isBodyScrollVisible: boolean;
  setIsBodyScrollVisible: Dispatch<SetStateAction<boolean>>;
};

export const [useModalContext, ModalContextProvider] =
  createCtx<ModalContext>('ModalContext');
