import type { ReactNode } from 'react';

import { container } from './Header.css';

type Props = {
  children?: ReactNode;
};

export function Header({ children }: Props) {
  return <div className={container}>{children}</div>;
}
