{{! this template is used in both regularCnVisit/t2d and regularCnVisit/t2dAndHtn }}
{{> header val="HPI" }}
{{> patient_summary }}
{{> free_text_space }}

{{> subheader val="Recent Vital Trends" }}
{{> vitals_list }}
<br/>

{{#if flags.autopopulateEncounterLabs}}
{{> header val="Lab Results" }}
{{> latest_labs }}
<br/>
{{else}}
{{> header val="Lab Results" }}
{{! t2d, t2d+htn only }}
{{> subheader val="Last A1C, Basic Metabolic Panel (BMP) and Lipid Panel" }}
{{> free_text_space }}
{{! /t2d, t2d+htn only }}
{{/if}}

{{> subheader val="Medication allergies" }}
{{> free_text_space }}

{{> subheader val="Patient preferred pharmacy" }}
{{> free_text_space }}
