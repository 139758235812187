import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import SyncIcon from '@/shared/assets/svgs/sync.svg?react';

import { noSyncDate, syncDateInfo, syncIcon } from './LastSyncDate.css';

type Props = {
  syncDate: Maybe<Date>;
};

export function LastSyncDate({ syncDate }: Props) {
  return (
    <div className={syncDateInfo}>
      <SyncIcon className={syncIcon} />
      {syncDate ? (
        <FormattedMessage
          defaultMessage="Last EMR sync on {date} at {time}"
          values={{
            date: format(syncDate, 'MM/d/yyyy'),
            time: format(syncDate, 'h:mm a'),
          }}
        />
      ) : (
        <div className={noSyncDate}>
          <FormattedMessage defaultMessage="EMR not synced" />
        </div>
      )}
    </div>
  );
}
