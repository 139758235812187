import { FormattedMessage } from 'react-intl';

import { PatientActivity } from '@/components/AsyncTitration/PatientActivity';
import { removeItem } from '@/lang.utils';
import { logger } from '@/logger';
import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { usePatientDetailsCtx } from '@/pages/patients/PatientProfile/PatientDetailContext';
import FileIcon from '@/shared/assets/svgs/file.svg?react';
import MedicalHistory from '@/shared/assets/svgs/medicalHistory.svg?react';
import UserIcon from '@/shared/assets/svgs/user.svg?react';
import { useFlags } from '@/shared/hooks';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';
import { color } from '@/shared/tempo/theme';
import { CareModelVersion } from '@/shared/types/featureFlags.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import type {
  EncounterModuleInstance,
  EncounterTypeInputs,
} from '../../Notes.types';
import {
  EncounterModuleId as ModuleId,
  TypeOfEncounter,
} from '../../Notes.types';
import {
  isAdHocClinicalEncounterType,
  isClinicalNavigatorEncounterType,
  isNPEncounterType,
  isPreV3ClinicalNavigatorEncounterType,
  isRegularVisitClinicianType,
  showClinicalAttestation,
} from '../../utils/encounterTypeUtils';
import { RecentHospitalizationForm as EmergencyVisitsForm } from '../RecentHospitalizationForm';
import { SymptomsForm } from '../SymptomsForm';
import {
  requireMedActionsInEncounter,
  showMedReview,
} from '../utils/medReviewUtils';
import type {
  NoteFieldValidationResult,
  NoteFormSubmissionType,
} from '../validation';
import { CarePlanButton } from './CarePlanButton';
import { CarePlanForm } from './CarePlanForm';
import { ClinicalAttestationForm } from './ClinicalAttestationForm';
import { ClinicalGoalReachedForm } from './ClinicalGoalReachedForm';
import type { RequirableEncounterModule } from './EncounterModule';
import { EncounterModule } from './EncounterModule';
import { GeneralAssessmentAndPlanForm } from './GeneralAssessmentAndPlanForm';
import { PatientDeclinedMedReviewToggle } from './PatientDeclinedMedReviewToggle';
import { PatientNotesForm } from './PatientNotesForm';
import { VisitLayoutSection } from './VisitLayoutSection';

type Props = {
  encounterTypeInstance?: EncounterModuleInstance<EncounterTypeInputs>;
  onChangeInstance: (value: EncounterModuleInstance<{}>) => void;
  validationResults: NoteFieldValidationResult[];
  values: EncounterModuleInstance<{}>[];
  noteFormSubmissionType: NoteFormSubmissionType;
  noteId: Maybe<number>;
};

export const EncounterModuleSections = ({
  encounterTypeInstance,
  onChangeInstance,
  validationResults,
  values,
  noteFormSubmissionType,
  noteId,
}: Props) => {
  const { patientDetails } = usePatientDetailsCtx();
  const { editingNote } = useNoteEditorContext();
  const { careModelVersion, structuredClinicalGoalReached } = useFlags();
  const encounterType = encounterTypeInstance?.inputs.type_of_encounter;

  function getFormProps(moduleId: ModuleId) {
    return {
      initialValues: getModuleValues(moduleId, values),
      onChange: onChangeInstance,
      shouldShowValidation: getValidationState(moduleId, validationResults),
    };
  }

  const getModuleProps = useGetModuleProps(
    onChangeInstance,
    validationResults,
    encounterTypeInstance,
  );

  const emergencyVisitsModule = (
    <EncounterModule
      form={<EmergencyVisitsForm {...getFormProps(ModuleId.Hospitalization)} />}
      {...getModuleProps(ModuleId.Hospitalization)}
    />
  );
  const symptomsModule = (
    <EncounterModule
      form={<SymptomsForm {...getFormProps(ModuleId.Symptoms)} />}
      {...getModuleProps(ModuleId.Symptoms)}
    />
  );

  const patientNotesForm = (
    <PatientNotesForm {...getFormProps(ModuleId.PatientNotes)} />
  );

  if (!encounterType) {
    return null;
  }

  const isCareModelV3 = careModelVersion === CareModelVersion.V3;
  const isCnVisit = isClinicalNavigatorEncounterType(encounterType);

  const medsModuleProps = getModuleProps(ModuleId.Medications);

  const medsModule = (
    <>
      <PatientMedicationsList
        noteId={editingNote?.note?.id}
        patientId={patientDetails.id}
        showRequiredActions={requireMedActionsInEncounter(
          noteFormSubmissionType,
          values,
        )}
        medPermissions={
          isCnVisit ? MedPermissions.Review : MedPermissions.Titrate
        }
        typeOfEncounter={encounterType}
      />
      {showMedReview(values) && (
        <PatientDeclinedMedReviewToggle
          value={!medsModuleProps.hasInfoToReport}
          onChange={(val) => medsModuleProps.onChangeHasInfoToReport(!val)}
        />
      )}
    </>
  );

  if (encounterType === TypeOfEncounter.ASYNC_REVIEW) {
    return (
      <>
        <PatientActivity patientId={patientDetails.id} />
        {medsModule}
      </>
    );
  }

  if (encounterType === TypeOfEncounter.TITRATION_OUTREACH) {
    return (
      <>
        <PatientActivity patientId={patientDetails.id} />
        <VisitLayoutSection
          title={<FormattedMessage defaultMessage="Clinical Overview" />}
          icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
        >
          {medsModule}
          {symptomsModule}
          {patientNotesForm}
        </VisitLayoutSection>
      </>
    );
  }

  if (isCareModelV3 && isCnVisit) {
    const generalAssessmentAndPlanForm = (
      <GeneralAssessmentAndPlanForm
        {...getFormProps(ModuleId.GeneralAssessmentAndPlan)}
      />
    );

    return (
      <>
        <VisitLayoutSection
          title={<FormattedMessage defaultMessage="Clinical Overview" />}
          icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
        >
          {patientNotesForm}
          {symptomsModule}
          {medsModule}
        </VisitLayoutSection>

        <VisitLayoutSection
          title={<FormattedMessage defaultMessage="General Assessment" />}
          icon={
            <MedicalHistory
              fill={color.Theme.Light['Base Font']}
              width="20"
              height="20"
            />
          }
        >
          {generalAssessmentAndPlanForm}
        </VisitLayoutSection>
      </>
    );
  }

  if (
    isRegularVisitClinicianType(encounterType) ||
    isPreV3ClinicalNavigatorEncounterType(careModelVersion, encounterType)
  ) {
    const generalAssessmentAndPlanForm = (
      <GeneralAssessmentAndPlanForm
        {...getFormProps(ModuleId.GeneralAssessmentAndPlan)}
      />
    );
    const clinicalAttestationForm = (
      <ClinicalAttestationForm
        {...getFormProps(ModuleId.ClinicalAttestation)}
      />
    );
    const clinicalGoalReachedForm = patientDetails && (
      <ClinicalGoalReachedForm
        key={encounterTypeInstance?.inputs.type_of_encounter}
        conditions={getRpmConditionsFromProgramAndStatus(
          patientDetails.programs,
          patientDetails.status,
        )}
        encounterTypeInstance={encounterTypeInstance}
        {...getFormProps(ModuleId.ClinicalGoalReached)}
      />
    );
    return (
      <>
        <VisitLayoutSection
          title={<FormattedMessage defaultMessage="Clinical Overview" />}
          icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
        >
          {patientNotesForm}
          {emergencyVisitsModule}
          {symptomsModule}
          {medsModule}
        </VisitLayoutSection>

        <VisitLayoutSection
          title={<FormattedMessage defaultMessage="Assessment + Plan" />}
          icon={
            <MedicalHistory
              fill={color.Theme.Light['Base Font']}
              width="20"
              height="20"
            />
          }
        >
          {generalAssessmentAndPlanForm}
          {showClinicalAttestation(encounterType, careModelVersion) &&
            clinicalAttestationForm}
          {structuredClinicalGoalReached &&
            isNPEncounterType(encounterType) &&
            clinicalGoalReachedForm}
        </VisitLayoutSection>
      </>
    );
  }

  if (isAdHocClinicalEncounterType(encounterType)) {
    return (
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Encounter Note" />}
        icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
      >
        {patientNotesForm}
        {medsModule}
        {emergencyVisitsModule}
        {symptomsModule}
      </VisitLayoutSection>
    );
  }

  if (encounterType === TypeOfEncounter.CCM_CARE_PLAN) {
    return (
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Care Plan" />}
        icon={<FileIcon />}
      >
        <CarePlanButton noteId={noteId} />
        <CarePlanForm {...getFormProps(ModuleId.CarePlan)} />
        {/* TODO: we want to hide ED visits for care plans
        but the validation is blocking us right now. */}
        {emergencyVisitsModule}
      </VisitLayoutSection>
    );
  }

  return (
    <VisitLayoutSection
      title={<FormattedMessage defaultMessage="Encounter Note" />}
      icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
    >
      {patientNotesForm}
    </VisitLayoutSection>
  );
};

export type ReportingField =
  | 'has_meds_to_report'
  | 'has_symptoms_to_report'
  | 'has_emergency_visits_to_report';

export const ReportingFields = {
  [ModuleId.Medications]: 'has_meds_to_report',
  [ModuleId.Symptoms]: 'has_symptoms_to_report',
  [ModuleId.Hospitalization]: 'has_emergency_visits_to_report',
} as {
  [key in RequirableEncounterModule]: ReportingField;
};

function useGetModuleProps(
  onChangeInstance: (value: EncounterModuleInstance<{}>) => void,
  validationResults: NoteFieldValidationResult[],
  encounterTypeInstance?: EncounterModuleInstance<EncounterTypeInputs>,
) {
  const { encounterModuleInstances, setEncounterModuleInstances } =
    useNoteEditorContext();

  return function getModuleProps(moduleId: keyof typeof ReportingFields) {
    const fieldName = ReportingFields[moduleId];
    return {
      moduleId,
      hasInfoToReport: encounterTypeInstance?.inputs[fieldName],
      isPatientNoShow: encounterTypeInstance?.inputs.patient_no_show === true,
      encounterType: encounterTypeInstance?.inputs.type_of_encounter,
      onChangeHasInfoToReport: (hasInfoToReport: boolean) => {
        if (!encounterTypeInstance) {
          logger.error('Encounter type instance not initialized');
          return;
        }
        const newInstance = { ...encounterTypeInstance };
        newInstance.inputs[fieldName] = hasInfoToReport;
        onChangeInstance(newInstance);

        // Remove instance if nothing to report option is selected
        if (hasInfoToReport === false) {
          const newInstances = removeItem(
            encounterModuleInstances,
            ({ encounter_module_id }) => encounter_module_id === moduleId,
          );
          setEncounterModuleInstances(newInstances);
        }
      },
      validationResults: getFieldValidationResults(
        validationResults,
        fieldName,
      ),
    };
  };
}

function getModuleValues(
  moduleId: ModuleId,
  values: EncounterModuleInstance<{}>[],
) {
  return values.find(
    ({ encounter_module_id }) => encounter_module_id === moduleId,
  )?.inputs;
}

function getValidationState(
  moduleId: ModuleId,
  validationResults: NoteFieldValidationResult[],
) {
  return validationResults
    .map((result) => result.encounterModuleId)
    .includes(moduleId);
}

function getFieldValidationResults(
  validationResults: NoteFieldValidationResult[],
  field: ReportingField,
) {
  return validationResults.filter(
    (result) => result.params?.argument === field,
  );
}
