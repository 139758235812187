import {
  ENCOUNTER_TYPE_TITLE,
  PATIENT_FORMATTED_MESSAGES,
  TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES,
} from '../NoteEditor/i18nMappings';
import { type EncounterTypeInputs, TypeOfEncounter } from '../Notes.types';
import { modulePreviewSection } from './NotePreview.css';

export const formatEncounterTypeInputs = ({
  type_of_encounter: typeOfEncounter,
  additional_information: additionalInformation,
  ...additionalTypes
}: EncounterTypeInputs) => {
  // TODO: logger.error if schema shape is not what was expected

  const typeOfEncounterMessage = typeOfEncounter && (
    <div>
      {TYPE_OF_ENCOUNTER_FORMATTED_MESSAGES[typeOfEncounter] || typeOfEncounter}
    </div>
  );

  // titration outreach encounter has its own no-show language baked in, so
  // removing this message in favor of the encounter-specific language
  const shouldHideNoShowMessage =
    typeOfEncounter === TypeOfEncounter.TITRATION_OUTREACH;

  const additionalTypesMessages = Object.entries(additionalTypes)
    .filter(([key]) => key !== 'patient_no_show' || !shouldHideNoShowMessage)
    .map(
      ([key, value]) =>
        value && (
          <div key={key}>
            {
              PATIENT_FORMATTED_MESSAGES[
                key as keyof typeof PATIENT_FORMATTED_MESSAGES
              ]
            }
          </div>
        ),
    )
    .filter(Boolean);

  const additionInfoMessage = additionalInformation && (
    <div>{additionalInformation}</div>
  );

  const hasAnyEncounterTypeInputs = Boolean(
    typeOfEncounterMessage ||
      additionalTypesMessages.length ||
      additionInfoMessage,
  );
  return (
    hasAnyEncounterTypeInputs && (
      <div key="type-of-encounter-preview" className={modulePreviewSection}>
        <b>{ENCOUNTER_TYPE_TITLE}:</b>
        {typeOfEncounterMessage}
        {additionalTypesMessages}
        {additionInfoMessage}
      </div>
    )
  );
};
