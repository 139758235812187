/* eslint-disable */
// @generated by protobuf-ts 2.9.1 with parameter long_type_string,add_pb_suffix,eslint_disable
// @generated from protobuf file "go/pms/pkg/patient/pms.proto" (package "go.pms.pkg.patient", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";



/**
 * @generated from protobuf message go.pms.pkg.patient.PatientChangeNotificationsRequest
 */
export interface PatientChangeNotificationsRequest {
    /**
     * @generated from protobuf field: string patient_id = 1;
     */
    patientId: string;
}
/**
 * @generated from protobuf message go.pms.pkg.patient.PatientChangeNotificationResponse
 */
export interface PatientChangeNotificationResponse {
    /**
     * @generated from protobuf field: bool activated = 1;
     */
    activated: boolean;
    /**
     * @generated from protobuf field: string patient_id = 2;
     */
    patientId: string;
}


// @generated message type with reflection information, may provide speed optimized methods
class PatientChangeNotificationsRequest$Type extends MessageType<PatientChangeNotificationsRequest> {
    constructor() {
        super("go.pms.pkg.patient.PatientChangeNotificationsRequest", [
            { no: 1, name: "patient_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<PatientChangeNotificationsRequest>): PatientChangeNotificationsRequest {
        const message = { patientId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PatientChangeNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PatientChangeNotificationsRequest): PatientChangeNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string patient_id */ 1:
                    message.patientId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PatientChangeNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string patient_id = 1; */
        if (message.patientId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.patientId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.patient.PatientChangeNotificationsRequest
 */
export const PatientChangeNotificationsRequest = new PatientChangeNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PatientChangeNotificationResponse$Type extends MessageType<PatientChangeNotificationResponse> {
    constructor() {
        super("go.pms.pkg.patient.PatientChangeNotificationResponse", [
            { no: 1, name: "activated", kind: "scalar", T: 8 /*ScalarType.BOOL*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "patient_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<PatientChangeNotificationResponse>): PatientChangeNotificationResponse {
        const message = { activated: false, patientId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PatientChangeNotificationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PatientChangeNotificationResponse): PatientChangeNotificationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool activated */ 1:
                    message.activated = reader.bool();
                    break;
                case /* string patient_id */ 2:
                    message.patientId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PatientChangeNotificationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool activated = 1; */
        if (message.activated !== false)
            writer.tag(1, WireType.Varint).bool(message.activated);
        /* string patient_id = 2; */
        if (message.patientId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.patientId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message go.pms.pkg.patient.PatientChangeNotificationResponse
 */
export const PatientChangeNotificationResponse = new PatientChangeNotificationResponse$Type();
/**
 * @generated ServiceType for protobuf service go.pms.pkg.patient.PatientService
 */
export const PatientService = new ServiceType("go.pms.pkg.patient.PatientService", [    
    { name: "PatientChangeNotifications", serverStreaming: true, options: { "google.api.http": { get: "/rpm/v1/patient:changeNotifications" } }, I: PatientChangeNotificationsRequest, O: PatientChangeNotificationResponse }
]);
