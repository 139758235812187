import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { EndCallReason } from './formConfig';

export function getEndCallReasonI18n(
  intl: IntlShape,
): Record<EndCallReason, string> {
  return {
    [EndCallReason.PatientUnableToSpeak]: intl.formatMessage({
      defaultMessage: 'Patient unable to speak at this time',
    }),
    [EndCallReason.SchedulingErrorDisenrollment]: intl.formatMessage({
      defaultMessage: 'Scheduling error / disenrollment',
    }),
    [EndCallReason.PatientInNonCadenceState]: intl.formatMessage({
      defaultMessage: 'Patient in a non-Cadence state',
    }),
    [EndCallReason.PatientHospitalized]: intl.formatMessage({
      defaultMessage: 'Patient currently hospitalized',
    }),
    [EndCallReason.PatientRefusedAppointment]: intl.formatMessage({
      defaultMessage: 'Patient refused appointment',
    }),
    [EndCallReason.PatientDissatisfied]: intl.formatMessage({
      defaultMessage: 'Patient dissatisfied',
    }),
    [EndCallReason.TimeConstraints]: intl.formatMessage({
      defaultMessage: 'Time constraints',
    }),
    [EndCallReason.DeviceDelayTroubleshooting]: intl.formatMessage({
      defaultMessage: 'Device delay / troubleshooting',
    }),
    [EndCallReason.Other]: intl.formatMessage({
      defaultMessage: 'Other',
    }),
  };
}

export function useEndCallReasonI18n() {
  const intl = useIntl();
  return getEndCallReasonI18n(intl);
}
