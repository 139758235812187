export class MarkdownBuilder {
  markdown: string;

  constructor() {
    this.markdown = '';
  }

  h1(text: string) {
    this.newline();
    this.markdown += `# ${escape(text)}`;
  }

  h2(text: string) {
    this.newline();
    this.markdown += `## ${escape(text)}`;
  }

  h3(text: string) {
    this.newline();
    this.markdown += `### ${escape(text)}`;
  }

  h4(text: string) {
    this.newline();
    this.markdown += `#### ${escape(text)}`;
  }

  h5(text: string) {
    this.newline();
    this.markdown += `##### ${escape(text)}`;
  }

  h6(text: string) {
    this.newline();
    this.markdown += `###### ${escape(text)}`;
  }

  p(text: string) {
    this.newline();
    this.markdown += escape(text);
    this.newline();
  }

  uli(text: string, indent = 0) {
    this.newline();
    for (let i = 0; i < indent; i++) {
      this.markdown += '  ';
    }
    this.markdown += `- ${escape(text)}`;
  }

  oli(text: string, idx: number, indent = 0) {
    this.newline();
    for (let i = 0; i < indent; i++) {
      this.markdown += '  ';
    }
    this.markdown += `${idx + 1}. ${escape(text)}`;
  }

  field(label: string, value: unknown) {
    this.newline();
    this.markdown += fieldMarkdown(escape(label), escape(value));
  }

  concat(markdown: string) {
    this.markdown += markdown;
  }

  newline() {
    this.markdown += newline();
  }

  fieldWithDescription(
    label: string,
    value: 'true' | 'false' | undefined,
    description: {
      description: string | undefined;
      whenVal: 'true' | 'false';
      elseVal?: string;
    },
  ) {
    if (value === description.whenVal) {
      this.field(
        label,
        [formatValue(value), description.description]
          .filter(Boolean)
          .join(', '),
      );
    } else if (description.elseVal !== undefined) {
      this.field(label, description.elseVal);
    } else {
      this.field(label, value);
    }
  }

  toString() {
    return this.markdown;
  }
}

/**
 * MD Utils
 */
function fieldMarkdown(label: string, value: unknown) {
  const trimmedLabel = label.trim();
  const shouldAddColon = /[a-zA-Z)\]]$/.test(trimmedLabel);
  // Separate field from value using colon if label ends with an alpha char
  const delimeter = shouldAddColon ? ':' : '';
  return `- ${trimmedLabel}${delimeter} ${formatValue(value)}`;
}

function formatValue(val: unknown) {
  if (typeof val === 'string') {
    if (val === 'true') {
      return 'Yes';
    }
    if (val === 'false') {
      return 'No';
    }
    if (!val.trim()) {
      return 'N/A';
    }
  }
  return val ?? 'N/A';
}

function newline() {
  return '\n';
}

function escape(val: undefined | null): undefined | null;
function escape<T>(
  val: Exclude<T, null | undefined>,
): Exclude<T, null | undefined>;
function escape<T>(val: Maybe<T>): Maybe<T> {
  if (val === undefined || val === null) {
    return val;
  }
  if (typeof val === 'string') {
    const markdownSpecialChars = /([\\*_{}[\]()#+\-.!|])/g;
    return (val as string)
      .trim()
      .replace(markdownSpecialChars, '\\$1') as Maybe<T>;
  }
  return val;
}
