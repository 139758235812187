import { draftJsFieldTemplate } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/template.utils';
import { EncounterModuleId } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import notesBody from '../../__shared__/patient_notes.hbs';

export const patientNotesModule = {
  [EncounterModuleId.PatientNotes]: {
    notes_body: draftJsFieldTemplate(notesBody),
  },
};
