import { FormattedMessage, useIntl } from 'react-intl';

import { usePatientDetails } from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { useJitQueryEnabler } from '@/shared/hooks/useJitQueryEnabler';
import type { Task } from '@/shared/tasking/types';

import { formatProviderName } from '../utils';
import { title } from './styles.css';
import { useShouldShowPatientDetails } from './useShouldShowPatientDetails';

type Props = {
  task: Task;
};

export function OpenTaskTitle({ task }: Props) {
  const intl = useIntl();
  const { assignee, patientId } = task;
  const { queryEnabled, inViewRef } = useJitQueryEnabler();
  const shouldShowPatientDetails = useShouldShowPatientDetails();
  const { data: patientDetails } = usePatientDetails(
    patientId || '',
    true,
    queryEnabled && shouldShowPatientDetails && Boolean(patientId),
  );
  const { currentUserId } = useCurrentUser();
  const state = patientDetails?.addresses?.find(({ primary }) => primary)
    ?.region;

  // this case cannot happen because we handle unassigned tasks in a separate
  // component, QueueTaskTitle. we need to handle it here to make TS happy.
  if (!assignee) {
    return null;
  }

  const assigneeText =
    assignee.uid === currentUserId
      ? intl.formatMessage({ defaultMessage: 'you' })
      : formatProviderName(assignee);

  let text;

  if (shouldShowPatientDetails && patientDetails?.patient) {
    text = (
      <FormattedMessage
        defaultMessage="Assigned to {assignee} for patient {patient}, in {state}"
        values={{
          patient: `${patientDetails?.patient?.givenName} ${patientDetails?.patient?.familyName}`,
          assignee: assigneeText,
          state,
        }}
      />
    );
  } else {
    text = (
      <FormattedMessage
        defaultMessage="Assigned to {assignee}"
        values={{ assignee: assigneeText }}
      />
    );
  }

  return (
    <div className={title} ref={inViewRef}>
      {text}
    </div>
  );
}
